import React from 'react';
import { useTranslation } from 'react-i18next';
import Integration from '../IntegrationEdit';
import Setting from '../../../ui/templates/forms/SettingForm';
import FieldWrapper from '../../../utils/FieldWrapper';
import { TextField } from '../../../ui';

function Cradle() {
  const { t } = useTranslation();

  return (
    <Integration name="cradle">
      <Setting
        label={t('integrations.twilio.accountSid.label')}
        description={t('integrations.twilio.accountSid.description')}
      >
        <FieldWrapper component={TextField} name="accountSid" />
      </Setting>
      <Setting
        label={t('integrations.twilio.keyId.label')}
        description={t('integrations.twilio.keyId.description')}
      >
        <FieldWrapper component={TextField} name="keyId" type="password" />
      </Setting>
      <Setting
        label={t('integrations.twilio.keySecret.label')}
        description={t('integrations.twilio.keySecret.description')}
      >
        <FieldWrapper component={TextField} name="keySecret" type="password" />
      </Setting>
    </Integration>
  );
}

export default Cradle;
