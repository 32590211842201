import React, { useState, useEffect, useContext } from 'react';
import { Box, LinearProgress, Stack } from '@mui/joy';
import _ from 'lodash';
import { Campaign, Campaign as CampaignType } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  logger,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useDeleteCampaignMutation,
  useGetCampaignsQuery,
  usePauseCampaignMutation,
  useResumeCampaignMutation,
} from '../../api';
import CampaignList from './CampaignList';
import SortBy from '../../ui/organisms/ButtonContexts/SortBy';
import { Sort } from '../../types';
import SearchBar from '../../ui/molecules/inputs/SearchBar';
import CampaignDetails from './CampaignDetails';
import Container from '../../ui/templates/Container';
import { showToast } from '../../redux/reducers/toast';
import { useAppDispatch } from '../../redux';
import PageHeader from '../../ui/organisms/headers/PageHeader';
import { AccountContext } from '../../contexts/account.context';
import ExecuteCampaign from './CampaignListItem/Execute';
import EmptyResult from '../../ui/molecules/EmptyResult';
import ConfirmationDialog from '../../ui/molecules/ConfirmationDialog';
import { UserRole } from '../../constants';

const CAMPAIGN_SORT_FIELDS = [
  {
    name: 'created',
    label: 'Date Created',
    direction: 'desc',
  },
  {
    name: 'lastJob',
    label: 'Date Executed',
    direction: 'desc',
  },
];

function Campaigns() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { account, userRole } = useContext(AccountContext);
  const canManage = [
    UserRole.CAMPAIGN_MANAGER,
    UserRole.SENDER,
    UserRole.ADMIN,
  ].includes(userRole);

  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>('');
  const debouncedSearchKey = useDebouncedValue(searchKey, 250);
  const [selectedSort, setSelectedSort] = useState<Sort>(
    CAMPAIGN_SORT_FIELDS[0],
  );
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignType>();
  const [dialog, setDialog] = useState<
    'edit' | 'delete' | 'execute' | undefined
  >(undefined);
  const [deleteCampaign] = useDeleteCampaignMutation();
  const [pauseCampaign] = usePauseCampaignMutation();
  const [resumeCampaign] = useResumeCampaignMutation();

  // TODO: revert to 10
  const loadCampaigns = () => {
    setOffset(offset + 10);
  };

  const { data, isLoading, isFetching } = useGetCampaignsQuery(
    account
      ? {
          accountId: account?.id,
          request: {
            offset,
            limit: 20,
            q: debouncedSearchKey || '',
            orderBy: `${selectedSort.name}-${selectedSort.direction}`,
            inactive: true,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    setOffset(0);
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (data?.data) {
      if (offset > 0) {
        setCampaigns(_.uniq([...(campaigns || []), ...data.data]));
      } else {
        setCampaigns(data.data);
      }
    }
  }, [data, offset]);

  const createNew = () => {
    setDialog('edit');
    setSelectedCampaign({
      id: '',
      name: '',
    });
  };

  const onResume = (campaign: Campaign) => {
    resumeCampaign(campaign);
    updatePauseOrResume(campaign, true);
  };

  const onPause = (campaign: Campaign) => {
    pauseCampaign(campaign);
    updatePauseOrResume(campaign);
  };

  const updatePauseOrResume = (campaign: Campaign, isDelete = false) => {
    const tempCampaigns = _.cloneDeep(campaigns);
    const updateCampaign = tempCampaigns.find((i) => i.id === campaign.id);
    if (updateCampaign) {
      if (isDelete) {
        delete updateCampaign?.paused;
      } else {
        updateCampaign.paused = new Date().toString();
      }
      setCampaigns(tempCampaigns);
    }
  };

  const handleDelete = (campaign: Campaign) => {
    deleteCampaign(campaign)
      .then(() => {
        dispatch(
          showToast({
            severity: 'success',
            message: 'Campaign deleted successfully',
          }),
        );
      })
      .catch((err) => {
        logger.error('err', err);
        dispatch(
          showToast({
            severity: 'error',
            message: 'Unable to delete campaign',
          }),
        );
      });
  };

  const handleAction = (action: string, campaign?: CampaignType) => {
    if (!campaign) return;

    switch (action) {
      case 'execute':
        setSelectedCampaign(campaign);
        setDialog('execute');
        break;
      case 'edit':
        setSelectedCampaign(campaign);
        setDialog('edit');
        break;
      case 'copy':
        setSelectedCampaign({ ...campaign, id: '' });
        setDialog('edit');
        break;
      case 'delete':
        setSelectedCampaign(campaign);
        setDialog('delete');
        break;
      case 'pause':
        onPause(campaign);
        break;
      case 'resume':
        onResume(campaign);
        break;
      default:
    }
  };

  const renderLoader = () => (
    <Stack
      justifyContent="center"
      height="100%"
      alignItems="center"
      position="relative"
    >
      <Loader size={200} label="Loading campaigns..." hideBackdrop />
    </Stack>
  );

  const renderEmptyResult = () => {
    const isSearching = (debouncedSearchKey?.length || 0) > 0;

    return (
      <EmptyResult
        heading={
          isSearching ? t('emptyResultHeading') : t('emptyResultCreateHeading')
        }
        text={
          isSearching ? t('emptyResultMessage') : t('emptyCampaignListMessage')
        }
        onButtonClicked={!isSearching ? () => createNew() : undefined}
        item="campaigns"
      />
    );
  };

  const renderCampaignList = () =>
    account && (
      <CampaignList
        account={account}
        campaigns={campaigns}
        loadCampaigns={loadCampaigns}
        onSelectedCampaign={(action, campaign) => {
          handleAction(action, campaign);
        }}
      />
    );

  const renderContent = () => {
    if (isLoading) {
      return renderLoader();
    }
    if (campaigns.length === 0) {
      return renderEmptyResult();
    }
    return renderCampaignList();
  };

  return (
    <>
      <PageHeader
        title="Campaigns"
        ctaText="Create Campaign"
        ctaAction={() => createNew()}
        ctaDisabled={!canManage || userRole === UserRole.SENDER}
      />
      <Container>
        <Stack direction="row" pb={2}>
          <SearchBar value={searchKey} onChange={setSearchKey} />
          {campaigns.length > 0 && (
            <Stack marginLeft="auto">
              <SortBy
                onChange={setSelectedSort}
                value={selectedSort}
                options={CAMPAIGN_SORT_FIELDS}
              />
            </Stack>
          )}
        </Stack>

        <Box
          flex={1}
          id="campaignList"
          sx={{
            overflowY: 'overlay',
          }}
        >
          {isFetching && (
            <LinearProgress
              sx={{
                position: 'sticky',
                top: 0,
              }}
            />
          )}
          {renderContent()}
        </Box>
      </Container>

      {dialog === 'edit' && (
        <CampaignDetails
          campaign={selectedCampaign}
          onClose={() => {
            setDialog(undefined);
            setSelectedCampaign(undefined);
          }}
        />
      )}
      {selectedCampaign && (
        <>
          <ExecuteCampaign
            open={dialog === 'execute'}
            onClose={() => setDialog(undefined)}
            campaign={selectedCampaign}
          />
          <ConfirmationDialog
            open={dialog === 'delete'}
            onClose={(result) => {
              setDialog(undefined);
              if (result) {
                handleDelete(selectedCampaign);
              }
            }}
            type="danger"
            header="Delete Campaign"
            content="Are you sure you want to delete this campaign?"
            confirmLabel="action.delete"
          />
        </>
      )}
    </>
  );
}

export default Campaigns;
