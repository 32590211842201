import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Stack, Card, Typography } from '@mui/joy';

function SheetReconnectPrompt() {
  const { t } = useTranslation('workflows');
  return (
    <Card
      sx={{
        padding: '12px',
        backgroundColor: 'var(--joy-palette-neutral-100)',
      }}
    >
      <Stack spacing={1.5} direction="row">
        <FontAwesomeIcon
          icon={regular('triangle-exclamation')}
          style={{ color: 'var(--joy-palette-primary-500)' }}
        />

        <Typography level="body-xs">{t('edit.noAccessToSheet')}</Typography>
      </Stack>
    </Card>
  );
}

export default SheetReconnectPrompt;
