import {
  SakariAPIResponse,
  SearchablePaginationRequest,
  Sender,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { buildGridQuery } from './common';
import { SENDER_TAG } from './tags';
import { AccountIdWith } from '../types';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getSenders: builder.query<
      SakariAPIResponse<Sender[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/senders?${buildGridQuery(request)}`,
      providesTags: [SENDER_TAG],
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetSendersQuery } = extendedApi;
