import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chip, Stack } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface OverFlowCellProps<T> {
  data: Array<any>;
  itemWidth?: number;
  renderItem?: (item: T) => JSX.Element | null;
  children?: React.ReactNode;
  renderOverflow?: (overflowData: T[]) => React.ReactNode;
  containerSx?: SxProps;
  showMin?: number;
  showOverflow?: boolean;
  positionChildren?: 'before' | 'after';
}

function OverFlowCell<T>({
  data,
  itemWidth = 100,
  showMin,
  renderItem,
  children,
  renderOverflow,
  containerSx,
  showOverflow,
  positionChildren = 'before',
}: OverFlowCellProps<T>) {
  const ref = useRef<HTMLDivElement>(null);

  const [max, setMax] = useState(showMin || 0); // Initialize with `showMin`
  const [visibleData, setVisibleData] = useState<T[]>([]);
  const overflowData: T[] = useMemo(() => data.slice(max), [data, max]);
  const calculateVisibleItems = () => {
    if (!ref?.current || !data) return;

    const containerWidth: number = ref.current?.offsetWidth;

    let totalWidth = 0;
    let numVisibleItems = 0;
    const newVisibleItems = [];

    for (let i = 0; i < data.length; i++) {
      totalWidth += itemWidth;

      if (totalWidth > containerWidth) {
        break;
      }
      newVisibleItems.push(data[i]);
      numVisibleItems++;
    }
    setMax(numVisibleItems);
    setVisibleData(newVisibleItems);
  };

  useEffect(() => {
    calculateVisibleItems();

    window.addEventListener('resize', calculateVisibleItems);

    return () => {
      window.removeEventListener('resize', calculateVisibleItems);
    };
  }, [data, ref.current?.offsetWidth]);

  if (!data) return null;

  return (
    <Stack
      ref={ref}
      sx={{
        width: '100%',
        flexFlow: 'row nowrap',
        overflow: 'scroll',
        overflowX: 'hidden',
        gap: 1,
        alignItems: 'center',
        ...containerSx,
      }}
    >
      {positionChildren === 'before' && children}
      <Stack
        sx={{
          flexFlow: 'row nowrap',
          gap: 1,
          alignItems: 'center',
          ...containerSx,
        }}
      >
        {visibleData?.map((item) => renderItem?.(item))}
      </Stack>
      {(data?.length > max || showOverflow) &&
        (renderOverflow?.(overflowData) || (
          <Chip key="overflow">+{data.length - max}</Chip>
        ))}
      {positionChildren === 'after' && children}
    </Stack>
  );
}
export default OverFlowCell;
