import React from 'react';
import { Stack, Box, Checkbox } from '@mui/joy';
import { useFormState } from 'react-final-form';
import { PhoneNumber } from '@sakari-io/sakari-typings';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import Setting from '../../../../../ui/templates/forms/SettingForm';
import RestrictionCheck from './RestrictionCheck';
import Regulatory from './Regulatory';
import CountryChips from '../../../../../ui/organisms/ChipContexts/CountryChips';

interface SmsChannelDetailsProps {
  sender?: PhoneNumber;
}

function SmsChannelDetails({ sender }: SmsChannelDetailsProps) {
  const { type, country } = useFormState().values;

  return (
    <Box padding={2}>
      <Stack spacing={2}>
        {type === 'senderid' ? (
          <Setting
            label="Universal reply"
            description="Enable 2 way messaging on this number"
          >
            <FieldWrapper
              component={Checkbox}
              type="checkbox"
              name="channels.sms.universalReply"
            />
          </Setting>
        ) : null}

        <Setting label="Countries Served">
          <FieldWrapper
            component={
              type === 'local' && ['US', 'CA'].includes(country)
                ? RestrictionCheck
                : CountryChips
            }
            name="channels.sms.supportedDestinations"
            type={sender?.type}
          />
        </Setting>

        {sender?.channels?.sms?.regulatory && (
          <Setting label="Regulatory">
            <Regulatory sender={sender} />
          </Setting>
        )}
      </Stack>
    </Box>
  );
}

export default SmsChannelDetails;
