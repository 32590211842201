import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography, Link } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';
import { WorkflowNodeType } from '@sakari-io/sakari-typings';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import IncompleteButton from '../../../../../ui/organisms/ButtonContexts/IncompleteButton';
import NodeMini from '../NodeMini';
import SimpleDrawerHeader, {
  SimpleDrawerHeaderProps,
} from '../../../../../ui/molecules/SimpleDrawerHeader';

interface NodeConfigHeaderProps extends Omit<SimpleDrawerHeaderProps, 'title'> {
  type: WorkflowNodeType;
  incomplete?: boolean;
  divider?: boolean;
  onClick?: () => void;
  hideClose?: boolean;
}

const iconStyle = {
  color: 'var(--joy-palette-neutral-400)',
  '&:hover': { opacity: 0.7 },
};

const triggerLink =
  'https://support.sakari.io/hc/en-us/articles/15180659913492';

const actionLink = 'https://support.sakari.io/hc/en-us/articles/15180662074516';

function NodeConfigHeader({
  type,
  incomplete,
  divider,
  onClick,
  hideClose,
}: NodeConfigHeaderProps): JSX.Element {
  const renderActionDescription = (description?: string) => {
    if (!description) {
      return null;
    }

    return (
      <Stack sx={{ width: '100%' }}>
        <Typography level="body-sm" sx={{ display: 'inline' }}>
          <Typography style={{ marginRight: '0.5em' }}>
            {description}
          </Typography>
          <Link
            component={RouterLink}
            target="blank"
            to={type?.type === 'action' ? actionLink : triggerLink}
            sx={{ display: 'inline', '&:hover': { opacity: 0.7 } }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} style={iconStyle} />
          </Link>
        </Typography>
      </Stack>
    );
  };

  return (
    <SimpleDrawerHeader
      sx={{
        flexDirection: 'column',
        gap: '10px',
      }}
      divider={divider}
      onClick={onClick}
      hideClose={hideClose}
      title={
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <NodeMini type={type} />

            <Stack spacing={1} direction="row" alignItems="center">
              <Typography
                level="body-lg"
                sx={{ marginLeft: '1rem', fontWeight: 700 }}
              >
                {type.label}
              </Typography>

              {incomplete && <IncompleteButton />}
            </Stack>
          </Stack>
        </Stack>
      }
    >
      {renderActionDescription(type.description)}
    </SimpleDrawerHeader>
  );
}

export default NodeConfigHeader;
