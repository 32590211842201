import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/joy';
import React, { useRef } from 'react';
import { WorkflowEdge } from '@sakari-io/sakari-typings';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Edge, Node } from 'reactflow';
import {
  ACTION_PLACEHOLDER_NODE_CONFIG,
  actions,
} from '../../../../../redux/reducers/workflow';

interface AddNodeButtonProps {
  edge: WorkflowEdge;
  nodeId?: string;
  onClick?: (event: any) => void;
  disabled?: boolean;
}

function AddNodeButton({
  edge,
  nodeId,
  onClick,
  disabled,
}: AddNodeButtonProps) {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const { nodes } = useSelector((state: any) => state.workflow.present);

  const defaultHandleEdgeClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (!nodeId) {
      dispatch(actions.insertPlaceholderNode(edge as Edge)); //TODO hack remove
    } else {
      dispatch(
        actions.setCurrentRFNode(nodes.find(({ id }: Node) => id === nodeId)),
      );
      dispatch(actions.setNodeType(ACTION_PLACEHOLDER_NODE_CONFIG));
    }
  };

  return (
    <Box ref={ref} sx={{ pointerEvents: 'auto', zIndex: 50 }}>
      <Button
        size="xs"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (!disabled) {
            if (onClick) {
              onClick(e);
            } else {
              defaultHandleEdgeClick(e);
            }
          }
        }}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Box>
  );
}

export default AddNodeButton;
