import {
  ClickAwayListener,
  Popper as PopperBase,
  PopperProps,
} from '@mui/base';
import { SheetProps, styled } from '@mui/joy';
import { motion, AnimatePresence } from 'framer-motion';
import React, { forwardRef } from 'react';
import { Animations } from '@sakari-io/sakari-components';

type StyledPopperProps = PopperProps & Pick<SheetProps, 'variant'>;

const StyledPopper = styled(PopperBase)<StyledPopperProps>(
  ({ variant = 'plain', color = 'neutral' }) => ({
    // BASE POPPER STYLES
    filter: 'var(--joy-shadow-hover)',

    '&[data-popper-placement*="top"] > .arrow': {
      bottom: 0,
      '&::before': {
        borderColor: `var(--joy-palette-${color}-outlinedBorder)`,
        marginBottom: '-0.25rem',
        borderWidth: '0 1px 1px 0',
      },
    },
    '&[data-popper-placement*="right"] > .arrow': {
      left: 0,
      '&::before': {
        borderColor: `var(--joy-palette-${color}-outlinedBorder)`,
        marginLeft: '-0.25rem',
        borderWidth: '0 0 1px 1px',
      },
    },
    '&[data-popper-placement*="left"] > .arrow': {
      right: 0,
      '&::before': {
        borderColor: `var(--joy-palette-${color}-outlinedBorder)`,
        marginRight: '-0.25rem',
        borderWidth: '1px 1px 0 0',
      },
    },
    '&[data-popper-placement*="bottom"] > .arrow': {
      top: 0,
      '&::before': {
        borderColor: `var(--joy-palette-${color}-outlinedBorder)`,
        marginTop: '-0.25rem',
        borderWidth: '1px 0 0 1px',
      },
    },
    // ARROW STYLING
    '.arrow': {
      position: 'absolute',
      fontSize: 7,
      zIndex: 999,

      ...(['outlined', 'plain'].includes(variant)
        ? {
            background: `var(--joy-palette-background-surface)`,
          }
        : { background: `var(--joy-palette-${color}-${variant}Bg)` }),

      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        transform: 'rotate(45deg)',
        width: '0.5rem',
        height: '0.5rem',
        ...(['outlined', 'plain'].includes(variant)
          ? {
              background: `var(--joy-palette-background-surface)`,
            }
          : { background: `var(--joy-palette-${color}-${variant}Bg)` }),
        ...(variant === 'outlined' && {
          border: '1px solid',
        }),
      },
    },
  }),
);

export interface AppPopperProps
  extends PopperProps,
    Pick<SheetProps, 'variant'> {
  onOpenClose: (state: boolean) => void;
  arrow?: boolean;
  offset?: [number, number];
  animate?: 'grow' | 'fade';
  forwardedRef?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
}

function AppPopper({
  open,
  onOpenClose,
  placement,
  arrow,
  offset,
  children,
  animate,
  forwardedRef,
  variant = 'plain',
  color = 'neutral',
  ...popperProps
}: AppPopperProps) {
  const defaultArgs = {
    style: {
      zIndex: 'var(--joy-zIndex-popup)',
    },
    placement: placement || 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset || [0, 8],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrow ? '[data-popper-arrow]' : undefined,
        },
      },
    ],
    ...popperProps,
  };

  const getAnimation = () => {
    switch (animate) {
      case 'grow':
        return Animations.Grow();
      case 'fade':
        return Animations.Fade();
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      <StyledPopper
        open={open}
        ref={forwardedRef}
        variant={variant}
        color={color}
        {...defaultArgs}
      >
        {arrow ? <div className="arrow" data-popper-arrow /> : null}

        <ClickAwayListener onClickAway={() => onOpenClose(false)}>
          <motion.div {...getAnimation()}>
            {children as React.ReactNode}
          </motion.div>
        </ClickAwayListener>
      </StyledPopper>
    </AnimatePresence>
  );
}

const Popper = forwardRef<HTMLDivElement, AppPopperProps>((props, ref) => (
  <AppPopper {...props} forwardedRef={ref} />
));
export default Popper;
