import React, { useState } from 'react';
import { Stack, Button } from '@mui/joy';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { logger } from '@sakari-io/sakari-components';
import { PageHeader, TextField } from '../../../ui';
import Container from '../../../ui/templates/Container';
import PhoneInput from '../../../ui/atoms/inputs/PhoneInput';

export const download = () => {
  const svg = document.getElementById('QRCode');
  if (!svg) {
    logger.info('no qr code');
    return;
  }
  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    logger.info('no ctx');
    return;
  }
  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.download = 'QRCode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

function QRGenerator() {
  const [number, setNumber] = useState({
    number: '',
    country: '',
  });
  const [message, setMessage] = useState('');

  const { t } = useTranslation();

  return (
    <>
      <PageHeader title="QR Code Generator" />
      <Container>
        <Stack direction="row" gap={2}>
          <Stack gap={2} width={200} alignItems="center">
            <QRCode
              id="QRCode"
              size={200}
              value={`SMSTO:${number.number}:${message}`}
            />

            <Button
              onClick={() => download()}
              fullWidth
              disabled={!number.number}
            >
              {t('settings.reporting.download.label')}
            </Button>
          </Stack>
          <Stack gap={1} flex={2}>
            <PhoneInput
              value={number}
              onChange={setNumber}
              label={t('addSenders.phonenumber.buy.number')}
            />
            <TextField
              label={t('message')}
              value={message}
              onChange={setMessage}
            />
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

export default QRGenerator;
