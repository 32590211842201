/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import {
  DecoratorNode,
  LexicalNode,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import React, { ReactNode } from 'react';
import { $createVariableNode } from '../plugins/TemplatingPlugin';
import VariableComponent from './VariableComponent';

export type SerializedVariableNode = Spread<
  {
    name: string;
    params: string[];
    escaped: boolean;
    onHover?: (nodeId?: string) => any;
  },
  SerializedLexicalNode
>;

export class VariableNode extends DecoratorNode<ReactNode> {
  __name: string;
  __params: string[];
  __escaped: boolean;
  __onHover?: (nodeId?: string) => any;

  updateDOM(): boolean {
    return false;
  }

  static getType(): string {
    return 'variable';
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(
      node.__name,
      node.__params,
      node.__escaped,
      node.__onHover,
    );
  }

  constructor(
    name: string,
    params: string[],
    escaped: boolean,
    onHover?: (nodeId?: string) => any,
  ) {
    super();
    this.__name = name;
    this.__params = params;
    this.__escaped = escaped;
    this.__onHover = onHover;
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.contentEditable = 'false';
    return element;
  }

  static updateDOM(): false {
    return false;
  }

  setName(n: string) {
    this.__name = n;
  }

  setParams(n: string[]) {
    this.__params = n;
  }

  setEscaped(n: boolean) {
    this.__escaped = n;
  }

  setOnHover(n: (nodeId?: string) => any) {
    this.__onHover = n;
  }

  decorate(): ReactNode {
    return (
      <VariableComponent
        name={this.__name}
        params={this.__params}
        onHover={this.__onHover}
        nodeKey={this.getKey()}
      />
    );
  }

  // removing node
  // collapseAtStart(selection: RangeSelection): boolean {
  //   return true;
  // }

  exportJSON(): SerializedVariableNode {
    return {
      type: 'variable',
      version: 1,
      name: this.__name,
      params: this.__params,
      escaped: this.__escaped,
      onHover: this.__onHover,
    };
  }

  static isInline(): boolean {
    return true;
  }

  update(): void {
    // const {altText, showCaption, position} = payload;
    // if (altText !== undefined) {
    //   writable.__altText = altText;
    // }
    // if (showCaption !== undefined) {
    //   writable.__showCaption = showCaption;
    // }
    // if (position !== undefined) {
    //   writable.__position = position;
    // }
  }

  static importJSON(serializedNode: SerializedLexicalNode): LexicalNode {
    //logger.info('importJSON', serializedNode);
    const varNode = serializedNode as SerializedVariableNode;
    const node = $createVariableNode(
      varNode.name,
      varNode.params,
      varNode.escaped,
      varNode.onHover,
    );
    return node;
  }
}

export function $isVariableNode(
  node: LexicalNode | null | undefined,
): node is VariableNode {
  return node instanceof VariableNode;
}
