import React from 'react';
import { useDeleteLeadMutation } from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

// action is removed

interface ActionLeadDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  lead: string;
}

function ActionLeadDialog({ open, setOpen, lead }: ActionLeadDialogProps) {
  const [deleteLead] = useDeleteLeadMutation();
  const dispatch = useAppDispatch();

  const onDelete = async () => {
    deleteLead(lead).then(() => {
      dispatch(showToast({ severity: 'info', message: 'Lead Deleted' }));
    });
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={(result) => {
        setOpen(false);
        if (result) {
          onDelete();
        }
      }}
      confirmLabel="action.delete"
      header="Delete Lead"
      content="You cannot undo this action."
      type="danger"
    />
  );
}

export default ActionLeadDialog;
