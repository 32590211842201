import { Stack, Tooltip, Typography } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import { Option } from '../../../../../types/ui';
import SelectableCardGroup from '../../../../../ui/molecules/groups/SelectableCardGroup';

export const USE_CASES = [
  '2FA',
  'ACCOUNT_NOTIFICATION',
  // 'AGENTS_FRANCHISES',
  // 'CARRIER_EXEMPT',
  // 'CHARITY',
  'CUSTOMER_CARE',
  'DELIVERY_NOTIFICATION',
  // 'EMERGENCY',
  'FRAUD_ALERT',
  'HIGHER_EDUCATION',
  // 'K12_EDUCATION',
  // 'LOW_VOLUME',
  'MARKETING',
  // 'MIXED',
  // 'POLITICAL',
  'POLLING_VOTING',
  // 'PROXY',
  'PUBLIC_SERVICE_ANNOUNCEMENT',
  'SECURITY_ALERT',
  // 'SOCIAL',
  // 'SOLE_PROPRIETOR',
  // 'SWEEPSTAKE',
  // 'TRIAL',
  // 'UCAAS_HIGH',
  // 'UCAAS_LOW',
];

// const MIXED_USE_CASES = [
//   '2FA',
//   'ACCOUNT_NOTIFICATION',
//   'CUSTOMER_CARE',
//   'DELIVERY_NOTIFICATION',
//   'FRAUD_ALERT',
//   'HIGHER_EDUCATION',
//   'MARKETING',
//   'POLLING_VOTING',
//   'PUBLIC_SERVICE_ANNOUNCEMENT',
//   'SECURITY_ALERT',
// ];

interface UseCaseSelectionProps {
  brand: any;
}

function UseCaseSelection({ brand }: UseCaseSelectionProps) {
  const { t } = useTranslation();

  const useCaseOptions = USE_CASES.map((option) => ({
    id: option,
    label: t(`senders.register.10dlc.campaign.usecases.${option}.name`),
    description: t(
      `senders.register.10dlc.campaign.usecases.${option}.description`,
    ),
  }));

  return (
    <>
      <Typography>
        {t('senders.register.10dlc.campaign.usecases.description')}
      </Typography>
      <FieldWrapper
        component={SelectableCardGroup}
        multiple
        name="campaign.useCases"
        options={useCaseOptions.filter((option) => {
          return brand?.entityType === 'SOLE_PROPRIETOR'
            ? true
            : option.id !== 'SOLE_PROPRIETOR';
        })}
        containerSx={{
          flexFlow: 'row wrap',
        }}
        width={150}
        renderOption={(option: Option) => (
          <Stack
            key={option.id}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Tooltip title={option.label}>
              <Typography fontSize={option.label.length > 10 ? 'sm' : 'md'}>
                {option.label}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />

      {/* {campaign?.useCases?.length > 1 ? (
        <Setting
          label={t('senders.register.10dlc.campaign.isLowVolume.label')}
          description={t(
            'senders.register.10dlc.campaign.isLowVolume.description'
          )}
          sx={{
            '& > div': {
              flex: 2,
            },
          }}
        >
          <FieldWrapper
            component={Switch}
            name="campaign.lowVolume"
            type="checkbox"
          />
        </Setting>
      ) : null} */}
    </>
  );
}

export default UseCaseSelection;
