import React from 'react';
import { Stack, Typography, Divider } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface CreateWorkflowPropertyMenuItemProps {
  onClick?: () => void;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
}

function CreateWorkflowPropertyMenuItem({
  onClick,
  startDecorator,
  endDecorator,
}: CreateWorkflowPropertyMenuItemProps) {
  const { t } = useTranslation('workflows');

  return (
    <>
      <Divider inset="context" />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={1}
        pl={2}
        pr={2}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onClick) {
            onClick();
          }
        }}
        sx={{
          '&:hover': {
            cursor: 'pointer',
            opacity: 0.7,
          },
        }}
      >
        {startDecorator}
        <Typography
          level="body-sm"
          sx={{ color: 'var(--joy-palette-primary-600)' }}
        >
          {t('edit.createNewWorkflowProperty')}
        </Typography>
        {endDecorator}
      </Stack>
    </>
  );
}

export default CreateWorkflowPropertyMenuItem;
