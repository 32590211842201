import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Link } from '@mui/joy';
import AppDialog from '../../../ui/molecules/AppDialog';
import Restrictions from '../Restrictions';

interface RestrictionsConfirmDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  handleConfirm: () => void;
  handleCancel: () => void;
}

function RestrictionsConfirmDialog({
  open = true,
  setOpen,
  handleConfirm,
  handleCancel,
}: RestrictionsConfirmDialogProps) {
  const { t } = useTranslation();
  return (
    <AppDialog
      open={open}
      setOpen={setOpen}
      size="sm"
      header={
        <Typography level="h4" fontWeight={700}>
          {t('senders.register.platformFreeTrial.restrictions.areYouSure')}
        </Typography>
      }
      confirmButtonProps={{
        children: t('continue'),
        onClick: () => {
          handleConfirm();
        },
        size: 'md',
      }}
      cancelButtonProps={{
        children: t('cancel'),
        onClick: () => {
          handleCancel();
        },
        size: 'md',
      }}
      buttonLayout={{
        position: 'center',
        reverse: false,
        fullWidth: false,
      }}
      sx={{ maxWidth: 'clamp(500px, 100%, 600px)', padding: '24px 40px' }}
      content={
        <Stack spacing={2}>
          <Restrictions
            withoutQr
            label={
              <Typography level="body-md">
                {t('senders.register.platformFreeTrial.restrictions.warning')}
              </Typography>
            }
          />

          <Typography level="body-md">
            {t(
              'senders.register.platformFreeTrial.restrictions.upgradeAndRegister',
            )}
          </Typography>

          <Link
            underline="always"
            target="_blank"
            rel="noopener"
            href="https://support.sakari.io/hc/en-us/articles/20314374147092"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textDecoration: 'underline',
              color: 'primary.light',
              '&:hover': {
                textDecoration: 'underline',
                color: 'primary.main',
              },
            }}
          >
            {t('senders.register.platformFreeTrial.learnMore')}
          </Link>
        </Stack>
      }
    />
  );
}

export default RestrictionsConfirmDialog;
