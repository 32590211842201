import { Stack, FormLabel } from '@mui/joy';
import _ from 'lodash';
import React from 'react';
import Select from '../../../atoms/inputs/Select';

interface Time {
  hour: number;
  minute: number;
}

interface SimpleTimeProps {
  label?: string;
  value: Time;
  onChange: (time: Time) => any;
  sx?: any;
  disabled?: boolean;
}

const hours = _.range(0, 24);
const minutes = _.range(0, 60);
const width = 80;

function SimpleTime({ label, value, onChange, sx, disabled }: SimpleTimeProps) {
  const renderMinuteLabel = (i: number) => {
    if (i < 10) {
      return `0${i}`;
    }
    return i.toString();
  };

  return (
    <Stack gap={1}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Stack direction="row" sx={sx}>
        <Select
          sx={{ minWidth: width }}
          disabled={disabled}
          value={value?.hour}
          onChange={(newVal) =>
            onChange({ hour: Number(newVal), minute: value?.minute })
          }
          options={hours.map((i) => ({
            value: i,
            label: i.toString(),
          }))}
        />
        <Select
          sx={{ minWidth: width }}
          value={value?.minute}
          disabled={disabled}
          onChange={(newVal) =>
            onChange({ hour: value?.hour, minute: Number(newVal) })
          }
          options={minutes.map((i) => ({
            value: i,
            label: renderMinuteLabel(i),
          }))}
        />
      </Stack>
    </Stack>
  );
}

export default SimpleTime;
