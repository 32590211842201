import React from 'react';
import { Box, Card, IconButton, Stack, Typography } from '@mui/joy';
import { Anchorme } from 'react-anchorme';
import { Message as MessageType } from '@sakari-io/sakari-typings';
import { format, parseJSON } from 'date-fns';
import { Tooltip, Avatar, IntegrationIcon } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import _ from 'lodash';
import MessageStatus from '../MessageStatus';
import SecureMedia from '../../../../../ui/molecules/SecureImage';
import { DialogType } from '..';
import config from '../../../../../config';

function MessageContent({ message }: { message: MessageType }) {
  return (
    <Card
      id="message-content"
      variant={message.outgoing ? 'solid' : 'plain'}
      color={message.outgoing ? 'primary' : 'neutral'}
      sx={{
        ...(message.outgoing
          ? {
              alignSelf: 'flex-end',
            }
          : {
              alignSelf: 'flex-start',
            }),
        width: '100%',
        overflow: 'hidden',
        padding: 'var(--joy-radius-md)',
        hyphens: 'auto',
        display: 'block',
        whiteSpace: 'pre-wrap',
        boxShadow: 'sm',

        a: {
          color: 'var(--joy-palette-primary-softHoverBg)',
          textDecorationThickness: '1px',
          textUnderlineOffset: '4px',
          wordBreak: 'break-all',

          '&:hover': { color: 'inherit' },
        },
      }}
    >
      <Anchorme target="_blank">
        {message.message || message.template || ''}
      </Anchorme>
    </Card>
  );
}

// TODO: pass email into created by object?
const getSender = (message: MessageType) => {
  const { contact, created } = message;
  if (contact?.id === created?.by?.id) {
    return contact;
  }

  if (created.by && created.by?.name === '') {
    return {
      ...created.by,
      name: _.capitalize([created.by.source, created.by.subSource].join(' ')),
    };
  }
  return created.by;
};

const getAvatarImage = (message: MessageType) => {
  switch (message.created?.by?.source) {
    case 'autoresponder': {
      return <FontAwesomeIcon icon={solid('robot')} />;
    }
    case 'sakari':
      return undefined;
    default: {
      return message.created.by?.source ? (
        <IntegrationIcon
          stage={config.stage}
          integration={message.created.by.source}
          fill
        />
      ) : undefined;
    }
  }
};

const getFormattedScheduleDate = (date: string) => {
  const parseDate = new Date(parseJSON(date));

  return `${format(parseDate, 'MMMM dd, yyyy')} @ ${format(
    parseDate,
    'h:mm a',
  )}`;
};

const getDisplayedDate = (message: MessageType) => {
  let date = message.created.at;
  // if scheduled but not sent, show scheduled date with time
  if (message.sendAt && message.status !== 'delivered') {
    return getFormattedScheduleDate(message.sendAt);
  }
  if (message.status === 'delivered') {
    // if scheduled, and delivered, use sendAt date
    if (message.sendAt) {
      date = message.sendAt;
    }
  }
  return format(new Date(parseJSON(date)), 'h:mm a');
};

interface MessageProps {
  message: MessageType;
  onAction: (action: DialogType, message: MessageType) => void;
}

function Message({ message, onAction }: MessageProps) {
  if (!(message.media?.length || message.template || message.message)) {
    return null;
  }

  return (
    <Box
      sx={{
        py: 1,
        pr: 4,
        ':hover #actions': {
          display: 'flex',
        },
      }}
    >
      <Stack
        direction={`${message.outgoing ? 'row-reverse' : 'row'}`}
        spacing={1}
      >
        <Avatar
          person={getSender(message)}
          size="lg"
          color={message.outgoing ? 'primary' : 'neutral'}
          variant="soft"
          sx={{}}
        >
          {getAvatarImage(message)}
        </Avatar>

        <Stack
          sx={{
            position: 'relative',
            justifyContent: 'flex-end',
            maxWidth: 'clamp(200px, 70%, 400px)',
          }}
        >
          {message.status === 'scheduled' && (
            <Card
              id="actions"
              variant="outlined"
              color="neutral"
              size="sm"
              sx={{
                position: 'absolute',
                display: 'none',
                bottom: 8,
                right: -1,
                zIndex: 1,
                p: 0,
                opacity: 0.9,
                ':hover': {
                  opacity: 1,
                },
              }}
            >
              <IconButton
                size="xs"
                onClick={() => onAction(DialogType.Cancel, message)}
              >
                <FontAwesomeIcon icon={regular('trash-can')} />
              </IconButton>
            </Card>
          )}

          {message.message || message.template ? (
            <MessageContent message={message} />
          ) : null}
          {message.media?.length ? (
            <Stack
              sx={{
                gap: 1,
                mr: 0.8,
                mt: 0.8,
                alignItems: 'flex-end',
              }}
            >
              {message.media.map((mediaFile: any, index: number) => {
                return (
                  <SecureMedia
                    key={`${message.id}-${index}`}
                    messageId={message.id}
                    index={index}
                    type={mediaFile.type}
                    fileName={mediaFile.filename}
                  />
                );
              })}
            </Stack>
          ) : null}
          <Stack
            sx={{
              alignItems: 'start',
              flexDirection: `${message.outgoing ? 'row' : 'row-reverse'}`,
              justifyContent: 'space-between',
              gap: 1,
              width: '100%',
            }}
          >
            <Tooltip
              title={getFormattedScheduleDate(
                message.sendAt ?? message.created.at,
              )}
              size="sm"
              arrow
              followCursor
            >
              <Typography level="body-sm" textColor="text.secondary" mt={0.5}>
                {getDisplayedDate(message)}
              </Typography>
            </Tooltip>
            <MessageStatus message={message} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default React.memo(Message);
