import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/joy';
import { GoogleSheet } from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader } from '@sakari-io/sakari-components';
import { Node } from 'reactflow';
import SheetDetails from './SheetDetails';
import { useGetGoogleSheetQuery } from '../../../api';
import { AccountContext } from '../../../contexts/account.context';
import SheetConnect from './SheetConnect';
import SaveFailureDialog from '../../../pages/Workflows/Edit/SaveFailureDialog';
import UserIntegration from '../../../pages/Workflows/Edit/ConfigDrawer/NodeProperties/UserIntegration';

interface GoogleSheetsProps {
  value: GoogleSheet | undefined;
  onChange: (value: GoogleSheet | undefined) => void;
  saveVersion: (onSuccess?: () => any) => any;
  currentNode: Node;
  workflowId: string;
  disabled?: boolean;
}

function GoogleSheets({
  value,
  onChange,
  saveVersion,
  currentNode,
  workflowId,
  disabled,
}: GoogleSheetsProps) {
  const { account, profile } = useContext(AccountContext);
  const [openSaveFailureDialog, setOpenSaveFailureDialog] = useState(false);

  const {
    data: sheetData,
    isLoading: isLoadingSheet,
    isError,
  } = useGetGoogleSheetQuery(
    account && value?.id && value?.connectionId
      ? {
          accountId: account?.id,
          request: {
            sheetId: value.id,
            connectionId: value.connectionId,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    if (sheetData?.data) {
      const { id, name, sheets } = sheetData?.data as any;
      onChange({
        ...value,
        id,
        name,
        tab: sheets[0],
      });
    }
  }, [sheetData]);

  return (
    <Stack spacing={3}>
      {isLoadingSheet && <Loader size={100} />}

      <UserIntegration
        account={account!}
        currentNode={currentNode}
        integrationType="googlesheets"
        saveVersion={saveVersion}
        value={value?.connectionId}
        onChange={(connectionId) => {
          if (connectionId) {
            onChange({ ...value, connectionId });
          }
        }}
        user={profile!}
        workflowId={workflowId}
        disabled={disabled}
      />

      {value?.connectionId && !value?.id ? (
        <SheetConnect
          disabled={disabled}
          onConnect={(sheetId) =>
            onChange({ connectionId: value?.connectionId, id: sheetId })
          }
        />
      ) : null}

      {value?.connectionId && value?.id ? (
        <SheetDetails
          disabled={disabled}
          value={value}
          onChange={onChange}
          tabs={(sheetData?.data as any)?.sheets}
          readOnly={isError}
        />
      ) : null}

      <SaveFailureDialog
        open={openSaveFailureDialog}
        onClose={() => setOpenSaveFailureDialog(false)}
      />
    </Stack>
  );
}

export default GoogleSheets;
