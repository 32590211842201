import React from 'react';
import { Stack, Card, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';

interface TriggerPlaceholderProps {
  id?: string;
  hoverEditMode?: any;
}

function TriggerPlaceholder({ id, hoverEditMode }: TriggerPlaceholderProps) {
  const { t } = useTranslation('workflows');
  const { currentRFNode } = useSelector((state: any) => state.workflow.present);
  const selected = id === currentRFNode?.id;

  return (
    <Card
      sx={{
        flex: 1,
        width: '100%',
        height: '100%',
        border: '1px dashed var(--joy-palette-neutral-200)',
        borderRadius: 'var(--joy-radius-lg)',
        ...(selected && {
          ...hoverEditMode,
        }),
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          width: '100%',
          borderRadius: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '50px',
        }}
      >
        <FontAwesomeIcon
          icon={solid('circle-plus')}
          style={{
            color: 'var(--joy-palette-focusVisible)',
          }}
        />

        <Typography
          level="body-md"
          sx={{ fontWeight: 700, color: 'var(--joy-palette-neutral-400)' }}
        >
          {t('triggers.assignTrigger')}
        </Typography>
      </Stack>

      <Handle
        type="target"
        style={{
          opacity: 0,
          width: '100%',
          height: '50%',
          cursor: 'pointer',
        }}
        position={Position.Top}
        isConnectable={false}
        id="b"
      />

      <Handle
        type="source"
        style={{
          opacity: 0,
          width: '1',
          height: '1',
          cursor: 'pointer',
        }}
        position={Position.Bottom}
        isConnectable={false}
        id="a"
      />
    </Card>
  );
}

export default TriggerPlaceholder;
