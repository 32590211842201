import React from 'react';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { LicenseInfo } from '@mui/x-license';
import 'react-phone-input-2/lib/material.css';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import Main from './Main';
import SystemNotifications from './ui/organisms/alerts/SystemNotifications';
import store from './redux/store';
import AccountContextProvider from './contexts/account.context';
import config from './config';

LicenseInfo.setLicenseKey(config.datagridLicenseKey);

function App() {
  const onRedirectCallback = (appState: any) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );

    window.location.href = appState.returnTo;
  };

  // const onRedirect = () => {
  //   const url = `${window.location.origin}/accounts`;
  //   const date = new Date();
  //   date.setDate(date.getDate() + 60);
  //   const params = qs.parse(window.location.search, {
  //     ignoreQueryPrefix: true,
  //   });
  //   if (params.referrer) {
  //     setCookie('referrer', params.referrer, { path: '/', expires: date });
  //   }
  //   return url;
  // };

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Auth0Provider
          audience={config.auth0.audience}
          domain={config.auth0.domain}
          clientId={config.auth0.clientId}
          // redirectUri={getRedirectUri}
          // redirectUri={onRedirect()}
          redirectUri={config.auth0.callbackUrl}
          cacheLocation="localstorage"
          useRefreshTokens
          onRedirectCallback={onRedirectCallback}
          scope={config.auth0.scopes}
        >
          <SystemNotifications />

          <AccountContextProvider>
            <Main />
          </AccountContextProvider>
        </Auth0Provider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
