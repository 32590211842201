import React from 'react';
import { Button, Divider, Stack } from '@mui/joy';
import { Account } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { logger } from '@sakari-io/sakari-components';
import FieldWrapper from '../../../../utils/FieldWrapper';
import { useUpdateAccountMutation } from '../../../../api';
import Switch from '../../../../ui/atoms/inputs/Switch';
import { TextField } from '../../../../ui';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

interface AutoTopUpProps {
  account: Account;
}

function AutoTopUp({ account }: AutoTopUpProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [updateAccount] = useUpdateAccountMutation();

  const schema = Yup.object().shape({
    enabled: Yup.boolean(),
    below: Yup.number().when('enabled', {
      is: true,
      then: Yup.number().required(),
    }),
    amount: Yup.number().when('enabled', {
      is: true,
      then: Yup.number()
        .min(
          10,
          t('validation.minAmount', {
            amount: '$10',
          }),
        )
        .max(
          2000,
          t('validation.maxAmount', {
            amount: '$2000',
          }),
        )
        .required(),
    }),
  });

  const validate = makeValidate(schema);

  const submitAutoTopUp = async (values: any) => {
    logger.info({ values });
    const payload: Partial<Account> = {
      options: {
        autotopup: values.enabled
          ? {
              below: values.below,
              amount: values.amount,
            }
          : false,
      } as any,
    };
    await updateAccount(payload)
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              message: t('settings.billing.general.credits.autoTopup.success'),
              severity: 'success',
            }),
          );
        }
      })
      .catch((err) => {
        logger.info('err', err.message);
        dispatch(
          showToast({
            message: err?.data?.error.message ?? t('somethingWrong'),
            severity: 'error',
          }),
        );
      });
  };

  return (
    <>
      {/* Refactor into a form component */}
      <Form
        initialValues={{
          enabled: !!account.options?.autotopup,
          below: account.options?.autotopup?.below ?? 5,
          amount: account.options?.autotopup?.amount ?? 10,
        }}
        validate={validate}
        onSubmit={submitAutoTopUp}
      >
        {({
          handleSubmit,
          pristine,
          values,
          submitting,
          hasValidationErrors,
        }) => (
          <>
            <FieldWrapper
              type="checkbox"
              name="enabled"
              component={Switch}
              label={t(
                `settings.billing.general.credits.autoTopup.${
                  values.enabled ? 'enabled' : 'disabled'
                }`,
              )}
              sx={{ width: 'fit-content', flexDirection: 'row-reverse' }}
            />
            {values.enabled && (
              <Stack direction="row" gap={2}>
                <FieldWrapper
                  component={TextField}
                  name="below"
                  type="number"
                  inputType="currency"
                  label={t(
                    'settings.billing.general.credits.belowAmount.label',
                  )}
                  helperText={t(
                    'settings.billing.general.credits.belowAmount.description',
                  )}
                  slotProps={{
                    input: {
                      min: 0,
                      step: 5,
                    },
                  }}
                  fullWidth
                />
                <FieldWrapper
                  component={TextField}
                  name="amount"
                  type="number"
                  inputType="currency"
                  label={t('settings.billing.general.credits.addAmount.label')}
                  helperText={t(
                    'settings.billing.general.credits.addAmount.description',
                  )}
                  slotProps={{
                    input: {
                      min: 10,
                      max: 2000,
                      step: 5,
                    },
                  }}
                  fullWidth
                />
              </Stack>
            )}
            <Divider inset="context" />
            <Stack alignItems="flex-end">
              <Button
                onClick={handleSubmit}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                {t('action.save')}
              </Button>
            </Stack>
          </>
        )}
      </Form>
    </>
  );
}
export default AutoTopUp;
