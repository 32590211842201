import React from 'react';
import Snackbar, { SnackbarProps } from '../../../templates/Snackbar';
import StatusAlert, { StatusAlertProps } from '../StatusAlert';

type ToastSnackbarProps = StatusAlertProps &
  Pick<SnackbarProps, 'open' | 'placement' | 'onClose' | 'fullWidth'>;

function ToastSnackbar({
  placement,
  open,
  onClose,
  fullWidth,
  style,
  ...alertProps
}: ToastSnackbarProps) {
  return (
    <Snackbar {...{ open, onClose, placement, fullWidth, style }}>
      <StatusAlert {...{ ...alertProps, handleClose: onClose }} />
    </Snackbar>
  );
}

export default ToastSnackbar;
