import React from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Stack,
  Typography,
} from '@mui/joy';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@sakari-io/sakari-components';
import FieldWrapper from '../../../../../utils/FieldWrapper';

import Checkboxes, {
  CheckboxData,
} from '../../../../../ui/organisms/Checkboxes';
import { TextField } from '../../../../../ui';
import URLInput from '../../../../../ui/atoms/inputs/URLInput';

function CampaignDetails() {
  const { t } = useTranslation();
  const { values } = useFormState();

  const containOptions: CheckboxData[] = [
    'embeddedLink',
    'embeddedPhone',
    'numberPool',
    'ageGated',
    'directLending',
  ].map((option) => ({
    value: option,
    label: t(`senders.register.10dlc.campaign.features.${option}.label`),
    description:
      option === 'ageGated' ? (
        <Stack>
          <Typography level="body-sm" textColor="text.tertiary">
            {t(
              `senders.register.10dlc.campaign.features.${option}.description`,
            )}
          </Typography>
          <Link
            href="https://www.ctia.org/the-wireless-industry/industry-commitments/wireless-content-guidelines-classification-criteria"
            target="_blank"
            title={t(
              'senders.register.10dlc.campaign.features.ageGated.linkTitle',
            )}
          >
            {t('readMore')}
          </Link>
        </Stack>
      ) : (
        t(`senders.register.10dlc.campaign.features.${option}.description`)
      ),
  }));

  const useOptions: CheckboxData[] = [
    'subscriberOptin',
    'subscriberOptout',
    'subscriberHelp',
  ].map((option) => ({
    value: option,
    label: t(`senders.register.10dlc.campaign.features.${option}.label`),
    description: t(
      `senders.register.10dlc.campaign.features.${option}.description`,
    ),
  }));

  return (
    <>
      <Typography level="body-md">
        All campaign submissions must include SMS disclosures and a compliant
        privacy policy. A web presence is also required during the review of
        your Brand’s campaign.
      </Typography>
      <Typography level="body-md">
        Please ensure your SMS program is compliant and meets the standard for
        approval prior to submitting. Rejections can significantly increase the
        timeline for sending SMS.
      </Typography>
      <FieldWrapper
        component={Textarea}
        name="campaign.description"
        label={t('senders.register.10dlc.campaign.description.label')}
        placeholder={
          'This campaign will be used by [BRAND NAME] to send [Marketing/Account Alerts/OTP/Reminders] to end-users who opt-in to receive SMS messages. '
        }
        minRows={5}
        minLength={40}
        maxLength={501}
        required
        autoFocus
        labelInfo="Provide a clear and comprehensive description of the campaign’s objectives, and explain what the end-user will experience after opting in (min 40 characters) "
        helperText="Be sure to include your Brand and use case in the description. Your description should match your selected use case(s) and the rest of your submission (CTA, Campaign Details, Sample Messages). "
      />

      {/* <FieldWrapper
        component={Select}
        name="campaign.optInMethod"
        label="Opt In Method"
        // labelInfo={t('')}
      >
        <Option value="web">Web Form</Option>
        <Option value="other">Other</Option>
      </FieldWrapper> */}

      <FieldWrapper
        component={Textarea}
        name="campaign.messageFlow"
        label={t('senders.register.10dlc.campaign.messageFlow.label')}
        placeholder={t(
          'senders.register.10dlc.campaign.messageFlow.placeholder',
        )}
        // labelInfo={t('senders.register.10dlc.campaign.messageFlow.labelInfo')}
        minRows={5}
        minLength={40}
        maxLength={501}
        required
        helperText="Your opt-in must be explicitly clear and optional, include an SMS disclosure, and cannot be combined with other channels or terms."
      />

      <FieldWrapper
        component={URLInput}
        name="campaign.privacyPolicyLink"
        label="Privacy Policy URL"
        labelInfo={
          'A compliant Privacy Policy is mandatory for all 10DLC campaigns.'
        }
        helperText="A dedicated SMS/Texting section indicating mobile information is not shared or sold to 3rd parties and opt-out instructions should be included in your Privacy Policy."
      />
      <FieldWrapper
        component={URLInput}
        name="campaign.termsAndConditionsLink"
        label="Terms and Conditions URL"
        // labelInfo={t(
        //   'senders.register.10dlc.campaign.features.optinMessage.description',
        // )}
      />

      <Typography fontWeight="bold">
        {t('senders.register.10dlc.campaign.features.optionsHeader')}
      </Typography>
      <FieldWrapper
        row
        component={Checkboxes}
        name="campaign.features"
        id="options"
        items={containOptions}
        columns={2}
        sx={{ '& > .MuiCheckbox-checkbox': { position: 'relative' } }}
      />
      {values?.campaign?.features?.embeddedLink ? (
        <FieldWrapper
          component={TextField}
          name="campaign.embeddedLinkSample"
          label="Embedded Link Sample URL"
          type="url"
          // labelInfo={t(
          //   'senders.register.10dlc.campaign.features.optinMessage.description',
          // )}
          helperText="Check that provided link exists in at least 1 sample message"
        />
      ) : null}

      <Typography fontWeight="bold">
        {t('senders.register.10dlc.campaign.samples.header')}
      </Typography>
      <Typography level="body-md">
        {t('form.description.helperText', { count: 20 })}
      </Typography>
      <Typography level="body-md">
        Sample messages should reflect actual messages that will be sent to
        end-users
        <ul>
          <li>
            Use brackets to indicate templates fields in sample messages (e.g.
            [first name])
          </li>
          <li>
            Ensure your sample message is consistent with your use case and
            campaign description
          </li>
          <li>Provide opt-out text in sample message (e.g. STOP to opt-out)</li>
          <li>
            If embedded link or phone number options selected, include the
            applicable option(s) in at least one of your sample messages
          </li>
        </ul>
      </Typography>
      <FieldWrapper
        component={Textarea}
        label={`${t('senders.register.10dlc.campaign.sampleMessage.label')} #1`}
        name="campaign.samples.0"
        placeholder={
          "[Brand Name]: Enjoy 15% OFF on all products until the end of July! Don't miss out on this limited-time offer. Shop now and save big on your favorite items! [website link] Reply STOP to opt-out"
        }
        minRows={5}
        minLength={20}
        maxLength={501}
        required
      />
      <FieldWrapper
        component={Textarea}
        label={`${t('senders.register.10dlc.campaign.sampleMessage.label')} #2`}
        name="campaign.samples.1"
        placeholder={
          'Hi [first name], this is John with [Brand]! It’s time to set your follow-up appointment! Feel free to reply to this message, set an appointment online at [website link], or give us a call at +1(###)###-####!  Reply STOP to opt-out'
        }
        minRows={5}
        minLength={20}
        maxLength={501}
        required
      />

      <Typography fontWeight="bold">
        {t('senders.register.10dlc.campaign.features.subscriberHeader')}
      </Typography>
      <Stack gap={1}>
        <FieldWrapper
          row
          component={Checkboxes}
          id="optin"
          name="campaign.features"
          isDisabled={(option: CheckboxData) =>
            ['subscriberOptin'].includes(option.value)
          }
          items={useOptions.slice(0, 1)}
          sx={{ '& > .MuiCheckbox-checkbox': { position: 'relative' } }}
        />
        {values?.campaign?.features?.subscriberOptin && (
          <Stack
            sx={{
              p: 1,
              gap: 1,
            }}
          >
            <FieldWrapper
              component={TextField}
              name="campaign.optin.keywords"
              label={t(
                'senders.register.10dlc.campaign.features.optinKeywords.label',
              )}
              defaultValue="START"
              placeholder={t(
                'senders.register.10dlc.campaign.features.optinKeywords.placeholder',
              )}
              labelInfo={t(
                'senders.register.10dlc.campaign.features.optinKeywords.description',
              )}
              disabled
              required
            />
            <FieldWrapper
              component={Textarea}
              name="campaign.optin.message"
              label={t(
                'senders.register.10dlc.campaign.features.optinMessage.label',
              )}
              placeholder={`[Brand Name]: You are now opted in. Msg&data rates may apply. Reply HELP for help. Reply STOP to cancel.`}
              labelInfo={t(
                'senders.register.10dlc.campaign.features.optinMessage.description',
              )}
              minRows={5}
              minLength={20}
              maxLength={321}
              required
            />
          </Stack>
        )}
        <FieldWrapper
          row
          component={Checkboxes}
          name="campaign.features"
          items={useOptions.slice(1)}
          isDisabled={(option: CheckboxData) =>
            ['subscriberOptout', 'subscriberHelp'].includes(option.value)
          }
          sx={{ '& > .MuiCheckbox-checkbox': { position: 'relative' } }}
        />
        <FieldWrapper
          component={Checkbox}
          name="campaign.toc"
          size="sm"
          sx={{
            '& > .MuiCheckbox-checkbox': {
              position: 'relative',
            },
          }}
          label={
            <FormControl size="sm">
              <FormLabel required>
                {t('senders.register.10dlc.campaign.termsAndConditions.label')}
              </FormLabel>
              <FormHelperText>
                {t(
                  'senders.register.10dlc.campaign.termsAndConditions.description',
                )}
              </FormHelperText>
              <Link
                level="body-sm"
                href="https://api.ctia.org/wp-content/uploads/2019/07/190719-CTIA-Messaging-Principles-and-Best-Practices-FINAL.pdf"
                target="_blank"
                title={t(
                  'senders.register.10dlc.campaign.features.ageGated.linkTitle',
                )}
              >
                {t('readMore')}
              </Link>
            </FormControl>
          }
        />
      </Stack>
    </>
  );
}

export default CampaignDetails;
