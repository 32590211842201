import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../../../molecules/Menu/DropdownMenu';

export type TimeRange = 'last24hours' | 'last7days' | 'last30days' | 'custom';

interface SelectTimeRangeProps {
  timeRange: TimeRange;
  setTimeRange: (timeRange: TimeRange) => void;
}

function SelectTimeRange({ timeRange, setTimeRange }: SelectTimeRangeProps) {
  const { t } = useTranslation();
  return (
    <DropdownMenu
      button={t(`links.timerange.${timeRange}`)}
      onSelect={(e, item) => setTimeRange(item?.id as TimeRange)}
      width={150}
      items={[
        {
          id: 'last24hours',
          label: 'Last 24 hours',
          disabled: true,
        },
        {
          id: 'last7days',
          label: 'Last 7 days',
        },
        {
          id: 'last30days',
          label: 'Last 30 days',
          disabled: true,
        },
        {
          id: 'custom',
          label: 'Custom',
          disabled: true,
        },
      ]}
    />
  );
}
export default SelectTimeRange;
