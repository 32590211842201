import React, { HTMLInputTypeAttribute } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

interface InputWrapperProps<T, P>
  extends Omit<FieldProps<T | T[], any>, 'name'> {
  component: React.ComponentType<
    FieldProps<T | T[], FieldRenderProps<T | T[]>> & P
  >;
}
export function InputWrapper<T, P>({
  component: Component,
  input,
  meta,
  helperText,
  ...rest
}: InputWrapperProps<T | T[], P>) {
  const message = meta.error;
  return (
    <Component
      value={input.value}
      onChange={input.onChange}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && message ? message : helperText}
      disabled={rest.disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

interface FieldWrapperProps<T, P> extends FieldProps<T | T[], any> {
  label?: string | React.ReactNode;
  type?: HTMLInputTypeAttribute;
  component:
    | React.ComponentType<FieldProps<T | T[], FieldRenderProps<T | T[]>> & P>
    | React.FunctionComponent<P>;
  condition?: string;
}
/** Note: This is a generic component that can be used to wrap any input component
 * the input component must accept a value and onChange prop, and it must be
 * return the new value in the onChange callback
 * It can also be used as a template for creating other generic components.
 * @param component - the input component to wrap
 * @paaram name - the name of the field as seen in form values
 * @param label - the label of the field
 * @param type - the type of input, defaults to text
 */
function FieldWrapper<T, P>({
  label,
  type,
  component: Component,
  ...rest
}: FieldWrapperProps<T | T[], P>) {
  return (
    <Field
      label={label}
      type={type || 'text'}
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <InputWrapper key={rest.name} component={Component} {...props} />
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

export default FieldWrapper;
