import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../../../molecules/Menu/DropdownMenu';

export type LinkMetric = 'totalClicks' | 'uniqueClicks' | 'ctr';

interface SelectLinkMetricProps {
  metric: LinkMetric;
  setMetric: (metric: LinkMetric) => void;
}

function SelectLinkMetric({ metric, setMetric }: SelectLinkMetricProps) {
  const { t } = useTranslation();
  return (
    <DropdownMenu
      button={t(`links.fields.${metric}`)}
      onSelect={(e, item) => setMetric(item?.id as LinkMetric)}
      width={150}
      items={[
        {
          id: 'totalClicks',
          label: t('links.fields.totalClicks'),
        },
        // {
        //   id: 'uniqueClicks',
        //   label: t('links.fields.uniqueClicks'),
        // },
        // {
        //   id: 'ctr',
        //   label: t('links.fields.ctr'),
        // },
      ]}
    />
  );
}
export default SelectLinkMetric;
