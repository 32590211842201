import {
  Snackbar as SnackbarBase,
  SnackbarProps as SnackbarBaseProps,
  Stack,
} from '@mui/joy';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useMemo } from 'react';

const variants = {
  top: {
    y: '-100%',
  },
  bottom: {
    y: '100%',
  },
  left: {
    x: '-100%',
  },
  right: {
    x: '100%',
  },
  center: {
    opacity: 0,
    x: '-50%',
    y: '-50%',
  },
};

export type SnackbarProps = Omit<SnackbarBaseProps, 'onClose'> & {
  onClose: () => void;
  placement?: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'center' | 'bottom';
  };
  fullWidth?: boolean;
  children?: React.ReactNode;
};

function Snackbar({
  open,
  onClose,
  placement = {
    horizontal: 'center',
    vertical: 'bottom',
  },
  fullWidth,
  children,
  style,
  ...args
}: SnackbarProps) {
  const { horizontal, vertical } = placement;
  const variant = useMemo(() => {
    const isCenter = {
      horizontal: horizontal === 'center',
      vertical: vertical === 'center',
    };

    return {
      ...variants[isCenter.horizontal ? vertical : horizontal],
      ...(isCenter.horizontal && { x: '-50%' }),
      ...(isCenter.vertical && { y: '-50%' }),
    };
  }, [horizontal, vertical]);

  return (
    <AnimatePresence>
      {open && (
        <SnackbarBase
          slots={{
            root: motion.div,
          }}
          slotProps={{
            root: {
              initial: variant,
              animate: {
                opacity: 1,
                x: placement.horizontal === 'center' ? '-50%' : '0',
                y: placement.vertical === 'center' ? '-50%' : '0',
              },
              exit: variant,
              transition: {
                type: 'spring',
                damping: 15,
              },
            } as any,
          }}
          style={{
            zIndex: 'var(--joy-zIndex-snackbar)',
            position: 'fixed',
            width: fullWidth ? '100%' : 'fit-content',
            ...(placement.horizontal !== 'center'
              ? {
                  [placement.horizontal]: '0',
                }
              : {
                  left: '50%',
                }),
            ...(placement.vertical !== 'center'
              ? {
                  [placement.vertical]: '0',
                }
              : {
                  top: '50%',
                }),
            ...style,
          }}
          open={open}
          onClose={onClose}
          {...args}
        >
          <Stack
            sx={{
              flex: 1,
              boxSizing: 'border-box',
              pb: '5px',
            }}
          >
            {children}
          </Stack>
        </SnackbarBase>
      )}
    </AnimatePresence>
  );
}

export default Snackbar;
