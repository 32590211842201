/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { EdgeProps, getSmoothStepPath, EdgeLabelRenderer } from 'reactflow';
import { useTranslation } from 'react-i18next';
import {
  WorkflowEdge as IWorkflowEdge,
  WorkflowEdgeType,
  EdgeValidation,
} from '@sakari-io/sakari-typings';
import { Stack } from '@mui/joy';
import { useSelector, useDispatch } from 'react-redux';
import { logger } from '@sakari-io/sakari-components';
import DeleteHoverButton from '../../DeleteHoverButton';
import Dialog from '../../../../../components/molecules/Dialog';
import getMetrics from '../../hooks/getMetrics';
import { Mode, actions } from '../../../../../redux/reducers/workflow';
import BranchLabel from '../BranchLabel';
import AddNodeButton from '../AddNodeButton';

export interface WorkflowEdgeProps extends EdgeProps {
  data?: {
    edge: IWorkflowEdge;
    source: string;
    target?: string;
    mode: Mode;
    rule: EdgeValidation;
  };
}

const getBranchValue = (edge: IWorkflowEdge, rule?: EdgeValidation) => {
  switch (edge.type) {
    case WorkflowEdgeType.Error:
      return '⚠️';
    case WorkflowEdgeType.Timeout:
      return '⏰';
    default:
      return `${
        edge?.value !== undefined ? edge?.value : rule?.value?.default || ''
      }`;
  }
};

export default function WorkflowEdge(props: WorkflowEdgeProps) {
  const dispatch = useDispatch();

  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    data,
  } = props;

  const { t } = useTranslation('workflows');

  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const { nodes, edges, version, metricType, metrics } = useSelector(
    (state: any) => state.workflow.present,
  );

  const [deletableEdge, setDeletableEdge] = useState<boolean | undefined>(
    false,
  );

  const edgeMetrics = getMetrics(
    metrics?.paths,
    metrics?.total ?? 0,
    version,
    metricType,
    undefined,
    data?.edge?.id,
  );

  useEffect(() => {
    // const sourceNode = nodes.find((n: any) => n.id === data?.edge?.source);
    // const edgesFromSourceNode = edges.filter(
    //   (e: any) => e.source === sourceNode?.id,
    // );

    // const meetsEdgeRequirements = (node: Node) => {
    //   const numEdgeCondition = node?.data?.type?.validation?.numEdgeCondition;
    //   const numEdges = node?.data?.type?.validation?.numEdges;

    //   return (
    //     numEdgeCondition === 'at least' &&
    //     edgesFromSourceNode.length > (numEdges || 0)
    //   );
    // };

    if (data) {
      const isDeletable = data.edge?.type === 'standard' && data.rule?.multiple;
      // const isDeletable =
      //   data.edge?.type === 'standard' &&
      //   data.rule?.multiple &&
      //   meetsEdgeRequirements(sourceNode);
      setDeletableEdge(isDeletable);
    }
  }, [nodes, edges, data]);

  if (!data) {
    logger.info('no data found for edge', id);
    return null;
  }

  const { mode, edge, rule } = data;

  const onClose = () => {
    setOpen(false);
    setHover(false);
  };

  const addLabel =
    edge?.value !== undefined ||
    rule?.value?.mandatory ||
    [WorkflowEdgeType.Timeout, WorkflowEdgeType.Error].includes(edge.type);

  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 20,
    centerY: sourceY + (targetY - sourceY) / 6,
  });

  const addButton = !!edge.target;

  const verticalOffset = addLabel
    ? (targetY - sourceY) / 3
    : (targetY - sourceY) / 6;

  const horizontalOffset = (targetX - sourceX) / 2;

  const baseTransform = `translate(-50%, -50%) translate(${edgeCenterX}px,${edgeCenterY}px)`;
  const transform = `${baseTransform} translateY(${verticalOffset}px) translateX(${horizontalOffset}px)`;

  const hoverStyle = hover
    ? { stroke: 'var(--joy-palette-primary-500)', strokeWidth: 2 }
    : {};

  return (
    <>
      <path
        id={id}
        d={edgePath}
        markerEnd={markerEnd}
        style={{
          fill: 'none',
          stroke:
            edge?.type === 'error'
              ? 'var(--joy-palette-danger-300)'
              : 'var(--joy-palette-neutral-300)',
          strokeWidth: 1,

          ...hoverStyle,
        }}
      />

      {/* 
    thick transparent path to make thin edge easier to hover 
    */}
      <path
        d={edgePath}
        style={{
          fill: 'none',
          stroke: 'transparent',
          strokeWidth: 10,
          pointerEvents: 'stroke',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />

      <EdgeLabelRenderer>
        {addLabel || addButton ? (
          <div
            style={{
              position: 'absolute',
              transform,
              padding: 10,
              borderRadius: 5,
              fontSize: 12,
              fontWeight: 700,
              zIndex: 10,
              pointerEvents: 'all',
            }}
          >
            <Stack spacing={1} alignItems="center">
              {addLabel ? (
                <BranchLabel
                  value={getBranchValue(edge)}
                  onChange={(v) =>
                    dispatch(
                      actions.updateEdgeValue({ edgeId: edge.id, value: v }),
                    )
                  }
                  editable={
                    mode === Mode.EDIT && rule?.value?.editable !== false
                  }
                  metrics={edgeMetrics}
                />
              ) : null}

              <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={{
                  // zIndex: 100,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <DeleteHoverButton
                  onClick={() => {
                    if (mode === Mode.EDIT && deletableEdge) {
                      setOpen(true);
                    }
                  }}
                  size="xs"
                  ignorePosition
                  sx={{
                    visibility:
                      mode === Mode.EDIT && deletableEdge && hover
                        ? 'visible'
                        : 'hidden',
                    backgroundColor: 'var(--joy-palette-common-white)',
                  }}
                />
                <Dialog
                  open={open}
                  onClose={onClose}
                  title={t('edit.delete.edge')}
                  primaryAction={() => {
                    dispatch(actions.deleteEdge(edge.id));
                  }}
                  primaryTitle={t('edit.delete.label')}
                  cancelAction={onClose}
                  cancelTitle={t('cancel')}
                  color="danger"
                >
                  {t('edit.delete.deleteEdgeConfirm')}
                </Dialog>
              </div>
              {edge?.target && mode === Mode.EDIT ? (
                <AddNodeButton edge={edge} />
              ) : null}
            </Stack>
          </div>
        ) : null}
      </EdgeLabelRenderer>
    </>
  );
}
