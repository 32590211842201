import React, { useContext, useState } from 'react';
import { Box, Button, IconButton, Stack, ToggleButtonGroup } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CallHistory as CallHistoryType } from '@sakari-io/sakari-typings';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { subMonths, subWeeks } from 'date-fns';
import { DateRangePicker } from '@sakari-io/sakari-components';
import Container from '../../ui/templates/Container';
import CallsGrid, { CallFilter } from '../../ui/organisms/datagrids/CallsGrid';
import PageHeader from '../../ui/organisms/headers/PageHeader';

import ContactDetails from '../../ui/organisms/drawers/ContactDetails';
import { AccountContext } from '../../contexts/account.context';
import Recording from './CallDetails/Recording';

function CallHistory() {
  const { account } = useContext(AccountContext);

  const [type, setType] = useState<'all' | 'missed' | 'voicemails'>('all');

  const [filter, setFilter] = useState<CallFilter>({
    direction: [],
    userId: [],
    groupId: [],
    contactId: [],
    phonenumberId: [],
    orderBy: 'created-desc',
    status: undefined,
    from: null,
    to: null,
  });

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [contactId, setContactId] = useState('');

  const handleUpdateFilter = (filter: Partial<CallFilter>) => {
    setFilter((prev) => ({ ...prev, ...filter }));
  };

  const setTypeFilter = (value: 'missed' | 'voicemails' | 'all') => {
    setType(value);
    switch (value) {
      case 'voicemails':
        handleUpdateFilter({ status: 'missed', recording: true });
        break;
      case 'missed':
        handleUpdateFilter({ status: 'missed', recording: undefined });
        break;
      case 'all':
      default:
        handleUpdateFilter({ status: undefined, recording: undefined });
        break;
    }
  };

  const showContact = (row: any) => {
    if (row.contact) {
      setContactId(row.contact.id);
      setDrawerOpen(true);
    }
  };

  const [currentRecording, setCurrentRecording] = useState<
    CallHistoryType | undefined
  >(undefined);
  const [displayAudioPlayer, setDisplayAudioPlayer] = useState(false);

  const playAudio = (row: CallHistoryType) => {
    if (currentRecording?.id === row.id) {
      setDisplayAudioPlayer(false);
      setCurrentRecording(undefined);
    } else {
      setCurrentRecording(row);
      setDisplayAudioPlayer(true);
    }
  };

  const closeAudioPlayer = () => {
    setDisplayAudioPlayer(false);
    setCurrentRecording(undefined);
  };

  return (
    <>
      <PageHeader title="Call History" />
      <Container spacing={2}>
        <Stack
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ToggleButtonGroup
            value={type}
            onChange={(evt: any, val: 'all' | 'missed' | 'voicemails' | null) =>
              setTypeFilter(val || 'all')
            }
          >
            <Button value="all">All</Button>
            <Button value="missed">Missed Calls</Button>
            <Button value="voicemails">Voicemails</Button>
          </ToggleButtonGroup>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <DateRangePicker
              value={filter.from ? [filter.from, filter.to] : undefined}
              onChange={([from, to]: Date[]) =>
                setFilter((prev) => ({ ...prev, from, to }))
              }
              shortcuts={[
                {
                  label: 'Last Week',
                  getValue: () => {
                    return [subWeeks(new Date(), 1), new Date()];
                  },
                },
                {
                  label: 'Last Month',
                  getValue: () => {
                    return [subMonths(new Date(), 1), new Date()];
                  },
                },
              ]}
            />
          </Stack>
        </Stack>
        <CallsGrid
          filter={filter}
          showContact={showContact}
          playAudio={playAudio}
        />
      </Container>
      {currentRecording && displayAudioPlayer && (
        <Box
          sx={{
            display: displayAudioPlayer ? 'flex' : 'none',
            width: 360,
            height: 78,
            backgroundColor: 'var(--joy-palette-voice-solidBg)',
            borderRadius: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 'var(--joy-zIndex-popup)',
            marginLeft: 'calc(50% - 290px)',
            position: 'absolute',
            bottom: 16,
            borderBottom: `1px solid ${'var(--joy-palette-voice-softHoverBg)'}}`,
          }}
        >
          <Recording
            callId={currentRecording.id}
            type={currentRecording.voicemail ? 'voicemail' : 'recording'}
            onClose={closeAudioPlayer}
            autoClose
            theme="dark"
            auto
          />
          <IconButton
            variant="soft"
            color="voice"
            onClick={() => {
              setDisplayAudioPlayer(!displayAudioPlayer);
              setCurrentRecording(undefined);
            }}
          >
            <FontAwesomeIcon icon={faClose} color="white" />
          </IconButton>
        </Box>
      )}
      {account && (
        <ContactDetails
          account={account}
          contactId={contactId}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      )}
    </>
  );
}

export default CallHistory;
