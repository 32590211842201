import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import _ from 'lodash';
import {
  Box,
  Stack,
  Typography,
  Divider,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {
  IFileUpload,
  Contact,
  ShortenedLink,
  Account,
  AccountAttribute,
  Template,
  WorkflowProperty,
  WorkflowDataReference,
} from '@sakari-io/sakari-typings';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SmsService } from '@sakari-io/sakari-common';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { logger } from '@sakari-io/sakari-components';
import { CLEAR_EDITOR_COMMAND, LexicalEditor } from 'lexical';
import {
  faEllipsisH,
  faHeadSideBrain,
  faImage,
  faLink,
} from '@fortawesome/pro-solid-svg-icons';
import MediaThumbnail from '../../../molecules/MediaThumbnail';
import uploadFile from '../../../../api/direct';
import TemplateMenuButton from '../../ButtonContexts/TemplateMenuButton';
import handlebars from '../../../../utils/handlebars';
import ATTRIBUTES from './List/constants';
import AttributeList from './List';
import InputControl from '../../../molecules/InputControl';
import OverFlowCell from '../../../molecules/OverFlowCell';
import GeneratorDialog from '../../Dialogs/GeneratorDialog';
import DropdownMenu from '../../../molecules/Menu/DropdownMenu';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import CreateLinkDialog from '../../Dialogs/CreateLinkDialog';
import InsertLinkDialog from '../../Dialogs/InsertLinkDialog';
import Popper from '../../../templates/Popper';
import { useGetAttributesByTypeQuery } from '../../../../api';
import SendButton from './SendButton';
import Editor from '../../../../components/molecules/Editor';
import ActionsPlugin from '../../../../components/molecules/Editor/plugins/ActionsPlugin';
import { INSERT_VARIABLE_COMMAND } from '../../../../components/molecules/Editor/plugins/TemplatingPlugin';
import WorkflowAttributesList from './WorkflowAttributesList';

type ComposerFeatures = 'upload' | 'schedule' | 'link' | 'template';

export type ComposerResponse = {
  message: string;
  files: IFileUpload[];
  sendAt?: Date;
};

interface ComposerProps {
  account: Account;
  contact?: Contact;
  onChange: (newVal: ComposerResponse) => any;
  onSend?: (message: string, files: IFileUpload[], sendAt?: Date) => any;
  value: ComposerResponse;
  onFocus?: (value: boolean) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: (value?: any) => any;
  disabledFeatures?: ComposerFeatures[];
  hideTemplates?: boolean;
  rowRange?: [number, number];
  usePropertiesMenu?: boolean;
  properties?: WorkflowProperty[];
  autofocus?: boolean;
  onHighlight?: (nodeId: string) => void;
}

const SEGMENT_LIMIT = 10;

function Composer({
  account,
  contact,
  onChange,
  onSend,
  value,
  onFocus,
  disabled,
  helperText,
  error,
  onBlur,
  disabledFeatures,
  rowRange,
  hideTemplates,
  usePropertiesMenu,
  properties,
  autofocus,
  onHighlight,
  ...rest
}: ComposerProps) {
  const { t } = useTranslation();
  const linksButtonRef = useRef<HTMLButtonElement>(null);
  const [localValue, setLocalValue] = useState<ComposerResponse>(value);
  const [template, setTemplate] = useState<Template | null>(null);

  const { message, files } = localValue;

  const debouncedOnChange = useCallback(_.debounce(onChange, 300), [onChange]);

  useEffect(() => {
    debouncedOnChange(localValue);
  }, [localValue, debouncedOnChange]);

  const onSectionChanged = (key: 'message' | 'files' | 'sendAt', val: any) => {
    setLocalValue((prev) => ({ ...prev, [key]: val }));
    onBlur?.();
  };

  const { getAccessTokenSilently } = useAuth0();

  const { data: attributes, isFetching } = useGetAttributesByTypeQuery(
    account
      ? {
          accountId: account.id,
          request: {
            type: 'contacts',
          },
        }
      : skipToken,
  );

  const allAttributes = useMemo(() => {
    const attrs: any = [];

    if (contact) {
      attrs.push(...ATTRIBUTES.filter((a) => _.get(contact, a.name, false)));
    } else {
      attrs.push(...ATTRIBUTES);
    }

    if (!isFetching && attributes?.data) {
      let customAttributes = attributes.data;

      // if a contact is passed, filter the attributes data to only include the attributes that the contact has values for else return all

      if (contact?.attributes) {
        const contactAttributes = _.keys(contact?.attributes || {});

        customAttributes = attributes.data.filter((a) => {
          if (contactAttributes) {
            return contactAttributes.includes(a.name);
          }
          return true;
        });
      }

      attrs.push(...customAttributes);
    }

    return attrs;
  }, [isFetching, attributes, contact]);

  const [dialog, setDialog] = useState<
    | 'generator'
    | 'schedule'
    | 'customSchedule'
    | 'createLink'
    | 'insertLink'
    | 'template'
    | null
  >(null);
  const [isTemplateSaving, setIsTemplateSaving] = useState<boolean>(false);
  useState<boolean>(false);

  const [hasErrors, setHasErrors] = useState(false);

  const additionalActions = [
    { key: 'template' },
    ...(disabledFeatures?.includes('link')
      ? []
      : [
          {
            key: 'insertLink',
            ref: linksButtonRef,
            label: t('Insert Link'),
            startIcon: <FontAwesomeIcon icon={faLink} />,
            onMouseUp: () => {
              setDialog('insertLink');
            },
          },
        ]),

    {
      key: 'generator',
      label: t('generator.title'),
      startIcon: <FontAwesomeIcon icon={faHeadSideBrain} />,
      onMouseUp: () => setDialog('generator'),
    },
  ];

  const dispatch = useAppDispatch();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const uploadRes: {
      file: IFileUpload;
      upload: Promise<IFileUpload>;
    }[] = acceptedFiles.map((file: File) => {
      const reader = new FileReader();
      const id = uuidv4();
      const fileResult = {
        id,
        name: file.name,
        type: file.type,
        url: '',
        isUploaded: false,
      };

      return {
        file: fileResult,
        upload: new Promise((resolve) => {
          reader.onabort = () => logger.info('file reading was aborted');
          reader.onerror = () => logger.info('file reading has failed');
          reader.onload = async () => {
            // // Do whatever you want with the file contents
            const binaryStr = reader.result as any;

            uploadFile(await getAccessTokenSilently(), file.type, binaryStr)
              .then((link) => {
                setHasErrors(false);
                resolve({ ...fileResult, isUploaded: true, url: link });
              })
              .catch((err) => {
                logger.info('Unable to upload file', err.message);
                setHasErrors(true);
                resolve({ ...fileResult, isUploaded: true, url: '' });
                dispatch(
                  showToast({
                    severity: 'error',
                    message: t('uploadError'),
                  }),
                );
              });
          };
          reader.readAsArrayBuffer(file);
        }),
      };
    });

    onSectionChanged('files', [
      ...(files || []),
      ...uploadRes.map(({ file }) => file),
    ]);

    Promise.all(uploadRes.map(({ upload }: any) => upload)).then(
      (uploads: IFileUpload[]) =>
        onSectionChanged('files', [...(files || []), ...uploads]),
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: false,
    disabled: disabled || disabledFeatures?.includes('upload'),
  });

  const smsCounts = SmsService.smsCount(
    message,
    undefined,
    undefined,
    files?.length ? 'mms' : 'sms',
  );
  const getSegmentMessage = () => {
    const segments = smsCounts.segments || 0;
    if (segments > SEGMENT_LIMIT) {
      return 'Over 10 segment limit';
    }
    return `${segments} segment${segments > 1 ? 's' : ''}`;
  };

  const onTemplatedSelected = (template: Template) => {
    const { template: message, media } = template as any;
    if (!message) return;

    if (contact) {
      const handlebarTemplate = handlebars.compile(message);
      setLocalValue({
        ...value,
        message: handlebarTemplate(contact),
        files: media,
      });
    } else {
      setLocalValue({ ...value, message, files: media });
    }
    onBlur?.();
  };

  const handleSelectTag = (
    tag: AccountAttribute | WorkflowDataReference,
    editor: LexicalEditor,
    contact?: any,
  ) => {
    let id = tag.name;
    let value;

    editor.focus(undefined, {
      defaultSelection: 'rootEnd',
    });

    switch (tag.type) {
      case 'contacts':
        // if source is custom get value from contact.attribute object
        if (contact?.attributes && contact?.attributes[tag.name]) {
          value = [contact.attributes[tag.name]];
        }
        break;
      case 'output':
        id = tag.type;
        value = [tag.nodeId, tag.path];
        break;
      case 'workflow':
        id = tag.type;
        value = [tag.name];
        break;
      default:
        break;
      // TODO:  handle integration attributes in the future
    }
    return editor.dispatchCommand(INSERT_VARIABLE_COMMAND, {
      name: id,
      params: value || [],
    });
  };

  const resetState = () => {
    setLocalValue({ message: '', files: [] });
    setDialog(null);
    setIsTemplateSaving(false);
  };

  const handleOnSchedule = (date: Date) => {
    onChange({ ...localValue, sendAt: date });

    if (onSend) {
      onSend(message, files, date);
    }

    setDialog(null);
    resetState();
  };

  const handleOnSend = () => {
    if (onSend) {
      onSend(message, files);
    }
    resetState();
  };

  return (
    <InputControl error={error} helperText={helperText} {...rest}>
      <Stack
        sx={{
          p: 2,
          border: 'solid 1px',
          borderColor: 'divider',
          borderRadius: '8px',
          height: 'fit-content',
          background: 'var(--joy-palette-background-surface)',
        }}
      >
        <Editor
          value={message}
          disabled={disabled || isTemplateSaving}
          initialValue={template?.template}
          onChange={(value) => {
            // logger.info('Editor changed', value);
            onSectionChanged('message', value);
            onBlur?.(value);
          }}
          rowRange={rowRange}
          autofocus={autofocus}
          onHover={onHighlight}
          plugins={{
            end: (
              <Stack flex={1}>
                <input id="file-upload" {...getInputProps()} />
                {files?.length && !hasErrors ? (
                  <Stack direction="row" sx={{ overflow: 'scroll' }}>
                    {files.map((file: IFileUpload) => {
                      return (
                        <Box key={file.id} sx={{ mr: 0.8 }}>
                          <MediaThumbnail
                            size={128}
                            file={file}
                            onDelete={() =>
                              onSectionChanged(
                                'files',
                                files.filter((f) => f.id !== file.id),
                              )
                            }
                          />
                        </Box>
                      );
                    })}
                  </Stack>
                ) : null}
                <ActionsPlugin
                  renderActions={(editor: LexicalEditor) => (
                    <Stack>
                      <Stack
                        sx={{
                          flexFlow: 'row nowrap',
                          justifyContent: 'space-between',
                          gap: 0.5,
                          width: '100%',
                        }}
                      >
                        <Box
                          id="composer-actions"
                          sx={{
                            flex: 1,
                          }}
                          {...getRootProps()}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {isDragActive ? (
                            <Typography level="body-sm" alignSelf="center">
                              {t('dragFile')}
                            </Typography>
                          ) : (
                            <OverFlowCell
                              itemWidth={70}
                              containerSx={{
                                gap: 0.5,
                              }}
                              renderOverflow={(overflowData) => {
                                return (
                                  <DropdownMenu
                                    button={
                                      <IconButton
                                        size="smRound"
                                        disabled={disabled}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                      </IconButton>
                                    }
                                    items={overflowData}
                                  />
                                );
                              }}
                              data={additionalActions}
                              renderItem={(item: any) => {
                                if (item.key === 'template' && hideTemplates)
                                  return null;

                                if (item.key === 'template') {
                                  return (
                                    <TemplateMenuButton
                                      key="TemplateMenuButton"
                                      onSelect={(item: Template) => {
                                        onTemplatedSelected(item);
                                        setTemplate(item);
                                      }}
                                      disabled={disabled || isTemplateSaving}
                                      message={message}
                                    />
                                  );
                                }

                                return (
                                  <Tooltip
                                    key={item.label}
                                    title={item.label}
                                    size="sm"
                                  >
                                    <IconButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        item.onMouseUp(e);
                                      }}
                                      disabled={disabled}
                                      ref={item.ref}
                                    >
                                      {item.startIcon}
                                    </IconButton>
                                  </Tooltip>
                                );
                              }}
                            >
                              {disabledFeatures?.includes('upload') ? null : (
                                <Tooltip title={t('upload')} size="sm">
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      open();
                                    }}
                                    disabled={disabled}
                                  >
                                    <FontAwesomeIcon icon={faImage} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </OverFlowCell>
                          )}
                        </Box>
                        <Stack
                          direction="row"
                          sx={{
                            flex: 1,
                            gap: 1,
                            justifyContent: 'flex-end',
                            '& > *': {
                              justifyContent: 'flex-end',
                            },
                          }}
                        >
                          {usePropertiesMenu ? (
                            <WorkflowAttributesList
                              onSelect={(attr: WorkflowDataReference) =>
                                handleSelectTag(attr as any, editor, contact)
                              }
                              properties={properties || []}
                              disabled={disabled}
                            />
                          ) : (
                            <OverFlowCell
                              showMin={0}
                              data={allAttributes}
                              showOverflow
                              itemWidth={100}
                              renderOverflow={() => (
                                <AttributeList
                                  onSelect={(attr: AccountAttribute) =>
                                    handleSelectTag(attr, editor, contact)
                                  }
                                  disabled={disabled}
                                  account={account}
                                  isOptionDisabled={(
                                    attr: AccountAttribute,
                                  ) => {
                                    if (contact) {
                                      return (
                                        !_.get(contact, attr.name, false) &&
                                        !_.get(
                                          contact,
                                          `attributes.${attr.name}`,
                                          false,
                                        )
                                      );
                                    }
                                    return false;
                                  }}
                                />
                              )}
                              renderItem={(attr: any) => (
                                <Chip
                                  key={attr.id}
                                  color="primary"
                                  onClick={() =>
                                    handleSelectTag(attr, editor, contact)
                                  }
                                  size="sm"
                                  disabled={disabled}
                                  id={attr.name}
                                >
                                  {attr.label}
                                </Chip>
                              )}
                              containerSx={{
                                justifyContent: 'flex-end',
                              }}
                            />
                          )}
                        </Stack>
                      </Stack>
                      <Divider />
                      <Stack
                        sx={{
                          pt: 2,
                          flexFlow: 'row nowrap',
                          justifyContent: 'space-between',
                          gap: 1,
                          width: '100%',
                        }}
                      >
                        {!isTemplateSaving && (
                          <>
                            <Box flex={1} />
                            <Stack alignItems="flex-end">
                              <Typography
                                level="body-sm"
                                textColor={
                                  smsCounts.segments > SEGMENT_LIMIT
                                    ? 'danger.plainColor'
                                    : 'text.secondary'
                                }
                                sx={{
                                  textAlign: 'end',
                                  hyphens: 'auto',
                                }}
                              >
                                {getSegmentMessage()}
                              </Typography>
                              <Typography
                                textAlign="right"
                                level="body-sm"
                                textColor="text.secondary"
                              >
                                {smsCounts.segments > SEGMENT_LIMIT
                                  ? 0
                                  : smsCounts.charsLeft}{' '}
                                {t('remainChar')}
                              </Typography>
                            </Stack>
                          </>
                        )}
                        {onSend ? (
                          <SendButton
                            disabled={
                              disabled ||
                              !message?.length ||
                              isTemplateSaving ||
                              smsCounts.segments > 10
                            }
                            onSend={() => {
                              handleOnSend();
                              editor.dispatchCommand(
                                CLEAR_EDITOR_COMMAND,
                                undefined,
                              );
                            }}
                            onSchedule={(date: Date) => {
                              handleOnSchedule(date);
                              editor.dispatchCommand(
                                CLEAR_EDITOR_COMMAND,
                                undefined,
                              );
                            }}
                          />
                        ) : null}
                      </Stack>
                      <GeneratorDialog
                        open={dialog === 'generator'}
                        setOpen={() => setDialog(null)}
                        onHide={(result?: string) => {
                          if (result) {
                            editor.dispatchCommand(
                              CLEAR_EDITOR_COMMAND,
                              undefined,
                            );
                            setTemplate({ template: result });
                          }
                        }}
                      />
                      <Popper
                        open={dialog === 'insertLink'}
                        onOpenClose={() => setDialog(null)}
                        anchorEl={linksButtonRef.current}
                        placement="top-start"
                        style={{
                          zIndex: 9000,
                        }}
                      >
                        <InsertLinkDialog
                          onSelect={(result?: ShortenedLink) => {
                            if (result) {
                              editor.dispatchCommand(INSERT_VARIABLE_COMMAND, {
                                name: 'track',
                                escaped: false,
                                params: [result.id],
                              });
                            }
                            setDialog(null);
                          }}
                          onCreateLink={() => {
                            setDialog('createLink');
                          }}
                        />
                      </Popper>
                      <CreateLinkDialog
                        open={dialog === 'createLink'}
                        onClose={(result?: ShortenedLink) => {
                          if (result) {
                            editor.dispatchCommand(INSERT_VARIABLE_COMMAND, {
                              name: 'track',
                              escaped: false,
                              params: [result.id],
                            });
                          }
                          setDialog(null);
                        }}
                      />
                    </Stack>
                  )}
                />
              </Stack>
            ),
          }}
        />
      </Stack>
    </InputControl>
  );
}

export default Composer;
