import React, { useEffect, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import _ from 'lodash';
import { useCookies } from 'react-cookie';
import { GridColDef } from '@mui/x-data-grid';
import { Tooltip, IconButton, ListItem, Stack, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import OverlayPopup from '../../../templates/OverlayPopup';
import AppMenu from '../../Menu';
import SearchBar from '../../inputs/SearchBar';
import ColumnItem from './ColumnItem';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

interface ColumnVisibilityProps {
  cookie: string;
  columns: GridColDef[];
}

function ColumnVisibility({ cookie, columns }: ColumnVisibilityProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies([cookie]);

  const [visibility, setVisibility] = useState(cookies[cookie]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const visibleColumns = useMemo(
    () => columns.filter((col) => visibility?.[col.field]),
    [columns, visibility],
  );
  const hiddenColumns = useMemo(
    () => columns.filter((col) => !visibility?.[col.field]),
    [columns, visibility],
  );

  useEffect(() => {
    setVisibility(initialVisibility());
  }, []);

  const initialVisibility = () => {
    const initialVisibility = columns.reduce((acc: any, col) => {
      acc[col.field] =
        visibility?.[col.field] !== undefined ? visibility[col.field] : true;
      return acc;
    }, {});
    return initialVisibility;
  };
  useEffect(() => {
    if (!cookies[cookie]) {
      setCookie(cookie, visibility);
    }

    if (!_.isEqual(visibility, cookies[cookie])) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [visibility]);

  const handleClose = () => {
    setOpen(false);
    setVisibility(cookies[cookie]);
  };

  const onColumnChecked = (value: { [key: string]: boolean }) => {
    setVisibility(_.assign({}, visibility, value));
  };

  const handleApply = () => {
    setCookie(cookie, visibility);
    dispatch(
      showToast({
        message: t('feedback.applyChangesSuccess'),
        severity: 'success',
      }),
    );
    setVisibility(initialVisibility());
    setOpen(false);
  };

  return (
    <OverlayPopup
      open={open}
      onOpenClose={setOpen}
      placement="bottom-end"
      renderButton={(setAnchorEl, onClick) => (
        <Tooltip arrow placement="top" title="Settings">
          <IconButton onClick={onClick} size="smRound" ref={setAnchorEl}>
            <FontAwesomeIcon icon={solid('gear')} />
          </IconButton>
        </Tooltip>
      )}
      renderPopup={() => (
        <AppMenu
          header={
            <SearchBar
              value={searchTerm}
              onChange={setSearchTerm}
              sx={{ mb: 1 }}
            />
          }
          variant="outlined"
          size="sm"
          sx={{
            height: '50vh',
            '.MuiBox-root:last-of-type': {
              borderTop: '1px solid var(--joy-palette-divider)',
            },
          }}
          footer={
            <Stack
              sx={{
                flexFlow: 'row wrap',
                pt: 1,
                gap: 1,
                '& > *': { flex: 1 },
              }}
            >
              <Button onClick={handleApply} disabled={!isFormDirty}>
                {t('action.apply')}
              </Button>
              <Button variant="outlined" color="neutral" onClick={handleClose}>
                {t('action.cancel')}
              </Button>
            </Stack>
          }
        >
          {visibleColumns.length > 0 ? (
            <>
              <ListItem variant="soft">{t('grid.visible')}</ListItem>
              {visibleColumns.map((c) => (
                <ColumnItem
                  key={c.field}
                  value={c}
                  visibility={visibility}
                  onChange={onColumnChecked}
                />
              ))}
            </>
          ) : null}
          {hiddenColumns.length > 0 ? (
            <>
              <ListItem variant="soft">{t('grid.hidden')}</ListItem>
              {hiddenColumns.map((c) => (
                <ColumnItem
                  key={c.field}
                  value={c}
                  visibility={visibility}
                  onChange={onColumnChecked}
                />
              ))}
            </>
          ) : null}
        </AppMenu>
      )}
    />
  );
}

export default ColumnVisibility;
