import React from 'react';
import { MenuList } from '@mui/joy';
import {
  WorkflowProperty,
  WorkflowDataReference,
} from '@sakari-io/sakari-typings';
import { useDispatch } from 'react-redux';
import HoverMenuItem from './HoverMenuItem';
import OverlayPopup from '../../../../ui/templates/OverlayPopup';
import { actions } from '../../../../redux/reducers/workflow';
import { iconMap, labelMap } from './utils';

interface PropertyTypeMenuProps {
  onSelectType: (type: string) => void;
  inputRef: any;
  open: boolean;
  onClose: () => void;
  onChange: (value: WorkflowDataReference) => void;
  properties: WorkflowProperty[];
  fullWidth?: boolean;
}

function PropertyTypeMenu({
  onSelectType,
  inputRef,
  open,
  onClose,
  properties,
  onChange,
  fullWidth,
}: PropertyTypeMenuProps) {
  const dispatch = useDispatch();

  return (
    <OverlayPopup
      buttonRef={inputRef}
      fullWidth={fullWidth}
      open={open}
      onOpenClose={onClose}
      renderPopup={() => (
        <MenuList>
          <HoverMenuItem
            icon={iconMap.contact.icon}
            label={labelMap.contact}
            onSelect={() => onSelectType('contact')}
            color={iconMap.contact.color}
          />
          {properties?.length > 0 && (
            <HoverMenuItem
              icon={iconMap.workflow.icon}
              label={labelMap.workflow}
              onSelect={() => onSelectType('workflow')}
              color={iconMap.workflow.color}
            />
          )}
          <HoverMenuItem
            icon={iconMap.output.icon}
            label={labelMap.output}
            onSelect={() => {
              onSelectType('output');
              dispatch(
                actions.enterSelectionMode({
                  callback: (v) => {
                    onChange(v);
                  },
                }),
              );
            }}
            color={iconMap.output.color}
          />
        </MenuList>
      )}
    />
  );
}

export default PropertyTypeMenu;
