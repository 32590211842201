import { Box, Stack, Typography, Button, Chip } from '@mui/joy';
import { IDable } from '@sakari-io/sakari-typings';
import React, { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ImportIcon } from '../../../../assets/icons';
import SelectableCard from '../../../../ui/atoms/inputs/SelectableCard';
import { AccountContext } from '../../../../contexts/account.context';
import Container from '../../../../ui/templates/Container';

interface Option extends IDable {
  label: string;
  description?: string;
  icon?: ReactNode;
  link?: string;
  disabled?: boolean;
}

function ModeSelect() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const isTrial = account?.plan.name === 'Trial';
  const isPassthrough = account?.plan.name === 'passthrough';
  const upgradeRequired = isTrial || isPassthrough;

  const options: Option[] = [
    {
      id: 'buy',
      icon: (
        <img
          src="https://assets.sakari.io/images/sakari-logo-only.png"
          alt="Sakari"
          height="30px"
        />
      ),
      label: t('addSenders.phonenumber.buy.label'),
      description: t('addSenders.phonenumber.buy.description'),
      link: './buy',
      disabled: upgradeRequired,
    },
    {
      id: 'bring',
      icon: '📲',
      label: t('addSenders.phonenumber.byo.label'),
      description: t('addSenders.phonenumber.byo.description'),
      link: './byo',
      disabled: upgradeRequired,
    },
    {
      id: 'import',
      icon: <img src={ImportIcon} alt="import" height="30px" />,
      label: t('addSenders.phonenumber.import.label'),
      description: t('addSenders.phonenumber.import.description'),
      link: './import',
      disabled: false,
    },
  ];
  const [selected, setSelected] = useState<Option>(
    upgradeRequired ? options[2] : options[0],
  );

  return (
    <Container>
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          overflow: 'auto',
        }}
      >
        <Typography level="title-lg">
          {t('addSenders.phonenumber.importOrBring')}
        </Typography>
        <Stack direction="column" spacing={1} width="500px">
          {options.map((option) => {
            const isSelected = selected?.id === option.id;
            return (
              <SelectableCard
                key={option.id}
                checked={isSelected}
                onChange={() => setSelected(option)}
                disabled={option.disabled}
                height={64}
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 2,
                  padding: '8px 16px',
                }}
                label={
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: '20px',
                        fontSize: 20,
                        textAlign: 'center',
                      }}
                    >
                      {option.icon}
                    </Box>
                    <Typography
                      sx={{
                        color: isSelected ? 'primary.main' : 'text.primary',
                      }}
                      fontWeight="bold"
                    >
                      {option.label}
                    </Typography>
                    <Typography
                      level="body-xs"
                      sx={{
                        color: isSelected ? 'primary.main' : 'text.tertiary',
                      }}
                    >
                      {option.description}
                    </Typography>
                    {option.disabled ? (
                      <Box
                        sx={{
                          flex: 1,
                          textAlign: 'end',
                        }}
                      >
                        <Chip size="sm" color="primary" variant="soft">
                          {isTrial && t('addSenders.phonenumber.paidPlansOnly')}
                          {isPassthrough &&
                            t('addSenders.phonenumber.sakariPlansOnly')}
                        </Chip>
                      </Box>
                    ) : null}
                  </Stack>
                }
              />
            );
          })}
        </Stack>
        <Button
          color="primary"
          sx={{ width: 200 }}
          onClick={() => navigate(selected?.link as string)}
        >
          {t('action.next')}
        </Button>
        {upgradeRequired && (
          <Button
            variant="plain"
            sx={{ width: 200 }}
            onClick={() => navigate('/billing')}
          >
            {t('action.upgrade')}
          </Button>
        )}
      </Stack>
    </Container>
  );
}
export default ModeSelect;
