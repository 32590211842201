import { IconButton, Input, InputProps, Typography } from '@mui/joy';
import React from 'react';
import { Tooltip, InputControl } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export interface TextFieldProps extends Omit<InputProps, 'onChange'> {
  onChange?: (newVal: string) => void;
  label?: string;
  helperText?: string;
  infoText?: string;
  error?: boolean;
  orientation?: 'vertical' | 'horizontal';
  fullWidth?: boolean;
  labelInfo?: string;
  inputType?: 'currency' | 'url' | 'email';
  readOnly?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

function TextField({
  id,
  label,
  helperText,
  error,
  value,
  onChange,
  disabled,
  size,
  required,
  color,
  fullWidth,
  infoText,
  labelInfo,
  inputType,
  readOnly,
  inputRef,
  type,
  ...rest
}: TextFieldProps) {
  const handleInputChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  const [isVisible, setIsVisible] = React.useState(false);

  const getTypeProps = (type?: string): Partial<InputProps> => {
    switch (type) {
      case 'currency':
        return {
          type: 'number',

          startDecorator: (
            <FontAwesomeIcon
              icon={solid('money-bill-alt')}
              style={{ opacity: 0.5 }}
            />
          ),
          sx: {
            '.MuiInput-startDecorator': {
              width: 35,
              '::before': {
                content: "'$'",
                position: 'absolute',
                left: 40,
                top: 'auto',
                bottom: 'auto',
              },
            },
          },
        };
      case 'email':
        return {
          type: 'email',
          startDecorator: (
            <FontAwesomeIcon
              icon={solid('envelope')}
              style={{ opacity: 0.5 }}
            />
          ),
        };

      case 'url':
        return {
          startDecorator: (
            <Typography textColor="text.secondary">https://</Typography>
          ),
          sx: {
            input: {
              borderLeft: '1px solid ',
              borderColor: 'divider',
              pl: '0.75rem',
              width: 0,
            },
          },
        };
      case 'password':
        return {
          type: isVisible ? 'text' : 'password',
          endDecorator: (
            <IconButton onClick={() => setIsVisible(!isVisible)}>
              <FontAwesomeIcon
                icon={isVisible ? solid('eye') : solid('eye-slash')}
                style={{ opacity: 0.5 }}
              />
            </IconButton>
          ),
        };
      default:
        return {};
    }
  };

  const handleInputType = () => {
    if (typeof value === 'object') {
      return '';
    }
    return value;
  };

  return (
    <InputControl
      label={label}
      helperText={helperText}
      error={error}
      fullWidth={fullWidth}
      required={required}
    >
      <Input
        ref={inputRef}
        value={handleInputType()}
        readOnly={readOnly}
        onChange={(e) => handleInputChange(e.target.value)}
        disabled={disabled}
        endDecorator={
          infoText ? (
            <Tooltip
              title={infoText}
              placement="top"
              arrow
              size={size}
              sx={{
                whiteSpace: 'pre-wrap',
                fontWeight: 100,
                maxWidth: '280px',
                textAlign: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={regular('info-circle')}
                style={{ opacity: 0.5 }}
              />
            </Tooltip>
          ) : null
        }
        type={type}
        {...getTypeProps(inputType ?? type)}
        {...rest}
      />
    </InputControl>
  );
}
export default TextField;
