import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  IconButton,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Stack,
} from '@mui/joy';
import { List as ListType } from '@sakari-io/sakari-typings';
import { Tooltip } from '@sakari-io/sakari-components';
import React, { useEffect, useState } from 'react';
import AppList, { AppListProps } from '../../../molecules/AppList';

interface ListsListProps extends Omit<AppListProps<ListType>, 'items'> {
  lists: ListType[];
  onDelete?: (list: ListType) => any;
  onAction?: (list: ListType) => any;
  actionText?: string;
  readOnly?: boolean;
}

function ListsList({
  lists,
  onDelete,
  onAction,
  actionText,
  readOnly,
  ...rest
}: ListsListProps) {
  const [confirmDelete, setConfirmDelete] = useState('');

  useEffect(() => {
    if (confirmDelete) {
      setTimeout(() => {
        setConfirmDelete('');
      }, 3000);
    }
  }, [confirmDelete]);

  return (
    <AppList
      items={lists}
      noItemsText="No lists"
      renderItem={(list: ListType) => (
        <ListItem
          key={list.id}
          endAction={
            <Stack direction="row">
              {onDelete ? (
                <Tooltip
                  title={
                    confirmDelete === list.id ? 'Confirm Remove' : 'Remove List'
                  }
                  size="sm"
                  placement="top"
                >
                  <IconButton
                    onClick={() => {
                      if (confirmDelete === list.id) {
                        onDelete(list);
                      } else {
                        setConfirmDelete(list.id);
                      }
                    }}
                    size={rest.size}
                    disabled={readOnly}
                  >
                    <FontAwesomeIcon
                      icon={
                        confirmDelete === list.id
                          ? solid('trash-check')
                          : solid('xmark')
                      }
                    />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title={actionText} size="sm" placement="top">
                <IconButton
                  onClick={() => {
                    if (onAction) {
                      onAction(list);
                    }
                  }}
                  size={rest.size}
                >
                  <FontAwesomeIcon icon={solid('angle-right')} />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        >
          <Stack direction="row" width="100%">
            <ListItemDecorator>
              <FontAwesomeIcon icon={solid('list')} />
            </ListItemDecorator>
            <Tooltip
              title={list.name}
              size="sm"
              sx={{
                maxWidth: '200px',
              }}
            >
              <ListItemContent
                sx={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {list.name}
              </ListItemContent>
            </Tooltip>
          </Stack>
        </ListItem>
      )}
      {...rest}
    />
  );
}
export default ListsList;
