import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface RemoveContactDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  selectedContactCount: number;
}

function RemoveContactDialog({
  open,
  onClose,
  onSubmit,
  selectedContactCount,
}: RemoveContactDialogProps) {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const { t } = useTranslation();

  const handleClose = (result?: boolean) => {
    if (result) {
      onSubmit();
    }
    onClose();
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={handleClose}
      header={`Remove ${selectedContactCount} Contact${selectedContactCount > 1 ? 's' : ''}?`}
      content={t('cannotUndoAction')}
      confirmLabel={t('yesRemove')}
      cancelLabel={t('cancel')}
      width={400}
      type="danger"
    />
  );
}

export default RemoveContactDialog;
