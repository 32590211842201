import { List } from '@mui/joy';
import React from 'react';
import { listClasses } from '@mui/joy/List';
import { listItemClasses } from '@mui/joy/ListItem';
import Step, { StepProps } from '../../atoms/Step';

export interface StepperProps extends Pick<StepProps, 'orientation' | 'size'> {
  activeStep: number;
  steps: Pick<StepProps, 'id' | 'label' | 'children' | 'subLabel'>[];
  gap?: number;
  connectors?: boolean;
  activeShadow?: boolean;
  checkVisited?: boolean;
}

function Stepper({
  activeStep,
  orientation = 'horizontal',
  steps,
  connectors,
  size,
  gap = 1,
  activeShadow,
  checkVisited,
  ...rest
}: StepperProps) {
  const getIndicatorSize = (s: typeof size) => {
    switch (s) {
      case 'sm':
        return '24px';
      case 'lg':
        return '40px';
      default:
        return '32px';
    }
  };

  return (
    <List
      size={size}
      orientation={orientation}
      sx={{
        [`&.${listClasses.root}`]: {
          '--List-spacing': `${gap * 8}px`,
          '--ListItemDecorator-size': getIndicatorSize(size),
          gap: 'var(--List-spacing)',
          display: 'flex',
          width: '100%',
        },
        [`.${listItemClasses.root}:not(:last-child)`]: {
          flex: 1,
          ...(connectors &&
            orientation === 'vertical' && {
              '&:after': {
                content: '""',
                position: 'absolute',
                borderLeft: '1px solid ',
                borderColor: 'divider',
                height: `calc(100% + var(--List-spacing) - var(--ListItemDecorator-size) - (var(--ListDivider-gap) * 2))`,
                left: 'calc(var(--ListItemDecorator-size) * 0.5  )',
                bottom: `calc((var(--List-spacing) * -1) + var(--ListDivider-gap))`,
              },
            }),
          ...(connectors &&
            orientation === 'horizontal' && {
              '&:after': {
                content: '""',
                position: 'absolute',
                borderBottom: '1px solid ',
                borderColor: 'divider',
                width: `calc(100% + var(--List-spacing) - var(--ListItemDecorator-size) - (var(--ListDivider-gap) * 2.5))`,
                top: `calc(var(--ListItemDecorator-size) * 0.5 )`,
                right: `calc((var(--List-spacing) * -1) + var(--ListDivider-gap))`,
              },
            }),
        },
      }}
    >
      {steps.map(
        (step: Pick<StepProps, 'id' | 'label' | 'children'>, index: number) => (
          <Step
            key={step.id}
            index={index + 1}
            active={index === activeStep}
            visited={index < activeStep}
            size={size}
            orientation={orientation}
            activeShadow={activeShadow}
            checkVisited={checkVisited}
            {...step}
            {...rest}
          />
        ),
      )}
    </List>
  );
}

export default Stepper;
