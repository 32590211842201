import React, { useEffect, useState } from 'react';
import { Country as CountryType } from '@sakari-io/sakari-typings';
import { findCountry, countries as COUNTRIES } from '@sakari-io/sakari-common';
import { Flag } from '@sakari-io/sakari-components';
import { Typography, Stack } from '@mui/joy';
import { useFormState } from 'react-final-form';
import FieldWrapper from '../../../../utils/FieldWrapper';
import SelectableCardGroup from '../../../../ui/molecules/groups/SelectableCardGroup';
import SearchBar from '../../../../ui/molecules/inputs/SearchBar';

const us = findCountry('US');
const ca = findCountry('CA');
const au = findCountry('AU');
const gb = findCountry('GB');

const initialCountries: CountryType[] = [];
if (us) initialCountries.push(us);
if (ca) initialCountries.push(ca);
if (gb) initialCountries.push(gb);
if (au) initialCountries.push(au);

function Country({ name }: { name: string }) {
  const [countries, setCountries] = useState(initialCountries);
  const [searchValue, setSearchValue] = useState('');

  const { values } = useFormState();

  const selectedCountry = values[name];

  useEffect(() => {
    if (!searchValue.trim()) {
      setCountries(() =>
        selectedCountry &&
        !['US', 'CA', 'AU', 'GB'].includes(selectedCountry?.code)
          ? [selectedCountry, ...initialCountries]
          : initialCountries,
      );
    } else {
      const searchValueLC = searchValue.toLowerCase();
      setCountries(
        COUNTRIES.filter((c) => {
          return c?.name.toLowerCase().indexOf(searchValueLC) >= 0;
        }),
      );
    }
  }, [searchValue, COUNTRIES]);

  return (
    <Stack spacing={2}>
      <SearchBar value={searchValue} onChange={setSearchValue} />
      <Stack
        sx={{
          width: '100%',
        }}
      >
        <FieldWrapper
          component={SelectableCardGroup}
          name={name}
          options={countries?.slice(0, 8)}
          getId={(option: CountryType) => option.code}
          renderOption={(option: CountryType) => (
            <Stack alignItems="center" spacing={2}>
              <Flag country={option} rounded size={40} />
              <Typography>{option.name}</Typography>
            </Stack>
          )}
          height={128}
          width={145}
          containerSx={{
            flexFlow: 'row wrap',
            width: '100%',
            minHeight: 145,
            alignContent: 'flex-start',
          }}
        />
      </Stack>
    </Stack>
  );
}

export default Country;
