import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/joy';
import { WorkflowNodeType } from '@sakari-io/sakari-typings';
import Drawer from '../../../../../ui/templates/Drawer';
import SimpleDrawerHeader from '../../../../../ui/molecules/SimpleDrawerHeader';
import { useGetWorkflowNodeTypesQuery } from '../../../../../api';
import ElementGroup from '../../ElementGroup';

interface AddTriggerDrawerProps {
  open: boolean;
  onClose: (nodeType?: WorkflowNodeType) => void;
}

function AddTriggerDrawer({
  open,
  onClose,
}: AddTriggerDrawerProps): JSX.Element {
  const { t } = useTranslation('workflows');
  const { data } = useGetWorkflowNodeTypesQuery({ limit: 100 });
  const triggers = data?.data.filter((node) => node.type === 'trigger');

  return (
    <Drawer
      isOpen={open}
      position="right"
      variant="outlined"
      sx={{
        zIndex: 99,
        width: 'clamp(350px, 100%, 370px) !important',
        height: '100%',
        overflow: 'scroll',
      }}
    >
      <SimpleDrawerHeader
        title={t('triggers.triggerMenu')}
        onClick={() => onClose()}
        divider
      />
      <Stack
        pl={2}
        pr={2}
        sx={{
          overflow: 'scroll',
        }}
      >
        {triggers && (
          <ElementGroup
            options={triggers}
            onChange={(trigger) => onClose(trigger)}
          />
        )}
      </Stack>
    </Drawer>
  );
}

export default AddTriggerDrawer;
