import { useState } from 'react';
import { Textarea } from '@sakari-io/sakari-components';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import TextField from '../../../../../ui/atoms/inputs/TextField';

interface EnterpriseFormProps {
  onSubmit: (form: { volume: string; message: string }) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
}

function EnterpriseForm({
  onSubmit,
  isLoading,
  isSuccess,
}: EnterpriseFormProps) {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    volume: '',
    message: '',
  });

  return (
    <>
      <TextField
        name="volume"
        label={t('settings.billing.plans.enterprise.volume.label')}
        labelInfo={t('registration.businessInfo.monthlyUsage.info')}
        placeholder={t('settings.billing.plans.enterprise.volume.placeholder')}
        value={form.volume}
        onChange={(newVal) => setForm({ ...form, volume: newVal })}
        disabled={isSuccess}
        required
      />
      <Textarea
        name="message"
        label={t('settings.billing.plans.enterprise.message.label')}
        placeholder={t('settings.billing.plans.enterprise.message.placeholder')}
        value={form.message}
        onChange={(newVal: any) => setForm({ ...form, message: newVal })}
        minRows={5}
        disabled={isSuccess}
        required
      />
      <Button
        fullWidth
        onClick={() => onSubmit(form)}
        loading={isLoading}
        disabled={isSuccess || form.volume === '' || form.message === ''}
      >
        {isSuccess
          ? t('settings.billing.plans.enterprise.submitted')
          : t('settings.billing.plans.enterprise.submit')}
      </Button>
    </>
  );
}
export default EnterpriseForm;
