import React from 'react';

import { useTranslation } from 'react-i18next';
import Integration from '../IntegrationEdit';
import Setting from '../../../ui/templates/forms/SettingForm';
import FieldWrapper from '../../../utils/FieldWrapper';
import { TextField } from '../../../ui';

function Twilio() {
  const { t } = useTranslation();

  return (
    <Integration name="twilio">
      <Setting
        label={t('integrations.twilio.accountSid.label')}
        description={t('integrations.twilio.accountSid.description')}
      >
        <FieldWrapper
          component={TextField}
          name="accountSid"
          sx={{
            pointerEvents: 'none',
          }}
        />
      </Setting>
      <Setting
        label={t('integrations.twilio.keyId.label')}
        description={t('integrations.twilio.keyId.description')}
      >
        <FieldWrapper
          component={TextField}
          name="keyId"
          type="password"
          disabled
        />
      </Setting>
      <Setting
        label={t('integrations.twilio.keySecret.label')}
        description={t('integrations.twilio.keySecret.description')}
      >
        <FieldWrapper
          component={TextField}
          name="keySecret"
          type="password"
          disabled
        />
      </Setting>
    </Integration>
  );
}

export default Twilio;
