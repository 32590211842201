import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DotChip } from '@sakari-io/sakari-components';
import { ColorPaletteProp } from '@mui/joy';

const COLORS: { [key: string]: ColorPaletteProp } = {
  default: 'neutral',
  connected: 'primary',
  disconnected: 'warning',
  active: 'success',
  expired: 'danger',
  closed: 'danger',
  pending: 'warning',
  approved: 'success',
  verified: 'success',
  vetted_verified: 'success',
  unverified: 'warning',
  unregistered: 'warning',
  submitted: 'success',
  rejected: 'danger',
};

interface StatusChipProps {
  status: string;
}

// TODO candidate to move to components
function StatusChip({ status }: StatusChipProps) {
  const { t } = useTranslation();

  const color = COLORS[status] || COLORS.default;

  return (
    <DotChip
      color={color}
      sx={{
        width: 'fit-content',
      }}
    >
      {_.capitalize(t(`statusType.${status || 'unknown'}`))}
    </DotChip>
  );
}

export default StatusChip;
