import { useTranslation } from 'react-i18next';
import { Box, Typography, Chip, Button } from '@mui/joy';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { format } from '@sakari-io/sakari-common';
import { logger } from '@sakari-io/sakari-components';
import Recording from '../../../../pages/CallHistory/CallDetails/Recording';
import { updateQueryData } from '../../../../api/voice';
import { AccountContext } from '../../../../contexts/account.context';
import { ActiveCall } from '../../../../types';

export interface MissedCallProps {
  call: ActiveCall;
}

function MissedCall({ call }: MissedCallProps) {
  const { account } = useContext(AccountContext);
  const dispatch = useDispatch();

  const { t } = useTranslation('voice');

  const dismiss = () => {
    if (account) {
      dispatch(
        updateQueryData('startSession', account.id, (draft) => {
          logger.info('dismissing', 'clearing call');
          draft.data.call = undefined;
        }),
      );
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        flexDirection: 'column',
        bottom: 30,
        left: 30,
        width: 321,
        backgroundColor: 'var(--joy-palette-voice-solidBg)',
        borderRadius: '8px',
        zIndex: 'calc(var(--joy-zIndex-modal) - 1)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px',
          borderRadius: '8px 8px 0 0',
          backgroundColor: 'var(--joy-palette-voice-softBg)',
        }}
      >
        <Chip color="primary" sx={{ marginRight: 2 }}>
          {call?.group?.name}
        </Chip>
        <Typography level="body-md" sx={{ color: 'white' }}>
          {call.voicemail ? t('voicemail') : t('missedCall')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '14px 16px',
        }}
      >
        <Typography level="body-md" sx={{ color: 'white', fontWeight: 'bold' }}>
          {format.nameOrMobile(call.contact)}
        </Typography>
      </Box>
      {call.voicemail && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            paddingBottom: '10px',
          }}
        >
          <Recording
            callId={call.id}
            type="voicemail"
            theme="dark"
            auto={false}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          borderTop: `1px solid ${'var(--joy-palette-voice-softColor)'}}`,
        }}
      >
        {/* <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                padding: '4px 0',
              }}
            >
              <ReturnCallButton call={call} text="Call Back" />
            </Box> */}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            padding: '4px 0',
            borderLeft: `1px solid ${'var(--joy-palette-voice-softColor)'}}`,
          }}
        >
          <Button
            variant="plain"
            color="voiceBtn"
            size="sm"
            sx={{
              color: 'var(--joy-palette-danger-500)',
              '&:hover': { opacity: 0.8 },
            }}
            onClick={dismiss}
          >
            Dismiss
          </Button>
        </Box>
      </Box>
    </Box>
    // </>
  );
}

export default MissedCall;
