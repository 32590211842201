import {
  SakariAPIResponse,
  TenDlcBrand,
  TenDlcCampaign,
  TollFreeVerificationRequest,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { buildQuery, getAccountId } from './common';
import { AccountIdWith } from '../types';
import { PHONE_NUMBER_TAG } from './tags';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    // 10DLC Registration
    get10DLCBrands: builder.query<SakariAPIResponse<TenDlcBrand[]>, string>({
      query: (request) => `accounts/${request}/regulatory/10dlc/brands`,
    }),
    register10DLCBrand: builder.mutation<SakariAPIResponse<TenDlcBrand>, any>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/regulatory/10dlc/brands`,
        method: 'POST',
        body: data,
      }),
    }),
    get10DLCCampaign: builder.query<
      SakariAPIResponse<TenDlcCampaign>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/regulatory/10dlc/campaigns/${request}`,
    }),
    get10DLCCampaigns: builder.query<
      SakariAPIResponse<TenDlcCampaign[]>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: brandId }) =>
        `accounts/${accountId}/regulatory/10dlc/campaigns?${buildQuery({
          brandId,
        })}`,
    }),
    register10DLCCampaign: builder.mutation<
      SakariAPIResponse<TenDlcCampaign>,
      Partial<TenDlcCampaign>
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/regulatory/10dlc/campaigns`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [PHONE_NUMBER_TAG],
    }),
    updateAndResubmit10DLCCampaign: builder.mutation<
      SakariAPIResponse<TenDlcCampaign>,
      Partial<TenDlcCampaign>
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/regulatory/10dlc/campaigns/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [PHONE_NUMBER_TAG],
    }),
    associate10DLCCampaign: builder.mutation<
      SakariAPIResponse<any>,
      { campaign: TenDlcCampaign; numbers: string[] }
    >({
      query: ({ campaign, numbers }) => ({
        url: `accounts/${getAccountId()}/regulatory/10dlc/campaigns/${
          campaign.id
        }/numbers`,
        method: 'POST',
        body: numbers,
      }),
      invalidatesTags: [PHONE_NUMBER_TAG],
    }),
    verifyTollFreeNumber: builder.mutation<
      SakariAPIResponse<TollFreeVerificationRequest>,
      AccountIdWith<Partial<TollFreeVerificationRequest>>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/regulatory/tollfreeverification`,
        method: 'POST',
        body: request,
      }),
    }),
    sendPftEmail: builder.query<SakariAPIResponse<null>, AccountIdWith<string>>(
      {
        query: ({ accountId }) =>
          `accounts/${accountId}/regulatory/10dlc/pftemail`,
      },
    ),
  }),
  overrideExisting: false,
});

export const {
  useGet10DLCBrandsQuery,
  useRegister10DLCBrandMutation,
  useGet10DLCCampaignQuery,
  useGet10DLCCampaignsQuery,
  useRegister10DLCCampaignMutation,
  useUpdateAndResubmit10DLCCampaignMutation,
  useAssociate10DLCCampaignMutation,
  useVerifyTollFreeNumberMutation,
  useSendPftEmailQuery,
  useLazySendPftEmailQuery,
} = extendedApi;
