import React, { useState } from 'react';
import { Option, Select, Stack, IconButton } from '@mui/joy';
import { logger, InputControl } from '@sakari-io/sakari-components';
import { Account, User } from '@sakari-io/sakari-typings';
import { faBoltSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  useGetConnectionsQuery,
  useDeleteIntegrationUserMutation,
} from '../../../../../../api';
import config from '../../../../../../config';
import Dialog from '../../../../../../components/molecules/Dialog';
import { handleApiMutationResponse } from '../../../../../../utils/api';
import { useAppDispatch } from '../../../../../../redux';

interface UserIntegrationProps {
  label?: string;
  helperText?: string;
  disabled?: boolean;
  value?: string;
  integrationType: string;
  onChange: (value?: string) => any;
  saveVersion: (onSuccess?: () => any) => any;
  account: Account;
  user: User;
  workflowId: string;
  currentNode: any;
  required?: boolean;
}

function UserIntegration({
  account,
  user,
  workflowId,
  label,
  helperText,
  value,
  integrationType,
  onChange,
  disabled,
  saveVersion,
  currentNode,
  required,
}: UserIntegrationProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState<string>('');

  const dispatch = useAppDispatch();

  const returnTo = `${config.baseUrl}/workflows/${workflowId}?nid=${currentNode?.id}&edit=1`;

  const { data: connectionsData } = useGetConnectionsQuery(integrationType);

  const [deleteIntegrationUser] = useDeleteIntegrationUserMutation();

  const updateWorkflowDefinition = async () => {
    return saveVersion(() => {
      window.open(
        `${config.integrationsUrl}/oauth2/v1/${integrationType}/authorizeurl?account=${account?.id}&user=${user?.id}&returnTo=${encodeURIComponent(returnTo)}`,
        '',
        'noopener',
      );
    });
  };

  const handleRemoveUser = async () => {
    await handleApiMutationResponse(
      deleteIntegrationUser({
        integrationId: idToRemove,
        integration: integrationType,
      }).unwrap(),
      dispatch,
      {
        onError: () => {
          setOpen(false);
        },
        onSuccess: () => {
          setOpen(false);
        },
        successMessage: t('integrations.user.remove.success'),
        defaultErrorMessage: t('integrations.user.remove.error'),
      },
    );
  };

  return (
    <>
      <InputControl
        fullWidth
        label={label}
        helperText={helperText}
        required={required}
      >
        <Select
          disabled={disabled}
          value={value}
          onChange={async (evt, connectionId) => {
            if (connectionId === 'connect') {
              try {
                await updateWorkflowDefinition();
              } catch (error) {
                logger.error('workflow update failed', error);
              }
            } else if (connectionId) {
              onChange(connectionId);
            }
          }}
        >
          {connectionsData?.data?.map(({ id, user: { email } }: any) => (
            <Option key={id} value={id}>
              <Stack
                direction="row"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                {email}

                <IconButton
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIdToRemove(id);
                    setOpen((prev) => !prev);
                  }}
                >
                  <FontAwesomeIcon icon={faBoltSlash} />
                </IconButton>
              </Stack>
            </Option>
          ))}
          <Option value="connect">Add integration...</Option>
        </Select>
      </InputControl>
      <Dialog
        color="danger"
        open={open}
        onClose={() => setOpen(false)}
        title={t('integrations.user.remove.title')}
        primaryTitle={t('confirm')}
        primaryAction={handleRemoveUser}
        cancelTitle={t('cancel')}
        cancelAction={() => setOpen(false)}
      >
        {t('integrations.user.remove.description')}
      </Dialog>
    </>
  );
}

export default UserIntegration;
