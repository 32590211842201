import { GridColDef, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { Box, Chip, IconButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { logger } from '@sakari-io/sakari-components';
import SourceDetail from './SourceDetail';
import colorArray from './colors';

interface SourceRowProps {
  linkId: string;
  source: any;
  columns: GridColDef[];
  index: number;
}
function SourceRow({ linkId, source, columns, index }: SourceRowProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const apiRef = useGridApiContext();

  const [cols, setCols] = useState<GridColDef[]>(columns);

  useEffect(() => {
    const unsubscribe = apiRef.current.subscribeEvent(
      'columnResize',
      (params, event, details) => {
        const { colDef, width } = params;
        logger.info('updating width', colDef, width, details);
        setCols((prev) => {
          const newCols = [...prev];
          const ix = newCols.findIndex((c) => c.field === colDef.field);
          newCols[ix].width = width;
          return newCols;
        });
      },
    );
    return () => {
      unsubscribe();
    };
  }, [apiRef]);

  const sourceColumns: GridColDef[] = cols.map((c) => {
    if (c.field === 'name') {
      return {
        ...c,
        renderCell: (params) => {
          // TODO: create a component for this
          return (
            <Chip
              variant="soft"
              size="sm"
              sx={{
                backgroundColor: `${colorArray[index]}30`,
                color: colorArray[index],
              }}
            >
              {params.row.name}
            </Chip>
          );
        },
      };
    }
    return c;
  });

  const getCellContent = (column: GridColDef, source: any) => {
    if (column.renderCell) return column.renderCell({ row: source } as any);

    // if (column.valueGetter) return column.valueGetter(source, source, column, undefined);
    return source[column.field];
  };

  return (
    <>
      <div
        id={`${linkId}-${source.name}`}
        className="MuiDataGrid-row"
        style={{
          width: '100%',
        }}
      >
        <div
          className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell"
          data-field="__detail_panel_toggle__"
          style={{
            width: columns[0].width || 50,
            minWidth: columns[0].minWidth || 50,
          }}
        >
          <Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen((prev) => !prev);
              }}
            >
              <FontAwesomeIcon
                icon={solid('caret-right')}
                style={{
                  opacity: 0.3,
                  transform: isOpen ? 'rotate(90deg)' : '',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />
            </IconButton>
          </Box>
        </div>
        {sourceColumns.map((column, ix) => {
          if (['actions', '__detail_panel_toggle__'].includes(column.field))
            return null;

          return (
            <div
              key={`${source.id}-${column.field}`}
              data-field={column.field}
              data-colindex={ix + 1}
              aria-colindex={ix + 1}
              tabIndex={-1}
              className="MuiDataGrid-cell--withRenderer MuiDataGrid-cell"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: column.align || 'left',
                width: column.width || 80,
                minWidth: column.minWidth || 50,
                maxWidth: column.maxWidth || 200,
                ...(column.field === 'name' && {
                  paddingLeft: '24px',
                }),
              }}
            >
              {getCellContent(column, source)}
            </div>
          );
        })}
      </div>
      {isOpen && <SourceDetail source={source} linkId={linkId} index={index} />}
    </>
  );
}
export default SourceRow;
