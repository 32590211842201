import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Sheet, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import ReferralsGrid from '../../ui/organisms/datagrids/ReferralsGrid';
import Partners, { PartnersViewMode } from '.';
import { AccountContext } from '../../contexts/account.context';
import OverlayPopup from '../../ui/templates/OverlayPopup';
import Radios from '../../ui/molecules/Radios';

const FILTER_OPTIONS = [
  {
    label: 'All Referrals',
    value: 'all',
    tooltip: '',
  },
  {
    label: 'Only Expired Referrals',
    value: 'expired',
    tooltip: '',
  },
  {
    label: 'Only Pending Referrals',
    value: 'pending',
    tooltip: '',
  },
  {
    label: 'Only Active Referrals',
    value: 'active',
    tooltip: '',
  },
  {
    label: 'Only Ineligible Referrals',
    value: 'ineligible',
    tooltip: '',
  },
];
interface ReferralFilter {
  status: 'pending' | 'expired' | 'active' | 'ineligible';
}

function Referrals() {
  const { account } = useContext(AccountContext);

  const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);
  const [filters, setFilters] = useState<ReferralFilter | undefined>(undefined);
  const [referralStatusFilter, setReferralStatusFilter] = useState<any>('all');

  useEffect(() => {
    setFilters(
      referralStatusFilter !== 'all'
        ? { status: referralStatusFilter }
        : undefined,
    );
  }, [referralStatusFilter]);

  if (!account) {
    return null;
  }

  return (
    <Partners view={PartnersViewMode.REFERRALS} title="Referrals">
      <Stack direction="row" gap={2} pb={2} alignItems="right">
        <OverlayPopup
          open={openFilterMenu}
          onOpenClose={setOpenFilterMenu}
          placement="bottom-end"
          renderButton={(setAnchorEl, onClick) => (
            <IconButton
              variant="outlined"
              sx={{ transform: 'scale(0.8)' }}
              onClick={onClick}
              ref={setAnchorEl}
            >
              <FontAwesomeIcon icon={regular('bars-filter')} />
            </IconButton>
          )}
          renderPopup={() => (
            <Sheet sx={{ padding: 2, borderRadius: 2 }}>
              <Radios
                size="sm"
                value={referralStatusFilter}
                items={FILTER_OPTIONS}
                onChange={(e) => {
                  setReferralStatusFilter(e);
                }}
              />
            </Sheet>
          )}
        />
      </Stack>
      <ReferralsGrid account={account} filter={filters} />
    </Partners>
  );
}

export default Referrals;
