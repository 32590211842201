import { useAuth0 } from '@auth0/auth0-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Account, AccountAttribute, User } from '@sakari-io/sakari-typings';
import React, { ReactNode, useEffect, useState } from 'react';
import { format } from '@sakari-io/sakari-common';
import { logger } from '@sakari-io/sakari-components';
import {
  useGetAccountQuery,
  useGetAttributesByTypeQuery,
  useGetUserProfileQuery,
} from '../api';
import useLocalStorage from '../hooks/useLocalStorage';
import { ROLES_CLAIM_KEY, UserRole } from '../constants';
import { disconnectSockets } from '../utils/socket-connection';
import config from '../config';

interface AccountContextProps {
  profile?: User;
  account?: Account;
  accountId?: string;
  contactAttributes: AccountAttribute[];
  userRole: UserRole;
  refreshUserRole: (accountId?: string) => Promise<any>;
  isLoading?: boolean;
  isFetching?: boolean;
  isFetchingAccount?: boolean;
  isFetchingProfile?: boolean;
  error?: any;
  changeAccount: (accountId: string) => any;
  isChangingAccount?: boolean;
}

interface AccountContextProviderProps {
  children: ReactNode;
}

export const AccountContext = React.createContext<AccountContextProps>({
  profile: undefined,
  account: undefined,
  accountId: undefined,
  error: undefined,
  contactAttributes: [],
  isLoading: false,
  isFetching: false,
  isFetchingAccount: false,
  isFetchingProfile: false,
  changeAccount: (accountId) => {
    logger.info('changeAccountDefault', 'accountId', accountId);
  },
  userRole: UserRole.READ_ONLY,
  refreshUserRole: () => Promise.resolve(UserRole.READ_ONLY),
  isChangingAccount: false,
});

function AccountContextProvider({ children }: AccountContextProviderProps) {
  const {
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading: isLoadingAuth,
    logout,
  } = useAuth0();

  const [accountId, setAccountId] = useLocalStorage<string>('accountId');
  const [isChangingAccount, setIsChangingAccount] = useState(false);

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    isFetching: isFetchingProfile,
    error: profileError,
  } = useGetUserProfileQuery();
  const [userRole, setUserRole] = useState(UserRole.READ_ONLY);

  const {
    data: account,
    isLoading,
    isFetching: isFetchingAccount,
    error,
  } = useGetAccountQuery(accountId ?? skipToken);

  const { data: contactAttributes } = useGetAttributesByTypeQuery(
    accountId
      ? {
          accountId,
          request: {
            type: 'contacts',
            limit: 100,
          },
        }
      : skipToken,
  );

  const currentAccount = account?.data;

  const refreshUserRole = async (accId = accountId) => {
    // logger.info('refreshing token and retrieving updated role', accId);
    if (!accId) {
      // logger.info('acct id not set');
      return Promise.resolve();
    }

    await getAccessTokenSilently({ ignoreCache: true })
      .catch((err) => {
        // TODO better error handling
        logger.info('error getting token', err);
      })
      .finally(() => {
        // logger.info('ugaccount', account);
        if (profileData?.data?.id) {
          const { email } = profileData.data;
          const identifier =
            email.indexOf('@sakari.io') >= 0 ? email : `${accountId}:${email}`;

          const {
            name,
            user,
            plan,
            balance,
            created,
            defaults,
            options,
            subscription,
          } = (account?.data as any) || {};
          (window as any).userGuiding.identify(identifier, {
            // account
            email,
            name: format.name(profileData.data),

            role: user?.role,
            accountName: name,
            balance,
            createdAt: created?.at,
            defaultCountry: defaults?.country?.code,
            commitment: plan?.price,
            billingFrequency: plan?.billingFrequency,
            autoTopupAmount: options?.autotoopup?.amount,
            autoTopupBelow: options?.autotoopup?.below,
            autoTopupLastTopup: options?.autotoopup?.lastTopup,
            nextBillDate: subscription?.activeUntil,
          });
        }

        return getIdTokenClaims().then((claims) => {
          if (claims && accId) {
            setUserRole(claims[ROLES_CLAIM_KEY][accId]);
            if (user) {
              user[ROLES_CLAIM_KEY] = claims[ROLES_CLAIM_KEY];
            }
          }
        });
      });
    return Promise.resolve();
  };

  useEffect(() => {
    if (window.location.pathname === '/callback') return;

    if (
      (profileError as any)?.status === 401 ||
      (profileError as any)?.originalStatus === 401 ||
      error
    ) {
      logout({ returnTo: config.auth0.callbackUrl });
    }
  }, [profileError]);

  useEffect(() => {
    if (window.location.pathname === '/callback') return;

    if (accountId && error) {
      localStorage.removeItem('accountId');
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    if (isFetchingAccount && currentAccount?.id !== accountId) {
      setIsChangingAccount(true);
      disconnectSockets();
    }
  }, [isFetchingAccount]);

  useEffect(() => {
    const isSameAccount = currentAccount?.id === accountId;

    if (
      isSameAccount &&
      !isFetchingAccount &&
      !isFetchingProfile &&
      !isLoadingAuth
    ) {
      setIsChangingAccount(false);
    }
    if (accountId) {
      refreshUserRole(accountId);
    }
  }, [currentAccount, isFetchingAccount, isFetchingProfile, isLoadingAuth]);

  // useEffect(() => {
  //   if (account?.data && profileData?.data) {
  //     logger.info('updating user guiding');
  //     const { email, firstName, lastName } = profileData.data;
  //     const { id:accountId, name: accountName, plan } = account?.data || {};

  //     (window as any).userGuiding.identify(email, {
  //       // account
  //       email,
  //       name: `${firstName || ''} ${lastName || ''}`,
  //       // created_at: 1644403436643,
  //       accountId,
  //       accountName,
  //       planId: plan?.id,
  //       planName: plan?.name,
  //       // planBillingFrequency: plan
  //     });
  //   }
  // }, [account, profileData]);

  return (
    <AccountContext.Provider
      value={{
        profile: profileData?.data,
        account: account?.data,
        accountId,
        error,
        contactAttributes: contactAttributes?.data || [],
        userRole,
        refreshUserRole,
        isLoading: isLoading || isLoadingAuth || isLoadingProfile,
        isFetchingAccount,
        isFetchingProfile,
        changeAccount: (accountId: string) => {
          setAccountId(accountId);
        },
        isChangingAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export default AccountContextProvider;
