import React, { useEffect, useState } from 'react';

import { Account, Contact } from '@sakari-io/sakari-typings';
import { Loader } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ContactEdit from './ContactEdit';
import { useGetContactListsQuery, useGetContactQuery } from '../../../../api';
import DeleteContactDialog from '../../Dialogs/DeleteContactDialog';
import BlockContactDialog from '../../Dialogs/BlockContactDialog';
import AppDrawer from '../../../molecules/AppDrawer';

interface ContactDetailsProps {
  account: Account;
  contactId: string;
  hideMessageButton?: boolean;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  listId?: string;
}

function ContactDetails({
  account,
  contactId,
  drawerOpen,
  setDrawerOpen,
  hideMessageButton,
  listId,
}: ContactDetailsProps) {
  const { data: contact, isLoading: isContactLoading } = useGetContactQuery(
    contactId !== 'new'
      ? {
          accountId: account.id,
          request: contactId,
        }
      : skipToken,
  );

  const { data: lists, isLoading } = useGetContactListsQuery(
    contactId && contactId !== 'new'
      ? {
          accountId: account.id,
          request: contactId,
        }
      : skipToken,
  );

  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClose = () => {
    setOpenBlockDialog(false);
    setDrawerOpen(false);
  };

  const [selected, setSelected] = useState<Contact>({ id: 'new' });

  useEffect(() => {
    if (!isContactLoading && contact?.data) {
      setSelected(contact.data);
    }
  }, [contactId, contact, isContactLoading]);

  return (
    <>
      <AppDrawer
        open={drawerOpen}
        onClose={() => handleClose()}
        sx={{
          width: 400,
        }}
      >
        {contactId !== 'new' &&
        (isLoading ||
          isContactLoading ||
          !contact ||
          contact.data.id !== contactId) ? (
          <Loader size={100} label="Loading contact..." />
        ) : (
          <ContactEdit
            contact={selected}
            setContact={(value) => setSelected(value)}
            setDrawerOpen={(state) =>
              state ? setDrawerOpen(state) : handleClose()
            }
            onShowBlockDialog={() => setOpenBlockDialog(true)}
            onShowDeleteDialog={() => setOpenDeleteDialog(true)}
            lists={lists?.data || []}
            hideMessageButton={hideMessageButton}
            listId={listId}
          />
        )}
      </AppDrawer>
      <DeleteContactDialog
        onClose={(result) => {
          setOpenDeleteDialog(false);
          if (result) {
            setDrawerOpen(false);
          }
        }}
        open={openDeleteDialog}
        contact={selected}
      />
      <BlockContactDialog
        onClose={(result) => {
          setOpenBlockDialog(false);
          if (result) {
            setDrawerOpen(false);
          }
        }}
        open={openBlockDialog}
        contact={selected}
      />
    </>
  );
}

export default ContactDetails;
