import React from 'react';
import { Link, Sheet, Stack, Typography } from '@mui/joy';
import { useFormState } from 'react-final-form';
import PhoneNumberTypeOptions from './PhoneNumberTypeOptions';
import FieldWrapper from '../../../../../../utils/FieldWrapper';

interface NumberTypeProps {
  name: string;
}

function NumberType({ name }: NumberTypeProps) {
  const { values } = useFormState();

  return (
    <Stack>
      <FieldWrapper component={PhoneNumberTypeOptions} name={name} />
      {values[name]?.info?.length > 0 && (
        <Sheet
          variant="outlined"
          color="neutral"
          sx={{
            marginTop: '20px',
            border: `1px solid divider`,
            borderRadius: 'var(--joy-radius-md)',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography level="body-md" fontWeight="bold">
            What is this?
          </Typography>
          {values[name].info?.map((info: string, idx: number) => {
            return (
              <Typography key={`info${idx}`} level="body-md">
                {info}
              </Typography>
            );
          })}
          {values[name].links?.map((link: any) => {
            return (
              <Link
                key={link.id}
                color="primary"
                onClick={() => window.open(link.url)}
                level="body-md"
              >
                {link.name}
              </Link>
            );
          })}
        </Sheet>
      )}
    </Stack>
  );
}
export default NumberType;
