import React, { useContext, useState, useEffect } from 'react';
import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SearchableDataGrid from '../../../ui/organisms/datagrids/SearchableDataGrid';
import CustomAttributesGrid from '../../../ui/organisms/datagrids/CustomAttributesGrid';
import { AccountContext } from '../../../contexts/account.context';
import AttributeForm from './AttributeForm';

interface CustomAttributesProps {
  type: string;
}

function CustomAttributes({ type }: CustomAttributesProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  const params = useParams();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  useEffect(() => {
    if (params.attributeId) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }
  }, [params.attributeId]);

  return (
    <>
      <SearchableDataGrid
        action={() => setOpenDrawer(true)}
        actionLabel={
          <Typography
            level="body-sm"
            sx={{ color: 'var(--joy-palette-primary-solidColor)' }}
            startDecorator={<FontAwesomeIcon icon={solid('plus')} />}
            whiteSpace="nowrap"
          >
            {t('customAttributes.addAttribute')}
          </Typography>
        }
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder={t('search')}
        renderDataGrid={() => (
          <CustomAttributesGrid
            type={type}
            filter={{
              accountId: account && account.id,
              request: {
                q: searchTerm,
                type,
              },
            }}
            onSelectedRowChange={(row: any) => {
              navigate(`/attributes/${row.name}`);
            }}
          />
        )}
      />

      <AttributeForm
        type={type}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          navigate(`/attributes/`);
        }}
      />
    </>
  );
}

export default CustomAttributes;
