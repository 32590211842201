import React from 'react';
import { Sheet, Stack, Typography, IconButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export interface SimpleDrawerHeaderProps {
  title: string | React.ReactNode;
  onClick?: () => void;
  link?: React.ReactNode;
  divider?: boolean;
  backAction?: (values: any) => void;
  replaceCloseWith?: React.ReactNode;
  hideClose?: boolean;
  drawerTitle?: string;
  children?: React.ReactNode;
  sx?: any;
}

function SimpleDrawerHeader({
  title,
  onClick,
  link,
  divider,
  backAction,
  replaceCloseWith,
  hideClose,
  drawerTitle,
  children,
  sx,
}: SimpleDrawerHeaderProps) {
  const renderClose = () => {
    if (hideClose) return null;
    if (replaceCloseWith) return replaceCloseWith;
    return (
      <IconButton onClick={onClick}>
        <FontAwesomeIcon
          icon={solid('x')}
          style={{
            color: 'var(--joy-palette-neutral-400)',
            fontSize: '0.75rem',
          }}
        />
      </IconButton>
    );
  };

  const renderDrawerTitle = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{
          padding: '16px',
          backgroundColor: 'white',
          borderBottom: '1px solid var(--joy-palette-neutral-200)',
        }}
      >
        <Typography
          fontWeight={700}
          sx={{ paddingLeft: !backAction ? '16px' : '0px' }}
        >
          {drawerTitle}
        </Typography>
        <IconButton onClick={onClick} size="xs">
          <FontAwesomeIcon icon={solid('x')} />
        </IconButton>
      </Stack>
    );
  };

  return (
    <>
      {drawerTitle && renderDrawerTitle()}
      <Sheet
        sx={{
          display: 'flex',
          width: '100%',
          height: 'fit-content',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: divider
            ? '1px solid var(--joy-palette-neutral-200)'
            : 'none',
          paddingBottom: divider ? '0.5rem' : '0',
          padding: '16px',
          backgroundColor: 'var(--joy-palette-neutral-50)',
          ...sx,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {backAction ? (
              <IconButton
                size="sm"
                onClick={backAction}
                color="neutral"
                variant="outlined"
                sx={{
                  borderRadius: '8px',
                  padding: 'auto',
                  background: 'var(--joy-palette-background-surface)',
                }}
                aria-label="Back"
              >
                <FontAwesomeIcon
                  icon={solid('angle-left')}
                  size="xs"
                  style={{ fontWeight: 700 }}
                />
              </IconButton>
            ) : null}

            <Stack direction="row" spacing={1} alignItems="center">
              <Stack direction="column">
                {title && typeof title === 'string' ? (
                  <Typography
                    fontWeight={700}
                    sx={{ paddingLeft: !backAction ? '16px' : '0px' }}
                  >
                    {title}
                  </Typography>
                ) : (
                  title
                )}

                {link ? link : null}
              </Stack>
            </Stack>
          </Stack>

          {renderClose()}
        </Stack>

        {children}
      </Sheet>
    </>
  );
}

export default SimpleDrawerHeader;
