import React from 'react';
import { Plan } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';

import { Alert, Stack, Typography, Link, Checkbox } from '@mui/joy';
import Dialog, { DialogProps } from '../../../../components/molecules/Dialog';

interface ChangePlanDialogProps extends Pick<DialogProps, 'open'> {
  onClose: (result: boolean) => void;
  newCommitment?: number;
  frequency?: Plan['billingFrequency'];
  isTrial?: boolean;
  isUpgrading?: boolean;
  nextBillingDate?: string;
}

function ChangePlanDialog({
  open,
  onClose,
  newCommitment,
  frequency,
  isTrial,
  isUpgrading,
  nextBillingDate,
}: ChangePlanDialogProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      color="primary"
      title={t('settings.billing.plans.changePlan.confirmTitle')}
      primaryTitle={
        isTrial
          ? t('settings.billing.plans.continueToPayment')
          : t('action.confirm')
      }
      primaryAction={() => onClose(true)}
      disablePrimary={!checked}
      cancelTitle={t('action.cancel')}
      cancelAction={() => onClose(false)}
      size="md"
    >
      <Stack>
        {!isTrial && (
          <Typography>
            {t(
              `settings.billing.plans.changePlan.${
                isUpgrading || isTrial ? 'upgrade' : 'downgrade'
              }.description`,
              {
                date: nextBillingDate
                  ? new Date(nextBillingDate).toLocaleDateString()
                  : undefined,
              },
            )}
          </Typography>
        )}
        <Stack flexDirection="row" justifyContent="space-between" py={1}>
          <Typography fontWeight={700}>
            New {frequency} charge ($USD)
          </Typography>
          <Typography level="h4">${newCommitment}</Typography>
        </Stack>

        <Typography level="body-xs">
          {`* ${t('settings.billing.plans.changePlan.additionalChargesDisclaimer')} ${t('settings.billing.plans.changePlan.segmentPriceChange')}`}
        </Typography>

        <Alert
          color="neutral"
          sx={{
            mt: 2,
            justifyContent: 'space-between',
            gap: 1,
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            label={
              <>
                <Typography level="body-sm">
                  {t('settings.billing.plans.segmentUnderstanding')}
                </Typography>
              </>
            }
          />
          <Link
            level="body-md"
            href="https://support.sakari.io/hc/en-us/articles/360039248071-What-is-a-Text-Message-Segment-"
            target="_blank"
          >
            {t('learnMore')}
          </Link>
        </Alert>
      </Stack>
    </Dialog>
  );
}
export default ChangePlanDialog;
