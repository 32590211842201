import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import Container from '../../ui/templates/Container';
import GroupsGrid from '../../ui/organisms/datagrids/GroupsGrid';
import PageHeader from '../../ui/organisms/headers/PageHeader';
import { AccountContext } from '../../contexts/account.context';
import SearchableDataGrid from '../../ui/organisms/datagrids/SearchableDataGrid';

function Groups() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);

  const createNewAction = () => {
    return navigate('/groups/add');
  };

  return (
    <>
      <PageHeader
        title="Groups"
        ctaText={t('groups.add')}
        ctaAction={createNewAction}
      />

      <Container>
        <SearchableDataGrid
          value={searchTerm}
          onChange={setSearchTerm}
          renderDataGrid={() => (
            <GroupsGrid
              filter={{
                accountId: account?.id || '',
                request: { q: debouncedSearchTerm, admin: 1 },
              }}
              onSelectedRowChange={(row: any) => navigate(row.id)}
            />
          )}
        />
      </Container>
    </>
  );
}

export default Groups;
