import React, { useEffect, useRef, useState } from 'react';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';

import { Account, Role, User as UserProfile } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, List, Stack } from '@mui/joy';
import { Logo, Wordmark } from '@sakari-io/sakari-components';

import Drawer from '../../../../templates/Drawer';
import SettingsMenu from '../../menus/SettingsMenu';
import NavBarItem from './NavbarItem';
import DrawerToggleButton from '../../../ButtonContexts/DrawerToggleButton';

import SupportMenu from '../../menus/SupportMenu';
import LanguageMenu from '../../menus/LanguageMenu';
import Tutorial from '../../../../../pages/Tutorial';
import {
  getNavigationItems,
  NavigationItem,
} from '../../../../../utils/navigation';
import OverlayPopup from '../../../../templates/OverlayPopup';
import useSessionStorage from '../../../../../hooks/useSessionStorage';

interface NavBarProps {
  account?: Account;
  userRole?: string;
  profile?: UserProfile;
}

function NavBar({ account, userRole, profile }: NavBarProps) {
  const DRAWER_STORAGE_KEY = 'isLeftDrawerOpened';
  const LANGUAGE_STORAGE_KEY = 'language';

  const navRef = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();

  const currentPath = window.location.pathname;

  const [language, setLanguage] = useSessionStorage<string | null>(
    LANGUAGE_STORAGE_KEY,
    'en',
  );

  const navItems: NavigationItem[] = getNavigationItems(
    currentPath,
    profile,
    userRole,
    account,
  );

  const [isOpen, setIsOpen] = useSessionStorage<boolean>(
    DRAWER_STORAGE_KEY,
    true,
  );
  const [menuId, setMenuId] = useState<string | null>(null);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const [showOnboarding] = useSessionStorage<boolean>('showOnboarding');
  const [tutorialOpen, setTutorialOpen] = useState<boolean>(showOnboarding);

  const handleToggleTutorial = (state: boolean) => {
    setTutorialOpen(state);
    setMenuId(null);

    if (!state) {
      sessionStorage.removeItem('showOnboarding');
    }
  };

  useEffect(() => {
    i18n.changeLanguage(language || 'en');
  }, [language]);

  useEffect(() => {
    const handleWatchScreen = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWatchScreen);
    return () => {
      window.removeEventListener('resize', handleWatchScreen);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 1000 && isOpen && currentPath === '/inbox') {
      setIsOpen(false);
    }
  }, [screenWidth, currentPath]);

  const handleMenuClick = (id: string) => {
    setMenuId((prev) => (prev === id ? null : id));
  };

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectLanguage = (language: string) => {
    setLanguage(language);
  };

  return (
    <Drawer
      position="left"
      variant="plain"
      isOpen={isOpen}
      persistent
      minDepth={64}
      sx={{
        backgroundColor: 'background.body',
      }}
    >
      <DrawerToggleButton
        isOpen={isOpen}
        onClick={handleDrawerToggle}
        tooltip={isOpen ? t('action.collapse') : t('action.expand')}
        sx={{ position: 'absolute', top: '30px', right: '-12px' }}
      />
      <Stack
        sx={{
          height: '100%',
          overflow: 'hidden',
          justifyContent: 'space-between',
          borderRight: '1px solid var(--joy-palette-divider)',
        }}
      >
        <Stack
          sx={{
            justifyContent: 'flex-start',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Stack
            sx={{
              flexFlow: 'row nowrap',
              px: 1,
              mr: 5,
              alignItems: 'center',
              gap: 1,
              pt: 2,
            }}
          >
            <Stack padding="10px">
              <Logo size={30} />
            </Stack>
            {isOpen && <Wordmark size={30} />}
          </Stack>

          <Stack
            ref={navRef}
            sx={{
              p: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              '::-webkit-scrollbar': {
                width: 0,
                background: 'transparent',
              },
            }}
          >
            <List
              size="md"
              sx={{
                rowGap: 1,
              }}
            >
              {(navItems || []).map((item) => {
                return (
                  <React.Fragment key={item.label}>
                    <NavBarItem
                      key={item.label}
                      expanded={isOpen}
                      isActive={
                        currentPath.startsWith(item.url) ||
                        (item.url === '/inbox' && currentPath === '/')
                      }
                      onClick={() => handleMenuClick(item.url.replace('/', ''))}
                      // skipNav={item.url === '/tools'}
                      nested={item.subItems && item.subItems?.length > 0}
                      {...item}
                    />
                    {item.subItems &&
                      (menuId === item.url.replace('/', '') ||
                        currentPath.startsWith(`${item.url}/`)) && (
                        <List
                          key={`${item.label}-sub`}
                          size="sm"
                          sx={{
                            '--List-padding': '0',
                            rowGap: 1,
                          }}
                        >
                          {item.subItems.map((subItem) => {
                            return (
                              <NavBarItem
                                key={subItem.label}
                                expanded={isOpen}
                                isActive={currentPath.startsWith(subItem.url)}
                                isSubItem
                                {...subItem}
                              />
                            );
                          })}
                        </List>
                      )}
                  </React.Fragment>
                );
              })}
            </List>
          </Stack>
        </Stack>
        {/* Bottom Menu */}
        <Stack
          sx={{
            height: 'fit-content',
            flex: 0,
            p: 1,
          }}
        >
          <List
            sx={{
              textAlign: isOpen ? 'left' : 'center',
              rowGap: 1,
            }}
          >
            {profile?.email.endsWith('sakari.io') && (
              <OverlayPopup
                open={menuId === 'language-menu'}
                onOpenClose={() => handleMenuClick('language-menu')}
                offset={[0, 20]}
                placement="right"
                renderButton={(setAnchorEl, toggleOpen) => {
                  return (
                    <NavBarItem
                      expanded={isOpen}
                      label={t(`languageMenu.${language}.label`)}
                      ref={setAnchorEl}
                      onClick={toggleOpen}
                    />
                  );
                }}
                renderPopup={() => (
                  <LanguageMenu
                    onSelectLanguage={(language) =>
                      handleSelectLanguage(language)
                    }
                    onClose={() => handleMenuClick('language-menu')}
                    isSelected={(id) => id === language}
                  />
                )}
              />
            )}
            <NavBarItem
              key="featurerequests"
              expanded={isOpen}
              isActive={currentPath.startsWith('/featurerequests')}
              label={t('featureRequests')}
              url="/featurerequests"
              icon={{
                default: <FontAwesomeIcon icon={regular('lightbulb-on')} />,
                active: <FontAwesomeIcon icon={solid('lightbulb-on')} />,
              }}
            />
            <OverlayPopup
              open={menuId === 'support-menu'}
              onOpenClose={() => handleMenuClick('support-menu')}
              offset={[0, 20]}
              placement="right"
              renderButton={(setAnchorEl, toggleOpen) => (
                <NavBarItem
                  expanded={isOpen}
                  icon={{
                    default: (
                      <FontAwesomeIcon icon={regular('question-circle')} />
                    ),
                  }}
                  label={t('supportCenter')}
                  ref={setAnchorEl}
                  onClick={() => toggleOpen(false)}
                />
              )}
              renderPopup={() => (
                <SupportMenu account={account} profile={profile} />
              )}
            />
            <OverlayPopup
              open={menuId === 'settings-menu'}
              onOpenClose={() => handleMenuClick('settings-menu')}
              offset={[0, 20]}
              sx={{
                zIndex: 'var(--joy-zIndex-tooltip)',
              }}
              placement="right-end"
              renderButton={(setAnchorEl, toggleOpen) => (
                <NavBarItem
                  expanded={isOpen}
                  icon={{ default: <FontAwesomeIcon icon={regular('cog')} /> }}
                  label={t('settingsMenu.label')}
                  ref={setAnchorEl}
                  onClick={toggleOpen}
                  isActive={
                    currentPath.startsWith('/settings') ||
                    currentPath.startsWith('/profile')
                  }
                />
              )}
              renderPopup={() => {
                if (profile && account)
                  return (
                    <SettingsMenu
                      id="settings-menu"
                      account={account}
                      profile={profile}
                      role={userRole as Role}
                      variant="outlined"
                      onClose={() => handleMenuClick('settings-menu')}
                      sx={{
                        width: 250,
                      }}
                    />
                  );
                return <Card>{t('noProfileFound')}</Card>;
              }}
            />
          </List>
        </Stack>
      </Stack>
      <Tutorial isOpen={tutorialOpen} setIsOpen={handleToggleTutorial} />
    </Drawer>
  );
}

export default NavBar;
