/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import {
  WorkflowNodeProperty,
  WorkflowNodePropertyType,
  WorkflowNodeType,
  WorkflowProperty,
  Delay,
  AdvancedDelay,
  WorkflowNodeConfig,
  GoogleSheet,
  GoogleSheetsExportNodeConfig,
  WorkflowNodeTypeValidation,
  WorkflowNodePropertyCondition,
  Property,
} from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { WorkflowEdgeProps } from '../../pages/Workflows/Canvas/EdgeTypes/WorkflowEdge/index.js';

const isObjectId = (val: string) => val?.length === 24;

const validateGroupProp = (value: any, required: boolean = true) => {
  if (required && !value) {
    return false;
  }

  return !!(isObjectId(value?.id) && value?.name);
};

const validateGroupsProp = (value: any, mandatory?: boolean) => {
  if (!_.isArray(value)) {
    return false;
  }

  if (!value?.length && mandatory) {
    return false;
  }

  return !value.find((g) => !validateGroupProp(g, true));
};

const validateDynamicGroupProp = (value: any) => {
  if (!value?.type) {
    return false;
  }

  switch (value.type) {
    case 'group':
      return !!(isObjectId(value?.group?.id) && value?.group?.name);
    case 'custom':
      return true;
    default:
      return false;
  }
};

const validateToProp = (value: any) => {
  switch (value?.type) {
    case 'contact': // trigger contact and outuput contact
      return !!value?.contact;
    case 'user': //internal
      return !!(value.user?.mobile?.number && value.user?.mobile?.country);
    case 'custom':
      return !!(value?.mobile?.country && value?.mobile?.number);
    default:
      return false;
  }
};

const validateComposerProp = (value: any) => {
  return !!(value?.message?.length || value?.media?.length);
};

const validateConditionProp = (condition: any) => {
  // logger.info('validateConditionProp', condition);
  if (!condition?.attribute || !condition?.comparator) return false;

  if (Array.isArray(condition.value)) {
    if (!condition.value.length) {
      return false;
    }

    return _.uniq(condition.value).length === condition.value.length;
  }

  return condition.value !== undefined;
};

const validateConditionsProp = (value: any) => {
  logger.info('validateConditionsProp', value);
  if (!value?.length) return false;

  return value.every(validateConditionProp);
};

const validatePropertyProp = (value: any) => {
  // switch (value?.type) {
  //   case 'contact':
  //   case 'workflow':
  //     return !!value?.name;
  //   case 'output':
  //     return !!value?.name && value?.name.indexOf('.') > 0;
  //   default:
  //     return false;
  // }
  return !!value;
};

const validateInitialDelay = (initialDelay: Delay) => {
  if (!initialDelay || typeof initialDelay !== 'object') return false;

  const { days, hours, minutes } = initialDelay;

  if (days === 0 && hours === 0 && minutes === 0) {
    return false;
  }

  return (
    typeof days === 'number' &&
    typeof hours === 'number' &&
    typeof minutes === 'number'
  );
};

const validateScheduleRelease = (
  additionalTime: AdvancedDelay['additionalTime'],
) => {
  if (!additionalTime) return false;

  const { days } = additionalTime;

  if (
    !Array.isArray(days) ||
    !days.every((day) => [0, 1, 2, 3, 4, 5, 6].includes(day))
  ) {
    return false;
  }

  if (days?.length === 0) return false;

  return true;
};

const validateScheduleTime = (
  additionalTime: AdvancedDelay['additionalTime'],
) => {
  if (!additionalTime?.time) return false;

  const { hour, minute, timezone } = additionalTime?.time || {};

  const isValidTypes =
    typeof hour === 'number' &&
    typeof minute === 'number' &&
    typeof timezone === 'string';

  if (!isValidTypes) {
    return false;
  }
  if (hour === 0 && minute === 0) {
    return false;
  }
  return true;
};

const validateDelayProp = (value: AdvancedDelay | undefined) => {
  // validation check here

  if (!value) return false;
  const { initialDelay, additionalTime } = value;

  if (!initialDelay && !additionalTime) {
    return false;
  }

  if (initialDelay && !validateInitialDelay(initialDelay)) {
    return false;
  }

  // logger.info('additionalTime', additionalTime);
  if (additionalTime?.days !== undefined) {
    if (!validateScheduleRelease(additionalTime)) {
      return false;
    }
  }

  if (additionalTime?.time !== undefined) {
    if (!validateScheduleTime(additionalTime)) {
      return false;
    }
  }

  return true;
};

const validateWorkflowPropertyProp = (value: WorkflowProperty) => {
  return !!value;
};

const validateUpdateProp = (
  value: { property: Property; value: string }[],
): boolean => {
  if (Array.isArray(value)) {
    return value.every((update) => {
      return update?.property && update?.value;
    });
  }

  return false;
};

const validateGoogleSheetObject = (googlesheet: GoogleSheet) => {
  return !!(
    googlesheet &&
    googlesheet.connectionId &&
    googlesheet.id &&
    googlesheet.name &&
    googlesheet.tab
  );
};

const validateColumnMappings = (
  columnmappings: GoogleSheetsExportNodeConfig,
) => {
  logger.info('validateColumnMappings', columnmappings);
  return (
    Array.isArray(columnmappings) &&
    columnmappings.every((mapping) => {
      return (
        mapping.column &&
        mapping.column.index !== undefined &&
        mapping.column.name &&
        mapping.value?.length
      );
    })
  );
};

const validateGoogleSheetColumn = (config: { index: number; name: string }) => {
  return config !== undefined;
};

const validateErrorsProp = (value: any, mandatory: boolean) => {
  if (!value || value?.length === 0) {
    if (mandatory) {
      return false;
    }

    return true;
  }

  return !value.find((v: any) => {
    const { code } = v;
    return code ? !/[A-Z]{3,4}-\d{3}/.test(code) : false;
  });
};

export const areEdgeValuesUnique = (edges: WorkflowEdgeProps[]) => {
  const edgesByValue = _.groupBy(
    edges,
    (e) => `${e.data?.edge?.value}-${e.data?.edge?.type}`,
  );
  logger.info('edgesByValue', edgesByValue);
  const duplicated = _.pickBy(edgesByValue, (v, k) => v?.length > 1 && k);
  logger.info('duplicated', duplicated);
  return _.keys(duplicated)?.length === 0;
};

const validateTimeout = (config: Delay) => {
  if (!config) {
    return true;
  }

  return !!(config?.days || config?.hours || config?.minutes);
};

const validateContactSelect = (value: any) => !!value?.nodeId && !!value.path;

const validateUserIntegration = (value: any) => !!value;

const isNodePropertyComplete = (
  prop: WorkflowNodeProperty,
  value: any,
  validation: WorkflowNodeType['validation'],
  edges?: WorkflowEdgeProps[],
): boolean => {
  if (prop.mandatory && value === undefined && !edges?.length) {
    return false;
  }

  if (prop.mandatory && value === undefined && edges?.length) {
    if (
      validation?.numEdgeCondition === 'exactly' &&
      validation.numEdges !== edges?.length
    ) {
      return false;
    }
    if (
      validation?.numEdgeCondition === 'at least' &&
      (validation.numEdges || 0) > edges?.length
    ) {
      return false;
    }
  }

  switch (prop.type) {
    case WorkflowNodePropertyType.List:
    case WorkflowNodePropertyType.Link:
      return !!(isObjectId(value?.id) && value?.name);

    case WorkflowNodePropertyType.Group:
      return validateGroupProp(value);
    case WorkflowNodePropertyType.Groups:
      return validateGroupsProp(value, prop.mandatory);
    case WorkflowNodePropertyType.Dynamicgroup:
      return validateDynamicGroupProp(value);

    case WorkflowNodePropertyType.To:
      return validateToProp(value);

    case WorkflowNodePropertyType.Composer:
      return validateComposerProp(value);

    case WorkflowNodePropertyType.Filtercondition:
    case WorkflowNodePropertyType.Contactattributecondition:
      if (prop.min !== undefined) {
        return validateConditionsProp(value);
      }
      return validateConditionProp(value);

    case WorkflowNodePropertyType.Property:
      return validatePropertyProp(value);

    case WorkflowNodePropertyType.Update:
      return validateUpdateProp(value);

    case WorkflowNodePropertyType.Basictext:
    case WorkflowNodePropertyType.Text:
    case WorkflowNodePropertyType.Password:
    case WorkflowNodePropertyType.Code:
    case WorkflowNodePropertyType.Url:
      return !!value?.length || !prop.mandatory;
    case WorkflowNodePropertyType.Checkbox:
      return value !== undefined || !prop.mandatory;

    case WorkflowNodePropertyType.Number:
      return !!value && _.isNumber(value);

    case WorkflowNodePropertyType.Dropdown:
      return !!(prop.options || []).find((opt) => opt.value === value);
    case WorkflowNodePropertyType.Apidropdown:
      return !!value || !prop.mandatory;
    case WorkflowNodePropertyType.Split:
      return value !== undefined && value >= 0 && value <= 100;
    case WorkflowNodePropertyType.Workflowproperty:
      return validateWorkflowPropertyProp(value);
    case WorkflowNodePropertyType.Delay:
      return validateDelayProp(value);
    case WorkflowNodePropertyType.Edges:
      return true;
    case WorkflowNodePropertyType.Errors:
      return validateErrorsProp(value, false);

    case WorkflowNodePropertyType.Googlesheet:
      return validateGoogleSheetObject(value);
    case WorkflowNodePropertyType.Googlesheetcolumnmappings:
      return validateColumnMappings(value);
    case WorkflowNodePropertyType.Googlesheetcolumn:
      return validateGoogleSheetColumn(value);

    case WorkflowNodePropertyType.Userintegration:
      return validateUserIntegration(value);

    case WorkflowNodePropertyType.Timeout:
      return validateTimeout(value);

    case WorkflowNodePropertyType.Contactselect:
      return validateContactSelect(value);

    default:
      logger.error('unhandled property type:', prop.type);
      throw new Error(`Property type "${prop.type}" is not supported`);
  }
};

export const isPropertyConditionValid = (
  condition: WorkflowNodePropertyCondition,
  config: WorkflowNodeConfig,
) => {
  if (!condition) {
    return true;
  }

  const propertyValue = _.get(config, condition.property);
  logger.info('propertyValue', propertyValue);
  logger.info('comparator', condition.comparator);
  logger.info('condition.value', condition.value);

  switch (condition.comparator) {
    case '=':
      return propertyValue === condition.value;
    case 'is':
      return !!(condition.value === 'present' && propertyValue !== undefined);
    default:
      return false;
  }
};

export const isNodeCompleteSub = (
  properties: WorkflowNodeProperty[],
  validation: WorkflowNodeTypeValidation,
  config: WorkflowNodeConfig,
  edges?: WorkflowEdgeProps[],
) => {
  let valid = true;
  if (properties.length === 0 && validation?.numEdgeCondition === 'at least') {
    if ((validation.numEdges || 0) > (edges?.length || 0)) {
      valid = false;
    }
  }
  const props = properties?.reduce(
    (res, prop) => {
      if (
        (prop.conditions || []).find((condition) => {
          const v = isPropertyConditionValid(condition, config);
          return !v;
        })
      ) {
        return res;
      }

      const propValid = isNodePropertyComplete(
        prop,
        (config as any)?.[prop.name],
        validation,
        edges,
      );
      res[prop.name] = propValid;

      if (!propValid) {
        valid = false;
      }

      return res;
    },
    {} as { [key: string]: boolean },
  );

  const mandatory = validation?.edges?.find((e) => e.value?.mandatory);

  if (mandatory && !areEdgeValuesUnique(edges || [])) {
    logger.info('areEdgeValuesUnique', false, edges);
    valid = false;
  }

  if (!valid) {
    logger.info('props invalid', props);
  }

  return {
    valid,
    properties: props,
  };
};

export const isNodeComplete = (
  nodeType: WorkflowNodeType,
  config: WorkflowNodeConfig,
  edges?: WorkflowEdgeProps[],
) => {
  return isNodeCompleteSub(
    nodeType.properties || [],
    nodeType.validation!,
    config,
    edges,
  );
};
