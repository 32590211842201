enum ActionType {
  // TODO are these login actions used?
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  // CONTACT_RESPONSE_ALERT = 'CONTACT_RESPONSE_ALERT',

  // TOAST = 'TOAST',

  NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE',
}

export default ActionType;
