import * as React from 'react';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import { List, ListProps } from '@mui/joy';
import IntegrationListItem from './Item';
import { IntegrationProperty } from '../../cards/IntegrationCard/integrationProperty';

interface IntegrationPropertyListProps extends Omit<ListProps, 'onDragEnd'> {
  items: IntegrationProperty[];
  onDragEnd: OnDragEndResponder;
  isEditMode: boolean;
  onVisibility: (item: IntegrationProperty) => void;
}

const IntegrationPropertyList = React.memo(
  ({
    items,
    onDragEnd,
    isEditMode,
    onVisibility,
    ...listProps
  }: IntegrationPropertyListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List
              sx={{
                '--ListItem-paddingX': '1rem',
                '& .MuiListDivider-root:last-child': {
                  display: 'none',
                },
              }}
              {...listProps}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map((item, index) => {
                return (
                  <IntegrationListItem
                    isEditMode={isEditMode}
                    item={item}
                    index={index}
                    key={index}
                    onVisibility={(visibility: boolean) => {
                      onVisibility({
                        name: item.name,
                        position: index,
                        visible: visibility,
                      });
                    }}
                  />
                );
              })}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  },
);

export default IntegrationPropertyList;
