import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Chip, Stack } from '@mui/joy';

import { format } from '@sakari-io/sakari-common';
import { skipToken } from '@reduxjs/toolkit/query';
import { Timer, logger } from '@sakari-io/sakari-components';
import ActionButtons from '../ActionButtons';
import Bandwidth from '../Bandwidth';
import {
  useEndCallMutation,
  useSendDtmfMutation,
  useStartSessionQuery,
} from '../../../../api';

import Mute from '../Controls/Mute';
import Keypad from '../Controls/Keypad';
import Contact from '../Controls/Contact';
import Transfer from '../Controls/Transfer';
import { ActiveCall as ActiveCallType } from '../../../../types';
import { AccountContext } from '../../../../contexts/account.context';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

interface ActiveCallProps {
  call: ActiveCallType;
}

function ActiveCall({ call }: ActiveCallProps) {
  const { t } = useTranslation('voice');
  const { account } = useContext(AccountContext);
  const dispatch = useAppDispatch();
  const [muteStatus, setMuteStatus] = useState<boolean>();

  const { data: sessionData } = useStartSessionQuery(
    account ? account.id : skipToken,
  );

  const [endCall] = useEndCallMutation();
  const [sendDtmf] = useSendDtmfMutation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '16px',
          flexGrow: 1,
        }}
      >
        {call.group && (
          <Chip color="primary" sx={{ marginRight: 2 }}>
            {t('to')}: {call.group?.name}
          </Chip>
        )}
        {call.target && call.target.user?.id !== call?.user?.id ? (
          <Typography level="title-md" sx={{ color: 'white' }}>
            {t('transferingTo')}
            {call.target.user
              ? `${format.name(call.target.user)}`
              : call.target.group?.name}
          </Typography>
        ) : (
          <Stack>
            <Typography sx={{ color: 'var(--joy-palette-voice-softHoverBg)' }}>
              <Timer />
            </Typography>
            <Typography
              level="title-md"
              sx={{ color: 'white', fontWeight: 'bold' }}
            >
              {format.nameOrMobile(call.contact)}
            </Typography>
            {call.contact.id ? <Contact contactId={call.contact.id} /> : null}
          </Stack>
        )}
      </Box>
      <Box
        sx={{
          paddingRight: '14px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 14px',
          height: '100%',
          color: 'white',
        }}
      >
        <Mute status={muteStatus} onStatusChanged={setMuteStatus} />
        <Keypad onDigits={(digits) => sendDtmf({ id: call.id, digits })} />
        {/* <Record
              onStart={() => startRecording(call.id)}
              onPause={() => pauseRecording(call.id)}
              onResume={() => resumeRecording(call.id)}
            /> */}
      </Box>
      <ActionButtons
        topBtn={<Transfer setMuteStatus={setMuteStatus} />}
        bottomBtn={`${t('endCall')}`}
        bottomAction={() =>
          endCall(call.id)
            .unwrap()
            .catch((err) => {
              logger.error('unable to end call', err);
              dispatch(
                showToast({
                  message: 'Unable to end call',
                  severity: 'error',
                }),
              );
            })
        }
      />
      {sessionData?.data.token && (
        <Bandwidth token={sessionData?.data.token} muted={muteStatus} />
      )}
    </>
  );
}

export default ActiveCall;
