import React from 'react';
import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import Switch from '@mui/joy/Switch';
import TextField from '../../../ui/atoms/inputs/TextField';
import FieldWrapper from '../../../utils/FieldWrapper';
import Select from '../../../ui/atoms/inputs/Select';

import Setting from '../../../ui/templates/forms/SettingForm';
import Integration from '../IntegrationEdit';
import UserSync from '../../../ui/organisms/forms/UserSync';
import HubSpotInbox from '../../../ui/organisms/forms/HubSpotInbox';

function HubSpot() {
  const { t } = useTranslation();

  const eventNotifOptions = [
    {
      label: t(
        'integrations.hubspot.eventNotifications.options.timelineMessageInfo',
      ),
      value: 'timeline_messageInfo',
    },
    {
      label: t(
        'integrations.hubspot.eventNotifications.options.messageInfoInbound',
      ),
      value: 'messageInfoInbound',
    },
    {
      label: t('integrations.hubspot.eventNotifications.options.none'),
      value: 'none',
    },
  ];

  const mobilePhoneOptions = [
    {
      label: t('integrations.hubspot.mobilePhone.options.mobilePhone'),
      value: 'mobilephone,phone',
    },
    {
      label: t('integrations.hubspot.mobilePhone.options.phoneMobile'),
      value: 'phone,mobilephone',
    },
    {
      label: t('integrations.hubspot.mobilePhone.options.mobile'),
      value: 'mobilephone',
    },
    {
      label: t('integrations.hubspot.mobilePhone.options.phone'),
      value: 'phone',
    },
  ];

  // const formatSave = (values: any) => {
  //   const hubspotVals = _.pick(values, [
  //     'user',
  //     'hub_domain',
  //     'timeline',
  //     'messageInfoInbound',
  //     'createContact',
  //     'mobilePhone',
  //     'userSyncRole',
  //     'userSyncGroup',
  //     'inbox',
  //   ]);

  //   if (values.eventNotifications) {
  //     if (values.eventNotifications === 'timeline_msgInfo') {
  //       hubspotVals.timeline = true;
  //       hubspotVals.messageInfoInbound = true;
  //     } else if (values.eventNotifications === 'msgInfo') {
  //       hubspotVals.messageInfoInbound = true;
  //       hubspotVals.timeline = false;
  //     } else if (values.eventNotifications === 'none') {
  //       hubspotVals.timeline = false;
  //       hubspotVals.messageInfoInbound = false;
  //     }
  //   }

  //   if (values.userSync) {
  //     hubspotVals.userSyncRole = values.userSync.role;
  //     hubspotVals.userSyncGroup = { id: values.userSync.group.id };
  //   }

  //   hubspotVals.inbox = values?.inbox?.map((val: any) => {
  //     return {
  //       group: { id: val.group.id },
  //       destination: { id: val.destination.id },
  //     };
  //   });

  //   return hubspotVals;
  // };

  return (
    <Integration title="HubSpot" name="hubspot">
      <Setting
        label={t('integrations.hubspot.user.label')}
        description={t('integrations.hubspot.user.description')}
        spacer="0px 30px 0px 0px"
      >
        <FieldWrapper
          component={TextField}
          fullWidth
          name="user"
          sx={{
            pointerEvents: 'none',
          }}
        />
      </Setting>

      <Setting
        label={t('integrations.hubspot.connectHubSpot.label')}
        description={t('integrations.hubspot.connectHubSpot.description')}
        spacer="0px 30px 0px 0px"
      >
        <FieldWrapper
          component={TextField}
          fullWidth
          name="hub_domain"
          sx={{
            pointerEvents: 'none',
          }}
        />
      </Setting>

      <Setting
        label={t('integrations.hubspot.eventNotifications.label')}
        description={t('integrations.hubspot.eventNotifications.description')}
        spacer="0px 30px 0px 0px"
      >
        <FieldWrapper
          component={Select}
          fullWidth
          name="eventNotifications"
          options={eventNotifOptions}
        />
      </Setting>

      <Setting
        label={t('integrations.hubspot.createContacts.label')}
        description={t('integrations.hubspot.createContacts.description')}
        spacer="0px 30px 0px 0px"
      >
        <FieldWrapper component={Switch} name="createContact" type="checkbox" />
      </Setting>

      <Setting
        label={t('integrations.hubspot.mobilePhone.label')}
        description={t('integrations.hubspot.mobilePhone.description')}
        spacer="0px 30px 0px 0px"
      >
        <FieldWrapper
          component={Select}
          fullWidth
          name="mobileProperty"
          options={mobilePhoneOptions}
        />
      </Setting>

      <Typography level="title-md">
        {t('integrations.hubspot.userSync.title')}
      </Typography>

      <FieldWrapper component={UserSync} name="userSync" />

      <Typography level="title-md">
        {t('integrations.hubspot.inbox.title')}
      </Typography>

      <FieldWrapper component={HubSpotInbox} name="inbox" />
    </Integration>
  );
}

export default HubSpot;
