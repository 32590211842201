import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNodes,
  faBullseye,
  faSitemap,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

export const iconMap: {
  [key: string]: { icon: IconDefinition; color: string };
} = {
  contact: {
    icon: faBullseye,
    color: 'var(--joy-palette-primary-500)',
  },
  workflow: {
    icon: faSitemap,
    color: 'var(--joy-palette-primary-500)',
  },
  output: {
    icon: faCircleNodes,
    color: 'var(--joy-palette-primary-500)',
  },
  trigger: {
    icon: faBullseye,
    color: 'var(--joy-palette-primary-500)',
  },
  outputContact: {
    icon: faUser,
    color: 'var(--joy-palette-primary-500)',
  },
};

export const labelMap: { [key: string]: string } = {
  contact: 'Enrollment Contact Property',
  workflow: 'Workflow Property',
  output: 'Output Property',
};
