import React from 'react';
import _ from 'lodash';

import { Box, Divider, Sheet, Stack, Typography } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { AttributeMapping } from '../../../../utils/fieldValidationMapping';

import FieldMapping from './FieldMapping';

interface FieldMappingsProps {
  columns: string[];
  value: AttributeMapping[];
  onChange: (v: AttributeMapping[]) => any;
}

function FieldMappings({ value, onChange, columns }: FieldMappingsProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Typography level="body-md" fontWeight="lg" textColor="text.primary">
        {t('mapFields')}
      </Typography>
      <Divider />

      <Stack direction="row" gap={2}>
        <Sheet
          variant="soft"
          color="primary"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '8px',
          }}
        >
          <FontAwesomeIcon icon={faUser} fontSize="16pt" />
        </Sheet>

        <Box>
          <Typography level="body-sm" fontWeight="md" textColor="text.primary">
            {t('contactAttributes')}
          </Typography>
          <Typography level="body-xs">{t('contactsDatabase')}</Typography>
        </Box>
      </Stack>

      <Stack spacing={1}>
        {(value || [])
          // .filter((v) => CORE_ATTRIBUTES.includes(v.attribute))
          .map((mapping) => {
            return (
              <FieldMapping
                key={mapping.attribute}
                attribute={mapping.attribute}
                value={mapping.column}
                columns={columns}
                onChange={(column: string) => {
                  onChange(
                    value.map((v) => {
                      return v.attribute === mapping.attribute
                        ? _.assign({}, v, { column })
                        : v;
                    }),
                  );
                }}
                onDelete={(attr) =>
                  onChange(value.filter((v) => v.attribute !== attr))
                }
              />
            );
          })}
      </Stack>
    </Stack>
  );
}

export default FieldMappings;
