import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Account } from '@sakari-io/sakari-typings';
import HubSpotInboxSelect from '../HubSpotInboxSelect';
import AssignGroups from '../AssignGroups';
import { GroupAssignment } from '../AssignGroups/AssignGroup';

interface HubSpotAssignGroupsProps {
  account: Account;
  value: GroupAssignment[];
  onChange: (value: GroupAssignment[]) => any;
}

function HubSpotAssignGroups({
  account,
  value,
  onChange,
}: HubSpotAssignGroupsProps) {
  const { t } = useTranslation();

  return (
    <AssignGroups
      value={value || [{ id: '1' }]}
      onChange={onChange}
      renderDestinationLabel="HubSpot Conversations Inbox"
      groupTooltip={t('integrations.hubspot.inbox.assignGroups.description')}
      destinationTooltip={t(
        'integrations.hubspot.inbox.forwardingAddress.description',
      )}
      renderDestination={(value, onChange) => (
        <HubSpotInboxSelect
          account={account}
          value={value.destination}
          onChange={(destination) =>
            onChange(_.assign({}, value, { destination }))
          }
        />
      )}
    />
  );
}

export default HubSpotAssignGroups;
