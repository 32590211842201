import React from 'react';
import { Typography, IconButton, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

interface VerifyDomainProps {
  domain: string;
  verified?: boolean;
  loading?: boolean;
  onVerify: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function VerifiedDomain({
  domain,
  verified,
  loading,
  onVerify,
  onDelete,
}: VerifyDomainProps) {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <IconButton
        aria-label="Verify Custom Domain"
        disabled={verified || loading}
        color={verified ? 'success' : 'primary'}
        onClick={onVerify}
      >
        <FontAwesomeIcon
          icon={verified ? solid('badge-check') : solid('arrows-spin')}
        />
      </IconButton>
      <Typography fontWeight="bold" textColor="text.secondary">
        {domain}
      </Typography>
      <IconButton
        aria-label="Delete Custom Domain"
        disabled={loading}
        onClick={onDelete}
      >
        <FontAwesomeIcon icon={regular('trash-can')} />
      </IconButton>
    </Stack>
  );
}

export default VerifiedDomain;
