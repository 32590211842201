import React, { useContext } from 'react';
import { Link } from '@sakari-io/sakari-typings';
import { AutocompleteOption, Stack, Typography } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/query';
import { ObjectAutoComplete } from '@sakari-io/sakari-components';
import { useGetLinksQuery } from '../../../../../../api';
import { AccountContext } from '../../../../../../contexts/account.context';

interface LinkAutoCompleteProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  value?: Link;
  onChange: (value?: Link) => void;
  disabled?: boolean;
}

function LinkAutoComplete({
  label,
  placeholder,
  helperText,
  value,
  onChange,
  disabled,
}: LinkAutoCompleteProps) {
  const { account } = useContext(AccountContext);

  return (
    <ObjectAutoComplete
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      fetch={useGetLinksQuery}
      query={(input: string) =>
        account
          ? {
              accountId: account.id,
              request: {
                limit: 10,
                q: input,
              },
            }
          : skipToken
      }
      value={value}
      onChange={onChange}
      disabled={disabled}
      renderOption={(props: any, option: any) => (
        <AutocompleteOption {...props}>
          <Stack>
            <Typography level="body-md">
              {option?.name ?? '[Untitled]'}
            </Typography>
            <Typography level="body-sm" overflow="hidden" title={option?.label}>
              {option?.destinationUrl}
            </Typography>
          </Stack>
        </AutocompleteOption>
      )}
    />
  );
}

export default LinkAutoComplete;
