import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';
import {
  Typography,
  IconButton,
  Tooltip,
  Button,
  Stack,
  ButtonProps,
} from '@mui/joy';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';

interface PageHeaderProps {
  id?: string;
  backAction?: (values: any) => void;
  title: string | ReactNode;
  subheading?: string;
  cancelText?: string;
  cancelLink?: string;
  cancelAction?: (values: any) => any;
  ctaText?: string;
  ctaAction?: (values: any) => void;
  ctaDisabled?: boolean;
  ctaTooltip?: string;
  divider?: boolean;
  addTooltip?: string;
  optionalContent?: any;
  loading?: boolean;
  sx?: any;
  ctaVariant?: {
    color?: ButtonProps['color'];
    variant?: 'solid' | 'soft' | 'outlined' | 'plain';
  };
}

function PageHeader({
  id,
  title,
  subheading,
  cancelText,
  cancelLink,
  cancelAction,
  ctaText,
  ctaAction,
  ctaDisabled = false,
  ctaTooltip,
  backAction,
  divider,
  addTooltip,
  optionalContent,
  loading,
  sx,
  ctaVariant,
}: PageHeaderProps) {
  const shouldRenderCancel = cancelText && cancelLink && cancelAction;
  const shouldRenderCta = ctaText && ctaAction;

  const ctaButton = (
    <Button
      disabled={ctaDisabled}
      variant={ctaVariant?.variant || 'solid'}
      color={ctaVariant?.color || 'primary'}
      onClick={ctaAction}
      aria-label={ctaText}
      loading={loading}
      sx={{
        width: 'max-content',
      }}
    >
      {ctaText}
    </Button>
  );

  const cta = ctaTooltip ? (
    <Tooltip title={ctaTooltip}>
      <span>{ctaButton}</span>
    </Tooltip>
  ) : (
    ctaButton
  );

  const renderCta = () => {
    if (shouldRenderCta) {
      return addTooltip ? (
        <Tooltip title={addTooltip} arrow>
          {cta}
        </Tooltip>
      ) : (
        cta
      );
    }
    return null;
  };

  return (
    <Stack
      id={id || 'page-header'}
      sx={{
        flexFlow: 'row nowrap',
        py: 3,
        px: 4,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        height: '84px',
        boxSizing: 'border-box',
        borderBottom: divider ? `1px solid var(--joy-palette-divider)` : 'none',
        ...sx,
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
        {backAction ? (
          <IconButton
            onClick={backAction}
            color="neutral"
            variant="outlined"
            sx={{
              height: '36px',
              width: '36px',
              borderRadius: '8px',
              padding: 'auto',
              background: 'var(--joy-palette-background-surface)',
            }}
            aria-label="Back"
          >
            <FontAwesomeIcon icon={faAngleLeft} size="xs" />
          </IconButton>
        ) : null}
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
            flexShrink: 0,
          }}
        >
          <Typography level="h3">{title}</Typography>
          {subheading && (
            <Typography level="body-sm" gutterBottom>
              {subheading}
            </Typography>
          )}
        </Stack>
      </Stack>
      {optionalContent && (
        <Stack
          sx={{ flexDirection: 'row', gap: 2, alignItems: 'center', flex: 1 }}
        >
          {optionalContent}
        </Stack>
      )}
      {shouldRenderCta || shouldRenderCancel ? (
        <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          {cancelText && cancelLink && (
            <Link to={cancelLink} style={{ textDecoration: 'none' }}>
              <Typography level="body-sm" aria-label={cancelText}>
                {cancelText}
              </Typography>
            </Link>
          )}
          {cancelText && cancelAction && (
            <Typography
              sx={{ cursor: 'pointer' }}
              level="body-sm"
              aria-label={cancelText}
              onClick={cancelAction}
            >
              {cancelText}
            </Typography>
          )}
          {renderCta()}
        </Stack>
      ) : null}
    </Stack>
  );
}

export default PageHeader;
