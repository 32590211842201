import { Stack, StackProps, styled } from '@mui/joy';

interface ContainerProps extends StackProps {
  backgroundColor?: string;
  sx?: any;
  spacing?: number;
}

const Container = styled(Stack)<ContainerProps>(
  ({ backgroundColor = 'transparent', sx, spacing }) => ({
    padding: '16px 32px',
    backgroundColor,
    flex: '1',
    overflow: 'auto',
    spacing,
    ...sx,
  }),
);

export default Container;
