import {
  SakariAPIResponse,
  SearchablePaginationRequest,
  AvailableIntegration,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { buildGridQuery } from './common';

const AVAILABLE_INTEGRATION_TAG = 'AvailableIntegration';

// interface SyncUsersRequestWrapper {
//   data: SyncUsersRequest;
//   type: string;
// }

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableIntegrations: builder.query<
      SakariAPIResponse<AvailableIntegration[]>,
      SearchablePaginationRequest
    >({
      query: (params) => `marketplace?${buildGridQuery(params)}`,
      providesTags: [AVAILABLE_INTEGRATION_TAG],
    }),
    getAvailableIntegration: builder.query<
      SakariAPIResponse<AvailableIntegration>,
      string
    >({
      query: (id) => `marketplace/${id}`,
      providesTags: [AVAILABLE_INTEGRATION_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAvailableIntegrationsQuery,
  useGetAvailableIntegrationQuery,
} = extendedApi;
