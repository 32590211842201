import React, { useEffect, useState } from 'react';
import { Group } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import _ from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import OverlayPopup, {
  OverlayPopupProps,
} from '../../../templates/OverlayPopup';
import SearchableMenu from '../../../molecules/Menu/SearchableMenu';
import InputControl, {
  InputControlProps,
} from '../../../molecules/InputControl';
import { useGetGroupQuery, useGetGroupsQuery } from '../../../../api';

const LIMIT = 20;

interface GroupSelectProps
  extends Omit<InputControlProps, 'onChange' | 'value'>,
    Pick<OverlayPopupProps, 'placement'> {
  accountId: string;
  fullWidth?: boolean;
  value: Group | undefined;
  onChange: (value: Group) => void;
  placeholder?: string;
  size?: 'sm' | 'md' | 'lg';
  collapsedStyle?: any;
  expandedStyle?: any;
  noGap?: boolean;
  addUnassignedGroup?: boolean;
  addAllGroups?: boolean;
  altLabel?: boolean;
  variant?: 'primary' | 'secondary';
}

function GroupSelect({
  accountId,
  value,
  onChange,
  fullWidth,
  collapsedStyle,
  expandedStyle,
  placeholder,
  size = 'md',
  noGap,
  addUnassignedGroup,
  addAllGroups,
  placement,
  altLabel,
  variant,
  ...rest
}: GroupSelectProps) {
  const { t } = useTranslation();

  const [currentGroup, setCurrentGroup] = useState<Group | undefined>(value);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const debouncedSearch = useDebouncedValue(search, 300);

  const {
    data: group,
    isLoading: isLoadingGroup,
    refetch,
  } = useGetGroupQuery(
    value?.id && accountId
      ? {
          accountId: accountId || '',
          request: value?.id,
        }
      : skipToken,
  );

  const {
    data: groups,
    isLoading,
    isFetching,
  } = useGetGroupsQuery({
    accountId,
    request: {
      offset,
      limit: LIMIT,
      q: debouncedSearch,
    },
  });

  useEffect(() => {
    setOffset(0);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!value?.name && value?.id && !isLoading && group?.data) {
      handleChange(group.data);
    }
  }, [value, isLoadingGroup]);

  useEffect(() => {
    if (group?.data && currentGroup && !currentGroup.name) {
      refetch();
      handleChange(group.data);
    }
  }, [value, currentGroup, refetch, group?.data]);

  const handleInput = (newInput: string) => {
    setSearch(newInput);
  };

  const handleChange = (newValue: Group) => {
    setCurrentGroup(newValue);
    onChange(newValue);
  };

  let color: 'primary' | 'neutral' | 'danger' = 'neutral';

  if (rest.error) {
    color = 'danger';
  } else if (variant === 'primary') {
    color = 'primary';
  } else {
    color = 'neutral';
  }

  return (
    <InputControl
      {...{
        fullWidth,
        noGap,
        ...rest,
      }}
    >
      <OverlayPopup
        open={showMenu}
        onOpenClose={setShowMenu}
        placement={placement ?? 'bottom-start'}
        renderButton={(setAnchorEl, onClick) => (
          <Button
            ref={setAnchorEl}
            variant={variant === 'primary' ? 'solid' : 'outlined'}
            color={color}
            size={size || 'lg'}
            endDecorator={
              <FontAwesomeIcon
                icon={regular('chevron-down')}
                style={{
                  ...(showMenu
                    ? { transform: 'rotate(180deg)' }
                    : {
                        transform: 'rotate(0deg)',
                      }),
                  transition: 'transform 0.2s',
                }}
              />
            }
            onClick={() => {
              setShowMenu(!showMenu);
              onClick(true);
            }}
            sx={{
              justifyContent: 'space-between',
              minWidth: 270,
              fontWeight: 400,
              // '&:hover, &:active, &:focus': {
              //   color: 'text.primary',
              // },
              ...(!value?.id &&
                !rest.error && {
                  color: 'text.tertiary',
                }),

              ...(fullWidth && {
                width: '100%',
              }),
              ...collapsedStyle,
            }}
          >
            {altLabel ||
              (value?.name ??
                currentGroup?.name ??
                placeholder ??
                t('selectGroup'))}
          </Button>
        )}
        renderPopup={() => (
          <SearchableMenu
            items={_.concat(
              addAllGroups ? [{ id: 'all', name: 'All Groups' }] : [],
              groups?.data || [],
              addUnassignedGroup
                ? [{ id: 'unassigned', name: 'Unassigned' }]
                : [],
            )}
            isLoading={isLoading || isFetching}
            getLabel={(item: Group) => item.name || ''}
            onQueryChanged={(newVal) => handleInput(newVal)}
            onSelect={(newVal) => {
              handleChange(newVal);
              setShowMenu(false);
            }}
            isDisabled={(item: Group) => item.id === value?.id}
            sx={{
              minWidth: 270,
              ...expandedStyle,
            }}
          />
        )}
      />
    </InputControl>
  );
}

export default GroupSelect;
