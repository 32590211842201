/* eslint-disable react/no-unstable-nested-components */
// TODO
import { Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { actions } from './redux/reducers/workflow';

import InboxPage from './pages/Inbox';
import CampaignsPage from './pages/Campaigns';
import ContactsPage, { ContactsViewMode } from './pages/Contacts';
import InsightsPage from './pages/Insights';
import TemplatesPage from './pages/Templates';
import FeatureRequestsPage from './pages/FeatureRequests';
import ToolsPage from './pages/Tools';
import LinksPage from './pages/Tools/Links';
import LinkDetailPage from './pages/Tools/Links/LinkDetail';

import NotFound from './pages/Misc/NotFound';
import QRGenerator from './pages/Tools/QRGenerator';
import Senders from './pages/Senders';
import SendersAdd from './pages/Senders/SendersAdd';
import SendersRegister from './pages/Senders/SendersRegister';

import Groups from './pages/Groups';
import GroupEdit from './pages/GroupEdit';
import PartnerSettings from './pages/Partners/Settings';
import PartnerLeads from './pages/Partners/Leads';
import PartnerReferrals from './pages/Partners/Referrals';
import SelectAccount from './pages/SelectAccount';
import AuthResult from './pages/AuthResult';
import VerifyEmail from './pages/VerifyEmail';
import SenderEdit from './pages/Senders/SenderEdit';
import LeadAdd from './pages/LeadAdd';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Logout from './pages/Logout';

import MarketplaceIntegrations from './pages/Integrations/Marketplace';
import InstalledIntegrations from './pages/Integrations/Installed';

import ActiveCampaign from './pages/Integrations/ActiveCampaign';
import HubSpot from './pages/Integrations/HubSpot';
import Intercom from './pages/Integrations/Intercom';
import Pipedrive from './pages/Integrations/Pipedrive';
import Slack from './pages/Integrations/Slack';

import EmailVerifiedRoute from './utils/EmailVerifiedRoute';
import Refresh from './pages/Refresh';
import AccountSettingsPage from './pages/AccountSettings';
import ProfileSettings from './pages/ProfileSettings';
import Twilio from './pages/Integrations/Twilio';
import AirCall from './pages/Integrations/AirCall';
import Callrail from './pages/Integrations/CallRail';
import GoogleSheets from './pages/Integrations/GoogleSheets';
import Workflows from './pages/Workflows/List';
import WorkflowEdit from './pages/Workflows/Canvas';
import Cradle from './pages/Integrations/Cradle';
import General from './pages/Group/General';
import AutoResponses from './pages/Group/AutoResponses';
import OfficeHours from './pages/Group/OfficeHours';
import AddAutoResponder from './pages/Group/AutoResponses/AddAutoResponder';
import ErrorPage from './pages/Misc/ErrorPage';
import CallHistory from './pages/CallHistory';
import CallDetails from './pages/CallHistory/CallDetails';
import BillingPage from './pages/Billing';
import Mailgun from './pages/Integrations/Mailgun';
import PartnerSignup from './pages/Partners/Signup';
import AccountRegistration from './pages/AccountRegistration';
import AuthenticatedRoute from './utils/AuthenticatedRoute';
import UserRegistration from './pages/UserRegistration';
import CallBack from './pages/Misc/CallBackPage';
import NavBarWrapper from './pages/NavBarWrapper';
import Integration from './pages/Integrations/IntegrationEdit';

function Routing() {
  const location = useLocation();
  const dispatch = useDispatch();

  //TODO this isn't approved - needs rework
  //get out of workflow edit mode if user navigates away from workflow edit page
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    if (
      pathSegments.length === 3 &&
      pathSegments[1] === 'workflows' &&
      pathSegments[2] !== ''
    ) {
      // ignore
    } else {
      dispatch(actions.enterViewMode());
    }
  }, [location.pathname, dispatch]);

  return (
    <Routes>
      {/* No verification */}

      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/refresh" element={<Refresh />} />
      {/* <Route path="/signup" element={<SignUp />} /> */}
      {/* <Route path="/signup" element={<SignUp />} /> */}
      <Route path="/callback" element={<CallBack />} />
      {/* <Route path="/error" element={<CallBackError />} /> */}
      <Route path="/verifyemail" element={<VerifyEmail />} />
      <Route path="/auth" element={<AuthResult />} />
      <Route path="/*" element={<NotFound />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/504" element={<ErrorPage code="serverDown" />} />
      <Route path="/403" element={<ErrorPage code="notAuthorized" />} />

      {/* Email Verified */}
      <Route
        path="/accounts"
        element={<EmailVerifiedRoute component={SelectAccount} />}
      />
      <Route
        path="/createprofile"
        element={<EmailVerifiedRoute component={UserRegistration} />}
      />
      <Route
        path="/register"
        element={<EmailVerifiedRoute component={AccountRegistration} />}
      />
      {/* Account Authed */}
      <Route
        path="/"
        element={
          <NavBarWrapper>
            <Outlet />
          </NavBarWrapper>
        }
      >
        <Route
          index
          path="/"
          element={<AuthenticatedRoute component={InboxPage} />}
        />
        <Route
          path="/inbox"
          element={<AuthenticatedRoute component={InboxPage} />}
        />
        <Route
          path="/inbox/:groupId"
          element={<AuthenticatedRoute component={InboxPage} />}
        />
        <Route
          path="/campaigns"
          element={<AuthenticatedRoute component={CampaignsPage} />}
        />
        <Route
          path="contacts"
          element={
            <AuthenticatedRoute
              component={() => (
                <ContactsPage view={ContactsViewMode.CONTACTS} />
              )}
            />
          }
        />
        <Route
          path="/lists"
          element={
            <AuthenticatedRoute
              component={() => <ContactsPage view={ContactsViewMode.LISTS} />}
            />
          }
        />
        <Route
          path="/attributes"
          element={
            <AuthenticatedRoute
              component={() => (
                <ContactsPage view={ContactsViewMode.ATTRIBUTES} />
              )}
            />
          }
        />
        <Route
          path="/attributes/:attributeId"
          element={
            <AuthenticatedRoute
              component={() => (
                <ContactsPage view={ContactsViewMode.ATTRIBUTES} />
              )}
            />
          }
        />
        <Route
          path="/lists/:listId"
          element={
            <AuthenticatedRoute
              component={() => <ContactsPage view={ContactsViewMode.LISTS} />}
            />
          }
        />
        <Route
          path="/tools"
          element={<AuthenticatedRoute component={ToolsPage} />}
        />
        <Route
          path="/tools/links"
          element={<AuthenticatedRoute component={LinksPage} />}
        />
        <Route
          path="/tools/links/:linkId"
          element={<AuthenticatedRoute component={LinkDetailPage} />}
        />
        <Route
          path="/featurerequests"
          element={<AuthenticatedRoute component={FeatureRequestsPage} />}
        />
        <Route
          path="/templates"
          element={<AuthenticatedRoute component={TemplatesPage} />}
        />
        <Route
          path="/insights"
          element={<AuthenticatedRoute component={InsightsPage} />}
        />
        <Route
          path="/tools/qr"
          element={<AuthenticatedRoute component={QRGenerator} />}
        />
        {/* <Route
          path="/integrations"
          element={<AuthenticatedRoute component={Integrations} />}
        /> */}
        {/* <Route path="/integrations" element={<Integrations />} /> */}
        {/* <Route path="/integrations/:integrationId" element={<Integrations />} /> */}
        <Route
          path="/marketplace"
          element={
            <AuthenticatedRoute
              component={() => <MarketplaceIntegrations />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/workflows"
          element={
            <AuthenticatedRoute
              component={() => <Workflows />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/workflows/:workflowId"
          element={
            <AuthenticatedRoute
              component={() => <WorkflowEdit />}
              requiredRoles={['Administrator']}
            />
          }
        />
        {/* <Route
          path="/workflows/:workflowId/:versionId"
          element={
            <AuthenticatedRoute
              component={() => <WorkflowEdit />}
              requiredRoles={['Administrator']}
            />
          }
        /> */}
        {/* <Route
          path="/workflows/add"
          element={
            <AuthenticatedRoute
              component={() => <AddWorkflow />}
              requiredRoles={['Administrator']}
            />
          }
        /> */}
        {/* <Route
          path="/workflow"
          element={
            <AuthenticatedRoute
              component={() => <Workflow />}
              requiredRoles={['Administrator']}
            />
          }
        /> */}
        <Route
          path="/integrations"
          element={
            <AuthenticatedRoute
              component={() => <InstalledIntegrations />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/activecampaign"
          element={
            <AuthenticatedRoute
              component={() => <ActiveCampaign />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/aircall"
          element={
            <AuthenticatedRoute
              component={() => <AirCall />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/callrail"
          element={
            <AuthenticatedRoute
              component={() => <Callrail />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/cradle"
          element={
            <AuthenticatedRoute
              component={() => <Cradle />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/hubspot"
          element={
            <AuthenticatedRoute
              component={() => <HubSpot />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/intercom"
          element={
            <AuthenticatedRoute
              component={() => <Intercom />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/mailgun"
          element={
            <AuthenticatedRoute
              component={() => <Mailgun />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/pipedrive"
          element={
            <AuthenticatedRoute
              component={() => <Pipedrive />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/slack"
          element={
            <AuthenticatedRoute
              component={() => <Slack />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/twilio"
          element={
            <AuthenticatedRoute
              component={() => <Twilio />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/googlesheets"
          element={
            <AuthenticatedRoute
              component={() => <GoogleSheets />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/integrations/lob"
          element={
            <AuthenticatedRoute component={() => <Integration name="lob" />} />
          }
        />
        {/* <Route path="/channels" element={<AuthenticatedRoute component={ChannelsPage} />} />
        <Route path="/channels/phonenumber" element={<AuthenticatedRoute component={ChannelsPage} />} />
        <Route path="/channels/whatsapp" element={<AuthenticatedRoute component={ChannelsPage} />} /> */}
        {/* <Route
          path="/channels/phonenumber"
          element={
            <RequiresAuth>
              <Master>
                <ChannelsPage view="phonenumber" />
              </Master>
            </RequiresAuth>
          }
        />
        <Route
          path="/channels/whatsapp"
          element={
            <RequiresAuth>
              <Master>
                <ChannelsPage view="whatsapp" />
              </Master>
            </RequiresAuth>
          }
        /> */}
        {/* <Route path="/logout"  /> */}
        <Route
          path="/settings/*"
          element={
            <AuthenticatedRoute
              component={AccountSettingsPage}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/billing/*"
          element={
            <AuthenticatedRoute
              component={BillingPage}
              requiredRoles={['Administrator']}
            />
          }
        />
        {/* <Route
          path="/settings/advanced"
          element={
            <AuthenticatedRoute
              component={AdvancedSettings}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/settings/users"
          element={
            <AuthenticatedRoute
              component={UserSettings}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/settings/api"
          element={
            <AuthenticatedRoute
              component={APISettings}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/settings/reporting"
          element={
            <AuthenticatedRoute
              component={ReportingSettings}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/settings/invoices"
          element={
            <AuthenticatedRoute
              component={InvoiceSettings}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/settings/supportinfo"
          element={
            <AuthenticatedRoute
              component={SupportInfo}
              requiredRoles={['Administrator']}
            />
          }
        /> */}
        <Route
          path="/profile/*"
          element={<AuthenticatedRoute component={ProfileSettings} />}
        />
        <Route
          path="/senders"
          element={<AuthenticatedRoute component={Senders} />}
        />
        <Route
          path="/senders/:senderType/:senderId"
          element={<AuthenticatedRoute component={SenderEdit} />}
        />
        <Route
          path="/senders/:senderType/add"
          element={<AuthenticatedRoute component={SendersAdd} />}
        />
        {/* <Route
          path="/senders/add/:senderType"
          element={<AuthenticatedRoute component={SendersAdd} />}
        /> */}
        <Route
          path="/senders/:senderType/add/:mode"
          element={<AuthenticatedRoute component={SendersAdd} />}
        />
        <Route
          path="/senders/:senderType/add/:mode/:country"
          element={<AuthenticatedRoute component={SendersAdd} />}
        />
        <Route
          path="/regulatory/register"
          element={<AuthenticatedRoute component={SendersRegister} />}
        />
        <Route
          path="/regulatory/register/:subType/"
          element={<AuthenticatedRoute component={SendersRegister} />}
        />
        <Route
          path="/groups"
          element={
            <AuthenticatedRoute
              component={Groups}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/groups/add"
          element={
            <AuthenticatedRoute
              component={GroupEdit}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/groups/:groupId"
          element={
            <AuthenticatedRoute
              component={General}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/groups/:groupId/autoresponders"
          element={
            <AuthenticatedRoute
              component={AutoResponses}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/groups/:groupId/autoresponders/:autoResponderId"
          element={
            <AuthenticatedRoute
              component={AddAutoResponder}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/groups/:groupId/autoresponders/add"
          element={
            <AuthenticatedRoute
              component={AddAutoResponder}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/groups/:groupId/officehours"
          element={
            <AuthenticatedRoute
              component={OfficeHours}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/partnersignup"
          element={
            <AuthenticatedRoute
              component={() => <PartnerSignup />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/partners"
          element={
            <AuthenticatedRoute
              component={() => <PartnerSettings />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/partners/leads"
          element={
            <AuthenticatedRoute
              component={() => <PartnerLeads />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/partners/leads/:leadId"
          element={
            <AuthenticatedRoute
              component={() => <LeadAdd />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/partners/leads/add"
          element={
            <AuthenticatedRoute
              component={() => <LeadAdd />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/partners/referrals"
          element={
            <AuthenticatedRoute
              component={() => <PartnerReferrals />}
              requiredRoles={['Administrator']}
            />
          }
        />
        <Route
          path="/calls"
          element={<AuthenticatedRoute component={CallHistory} />}
        />
        <Route
          path="/calls/:callId"
          element={<AuthenticatedRoute component={CallDetails} />}
        />
      </Route>
    </Routes>
  );
}

export default Routing;
