import React from 'react';
import { Grid, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { Autocomplete } from '@sakari-io/sakari-components';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import { TextField } from '../../../../../ui';
import PhoneInput from '../../../../../ui/atoms/inputs/PhoneInput';
import CountryAutocomplete from '../../../../../ui/organisms/forms/CountryAutocomplete';
import AddressAutocomplete from '../../../../../ui/organisms/forms/AddressAutocomplete/index';

const STOCK_EXCHANGES = [
  'NONE',
  'NASDAQ',
  'NYSE',
  'AMEX',
  'AMX',
  'ASX',
  'B3',
  'BME',
  'BSE',
  'FRA',
  'ICEX',
  'JPX',
  'JSE',
  'KRX',
  'LON',
  'NSE',
  'OMX',
  'SEHK',
  'SGX',
  'SSE',
  'STO',
  'SWX',
  'SZSE',
  'TSX',
  'TWSE',
  'VSE',
  'OTHER',
];

const ENTITY_TYPES = [
  'PRIVATE_PROFIT',
  'PUBLIC_PROFIT',
  'NON_PROFIT',
  'GOVERNMENT',
  // 'SOLE_PROPRIETOR',
];

const VERTICALS = [
  'PROFESSIONAL',
  'REAL_ESTATE',
  'HEALTHCARE',
  'HUMAN_RESOURCES',
  'ENERGY',
  'ENTERTAINMENT',
  'RETAIL',
  'TRANSPORTATION',
  'AGRICULTURE',
  'INSURANCE',
  'POSTAL',
  'EDUCATION',
  'HOSPITALITY',
  'FINANCIAL',
  'POLITICAL',
  'GAMBLING',
  'LEGAL',
  'CONSTRUCTION',
  'NGO',
  'MANUFACTURING',
  'GOVERNMENT',
  'TECHNOLOGY',
  'COMMUNICATION',
];

function BrandDetails() {
  const { t } = useTranslation();

  const form = useForm();
  const { brand } = form.getState().values;

  return (
    <Stack width="100%" gap={2}>
      <Typography level="title-lg">
        {t('10dlc.brand.supportContact')}
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <FieldWrapper
            component={TextField}
            label={t('10dlc.brand.email.name')}
            name="brand.email"
            type="email"
            placeholder="you@company.com"
            required
          />
        </Grid>
        <Grid xs={12} md={6}>
          <FieldWrapper
            component={PhoneInput}
            label={t('10dlc.brand.phone.name')}
            name="brand.phone"
            required
          />
        </Grid>
      </Grid>

      <Typography level="title-lg">{t('10dlc.brand.brandDetails')}</Typography>

      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <FieldWrapper
            component={Autocomplete}
            fullWidth
            required
            name="brand.entityType"
            label={t('10dlc.brand.companyType.name')}
            getOptionLabel={(option: string) =>
              option && t(`10dlc.brand.entitytypes.${option}`)
            }
            isOptionEqualToValue={(option: string, value: string) =>
              option === value
            }
            options={ENTITY_TYPES}
            disableClearable
            placeholder={t('10dlc.brand.companyType.placeholder')}
          />
        </Grid>

        <Grid xs={12} md={6}>
          <FieldWrapper
            component={Autocomplete}
            fullWidth
            required
            name="brand.vertical"
            label={t('10dlc.brand.vertical.name')}
            getOptionLabel={(option: string) =>
              option && t(`10dlc.brand.verticals.${option}.name`)
            }
            isOptionEqualToValue={(option: string, value: string) =>
              option === value
            }
            options={VERTICALS}
            placeholder={t('10dlc.brand.vertical.placeholder')}
            disableClearable
          />
        </Grid>

        <Grid xs={12} md={12}>
          <FieldWrapper
            component={TextField}
            label={t('10dlc.brand.companyName.name')}
            name="brand.companyName"
            required
          />
        </Grid>

        <Grid xs={12} md={6}>
          <FieldWrapper
            component={TextField}
            label={t('10dlc.brand.brandName.name')}
            name="brand.displayName"
          />
        </Grid>

        <Grid xs={12} md={6}>
          <FieldWrapper
            component={TextField}
            label={t('10dlc.brand.url.name')}
            name="brand.website"
            required
          />
        </Grid>
        <Grid xs={12}>
          <FieldWrapper
            component={AddressAutocomplete}
            label={t('10dlc.brand.street.name')}
            name="brand.address"
            isRequired={(field: string) => !['line2'].includes(field)}
          />
        </Grid>

        {brand?.entityType &&
          (brand?.entityType !== 'SOLE_PROPRIETOR' ? (
            <>
              <Grid xs={12} md={6}>
                <FieldWrapper
                  component={CountryAutocomplete}
                  fullWidth
                  label={t('10dlc.brand.tax.country.name')}
                  name="brand.tax.country"
                  placeholder={t('10dlc.brand.tax.country.placeholder')}
                  disableClearable
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FieldWrapper
                  component={TextField}
                  label={t('10dlc.brand.tax.id.name')}
                  name="brand.tax.id"
                  required
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid xs={12} md={6}>
                <FieldWrapper
                  component={TextField}
                  name="brand.ownerFirstName"
                  label={t('10dlc.brand.owner.firstName.name')}
                  placeholder={t('10dlc.brand.owner.lastName.placeholder')}
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FieldWrapper
                  component={TextField}
                  name="brand.ownerLastName"
                  label={t('10dlc.brand.owner.lastName.name')}
                  placeholder={t('10dlc.brand.owner.lastName.placeholder')}
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FieldWrapper
                  component={PhoneInput}
                  name="brand.ownerMobile"
                  label={t('10dlc.brand.owner.mobile.name')}
                  required
                />
              </Grid>
            </>
          ))}

        {brand?.entityType === 'PUBLIC_PROFIT' && (
          <>
            <Grid xs={6} md={3}>
              <FieldWrapper
                component={TextField}
                label={t('10dlc.brand.stock.symbol.name')}
                name="brand.stockSymbol"
                required
              />
            </Grid>
            <Grid xs={6} md={3}>
              <FieldWrapper
                component={Autocomplete}
                fullWidth
                name="brand.stockExchange"
                label={t('10dlc.brand.stock.exchange.name')}
                options={STOCK_EXCHANGES}
                placeholder={t('10dlc.brand.stock.exchange.placeholder')}
                isOptionEqualToValue={(option: string, value: string) =>
                  option === value
                }
                disableClearable
                required
              />
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
export default BrandDetails;
