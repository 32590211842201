import React from 'react';
import { Error } from '@sakari-io/sakari-typings';
import { AutocompleteOption, Stack, Typography } from '@mui/joy';
import { ObjectAutoComplete } from '@sakari-io/sakari-components';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useGetErrorsQuery } from '../../../../../../api';

const filterOptions = createFilterOptions({
  stringify: (option: any) => option.code + ' ' + option.description,
});

interface ErrorsAutoCompleteProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  value?: Error[];
  onChange: (value?: Error[]) => void;
  disabled?: boolean;
}

function ErrorsAutoComplete({
  label,
  placeholder,
  helperText,
  value,
  onChange,
  disabled,
}: ErrorsAutoCompleteProps) {
  return (
    <ObjectAutoComplete
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      fetch={useGetErrorsQuery}
      query={(input: string) => ({ q: input })}
      value={value || ([] as Error[])}
      onChange={onChange}
      disabled={disabled}
      multiple
      isOptionEqualToValue={(option: any, v: any) => {
        return option?.code === v?.code;
      }}
      getOptionLabel={(option: any) => {
        return (option as any)?.code || '';
      }}
      filterOptions={filterOptions}
      renderOption={(props: any, option: Error) => {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <AutocompleteOption {...props}>
            <Stack>
              <Typography level="body-md">{option.code}</Typography>
              <Typography
                level="body-sm"
                overflow="hidden"
                title={option?.description}
              >
                {option.description}
              </Typography>
            </Stack>
          </AutocompleteOption>
        );
      }}
    />
  );
}

export default ErrorsAutoComplete;
