import React, { useState } from 'react';
import { Stack, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import Setting from '../../../ui/templates/forms/SettingForm';
import ReportsDialog from '../../../ui/organisms/Dialogs/ReportsDialog';

interface ReportingProps {
  onPerformedAction?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Reporting({ onPerformedAction }: ReportingProps) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [type, setType] = useState<
    'opt-out' | 'summary' | 'detailed' | undefined
  >();

  return (
    <>
      <Stack
        spacing={1}
        sx={{
          '& .MuiButton-root': {
            background: 'var(--joy-palette-background-surface)',
            fontWeight: 'md',
          },
        }}
      >
        <Setting
          label={t('settings.reporting.summary.label')}
          description={t('settings.reporting.summary.description')}
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              setType('summary');
              setDialogOpen(true);
            }}
          >
            {t('settings.reporting.download.label')}
          </Button>
        </Setting>

        <Setting
          label={t('settings.reporting.detailed.label')}
          description={t('settings.reporting.detailed.description')}
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              setType('detailed');
              setDialogOpen(true);
            }}
          >
            {t('settings.reporting.download.label')}
          </Button>
        </Setting>

        <Setting
          label={t('settings.reporting.optout.label')}
          description={t('settings.reporting.optout.description')}
        >
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              setType('opt-out');
              setDialogOpen(true);
            }}
          >
            {t('settings.reporting.download.label')}
          </Button>
        </Setting>
      </Stack>
      {type && (
        <ReportsDialog type={type} open={dialogOpen} setOpen={setDialogOpen} />
      )}
    </>
  );
}

export default Reporting;
