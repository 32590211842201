import { useForm } from 'react-final-form';

import React, { useEffect } from 'react';
import UseCaseSelection from './UseCaseSelection';

function CreateCampaign() {
  const form = useForm();
  const { brand } = form.getState().values;

  useEffect(() => {
    if (brand) {
      form.change('campaign', {
        status: 'NEW',
        features: {
          subscriberOptin: true,
          subscriberOptout: true,
          subscriberHelp: true,
        },
      });
    }
  }, []);

  return <UseCaseSelection brand={brand} />;
}

export default CreateCampaign;
