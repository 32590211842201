import React, { useState } from 'react';
import { Panel as RFPanel, PanelPosition } from 'reactflow';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Button } from '@mui/joy';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { subDays, format } from 'date-fns';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { logger } from '@sakari-io/sakari-components';
import Select from '../../../../ui/atoms/inputs/Select';
import { actions, MetricType } from '../../../../redux/reducers/workflow';

interface PanelProps {
  position: PanelPosition;
}

const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = new Date();
      const sevenDaysAgo = subDays(today, 7);
      return [sevenDaysAgo, today];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = new Date();
      const thirtyDaysAgo = subDays(today, 30);
      return [thirtyDaysAgo, today];
    },
  },
  {
    label: 'Last 14 Days',
    getValue: () => {
      const today = new Date();
      const fourteenDaysAgo = subDays(today, 14);
      return [fourteenDaysAgo, today];
    },
  },
  {
    label: 'Yesterday',
    getValue: () => {
      const today = new Date();
      const yesterday = subDays(today, 1);
      return [yesterday, yesterday];
    },
  },
  {
    label: 'Today',
    getValue: () => {
      const today = new Date();
      return [today, today];
    },
  },
];

function formatDate(date: Date | null) {
  if (date === null) {
    return null;
  }
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate()).toISOString();
}

function CustomActionBar({
  onCancel,
  onAccept,
}: {
  onCancel: () => void;
  onAccept: () => any;
}) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={2}
      sx={{ position: 'absolute', bottom: 16, right: 16 }}
    >
      <Button color="neutral" variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onAccept}>Submit</Button>
    </Stack>
  );
}

function Panel({ position = 'top-right' }: PanelProps) {
  const { t } = useTranslation('workflows');
  const dispatch = useDispatch();
  const { metricType, metricDateRange } = useSelector(
    (state: any) => state.workflow.present,
  );

  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<[Date | null, Date | null]>(
    metricDateRange
      ? [metricDateRange.start, metricDateRange.end]
      : [null, null],
  );

  const analyticsOptions = [
    { value: MetricType.ABSOLUTE, label: t('absolute') },
    { value: MetricType.PERCENTAGE, label: t('percentage') },
  ];

  const formatDateLabel = (date: Date) => {
    return format(date, 'MMMM do');
  };

  const calculateDays = (start: Date, end: Date) => {
    const diff = Math.abs(end?.getTime() - start?.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  };

  const matchingLabel =
    (metricDateRange &&
      shortcutsItems.find((item: any) => {
        const [startDate, endDate] = item.getValue();
        return (
          formatDate(startDate) === formatDate(metricDateRange.start) &&
          formatDate(endDate) === formatDate(metricDateRange.end)
        );
      })?.label) ||
    (range[0] &&
      range[1] &&
      `Last ${calculateDays(range[0], range[1])} Days`) ||
    (range[0] && !range[1] && formatDateLabel(range[0]));

  const handleDateChange = (newValue: [Date | null, Date | null]) => {
    if (newValue[0] && newValue[1]) {
      setRange(newValue);
    } else if (newValue[0] && !range[0]) {
      setRange([newValue[0], null]);
    } else if (newValue[0] && range[0] && !range[1]) {
      setRange([range[0], newValue[0]]);
    } else {
      setRange(newValue);
    }
  };

  return (
    <RFPanel position={position}>
      <Stack direction="row" spacing={1}>
        <Select
          value={range}
          placeholder={matchingLabel ?? 'Last 7 Days'}
          options={[]}
          onClick={() => setOpen((prev) => !prev)}
          onChange={logger.info}
          sx={{ minWidth: '100px' }}
          slots={{
            listbox: () => (
              <>
                {open && (
                  <StaticDateRangePicker
                    sx={{ position: 'absolute', right: 0 }}
                    slots={{
                      toolbar: undefined,
                      actionBar: () => (
                        <CustomActionBar
                          onCancel={() => setOpen(false)}
                          onAccept={() => {
                            if (range) {
                              if (range[0] && range[1]) {
                                dispatch(
                                  actions.setMetricDateRange({
                                    start: new Date(range[0]).toISOString(),
                                    end: new Date(range[1]).toISOString(),
                                  }),
                                );
                              } else if (range[0] && !range[1]) {
                                dispatch(
                                  actions.setMetricDateRange({
                                    start: new Date(range[0]).toISOString(),
                                    end: new Date(range[0]).toISOString(),
                                  }),
                                );
                              }
                            }
                            setOpen(false);
                          }}
                        />
                      ),
                    }}
                    disableFuture
                    slotProps={{
                      shortcuts: {
                        items: shortcutsItems,
                      },
                    }}
                    value={range}
                    onChange={(newValue) => {
                      handleDateChange(newValue);
                    }}
                  />
                )}
              </>
            ),
          }}
        />

        <Select
          options={analyticsOptions}
          value={metricType}
          onChange={(newVal) => {
            dispatch(actions.setMetricType(newVal));
          }}
        />
      </Stack>
    </RFPanel>
  );
}

export default Panel;
