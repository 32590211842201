import React, { useState, useEffect } from 'react';
import { InputControl } from '@sakari-io/sakari-components';
import TextField from '../../../../../../ui/atoms/inputs/TextField';
import { ConfigFieldProps } from '../PropertyField';

interface IncrDecrementValueProps extends ConfigFieldProps<number | string> {
  value: number | string;
  onChange: (value: number | string) => void;
}

function IncrDecrementValue({
  value,
  onChange,
  disabled,
  ...rest
}: IncrDecrementValueProps) {
  const [operation, setOperation] = useState<'add' | 'subtract' | null>(null);

  useEffect(() => {
    if (Number(value) > 0) {
      setOperation('add');
    } else if (Number(value) < 0) {
      setOperation('subtract');
    }
  }, [value]);

  const handleChange = (e: string) => {
    if (e === '') {
      onChange(0);
      return;
    }

    let newValue = Math.abs(parseInt(e, 10));

    if (Number.isNaN(newValue)) {
      newValue = 0;
    } else {
      newValue = Math.min(Math.max(newValue, 0), 100);
    }

    onChange(operation === 'subtract' ? -newValue : newValue);
  };

  return (
    <InputControl {...rest} fullWidth>
      <TextField
        type="number"
        disabled={disabled}
        onChange={(e) => handleChange(e)}
        value={value === 0 ? '' : Math.abs(Number(value))}
        slotProps={{
          input: {
            min: 0,
            max: 100,
          },
        }}
      />
    </InputControl>
  );
}

export default IncrDecrementValue;
