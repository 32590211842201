import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { faCommentPlus } from '@fortawesome/pro-solid-svg-icons';

import InfiniteScroll from 'react-infinite-scroll-component';

import { List, Stack, Typography } from '@mui/joy';
import {
  Conversation as ConversationType,
  CreateConversation as CreateConversationType,
} from '@sakari-io/sakari-typings';

import ConversationListItem from '../../drawers/ConversationsDrawer/ConversationListItem';
import ConversationSkeleton from '../../drawers/ConversationsDrawer/ConversationSkeleton';
import EmptyResult from '../../../molecules/EmptyResult';
import Container from '../../../templates/Container';

interface ConversationsListProps {
  value: ConversationType[];
  onChange: any;
  selected?: ConversationType | CreateConversationType;
  onSelected: (conversation: ConversationType | CreateConversationType) => any;
  onStartConversation: () => void;
  loading?: boolean;
  hasMore?: boolean;
  showGroup?: boolean;
}

function ConversationsList({
  value,
  onChange,
  selected,
  onSelected,
  onStartConversation,
  loading,
  hasMore,
  showGroup,
}: ConversationsListProps) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Stack
        sx={{
          width: '90%',
          gap: 3,
          py: 2,
        }}
      >
        {_.range(0, 5).map((i: number) => (
          <ConversationSkeleton key={i} />
        ))}{' '}
      </Stack>
    );
  }

  if (value?.length === 0 && !selected) {
    return (
      <Container>
        <EmptyResult
          heading={t('startConv')}
          text={
            <Typography level="body-md">
              {t('emptyCampaignListMessage')}
            </Typography>
          }
          icon={faCommentPlus}
          onIconClicked={() => onStartConversation()}
        />
      </Container>
    );
  }

  return (
    <List
      id="scrollableDiv"
      sx={{
        overflowY: 'overlay',
        flex: '1 1 0',
        py: 0,
        borderRadius: 0,
        maxWidth: '100%',
        '--ListItem-paddingX': '16px',
        '--ListItem-paddingY': '16px',
        '--ListDivider-gap': 0,
        width: '100%',
      }}
      variant="plain"
    >
      <InfiniteScroll
        dataLength={value?.length || 0}
        next={() => {
          onChange();
        }}
        hasMore={hasMore || false}
        scrollableTarget="scrollableDiv"
        loader={loading && <ConversationSkeleton />}
        style={{ overflow: 'visible' }}
      >
        {selected &&
        (selected?.id === 'draft' ||
          !value?.find((val) => val.id === selected.id)) ? (
          <ConversationListItem
            key={`conversation-${selected.id}`}
            selected={selected?.id === selected.id}
            onSelected={onSelected}
            conversation={selected}
          />
        ) : null}

        {value.map(
          (conversation: ConversationType | CreateConversationType) => (
            <ConversationListItem
              key={`conversation-${conversation.id}`}
              selected={selected?.id === conversation.id}
              onSelected={onSelected}
              conversation={conversation}
              showGroup={showGroup}
            />
          ),
        )}
      </InfiniteScroll>
    </List>
  );
}

export default ConversationsList;
