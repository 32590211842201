import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Loader } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import clearCookies from '../../utils/authentication';
import { disconnectSockets } from '../../utils/socket-connection';
import config from '../../config';

function Logout() {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    clearCookies();
    logout({ returnTo: config.auth0.callbackUrl });
    return () => {
      disconnectSockets();
    };
  }, []);

  return <Loader size={200} label={t('loggingOut')} />;
}

export default Logout;
