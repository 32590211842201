import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact, Group } from '@sakari-io/sakari-typings';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { logger } from '@sakari-io/sakari-components';
import { PopperPlacementType } from '@mui/base';
import { displayPhoneComponent } from '../../../../redux/reducers/calls';
import { PhoneStatus } from '../../../../types';
import { showToast } from '../../../../redux/reducers/toast';
import GroupMenuButton from '../GroupMenuButton';
import { useMakeCallMutation } from '../../../../api';

interface CallMenuButtonProps {
  accountId: string;
  contact: Contact;
  user: any;
  icon?: ReactNode;
  disabled?: boolean;
  limit?: number;
  placement?: PopperPlacementType;
}

function CallMenuButton({
  accountId,
  contact,
  icon,
  disabled,
  limit = 5,
  user,
  ...rest
}: CallMenuButtonProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [makeCall] = useMakeCallMutation();

  const startCall = async (values: {
    group: Group;
    contact: Contact;
    user: any;
  }) => {
    const { group, contact, user } = values;
    await makeCall({
      from: group,
      to: contact,
      user,
    })
      .unwrap()
      .then((res) => {
        logger.log('res', res);
        dispatch(displayPhoneComponent(PhoneStatus.CALLING));
      })
      .catch((error) => {
        dispatch(
          showToast({
            message: error.data?.error.message || t('somethingWrong'),
            severity: 'error',
          }),
        );
      });
  };

  if (!user.email.includes('@sakari.io')) {
    return null;
  }

  return (
    <GroupMenuButton
      accountId={accountId}
      contact={contact}
      icon={<FontAwesomeIcon icon={solid('phone')} />}
      disabled={disabled}
      limit={limit}
      onSelectGroup={(group) => {
        startCall({ group, contact, user });
      }}
      tooltip={t('action.makeCall')}
      {...rest}
    />
  );
}

export default CallMenuButton;
