import React from 'react';
import _ from 'lodash';
import { Stack, Typography, Link, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { GoogleSheet, GoogleSheetTab } from '@sakari-io/sakari-typings';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SheetReconnectPrompt from '../SheetReconnectPrompt';
import Select from '../../../atoms/inputs/Select';

interface SheetDetailsProps {
  value: GoogleSheet | undefined;
  onChange: (value: GoogleSheet | undefined) => void;
  tabs: GoogleSheetTab[];
  readOnly: boolean;
  disabled?: boolean;
}

function SheetDetails({
  value,
  onChange,
  tabs,
  readOnly,
  disabled,
}: SheetDetailsProps) {
  const { t } = useTranslation('workflows');

  const sheetOptions = (tabs || []).map((tab) => ({
    value: tab,
    label: tab.name,
  }));

  const googleSheetView = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${value?.id}`,
      '_blank',
    );
  };

  return (
    <Stack spacing={3}>
      {readOnly && <SheetReconnectPrompt />}

      <>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography level="h4">{value?.name}</Typography>
            <IconButton
              variant={'plain'}
              size={'sm'}
              onClick={() => googleSheetView()}
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </IconButton>
          </Stack>
          <Link
            level="body-sm"
            underline="none"
            disabled={readOnly || disabled}
            sx={{
              ':hover': {
                opacity: 0.8,
              },
            }}
            onClick={() => {
              onChange(_.pick(value, ['connectionId']));
            }}
          >
            {t('edit.connectToDifferentSheet')}
          </Link>
        </Stack>

        <Select
          disabled={readOnly || disabled}
          options={
            readOnly
              ? [
                  {
                    value: value?.tab,
                    label: value?.tab?.name,
                  },
                ]
              : (sheetOptions as any)
          }
          value={value?.tab}
          onChange={(newValue: GoogleSheetTab) => {
            onChange(
              _.assign({}, value, {
                tab: newValue,
              }),
            );
          }}
          label={t('edit.tabName')}
        />
      </>
    </Stack>
  );
}

export default SheetDetails;
