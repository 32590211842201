import React from 'react';
import { Link } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { AvailableIntegration } from '@sakari-io/sakari-typings';
import { StatusAlert } from '../../..';

interface ConnectionStatusBannerProps {
  status: string;
  setStatus: (value: string) => void;
  integration: AvailableIntegration;
}

// TODO combine with Alert component
const ConnectionStatusBanner = ({
  status,
  setStatus,
  integration,
}: ConnectionStatusBannerProps) => {
  const { t } = useTranslation();
  let statusAlert = null;

  switch (status) {
    case 'success':
      statusAlert = (
        <StatusAlert
          status="success"
          title={t('success')}
          message={`${t('integrations.alert.success')} ${integration.name}`}
          handleClose={() => setStatus('')}
        />
      );
      break;
    case 'error':
      statusAlert = (
        <StatusAlert
          status="error"
          title={t('couldNotConnect')}
          message={`${t('integrations.alert.error')}`}
          handleClose={() => setStatus('')}
        >
          <Link
            color="danger"
            level="body-sm"
            sx={{ '&:hover': { fontWeight: 'bold', cursor: 'pointer' } }}
          >
            {t('contactSupport')}
          </Link>
        </StatusAlert>
      );
      break;
    case 'warning':
      statusAlert = (
        <StatusAlert
          status="warning"
          title={t('couldNotConnect')}
          message={`${t('integrations.alert.error')} `}
          handleClose={() => setStatus('')}
        >
          <Link
            level="body-sm"
            color="warning"
            sx={{ '&:hover': { fontWeight: 'bold', cursor: 'pointer' } }}
          >
            {t('contactSupport')}
          </Link>
        </StatusAlert>
      );
      break;
    case 'inProgress':
      statusAlert = (
        <StatusAlert
          status="info"
          title={t('integrations.hubspot.userSync.syncInProgress')}
          message={t('integrations.hubspot.userSync.willNotify')}
          handleClose={() => setStatus('')}
        />
      );
      break;
    default:
      statusAlert = null;
  }

  return statusAlert;
};

export default ConnectionStatusBanner;
