import React from 'react';
import { GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Account } from '@sakari-io/sakari-typings';
import DataGrid from '../../../molecules/DataGrid';
import { useGetReferralsQuery } from '../../../../api/partners';
import StatusChip from '../../ChipContexts/StatusChip';
import EmptyResult from '../../../molecules/EmptyResult';

interface Filter {
  status: 'pending' | 'expired' | 'active' | 'ineligible';
}

interface ReferralGridProps {
  account: Account;
  filter: Filter | undefined;
}

function ReferralsGrid({ account, filter }: ReferralGridProps) {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: `name`,
      headerName: 'Account Name',
      headerClassName: 'gridHeaders',
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
    },
    {
      field: `plan`,
      headerName: 'Plan Name',
      headerClassName: 'gridHeaders',
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value: any) => value.name,
    },
    {
      field: `created`,
      headerName: 'Created Date',
      headerClassName: 'gridHeaders',
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value: any) => format(parseISO(value?.at), 'PP'),
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: 'gridHeaders',
      // flex: 1,
      width: 150,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <StatusChip status={params.row.status} />
      ),
    },
  ];

  return (
    <DataGrid
      loadingText="Loading referrals..."
      columns={columns}
      filter={{ accountId: account.id, request: filter || {} }}
      renderNoData={<EmptyResult item="referral" heading="No Referrals" />}
      loadData={useGetReferralsQuery}
    />
  );
}

export default ReferralsGrid;
