import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@mui/joy';
import FieldWrapper from '../../../utils/FieldWrapper';
import Setting from '../../../ui/templates/forms/SettingForm';
import Integration from '../IntegrationEdit';

function ActiveCampaign() {
  const { t } = useTranslation();

  return (
    <Integration name="activecampaign" title="ActiveCampaign">
      <Setting
        label={t('integrations.activecampaign.inbound.label')}
        description={t('integrations.activecampaign.inbound.description')}
      >
        <FieldWrapper
          component={Switch}
          name="timelineInbound"
          type="checkbox"
        />
      </Setting>

      <Setting
        label={t('integrations.activecampaign.outbound.label')}
        description={t('integrations.activecampaign.outbound.description')}
      >
        <FieldWrapper
          component={Switch}
          name="timelineOutbound"
          type="checkbox"
        />
      </Setting>

      <Setting
        label={t('integrations.activecampaign.receivedInfo.label')}
        description={t('integrations.activecampaign.receivedInfo.description')}
      >
        <FieldWrapper
          component={Switch}
          name="messageInfoInbound"
          type="checkbox"
        />
      </Setting>

      <Setting
        label={t('integrations.activecampaign.opt.label')}
        description={t('integrations.activecampaign.opt.description')}
      >
        <FieldWrapper component={Switch} name="optInOut" type="checkbox" />
      </Setting>
    </Integration>
  );
}

export default ActiveCampaign;
