import React from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/joy/Switch';
import FieldWrapper from '../../../utils/FieldWrapper';
import Setting from '../../../ui/templates/forms/SettingForm';
import Integration from '../IntegrationEdit';

function Intercom() {
  const { t } = useTranslation();

  return (
    <Integration
      name="intercom"
      renderSettings={(values) => {
        return (
          <>
            <Setting
              label={t('integrations.intercom.appId.label')}
              description={t('integrations.intercom.appId.description')}
            >
              {values.app_id}
            </Setting>

            <Setting
              label={t('integrations.intercom.autoAssignUser.label')}
              description={t(
                'integrations.intercom.autoAssignUser.description',
              )}
            >
              <FieldWrapper
                component={Switch}
                name="autoAssignUser"
                type="checkbox"
              />
            </Setting>
          </>
        );
      }}
    />
  );
}

export default Intercom;
