import React from 'react';
import BuyMode from './BuyMode';
import BYOMode from './BYOMode';
import ImportMode from './ImportMode';
import ModeSelect from './ModeSelect';

const Modes = {
  import: <ImportMode />,
  byo: <BYOMode />,
  buy: <BuyMode />,
};

interface AddPhoneNumbersProps {
  mode?: string;
}

function AddPhoneNumbers({ mode }: AddPhoneNumbersProps) {
  return (
    <>
      {!mode && <ModeSelect />}
      {mode && Modes[mode as keyof typeof Modes]}
    </>
  );
}
export default AddPhoneNumbers;
