import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/joy';
import { CallStatus } from '@sakari-io/sakari-typings';
import MissedCall from './MissedCall';
import CallAccepted from './CallAccepted';
import { AccountContext } from '../../../../contexts/account.context';
import { ActiveCall } from '../../../../types';

export interface CallNotificationsProps {
  call: ActiveCall;
}
function CallNotifications({ call }: CallNotificationsProps) {
  const { account, profile } = useContext(AccountContext);

  useEffect(() => {
    if (account) {
      // TODO this was hiding the dialer after 8 seconds
      // setTimeout(() => {
      //   dispatch(updateQueryData('startSession', account.id, (draft) => {
      //     if (draft.data?.call) {
      //       logger.info('CallNotifications','clearing call');
      //       draft.data.call = undefined;
      //     }
      //   }));
      // }, 8000);
    }
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        display: [CallStatus.Active, CallStatus.Missed].includes(call?.status)
          ? 'flex'
          : 'none',
        flexDirection: 'column',
        bottom: 30,
        left: 30,
        width: 321,
        backgroundColor: 'var(--joy-palette-voice-solidBg)',
        borderRadius: '8px',
        zIndex: 'calc(var(--joy-zIndex-modal) - 1)',
      }}
    >
      {call?.status === CallStatus.Missed && <MissedCall call={call} />}
      {call?.status === CallStatus.Active && call.user?.id !== profile?.id && (
        <CallAccepted call={call} />
      )}
    </Box>
  );
}

export default CallNotifications;
