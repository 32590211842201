import React from 'react';

import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/joy';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Logo, Loader, logger } from '@sakari-io/sakari-components';
import { showToast } from '../../redux/reducers/toast';
import { useAppDispatch } from '../../redux';
import {
  useGetAccountsQuery,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from '../../api';
import UserInfo from './UserInfo';
import Auth0Template from '../../ui/templates/Auth0Template';
import LogoutLink from '../../ui/organisms/ButtonContexts/LogoutLink';

function UserRegistration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetUserProfileQuery();
  const { data: accountsData, isLoading: isLoadingAccounts } =
    useGetAccountsQuery({
      limit: 1,
    });

  const profile = data?.data;

  const [updateProfile, { isLoading: isUpdating }] =
    useUpdateUserProfileMutation();

  const schema = Yup.object({
    firstName: Yup.string().required(t('validation.required')),
    lastName: Yup.string().required(t('validation.required')),
    mobile: Yup.object().when([], {
      is: () => (accountsData?.data?.length || 0) === 0,
      then: Yup.object().shape({
        number: Yup.string().required(t('validation.required')),
        country: Yup.string(),
        verified: Yup.string().required(
          t('validation.isRequired', {
            field: 'Mobile Verification',
          }),
        ),
      }),
    }),

    communication: Yup.object({
      email: Yup.boolean(),
      sms: Yup.boolean(),
    }),
    tacAccepted: Yup.boolean()
      .oneOf([true], t('validation.required'))
      .required(t('validation.required')),
  });

  const validate = makeValidate(schema);

  const handleOnSubmit = async (values: any) => {
    const showError = () =>
      dispatch(
        showToast({
          severity: 'error',
          message: t('registration.userInfo.error'),
        }),
      );
    logger.info('handleOnSubmit', values);
    await updateProfile(values)
      .unwrap()
      .then(async (res) => {
        if (res.success) {
          logger.info('success');

          if (accountsData?.data?.length === 0) {
            navigate('/register');
          } else {
            navigate('/accounts');
          }

          dispatch(
            showToast({
              severity: 'success',
              message: t('registration.userInfo.success'),
            }),
          );
        } else {
          showError();
        }
      })
      .catch((err) => {
        logger.error('err', err);
        showError();
      });
  };

  if (isLoading || isLoadingAccounts)
    return <Loader size={200} label={t('registration.userInfo.loading')} />;

  return (
    <>
      {isUpdating && (
        <Loader size={200} label={t('registration.userInfo.saving')} />
      )}
      <Auth0Template
        header="Look Who's Here!"
        subheader="It's great to have you on board. Help us make your experience even better by providing a bit of information."
      >
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Logo size={50} />
          <Typography level="h3" sx={{ textAlign: 'center' }}>
            {t('registration.userInfo.title')}
          </Typography>
        </Stack>
        <Form
          onSubmit={handleOnSubmit}
          validate={validate}
          initialValues={{
            ...profile,
            tacAccepted: false,
          }}
          keepDirtyOnReinitialize
          render={({ handleSubmit, hasValidationErrors, submitting }) => {
            return (
              <>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  sx={{
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    gap: 2,
                  }}
                >
                  <UserInfo />
                </Stack>
                <Stack
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                    loading={submitting}
                  >
                    {t('action.continue')}
                  </Button>
                  <LogoutLink />
                  <Typography
                    level="body-sm"
                    sx={{ fontStyle: 'italic', whiteSpace: 'pre-wrap' }}
                  >
                    {t('registration.userInfo.footer')}
                  </Typography>
                </Stack>
              </>
            );
          }}
        />
      </Auth0Template>
    </>
  );
}
export default UserRegistration;
