import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from '@sakari-io/sakari-common';
import { logger } from '@sakari-io/sakari-components';
import { useDeleteContactMutation } from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface DeleteContactDialogProps {
  open: boolean;
  onClose: (result?: boolean) => void;
  contact: any;
}

function DeleteContactDialog({
  open,
  onClose,
  contact,
}: DeleteContactDialogProps) {
  const [deleteContact] = useDeleteContactMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onDelete = async () => {
    //TODO use handleApiMutationResponse
    return deleteContact(contact.id)
      .unwrap()
      .then(() => {
        dispatch(showToast({ severity: 'info', message: 'Contact Deleted' }));
      })
      .catch((err) => {
        logger.error('err', err);
        dispatch(
          showToast({
            severity: 'error',
            message: `${format.name(contact) || 'Contact'} could not be deleted`,
          }),
        );
      });
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={(result) => {
        if (result) {
          onDelete();
        }
        onClose(result);
      }}
      header={`
        ${t('action.remove')} 
        ${format.name(contact) || t('items.contact_one')}?`}
      content={t('cannotUndoAction')}
      confirmLabel="yesDelete"
      type="danger"
    />
  );
}

export default DeleteContactDialog;
