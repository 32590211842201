import React, { SyntheticEvent, useEffect, useState } from 'react';
import OverlayPopup from '../../templates/OverlayPopup';
import SearchableMenu from '../Menu/SearchableMenu';
import InputControl, { InputControlProps } from '../InputControl';

interface DynamicAttributeSelectProps
  extends Omit<InputControlProps, 'onChange' | 'value'> {
  fullWidth?: boolean;
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  renderButton: (
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
    onClick: () => void,
  ) => React.ReactNode;
  hideButtonOnOpen?: boolean;
  fetchAttributes: (input: string) => void;
  attributes: any[];
  isLoading: boolean;
  renderLabel?: (item: any) => JSX.Element;
  disabled?: boolean;
  disabledOptions?: string[];
}

function DynamicAttributeSelect({
  value,
  onChange,
  fullWidth,
  placeholder,
  renderButton,
  hideButtonOnOpen,
  fetchAttributes,
  attributes,
  isLoading,
  renderLabel,
  disabled,
  disabledOptions,
  ...rest
}: DynamicAttributeSelectProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    fetchAttributes(search);
  }, [search, fetchAttributes]);

  const handleInput = (newInput: string) => {
    setSearch(newInput);
  };

  const handleChange = (newValue: any) => {
    onChange(newValue);
  };

  const customRenderButton = React.isValidElement(renderButton)
    ? React.cloneElement(renderButton as React.ReactElement, {
        onClick: () => {
          setShowMenu(!showMenu);
        },
      })
    : null;

  return (
    <InputControl
      {...{
        fullWidth,
        ...rest,
      }}
    >
      <OverlayPopup
        open={showMenu}
        onOpenClose={setShowMenu}
        placement="bottom-start"
        renderButton={(setAnchorEl) => {
          if (hideButtonOnOpen && showMenu) {
            return <div ref={setAnchorEl} />;
          }
          return customRenderButton
            ? React.cloneElement(customRenderButton, {
                ref: setAnchorEl,
                onClick: (e: SyntheticEvent) => {
                  if (disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                  }
                  setShowMenu(!showMenu);
                },
              })
            : null;
        }}
        renderPopup={() => (
          <SearchableMenu
            items={attributes}
            isLoading={isLoading}
            getLabel={renderLabel}
            onQueryChanged={(newVal) => handleInput(newVal)}
            onSelect={(newVal) => {
              handleChange(newVal);
              setShowMenu(false);
            }}
            isDisabled={(item) =>
              disabledOptions?.includes(
                item?.value?.toLowerCase() ?? item?.name?.toLowerCase(),
              ) || false
            }
          />
        )}
      />
    </InputControl>
  );
}

export default DynamicAttributeSelect;
