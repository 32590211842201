import React from 'react';
import { Skeleton, Box, List, ListItem } from '@mui/joy';
import './styles.scss';

function MessageSkeleton({ outgoing }: { outgoing: boolean }) {
  return (
    <Box className={`loaderSection ${outgoing === false && 'right'} `}>
      <List className="loaderBox">
        <Box className="loaderInfoBox">
          <ListItem style={{ flex: 'none' }}>
            <div className="loaderCircle">
              <Skeleton variant="circular" width="52px" height="52px" />
            </div>
          </ListItem>
          <ListItem style={{ flex: 'none', width: '75%' }}>
            <div className="loaderSmallBar">
              <Skeleton variant="text" width="90%" height="20px" />
            </div>
          </ListItem>
        </Box>
        <Box className="loaderLongBar">
          <Skeleton variant="text" width="90%" height="80px" />
        </Box>
      </List>
    </Box>
  );
}

export default MessageSkeleton;
