import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

interface ActionsPluginProps {
  renderActions: (editor: any) => JSX.Element;
}

const ActionsPlugin = ({ renderActions }: ActionsPluginProps) => {
  const [editor] = useLexicalComposerContext();
  return renderActions(editor);
};
export default ActionsPlugin;
