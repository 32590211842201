import React, { useContext, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Stack } from '@mui/joy';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Loader, logger } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import TabbedNavigation from '../../ui/organisms/headers/TabbedNavigation';
import {
  useGetUserProfileQuery,
  useGetUserNotificationsQuery,
  useUpdateUserNotificationsMutation,
  useUpdateUserProfileMutation,
} from '../../api';
import { showToast } from '../../redux/reducers/toast';
import { useAppDispatch } from '../../redux/store';
import { GroupNotificationSettings } from '../../ui/organisms/datagrids/NotificationsGrid';
import { PageHeader } from '../../ui';
import Profile from './Profile';
import Security from './Security';
import Notifications from './Notifications';
import { AccountContext } from '../../contexts/account.context';

// interface NavItem {
//   title: string;
//   link: string;
// }

function ProfileSettings() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const navItems: any[] = [
    {
      value: 0,
      label: t('profileSettings.profile.title'),
      url: '/profile',
      content: <Route path="" element={<Profile />} key="profile" />,
    },
    {
      value: 1,
      label: t('profileSettings.security.title'),
      url: '/profile/security',
      content: <Route path="security" element={<Security />} key="security" />,
    },
    {
      value: 2,
      label: t('profileSettings.notifications.title'),
      url: '/profile/notifications',
      content: (
        <Route
          path="notifications"
          element={<Notifications />}
          key="notifications"
        />
      ),
    },
  ];
  const [selectedTab, setSelectedTab] = React.useState(0);

  useEffect(() => {
    setSelectedTab(
      navItems.findIndex((item) => location.pathname === item.url),
    );
  }, [location]);

  const { data, isLoading, isFetching } = useGetUserProfileQuery();

  const { data: groupsData } = useGetUserNotificationsQuery(
    account ? { accountId: account.id, request: { q: '' } } : skipToken,
  );

  const [updateProfile, { isLoading: isUpdatingProfile }] =
    useUpdateUserProfileMutation();

  const [updateUserNotifications, { isLoading: isUpdatingNotifications }] =
    useUpdateUserNotificationsMutation();

  const getInitialValues = {
    ...data?.data,
    groups: groupsData?.data,
  };

  const schema = Yup.object().shape({
    firstName: Yup.string().required(
      t('profileSettings.profile.form.firstName.required'),
    ),
    lastName: Yup.string().required(
      t('profileSettings.profile.form.lastName.required'),
    ),
    mobile: Yup.object({
      country: Yup.string().required(
        t('profileSettings.profile.form.country.required'),
      ),
      number: Yup.string()
        .matches(
          /^[\s()+-]*([0-9][\s()+-]*){6,20}$/,
          t('profileSettings.profile.form.phone.invalid'),
        )
        .required(t('profileSettings.profile.form.phone.required')),
      // verified: Yup.string().required('Please verify your mobile number'),
    }),
    groups: Yup.array().of(Yup.object()),
  });

  const validate = makeValidate(schema);

  const resetForm = async (form: any) => {
    await form.reset();
  };

  const doUpdateProfile = async (values: any) =>
    updateProfile(values)
      .unwrap()
      .then(
        () => {
          dispatch(
            showToast({
              severity: 'success',
              message: t('updateSuccess', {
                item: t('profileSettings.profile.heading'),
              }),
            }),
          );
        },
        (error) => {
          logger.info({ error }, 'color: red');

          dispatch(
            showToast({
              severity: 'error',
              message: t('updateFailed', {
                item: t('profileSettings.profile.heading').toLowerCase(),
              }),
            }),
          );
        },
      );

  const doUpdateNotifications = async (groups: GroupNotificationSettings[]) =>
    updateUserNotifications(groups)
      .unwrap()
      .then(
        () => {
          dispatch(
            showToast({
              severity: 'success',
              message: t('updateSuccess', {
                item: t('profileSettings.notifications.title'),
              }),
            }),
          );
        },
        (error) => {
          logger.info({ error }, 'color: red');
          dispatch(
            showToast({
              severity: 'error',
              message: t('updateFailed', {
                item: t('profileSettings.notifications.title').toLowerCase(),
              }),
            }),
          );
        },
      );

  const onSubmit = async (values: any) => {
    if (location.pathname === '/profile') {
      doUpdateProfile(values);
    }

    if (location.pathname === '/profile/notifications') {
      const { groups } = values;

      await doUpdateNotifications(groups);

      // if (isAnyBrowserChecked) {

      // } else {
      //   getUserSubscription().then((subscription) => {
      //     if (subscription) {
      //       unsubscribeToUserNotifications(subscription).then((result) => {
      //         logger.info({ result });
      //       });
      //     }
      //   });
      // }
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={getInitialValues}
      render={({
        form,
        handleSubmit,
        pristine,
        hasValidationErrors,
        submitting,
      }) => (
        <Stack
          component="form"
          onSubmit={handleSubmit}
          sx={{
            height: '100vh',
          }}
        >
          <PageHeader
            title={t('profileSettings.title')}
            ctaDisabled={pristine || hasValidationErrors}
            loading={
              // isSubscribing ||
              // isUnsubscribing ||
              isUpdatingProfile || isUpdatingNotifications || submitting
            }
            ctaAction={handleSubmit}
            ctaText={t('action.save')}
            cancelAction={() => resetForm(form)}
            cancelText={t('action.cancel')}
          />
          <TabbedNavigation
            tabs={navItems}
            selectedTab={selectedTab}
            onTabSelected={(tab) => {
              setSelectedTab(tab);
            }}
            divider
          >
            {isLoading || isFetching ? (
              <Loader size={200} label="Loading data..." />
            ) : (
              <Routes>{navItems.map((tab) => tab.content)}</Routes>
            )}
          </TabbedNavigation>
        </Stack>
      )}
    />
  );
}

export default ProfileSettings;
