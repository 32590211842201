import {
  SerializedRootNode,
  SerializedLexicalNode,
  SerializedTextNode,
  SerializedParagraphNode,
} from 'lexical';
import handlebars from '../../../../utils/handlebars';
import { SerializedVariableNode } from '../nodes/VariableNode';

const convertHandlebarsPart = (part: any): SerializedLexicalNode => {
  switch (part.type) {
    case 'ContentStatement':
      return {
        type: 'text',
        version: 1,
        text: part.value,
      } as SerializedTextNode;
    case 'MustacheStatement':
      return {
        type: 'variable',
        version: 1,
        name: part.path.original,
        params: part.params.map(({ value }: any) => value),
      } as SerializedVariableNode;
    default:
      throw new Error('unrecognized type - ' + part.type);
  }
};

export const parseHandlebars = (
  input: string,
  ignoreErrors?: boolean,
): SerializedRootNode => {
  try {
    const parsed = handlebars.parse(input);
    const root: SerializedRootNode = {
      direction: 'ltr',
      children: [
        {
          type: 'paragraph',
          direction: 'ltr',
          format: '',
          indent: 0,
          version: 1,
          children: parsed.body.map(convertHandlebarsPart),
        } as SerializedParagraphNode,
      ],
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    };

    return root;
  } catch (err) {
    if (ignoreErrors) {
      return parseHandlebars('', false);
    }

    throw err;
  }
};

// const convertPart = (part: any): SerializedLexicalNode => {
//   const handlebarsRegex = /^{{{\s*[\w.]+\s*}}}$/;

//   if (handlebarsRegex.test(part)) {
//     return {
//       type: 'variable',
//       version: 1,
//       name: part.replace(/{{{\s*|\s*}}}/g, ''),
//     } as SerializedVariableNode;
//   }
//   return {
//     type: 'text',
//     version: 1,
//     text: part,
//   } as SerializedTextNode;
// };

// export const parseHandlebars2 = (input: string): SerializedRootNode => {
//   const parsed = splitByHandlebars(input);
//   // logger.info('parsed', parsed);

//   const root: SerializedRootNode = {
//     direction: 'ltr',
//     children: [
//       {
//         type: 'paragraph',
//         direction: 'ltr',
//         format: '',
//         indent: 0,
//         version: 1,
//         children: parsed.map(convertPart),
//       } as SerializedParagraphNode,
//     ],
//     format: '',
//     indent: 0,
//     type: 'root',
//     version: 1,
//   };

//   return root;
// };

export const exportHandlebars = (node: SerializedLexicalNode): string => {
  // logger.info('exportHandlebars', node);
  switch (node.type) {
    case 'root':
    case 'paragraph':
      return (node as SerializedRootNode).children
        .map(exportHandlebars)
        .join('');
    case 'linebreak':
      return '\n';
    case 'text':
      return (node as SerializedTextNode).text;
    case 'variable': {
      const varNode = node as SerializedVariableNode;
      let varString = varNode.name;

      if (varNode.params?.length) {
        varString = [
          varNode.name,
          ...varNode.params.map((value) => `'${value}'`),
        ].join(' ');
      }

      return varNode.escaped ? `{{ ${varString} }}` : `{{{ ${varString} }}}`;
    }
    // return node.
    //   return exportTextNode(node as SerializedTextNode);
    default:
      return '';
  }
};
