import React from 'react';
import { countries } from '@sakari-io/sakari-common';
import { Country } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import { Autocomplete, Flag } from '@sakari-io/sakari-components';
import { AutocompleteProps } from '../../../clonedfromcomponents';

interface CountryAutocompleteProps
  extends Omit<
    AutocompleteProps<Country>,
    'options' | 'isOptionEqualToValue' | 'getOptionLabel' | 'renderOption'
  > {
  rounded?: boolean;
  sx?: any;
}

function CountryAutocomplete({
  label,
  rounded,
  sx,
  value,
  ...rest
}: CountryAutocompleteProps) {
  const { t } = useTranslation();

  return (
    <Autocomplete
      id="country"
      label={label}
      placeholder={t('form.country.label')}
      orientation="vertical"
      fullWidth
      options={countries}
      startDecorator={<Flag country={value} rounded={rounded} />}
      getOptionLabel={(option: any) =>
        (option as Country).name ||
        countries.find((c) => c.code === (option as Country).code)?.name ||
        ''
      }
      isOptionEqualToValue={(option: any, value: any) =>
        option.code === value?.code
      }
      renderOption={(props: any, option: any) => (
        <AutocompleteOption {...props}>
          <ListItemDecorator>
            <Flag country={option} rounded={rounded} />
          </ListItemDecorator>
          <ListItemContent>{option.name}</ListItemContent>
        </AutocompleteOption>
      )}
      filterOptions={(options: any, country: any) =>
        options.filter((o: any) =>
          o.name.toLowerCase().includes(country.inputValue.toLowerCase()),
        )
      }
      autoHighlight
      blurOnSelect
      // autoSelect
      autoComplete
      sx={{
        ...(rounded && {
          '&.MuiAutocomplete-root, & .MuiAutocomplete-popupIndicator': {
            borderRadius: '50px',
          },
        }),

        ...sx,
      }}
      value={value}
      {...rest}
    />
  );
}

export default CountryAutocomplete;
