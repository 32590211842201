import { Language } from './types/ui';

const CORE_ATTRIBUTES = ['firstName', 'lastName', 'mobile', 'country', 'email'];
const ROLES_CLAIM_KEY = 'https://sakari.io/roles';
export interface Attribute {
  attribute: string;
  // display: string;
  alternatives?: string[];
  mandatory?: boolean;
}

const getPrimaryAttributes = (): Attribute[] => {
  return [
    {
      attribute: 'mobile',
      mandatory: true,
    },
    {
      attribute: 'country',
    },
    {
      attribute: 'firstName',
      alternatives: ['givenname'],
    },
    {
      attribute: 'lastName',
      alternatives: ['familyname'],
    },
    {
      attribute: 'email',
    },
  ];
};
enum UserRole {
  ADMIN = 'Administrator',
  CAMPAIGN_MANAGER = 'CampaignManager',
  SENDER = 'Sender',
  READ_ONLY = 'ReadOnly',
  PARTNER = 'Partner',
}

const getSupportedLanguages = (): Language[] => {
  return [
    { id: 'en', country: 'us' },
    { id: 'fr', country: 'fr' },
    { id: 'de', country: 'de' },
    { id: 'es', country: 'es' },
    { id: 'br', country: 'br' },
  ];
};

const integrationCategories = [
  'accounting',
  'ads-conversion',
  'analytics',
  'app-builder',
  'business-intelligence',
  'cms',
  'commerce',
  'communication',
  'contacts',
  'content-files',
  'crm',
  'customer-appreciation',
  'customer-support',
  'dashboards',
  'developer-tools',
  'devices',
  'documents',
  'drip-emails',
  'ecommerce',
  'education',
  'email',
  'email-newsletters',
  'event-management',
  'fax',
  'fitness',
  'forms',
  'fundraising',
  'google',
  'hr',
  'human-resources',
  'images',
  'internet-of-things',
  'invoices',
  'it-operations',
  'it-operations-education',
  'marketing',
  'marketing-automation',
  'microsoft',
  'notes',
  'notifications',
  'payment-processing',
  'phone',
  'product-management',
  'productivity',
  'project-management',
  'reviews',
  'sales-crm',
  'scheduling',
  'security-identity',
  'server-monitoring',
  'signatures',
  'social',
  'social-marketing',
  'spreadsheets',
  'team-collaboration',
  'team-chat',
  'time-tracking',
  'todo-lists',
  'url-shorten',
  'video',
  'video-calls',
  'webinars',
  'website-app-building',
  'zoho',
];

const daysOfWeekShort: string[] = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
];

const INDUSTRY_TYPES = [
  'accounting',
  'airlines_aviation',
  'alternative_dispute_resolution',
  'alternative_medicine',
  'animation',
  'apparel_fashion',
  'architecture_planning',
  'arts_and_crafts',
  'automotive',
  'aviation_aerospace',
  'banking',
  'biotechnology',
  'broadcast',
  'media',
  'building_materials',
  'business_supplies_and_equipment',
  'capital_markets',
  'chemicals',
  'civic_social_organization',
  'civil_engineering',
  'commercial_real_estate',
  'computer_network_security',
  'computer_games',
  'computer_hardware',
  'computer_networking',
  'computer_software',
  'internet',
  'construction',
  'consumer_electronics',
  'consumer_goods',
  'consumer_services',
  'cosmetics',
  'dairy',
  'defense_space',
  'design',
  'education_management',
  'e-learning',
  'electrical_electronic_manufacturing',
  'entertainment',
  'environmental_services',
  'events_services',
  'executive_office',
  'facilities_services',
  'farming',
  'financial_services',
  'fine_art',
  'fishery',
  'food_beverages',
  'food_production',
  'fund-raising',
  'furniture',
  'gambling_casinos',
  'glass,_ceramics_concrete',
  'government_administration',
  'government_relations',
  'graphic_design',
  'health,_wellness_and_fitness',
  'higher_education',
  'hospital_health_care',
  'hospitality',
  'human_resources',
  'import_and_export',
  'individual_family_services',
  'industrial_automation',
  'information_services',
  'information_technology_and_services',
  'insurance',
  'international_affairs',
  'international_trade_and_development',
  'investment_banking',
  'investment_management',
  'judiciarylaw_enforcement',
  'law_practice',
  'legal_services',
  'legislative_office',
  'leisure,_travel_tourism',
  'libraries',
  'logistics_and_supply_chain',
  'luxury_goods_jewelry',
  'machinery',
  'management_consulting',
  'maritime',
  'market_research',
  'marketing_and_advertising',
  'mechanical_or_industrial_engineering',
  'media_production',
  'medical_devices',
  'medical_practice',
  'mental_health_care',
  'military',
  'mining_metals',
  'motion_pictures_and_film',
  'museums_and_institutions',
  'music',
  'nanotechnology',
  'newspapers',
  'non-profit_organization_management',
  'oil_energy',
  'online_media',
  'outsourcing_offshoring',
  'package_freight_delivery',
  'packaging_and_containers',
  'paper_forest_products',
  'performing_arts',
  'pharmaceuticals',
  'philanthropy',
  'photography',
  'plastics',
  'political_organization',
  'primary_secondary_education',
  'printing',
  'professional_training_coaching',
  'program_development',
  'public_policy',
  'public_relations_and_communications',
  'public_safety',
  'publishing',
  'railroad_manufacture',
  'ranching',
  'real_estate',
  'recreational_facilities_and_services',
  'religious_institutions',
  'renewables_environment',
  'research',
  'restaurants',
  'retail',
  'security_and_investigations',
  'semiconductors',
  'shipbuilding',
  'sporting_goods',
  'sports',
  'staffing_and_recruiting',
  'supermarkets',
  'telecommunications',
  'textiles',
  'think_tanks',
  'tobacco',
  'translation_and_localization',
  'transportation_trucking_railroad',
  'utilities',
  'venture_capital_private_equity',
  'veterinary',
  'warehousing',
  'wholesale',
  'wine_and_spirits',
  'wireless',
  'writing_and_editing',
];

const COMPARATORS = [
  { value: '=', label: 'equals', multipleValues: true },
  { value: '!=', label: 'does not equal', multipleValues: true },
  {
    value: '<',
    label: 'less than',
    compatibleWith: ['number'],
    multipleValues: false,
  },
  {
    value: '<=',
    label: 'less than or equal',
    compatibleWith: ['number'],
    multipleValues: false,
  },
  {
    value: '>',
    label: 'greater than',
    compatibleWith: ['number'],
    multipleValues: false,
  },
  {
    value: '>=',
    label: 'greater than or equal',
    compatibleWith: ['number'],
    multipleValues: false,
  },
  {
    value: 'contains',
    label: 'contains',
    compatibleWith: ['text'],
    multipleValues: false,
  },
];

export {
  CORE_ATTRIBUTES,
  getPrimaryAttributes,
  getSupportedLanguages,
  UserRole,
  ROLES_CLAIM_KEY,
  integrationCategories,
  daysOfWeekShort,
  INDUSTRY_TYPES,
  COMPARATORS,
};
