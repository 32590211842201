import React, { useState } from 'react';

import {
  OnboardingInbox,
  OnboardingContacts,
  OnboardingAnalytics,
  OnboardingCampaigns,
  OnboardingLists,
} from '../../assets/screenshots/onboarding';
import Lightbox from '../../ui/organisms/LightBox';

const template = (src: string, alt: string): JSX.Element => (
  <img src={src} alt={alt} height="319px" />
);

interface TutorialProps {
  isOpen?: boolean;
  setIsOpen: (state: boolean) => void;
}

function Tutorial({ isOpen, setIsOpen }: TutorialProps) {
  const [currentStep, setCurrentStep] = useState(0);

  const onBoardingFlow = [
    {
      title: 'Welcome to Sakari!',
      description: 'This is your inbox.',
      content: template(OnboardingInbox, 'inbox'),
    },
    {
      title: 'Welcome to Sakari!',
      description:
        'This page is your contacts. These are people you chat with.',
      content: template(OnboardingContacts, 'contacts-page'),
    },
    {
      title: 'Welcome to Sakari!',
      description:
        'Upload contacts to create lists you can use to segment customers.',
      content: template(OnboardingLists, 'contact-lists'),
    },
    {
      title: 'Welcome to Sakari!',
      description: 'You can send campaigns to lists of contacts.',
      content: template(OnboardingCampaigns, 'campaigns'),
    },
    {
      title: 'Welcome to Sakari!',
      description: 'Track analytics on your campaigns.',
      content: template(OnboardingAnalytics, 'analytics'),
      actionButtonText: 'Get Started',
      // onActionButtonClick: () => {
      //   setIsOpen(false);
      //   if (account) {
      //     helper.markedOnBoardingCompleted(account.name);
      //   }
      // },
    },
  ];

  const args = {
    modalLabel: 'onboarding-flow',
    modalDescription: 'Onboarding flow for new users',
    next: true,
    currentStep,
    setCurrentStep,
    open: isOpen,
    onOpenClose: setIsOpen,
    steps: onBoardingFlow,
  };

  return <Lightbox {...args} />;
}

export default Tutorial;
