import React, { useEffect } from 'react';
import { RadioGroup, RadioGroupProps, Tooltip } from '@mui/joy';
import Radio, { RadioProps } from '@mui/joy/Radio';
import InputControl from '../InputControl';

export interface RadioData<T> extends RadioProps {
  label: string | number | React.ReactNode;
  value: T;
  description?: string;
  tooltip?: string;
  sx?: React.CSSProperties;
}

interface RadiosProps<T> extends Omit<RadioGroupProps, 'onChange' | 'value'> {
  items: RadioData<T>[];
  value: T;
  onChange: (val: T) => any;
  style?: React.CSSProperties;
  row?: boolean;
  label?: string;
  helperText?: string;
  error?: boolean;
  wrap?: boolean;
  orientation?: 'horizontal' | 'vertical';
  renderLabel?: (item: RadioData<T>) => React.ReactNode;
  children?: React.ReactNode;
}

function Radios<T>({
  items,
  onChange,
  style,
  helperText,
  row,
  value,
  label,
  error,
  orientation = 'vertical',
  children,
  size,
  wrap,
  renderLabel,
  ...rest
}: RadiosProps<T>) {
  const [selected, setSelected] = React.useState(value);
  useEffect(() => {
    setSelected(value);
  }, [value]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as T;
    setSelected(newValue);
    onChange(newValue);
  };

  return (
    <InputControl
      label={label}
      helperText={helperText}
      error={error}
      orientation={orientation}
      size={size}
    >
      <RadioGroup
        sx={{
          whiteSpace: 'nowrap',
          gap: '16px',
          flexWrap: wrap ? 'wrap' : 'nowrap',
          '--RadioGroup-gap': 0,
          ...rest.sx,
        }}
        orientation={row ? 'horizontal' : 'vertical'}
        style={style}
        value={selected}
        onChange={handleChange}
        size={size}
      >
        {(items || []).map((item: RadioData<T>, i) =>
          item?.tooltip ? (
            <Tooltip key={item?.id ?? i} title={item?.tooltip}>
              <Radio
                key={item?.id ?? i}
                value={item?.value}
                label={renderLabel ? renderLabel(item) : item?.label}
                disabled={item?.disabled}
                sx={item?.sx}
              />
            </Tooltip>
          ) : (
            <Radio
              key={item?.id ?? i}
              value={item?.value}
              label={renderLabel ? renderLabel(item) : item?.label}
              disabled={item?.disabled}
              sx={item?.sx}
            />
          ),
        )}
        {children}
      </RadioGroup>
    </InputControl>
  );
}

export default Radios;
