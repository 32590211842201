import { Group } from '@sakari-io/sakari-typings';
import React, { useContext, useState } from 'react';
import { Box, Stack, Chip, ChipDelete } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@sakari-io/sakari-components';
import { useGetGroupsQuery } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';

interface SenderGroupsProps {
  value: Group[];
  onChange: (value: Group | (string | Group)[] | null) => void;
  label?: string;
}

function SenderGroups({ value, onChange }: SenderGroupsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const [input, setInput] = useState('');
  const { data } = useGetGroupsQuery({
    accountId: account?.id || '',
    request: { q: input },
  });

  if (value?.length) {
    return (
      <Stack spacing={2} direction="row">
        {value.map((group: Group) => (
          <Box key={group.id}>
            <Chip
              size="sm"
              title={group.name}
              onClick={() => navigate(`/groups/${group.id}`)}
              endDecorator={
                <ChipDelete
                  onClick={() =>
                    onChange(
                      (value || []).filter((c: Group) => c.id !== group.id),
                    )
                  }
                />
              }
            >
              {group.name}
            </Chip>
          </Box>
        ))}
      </Stack>
    );
  }

  return (
    <Autocomplete
      value={value}
      multiple
      onChange={onChange}
      inputValue={input}
      onInputChange={(evt: any, value: any) => {
        setInput(value);
      }}
      options={data?.data || []}
      autoHighlight
      filterSelectedOptions
      getOptionLabel={(option: any) => (option as Group).name}
      isOptionEqualToValue={(option: any, value: any) => {
        return option.id === value.id || option === value;
      }}
      placeholder={t('selectGroup')}
      renderTags={(value: any, getTagProps: any) =>
        value.map((option: any, index: number) => (
          <Chip {...getTagProps({ index })}>{option.name}</Chip>
        ))
      }
    />
  );
}

export default SenderGroups;
