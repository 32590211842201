/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { faPerson, faSitemap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/joy';
import TrackedLinkChip from './TrackedLinkChip';
import WorkflowOutputPropertyChip from './WorkflowOutputPropertyChip';
import WorkflowChip from './WorkflowChip';

interface VariableComponentProps {
  nodeKey: string;
  name: string;
  params: string[];
  onHover?: (nodeId?: string) => any;
}

function VariableComponent({
  nodeKey,
  name,
  params,
  onHover,
}: VariableComponentProps) {
  const [showModal, setShowModal] = useState(false);

  const handleShowDialog = () => {
    setShowModal(true);
  };

  switch (name) {
    case 'track':
    case 'link':
      return <TrackedLinkChip id={params[0]} />;
    case 'output':
      return <WorkflowOutputPropertyChip nodeId={params[0]} path={params[1]} />;
    case 'workflow':
      return <WorkflowChip name={params[0]} onClick={handleShowDialog} />;
    default:
      return (
        <Chip
          color="primary"
          startDecorator={<FontAwesomeIcon icon={faPerson} />}
        >
          {name}
        </Chip>
      );
  }
}

export default VariableComponent;
