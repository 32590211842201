import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {
  WorkflowNodeConfig,
  WorkflowNodeType,
} from '@sakari-io/sakari-typings';
import { NodeProps } from 'reactflow';
import { useSelector } from 'react-redux';
import IncompleteButton from '../../../../../../ui/organisms/ButtonContexts/IncompleteButton';
import TriggerPlaceholder from '../TriggerPlaceholder';
import { Mode, MetricType } from '../../../../../../redux/reducers/workflow';
import WorkflowNode from '../../../../Canvas/NodeTypes/WorkflowNode';
import { isNodeComplete } from '../../../../../../utils/workflows/nodevalidation';
import getMetrics from '../../../../Canvas/hooks/getMetrics';
import MetricsTab from '../../../ElementCard/MetricsTab';

interface TriggerContainerProps extends NodeProps {
  data: {
    type?: WorkflowNodeType;
    config?: WorkflowNodeConfig;
    mode: Mode;
  };
}

function TriggerContainer({ id, data, type, ...rest }: TriggerContainerProps) {
  const { t } = useTranslation('workflows');
  const { version, mode, metricType, metrics } = useSelector(
    (state: any) => state.workflow.present,
  );

  const nodeMetrics = getMetrics(
    metrics?.paths,
    metrics?.total ?? 0,
    version,
    metricType,
    id,
  );

  return (
    <Stack alignItems="center" justifyContent="center">
      <Stack
        spacing={2}
        width={350}
        sx={{
          border: '1px solid var(--joy-palette-neutral-outlinedBorder)',
          borderRadius: 'var(--joy-radius-md)',
          backgroundColor: 'var(--joy-palette-background-level1)',
        }}
      >
        <Stack
          sx={{
            borderBottom: '1px solid var(--joy-palette-neutral-outlinedBorder)',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '10px',
            height: '41px',
            backgroundColor: 'var(--joy-palette-common-white)',
            borderRadius: 'var(--joy-radius-md) var(--joy-radius-md) 0px 0px',
          }}
        >
          <Typography level="body-sm" sx={{ fontWeight: 700 }}>
            {t('triggers.trigger')}
          </Typography>

          {data.type &&
            !isNodeComplete(data.type, data.config || {}, []).valid && (
              <IncompleteButton />
            )}
        </Stack>

        <Stack
          sx={{
            padding: 2,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '0px !important',
          }}
        >
          {data.type ? (
            <WorkflowNode id={id} data={data} type={type} {...rest} />
          ) : (
            <TriggerPlaceholder />
          )}
        </Stack>
      </Stack>
      {nodeMetrics &&
        (mode === Mode.VIEW ||
          mode === Mode.VERSIONS ||
          mode === Mode.CONTACTS) && (
          <MetricsTab
            metrics={nodeMetrics}
            percentage={metricType === MetricType.PERCENTAGE}
          />
        )}
    </Stack>
  );
}

export default TriggerContainer;
