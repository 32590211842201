import React, { ReactNode } from 'react';
import { Chip, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileSpreadsheet,
  faTimer,
  faCalendar,
  faClock,
} from '@fortawesome/pro-solid-svg-icons';
import {
  WorkflowEdge,
  WorkflowNodeConfig,
  WorkflowNodeType,
  ListNodeConfig,
  MessageReceivedTriggerConfig,
  LinkClickedTriggerConfig,
  ContactUpdatedTriggerConfig,
  GoogleSheetsLookupNodeConfig,
  ChoiceNodeConfig,
  DelayNodeConfig,
  MessageStatusTriggerConfig,
  LinkClickedNodeConfig,
  SendMessageNodeConfig,
  SetWorkflowPropertyNodeConfig,
  IncrDecrementNodeConfig,
  FilterNodeConfig,
  UpdateContactNodeConfig,
  IfThenNodeConfig,
} from '@sakari-io/sakari-typings';
import OverFlowCell from '../../../../../ui/molecules/OverFlowCell';

interface SummaryRendererProps {
  data: {
    type?: WorkflowNodeType;
    config?: WorkflowNodeConfig;
    edges?: WorkflowEdge[];
    disabled?: boolean;
  };
}

function CustomChip({
  startDecorator,
  endDecorator,
  children,
}: {
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  children: React.ReactNode | string;
}) {
  return (
    <Chip
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      sx={{ width: '150px' }}
    >
      {children}
    </Chip>
  );
}

const renderChip = (content: string | React.ReactNode) => (
  <>{content ? <CustomChip>{content}</CustomChip> : null}</>
);

const renderStack = (items: { text: string; startDecorator?: ReactNode }[]) => (
  <Stack direction="row" spacing={1} sx={{ width: '150px' }}>
    <OverFlowCell
      data={items}
      showMin={2}
      renderItem={(item: { text: string; startDecorator: React.ReactNode }) => {
        if (!item || !item.text) return null;
        return (
          <CustomChip key={item?.text} startDecorator={item?.startDecorator}>
            {item?.text}
          </CustomChip>
        );
      }}
    />
  </Stack>
);

const comparatorMap: { [key: string]: string } = {
  equals: '=',
  notequals: '=/=',
  gte: '>=',
  gt: '>',
  lt: '<',
  lte: '<=',
};

function SummaryRenderer({ data }: SummaryRendererProps) {
  const { config } = data;

  switch (data?.type?.name) {
    case 'filter': {
      const conditions = (config as FilterNodeConfig)?.conditions;
      return conditions?.length
        ? renderStack(conditions?.map((c) => ({ text: c?.attribute?.name })))
        : null;
    }
    case 'ifThen': {
      const conditions = (config as IfThenNodeConfig)?.conditions;
      return conditions?.length
        ? renderStack(conditions?.map((c) => ({ text: c?.attribute?.name })))
        : null;
    }
    case 'updateContact': {
      const updates = (config as UpdateContactNodeConfig)?.updates;
      const propertyNames = updates
        ?.map((p) => ({ text: p?.property?.label ?? p?.property?.name }))
        .filter(({ text }) => !!text);

      return propertyNames?.length ? renderStack(propertyNames as any) : null;
    }
    case 'contactUpdatedTrigger': {
      const condition = (config as ContactUpdatedTriggerConfig)?.condition;
      const attribute = condition?.attribute?.name;
      const comparator = condition?.comparator
        ? comparatorMap[condition.comparator]
        : undefined;
      const value = condition?.value;

      const content =
        `${attribute ?? ''} ${comparator ?? ''} ${value ?? ''}`.trim();

      return content ? renderChip(content) : null;
    }
    case 'missedCallTrigger':
    case 'messageReceivedTrigger': {
      const groups = (config as MessageReceivedTriggerConfig)?.groups || [];
      return groups.length
        ? renderStack(groups.map((group) => ({ text: group.name })))
        : null;
    }
    case 'messageStatusTrigger': {
      const status = (config as MessageStatusTriggerConfig)?.status;
      return status ? renderChip(status) : null;
    }
    case 'removedFromListTrigger':
    case 'addedToListTrigger': {
      const listName = (config as ListNodeConfig)?.list?.name;
      return listName ? renderChip(listName) : null;
    }
    case 'linkClickedTrigger': {
      const link = (config as LinkClickedTriggerConfig)?.link;
      return link ? renderChip(link.url || link.name) : null;
    }
    case 'removeFromList':
    case 'addToList': {
      const listName = (config as ListNodeConfig)?.list?.name;
      return listName ? renderChip(listName) : null;
    }
    case 'choice': {
      const propertyName = (config as ChoiceNodeConfig)?.property?.name;
      return propertyName ? renderChip(propertyName) : null;
    }
    case 'sendMessage': {
      const message = (config as SendMessageNodeConfig)?.message?.message;
      return message ? renderChip(message) : null;
    }
    case 'setWorkflowProperty': {
      const property = (config as SetWorkflowPropertyNodeConfig)?.property;
      return property ? renderChip(property) : null;
    }
    case 'incrDecrement': {
      const property = (config as IncrDecrementNodeConfig)?.property;
      return property ? renderChip(property) : null;
    }
    case 'delay': {
      const delay = (config as DelayNodeConfig)?.delay;
      const additionalDays = delay?.additionalTime?.days;
      const additionalTime = delay?.additionalTime?.time;
      const { days, hours, minutes } = delay?.initialDelay || {};
      const items: { text: string; startDecorator: React.ReactNode }[] = [];
      // if (days) items.push(`${days} day${days > 1 ? 's' : ''}`);
      // if (hours) items.push(`${hours} hour${hours > 1 ? 's' : ''}`);
      // if (minutes) items.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
      if (days || hours || minutes) {
        items.push({
          text: 'Time Delay',
          startDecorator: <FontAwesomeIcon icon={faTimer} />,
        });
      }
      if (additionalDays?.length) {
        items.push({
          text: 'Specific Day',
          startDecorator: <FontAwesomeIcon icon={faCalendar} />,
        });
      }
      if (additionalTime) {
        items.push({
          text: 'Specific Time',
          startDecorator: <FontAwesomeIcon icon={faClock} />,
        });
      }
      return renderStack(items);
    }
    case 'googleSheetsLookup': {
      const sheetName = (config as GoogleSheetsLookupNodeConfig)?.googlesheet
        ?.name;
      const tabName = (config as GoogleSheetsLookupNodeConfig)?.googlesheet?.tab
        ?.name;

      return sheetName ? (
        <CustomChip
          startDecorator={<FontAwesomeIcon icon={faFileSpreadsheet} />}
        >
          {`${sheetName} - ${tabName ?? ''} `}
        </CustomChip>
      ) : null;
    }
    case 'googleSheetsExport': {
      const sheetName = (config as GoogleSheetsLookupNodeConfig)?.googlesheet
        ?.name;
      const tabName = (config as GoogleSheetsLookupNodeConfig)?.googlesheet?.tab
        ?.name;
      return sheetName ? (
        <CustomChip
          startDecorator={<FontAwesomeIcon icon={faFileSpreadsheet} />}
        >
          {`${sheetName} - ${tabName ?? ''} `}
        </CustomChip>
      ) : null;
    }
    // case 'teslaRetrieveState':
    // case 'teslaSendCommand':
    //   return config.vehicleId ? renderChip(link) : undefined;
    case 'linkClicked': {
      const link = (config as LinkClickedNodeConfig)?.link?.url;
      return link ? renderChip(link) : null;
    }
    case 'slackSendMessage':
      return renderChip((config as any).message);
    default:
      return null;
  }
}

export default SummaryRenderer;
