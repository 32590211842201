import React from 'react';
import { Box, Button, Divider, Stack } from '@mui/joy';

interface ActionButtonsProps {
  topBtn: any;
  bottomBtn: string;
  bottomAction: () => void;
}

function ActionButtons({
  topBtn,
  bottomBtn,
  bottomAction,
}: ActionButtonsProps) {
  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '8px 0',
        height: '100%',
        borderLeft: `1px solid ${'var(--joy-palette-voice-softColor)'}}`,
      }}
    >
      <Box
        sx={{
          padding: '4px 30px',
        }}
      >
        {topBtn}
      </Box>
      <Divider sx={{ backgroundColor: 'var(--joy-palette-voice-softColor)' }} />
      <Box
        sx={{
          padding: '4px 30px',
        }}
      >
        <Button
          variant="plain"
          color="voiceBtn"
          size="sm"
          sx={{
            color: 'var(--joy-palette-danger-500)',
            '&:hover': { opacity: 0.8 },
          }}
          onClick={bottomAction}
        >
          {bottomBtn}
        </Button>
      </Box>
    </Stack>
  );
}

export default ActionButtons;
