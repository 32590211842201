import React, { useEffect, useState } from 'react';
import { AccountAttribute } from '@sakari-io/sakari-typings';
import { Option, Select } from '@mui/joy';
import { COMPARATORS } from '../../../../constants';

type Comparator = {
  value: string;
  label: string;
  compatibleWith?: string[];
  multipleValues?: boolean;
};

const filterComparators = (attribute: AccountAttribute) => {
  return COMPARATORS.filter(
    ({ compatibleWith }) =>
      !attribute ||
      !compatibleWith ||
      !attribute?.dataType ||
      compatibleWith.includes(attribute?.dataType),
  );
};

interface ComparatorPickerProps {
  attribute: AccountAttribute;
  value: string;
  onChange: (value: string) => any;
}

function ComparatorPicker({
  attribute,
  value,
  onChange,
}: ComparatorPickerProps) {
  const [options, setOptions] = useState<Comparator[]>(
    filterComparators(attribute),
  );

  useEffect(() => {
    setOptions(filterComparators(attribute));
  }, [attribute]);

  const handleOnChange = (newVal: string | null) => {
    return onChange(newVal || '');
  };

  return (
    <Select
      value={value}
      onChange={(evt, val) => handleOnChange(val)}
      size="sm"
      slotProps={{
        listbox: {
          sx: {
            maxHeight: 100,
            overflowY: 'overlay',
          },
        },
      }}
    >
      {options.map((opt) => {
        return (
          <Option value={opt.value} key={opt.value}>
            {opt.label}
          </Option>
        );
      })}
    </Select>
  );
}
export default ComparatorPicker;
