import React, { useState, useContext, useEffect } from 'react';
import { Stack } from '@mui/joy';
import {
  Conversation,
  CreateConversation,
  Group,
} from '@sakari-io/sakari-typings';
import _ from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ConversationFilter as ConversationFilterType } from '../../../../types';
import ConversationsList from '../../Lists/ConversationsList';
import ConversationsHeader from '../../headers/ConversationsHeader';
import { AccountContext } from '../../../../contexts/account.context';
import {
  useGetConversationsQuery,
  useMarkConversationAsReadMutation,
} from '../../../../api';
import { UserRole } from '../../../../constants';

export const LIMIT = 20;

interface ConversationsProps {
  conversations: Conversation[];
  setConversations: any;
  selected?: Conversation | CreateConversation;
  onSelected: (conversation: Conversation | CreateConversation) => any;
  onStartConversation: (group: Group) => void;
  group?: Group;
}

function Conversations({
  conversations,
  setConversations,
  selected,
  onSelected,
  onStartConversation,
  group,
}: ConversationsProps) {
  const { account, userRole } = useContext(AccountContext);
  const [filter, setFilter] = useState<ConversationFilterType>({
    blocked: false,
    closed: false,
    q: '',
  });
  const [offset, setOffset] = useState<number>(0);

  const getQuery = (offset: number) =>
    account && group?.id && offset >= 0
      ? {
          accountId: account?.id,
          request: {
            group: group.id === 'all' ? '' : group.id,
            offset,
            limit: 20,
            ...filter,
            closed: filter.closed,
          },
        }
      : skipToken;

  const { data, isLoading, isFetching, refetch } = useGetConversationsQuery(
    getQuery(offset),
  );

  const [markConversationAsRead] = useMarkConversationAsReadMutation();

  useEffect(() => {
    setOffset(0);
  }, [filter, group?.id]);

  useEffect(() => {
    if (!isFetching && data) {
      setConversations((prev: Conversation[]) => {
        const newConvos = [...(offset > 0 ? prev : []), ...data.data];

        return _.uniqBy(newConvos, 'id');
      });
    }
  }, [isFetching, data]);

  useEffect(() => {
    if (userRole === UserRole.READ_ONLY) return;

    const found = conversations.find((conv) => conv.id === selected?.id);

    if (found && found?.unread && found.unread.length > 0) {
      markConversationAsRead(found);
      refetch();
    }
  }, [conversations, selected]);

  const handleSelected = (conversation: Conversation) => {
    onSelected({ ...conversation });
  };

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'flex-start',
        transition: 'width 0.3s ease-in-out',
        overflow: 'hidden',
      }}
    >
      <ConversationsHeader filter={filter} onFilterChange={setFilter} />

      {account && group ? (
        <ConversationsList
          value={conversations}
          onChange={() => setOffset(offset + LIMIT)}
          selected={selected}
          onSelected={handleSelected}
          onStartConversation={() => onStartConversation(group)}
          loading={isLoading}
          hasMore={isFetching || data?.data.length === 20}
          showGroup={group.id === 'all'}
        />
      ) : null}
    </Stack>
  );
}

export default Conversations;
