import React from 'react';
import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../ui/organisms/headers/PageHeader';
import AddPhoneNumbers from './PhoneNumber';

function AddNewSender() {
  const { t } = useTranslation();
  const { senderType, mode } = useParams();
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        flexFlow: 'column',
        backgroundColor: 'background.default',
      }}
    >
      <PageHeader
        title={t(`senders.add.${senderType}.title`)}
        backAction={() => navigate('../senders')}
        // subheading={mode && t(`addSenders.${senderType}.${mode}.subheading`)}
        cancelText={t('cancelSetup')}
        cancelLink="/senders"
      />

      {senderType === 'phonenumbers' && <AddPhoneNumbers mode={mode} />}
    </Stack>
  );
}
export default AddNewSender;
