import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/joy';
import React, { useContext } from 'react';
import { format } from '@sakari-io/sakari-common';
import { ActiveCall } from '../../../../types';
import { updateQueryData } from '../../../../api/voice';
import { useAppDispatch } from '../../../../redux';
import { AccountContext } from '../../../../contexts/account.context';

interface CallAcceptedProps {
  call: ActiveCall;
}

function CallAccepted({ call }: CallAcceptedProps) {
  const { t } = useTranslation('voice');
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Stack sx={{ flexGrow: 1, padding: '20px 16px' }}>
        <Typography level="body-md" sx={{ color: 'white' }}>
          {call.target ? `${t('transferAccepted')}` : 'Call answered by'}
        </Typography>
        {call.user ? (
          <Typography
            level="body-md"
            sx={{ color: 'white', fontWeight: 'bold' }}
          >
            {format.name(call.user)}
          </Typography>
        ) : null}
      </Stack>
      <Button
        variant="plain"
        size="sm"
        color="danger"
        sx={{
          marginRight: '8px',
          '&:hover': { opacity: 0.8 },
        }}
        onClick={() => {
          if (account) {
            dispatch(
              updateQueryData('startSession', account.id, (draft) => {
                draft.data.call = undefined;
              }),
            );
          }
        }}
      >
        {t('dismiss')}
      </Button>
    </Box>
  );
}

export default CallAccepted;
