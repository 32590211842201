import { Card, Typography, Stack } from '@mui/joy';
import { Loader, Logo } from '@sakari-io/sakari-components';

interface PlanCardProps {
  type?: 'current' | 'new';
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  provider?: string;
}

function PlanCard({
  type = 'current',
  isLoading,
  title,
  subtitle,
  children,
  provider,
}: PlanCardProps) {
  return (
    <Card
      variant="plain"
      sx={{
        paddingTop: 3,
        paddingBottom: 4,
        px: 2.5,
        overflow: 'hidden',
        flex: 1,
        height: 'fit-content',
        boxShadow: 'lg',
        borderColor: 'divider',
        background:
          'radial-gradient(100% 100% at 50% 0%, #FFFFFF 0%, #F3F9FF 100%)',

        '& .MuiTypography-root': {
          color: 'primary.700',
          '&.MuiTypography-body-md': {
            opacity: 0.8,
          },
        },

        ...(type === 'new' && {
          borderColor: 'primary.700',
          background:
            'radial-gradient(126.3% 158.85% at 12.78% 7.11%, #0B48B1 0%, #022E6C 100%)',

          '& .MuiTypography-root, .MuiFormLabel-root': {
            color: 'white',
            '&.MuiTypography-body-md': {
              opacity: 0.8,
            },
          },
        }),
      }}
    >
      {isLoading && <Loader size={100} />}
      {title && (
        <Stack direction="row" alignItems="flex-end" gap={1}>
          <Typography level="h4">{title}</Typography>
          {subtitle && <Typography>{subtitle} </Typography>}
        </Stack>
      )}
      {provider && (
        <Stack
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            borderRadius: '5px',
            background: 'white',
            height: '20px',
            width: '20px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Logo provider={provider} size={16} />
        </Stack>
      )}
      {children}
    </Card>
  );
}

export default PlanCard;
