const handlebars = require('handlebars/dist/handlebars');

const passThroughHelper = (name: string) =>
  handlebars.registerHelper(name, (...args: any) => {
    const formattedArgs = args
      .slice(0, args.length - 1)
      .map((a: any) => (typeof a === 'number' ? a : `'${a}'`))
      .join(' ');
    return `{{{ ${name} ${formattedArgs} }}}`;
  });

passThroughHelper('allowReply');
passThroughHelper('formatDate');
passThroughHelper('formatDateTime');
passThroughHelper('track');
passThroughHelper('url');
passThroughHelper('useDefault');

export default handlebars;
