import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/joy';

interface WebhookToolbarProps {
  onCreateClick: () => void;
}

function WebhookToolbar({ onCreateClick }: WebhookToolbarProps) {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        alignItems: 'flex-end',
      }}
    >
      <Button size="sm" onClick={onCreateClick} sx={{ width: 'fit-content' }}>
        {t('settings.api.webhooks.createWebhookButtonText')}
      </Button>
    </Stack>
  );
}

export default WebhookToolbar;
