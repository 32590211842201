import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Typography,
} from '@mui/joy';
import { WorkflowNodeType } from '@sakari-io/sakari-typings';
import ElementCard from '../ElementCard';

interface ElementGroupProps {
  title?: string;
  label?: string;
  options: WorkflowNodeType[];
  value?: WorkflowNodeType;
  onChange: (value: WorkflowNodeType) => any;
  isDisabled?: (
    selected?: WorkflowNodeType,
    option?: WorkflowNodeType,
  ) => boolean;
  helperText?: string;
  error?: boolean;
}

function ElementGroup({
  title,
  label,
  options,
  value,
  onChange,
  isDisabled,
  helperText,
  error,
}: ElementGroupProps) {
  const renderOptions = (options: WorkflowNodeType[]) =>
    (options || []).map((option: WorkflowNodeType) => {
      return (
        <React.Fragment key={option.id}>
          <ElementCard
            type={option}
            disableEditMode
            key={option.id}
            value={option}
            disabled={isDisabled ? isDisabled(value, option) : false}
            selected={option.id === value?.id}
            setSelected={() => onChange && onChange(option)}
            omitInlineLabel
          />
        </React.Fragment>
      );
    });

  return (
    <>
      {title ? (
        <Typography level="body-md" fontWeight={500}>
          {title}
        </Typography>
      ) : null}
      <FormControl error={error} sx={{ border: 'none', padding: '0px' }}>
        {label && <FormLabel>{label}</FormLabel>}
        <RadioGroup value={value} sx={{ gap: '0.5rem' }}>
          {renderOptions(options)}
        </RadioGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
}

export default ElementGroup;
