import { faHandshake } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Stack, Typography } from '@mui/joy';
import { Logo } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';

interface PartnerBannerProps {
  planType?: string;
}
function PartnerBanner({ planType }: PartnerBannerProps) {
  const { t } = useTranslation();

  const isPartner = planType === 'partner';

  return (
    <Card
      variant="plain"
      sx={{
        p: 4,
        width: 'clamp(600px, 80%, 960px)',
        background:
          'radial-gradient(100% 100% at 50% 0%, #FFFFFF 0%, #F3F9FF 100%)',
        boxShadow: 'lg',
        borderColor: 'divider',
      }}
    >
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Stack justifyContent="center" sx={{ color: 'primary.700' }}>
          {isPartner ? (
            <FontAwesomeIcon icon={faHandshake} size="2x" />
          ) : (
            <Logo size={40} />
          )}
        </Stack>

        <Stack flex={1}>
          <Typography textColor="primary.700" fontWeight={600}>
            {isPartner
              ? t('settings.billing.plans.yourPlan', {
                  plan: t('items.partner_one'),
                })
              : t('settings.billing.plans.partnerEligible') + '!'}
          </Typography>
          <Typography level="body-xs" textColor="primary.700">
            {t('settings.billing.plans.creditCardOverages')}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
export default PartnerBanner;
