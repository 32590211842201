import React from 'react';
import {
  ListItemDecorator,
  Badge,
  ListItemContent,
  Tooltip,
  ListItemButton,
  Typography,
  Stack,
  SvgIcon,
  ListItem,
  Chip,
} from '@mui/joy';
import { format, isEqual, startOfToday } from 'date-fns';
import {
  Conversation as ConversationType,
  CreateConversation as CreateConversationType,
} from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Avatar, ChannelIcon } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Helper from '../../../../../utils/helper';

const getConversationDateTime = (date: string) => {
  const currentDate = startOfToday();
  const convDate = new Date(date);

  const result =
    isEqual(currentDate.getFullYear(), convDate.getFullYear()) &&
    isEqual(currentDate.getMonth(), convDate.getMonth()) &&
    currentDate.getDate() <= convDate.getDate();

  return result
    ? format(new Date(date), 'hh:mm a')
    : format(new Date(date), 'M/d/yy');
};

interface ConversationListItemProps {
  conversation: ConversationType | CreateConversationType;
  selected?: boolean;
  onSelected: (conversation: ConversationType | CreateConversationType) => any;
  showGroup?: boolean;
}

function ConversationListItem({
  conversation,
  selected,
  onSelected,
  showGroup,
}: ConversationListItemProps) {
  const { t } = useTranslation();
  const hasUnread = (conversation.unread?.length ?? 0) > 0;

  return (
    <ListItem>
      <ListItemButton
        onClick={() => onSelected(conversation)}
        selected={selected}
        variant={hasUnread ? 'soft' : 'plain'}
        color="neutral"
        sx={{
          gap: 3,
          flex: 1,
          alignItems: 'flex-start',
          '&:hover, & .Mui-focused, &:active, &:focus': {
            backgroundColor: 'var(--joy-palette-neutral-softBg)',
          },

          '&.Mui-selected, &[aria-selected="true"]': {
            backgroundColor: 'var(--joy-palette-primary-softBg)',

            '&:hover, & .Mui-focused, &:active, &:focus': {
              backgroundColor: 'var(--joy-palette-primary-softHoverBg)',
              boxShadow: 'none',
            },
          },
          '&:not(.Mui-selected):not([aria-selected="true"]):active, &:not(.Mui-selected):not([aria-selected="true"]):focus':
            {
              boxShadow: 'none',
              backgroundColor: 'neutral.100',
            },

          ...(hasUnread && {
            backgroundColor: `var(--joy-palette-primary-50)`,
          }),
        }}
      >
        <ListItemDecorator>
          <Badge
            size="sm"
            sx={{
              '.MuiBadge-badge': { padding: '3.5px' },
              aspectRatio: '1/1',
              width: 'fit-content',
            }}
            badgeInset="14%"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <ChannelIcon channel={conversation.lastMessage?.type || 'sms'} />
            }
          >
            <Avatar
              variant={selected || hasUnread ? 'solid' : 'soft'}
              color={hasUnread ? 'primary' : 'neutral'}
              person={conversation?.contact}
              sx={{
                ...(hasUnread && {
                  fontWeight: 'bold',
                }),
              }}
            />
          </Badge>
        </ListItemDecorator>

        <ListItemContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Stack sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography
              level="body-md"
              textColor="text.primary"
              fontWeight={hasUnread ? 'lg' : 'md'}
              endDecorator={
                conversation.contact?.blocked && (
                  <Tooltip
                    arrow
                    title={t('contactRequestedNoFurtherCommunication')}
                  >
                    <SvgIcon color="danger" fontSize="xs">
                      <FontAwesomeIcon icon={solid('ban')} />
                    </SvgIcon>
                  </Tooltip>
                )
              }
            >
              {Helper.getNameOrMobileNumber(conversation.contact)}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                level="body-sm"
                fontWeight={hasUnread ? 'md' : 'sm'}
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {conversation.lastMessage?.message ||
                  conversation.lastMessage?.template}
              </Typography>

              {conversation?.lastMessage?.status === 'error' ? (
                <SvgIcon color="danger" fontSize="xs">
                  <FontAwesomeIcon icon={solid('triangle-exclamation')} />
                </SvgIcon>
              ) : null}
            </Stack>
            {showGroup && (
              <Stack>
                <Chip color="primary">
                  {conversation?.group?.name ?? 'Unassigned'}
                </Chip>
              </Stack>
            )}
          </Stack>
          <Stack gap={2}>
            <Badge
              size="sm"
              sx={{
                '.MuiBadge-badge': { boxShadow: 'none' },
              }}
              badgeContent={conversation.unread?.length || null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeInset="0 .4rem -0.75rem 0"
            >
              <Typography level="body-sm" noWrap>
                {conversation?.lastMessage &&
                  conversation?.lastMessage?.created.at &&
                  getConversationDateTime(conversation.lastMessage?.created.at)}
              </Typography>
            </Badge>

            {Helper.isConversationArchived(conversation) && (
              <Tooltip
                arrow
                title={t('itemClosed', {
                  item: _.capitalize(t('items.conversation_one')),
                })}
              >
                <SvgIcon
                  fontSize="xs"
                  style={{
                    alignSelf: 'flex-end',
                  }}
                >
                  <FontAwesomeIcon icon={solid('inbox')} />
                </SvgIcon>
              </Tooltip>
            )}
          </Stack>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

export default ConversationListItem;
