import React from 'react';
import { Stack, Typography, Tooltip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

function InlineTriggerLabel() {
  const { t } = useTranslation('workflows');
  return (
    <Stack
      sx={{
        border: '1px solid var(--joy-palette-divider)',
        borderBottom: 'none',
        borderRadius: '10px 10px 0px 0px',
        backgroundColor: 'var(--joy-palette-common-white)',
      }}
    >
      <Typography
        level="body-xs"
        color="primary"
        sx={{ padding: '2px 10px' }}
        fontWeight={700}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ gap: '5px' }}
        >
          {t('elements.inlineTrigger')}
          <Tooltip
            title={<Typography>{t('triggers.labelDescription')}</Typography>}
            size="sm"
            sx={{
              maxWidth: '230px',
              whiteSpace: 'normal',
            }}
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ color: 'var(--joy-palette-neutral-400)' }}
            />
          </Tooltip>
        </Stack>
      </Typography>
    </Stack>
  );
}

export default InlineTriggerLabel;
