import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { Stack, IconButton, Chip } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridRowParams,
  GridCellParams,
} from '@mui/x-data-grid-pro';
import { Workflow } from '@sakari-io/sakari-typings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, AvatarChip } from '@sakari-io/sakari-components';
import { format } from 'date-fns';
import { faClone, faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
  useGetWorkflowsQuery,
  useDeleteWorkflowMutation,
} from '../../../../api';

import DataGrid from '../../../../ui/molecules/DataGrid';
import EmptyResult from '../../../../ui/molecules/EmptyResult';
import { AccountContext } from '../../../../contexts/account.context';
import { useAppDispatch } from '../../../../redux';
import { handleApiMutationResponse } from '../../../../utils/api';
import DuplicateWorkflowDialog from '../DuplicateWorkflowDialog';
import Dialog from '../../../../components/molecules/Dialog';

const COOKIE_NAME = 'workflow-columns';
export type Methods = 'duplicate' | 'discard' | 'delete';

interface WorkflowsGridProps {
  filter?: any;
  selectedRow?: Workflow;
  onSelectedRowChange: (value: Workflow) => any;
}

function WorkflowsGrid({
  filter,
  selectedRow,
  onSelectedRowChange,
}: WorkflowsGridProps) {
  const { t } = useTranslation('workflows');
  const { account, userRole } = useContext(AccountContext);
  const dispatch = useAppDispatch();

  const [showDialog, setShowDialog] = useState<
    'DeleteWorkflow' | 'DuplicateWorkflow' | undefined
  >();
  const [selected, setSelected] = useState<Workflow>();

  const [deleteWorkflow, { isLoading: isDeleting }] =
    useDeleteWorkflowMutation();

  const handleDelete = async () => {
    if (!account || !selected) return null;

    const request = {
      accountId: account.id,
      request: { workflowId: selected.id },
    };

    return handleApiMutationResponse(
      deleteWorkflow(request).unwrap(),
      dispatch,
      {
        successMessage: t('edit.delete.success'),
        defaultErrorMessage: t('edit.delete.error'),
      },
    ).finally(() => setShowDialog(undefined));
  };

  const handleCloseDialog = () => {
    setSelected(undefined);
    setShowDialog(undefined);
  };

  const getStatusChip = (workflow: Workflow) => {
    const hasActiveVersion = _.some(
      workflow?.versions,
      (version) => version?.active,
    );
    if (hasActiveVersion)
      return <Chip color="primary">{t('versions.active')}</Chip>;
    return workflow?.versions?.length === 1 &&
      !workflow.versions[0].readonly ? (
      <Chip>{t('versions.draft')}</Chip>
    ) : (
      <Chip color="warning">{t('versions.inactive')}</Chip>
    );
  };

  const disabledPermission = userRole === 'ReadOnly' || userRole === 'Sender';

  const columns: GridColDef[] = [
    {
      field: 'status',
      type: 'actions',
      headerName: t('status'),
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return getStatusChip(params.row);
      },
    },
    {
      field: 'name',
      headerName: t('name'),
      minWidth: 150,
      flex: 3,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'updated.at',
      headerName: t('workflow.lastUpdated'),
      minWidth: 150,
      flex: 1,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (value, row) => {
        return format(row.updated?.at, 'PPpp');
      },
      // renderCell: (params: GridCellParams<Workflow>) => {
      //   const date = new Date(params.row?.updated?.at);
      //   return (
      //     <Typography level="body-sm">{format(date, 'MM/dd/yyyy')}</Typography>
      //   );
      // },
    },
    {
      field: 'updatedBy',
      headerName: t('edit.updatedBy'),
      width: 150,
      flex: 1,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams<Workflow>) => {
        return (
          <AvatarChip person={params.row.updated.by || { name: 'unknown' }} />
        );
      },
    },
    {
      field: 'buttons',
      type: 'actions',
      minWidth: 90,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      sortable: false,

      getActions: (params: GridRowParams) => {
        return [
          <>
            <Tooltip
              title={
                disabledPermission ? t('readOnly') : t('edit.duplicate.label')
              }
              arrow
              placement="top"
            >
              <IconButton
                size="smRound"
                onClick={() => {
                  setSelected(params.row);
                  setShowDialog('DuplicateWorkflow');
                }}
                disabled={disabledPermission}
              >
                <FontAwesomeIcon icon={faClone} />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={
                disabledPermission ? t('readOnly') : t('edit.delete.label')
              }
              arrow
              placement="top"
            >
              <IconButton
                size="smRound"
                onClick={() => {
                  setSelected(params.row);
                  setShowDialog('DeleteWorkflow');
                }}
                disabled={disabledPermission}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Tooltip>
          </>,
        ];
      },
    },
  ];

  return (
    <Stack
      sx={{
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        borderRadius: 'var(--joy-radius-md)',
        flexFlow: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: 'background.surface',

        '& .MuiDataGrid-root .MuiDataGrid-menuIcon': {
          visibility: 'visible',
          width: '30px',
        },
      }}
    >
      <DataGrid
        cookie={COOKIE_NAME}
        columns={columns}
        selectedRow={selectedRow}
        onSelectedRowChange={onSelectedRowChange}
        filter={filter}
        loadData={useGetWorkflowsQuery}
        loadingText={t('loadingWorkflows')}
        renderNoData={<EmptyResult item="autoresponders" />}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        getRowId={(row) => row.id || ''}
      />

      {showDialog === 'DeleteWorkflow' && selected && (
        <Dialog
          color="danger"
          title={t('edit.delete.header')}
          primaryTitle={t('edit.delete.label')}
          primaryAction={handleDelete}
          cancelTitle={t('edit.cancel')}
          cancelAction={handleCloseDialog}
          disablePrimary={isDeleting}
          open={showDialog === 'DeleteWorkflow'}
          onClose={handleCloseDialog}
        >
          {t('edit.delete.context')}
        </Dialog>
      )}

      {showDialog === 'DuplicateWorkflow' && selected && (
        <DuplicateWorkflowDialog
          workflow={selected}
          open={showDialog === 'DuplicateWorkflow'}
          onClose={handleCloseDialog}
        />
      )}
    </Stack>
  );
}

export default WorkflowsGrid;
