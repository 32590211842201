import {
  SmsRegulatory,
  SupportedDestinations,
} from '@sakari-io/sakari-typings';

// eslint-disable-next-line import/prefer-default-export
export const getStatus = (
  country: string,
  type: string,
  supportedDestinations?: SupportedDestinations,
  reg?: SmsRegulatory,
) => {
  if (
    ['US', 'CA'].includes(country) &&
    ['tollfree', 'local'].includes(type) &&
    supportedDestinations?.US
  ) {
    if (reg?.submitted) {
      if (reg.approved) {
        return type === 'local' ? 'approved' : 'verified';
      }
      if (reg.rejected) {
        return 'rejected';
      }
      return 'pending';
    }

    if (reg?.brandStatus === 'PENDING' || reg?.campaign?.id === 'PENDING') {
      return 'pending';
    }
    return 'unverified';
  }

  return undefined;
};
