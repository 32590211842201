import React from 'react';
import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {
  SendMessageNodeConfig,
  SendMessageToType,
  User,
  WorkflowProperty,
} from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import { useSelector } from 'react-redux';
import Radios from '../../../../../../ui/molecules/Radios';
import PhoneInput from '../../../../../../ui/atoms/inputs/PhoneInput';
import UserAutoComplete from '../ObjectAutoComplete/UserAutoComplete';
import ContactTypeSelect from '../../../ContactTypeSelect';
import { ConfigFieldProps } from '../PropertyField';

interface SendToProps extends ConfigFieldProps<SendMessageNodeConfig['to']> {
  properties: WorkflowProperty[];
}

function SendTo({
  value,
  onChange,
  properties,
  label,
  helperText,
  error,
  required,
  disabled,
}: SendToProps) {
  const { t } = useTranslation('workflows');

  const { nodes } = useSelector((state: any) => state.workflow.present);
  const nodeType = nodes?.find((n: any) => n.id === value?.contact?.nodeId)
    ?.data?.type;

  const items = [
    {
      label: t('contactExecutions.contact'),
      value: 'contact',
      disabled,
    },
    { label: t('edit.internal'), value: 'user', disabled: true },
    { label: t('Custom'), value: 'custom', disabled },
  ];

  return (
    <InputControl
      label={label}
      helperText={helperText}
      error={error}
      required={required}
    >
      <Stack spacing={1} sx={{ width: '100%' }}>
        <Radios
          row
          items={items}
          value={value?.type ?? ''}
          onChange={(e) => onChange({ type: e as SendMessageToType })}
          size="sm"
        />

        {value?.type === SendMessageToType.Contact && (
          <ContactTypeSelect
            value={value?.contact}
            onChange={(v) =>
              onChange({ type: SendMessageToType.Contact, contact: v })
            }
            properties={properties}
            nodeType={nodeType}
            disabled={disabled}
          />
        )}

        {value?.type === SendMessageToType.Custom && (
          <PhoneInput
            value={{
              country: value?.mobile?.country ?? '',
              number: value?.mobile?.number ?? '',
            }}
            onChange={(newValue) =>
              onChange({ type: SendMessageToType.Custom, mobile: newValue })
            }
            disabled={disabled}
          />
        )}

        {value?.type === SendMessageToType.User && (
          <UserAutoComplete
            value={value.user}
            disabled={disabled}
            onChange={(v?: User | null) => {
              if (v) {
                onChange({
                  type: SendMessageToType.User,
                  user: v,
                });
              }
            }}
          />
        )}
      </Stack>
    </InputControl>
  );
}

export default SendTo;
