import React from 'react';
import { IconButton, Stack, Typography } from '@mui/joy';
import { Avatar } from '@sakari-io/sakari-components';
import { Contact } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import Helper from '../../../../../../utils/helper';

interface ContactListItemProps {
  contact: Contact;
  onClick: (contact: Contact) => any;
}

function ContactListItem({ contact, onClick }: ContactListItemProps) {
  return (
    <Stack
      key={contact.id}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={() => onClick(contact)}
      sx={{
        cursor: 'pointer',
        padding: '10px 12px',
        '&:hover': {
          backgroundColor: `var(--joy-palette-neutral-100)`,
        },
      }}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Avatar size="sm" person={contact} />
        <Typography level="body-sm">
          {Helper.getNameOrMobileNumber(contact)}
        </Typography>
      </Stack>
      <IconButton size="xs">
        <FontAwesomeIcon icon={faChevronRight} />
      </IconButton>
    </Stack>
  );
}

export default ContactListItem;
