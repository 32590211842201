import React from 'react';
import { Typography, Stack, Card } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InstalledIntegration } from '@sakari-io/sakari-typings';
import { useNavigate } from 'react-router-dom';
import CardCover from '@mui/joy/CardCover';
import { getIntegrationIcon } from '@sakari-io/sakari-components';
import StatusChip from '../../ChipContexts/StatusChip';
import SelectableCard from '../../../atoms/inputs/SelectableCard';
import config from '../../../../config';

interface InstalledIntegrationCardItemProps {
  integration: InstalledIntegration;
}

function IntegrationCardItem({
  integration,
}: InstalledIntegrationCardItemProps) {
  const navigate = useNavigate();
  const { id, sourceId, name, status = '' } = integration;
  return (
    <SelectableCard
      height={72}
      onChange={() => navigate(sourceId)}
      key={id}
      checked={false}
      color="neutral"
      variant="outlined"
      label={
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            width: '100%',
            paddingLeft: 1,
            paddingRight: 2,
          }}
        >
          <Card
            sx={{
              width: '40px',
              height: '40px',
            }}
          >
            <CardCover>
              <img
                src={getIntegrationIcon(config.stage, integration.id)}
                alt=""
                style={{ objectFit: 'contain' }}
              />
            </CardCover>
          </Card>
          <Typography sx={{ flexGrow: 100 }}>{name}</Typography>
          <StatusChip status={status} />
          <FontAwesomeIcon icon={solid('chevron-right')} />
        </Stack>
      }
    />
  );
}

export default IntegrationCardItem;
