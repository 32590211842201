import React, { useContext, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Sheet, Stack, Typography } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Loader, logger } from '@sakari-io/sakari-components';
import PageHeader from '../../../ui/organisms/headers/PageHeader';
import {
  useGetGroupQuery,
  useGetAutoResponderQuery,
  useCreateAutoResponderMutation,
  useUpdateAutoResponderMutation,
} from '../../../api';
import Container from '../../../ui/templates/Container';

import CustomHours from '../../../ui/organisms/forms/CustomHours';
import { AccountContext } from '../../../contexts/account.context';
import { useAppDispatch } from '../../../redux';
import { showToast } from '../../../redux/reducers/toast';
import timeCheck from './utils';
import FieldWrapper from '../../../utils/FieldWrapper';
import SendDuring from './SendDuring';
import Composer from '../../../ui/organisms/forms/Composer';
import TriggerMessage from './TriggerMessage';
import { daysOfWeekShort } from '../../../constants';

function AddAutoResponder() {
  const params = useParams();
  const { groupId, autoResponderId } = params;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { account } = useContext(AccountContext);
  const dispatch = useAppDispatch();

  const [createAutoResponder] = useCreateAutoResponderMutation();
  const [updateAutoResponder] = useUpdateAutoResponderMutation();

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data } = useGetGroupQuery(
    account && groupId
      ? { accountId: account.id, request: groupId }
      : skipToken,
  );

  const { data: autoResponderData, isLoading } = useGetAutoResponderQuery(
    account && autoResponderId
      ? {
          accountId: account.id,
          request: autoResponderId,
        }
      : skipToken,
  );

  const formSave = (values: any) => {
    const { message } = values.response;
    const { match } = values;
    const type = values.scheduleType?.id;

    const findAction = () => {
      if (params.autoResponderId) {
        return updateAutoResponder;
      }
      return createAutoResponder;
    };

    const findType = () => {
      if (type === 'ooo') {
        return 'OOO';
      }
      if (type === 'always') {
        return '';
      }
      return type;
    };

    const typeExists = findType() !== '';

    const newVals = {
      ...values,
      response: message,
      scheduleType: typeExists ? findType() : undefined,
      customHours: findType() === 'custom' ? values.customHours : undefined,
      group: params.groupId ? params.groupId : undefined,
      advanced: false,
      match: match || '',
    };

    findAction()(newVals)
      .unwrap()
      .then((res) => {
        dispatch(
          showToast({
            severity: res.success ? 'info' : 'error',
            message: res.success
              ? t('groupsNav.autoResponders.save.saved')
              : t('groupsNav.autoResponders.save.error'),
          }),
        );
      })
      .then(() => navigate(`/groups/${params.groupId}/autoresponders`));
  };

  const customHourCheck = (type: any, value: any): boolean => {
    if (
      type?.id === 'custom' &&
      Object.values(value.parent.customHours.times)?.every(
        (x) => x === undefined,
      )
    ) {
      logger.info('no times');
      return false;
    }
    return true;
  };

  const schema = Yup.object().shape({
    response: Yup.object()
      .shape({
        message: Yup.string(),
        files: Yup.array(),
      })
      .test(
        'response-check',
        t('groupsNav.autoResponders.customMessage.required'),
        (value) => value.message !== '',
      ),
    customHours: timeCheck(
      daysOfWeekShort,
      t('groupsNav.officeHours.validationDateRange'),
      t('groupsNav.officeHours.validationOverlaps'),
    ),
    scheduleType: Yup.object()
      .shape({
        id: Yup.string(),
      })
      .test(
        'custom-check',
        t('groupsNav.officeHours.selectHours'),
        (type, value) => customHourCheck(type, value),
      ),
  });

  const validate = makeValidate(schema);

  const emptyInitVals = {
    account: account?.id,
    match: '',
    advanced: false,
    response: {
      message: '',
      files: [],
    },
    scheduleType: { id: 'always' },
    customHours: {
      timezone: tz,
      times: {},
    },
  };

  const initialValues = useMemo(
    () =>
      ({
        ...autoResponderData?.data,
        customHours: {
          timezone:
            (autoResponderData?.data as any)?.customHours?.timezone || tz,
          times: (autoResponderData?.data as any)?.customHours?.times || {},
        },
        response: { message: autoResponderData?.data.response },
        scheduleType: {
          id: (autoResponderData?.data as any)?.scheduleType || 'always',
        },
      }) || emptyInitVals,
    [autoResponderData],
  );

  if (isLoading)
    return (
      <Box
        sx={{
          height: '100vh',
        }}
      >
        <Loader size={200} label="Loading auto responder..." />
      </Box>
    );

  return (
    <Form
      onSubmit={formSave}
      validateOnBlur
      keepDirtyOnReinitialize
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({
        handleSubmit,
        values,
        hasValidationErrors,
        submitting,
        pristine,
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            style={{
              overflow: 'hidden',
              backgroundColor: `var(--joy-palette-background-level1)`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <PageHeader
              title={data?.data ? data?.data.name : ''}
              cancelText={t('cancel')}
              ctaText={t('save')}
              ctaDisabled={hasValidationErrors || pristine}
              loading={isLoading || submitting}
              ctaAction={handleSubmit}
              backAction={() => navigate(-1)}
            />

            <Container sx={{ width: '100%', alignItems: 'center' }}>
              {isLoading ? (
                <Loader size={200} label="Loading auto responder..." />
              ) : (
                <Stack sx={{ width: 'clamp(720px, 90%, 800px)', gap: 2 }}>
                  <Typography level="h4">
                    {params.autoResponderId
                      ? t('groupsNav.autoResponders.updateAutoResponder')
                      : t('groupsNav.autoResponders.addAutoResponder')}
                  </Typography>

                  <Sheet
                    variant="outlined"
                    sx={{
                      flex: 1,
                      borderRadius: 'var(--joy-radius-md)',
                      p: 1,
                    }}
                  >
                    <FieldWrapper component={TriggerMessage} name="match" />
                    <Stack
                      spacing={2}
                      sx={{
                        borderBottom: `1px solid ${'var(--joy-palette-neutral-outlinedBorder)'}`,
                        padding: '40px',
                      }}
                    >
                      <Typography fontWeight="bold">
                        {t(
                          'groupsNav.autoResponders.customMessage.writeCustomMessage',
                        )}
                      </Typography>
                      <FieldWrapper
                        component={Composer}
                        name="response"
                        account={account}
                        disabledFeatures={['upload']}
                      />
                    </Stack>

                    <FieldWrapper component={SendDuring} name="scheduleType" />

                    {values && values.scheduleType.id === 'custom' ? (
                      <Sheet
                        sx={{
                          p: 2,
                          mx: 4,
                          mb: 4,
                          borderTop: `1px solid ${'var(--joy-palette-neutral-outlinedBorder)'}`,
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography sx={{ fontWeight: '500' }}>
                            {t(
                              'groupsNav.autoResponders.customMessage.setCustomHours',
                            )}
                          </Typography>
                        </Stack>
                        <CustomHours
                          rffName="customHours"
                          days={daysOfWeekShort}
                          officeHoursActive
                        />
                      </Sheet>
                    ) : null}
                  </Sheet>
                </Stack>
              )}
            </Container>
          </form>
        );
      }}
    />
  );
}

export default AddAutoResponder;
