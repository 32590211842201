import React from 'react';
import { Chip, ChipProps } from '@mui/joy';

const DEFAULT_COLOR = 'success';

const COLORS: { [key: string]: ChipProps['color'] } = {
  always: DEFAULT_COLOR,
  OOO: 'danger',
  custom: 'success',
  inOffice: 'primary',
};

interface ScheduleTypeChipProps {
  label: string;
}

function ScheduleTypeChip({ label }: ScheduleTypeChipProps) {
  const findLabelName = (label: string) => {
    switch (label) {
      case 'always':
        return 'Always';
      case 'OOO':
        return 'Out of office';
      case 'custom':
        return 'Custom hours';
      case 'inOffice':
        return 'In office';
      default:
        return 'Always';
    }
  };
  const color = COLORS[label] || DEFAULT_COLOR;
  return <Chip color={color}>{findLabelName(label)}</Chip>;
}

export default ScheduleTypeChip;
