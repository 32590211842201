import React from 'react';
import { Select, Option, Typography, Input, Stack } from '@mui/joy';
import { Country, countries, findCountry } from '@sakari-io/sakari-common';
import { InternationalNumber } from '@sakari-io/sakari-typings';
import { Flag, logger } from '@sakari-io/sakari-components';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PhoneNumberInputProps {
  value: InternationalNumber;
  onChange: (val: InternationalNumber) => any;
  // onBackspace: () => any;
  countryFilter?: (country: Country) => boolean;
}

function PhoneNumberInput({
  value,
  onChange,
  countryFilter = () => true,
  // onBackspace,
  // onReset,
}: PhoneNumberInputProps) {
  // useCallback((txt) => {
  //   const asYouType = new AsYouType(value.country);

  //   return asYouType.input(txt);
  // });
  // const asYouType = new AsYouType((value?.country as CountryCode) || 'US'); // .input(value?.number);
  // const [national, setNational] = useState('');

  // useEffect(() => {
  //  logger.info('useEffect');
  //   asYouType.input(value.number);
  // }, [value]);

  // const parsed = parsePhoneNumber(
  //   value?.number || '',
  //   (value.country || 'US') as CountryCode,
  // );
  // logger.info('parsed', parsed);

  return (
    <Stack direction="row">
      <Select
        variant="plain"
        value={value?.country || 'US'}
        indicator={<FontAwesomeIcon icon={faChevronDown} />}
        slotProps={{
          root: {
            sx: {
              background: 'rgba(255, 255, 255, 0.05)',
              color: 'white',
              border: '1px solid rgba(255, 255, 255, 0.20)',
              borderRadius: '55px',
              minHeight: 0,
              p: 0,
              pr: 1,
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.10)',
                color: 'white',
              },
            },
          },
          button: {
            sx: {
              pl: 0.5,
              py: 0,
            },
          },
          indicator: {
            sx: {
              color: 'rgba(255, 255, 255, 0.50)',
              transition: 'transform 0.3s',
              '&.Mui-expanded': {
                transform: 'rotate(180deg)',
              },
            },
          },
          listbox: {
            size: 'sm',
            sx: {
              background: 'rgb(25, 46, 84)',
              border: '1px solid rgba(255, 255, 255, 0.20)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              '&:hover::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.25)',
              },
              '.MuiOption-root': {
                color: 'white',

                '&.Mui-selected, &.MuiOption-highlighted': {
                  background: 'rgba(255, 255, 255, 0.10)',
                  '&:hover': {
                    background: 'rgba(255, 255, 255, 0.15)',
                  },
                },
                '&:not(.Mui-selected):hover': {
                  color: 'white',
                  background: 'rgba(255, 255, 255, 0.05)',
                },
              },
            },
          },
        }}
        onChange={(evt, country) =>
          onChange({ number: value.number, country: country || 'US' })
        }
        renderValue={(country) =>
          country ? <Flag rounded country={findCountry(country.value)} /> : null
        }
      >
        {countries.filter(countryFilter).map((country) => (
          <Option key={country.code} value={country.code}>
            <Flag country={country} /> {country.name}{' '}
            <Typography
              level="body-xs"
              style={{
                color: 'rgba(255, 255, 255, 0.50)',
              }}
            >
              +{country.dialing}
            </Typography>
          </Option>
        ))}
      </Select>
      <Input
        variant="outlined"
        placeholder="Enter Phone Number"
        sx={{
          '--Input-focusedThickness': 0,
          // '--Root-backgroundColor': 'yellow',
          // '--Input-color': 'green',
          backgroundColor: 'transparent',
          color: 'white',
          borderWidth: 0,
          borderStyle: 'none',
          '&:hover': {
            color: 'white',
          },
          // '&:hover': {
          //   borderWidth: 0,
          //   borderStyle: 'none',
          // },
          '&:focus, &.Mui-focused': {
            boxShadow: 'none',
            borderWidth: 0,
            borderStyle: 'none',
          },
          // '--Input-focusedHighlight': 'transparent',
        }}
        slotProps={{
          input: {
            // cursor: 'none',
            // caretColor: 'transparent',
            backgroundColor: 'red',
            color: 'green',
            focusedThickness: 0,
            borderWidth: 0,
            borderStyle: 'none',
          },
          root: {},
        }}
        value={value?.number}
        // onKeyUp={(evt) => {
        //   logger.info('key', evt.key);
        //   logger.info('value', value);

        //   onChange({
        //     country: value.country,
        //     number: asYouType.input(evt.key),
        //   });
        // }}
        onChange={(evt) => {
          logger.info('evet', evt.target.value);
          // logger.info('a2', val);
          // logger.info('res', asYouType.input(evt.target.value));

          // const aut = new AsYouType((value?.country || 'US') as CountryCode);
          // aut.input(evt.target.value);

          // setNational(asYouType.input(evt.target.value));
          onChange({
            country: value.country,
            // number: asYouType.getNumberValue(),
            // number: aut.getNumberValue(),
            number: evt.target.value,
            // number: parsed?.formatInternational() || evt.target.value,
          });

          // logger.info('nat', asYouType.getNumberValue());
        }}
      />
      {/* <Typography>AA:{asYouType.getNumberValue()}</Typography> */}
    </Stack>
  );
}

export default PhoneNumberInput;
