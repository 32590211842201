import {
  ListItem,
  ListItemButton,
  ListItemDecorator,
  ListItemContent,
} from '@mui/joy';
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@sakari-io/sakari-components';
import { NavigationItem } from '../../../../../../utils/navigation';

interface NavBarItemProps extends Partial<NavigationItem> {
  forwardedRef?: React.Ref<HTMLDivElement>;
  expanded?: boolean;
  onClick?: (e: any) => void;
  children?: React.ReactNode;
  url?: string;
  isActive?: boolean;
  nested?: boolean;
  isSubItem?: boolean;
  skipNav?: boolean;
}

function StyledNavBarItem({
  forwardedRef,
  expanded,
  url,
  label,
  isActive,
  icon,
  onClick,
  nested,
  isSubItem,
  skipNav,
  children,
}: NavBarItemProps) {
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    if (onClick) {
      onClick(e);
      if (skipNav) return;
    }

    if (url) {
      if (url.startsWith('http')) {
        window.open(url);
      } else {
        navigate(url);
      }
    }
  };

  return (
    <ListItem
      nested={nested}
      sx={{
        ...(isSubItem && {
          ...(expanded
            ? {
                borderLeft: '4px solid transparent',
                pl: 1.5,
                mx: 0,
                '.MuiListItemDecorator-root': {
                  opacity: 0,
                },
                ...(isActive && {
                  borderColor: 'var(--joy-palette-primary-500)',
                  borderRadius: '0px',
                }),
              }
            : {
                justifyContent: 'center',
              }),
        }),
        ...(nested && {
          pb: 0,
        }),
      }}
    >
      <ListItemButton
        variant="plain"
        ref={forwardedRef}
        sx={{
          height: '48px',
          borderRadius: '4px',
          backgroundColor: 'initial',
          color: 'text.tertiary',

          '& > * > svg': {
            color: 'text.tertiary',
            width: 'calc(var(--ListItemDecorator-size) / 2)',
          },

          '&:hover': {
            color: 'text.primary',
            backgroundColor: 'background.level1',
            '& > * > svg': {
              color: 'text.primary',
            },
          },

          '&:active, &:active:active': {
            boxShadow: 'none',
            backgroundColor: 'background.level2',
            color: 'primary.500',
            '& > * > svg': {
              color: 'primary.500',
            },
          },
          '.MuiListItemDecorator-root': {
            justifyContent: 'center',
            ml: '-9px',
            mr: 0,
          },

          ...(!expanded && {
            maxWidth: '48px',
            justifyContent: 'center',
            '.MuiListItemDecorator-root': {
              flex: '0 0 auto',
              justifyContent: 'center',
              mr: 0,
            },
          }),

          ...(isActive && {
            backgroundColor: 'background.level2',
            ...(nested && {
              backgroundColor: 'initial',
            }),
            color: 'primary.500',
            '& > * > svg': {
              color: 'primary.500',
            },
            '&:hover': {
              color: 'primary.500',
            },
          }),
          ...(isSubItem && {
            height: '36px',
            ...(!expanded && {
              maxWidth: '36px',
            }),
          }),
        }}
        onClick={handleClick}
      >
        {icon && (
          <ListItemDecorator>
            {isActive && !!icon?.active ? icon?.active : icon?.default}
          </ListItemDecorator>
        )}
        <ListItemContent
          sx={{
            color: 'inherit',
            ...(isActive && {
              color: 'primary.500',
            }),
            ...(expanded
              ? {
                  display: 'inline-block',
                }
              : {
                  display: 'none',
                }),
            whiteSpace: 'nowrap',
            ...(isSubItem &&
              expanded && {
                ml: 2,
              }),
          }}
        >
          {label}
        </ListItemContent>
      </ListItemButton>
      {children}
    </ListItem>
  );
}

const NavBarItem = forwardRef<HTMLDivElement, NavBarItemProps>((props, ref) => {
  const FwdNavItem = <StyledNavBarItem {...props} forwardedRef={ref} />;

  if (props.expanded) return FwdNavItem;
  return (
    <Tooltip title={props.label} placement="right" size="sm">
      {FwdNavItem}
    </Tooltip>
  );
});

export default NavBarItem;
