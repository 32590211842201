import React from 'react';

interface ConcentricCirclesProps {
  maxRadius: number;
  minRadius?: number;
  spaceBetweenCircles: number;
  color: string;
  maxOpacity?: number;
}

function ConcentricCircles({
  maxRadius,
  spaceBetweenCircles,
  color,
  maxOpacity = 0.2,
  minRadius = 0,
}: ConcentricCirclesProps) {
  const numberOfCircles = Math.floor(maxRadius / spaceBetweenCircles);
  const circles = [];
  for (let i = 0; i < numberOfCircles; i++) {
    const radius =
      maxRadius - ((maxRadius - minRadius) / (numberOfCircles - 1)) * i;
    const opacity = maxOpacity * (i / numberOfCircles) ** 3;

    circles.push(
      <circle
        key={i}
        cx="50%"
        cy="50%"
        r={`${radius}`}
        fill="none"
        stroke={`rgba(${color} / ${opacity})`}
        strokeWidth="1px"
      />,
    );
  }

  return (
    <svg width="100%" height="100%">
      {circles}
    </svg>
  );
}

export default ConcentricCircles;
