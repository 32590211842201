import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/joy';
import { CallDirection, CallStatus, User } from '@sakari-io/sakari-typings';
import IncomingCall from './IncomingCall';
import ActiveCall from './ActiveCall';
import Calling from './Calling';
import { ActiveCall as ActiveCallType } from '../../../types';

export interface CallBarProps {
  call?: ActiveCallType;
  profile?: User;
}

function CallBar({ call, profile }: CallBarProps) {
  const { t } = useTranslation('voice');

  const [isVisible, setIsVisible] = useState(true);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const renderCallWidget = () => {
    if (!call) {
      return null;
    }

    if (
      call.status === CallStatus.Ringing &&
      call.direction === CallDirection.Outbound
    ) {
      return <Calling call={call} />;
    }

    if (call.status === CallStatus.Active) {
      return <ActiveCall call={call} />;
    }

    if (
      call.status === CallStatus.Transferring &&
      call.user?.id === profile?.id
    ) {
      return <ActiveCall call={call} />;
    }

    if ([CallStatus.Ringing, CallStatus.Transferring].includes(call.status)) {
      return <IncomingCall call={call} />;
    }

    return null;
  };

  if (
    !call ||
    [
      CallStatus.Missed,
      CallStatus.Ended,
      CallStatus.Forwarded,
      CallStatus.Rejected,
    ].includes(call.status)
  ) {
    return null;
  }

  // if (
  //   call.status === CallStatus.Ringing &&
  //   call.direction === CallDirection.Outbound
  // ) {
  //   return null;
  // }
  if (
    CallStatus.Transferring !== call.status &&
    call.user &&
    call.user.id !== profile?.id
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        display: call ? 'block' : 'none',
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: isVisible ? 30 : -98,
        width: 'clamp(700px, 90vw, 992px)',
        zIndex: 'calc(var(--joy-zIndex-drawer) + 1)',
        transition: 'bottom 0.3s',
        opacity: '0.95',
        backdropFilter: 'blur(5px)',
      }}
    >
      <Box
        onClick={handleToggle}
        sx={{
          width: 172,
          height: 28,
          backgroundColor: 'var(--joy-palette-voice-solidBg)',
          borderRadius: '10px 10px 0 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          zIndex: 1001,
          marginLeft: 'calc(50% - 64.5px)',
          position: 'absolute',
          bottom: 102,
          borderBottom: `1px solid ${'var(--joy-palette-voice-softHoverBg)'}}`,
        }}
      >
        <Typography
          level="body-md"
          sx={{ color: 'var(--joy-palette-success-500)', fontWeight: 'bold' }}
        >
          {isVisible ? t('hideCall') : t('viewCall')}
        </Typography>
      </Box>
      <Box
        sx={{
          height: 102,
          backgroundColor: 'var(--joy-palette-voice-solidBg)',
          borderRadius: 8,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {renderCallWidget()}
      </Box>
    </Box>
  );
}
// (![CallStatus.Ringing, CallStatus.Transferring].includes(call.status)) &&
export default CallBar;
