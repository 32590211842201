import React, { useState } from 'react';
import _ from 'lodash';
import { SupportedDestinations } from '@sakari-io/sakari-typings';
import { findCountry } from '@sakari-io/sakari-common';
import { Stack } from '@mui/joy';
import Accordion from '../../../templates/Accordion';
import CountryChip from '../CountryChip';
import useVisibleItems from '../../../../hooks/useVisibleItems';

interface CountryChipsProps {
  value: SupportedDestinations;
  onChange: (countries: SupportedDestinations) => any;
  type?: string;
}

function CountryChips({ value, onChange, type }: CountryChipsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const allItems = _.keys(value || {}).length;

  const { containerRef, visibleItems } = useVisibleItems(60);

  const sharedStyle = {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    gap: 0.5,
  };

  const handleChips = (slice1: number, slice2?: number) => {
    const sorted = _.orderBy(_.keys(value || {}), [
      (code) => !value[code], // Sort by selected first
      (code) => code, // Then sort by country code
    ]);

    return sorted.slice(slice1, slice2).map((code: string) => {
      const country = findCountry(code);
      if (type === 'shortcode' && !value[code]) {
        return null;
      }
      return country ? (
        <CountryChip
          key={code}
          country={country}
          color="primary"
          selected={value[code]}
          onToggle={(selected) =>
            onChange(_.assign({}, value, { [code]: selected }))
          }
        />
      ) : null;
    });
  };

  return (
    <Accordion
      id="country-chips"
      noSpacer
      hideToggle={allItems <= visibleItems}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      action={() => setIsOpen(!isOpen)}
      sx={{
        '#accordion-header-country-chips': {
          ...sharedStyle,
        },
      }}
      header={handleChips(0, Math.min(visibleItems, allItems))}
      content={
        allItems <= visibleItems ? null : (
          <Stack
            ref={containerRef}
            sx={{
              ...sharedStyle,
              pr: 15,
            }}
          >
            {handleChips(visibleItems)}
          </Stack>
        )
      }
    />
  );
}

export default CountryChips;
