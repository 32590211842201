import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Sheet, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import Partners, { PartnersViewMode } from '.';
import { AccountContext } from '../../contexts/account.context';
import OverlayPopup from '../../ui/templates/OverlayPopup';
import Radios from '../../ui/molecules/Radios';
import LeadsGrid from '../../ui/organisms/datagrids/LeadsGrid';

const FILTER_OPTIONS = [
  {
    label: 'All Leads',
    value: 'all',
    tooltip: '',
  },
  {
    label: 'Only Expired Leads',
    value: 'expired',
    tooltip: '',
  },
  {
    label: 'Only Active Leads',
    value: 'active',
    tooltip: '',
  },
];

function Leads() {
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);

  const navigateToAdd = () => {
    navigate('/partners/leads/add');
  };

  const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(undefined);
  const [leadStatusFilter, setLeadStatusFilter] = useState<any>('all');

  useEffect(() => {
    switch (leadStatusFilter) {
      case 'expired': {
        setFilters({ status: 'expired' });
        break;
      }
      case 'active': {
        setFilters({ status: 'active' });
        break;
      }
      default: {
        setFilters(undefined);
        break;
      }
    }
  }, [leadStatusFilter]);

  if (!account) {
    return null;
  }

  return (
    <Partners
      view={PartnersViewMode.LEADS}
      title="Leads"
      action={navigateToAdd}
      actionText="Create Lead"
    >
      <Stack direction="row" gap={2} pb={2} alignItems="right">
        <OverlayPopup
          open={openFilterMenu}
          onOpenClose={setOpenFilterMenu}
          placement="bottom-end"
          renderButton={(setAnchorEl, onClick) => (
            <IconButton
              variant="outlined"
              sx={{ transform: 'scale(0.8)' }}
              onClick={onClick}
              ref={setAnchorEl}
            >
              <FontAwesomeIcon icon={regular('bars-filter')} />
            </IconButton>
          )}
          renderPopup={() => (
            <Sheet sx={{ padding: 2, borderRadius: 2 }}>
              <Radios
                size="sm"
                value={leadStatusFilter}
                items={FILTER_OPTIONS}
                onChange={(e) => {
                  setLeadStatusFilter(e);
                }}
              />
            </Sheet>
          )}
        />
      </Stack>
      {account && (
        <LeadsGrid
          accountId={account.id}
          filter={filters || {}}
          onSelectedRowChange={(row: any) =>
            navigate(`/partners/leads/${row.id}`)
          }
        />
      )}
    </Partners>
  );
}

export default Leads;
