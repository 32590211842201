import React from 'react';
import { Sort } from '../../../../types';
import DropdownMenu from '../../../molecules/Menu/DropdownMenu';

interface SortByProps {
  options: Sort[];
  value: Sort;
  onChange: (sort: Sort) => void;
}

function SortBy({ options, value, onChange }: SortByProps) {
  const handleChange = (sort: Sort) => {
    onChange(sort);
  };

  return (
    <DropdownMenu
      button={`Sort By${value ? `: ${value.label}` : ''}`}
      onSelect={(e, item) => handleChange(item as Sort)}
      items={options}
      size="md"
    />
  );
}

export default SortBy;
