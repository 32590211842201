import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Auth0Template from '../../ui/templates/Auth0Template';

function AuthResult() {
  const [searchParams] = useSearchParams();

  return <Auth0Template>{searchParams.get('error')}</Auth0Template>;
}

export default AuthResult;
