import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import WorkflowsGrid from './WorkflowsGrid';
import PageHeader from '../../../ui/organisms/headers/PageHeader';
import Container from '../../../ui/templates/Container';
import { AccountContext } from '../../../contexts/account.context';
import SearchableDataGrid from '../../../ui/organisms/datagrids/SearchableDataGrid';
import CreateWorkflowDialog from '../Add/CreateWorkflowDialog';

function Workflows() {
  const { account } = useContext(AccountContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
  const { t } = useTranslation('workflows');
  const navigate = useNavigate();

  const [openCreateWorkflowDialog, setOpenCreateWorkflowDialog] =
    useState(false);

  return (
    <>
      <PageHeader
        title={t('title')}
        ctaText={t('create.newWorkflow')}
        ctaAction={() => setOpenCreateWorkflowDialog(true)}
      />

      <Container>
        <SearchableDataGrid
          value={searchTerm}
          onChange={setSearchTerm}
          renderDataGrid={() => (
            <WorkflowsGrid
              filter={{
                accountId: account?.id,
                request: { orderBy: 'updated-desc', q: debouncedSearchTerm },
              }}
              onSelectedRowChange={(workflow) => navigate(`${workflow.id}`)} ///${getLatestVersionId(workflow)}
            />
          )}
        />
      </Container>

      <CreateWorkflowDialog
        open={openCreateWorkflowDialog}
        onClose={() => setOpenCreateWorkflowDialog(false)}
      />
    </>
  );
}

export default Workflows;
