import {
  SakariAPIResponse,
  SearchablePaginationRequest,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { buildGridQuery } from './common';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getErrors: builder.query<
      SakariAPIResponse<Error[]>,
      SearchablePaginationRequest
    >({
      query: (request) => `errorcodes?${buildGridQuery(request)}`,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetErrorsQuery } = extendedApi;
