import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Condition } from '@sakari-io/sakari-typings';

import Chip from '@mui/joy/Chip';
import React, { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ConditionChip from './ConditionChip';
import { useGetAttributesByTypeQuery } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import ConditionCard from './ConditionCard';

interface ConditionsProps {
  value: Condition[];
  onChange: (conditions: Condition[]) => any;
  disabled?: boolean;
}

function Conditions({ value, onChange, disabled }: ConditionsProps) {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);

  // TODO: handle how to retrieve data type from attribute
  const { data: attributes } = useGetAttributesByTypeQuery(
    account
      ? {
          accountId: account.id,
          request: {
            type: 'contacts',
            limit: 100,
          },
        }
      : skipToken,
  );

  const addCondition = () =>
    onChange([...value, { attribute: '', comparator: '', value: [''] }]);

  const onDeleteCondition = (val: Condition) => {
    onChange(
      value.filter((c: Condition) => {
        return c !== val;
      }),
    );
  };

  return (
    <Stack gap={1}>
      {value.length ? (
        <>
          {value.map((condition: Condition, idx: number) => {
            return (
              <ConditionCard
                key={idx}
                attributes={attributes?.data || []}
                onChange={(newCondition: Condition) =>
                  onChange(
                    value.map((c: Condition, i: number) =>
                      i === idx ? newCondition : c,
                    ),
                  )
                }
                value={condition}
                onDelete={() => onDeleteCondition(condition)}
                disabled={disabled}
              />
            );
          })}
          <ConditionChip
            variant="soft"
            color="primary"
            onClick={() => addCondition()}
            type="and"
            action="add"
          />
        </>
      ) : (
        <Chip color="primary" onClick={() => addCondition()} size="lg">
          <Typography
            startDecorator={<FontAwesomeIcon icon={faPlus} />}
            textColor="inherit"
            fontWeight={700}
          >
            {t('addFilter')}
          </Typography>
        </Chip>
      )}
    </Stack>
  );
}

export default Conditions;
