import qs from 'query-string';
import _ from 'lodash';
import { logger } from '@sakari-io/sakari-components';

const DEFAULT_PAGINATION = { offset: 0, limit: 10 };

const getAccountIdLive = () => localStorage.getItem('accountId') || undefined;

const getAccountStorybook = () => {
  logger.info('getAccountStoryBook');
  return { id: '5c38f42dc6db6d32f905aa0a' };
};

const getAccountIdStorybook = () => {
  logger.info('getAccountIdStoryBook');
  return getAccountStorybook()?.id;
};

// const getAccount =
//   window.location.port !== '6006' // process.env.REACT_APP_API_URL
//     ? getAccountLive
//     : getAccountStorybook;

const getAccountId =
  window.location.port !== '6006' // process.env.REACT_APP_API_URL
    ? getAccountIdLive
    : getAccountIdStorybook;

const buildQuery = (params: any) => {
  return qs.stringify(params);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const buildGridQuery = (params: any, valueMapper = (v: any, k: any) => v) => {
  return qs.stringify(
    _.mapValues(
      _.pickBy(params, (p) => p !== undefined),
      valueMapper,
    ),
  );
};

export {
  DEFAULT_PAGINATION,
  getAccountId,
  // getAccount,
  buildQuery,
  buildGridQuery,
};
