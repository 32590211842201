import React, { useState, useEffect } from 'react';
import Popper, { AppPopperProps } from '../Popper';

export type OverlayPopupProps = Omit<AppPopperProps, 'anchorEl'> & {
  renderPopup: () => React.ReactNode;
  renderButton?: (
    setAnchorEl: (anchorEl: HTMLElement | null) => void,
    onClick: (open: any) => void,
  ) => React.ReactNode;
  sx?: any;
  buttonRef?: any;
  fullWidth?: boolean;
};

// Create an Overlay Menu component that takes in a list of menu items
// and renders them in a menu that overlays the screen.

function OverlayPopup({
  open,
  onOpenClose,
  renderButton,
  renderPopup,
  sx,
  buttonRef,
  fullWidth,
  ...popperProps
}: OverlayPopupProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [inputWidth, setInputWidth] = useState(0);

  const popperStyle = {
    zIndex: 'calc(var(--joy-zIndex-modal) + 1)',
    width: fullWidth ? inputWidth : 'auto',
    ...sx,
  };

  useEffect(() => {
    if (buttonRef?.current) {
      setAnchorEl(buttonRef.current);
      setInputWidth(buttonRef.current.clientWidth);
    }
  }, [buttonRef]);

  const shouldRenderPopper = buttonRef ? !!anchorEl : true;

  return (
    <>
      {renderButton && renderButton(setAnchorEl, () => onOpenClose(!open))}

      {shouldRenderPopper && (
        <Popper
          anchorEl={anchorEl}
          style={popperStyle}
          open={open}
          onOpenClose={onOpenClose}
          {...popperProps}
        >
          {renderPopup()}
        </Popper>
      )}
    </>
  );
}
export default OverlayPopup;
