import {
  Campaign,
  CampaignExecution,
  ExecutionReport,
  ExecuteCampaignPreview,
  SakariAPIResponse,
  Updated,
  SearchablePaginationRequest,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { getAccountId, buildQuery } from './common';
import {
  CAMPAIGN_EXECUTION_TAG,
  CAMPAIGN_TAG,
  EXECUTION_REPORT_PREVIEW_TAG,
  EXECUTION_REPORT_TAG,
} from './tags';
import { AccountIdWith } from '../types';

interface ExecuteCampaignResponse {
  id: string;
  estimatedPrice: number;
  submitted: number;
  status: string;
  price: number;
  invalid: any[];
  created: Updated;
  updated: Updated;
}

interface FetchCampaignsRequest extends SearchablePaginationRequest {
  inactive: boolean;
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    createCampaign: builder.mutation<
      SakariAPIResponse<Campaign>,
      Partial<Campaign>
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/campaigns`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [CAMPAIGN_TAG],
    }),
    updateCampaign: builder.mutation<
      SakariAPIResponse<Campaign>,
      Partial<Campaign>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/campaigns/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [CAMPAIGN_TAG],
    }),

    getCampaigns: builder.query<
      SakariAPIResponse<Campaign[]>,
      AccountIdWith<FetchCampaignsRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/campaigns?${buildQuery(request)}`,
      providesTags: [CAMPAIGN_TAG],
    }),
    deleteCampaign: builder.mutation<
      Campaign,
      Partial<Campaign> & Pick<Campaign, 'id'>
    >({
      query: ({ id }) => ({
        url: `accounts/${getAccountId()}/campaigns/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CAMPAIGN_TAG],
    }),
    pauseCampaign: builder.mutation<
      Campaign,
      Partial<Campaign> & Pick<Campaign, 'id'>
    >({
      query: ({ id }) => ({
        url: `accounts/${getAccountId()}/campaigns/${id}/pause`,
        method: 'POST',
      }),
      // invalidatesTags: [CAMPAIGN_TAG],
    }),
    resumeCampaign: builder.mutation<
      Campaign,
      Partial<Campaign> & Pick<Campaign, 'id'>
    >({
      query: ({ id }) => ({
        url: `accounts/${getAccountId()}/campaigns/${id}/resume`,
        method: 'POST',
      }),
      // invalidatesTags: [CAMPAIGN_TAG],
    }),
    getCampaignExecutions: builder.query<
      SakariAPIResponse<CampaignExecution[]>,
      AccountIdWith<SearchablePaginationRequest & { campaignId: string }>
    >({
      query: ({ accountId, request: { campaignId, ...other } }) =>
        `accounts/${accountId}/campaigns/${campaignId}/jobs?${buildQuery(
          other,
        )}`,
      providesTags: (result, error, { request: { campaignId } }) => {
        return [`${CAMPAIGN_EXECUTION_TAG}-${campaignId}`];
      },
    }),
    getCampaignExecutionChart: builder.query<
      SakariAPIResponse<ExecutionReport>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: jobId }) =>
        `accounts/${accountId}/reports/job/${jobId}`,
      providesTags: [EXECUTION_REPORT_TAG],
    }),
    getExecuteCampaignPreview: builder.query<
      SakariAPIResponse<ExecuteCampaignPreview>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: campaignId }) =>
        `accounts/${accountId}/campaigns/${campaignId}/preview`,
      providesTags: [EXECUTION_REPORT_PREVIEW_TAG],
    }),
    executeCampaign: builder.mutation<
      SakariAPIResponse<ExecuteCampaignResponse>,
      any
    >({
      query: ({ id }) => ({
        url: `accounts/${getAccountId()}/campaigns/${id}/execute`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { id: campaignId }) => {
        return [`${CAMPAIGN_EXECUTION_TAG}-${campaignId}`];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useGetCampaignsQuery,
  useDeleteCampaignMutation,
  usePauseCampaignMutation,
  useResumeCampaignMutation,
  useGetCampaignExecutionsQuery,
  useGetCampaignExecutionChartQuery,
  useGetExecuteCampaignPreviewQuery,
  useExecuteCampaignMutation,
} = extendedApi;
