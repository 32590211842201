import React from 'react';
import Chip from '@mui/joy/Chip';

const COLORS: { [key: string]: any } = {
  SMS: 'info',
  MMS: 'warning',
  VOICE: 'primary',
};

interface FeatureChipProps {
  label: string;
}

function FeatureChip({ label }: FeatureChipProps) {
  return (
    <Chip variant="soft" color={COLORS[label.toLocaleUpperCase()]}>
      {label}
    </Chip>
  );
}

export default FeatureChip;
