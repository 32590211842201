import { createSlice } from '@reduxjs/toolkit';
import { ConversationFilter } from '../../types';
import { helperInst } from '../../utils/helper';

const initialState: {
  conversationFilter: ConversationFilter | undefined;
  // conversationUploadedFiles: Array<IFileUpload>;
} = {
  conversationFilter: helperInst.getConversationFilter(),
  // conversationUploadedFiles: new Array<IFileUpload>(),
};

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setAppliedFilter: (state, action) => {
      helperInst.setConversationFilter(action.payload);
      state.conversationFilter = action.payload;
    },
    // setFileSelected: (state, action) => {
    //   state.conversationUploadedFiles.push(action.payload);
    // },
    // setFileUploaded: (state, action) => {
    //   const { id, isUploaded, url } = action.payload;
    //   const files = state.conversationUploadedFiles;
    //   files
    //     .filter((file: IFileUpload) => {
    //       return file.id === id;
    //     })
    //     .forEach((file: IFileUpload) => {
    //       file.isUploaded = isUploaded;
    //       file.url = url;
    //     });
    //   state.conversationUploadedFiles = files;
    // },
    // removeSelectedFile: (state, action) => {
    //   state.conversationUploadedFiles = state.conversationUploadedFiles.filter(
    //     (file: IFileUpload) => file.id !== action.payload
    //   );
    // },
    // getUploadedFiles: (state, action) => {
    //   state.conversationUploadedFiles = state.conversationUploadedFiles.filter(
    //     (file: IFileUpload) => {
    //       return file.id === action.payload;
    //     }
    //   );
    // },
  },
});

export const {
  setAppliedFilter,
  // setFileSelected,
  // removeSelectedFile,
  // getUploadedFiles,
  // setFileUploaded,
} = conversationSlice.actions;

export default conversationSlice;
