import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { NumberItem } from '.';

export interface DialpadButtonProps {
  item: NumberItem;
  onClick: (value: string) => any;
}

function DialpadButton({ item, onClick }: DialpadButtonProps) {
  return (
    <Stack
      key={item.value}
      onClick={() => onClick(item.value)}
      onDoubleClick={() => (item.altValue ? onClick(item.altValue) : undefined)}
      sx={{
        width: '52.8px',
        height: '52.8px',
        borderRadius: '50%',
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%)',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
          content: '""',
          width: '52.8px',
          height: '52.8px',
          position: 'absolute',
          borderRadius: '50%',
          border: '1px solid ',
          borderColor: 'rgb(255, 255, 255)',
          opacity: 0.1,
        },
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)',

        '&:hover': {
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)',
        },
        '&:active': {
          opacity: 0.9,
          boxShadow: 'none',
          // boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Typography textColor="white" fontWeight={600} m={-0.5}>
        {item.value}
      </Typography>
      <Typography
        level="body-xs"
        textColor="white"
        sx={{
          opacity: 0.5,
        }}
      >
        {item.letters}
      </Typography>
    </Stack>
  );
}

export default DialpadButton;
