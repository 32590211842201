import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Table, Typography } from '@mui/joy';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import TimeRange from './TimeRange';

interface CustomHoursFieldArrayProps {
  fieldName: string;
  value: any;
  mutators: any;
  showAdd: boolean;
}

function CustomHoursFieldArray({
  fieldName,
  value,
  mutators,
  showAdd,
}: CustomHoursFieldArrayProps) {
  useEffect(() => {
    if (!value) handleAddField();
  }, []);

  useEffect(() => {
    if (!showAdd) {
      mutators.update(fieldName, undefined);
    }
  }, []);

  const handleAddField = () => {
    const defaultHours = {
      start: {
        hour: 9,
        minute: 0,
      },
      end: {
        hour: 17,
        minute: 0,
      },
    };
    mutators.push(fieldName, defaultHours);
  };

  const handleDeleteField = (index: number) => {
    mutators.remove(fieldName, index);
  };

  return (
    <FieldArray name={fieldName}>
      {({ fields, meta }) => (
        <Table
          noWrap
          borderAxis="none"
          width="0"
          sx={{
            td: { overflow: 'visible' },
          }}
        >
          <tbody>
            {fields.map((fName, index) => (
              <tr key={index}>
                <td width="380px">
                  <FieldWrapper id={fName} component={TimeRange} name={fName} />
                </td>
                <td>
                  <IconButton
                    size="smRound"
                    color="neutral"
                    variant="plain"
                    onClick={() => handleDeleteField(index)}
                  >
                    <FontAwesomeIcon icon={regular('trash')} />
                  </IconButton>
                </td>
                <td align="right">
                  {index === (fields?.length || 0) - 1 && (
                    <IconButton
                      size="smRound"
                      color="neutral"
                      variant="plain"
                      onClick={() => handleAddField()}
                    >
                      <FontAwesomeIcon
                        icon={regular('plus')}
                        style={{
                          cursor: 'pointer',
                          fontSize: '17px',
                        }}
                      />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))}
            {meta.error
              ? _.uniq(meta.error.flat()).map((error: any, i) => (
                  <tr>
                    <td colSpan={3}>
                      <Typography key={i} level="body-md" color="danger">
                        {error}
                      </Typography>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      )}
    </FieldArray>
  );
}
export default CustomHoursFieldArray;
