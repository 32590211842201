import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';

import { Stack, Button } from '@mui/joy';

import { logger } from '@sakari-io/sakari-components';
import { useAddUsersMutation } from '../../../../api';

import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import FieldWrapper from '../../../../utils/FieldWrapper';
import RoleSelect from '../RoleSelect';
import TextField from '../../../atoms/inputs/TextField';

const DEFAULT_ROLE = 'Administrator';

function InviteUsers() {
  const [addUsers] = useAddUsersMutation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: any) => {
    logger.info('adding users', JSON.stringify(values));
    try {
      const users = values.email
        .split(',')
        .filter((e: string) => e)
        .map((email: string) => ({ email, role: values.role }));
      const res = await addUsers(users).unwrap();
      logger.info('added users', res);
      const { added = 0, failed = [] } = res.data || {};

      dispatch(
        showToast({
          severity: 'info',
          message: t('users.invite.result', { added, failed: failed.length }),
        }),
      );
    } catch (err) {
      dispatch(
        showToast({
          severity: 'error',
          message: t('users.invite.error'),
        }),
      );
    }
  };

  // const formatInput = (fields: FieldsArr, index: number) => {
  //   const previous = fields.value[index];
  //   const emails = previous?.email
  //     ?.split(',')
  //     .map((email: string) => email.trim())
  //     .filter((email: string) => email.length > 0);

  //   (emails || []).forEach((email: string, idx: number) => {
  //     if (idx === 0) {
  //       fields.update(index, _.assign({}, previous, { email }));
  //     } else {
  //       fields.push(_.assign({}, previous, { email }));
  //     }
  //   });
  // };

  // const handleKeyUp =
  //   (fields: FieldsArr, index: number) =>
  //   (event: React.KeyboardEvent<HTMLDivElement>) => {
  //     if (event.key === 'Enter') {
  //       formatInput(fields, index);
  //     }
  //   };

  // const handleBlur =
  //   (
  //     fields: FieldsArr,
  //     index: number,
  //     submit: () => Promise<AnyObject | undefined> | undefined
  //   ) =>
  //   (event: React.FocusEvent<HTMLDivElement>) => {
  //     formatInput(fields, index);
  //     if (event?.relatedTarget?.id === 'sendInvite') {
  //       setTimeout(submit, 0);
  //     }
  //   };

  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('users.invite.validate.email'))
      .required(t('users.invite.validate.email')),
    role: Yup.string().required(t('users.invite.validate.role')),
  });

  const schema = Yup.object().shape({
    users: Yup.array().of(userSchema).min(1),
  });

  const validate = makeValidate(schema);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur
      keepDirtyOnReinitialize
      initialValues={{
        email: '',
        role: DEFAULT_ROLE,
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <Stack direction="row" alignItems="center" width="100%" spacing={1}>
          <FieldWrapper
            component={TextField}
            name="email"
            placeholder={t('emailLowerCase')}
            // onKeyUp={onKeyUp}
            // onBlur={onBlur}
            fullWidth
          />
          <FieldWrapper
            component={RoleSelect}
            name="role"
            sx={{ maxWidth: 280 }}
          />
          <Button
            id="sendInvite"
            color="primary"
            disabled={pristine || submitting}
            onClick={handleSubmit}
          >
            {t('users.invite.sendInvite')}
          </Button>
        </Stack>
      )}
    />
  );
}

export default InviteUsers;
