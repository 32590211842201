import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { WorkflowNodeType } from '@sakari-io/sakari-typings';
import { Stack } from '@mui/joy';
import Drawer from '../../../../ui/templates/Drawer';
import { useGetWorkflowNodeTypesQuery } from '../../../../api';
import SearchBar from '../../../../ui/molecules/inputs/SearchBar';
import ElementGroup from '../ElementGroup';
import EmptyResult from '../../../../ui/molecules/EmptyResult';

interface AddElementDrawerProps {
  open: boolean;
  onClose: (nt?: WorkflowNodeType) => void;
}

function AddElementDrawer({ open, onClose }: AddElementDrawerProps) {
  const { data, isFetching } = useGetWorkflowNodeTypesQuery({ limit: 100 }); // TODO remove
  const { t } = useTranslation('workflows');
  const [searchKey, setSearchKey] = useState<string>('');
  const [nodeTypesByType, setNodeTypesByType] = useState<{
    [key: string]: WorkflowNodeType[];
  }>({});

  const filterNodeType = (search: string) => (nt: WorkflowNodeType) => {
    const searchLC = (search || '').toLowerCase();
    return (
      (nt.label || '').toLowerCase().indexOf(searchLC) >= 0 ||
      (nt.description || '').toLowerCase().indexOf(searchLC) >= 0
    );
  };

  useEffect(() => {
    setNodeTypesByType(
      _.groupBy(data?.data?.filter(filterNodeType(searchKey)), (nt) => nt.type),
    );
  }, [data, searchKey]);

  return (
    <Drawer
      isOpen={open}
      position="right"
      variant="outlined"
      sx={{
        width: 'clamp(350px, 100%, 370px) !important',
        height: 'calc(100vh - 50px)',
        // padding: '16px 0px',
      }}
    >
      <Stack p={2} sx={{ height: 'calc(100vh - 50px)' }}>
        <SearchBar
          value={searchKey}
          onChange={(e) => setSearchKey(e)}
          placeholder={t('workflow.searchElements')}
        />

        {!data?.data?.length && isFetching ? (
          <Stack p={5}>
            <EmptyResult size="sm" heading={t('elements.noResults')} />
          </Stack>
        ) : (
          <Stack
            spacing={2}
            sx={{
              backgroundColor: 'var(--joy-palette-neutral-50)',
              overflow: 'scroll',
            }}
          >
            <ElementGroup
              options={[
                ...(nodeTypesByType.action || []),
                ...(nodeTypesByType.inlineTrigger || []),
              ]}
              onChange={onClose}
            />
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
}

export default AddElementDrawer;
