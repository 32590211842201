import React from 'react';
import { ChannelTypes } from '@sakari-io/sakari-typings';
import ChannelChip from '../ChannelChip';

interface ChannelChipsProps {
  value: string[];
  noLabel?: boolean;
}

function ChannelChips({ value, noLabel }: ChannelChipsProps) {
  return (
    <>
      {(value || []).map((channelType) => {
        return (
          <ChannelChip
            key={channelType}
            channel={channelType as ChannelTypes}
            noLabel={noLabel}
          />
        );
      })}
    </>
  );
}

export default ChannelChips;
