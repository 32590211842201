import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Stack, Button } from '@mui/joy';
import { Country as CountryProps } from '@sakari-io/sakari-typings';
import { findCountry } from '@sakari-io/sakari-common';
import { Flag } from '@sakari-io/sakari-components';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import WizardSteps from '../../../../../ui/molecules/WizardSteps';
import { StepDetail } from '../../../../../types/ui';
import SignLOA from './SignLOA';
import SelectableCardGroup from '../../../../../ui/molecules/groups/SelectableCardGroup';
import { useBuyPhoneNumbersMutation } from '../../../../../api/phonenumbers';
import WizardPage from '../../../../../ui/templates/forms/WizardPage';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import VerifyNumber from './VerifyNumber';
import AlertDialog from '../../../../../ui/molecules/AlertDialog';
import ConfirmationDialog from '../../../../../ui/molecules/ConfirmationDialog';
import { AccountContext } from '../../../../../contexts/account.context';
import { useAppDispatch } from '../../../../../hooks/index';
import { showToast } from '../../../../../redux/reducers/toast';
import { formatPayload } from './util';

function BYOMode() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { country } = params;

  const { account } = useContext(AccountContext);
  const [dialog, setDialog] = useState<'verify' | 'country' | undefined>(
    undefined,
  );

  const [addHostedNumbers, { isLoading }] = useBuyPhoneNumbersMutation();

  const [steps] = useState<StepDetail[]>([
    {
      id: 'country',
      label: t('addSenders.phonenumber.selectCountry.label'),
      title: t('addSenders.phonenumber.selectCountry.title'),
      description: t('addSenders.phonenumber.selectCountry.description'),
      subLabel: (values) => values.country?.name,
      pageComponent: (
        <FieldWrapper
          component={SelectableCardGroup}
          name="country"
          options={[
            { id: 'US', ...findCountry('US') },
            { id: 'CA', ...findCountry('CA') },
            {
              id: 'other',
              name: t('form.other.label'),
            },
          ]}
          renderOption={(option: CountryProps) => (
            <Stack alignItems="center" spacing={2}>
              {option.code ? (
                <Flag country={option} rounded size={40} />
              ) : (
                <FontAwesomeIcon icon={regular('globe')} size="3x" />
              )}
              <Typography>{option.name}</Typography>
            </Stack>
          )}
          height={128}
          width={145}
        />
      ),
      isVisible: () => true,
    },
    {
      id: 'verify',
      label: t('addSenders.phonenumber.byo.verify.label'),
      title: t('addSenders.phonenumber.byo.verify.label'),
      description: t('addSenders.phonenumber.byo.verify.description'),
      subLabel: (values) =>
        values?.numbers?.length
          ? `${
              values.numbers.filter((n: any) => n.status === 'verified').length
            } / ${values.numbers.length} Verified`
          : 'Not Verified',
      pageComponent: <VerifyNumber />,
      isVisible: () => true,
    },
    {
      id: 'signLOA',
      label: t('addSenders.phonenumber.byo.signLOA.label'),
      title: t('addSenders.phonenumber.byo.signLOA.label'),
      description: t('addSenders.phonenumber.byo.signLOA.description'),
      subLabel: (values) => {
        if (values.isLOASigned) {
          return 'Signed';
        }
        if (values.verified) {
          return 'Not Signed';
        }
        return null;
      },
      pageComponent: <SignLOA name="loa" />,
      isVisible: () => true,
    },
  ]);

  const [step, setStep] = useState<number>(0);
  const isLastStep = steps[step].id === 'signLOA';

  const schema = Yup.object().shape({
    country: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string().required(
          t('validation.isRequired', {
            field: t('form.country.label'),
          }),
        ),
      })
      .required(
        t('validation.pleaseSelect', {
          field: t('form.country.label'),
        }),
      )
      .nullable(),

    // TODO needs to handle an array
    hostedNumbers: Yup.array().when('country', {
      is: () => steps[step].id === 'verify',
      then: Yup.array()
        .of(
          Yup.object().shape({
            number: Yup.object()
              .shape({
                number: Yup.string().required(),
                country: Yup.string().required(),
                verification: Yup.object().shape({
                  id: Yup.string(),
                  key: Yup.string(),
                }),
              })
              .test(
                'isValidPhoneNumber',
                'Please enter a valid phone number',
                (value: any) => {
                  const { number, country } = value;

                  if (number && country) {
                    try {
                      const parsedNumber = parsePhoneNumberFromString(
                        number,
                        country as CountryCode,
                      );
                      return parsedNumber ? parsedNumber.isValid() : false;
                    } catch (error) {
                      return false;
                    }
                  }
                  return false;
                },
              ),
            provider: Yup.string().test(
              'isValidProvider',
              'This provider does not support hosted messaging',
              (value) => value === 'Other',
            ),
          }),
        )
        .min(1, 'Please add at least one valid number')
        .required(),
    }),
    loa: Yup.object().when('verified', {
      is: () =>
        // verified === true &&
        steps[step].id === 'signLOA',
      then: Yup.object({
        businessName: Yup.string().required(
          t('validation.isRequired', {
            field: t('addSenders.phonenumber.byo.signLOA.businessName.label'),
          }),
        ),
        address: Yup.object().shape({
          line1: Yup.string().required(
            t('validation.pleaseEnter', {
              field: t('form.street.label').toLowerCase(),
            }),
          ),
          line2: Yup.string(),
          city: Yup.string().required(
            t('validation.pleaseEnter', {
              field: t('form.city.label').toLowerCase(),
            }),
          ),
          state: Yup.mixed().test(
            'valid-state',
            'Invalid state',
            function (value) {
              const { path, createError } = this;
              if (typeof value === 'string') {
                return Yup.string().required().isValidSync(value);
              }
              if (
                typeof value === 'object' &&
                value !== null &&
                'code' in value
              ) {
                return Yup.object()
                  .shape({ code: Yup.string().required() })
                  .nullable()
                  .isValidSync(value);
              }
              return createError({
                path,
                message: t('validation.pleaseEnter', {
                  field: t('form.state.label').toLowerCase(),
                }),
              });
            },
          ),
          postalCode: Yup.string().required(
            t('validation.pleaseEnter', {
              field: t('form.postalCode.label').toLowerCase(),
            }),
          ),
          country: Yup.object({ code: Yup.string() })
            .required(
              t('validation.pleaseSelect', {
                field: t('form.country.label').toLowerCase(),
              }),
            )
            .required(
              t('validation.pleaseSelect', {
                field: t('form.country.label').toLowerCase(),
              }),
            )
            .nullable(),
        }),

        authPerson: Yup.object()
          .shape({
            firstName: Yup.string().required(
              t('validation.isRequired', {
                field: t('form.name.first'),
              }),
            ),
            lastName: Yup.string().required(
              t('validation.isRequired', {
                field: t('form.name.last'),
              }),
            ),
            email: Yup.string()
              .email(
                t('validation.isInvalid', {
                  field: t('form.email.label'),
                }),
              )
              .required(
                t('validation.isRequired', {
                  field: t('form.email.label'),
                }),
              ),
          })
          .required(
            t('validation.isRequired', {
              field: t('addSenders.phonenumber.byo.signLOA.authPerson.label'),
            }),
          ),
      }).required(),
    }),
    // isLOASigned: Yup.boolean().when('LOA', {
    //   is: () => steps[step].id === 'signLOA',
    //   then: Yup.boolean().equals([true], 'LOA Required').required(),
    // }),
  });

  const validate = makeValidate(schema);

  const initialValues = useMemo(
    () => ({
      country: country
        ? { id: country.toUpperCase(), ...findCountry(country) }
        : undefined,
      hostedNumbers: [],
      loa: {
        businessName: account?.billing?.name,
        address: account?.billing?.address,
        authPerson: {
          email: account?.info?.email,
        },
      },
    }),
    [account, country],
  );

  const handleBack = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    } else {
      history.back();
    }
  };

  const handleOnSubmit = async (values: any) => {
    const nextStep = step + 1;

    switch (steps[step].id) {
      case 'setup':
        setStep(nextStep);
        break;
      case 'country':
        if (values.country.id !== 'other') {
          setStep(nextStep);
        } else {
          setDialog('country');
        }

        break;
      case 'verify':
        if (values.hostedNumbers.some((n: any) => n.status !== 'verified')) {
          setDialog('verify');
        } else {
          setStep(nextStep);
        }
        break;
      case 'signLOA':
        if (!values) return;

        // logger.info('values', values);

        await addHostedNumbers(formatPayload(values))
          .unwrap()
          .then((res) => {
            // logger.info('added hosted numbers', res);
            if (res.success) {
              if (res.data?.failures && res.data.failures.length) {
                const failedNumbers = res.data.failures;
                navigate('/senders', {
                  state: {
                    addSender: {
                      success: false,
                      title: t('addSenders.phonenumber.byo.error'),
                      message: `Failed to add ${
                        failedNumbers.length
                      } number(s)\n${failedNumbers
                        .map((n: any) => `${n.number} - ${n.reason}`)
                        .join('\n')}`,
                    },
                  },
                });
              } else {
                navigate('/senders', {
                  state: {
                    addSender: {
                      success: true,
                      message: t('addSenders.phonenumber.byo.success'),
                    },
                  },
                });
              }
            } else {
              dispatch(
                showToast({
                  message: 'Failed to submit application',
                  severity: 'error',
                }),
              );
            }
          })
          .catch((err) => {
            dispatch(
              showToast({
                message: err.data.error?.message || t('somethingWrong'),
                severity: 'error',
              }),
            );
          });

        break;
      default:
        break;
    }
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        values,
        hasValidationErrors,
        hasSubmitErrors,
        submitting,
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Stack
              sx={{
                py: 8,
                pl: 4,
                pr: 2,
                width: 224,
                alignItems: 'center',
              }}
            >
              <WizardSteps
                orientation="vertical"
                steps={steps}
                activeStep={step}
                withFade
              />
            </Stack>
            <Stack
              sx={{
                paddingY: 8,
                paddingLeft: 8,
                paddingRight: { md: 12, xs: 6 },
                overflow: 'auto',
                width: '100%',
              }}
              spacing={2}
            >
              <WizardPage currentStep={steps[step]} />
              <Stack
                sx={{
                  columnGap: 2,
                  height: 'fit-content',
                  flexDirection: 'row',
                  width: '400px',
                }}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={() => handleBack()}
                  fullWidth
                >
                  {t('action.back')}
                </Button>
                <Button
                  color="primary"
                  disabled={hasValidationErrors || hasSubmitErrors}
                  loading={submitting || isLoading}
                  fullWidth
                  type="submit"
                >
                  {isLastStep
                    ? t('addSenders.phonenumber.byo.signLOA.action')
                    : t('action.next')}
                </Button>
              </Stack>
            </Stack>
            <AlertDialog
              open={dialog === 'country'}
              onClose={() => {
                setDialog(undefined);
              }}
              headerIcon={
                <FontAwesomeIcon icon={regular('phone-xmark')} size="3x" />
              }
              header={t('addSenders.phonenumber.unsupportedCountry.title', {
                country: values.country?.name,
              })}
              content={t(
                'addSenders.phonenumber.unsupportedCountry.description',
                {
                  country: values.country?.name,
                },
              )}
              closeLabel="action.back"
              type="warning"
            />
            <ConfirmationDialog
              open={dialog === 'verify'}
              onClose={(result) => {
                if (result) {
                  setStep(step + 1);
                }
                setDialog(undefined);
              }}
              header="Unverified Number(s)"
              content={
                <>
                  <Typography textColor="text.secondary">
                    You have one or more unverified numbers. Are you sure you
                    want to continue?
                  </Typography>
                  <Typography textColor="text.tertiary" level="body-sm">
                    We will reach out to continue the verification process after
                    submission.
                  </Typography>
                </>
              }
              confirmLabel="action.continue"
              cancelLabel="action.back"
              type="warning"
            />
          </form>
        );
      }}
    />
  );
}

export default BYOMode;
