import { useTranslation } from 'react-i18next';
import { Box, Typography, Chip, Stack, Button } from '@mui/joy';
import React, { useContext, useEffect } from 'react';
import { CallStatus } from '@sakari-io/sakari-typings';
import { format } from '@sakari-io/sakari-common';
import { logger } from '@sakari-io/sakari-components';
import ActionButtons from '../ActionButtons';
import { useAnswerCallMutation, useRejectCallMutation } from '../../../../api';
import Contact from '../Controls/Contact';
import { ActiveCall } from '../../../../types';
import { AccountContext } from '../../../../contexts/account.context';

export interface IncomingCallProps {
  call: ActiveCall;
}

function IncomingCall({ call }: IncomingCallProps) {
  const { t } = useTranslation('voice');
  const { profile } = useContext(AccountContext);

  const [answerCall] = useAnswerCallMutation();
  const [rejectCall] = useRejectCallMutation();

  useEffect(() => {
    const handleCallFromNotification = async (event: MessageEvent) => {
      if (event.data.type !== 'notificationclick') return null;
      if (event.data.action === '' && event.data.data.callId) {
        await accept(event.data.data.callId);
      } else if (event.data.action === 'dismiss' && event.data.data.callId) {
        await rejectCall(event.data.data.callId);
      }
      return null;
    };

    if (navigator.serviceWorker) {
      logger.info('registering service worker for incoming call');

      navigator.serviceWorker.addEventListener(
        'message',
        handleCallFromNotification,
      );
    }
    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener(
          'message',
          handleCallFromNotification,
        );
      }
    };
  }, []);

  // const playSound = () => {
  //   new Audio(ringtone).play();
  // };

  // useEffect(() => {
  //   playSound();
  // }, [socketData]);

  const { id, contact, target, user, group } = call;

  const info = {
    group: target?.group?.name ?? group?.name,
    contact: format.nameOrMobile(contact),
    user:
      profile && target?.user && profile.id !== target.user.id
        ? target.user
        : user,
  };

  const accept = async (id: string) => {
    if (!profile) {
      return;
    }

    try {
      await answerCall({ id, user: profile })
        .unwrap()
        .then(({ data }) => {
          logger.info('answerCall', 'data', data);
          return data;
        });
    } catch (error) {
      logger.error(error); // TODO better handling
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
        {info?.group && (
          <Chip color="primary" sx={{ marginRight: 2 }}>
            {t('to')}: {info.group}
          </Chip>
        )}
        <Stack>
          <Typography
            level="title-md"
            sx={{ color: 'var(--joy-palette-voice-softHoverBg)' }}
          >
            {call.status === CallStatus.Transferring
              ? `${t('transferFrom')} ${format.name(info.user)}`
              : t('incomingCall')}
          </Typography>
          <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
            {format.nameOrMobile(contact)}
          </Typography>
          {contact?.id ? <Contact contactId={contact?.id} /> : null}
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'end',
          paddingRight: '20px',
        }}
      />
      <ActionButtons
        topBtn={
          <Button
            variant="plain"
            color="voiceBtn"
            size="sm"
            onClick={() => accept(id)}
            sx={{
              color: 'var(--joy-palette-success-500)',
              '&:hover': { opacity: 0.8 },
            }}
            // disabled={answered}
          >
            {t('accept')}
          </Button>
        }
        bottomBtn={t('decline')}
        bottomAction={() => rejectCall(id)}
      />
    </>
  );
}

export default IncomingCall;
