import React from 'react';
import { Checkbox, ListItem } from '@mui/joy';
import { GridColDef } from '@mui/x-data-grid-pro';

interface ColumnItemProps {
  value: GridColDef;
  visibility: any;
  onChange: (value: { [key: string]: boolean }) => void;
}

function ColumnItem({ visibility, onChange, value }: ColumnItemProps) {
  return (
    <ListItem key={value.field}>
      <Checkbox
        label={value.headerName || ''}
        checked={visibility && visibility[value.field]}
        onChange={(e) => onChange({ [value.field]: e.target.checked })}
      />
    </ListItem>
  );
}

export default ColumnItem;
