import React, { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { GoogleSheet, GoogleSheetColumn } from '@sakari-io/sakari-typings';
import { CircularProgress } from '@sakari-io/sakari-components';
import Select from '../../../atoms/inputs/Select';
import { AccountContext } from '../../../../contexts/account.context';
import { useGetGoogleSheetColumnsQuery } from '../../../../api';
import { ConfigFieldProps } from '../../../../pages/Workflows/Edit/ConfigDrawer/NodeProperties/PropertyField';

interface ColumnValueSelectorProps extends ConfigFieldProps<GoogleSheetColumn> {
  config?: { googlesheet: GoogleSheet };
}

function ColumnValueSelector({
  label,
  helperText,
  value,
  onChange,
  config,
  disabled,
}: ColumnValueSelectorProps) {
  const { account } = useContext(AccountContext);

  const {
    data: columns,
    isError,
    isLoading,
    isFetching,
  } = useGetGoogleSheetColumnsQuery(
    account && config?.googlesheet?.connectionId && config?.googlesheet?.id
      ? {
          accountId: account.id,
          request: config?.googlesheet,
        }
      : skipToken,
  );

  const columnOptions = (columns?.data as GoogleSheetColumn[])?.map(
    (col: GoogleSheetColumn) => {
      return {
        value: col.index,
        label: col.name,
      };
    },
  );

  return (
    <Select
      label={label}
      helperText={helperText}
      disabled={disabled || (!isLoading && !isFetching && isError)}
      endDecorator={isLoading ? <CircularProgress size="sm" /> : null}
      value={value}
      onChange={onChange}
      options={columnOptions || []}
    />
  );
}

export default ColumnValueSelector;
