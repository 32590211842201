import React from 'react';
import { Group, PhoneNumber } from '@sakari-io/sakari-typings';
import { GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { Avatars, PhoneNumberChip } from '@sakari-io/sakari-components';
import { IconButton, Tooltip, Typography, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import OverFlowCell from '../../../molecules/OverFlowCell';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import EmptyResult from '../../../molecules/EmptyResult';
import { useGetGroupsQuery, useSetDefaultGroupMutation } from '../../../../api';
import DataGrid from '../../../molecules/DataGrid';

function InfoButton() {
  const { t } = useTranslation();
  return (
    <IconButton
      variant="solid"
      size="xsRound"
      component="a"
      href="https://support.sakari.io/hc/en-us/articles/18181130879252"
      target="_blank"
      title={t('learnMore')}
    >
      <FontAwesomeIcon icon={faQuestionCircle} color="white" />
    </IconButton>
  );
}

interface GroupsGridProps {
  filter?: object;
  // loadData: UseQuery<any>;
  onSelectedRowChange?: (group: Group) => any;
}

function GroupsGrid({ filter, onSelectedRowChange }: GroupsGridProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [setDefault] = useSetDefaultGroupMutation();

  const handleMakeDefault = async (group: Group) => {
    //TODO use handleApiMutationResponse
    await setDefault({ id: group.id })
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              severity: 'success',
              message: t('groups.defaultGroupSuccess'),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err?.data?.error?.message || t('groups.defaultGroupError'),
          }),
        );
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('group'),
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Stack
            sx={{
              // alignItems: 'center',
              // justifyContent: 'flex-start',
              // flexDirection: 'row',
              '#make-default': {
                ...(params.row.isDefault
                  ? {
                      visibility: 'visible',
                    }
                  : {
                      visibility: 'hidden',
                    }),
              },
              '&:hover #make-default': {
                visibility: 'visible',
              },
            }}
          >
            <Typography
              level="body-md"
              endDecorator={
                <Tooltip
                  title={
                    <Typography
                      level="body-md"
                      textColor="white"
                      endDecorator={<InfoButton />}
                      ml={1}
                    >
                      {params.row.isDefault
                        ? t('groups.defaultGroup')
                        : t('action.makeDefault')}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <IconButton
                    size="smRound"
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();
                      handleMakeDefault(params.row);
                    }}
                    sx={{
                      ...(params.row.isDefault
                        ? {
                            cursor: 'default',
                            color: 'var(--joy-palette-warning-500)',
                            '&:hover svg': {
                              color: 'var(--joy-palette-warning-600)',
                            },
                          }
                        : {
                            color: 'var(--joy-palette-grey-500)',
                            '&:hover svg': {
                              color: 'var(--joy-palette-primary-500)',
                            },
                          }),
                    }}
                  >
                    <FontAwesomeIcon
                      id="make-default"
                      icon={
                        params.row.isDefault ? solid('star') : regular('star')
                      }
                    />
                  </IconButton>
                </Tooltip>
              }
            >
              {params.row.name}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'users',
      headerName: t('settings.users.title'),
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const max = Math.floor(params.colDef.computedWidth / 40);
        return <Avatars data={params.row?.users} max={max} size="sm" />;
      },
    },
    {
      field: 'phoneNumbers',
      headerName: t('senders.title'),
      flex: 1,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <OverFlowCell
            data={params.row.phoneNumbers}
            containerSx={{
              p: 1,
              ml: -1,
            }}
            renderItem={(phonenumber: PhoneNumber) => {
              return (
                <PhoneNumberChip
                  key={phonenumber.number}
                  color="primary"
                  number={phonenumber}
                  onClick={() => {
                    navigate(`/senders/phonenumbers/${phonenumber.id}`);
                  }}
                />
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <DataGrid
      columns={columns}
      filter={filter}
      onSelectedRowChange={onSelectedRowChange}
      renderNoData={<EmptyResult heading={t('noGroups')} item="groups" />}
      loadingText={t('loadingGroups')}
      loadData={useGetGroupsQuery}
    />
  );
}

export default GroupsGrid;
