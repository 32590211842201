import {
  SakariAPIResponse,
  SearchablePaginationRequest,
  Group,
  Autoresponder,
  OfficeHours,
  OfficeHoursRequest,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';
import { getAccountId, buildGridQuery } from './common';
import {
  SENDER_TAG,
  GROUP_TAG,
  AUTO_RESPONDER_TAG,
  OFFICE_HOURS_TAG,
} from './tags';
import { AccountIdWith } from '../types';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<
      SakariAPIResponse<Group[]>,
      AccountIdWith<SearchablePaginationRequest & { admin?: number }>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/groups?${buildGridQuery(request)}`,
      providesTags: [GROUP_TAG, SENDER_TAG],
    }),

    getGroup: builder.query<SakariAPIResponse<Group>, AccountIdWith<string>>({
      query: ({ accountId, request: id }) =>
        `accounts/${accountId}/groups/${id}?v=2`,
      providesTags: [GROUP_TAG, SENDER_TAG],
    }),

    createGroup: builder.mutation<
      SakariAPIResponse<Group>,
      Partial<Group> & Pick<Group, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/groups`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [GROUP_TAG, SENDER_TAG], // TODO make more specific invalidation of senders
    }),

    updateGroup: builder.mutation<
      SakariAPIResponse<Group>,
      Partial<Group> & Pick<Group, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/groups/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [GROUP_TAG, SENDER_TAG], // TODO make more specific invalidation of senders
    }),
    addSenderToGroup: builder.mutation<
      SakariAPIResponse<Group>,
      AccountIdWith<{ groupId: string; senderId: string }>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/groups/${request.groupId}/senders`,
        method: 'POST',
        body: {
          id: request.senderId,
        },
      }),
      invalidatesTags: [GROUP_TAG, SENDER_TAG],
    }),
    removeSenderFromGroup: builder.mutation<
      SakariAPIResponse<Group>,
      AccountIdWith<{
        groupId: string;
        senderId: string;
      }>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/groups/${request.groupId}/senders/${request.senderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [GROUP_TAG, SENDER_TAG],
    }),
    deleteGroup: builder.mutation<SakariAPIResponse<Group>, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [GROUP_TAG, SENDER_TAG], // TODO make more specific invalidation of senders
    }),

    getAutoResponders: builder.query<
      SakariAPIResponse<Autoresponder[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/autoresponders?${buildGridQuery(request)}`,
      providesTags: [AUTO_RESPONDER_TAG],
    }),

    getAutoResponder: builder.query<
      SakariAPIResponse<Autoresponder>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: id }) =>
        `accounts/${accountId}/autoresponders/${id}`,
      providesTags: [AUTO_RESPONDER_TAG],
    }),

    createAutoResponder: builder.mutation<
      SakariAPIResponse<Autoresponder>,
      Partial<Autoresponder>
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/autoresponders`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [AUTO_RESPONDER_TAG],
    }),

    updateAutoResponder: builder.mutation<
      SakariAPIResponse<Autoresponder>,
      Partial<Autoresponder> & Pick<Autoresponder, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/autoresponders/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [AUTO_RESPONDER_TAG],
    }),

    deleteAutoResponder: builder.mutation<
      SakariAPIResponse<Autoresponder>,
      string
    >({
      query: (id) => ({
        url: `accounts/${getAccountId()}/autoresponders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [AUTO_RESPONDER_TAG],
    }),

    getOfficeHours: builder.query<
      SakariAPIResponse<OfficeHours>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: id }) =>
        `accounts/${accountId}/groups/${id}/officehours`,
      providesTags: [OFFICE_HOURS_TAG],
    }),

    updateOfficeHours: builder.mutation<
      SakariAPIResponse<OfficeHours>,
      OfficeHoursRequest
    >({
      query: ({ id, data }) => ({
        url: `accounts/${getAccountId()}/groups/${id}/officehours`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [OFFICE_HOURS_TAG],
    }),

    setDefaultGroup: builder.mutation<SakariAPIResponse<any>, any>({
      query: ({ id }) => ({
        url: `accounts/${getAccountId()}/groups/${id}/default`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [GROUP_TAG],
      onQueryStarted: async (
        { id },
        { dispatch, queryFulfilled, getState },
      ) => {
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [GROUP_TAG])
          .forEach(({ endpointName, originalArgs }) => {
            if (
              endpointName === 'getGroups' &&
              originalArgs.request?.admin === 1
            ) {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  endpointName,
                  originalArgs,
                  (draft) => {
                    draft.data.forEach((group) => {
                      group.isDefault = group.id === id;
                    });
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useAddSenderToGroupMutation,
  useRemoveSenderFromGroupMutation,
  useGetAutoRespondersQuery,
  useGetAutoResponderQuery,
  useCreateAutoResponderMutation,
  useUpdateAutoResponderMutation,
  useDeleteAutoResponderMutation,
  useGetOfficeHoursQuery,
  useUpdateOfficeHoursMutation,
  useSetDefaultGroupMutation,
} = extendedApi;
