import React, { useState } from 'react';

import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import { Contact, ContactFilter } from '@sakari-io/sakari-typings';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import ContactsToolbar from '../../../ui/organisms/headers/ContactsToolbar';
import ContactsGrid from '../../../ui/organisms/datagrids/ContactsGrid';
import BlockContactDialog from '../../../ui/organisms/Dialogs/BlockContactDialog';

interface ContactsTabProps {
  accountId: string;
  selectedRow?: Contact;
  onSelectedRowChange: (contact: Contact) => any;
  onCreateCampaign: (filter: ContactFilter) => any;
  onUploadContacts: () => any;
  onAddContact: () => any;
}

function ContactsTab({
  accountId,
  selectedRow,
  onSelectedRowChange,
  onCreateCampaign,
  onUploadContacts,
  onAddContact,
}: ContactsTabProps) {
  const [openBlockDialog, setOpenBlockDialog] = useState<boolean>(false);
  const [multi, setMulti] = useState<boolean>(false);
  const [blockContact] = useState<Contact>();
  const [filters, setFilters] = useState<ContactFilter>();
  const debouncedFilters = useDebouncedValue(filters, 500);

  // const onSortChange = useCallback((sortModel: GridSortModel) => {
  //   const sortValue = sortModel[0].field
  //     ? `${sortModel[0].field}-${sortModel[0].sort}`
  //     : '';
  //   setSort(sortValue);
  // }, []);

  const handleSelection = (
    newSelectionModel: GridRowSelectionModel,
    // condition?: Condition,
    // selectedAllRows?: boolean,
  ) => {
    setMulti(!!newSelectionModel.length);

    setFilters((prev: any) => {
      if (prev?.attributes) {
        const newAttributes = prev?.attributes?.filter(
          (attr: any) => attr.attribute !== 'id',
        );

        return {
          ...prev,
          attributes: [
            ...newAttributes,
            {
              attribute: 'id',
              comparator: 'in',
              value: newSelectionModel,
            },
          ],
        };
      }
      return {
        ...prev,
        attributes: [
          {
            attribute: 'id',
            comparator: 'in',
            value: newSelectionModel,
          },
        ],
      };
    });
  };

  return (
    <>
      <ContactsToolbar
        multi={multi}
        condition={filters?.attributes}
        onCreateCampaign={onCreateCampaign}
        filters={debouncedFilters}
        setFilters={setFilters}
        onAddContact={onAddContact}
        onUploadContacts={onUploadContacts}
      />

      <ContactsGrid
        accountId={accountId}
        selectedRow={selectedRow}
        onSelectedRowChange={onSelectedRowChange}
        filter={{
          ...(debouncedFilters || {}),
        }}
        onFilterChange={(attrs) =>
          setFilters((prev) => ({
            ...prev,
            attributes: attrs,
          }))
        }
        onAddContact={onAddContact}
        onUploadContacts={onUploadContacts}
        onSelectionModelChange={handleSelection}
      />

      {blockContact ? (
        <BlockContactDialog
          onClose={() => setOpenBlockDialog(false)}
          open={openBlockDialog}
          contact={blockContact}
        />
      ) : null}
    </>
  );
}

export default ContactsTab;
