import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../../../ui/atoms/inputs/TextField';
import Select from '../../../ui/atoms/inputs/Select';
import FieldWrapper from '../../../utils/FieldWrapper';
import Setting from '../../../ui/templates/forms/SettingForm';
import Integration from '../IntegrationEdit';

function Pipedrive() {
  const { t } = useTranslation();

  const timelineTypeOptions = [
    {
      label: 'Disabled',
      value: 'disabled',
    },
    {
      label: 'Activity',
      value: 'activity',
    },
    {
      label: 'Notes',
      value: 'notes',
    },
  ];

  return (
    <Integration name="pipedrive">
      <Setting label={t('pipedrive.companyName.label')}>
        <FieldWrapper component={TextField} name="company_name" readOnly />
      </Setting>

      <Setting label={t('pipedrive.companyDomain.label')}>
        <FieldWrapper component={TextField} name="company_domain" readOnly />
      </Setting>

      <Setting
        label={t('pipedrive.timelineType.label')}
        description={t('pipedrive.timelineType.description')}
      >
        <FieldWrapper
          component={Select}
          fullWidth
          name="timelineType"
          options={timelineTypeOptions}
        />
      </Setting>
    </Integration>
  );
}

export default Pipedrive;
