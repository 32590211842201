import {
  Divider,
  Modal,
  ModalDialog,
  ModalProps,
  Sheet,
  Stack,
} from '@mui/joy';
import { Form } from 'react-final-form';
import {
  Account,
  Contact,
  SakariAPIResponse,
  SendMessageRequest,
  SendMessageResponse,
} from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Loader } from '@sakari-io/sakari-components';
import { useState } from 'react';
import FieldWrapper from '../../../../utils/FieldWrapper';
import GroupSelect from '../../forms/GroupSelect';
import PeoplePicker from '../../forms/PeoplePicker';
import Composer, { ComposerResponse } from '../../forms/Composer';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';
import { useSendMessageMutation } from '../../../../api';
import isCountrySupported from '../../../../utils/groups';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import Helper from '../../../../utils/helper';

interface QuickSendDialogProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  onClose: (
    reason: string,
    result: SakariAPIResponse<SendMessageResponse> | null,
  ) => void;
  account: Account;
}

function QuickSendDialog({ open, onClose, account }: QuickSendDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dialog, setDialog] = useState<
    'discard' | 'schedule' | 'contacts' | null
  >(null);

  const [sendMessage, { isLoading }] = useSendMessageMutation();

  const handleClose = (reason: string, result: any) => {
    onClose?.(reason, result);
  };

  const handleOnSubmit = (values: any) => {
    if (!account) return;
    const payload: SendMessageRequest = {
      contacts: values.contacts,
      phoneNumberFilter: { group: values.group },
      template: values.content.message,
      media: values.content.files,
      sendAt: values.content.sendAt,
    };
    sendMessage(payload)
      .unwrap()
      .then((result) => {
        const { requested, valid } = result.data;
        let successMessage = t('messages.send.success');

        if (payload.sendAt) {
          if (valid === 0) {
            dispatch(
              showToast({
                severity: 'warning',
                message: t('messages.schedule.error'),
              }),
            );
            return;
          }

          const formattedDate = Helper.formatISODate(payload.sendAt, 'Pp');
          if (requested === valid) {
            successMessage = t('messages.schedule.success', {
              date: formattedDate,
            });
          } else {
            successMessage = t('messages.schedule.partialSuccess', {
              success: valid,
              total: requested,
              date: formattedDate,
            });
          }
        } else {
          if (valid === 0) {
            dispatch(
              showToast({
                severity: 'warning',
                message: t('messages.send.error'),
              }),
            );
            return;
          }

          if (requested !== valid) {
            successMessage = t('messages.send.partialSuccess', {
              success: valid,
              total: requested,
            });
          }
        }

        dispatch(
          showToast({
            severity: 'success',
            message: successMessage,
          }),
        );
        handleClose('submit', result);
      })
      .catch((error: SakariAPIResponse) => {
        dispatch(
          showToast({
            severity: 'error',
            message: error.data?.error?.message ?? t('somethingWrong'),
          }),
        );
      });
  };

  const getLoadingMessage = (values: {
    contacts: Contact[];
    content: ComposerResponse;
  }) => {
    const total = values?.contacts?.length ?? 1;

    if (values?.content?.sendAt) {
      return `${t('messages.schedule.loading', {
        count: total,
      })}...`;
    }
    return `${t('messages.send.loading', {
      count: total,
    })}...`;
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={{
        group: {
          id: localStorage.getItem(`${account.id}-selectedGroupId`),
        },
        contacts: [],
        content: {
          message: '',
          files: [],
        },
      }}
      keepDirtyOnReinitialize
      render={({ handleSubmit, initialValues, values }) => {
        const disableComposer = !values.contacts?.length || !values.group;
        return (
          <Modal
            open={open}
            onClose={() => {
              const { group, ...rest } = values;
              const { group: initialGroup, ...initialRest } = initialValues;

              const isPristine =
                group.id === initialGroup.id && _.isEqual(rest, initialRest);
              if (dialog && !isPristine) {
                setDialog(null);
              } else if (isPristine) {
                onClose('cancel', null);
              } else {
                setDialog('discard');
              }
            }}
          >
            <ModalDialog
              sx={{
                width: 520,
                padding: 0,
                overflow: 'hidden',
              }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (dialog && (e.target as Element).id !== 'people-picker') {
                  setDialog(null);
                }
              }}
            >
              {isLoading && (
                <Loader size={150} label={getLoadingMessage(values)} />
              )}
              <Stack
                sx={{
                  gap: 1,
                  padding: 1,
                  pb: 0,
                  '.MuiFormLabel-root': {
                    fontSize: 'sm',
                    fontWeight: 400,
                    color: 'text.tertiary',
                  },
                  '.MuiAutocomplete-startDecorator': {
                    fontSize: 'sm',
                    mr: 5,
                    ml: -1,
                  },
                }}
              >
                <FieldWrapper
                  component={GroupSelect}
                  name="group"
                  fullWidth
                  accountId={account.id}
                  label={`${_.capitalize(t('from'))}:`}
                  orientation="horizontal"
                />
                <Divider />
                <FieldWrapper
                  component={PeoplePicker}
                  name="contacts"
                  size="sm"
                  limit={null}
                  account={account}
                  tagsLimit={6}
                  defaultCountry={account?.defaults?.country?.code}
                  isOptionDisabled={(contact: Contact) => {
                    const { group } = values;
                    if (!contact?.mobile || (contact?.id).startsWith('new'))
                      return false;
                    const notSupported = !isCountrySupported(
                      group,
                      contact.mobile?.country,
                    );

                    return (
                      (notSupported && !group.useSharedPool) ||
                      !contact.valid ||
                      !!contact.blocked
                    );
                  }}
                  onOpen={() => {
                    setDialog('contacts');
                  }}
                />
              </Stack>
              <Sheet
                variant="soft"
                color="neutral"
                sx={{
                  padding: 1,
                }}
              >
                <FieldWrapper
                  account={account}
                  component={Composer}
                  name="content"
                  rowRange={[5, 10]}
                  onSend={() => {
                    handleSubmit();
                  }}
                  disabled={disableComposer || isLoading}
                  disabledFeatures={
                    account.plan.name === 'Trial' ? ['upload', 'link'] : []
                  }
                />
              </Sheet>
              <ConfirmationDialog
                type="warning"
                open={dialog === 'discard'}
                onClose={(result) => {
                  if (result) {
                    handleClose('discard', null);
                  }
                  setDialog(null);
                }}
                header={t('dialog.discard.title')}
                content={t('dialog.discard.description')}
                confirmLabel="action.discard"
              />
            </ModalDialog>
          </Modal>
        );
      }}
    />
  );
}
export default QuickSendDialog;
