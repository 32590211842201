import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Box, Stack, Button, Card, CardContent, Typography } from '@mui/joy';
import { Loader, Textarea } from '@sakari-io/sakari-components';
import { useGenerateContentMutation } from '../../../../api';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import { AppDialog } from '../../..';
import { AppDialogProps } from '../../../molecules/AppDialog';
import Radios from '../../../molecules/Radios';
import CopyButton from '../../ButtonContexts/CopyButton';
import PageHeader from '../../headers/PageHeader';

interface GeneratorDialogProps extends AppDialogProps {
  onHide: (content?: string) => any;
}

function GeneratorDialog({ open, onHide, setOpen }: GeneratorDialogProps) {
  const { t } = useTranslation();
  const [request, setRequest] = useState<string>('');
  const [count, setCount] = useState<number>(1);
  const [suggestionStep, setSuggestionStep] = useState<number>(0);

  const [generate, { isSuccess, data, isLoading }] =
    useGenerateContentMutation();
  const dispatch = useAppDispatch();

  const handleGenerate = async () => {
    if (!request) return null;
    setSuggestionStep(1);
    return generate({ request, count })
      .unwrap()
      .catch((error) => {
        dispatch(
          showToast({
            message: error.data.error.message
              ? error.data.error.message
              : t('unableToCreateItem', {
                  item: t('items.shortURL_one'),
                }),
            severity: 'error',
          }),
        );
      });
  };

  const generateExample = (example: string) => {
    return (
      <Typography
        level="body-md"
        sx={{
          '#ai-example': {
            opacity: 0,
          },
          '&:hover': {
            '#ai-example': { opacity: 1 },
          },
        }}
        endDecorator={
          <CopyButton
            id="ai-example"
            text={example}
            onClick={() => setRequest(example)}
          />
        }
      >
        {example}
      </Typography>
    );
  };

  return (
    <AppDialog
      size="md"
      autoClose={false}
      open={open}
      setOpen={setOpen}
      header={
        <PageHeader
          sx={{ pl: 0 }}
          title={t('generator.title')}
          backAction={
            suggestionStep === 1 ? () => setSuggestionStep(0) : undefined
          }
        />
      }
      onClose={() => {
        setRequest('');
        setSuggestionStep(0);
      }}
      showHeaderIcon={false}
      confirmButtonProps={{
        onClick: () => handleGenerate(),
        children:
          suggestionStep === 0
            ? t('generator.generate')
            : t('generator.regenerate'),
        disabled: _.isEmpty(request) || isLoading,
      }}
      content={
        suggestionStep === 0 ? (
          <>
            <Stack spacing={1} sx={{ overflow: 'scroll' }}>
              <Typography>{t('generator.examples.label')}</Typography>
              {generateExample(t('generator.examples.example1'))}
              {generateExample(t('generator.examples.example2'))}
              {generateExample(t('generator.examples.example3'))}
            </Stack>

            <Textarea
              placeholder={t('generator.placeholder')}
              value={request}
              onChange={(value: any) => {
                setRequest(value);
              }}
              maxRows={10}
              minRows={2}
            />

            <Radios
              label="Count"
              items={[
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
              ]}
              value={count}
              onChange={(evt: any) => setCount(Number(evt))}
              helperText={t('generator.generateCount')}
              row
            />
          </>
        ) : (
          <Box
            sx={{
              overflow: 'scroll',
              maxHeight: 'calc(100vh - 200px)',
            }}
          >
            {isLoading ? (
              <Loader size={100} />
            ) : (
              isSuccess &&
              suggestionStep === 1 &&
              data?.data.responses?.map((response, i) => (
                <Card orientation="horizontal">
                  <CardContent>
                    <Typography level="title-lg">{`Response ${
                      i + 1
                    }`}</Typography>
                    <Typography>{response.trim()}</Typography>
                  </CardContent>
                  <Box alignSelf="flex-end" ml={2}>
                    <Button
                      onClick={() => {
                        onHide(response.trim());
                        setOpen(false);
                        setRequest('');
                        setSuggestionStep(0);
                      }}
                    >
                      {t('generator.use')}
                    </Button>
                  </Box>
                </Card>
              ))
            )}
          </Box>
        )
      }
    />
  );
}

export default GeneratorDialog;
