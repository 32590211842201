import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import React, { useMemo } from 'react';
import { findCountry } from '@sakari-io/sakari-common';
import * as Yup from 'yup';
import { TaxType } from '@sakari-io/sakari-typings';
import FieldWrapper from '../../../../utils/FieldWrapper';
import { TextField } from '../../../../ui';
import Grid from '../../../../ui/templates/Grid';
import Select from '../../../../ui/atoms/inputs/Select';
import AddressAutocomplete from '../../../../ui/organisms/forms/AddressAutocomplete';

export const billingInfoSchema = Yup.object().shape({
  email: Yup.string().email(),
  name: Yup.string().required(),
  address: Yup.object().shape({
    line1: Yup.string().required('Street address is required.'),
    line2: Yup.string(),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    postalCode: Yup.string().when('country', {
      is: (country: { code: string }) => ['US', 'CA'].includes(country?.code),
      then: Yup.string().required('Required if in US or Canada.'),
    }),
    country: Yup.object()
      .shape({
        code: Yup.string().required('Country is required.'),
      })
      .nullable(),
  }),
  tax: Yup.object().shape({
    id: Yup.string(),
    type: Yup.string()
      .when('id', {
        is: (taxId: string) => !!taxId,
        then: Yup.string().required(),
      })
      .nullable(),
  }),
});

interface BillingInfoFormProps {
  isDisabled?: (name: string) => boolean;
}

function BillingInfoForm({ isDisabled = () => false }: BillingInfoFormProps) {
  const { t } = useTranslation();
  const formState = useFormState();
  const { values } = formState;

  const countryTaxTypes = useMemo(() => {
    const taxTypes = [
      ...(findCountry(values.address?.country?.code)?.taxTypes ?? []),
      {
        type: 'unknown',
        name: 'Other',
        example: '',
      },
    ];

    return taxTypes.map((taxType: TaxType) => ({
      value: taxType.type,
      label: taxType.name,
      example: taxType.example,
    }));
  }, [values?.address?.country?.code]);

  return (
    <Grid gap={2}>
      {/* billing email */}
      <Grid item gridColumn={{ sm: 12 }}>
        <FieldWrapper
          name="email"
          component={TextField}
          label={t('settings.billing.general.info.billingEmail.label')}
          labelInfo={t(
            'settings.billing.general.info.billingEmail.description',
          )}
          disabled={isDisabled('email')}
        />
      </Grid>

      {/* legal business name */}
      <Grid item gridColumn={{ sm: 12 }}>
        <FieldWrapper
          name="name"
          component={TextField}
          label={t('senders.register.legalCompanyName.label')}
          required
          disabled={isDisabled('name')}
        />
      </Grid>
      <Grid item gridColumn={{ sm: 12 }}>
        <FieldWrapper
          component={AddressAutocomplete}
          name="address"
          label={t('settings.billing.general.info.billingAddress.label')}
          fullWidth
          isRequired={(name: string) => {
            if (['US', 'CA'].includes(values?.address?.country?.code)) {
              return [
                'country',
                'line1',
                'city',
                'state',
                'postalCode',
              ].includes(name);
            }
            return ['country', 'line1', 'city'].includes(name);
          }}
        />
      </Grid>

      {/* tax type */}
      {values?.address?.country?.code ? (
        <Grid item gridColumn={{ sm: 12 }}>
          <FieldWrapper
            name="tax.type"
            component={Select}
            label={t('settings.billing.general.info.taxType.label')}
            placeholder={t('settings.billing.general.info.taxType.placeholder')}
            required={!!values.tax?.id}
            disabled={isDisabled('tax.type')}
            options={countryTaxTypes}
          />
        </Grid>
      ) : null}
      {/* tax id */}
      <Grid item gridColumn={{ sm: 12 }}>
        <FieldWrapper
          name="tax.id"
          component={TextField}
          label={t('settings.billing.general.info.taxId')}
          disabled={isDisabled('tax.id')}
          placeholder={
            countryTaxTypes.find(
              (taxType: any) => taxType.value === values?.tax?.type,
            )?.example
          }
        />
      </Grid>
    </Grid>
  );
}
export default BillingInfoForm;
