import React from 'react';
import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import Setting from '../../ui/templates/forms/SettingForm';
import FieldWrapper from '../../utils/FieldWrapper';
import { TextField, CountryAutocomplete } from '../../ui';

function GeneralPage() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        maxWidth: 700,
        gap: 2,
      }}
    >
      <Setting label={t('settings.general.name.label')} required>
        <FieldWrapper
          component={TextField}
          name="name"
          fullWidth
          placeholder={t('settings.general.name.label')}
        />
      </Setting>

      {/* <Setting
          label={t('settings.general.legalName.label')}
          description={t('settings.general.legalName.description')}
          required
        >
          <FieldWrapper
            component={TextField}
            name="legalName"
            fullWidth
            placeholder={t('settings.general.legalName.label')}
          />
        </Setting> */}

      <Setting
        label={t('settings.general.defaultCountry.label')}
        description={t('settings.general.defaultCountry.description')}
        required
      >
        <FieldWrapper
          component={CountryAutocomplete}
          name="defaults.country"
          fullWidth
          label={t('form.country.label')}
        />
      </Setting>
    </Stack>
  );
}

export default GeneralPage;
