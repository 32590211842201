import axios from 'axios';
import { logger } from '@sakari-io/sakari-components';
import Helper from '../utils/helper';
import config from '../config';

const uploadFile = async (token: string, type: string, data: any) => {
  logger.info('upload', type, data);

  // const accessToken = await getAccessTokenSilently();
  const url = `${
    config.apiUrl
  }/accounts/${Helper.getAccountId()}/tools/sharefile?resize=500`;
  const result = await axios({
    url,
    method: 'POST',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': type,
    },
    data,
  }).catch((err) => {
    throw err;
  });

  return result.data.data.link;
};

export default uploadFile;
