import React from 'react';
import { Controls as RFControls, ControlButton, useReactFlow } from 'reactflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/joy';

interface ControlsProps {
  workflowZoom: number;
}

function Controls({ workflowZoom }: ControlsProps) {
  const { zoomIn, zoomOut } = useReactFlow();

  const controlButtonStyle = {
    borderRadius: '50px',
    padding: '10px',
    fontSize: '1.2rem',
    boxShadow: '0px 0px 5px var(--joy-palette-neutral-200)',
  };

  return (
    <RFControls
      showInteractive={false}
      showFitView={false}
      showZoom={false}
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        width: '50px',
      }}
    >
      <Typography
        level="body-md"
        sx={{ color: 'var(--joy-palette-neutral-400)', fontWeight: 500 }}
      >{`${(workflowZoom * 100).toFixed(0)}%`}</Typography>
      <ControlButton
        style={controlButtonStyle}
        onClick={() => zoomIn()}
        disabled={workflowZoom >= 2}
      >
        <FontAwesomeIcon icon={solid('plus')} />
      </ControlButton>
      <ControlButton
        style={controlButtonStyle}
        onClick={() => zoomOut()}
        disabled={workflowZoom <= 0.5}
      >
        <FontAwesomeIcon icon={solid('minus')} />
      </ControlButton>
    </RFControls>
  );
}

export default Controls;
