import React, { useContext, useState } from 'react';
import { IconButton, Stack } from '@mui/joy';
import _ from 'lodash';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader, logger } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import PageHeader from '../../../ui/organisms/headers/PageHeader';
import {
  useGetIntegrationPropertiesQuery,
  useUpdateIntegrationMutation,
} from '../../../api';
import DisconnectDialog from '../../../ui/organisms/Dialogs/DisconnectDialog';
import Container from '../../../ui/templates/Container';
import StatusChip from '../../../ui/organisms/ChipContexts/StatusChip';
import { AccountContext } from '../../../contexts/account.context';
import { showToast } from '../../../redux/reducers/toast';
import { useAppDispatch } from '../../../redux';
import DropdownMenu from '../../../ui/molecules/Menu/DropdownMenu';

interface IntegrationProps {
  name: string;
  title?: string;
  renderSettings?: (values: any) => React.ReactNode;
  children?: React.ReactNode;
}

function Integration({
  name,
  title,
  children,
  renderSettings,
}: IntegrationProps) {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetIntegrationPropertiesQuery(
    account
      ? {
          accountId: account.id,
          request: name,
        }
      : skipToken,
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [updateIntegration] = useUpdateIntegrationMutation({});

  const [open, setOpen] = useState(false);

  const formSave = (values: any) => {
    return updateIntegration({ data: values, type: name })
      .unwrap()
      .then((res) => {
        dispatch(
          showToast({
            ...(res.success
              ? { severity: 'info', message: t('form.save.success') }
              : { severity: 'error', message: t('form.save.error') }),
          }),
        );
      });
  };

  if (isLoading) {
    return <Loader size={200} label="Loading integration..." />;
  }

  let eventNotifications;

  if (data?.data.timeline) {
    eventNotifications = 'timeline_messageInfo';
  } else if (data?.data.messageInfoInbound) {
    eventNotifications = 'msgInfoInbound';
  } else {
    eventNotifications = 'none';
  }

  // TODO this is not ideal
  const initialValues =
    name === 'hubspot'
      ? {
          ...data?.data,
          eventNotifications,
          userSync: {
            role: data?.data.userSyncRole,
            group: data?.data.userSyncGroup,
            lastRun: data?.data.userSyncLastRun,
          },
          hasInboxScope: (data?.data.scopes || []).includes(
            'conversations.read',
          ),
          inbox: (data?.data.scopes || []).includes('conversations.read')
            ? data?.data.inbox
            : [],
        }
      : data?.data;

  if (!data?.data || data?.data?.status !== 'connected') {
    logger.info('redirecting', location);
    navigate(
      {
        pathname: '/integrations',
        search: location.search,
      },
      {
        replace: true,
        state: {
          integration: name,
        },
      },
    );
  }

  const getDropDownItems = () => {
    const items: any[] = [
      {
        id: 'disconnect',
        label: t('disconnect'),
        color: 'danger',
      },
    ];

    if (data?.data.siteUrl) {
      items.unshift({
        id: 'goto',
        label: t('goToItem', {
          item: _.startCase(name),
        }),
        title: data?.data.siteUrl,
      });
    }

    return items;
  };

  const handleSelection = (value: string) => {
    logger.info(value);
    switch (value) {
      case 'disconnect':
        setOpen(true);
        break;
      case 'goto':
        if (!data?.data.siteUrl) break;
        window.open(data?.data.siteUrl, '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <Form
      onSubmit={formSave}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      render={({
        handleSubmit,
        hasValidationErrors,
        pristine,
        values,
        submitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <PageHeader
            title={title || _.startCase(name)}
            backAction={() => navigate('/integrations')}
            ctaText={children ? t('save') : undefined}
            ctaDisabled={isLoading || hasValidationErrors || pristine}
            loading={submitting}
            ctaAction={handleSubmit}
            optionalContent={
              <Stack
                sx={{
                  flex: 1,
                  flexFlow: 'row nowrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <StatusChip status={data?.data?.status} />
                <DropdownMenu
                  onSelect={(e, item) => handleSelection(item?.id ?? '')}
                  button={
                    <IconButton
                      sx={{
                        alignSelf: 'flex-end',
                      }}
                    >
                      <FontAwesomeIcon icon={regular('ellipsis-vertical')} />
                    </IconButton>
                  }
                  items={getDropDownItems()}
                />
              </Stack>
            }
          />

          <Container bgcolor="background.level1">
            <Stack
              gap={3}
              sx={{
                '.MuiFormControl-root': {
                  width: '100%',
                },
                gap: 2,
                maxWidth: 600,
                pb: 3,
              }}
            >
              {children}

              {renderSettings ? renderSettings(values) : null}
            </Stack>
          </Container>

          <DisconnectDialog
            open={open}
            setOpen={setOpen}
            type={name.toLowerCase()}
          />
        </form>
      )}
    />
  );
}

export default Integration;
