import React from 'react';
import { Typography, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface RestrictionsProps {
  label?: string | React.ReactNode;
  withoutQr?: boolean;
}

function Restrictions({ label, withoutQr }: RestrictionsProps) {
  const { t } = useTranslation();
  const maxContacts = 10;

  const qrPhrase = '"START"';

  const renderLabelContent = () => {
    if (typeof label === 'string') return renderLabel(label);
    if (!label)
      return renderLabel(
        t('senders.register.platformFreeTrial.restrictions.label'),
      );
    return label;
  };

  const renderLabel = (label: string) => (
    <Typography level="title-lg" sx={{ fontWeight: 700 }}>
      {label}
    </Typography>
  );

  return (
    <Stack spacing={2}>
      {renderLabelContent()}
      <Typography level="body-md">
        {t('senders.register.platformFreeTrial.restrictions.restrictTo')}
      </Typography>

      <ul>
        <li>
          <Typography
            level="body-md"
            fontWeight={700}
            sx={{
              color:
                'var(--grey-original-500-paras-placeholders-icons, #667085);',
            }}
          >
            {t(
              'senders.register.platformFreeTrial.restrictions.contactsMaximum',
              { contacts: maxContacts },
            )}
          </Typography>

          {withoutQr
            ? t(
                'senders.register.platformFreeTrial.restrictions.messagingLimited',
                { contacts: maxContacts, phrase: qrPhrase },
              )
            : t(
                'senders.register.platformFreeTrial.restrictions.messagingLimitedWithQr',
                { contacts: maxContacts },
              )}
        </li>

        <li>
          <Typography
            level="body-md"
            fontWeight={700}
            sx={{
              color:
                'var(--grey-original-500-paras-placeholders-icons, #667085);',
            }}
          >
            {t(
              'senders.register.platformFreeTrial.restrictions.watermarked.label',
            )}
          </Typography>

          {t(
            'senders.register.platformFreeTrial.restrictions.watermarked.description',
          )}
        </li>
      </ul>
    </Stack>
  );
}

export default Restrictions;
