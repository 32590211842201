import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { Stack, SvgIcon, Sheet, Typography } from '@mui/joy';
import Button from '@mui/joy/Button';

import { Form } from 'react-final-form';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';

import { Campaign } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormApi } from 'final-form';
import { Tooltip } from '@sakari-io/sakari-components';
import {
  faCalendarAlt,
  faCircleExclamation,
  faInfoCircle,
  faPencilAlt,
  faSlidersH,
  faTriangleExclamation,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import Details from './Details';

import {
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
} from '../../../api';
import IconAccordion from '../../../ui/molecules/IconAccordion';
import { showToast } from '../../../redux/reducers/toast';
import { useAppDispatch } from '../../../redux';

import Composer from '../../../ui/organisms/forms/Composer';
import FieldWrapper from '../../../utils/FieldWrapper';

import { CampaignDrawerMode } from '../../../types/ui';
import { AccountContext } from '../../../contexts/account.context';
import Contacts from './Contacts';
import Conditions from './Conditions';
import Schedule from './Schedule';
import ConfirmationDialog from '../../../ui/molecules/ConfirmationDialog';
import AppDrawer from '../../../ui/molecules/AppDrawer';
import Helper from '../../../utils/helper';
import AlertDialog from '../../../ui/molecules/AlertDialog';

interface CampaignDetailsProps {
  campaign?: Campaign;
  onClose: () => any;
}

function CampaignDetails({ campaign, onClose }: CampaignDetailsProps) {
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);
  const { t } = useTranslation();
  const [mode, setMode] = useState<CampaignDrawerMode>(CampaignDrawerMode.ADD);
  const [expanded, setExpanded] = useState<string>('details');
  const [dialog, setDialog] = useState<
    'discard' | 'noList' | 'uploading' | null
  >(null);
  const [createCampaign, { isLoading: isCreating }] =
    useCreateCampaignMutation();
  const [updateCampaign, { isLoading: isUpdating }] =
    useUpdateCampaignMutation();

  const campaignSections = [
    {
      id: 'details',
      formName: 'details',
      title: t('campaignDetails'),
      icon: <FontAwesomeIcon icon={faInfoCircle} />,
      component: <Details accountId={account?.id || ''} />,
    },
    {
      id: 'contacts',
      title: t('contacts.title'),
      formName: 'filters.list',
      icon: <FontAwesomeIcon icon={faUsers} />,
      component: <FieldWrapper component={Contacts} name="filters.list" />,
    },
    {
      id: 'conditions',
      title: t('conditions'),
      formName: 'filters.attributes',
      icon: <FontAwesomeIcon icon={faSlidersH} />,
      component: (
        <FieldWrapper component={Conditions} name="filters.attributes" />
      ),
    },
    {
      id: 'messaging',
      title: t('messaging'),
      formName: 'template',
      icon: <FontAwesomeIcon icon={faPencilAlt} />,
      component: (
        <FieldWrapper
          component={Composer}
          name="template"
          size="small"
          account={account}
        />
      ),
    },
    {
      id: 'schedule',
      title: t('schedule'),
      formName: 'schedule',
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      component: <FieldWrapper component={Schedule} name="schedule" />,
    },
  ];

  useEffect(() => {
    if (campaign?.id) {
      setMode(CampaignDrawerMode.EDIT);
    } else {
      setMode(CampaignDrawerMode.ADD);
    }
  }, [campaign?.id]);

  const schema = Yup.object().shape({
    details: Yup.object().shape({
      name: Yup.string().required('Please enter a campaign name'),
      phoneNumberFilter: Yup.object().shape({
        group: Yup.object().shape({
          id: Yup.string().required('Please select an Outbound Number Group'),
        }),
      }),
    }),
    template: Yup.object().shape({
      message: Yup.string().required('Please enter a message'),
    }),
    filters: Yup.object().shape({
      list: Yup.mixed(),
      attributes: Yup.array()
        .of(Yup.object())
        .test({
          name: 'allValid',
          message:
            'At least one condition has incomplete information. Enter missing fields or delete the condition to continue.',
          test: (value: any) => {
            if (!value) return true;

            return value.every((condition: any) => {
              return (
                condition.attribute &&
                condition.comparator &&
                condition.value &&
                Helper.asArray(condition.value).every(
                  (v: string | number) => v !== '',
                )
              );
            });
          },
        }),
    }),
    schedule: Yup.object().shape(
      {
        frequency: Yup.string().required('Please select a frequency'),
        // dayOfMonth: Yup.number().when(['frequency'], {
        //   is: (frequency: string) => frequency === 'M',
        //   then: Yup.number().required('Please select a day').nullable(),
        // }),
        // days: Yup.object().when(['frequency'], {
        //   is: (frequency: string) => frequency === 'D',
        //   then: Yup.object().required('Please select days'),
        // }),
        // day: Yup.object().when(['frequency'], {
        //   is: (frequency: string) => frequency === 'O',
        //   then: Yup.string().required('Please select a day'),
        // }),
        // time: Yup.string().when(['frequency'], {
        //   is: (frequency: string) => frequency !== 'OD',
        //   then: Yup.string().required('Please select a time'),
        // }),
      },
      // [
      //   ['frequency', 'dayOfMonth'],
      //   ['frequency', 'days'],
      //   ['frequency', 'day'],
      //   ['frequency', 'time'],
      // ]
    ),
  });

  const [campaignSectionList, setCampaignSectionList] =
    useState<any[]>(campaignSections);

  const validate = makeValidate(schema);

  const handleAccordion = () => () => {
    makeValidate(schema);
  };

  const onExpandCollapsed = (panelId: string) => {
    const updatedCampaignSectionList = campaignSectionList.map((item) => {
      if (item.id === expanded) {
        return { ...item, isTouched: true };
      }
      return item;
    });
    setCampaignSectionList(updatedCampaignSectionList);

    setExpanded((prev) => (prev === panelId ? '' : panelId));
  };

  const saveChanges = (values: any) => {
    const {
      id,
      details: { name, description, phoneNumberFilter },
      template,
      filters,
      schedule,
    } = values;

    const payload = {
      id,
      name,
      description,
      template: template.message,
      media: (template.files || []).map((f: any) =>
        _.pick(f, ['name', 'type', 'url']),
      ),
      trigger: {
        code: 'V2',
      },
      filters: _.pickBy({
        list: filters?.list?.id !== 'all' ? filters?.list : undefined,
        // q: filters?.q,
        attributes: filters?.attributes,
      }),
      phoneNumberFilter: {
        group: {
          id: phoneNumberFilter.group?.id,
        },
      },
      schedule,
    };

    const action =
      mode === CampaignDrawerMode.ADD
        ? createCampaign(payload)
        : updateCampaign(payload);

    action
      .unwrap()
      .then(() => {
        dispatch(
          showToast({
            severity: 'success',
            message:
              mode === CampaignDrawerMode.ADD
                ? t('campaign.createSuccess')
                : t('campaign.updateSuccess'),
          }),
        );
        onClose();
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message:
              err?.data?.message || err?.message || t('campaign.saveError'),
          }),
        );
      });
  };

  const formify = (campaign?: Campaign) => {
    if (!campaign) {
      return {
        schedule: {
          frequency: 'OD',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };
    }

    const {
      name,
      description,
      phoneNumberFilter,
      template,
      media,
      filters,
      schedule,
      ...other
    } = campaign;

    const data = {
      details: {
        name,
        description: description || '',
        phoneNumberFilter,
      },
      template: {
        message: template || '',
        files:
          media?.map((img) => ({
            ...img,
            isUploaded: true,
          })) || [],
      },
      filters,
      schedule: _.assign(
        {
          frequency: 'OD',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        schedule,
      ),
      ...other,
    };

    return data;
  };

  const initialValues = formify(campaign);

  const resetForm = (form: FormApi<any>) => {
    setExpanded('details');
    setCampaignSectionList(campaignSections);
    form.setConfig('keepDirtyOnReinitialize', false);
    form.restart({});
    form.setConfig('keepDirtyOnReinitialize', true);
  };

  const handleCloseDrawer = (values: any, form: FormApi<any>) => {
    const pristine = campaign && _.isEqual(values, initialValues);
    if (!pristine) {
      setDialog('discard');
    } else {
      resetForm(form);
      onClose();
    }
  };

  return (
    <Form
      onSubmit={(values) => {
        if (values.filters?.list?.status === 'uploading') {
          setDialog('uploading');
        } else if (values?.filters?.list?.id === 'all') {
          setDialog('noList');
        } else {
          saveChanges(values);
        }
      }}
      initialValues={initialValues}
      validate={validate}
      keepDirtyOnReinitialize
      render={({
        handleSubmit,
        form,
        values,
        hasValidationErrors,
        pristine,
        errors,
      }) => {
        return (
          <>
            <AlertDialog
              open={dialog === 'uploading'}
              // open
              onClose={() => setDialog(null)}
              type="warning"
              header={t('campaigns.uploadNeeded.title')}
              content={t('campaigns.uploadNeeded.description')}
              closeLabel="action.confirm"
            />
            <ConfirmationDialog
              open={dialog === 'discard'}
              onClose={(result) => {
                setDialog(null);

                if (result) {
                  resetForm(form);

                  onClose();
                }
              }}
              type="danger"
              header={t('campaigns.discard.title')}
              content={t('campaigns.discard.description')}
              confirmLabel="action.discard"
            />
            <ConfirmationDialog
              open={dialog === 'noList'}
              onClose={(result) => {
                if (result) {
                  saveChanges(values);
                } else {
                  setExpanded('contacts');
                }
                setDialog(null);
              }}
              type="warning"
              header={t('noListCampaign.header')}
              content={t('noListCampaign.description')}
              confirmLabel="continue"
            />
            <AppDrawer
              position="right"
              open={!!campaign}
              onClose={() => handleCloseDrawer(values, form)}
              header={
                mode === CampaignDrawerMode.ADD
                  ? t('newCampaign')
                  : t('editCampaign')
              }
              sx={{
                width: 450,
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <Stack sx={{ p: 2, overflowY: 'overlay', gap: 2 }}>
                  {campaignSectionList.map((section) => {
                    const { component, icon, isTouched, formName, ...rest } =
                      section;

                    const hasErrors = !!_.get(errors, formName);

                    const isExpanded = expanded === section.id;

                    return (
                      <IconAccordion
                        key={section.title}
                        expanded={isExpanded}
                        onExpand={() => {
                          handleAccordion();
                        }}
                        action={() => {
                          if (values.filters?.list?.status === 'uploading') {
                            setDialog('uploading');
                            return;
                          }
                          onExpandCollapsed(section.id);
                        }}
                        icon={
                          <SvgIcon
                            sx={{
                              opacity: isExpanded ? 1 : 0.5,
                              fontSize: 'md',
                            }}
                          >
                            {icon}
                          </SvgIcon>
                        }
                        validationIcon={
                          hasErrors && isTouched ? (
                            <SvgIcon color="danger" fontSize="sm">
                              <FontAwesomeIcon icon={faCircleExclamation} />
                            </SvgIcon>
                          ) : null
                        }
                        variant={isExpanded ? 'outlined' : 'plain'}
                        color={hasErrors && isTouched ? 'danger' : 'neutral'}
                        sx={{
                          [`[id^='accordion-header-']`]: {
                            backgroundColor: 'neutral.softBg',

                            ...(hasErrors &&
                              isTouched && {
                                backgroundColor: 'danger.softBg',
                              }),
                          },
                        }}
                        {...rest}
                      >
                        <Sheet
                          sx={{
                            p: 2,
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                          }}
                        >
                          {component}
                          {errors &&
                            section.id === 'conditions' &&
                            errors?.filters?.attributes && (
                              <Typography level="body-sm" color="danger" mt={1}>
                                {errors?.filters?.attributes?.join(',')}
                              </Typography>
                            )}
                        </Sheet>
                      </IconAccordion>
                    );
                  })}
                  <Button
                    variant="solid"
                    disabled={pristine || hasValidationErrors}
                    loading={isCreating || isUpdating}
                    type="submit"
                    fullWidth
                    sx={{
                      pointerEvents: 'auto',
                    }}
                    endDecorator={
                      hasValidationErrors ? (
                        <Tooltip
                          variant="outlined"
                          arrow
                          title="One or more fields are missing or invalid."
                          placement="top"
                        >
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            color="white"
                            style={{
                              pointerEvents: 'auto',
                            }}
                          />
                        </Tooltip>
                      ) : null
                    }
                  >
                    {mode === CampaignDrawerMode.ADD
                      ? t('createCampaign')
                      : t('updateCampaign')}
                  </Button>
                </Stack>
              </form>
            </AppDrawer>
          </>
        );
      }}
    />
  );
}

export default CampaignDetails;
