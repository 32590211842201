import { Box, SheetProps, Stack, Tooltip, Typography } from '@mui/joy';
import React, { SyntheticEvent } from 'react';
import Accordion from '../../templates/Accordion';

interface IconAccordionProps extends SheetProps {
  icon: any;
  title: string;
  expanded: boolean;
  onExpand: (event: SyntheticEvent<Element, Event>, expanded: boolean) => any;
  validationIcon?: any;
  action: () => void;
  children?: React.ReactNode;
}

function IconAccordion({
  icon,
  title,
  expanded,
  onExpand,
  children,
  validationIcon,
  action,
  sx,
  ...rest
}: IconAccordionProps) {
  return (
    <Accordion
      isOpen={expanded}
      setIsOpen={() => onExpand}
      action={action}
      header={
        <Stack width="100%" direction="row" spacing={1} alignItems="center">
          {icon}
          <Typography>{title}</Typography>
          {validationIcon && (
            <Box style={{ marginLeft: 'auto', marginRight: '10px' }}>
              <Tooltip title="Required">{validationIcon}</Tooltip>
            </Box>
          )}
        </Stack>
      }
      content={children}
      sx={{
        ...(expanded && {
          filter: 'var(--joy-shadow-open)',
        }),
        '&:hover': {
          filter: 'var(--joy-shadow-hover)',
        },

        zIndex: expanded ? 1 : 0,
        ...sx,
      }}
      {...rest}
    />
  );
}

export default IconAccordion;
