import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../contexts/account.context';
import PageHeader from '../../ui/organisms/headers/PageHeader';
import TabbedNavigation from '../../ui/organisms/headers/TabbedNavigation';

export enum PartnersViewMode {
  SETTINGS = 0,
  LEADS = 1,
  REFERRALS = 2,
}

interface PartnersProps {
  title: string;
  actionText?: string;
  action?: any;
  view: PartnersViewMode;
  disableSubmit?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
}

function Partners({
  title,
  action,
  actionText,
  view,
  disableSubmit,
  loading,
  children,
}: PartnersProps) {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation();

  const tabs = [
    {
      value: 0,
      label: t('partnerSettings.settings'),
      url: '/partners',
    },
    {
      value: 1,
      label: t('partnerSettings.leads'),
      url: '/partners/leads',
      hidden: !account?.partner?.approved,
    },
    {
      value: 2,
      label: t('partnerSettings.referrals'),
      url: '/partners/referrals',
      hidden: !account?.partner?.approved,
    },
  ];

  const tabUrlProvider = (tab: number) => {
    if (tab === 0) {
      return '/partners';
    }
    if (tab === 1) {
      return '/partners/leads';
    }
    if (tab === 2) {
      return '/partners/referrals';
    }
    return '/partners';
  };

  return (
    <>
      <PageHeader
        title={title}
        ctaAction={action}
        ctaText={actionText}
        ctaDisabled={disableSubmit || false}
        loading={loading}
      />

      <TabbedNavigation
        tabs={tabs}
        selectedTab={view}
        onTabSelected={(tab) => tabUrlProvider(tab)}
        divider
      >
        {children}
      </TabbedNavigation>
    </>
  );
}

export default Partners;
