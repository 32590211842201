import React from 'react';
import { MenuList } from '@mui/joy';
import { useDispatch } from 'react-redux';
import {
  WorkflowDataReference,
  WorkflowNodeTypeOutputDataType,
} from '@sakari-io/sakari-typings';
import OverlayPopup from '../../../../ui/templates/OverlayPopup';
import HoverMenuItem from '../PropertiesMenu/HoverMenuItem';
import Menu from '../../../../ui/molecules/Menu';
import { iconMap } from '../PropertiesMenu/utils';
import { actions, SubMode } from '../../../../redux/reducers/workflow';

interface ContactTypeMenuProps {
  open: boolean;
  onClose: () => void;
  inputRef: any;
  onChange: (value: any) => void;
  fullWidth?: boolean;
}

function ContactTypeMenu({
  open,
  onClose,
  inputRef,
  onChange,
  fullWidth = true,
}: ContactTypeMenuProps) {
  const dispatch = useDispatch();

  const options = [
    {
      label: 'Enrollment Contact',
      value: 'trigger',
    },
    {
      label: 'Output Contact',
      value: 'outputContact',
    },
  ];

  return (
    <OverlayPopup
      buttonRef={inputRef}
      fullWidth={fullWidth}
      open={open}
      onOpenClose={onClose}
      renderPopup={() => (
        <Menu>
          <MenuList sx={{ border: 'none', padding: 0 }}>
            {options?.map((option) => (
              <HoverMenuItem
                key={option?.label}
                icon={iconMap[option?.value]?.icon}
                color={iconMap[option?.value]?.color}
                label={option.label}
                onSelect={() => {
                  if (option?.value === 'outputContact') {
                    onChange({
                      name: option?.label,
                    });

                    dispatch(
                      actions.enterSelectionMode({
                        callback: (v: WorkflowDataReference) => {
                          onChange(v);
                        },
                        subMode: SubMode.SELECTION_BY_TYPE,
                        selectionType: WorkflowNodeTypeOutputDataType.Contact,
                      }),
                    );
                  } else {
                    onChange({
                      name: option?.label,
                      nodeId: option?.value,
                      path: 'contact',
                    });
                  }

                  onClose();
                }}
              />
            ))}
          </MenuList>
        </Menu>
      )}
    />
  );
}

export default ContactTypeMenu;
