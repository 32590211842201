import { Stack, Typography, Sheet, Checkbox, Button, Table } from '@mui/joy';
import { PhoneNumber } from '@sakari-io/sakari-typings';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo, Tooltip, NumberWithFlag } from '@sakari-io/sakari-components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface BulkRegisterProps {
  initialPn: PhoneNumber;
  availablePhoneNumbers?: PhoneNumber[];
  setShowForm: (show: boolean) => void;
  selectedPhoneNumbers: PhoneNumber[];
  setSelectedPhoneNumbers: (phoneNumbers: PhoneNumber[]) => void;
  setConfirmed: (confirmed: boolean) => void;
}

function BulkRegister({
  initialPn,
  availablePhoneNumbers,
  setShowForm,
  selectedPhoneNumbers,
  setSelectedPhoneNumbers,
  setConfirmed,
}: BulkRegisterProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const allSelected =
    selectedPhoneNumbers?.length === availablePhoneNumbers?.length;

  const campaign = initialPn?.channels?.sms?.regulatory?.campaign?.id;

  const sortedNumbers = useMemo(() => {
    // if initialPn has a campaign, it should filter phone numbers by campaign
    if (campaign) {
      return availablePhoneNumbers
        ?.filter(
          (pn) => pn.channels?.sms?.regulatory?.campaign?.id === campaign,
        )
        .sort((a, b) => {
          if (a.id === initialPn.id) {
            return -1;
          }
          if (b.id === initialPn.id) {
            return 1;
          }
          return 0;
        });
    }
    return availablePhoneNumbers?.slice().sort((a, b) => {
      if (a.id === initialPn.id) {
        return -1;
      }
      if (b.id === initialPn.id) {
        return 1;
      }
      return 0;
    });
  }, [availablePhoneNumbers, initialPn.id]);
  const handleSelectAll = () => {
    setSelectedPhoneNumbers(
      allSelected ? [initialPn] : sortedNumbers || [initialPn],
    );
  };

  useEffect(() => {
    if (initialPn) {
      setSelectedPhoneNumbers([initialPn]);
    } else {
      navigate('../senders');
    }

    if (campaign) {
      handleSelectAll();
    }
  }, []);

  const handleSelect = (pn: PhoneNumber) => {
    if (selectedPhoneNumbers.includes(pn)) {
      setSelectedPhoneNumbers(selectedPhoneNumbers.filter((s) => s !== pn));
    } else {
      setSelectedPhoneNumbers([...selectedPhoneNumbers, pn]);
    }
  };

  return (
    <Stack
      sx={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
        padding: 4,
      }}
    >
      {campaign ? (
        t('senders.register.bulkRegister.resubmit')
      ) : (
        <>
          <Typography level="title-lg">
            {t('senders.register.bulkRegister.title', {
              count: (sortedNumbers?.length && sortedNumbers?.length - 1) || 0,
            })}
          </Typography>
          <Typography textColor="text.secondary">
            {t('senders.register.bulkRegister.description')}
          </Typography>
        </>
      )}

      <Sheet
        variant="outlined"
        sx={{
          width: '597px',
          borderRadius: '8px',
          overflowY: 'overlay',
          overflowX: 'hidden',
          flex: 1,
        }}
      >
        <Table
          role="group"
          stickyHeader
          hoverRow
          sx={{
            height: '100%',

            thead: {
              '& th': {
                verticalAlign: 'middle',
                '&:nth-child(1)': { width: '10%' },
                '&:nth-child(2)': { width: '20%', textAlign: 'center' },
              },
            },

            // checkbox padding
            'th, td': {
              '&:first-of-type  > *': {
                p: 1,
              },
            },

            '[aria-selected=true]': {
              background: 'var(--joy-palette-primary-softBg)',
            },
          }}
        >
          <thead>
            <tr>
              <th>
                <Tooltip
                  placement="top-start"
                  title={
                    allSelected
                      ? t('action.deselectAll')
                      : t('action.selectAll')
                  }
                >
                  <Checkbox
                    id="select-all"
                    aria-label="select all"
                    disabled={!availablePhoneNumbers?.length || !!campaign}
                    onChange={() => handleSelectAll()}
                    checked={allSelected}
                    indeterminate={
                      !allSelected && selectedPhoneNumbers.length > 0
                    }
                  />
                </Tooltip>
              </th>
              <th>{_.capitalize(t('items.provider_one'))}</th>
              <th>{_.capitalize(t('items.number_one'))}</th>
            </tr>
          </thead>
          <tbody>
            {sortedNumbers?.map((pn: PhoneNumber) => (
              <tr
                key={pn.id}
                aria-selected={selectedPhoneNumbers
                  .map((s) => s.id)
                  .includes(pn.id)}
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (pn.id === initialPn.id || !!campaign) return;
                  handleSelect(pn);
                }}
              >
                <td>
                  <Checkbox
                    slotProps={{
                      checkbox: {
                        'aria-describedby': pn.number,
                      },
                    }}
                    sx={{
                      p: '12px',
                    }}
                    checked={selectedPhoneNumbers
                      .map((s) => s.id)
                      .includes(pn.id)}
                    onChange={() => handleSelect(pn)}
                    disabled={initialPn.id === pn.id || !!campaign}
                  />
                </td>
                <td align="center">
                  <Logo provider={pn.provider} size={30} />
                </td>
                <td>
                  <NumberWithFlag mobile={pn} />
                </td>
              </tr>
            ))}
            <tr
              style={{
                flex: '0 1 0',
              }}
            />
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={3}
                style={{
                  textAlign: 'center',
                  position: 'sticky',
                  bottom: 0,
                }}
              >
                {t('selectedItems', {
                  count: selectedPhoneNumbers.length,
                  items: t(`items.number`, {
                    count: selectedPhoneNumbers.length,
                  }),
                })}
              </td>
            </tr>
          </tfoot>
        </Table>
      </Sheet>

      <Stack sx={{ flexFlow: 'row wrap', gap: 1 }}>
        <Button
          color="neutral"
          variant="outlined"
          onClick={() => setConfirmed(false)}
        >
          {t('action.back')}
        </Button>
        <Button onClick={() => setShowForm(true)}>{t('action.next')}</Button>
      </Stack>
    </Stack>
  );
}
export default BulkRegister;
