import {
  STAGE,
  AUTH0_CLIENT_ID,
  INTEGRATION_PROPERTIES,
  API_SUFFIX,
} from './environment';

export const isStorybookEnv = window.location.port === '6006';

const BASE_URL = `${window.location.protocol}//${window.location.host}`;

const {
  REACT_APP_API_URL,
  REACT_APP_HUB_URL,
  REACT_APP_INTEGRATIONS_URL,
  REACT_APP_VOICE_URL,
  REACT_APP_VOICE_WEBSOCKET_URL,
} = !isStorybookEnv
  ? process.env
  : {
      REACT_APP_API_URL: '',
      REACT_APP_HUB_URL: '',
      REACT_APP_INTEGRATIONS_URL: '',
      REACT_APP_VOICE_URL: '',
      REACT_APP_VOICE_WEBSOCKET_URL: '',
    };

const config = {
  stage: STAGE,
  apiUrl:
    (REACT_APP_API_URL ||
      `https://api${API_SUFFIX}${
        STAGE === 'prod' ? '' : `.${STAGE}`
      }.sakari.io`) + '/v1',
  integrationsUrl:
    REACT_APP_INTEGRATIONS_URL ||
    `https://integrations${STAGE === 'prod' ? '' : `.${STAGE}`}.sakari.io`,
  voiceUrl:
    (REACT_APP_VOICE_URL ||
      `https://voice${STAGE === 'prod' ? '' : `.${STAGE}`}.sakari.io`) + '/v1',
  voiceWebsocketUrl:
    REACT_APP_VOICE_WEBSOCKET_URL ||
    `https://voice${STAGE === 'prod' ? '' : `.${STAGE}`}.sakari.io`,
  hubV1Url: `https://hub${STAGE === 'prod' ? '' : `.${STAGE}`}.sakari.io/v1`,
  hubUrl:
    REACT_APP_HUB_URL ||
    `https://hub${STAGE === 'prod' ? '' : `.${STAGE}`}.sakari.io`,
  websocketUrl: `https://ws${STAGE === 'prod' ? '' : `.${STAGE}`}.sakari.io`,
  baseUrl: BASE_URL,
  auth0: {
    domain: `https://auth${STAGE === 'prod' ? '' : `-${STAGE}`}.sakari.io`,
    clientId: AUTH0_CLIENT_ID,
    audience: 'https://api.sakari.io',
    // callbackUrl: REACT_APP_HUB_URL
    //   ? `${REACT_APP_HUB_URL}/callback`
    //   : `https://hub${STAGE === 'prod' ? '' : `.${STAGE}`}.sakari.io/callback`,
    callbackUrl: `${BASE_URL}/callback`,
    scopes:
      'read:current_user update:current_user_metadata profile offline_access',
  },
  googlePlacesApiKey: 'AIzaSyBSjhTwAKowLDHabvgedueNjzg-khaCDbE',
  datagridLicenseKey:
    'e6fecf68a3333c33f98956ee634ae303Tz04MjcyMSxFPTE3Mzc2MDc0MDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  integrationProperties: INTEGRATION_PROPERTIES,
};

export default config;
