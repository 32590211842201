import React from 'react';
import { Stack } from '@mui/joy';
import { IDable, AdvancedDelayDays } from '@sakari-io/sakari-typings';
import SelectableCardGroup from '../../groups/SelectableCardGroup';
import { InputControlProps } from '../../InputControl';

interface SelectableDaysProps
  extends Omit<InputControlProps, 'value' | 'onChange'> {
  value: AdvancedDelayDays;
  onChange: (value: AdvancedDelayDays) => any;
  children?: React.ReactNode;
}

function SelectableDays({
  value,
  onChange,
  color = 'primary',
  ...rest
}: SelectableDaysProps) {
  const options = [
    { id: 0, label: 'S' },
    { id: 1, label: 'M' },
    { id: 2, label: 'T' },
    { id: 3, label: 'W' },
    { id: 4, label: 'T' },
    { id: 5, label: 'F' },
    { id: 6, label: 'S' },
  ];

  const values =
    value?.map((v) => ({
      id: v,
      label: `${v}`,
    })) || [];

  return (
    <Stack spacing={2}>
      <SelectableCardGroup
        options={options}
        variant="solid"
        direction="row"
        width={38}
        slotProps={
          {
            action: ({ checked }: any) => ({
              sx: {
                backgroundColor: `var(--joy-palette-${color}-softHoverBg)`,
                '&:hover': {
                  backgroundColor: `var(--joy-palette-${color}-softActiveBg)`,
                },
                ...(checked && {
                  backgroundColor: `var(--joy-palette-${color}-solidBg)`,
                  boxShadow: `var(--joy-shadow-sm)`,
                }),
              },
            }),
            label: ({ checked }: any) => ({
              sx: {
                fontWeight: 500,
                color: `var(--joy-palette-common-white)`,
                ...(checked && {
                  fontWeight: 700,
                }),
              },
            }),
          } as any
        }
        size="sm"
        containerSx={{
          gap: '7px',
          flexWrap: 'nowrap',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          '& > *': { flexGrow: 1, minWidth: 38 },
        }}
        value={values}
        onChange={(e) => onChange((e as any).map((v: IDable) => v.id))}
        multiple
        {...rest}
      />
    </Stack>
  );
}

export default SelectableDays;
