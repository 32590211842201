/* eslint-disable no-underscore-dangle */
import { isBefore, parseISO, format as formatDate } from 'date-fns';
import parsePhoneNumberFromString, {
  parse,
  format,
  CountryCode,
  isPossiblePhoneNumber,
} from 'libphonenumber-js';
import { v4 as uuid } from 'uuid';
import {
  Conversation,
  InternationalNumber,
  List,
} from '@sakari-io/sakari-typings';
import i18next from 'i18next';
import moment from 'moment';
import _ from 'lodash';
import { ConversationFilter, StepDetail } from '../types';

const { t } = i18next;

class Helper {
  private CONVERSATION_FILTER_KEY = 'conversationFilter';

  private ON_BOARDING_LOCAL_STORAGE_KEY = 'isOnBoardingCompleted';

  private PINNED_LISTS_LOCAL_STORAGE_KEY = 'pinned-lists';

  public static arrayBufferToBase64(buffer: any): string {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b: any) => {
      binary += String.fromCharCode(b);
    });

    return window.btoa(binary);
  }

  public static stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: this._stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  // public isContactBlocked(contact: Contact): boolean {
  //   if (conversation?.contact?.blocked) return true;
  //   else return this.isConversationArchived(conversation);
  // }

  public static isConversationArchived(conversation: Conversation): boolean {
    return isBefore(new Date(conversation.closed), new Date());
  }

  // TODO consolidate with rtk.js
  // public getAccountDetails(): any {
  //   return JSON.parse(localStorage.getItem('account') as any);
  // }

  // TODO consolidate with rtk.js
  public static getAccountId(): string {
    return localStorage.getItem('accountId') || '';
  }

  public static generateMongoId(): string {
    const timestamp = Math.floor(Date.now() / 1000)
      .toString(16)
      .padStart(8, '0');
    const randomValue = uuid().replace(/-/g, '').slice(0, 10);
    const counter = Math.floor(Math.random() * 0xffffff)
      .toString(16)
      .padStart(6, '0');

    return timestamp + randomValue + counter;
  }

  // TODO DECOMMISSION - use getAccessTokenSilently method instead
  // Don't think we should use this - as it doesn't auto extend
  // public getAccessToken(): string {
  //   return localStorage.getItem('accessToken') || '';
  // }

  private static _stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  public static getNameInitials(contact: any) {
    if (contact.name) {
      return contact.name
        .split(' ')
        .map((value: string) => {
          return this.getInitials(value);
        })
        .join('');
    }

    if (contact.firstName || contact.lastName) {
      let initials = '';
      if (contact.firstName) {
        initials += this.getInitials(contact.firstName);
      }
      if (contact.lastName) {
        initials += this.getInitials(contact.lastName);
      }

      return initials;
    }

    return null;
  }

  private static getInitials(value: string) {
    if (value && value.replace(/[^a-zA-Z]/g, '').trim().length > 0) {
      const values = value.replace(/[^a-zA-Z]/g, '').split(' ');
      if (values.length > 0) {
        return values[0].substring(0, 1);
      }
    }
    return '';
  }

  public static getNameOrMobileNumber(contact: any) {
    if (!contact) {
      return null;
    }

    if (contact.name) {
      return contact.name.trim();
    }

    if (contact.firstName || contact.lastName) {
      return `${contact.firstName || ''} ${contact.lastName || ''}`.trim();
    }
    if (contact.mobile) {
      return this.formatPhoneNumber(contact.mobile);
    }
    return '';
  }

  public static formatPhoneNumber(mobile: InternationalNumber) {
    if (!mobile?.number) {
      return null;
    }
    const { number } = mobile;

    const phoneNumber = parse(number);
    const formatedNumber = format(phoneNumber, 'NATIONAL');
    const finalNumber = formatedNumber || number;
    return finalNumber;
  }

  public static stringToPhoneNumber = (
    number: string,
    country?: string,
  ): InternationalNumber | null => {
    if (!number) {
      return null;
    }

    const parsed = parsePhoneNumberFromString(
      number,
      country?.toUpperCase() as CountryCode,
    );
    return parsed
      ? ({
          country: parsed.country,
          number: parsed.number,
        } as InternationalNumber)
      : null;
  };

  public static validatePhoneNumber = (
    number: string,
    country?: string,
  ): boolean => {
    if (!number) {
      return false;
    }
    if (number.startsWith('+')) {
      return isPossiblePhoneNumber(number);
    }
    if (country) {
      const parsed = parsePhoneNumberFromString(
        number,
        country.toUpperCase() as CountryCode,
      );
      return parsed ? parsed.isValid() : false;
    }
    return false;
  };

  public static dateFormatter = (
    date: Date | string,
    formatString = 'd MMM yyyy',
  ): string => {
    if (typeof date === 'string') {
      return formatDate(new Date(date), formatString);
    }
    return formatDate(date, formatString);
  };

  public static dateFormatterDate = (
    date: Date | string,
    formatString = 'MM/dd/yyyy',
  ): string => {
    if (typeof date === 'string') {
      return formatDate(new Date(date), formatString);
    }
    return formatDate(date, formatString);
  };

  public static dateFormatterTime = (
    date: Date | string,
    formatString = 'p',
  ): string => {
    if (typeof date === 'string') {
      return formatDate(new Date(date), formatString);
    }
    return formatDate(date, formatString);
  };

  // public formatDateAndTime = (date: Date | string): string => {
  //   return formatDate(new Date(date), "MMM d, yyyy 'at' h:mma");
  // };

  public static formatDateTime = (date: Date | string): string => {
    return formatDate(new Date(date), 'PPpp');
  };

  public static capitalize = (str: string) => {
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  public static formatISODate = (date?: string | Date, pattern = 'PP') => {
    if (!date) return null;

    return formatDate(parseISO(new Date(date).toISOString()), pattern);
  };

  public static getLocalizedDateTime = (
    date: string | Date,
    options?: Intl.DateTimeFormatOptions,
  ) => {
    if (!date) return undefined;
    const parsed = typeof date === 'string' ? parseISO(date) : date;
    // get the client locale
    const clientLocale = navigator.language;

    // format date using the client locale
    const formatter = new Intl.DateTimeFormat(clientLocale, options);

    const formattedDate = formatter.format(parsed);
    return formattedDate;
  };

  public static getDuration(seconds: number) {
    return moment.utc(1000 * seconds).format('HH:mm:ss');
  }

  public static formatURL(url?: string) {
    if (!url) {
      return null;
    }
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  }

  public static formatNumberWithCommas(number: number, digits?: number) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(number);
  }

  public static formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  public static getDefaultAccount = () => {
    const defaultAccount = localStorage.getItem('defaultAccount');

    if (defaultAccount) {
      return JSON.parse(defaultAccount);
    }

    return null;
  };

  public setConversationFilter = (conversationFilter: ConversationFilter) => {
    localStorage.setItem(
      this.CONVERSATION_FILTER_KEY,
      JSON.stringify(conversationFilter),
    );
  };

  public resetFilter = () => {
    localStorage.removeItem(this.CONVERSATION_FILTER_KEY);
  };

  public getConversationFilter = () => {
    const filter = localStorage.getItem(this.CONVERSATION_FILTER_KEY);

    if (filter) {
      return JSON.parse(filter) as ConversationFilter;
    }

    return undefined;
  };

  public static formatType = (type: string) => {
    switch (type) {
      case 'tollfree':
        return 'Toll-Free';
      case 'shortcode':
        return 'Short Code';
      case 'senderid':
        return 'SenderID';
      default:
        return type.charAt(0).toUpperCase() + type.slice(1);
    }
  };

  // {"provider":"sakari","type":"local","numbers":[{"number":"+16502623594","country":"US"}]}

  public static formatAddNumbersRequest = (provider: string, values: any) => {
    if (!values) {
      throw new Error('Please enter a valid payload');
    }

    const { lineType, country, numbers } = values;

    return {
      provider,
      type: lineType.type,
      country: country.code,
      numbers:
        lineType.type === 'senderid'
          ? [
              {
                number: values.alphaNum,
                country: country.code,
              },
            ]
          : numbers?.map((n: string) => ({
              number: n,
              country: country.code,
            })),
    };
  };

  public static formatLabels = (values: any, steps: StepDetail[]) => {
    if (values.senderType) {
      const i = steps.findIndex((f) => f.id === 'channel');
      steps[i].label = `Channel: \n${values.senderType.label}`;
    }
    if (values.country) {
      const i = steps.findIndex((f) => f.id === 'country');
      steps[i].label = `Country: ${values.country.name}`;
    }
    if (values.hosted) {
      const i = steps.findIndex((f) => f.id === 'numberSource');
      steps[i].label =
        `Source: ${values.hosted.id === 'no' ? 'New Number' : 'Hosted'}`;
    }
    if (values.type) {
      const i = steps.findIndex((f) => f.id === 'numberType');
      steps[i].label = `Type: ${values.type.name}`;
    }
    if (values.brand) {
      const i = steps.findIndex((f) => f.id === 'brand');
      steps[i].label = `Brand: ${values.brand.displayName}` || 'New Brand';
    }
    if (values.campaign) {
      const i = steps.findIndex((f) => f.id === 'usecase');

      steps[i].label =
        values.campaign.useCase !== undefined
          ? t(`10dlc.usecases.${values.campaign.useCase}.name`)
          : 'New Use Case';
    }
    if (values.numbers) {
      const i = steps.findIndex((f) => f.id === 'number');
      steps[i].label =
        values.numbers.length > 0
          ? `${values.numbers.length} ${values.country.code} Number${
              values.numbers.length > 1 ? 's' : ''
            }`
          : 'Choose Number';
    }
    return steps;
  };

  // TODO: Make this more generic to the steps by linking the step id to the value label key?
  // public formatSubLabels = (values: any, steps: StepDetail[]) => {
  //   const subLabeledSteps = steps.map((step) => {
  //     if (values.senderType && step.id === 'channel') {
  //       return { ...step, subLabel: values.senderType.label };
  //     }
  //     if (values.country && step.id === 'country') {
  //       return { ...step, subLabel: values.country.name };
  //     }
  //     if (values.hosted && step.id === 'numberSource') {
  //       return {
  //         ...step,
  //         subLabel: values.hosted.id === 'no' ? 'New Number' : 'Hosted',
  //       };
  //     }
  //     if (values.type && step.id === 'numberType') {
  //       return { ...step, subLabel: values.type.name };
  //     }
  //     if (values.options?.sendToUS && step.id === 'sendToUS') {
  //       return { ...step, subLabel: values.options.sendToUS?.label };
  //     }
  //     if (values.brand && step.id === 'brand') {
  //       return { ...step, subLabel: values.brand.displayName || 'New Brand' };
  //     }
  //     if (values.campaign && step.id === 'usecase') {
  //       return {
  //         ...step,
  //         subLabel:
  //           values.campaign.useCase !== undefined
  //             ? t('10dlc.usecases.' + values.campaign.useCase + '.name')
  //             : 'New Use Case',
  //       };
  //     }
  //     if (values.numbers) {
  //       const i = steps.findIndex((f) => f.id === 'number');
  //       steps[i].subLabel =
  //         values.numbers.length > 0
  //           ? `${values.numbers.length} ${values.country.code} Number${
  //               values.numbers.length > 1 ? 's' : ''
  //             }`
  //           : 'Choose Number';
  //     }
  //     return step;
  //   });
  //   // logger.info('subLabeledSteps', subLabeledSteps);
  //   return subLabeledSteps;
  // };

  public setPinnedListItem = (list: List) => {
    const tempList = this.getPinnedList();
    tempList.push(list);
    localStorage.setItem(
      this.PINNED_LISTS_LOCAL_STORAGE_KEY,
      JSON.stringify(tempList),
    );
  };

  public updatePinnedListName = (id: string, name: string) => {
    const tempList = this.getPinnedList();
    const item = tempList.find((item: any) => item.id === id);
    if (item) {
      item.name = name;
      localStorage.setItem(
        this.PINNED_LISTS_LOCAL_STORAGE_KEY,
        JSON.stringify(tempList),
      );
    }
  };

  public removePinnedListFromLocalStorage = (list: List) => {
    localStorage.setItem(
      this.PINNED_LISTS_LOCAL_STORAGE_KEY,
      JSON.stringify(this.getPinnedList().filter((i) => i.id !== list.id)),
    );
  };

  public getPinnedList = () => {
    const list = localStorage.getItem(this.PINNED_LISTS_LOCAL_STORAGE_KEY);

    if (list) {
      return JSON.parse(list) as List[];
    }

    return [] as Array<List>;
  };

  public static copyToClipboard = (text: string) => {
    // copy text to clipboard
    window.navigator.clipboard.writeText(text);
  };

  public static getCursorPosition = (el: HTMLTextAreaElement) => {
    if (el.selectionStart || el.selectionStart === 0) {
      return el.selectionStart;
    }
    return 0;
  };

  public static asArray = (value: any) => {
    if (value === undefined) {
      return [];
    }
    if (_.isArray(value)) {
      return value;
    }
    return [value];
  };

  public static getAllFirstErrors = (error: any): string[] => {
    if (!error) return [];

    const firstErrors: string[] = [];

    Object.values(error).forEach((message) => {
      if (typeof message === 'string' && message.length > 0) {
        firstErrors.push(message);
      } else if (typeof message === 'object') {
        const nestedErrors = this.getAllFirstErrors(message);
        if (nestedErrors.length > 0) {
          firstErrors.push(...nestedErrors);
        }
      }
    });

    return firstErrors;
  };

  // private getOnBoardingAccountNames(): List[] {
  //   if (localStorage.getItem(this.PINNED_LISTS_LOCAL_STORAGE_KEY)) {
  //     const jsonValues = JSON.parse(localStorage.getItem(this.PINNED_LISTS_LOCAL_STORAGE_KEY)) as List[];
  //     if (jsonValues) {
  //       return jsonValues;
  //     }
  //   }
  //   return [] as List[];
  // }

  // public markedOnBoardingCompleted = (accountName: string) => {
  //   const values = this.getOnBoardingAccountNames();
  //   values.push(accountName);
  //   localStorage.setItem(
  //     this.ON_BOARDING_LOCAL_STORAGE_KEY,
  //     JSON.stringify(values)
  //   );
  // };

  // public isOnBoardingCompleted(accountName: string): boolean {
  //   return this.getOnBoardingAccountNames().includes(accountName);
  // }

  // private getOnBoardingAccountNames(): string[] {
  //   if (localStorage.getItem(this.ON_BOARDING_LOCAL_STORAGE_KEY)) {
  //     const jsonValues = JSON.parse(
  //       localStorage.getItem(this.ON_BOARDING_LOCAL_STORAGE_KEY) || ''
  //     );
  //     if (jsonValues) {
  //       return jsonValues;
  //     }
  //   }
  //   return [] as string[];
  // }
}

export const helperInst = new Helper();

export default Helper;
