import {
  List,
  ListItem,
  Checkbox,
  ListDivider,
  Chip,
  Stack,
  Typography,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SearchBar from '../../../../ui/molecules/inputs/SearchBar';
import { SourceStats } from '../../../../ui/organisms/datagrids/LinksGrid/InsightsChart';

interface SourcesListProps {
  sources: SourceStats[];
  selected: any;
  setSelected: (source: any) => void;
}

function SourcesList({ sources, selected, setSelected }: SourcesListProps) {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');

  const [filteredList, setFilteredList] = useState<any[]>([]);

  useEffect(() => {
    const newList = sources
      ?.filter(
        (source: SourceStats) =>
          source?.source?.name?.toLowerCase().includes(query?.toLowerCase()) ||
          selected[source?.source?.id],
      )
      .sort(
        (a: SourceStats, b: SourceStats) =>
          selected[b.source?.id] - selected[a.source?.id],
      );
    if (newList) {
      setFilteredList(newList);
    }
  }, [sources, query, selected]);

  const handleToggleSelectAll = (allSelected: boolean) => {
    const allSources = sources?.reduce(
      (acc: any, source: any) => ({
        ...acc,
        [source?.source?.id]: allSelected,
      }),
      {},
    );
    setSelected(allSources);
  };

  useEffect(() => {
    handleToggleSelectAll(true);
  }, [sources]);

  return (
    <>
      <SearchBar
        value={query}
        onChange={setQuery}
        sx={{
          m: 2,
          mb: 0,
        }}
      />
      <List
        id="sources-list"
        role="group"
        aria-labelledby="sources-list"
        sx={{
          '--ListItem-paddingLeft': '24px',
        }}
      >
        {sources.length ? (
          <ListItem>
            <Checkbox
              size="sm"
              label={t('action.selectAll')}
              sx={{
                color: 'text.tertiary',
                gap: 1,
              }}
              checked={Object.values(selected).every((v) => v)}
              indeterminate={
                Object.values(selected).some((v) => v) &&
                !Object.values(selected).every((v) => v)
              }
              onChange={(e) => handleToggleSelectAll(e.target.checked)}
            />
          </ListItem>
        ) : null}
        <ListDivider />
        {filteredList?.length > 0 ? (
          filteredList?.map((source: SourceStats) => {
            if (!source.source) return null;
            const { id, name } = source.source;
            return (
              <>
                <ListItem key={id}>
                  <Checkbox
                    value={id}
                    size="sm"
                    sx={{
                      alignItems: 'center',
                    }}
                    label={
                      <Chip
                        sx={{
                          backgroundColor: `${source.color}30`,
                          color: `${source.color}`,
                          ml: 1,
                        }}
                      >
                        {name}
                      </Chip>
                    }
                    checked={selected[id]}
                    onChange={(e) =>
                      setSelected({
                        ...selected,
                        [id]: e.target.checked,
                      })
                    }
                  />
                </ListItem>
                <ListDivider />
              </>
            );
          })
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%' }}
          >
            <Typography level="body-md" sx={{ fontWeight: 500 }}>
              No Sources Found
            </Typography>
          </Stack>
        )}
      </List>
    </>
  );
}
export default SourcesList;
