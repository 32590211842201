import React, { useState } from 'react';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/joy';
import { AccountAttribute } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@sakari-io/sakari-components';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import DeleteAttributeDialog from '../../Dialogs/DeleteAttributeDialog';
import { useGetAttributesByTypeQuery } from '../../../../api';
import EmptyResult from '../../../molecules/EmptyResult';
import DataGrid from '../../../molecules/DataGrid';

interface CustomAttributesGridProps {
  filter?: object;
  onSelectedRowChange?: (value: AccountAttribute) => any;
  type: string;
}

function CustomAttributesGrid({
  filter,
  onSelectedRowChange,
  type,
}: CustomAttributesGridProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [removedAttribute, setRemovedAttribute] = useState<AccountAttribute>();

  const columns: GridColDef[] = [
    {
      field: 'label',
      headerName: t('customAttributes.name'),
      headerClassName: 'gridHeaders',
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dataType',
      headerName: t('customAttributes.dataType'),
      headerClassName: 'gridHeaders',
      flex: 1,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 50,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      resizable: false,
      cellClassName: 'actions',
      getActions: (params: GridRowParams) => {
        return [
          <Tooltip title={t('delete')} arrow placement="top">
            <IconButton
              size="smRound"
              onClick={() => {
                setRemovedAttribute(params.row as AccountAttribute);
                setOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <>
      <DataGrid
        columns={columns}
        filter={filter}
        onSelectedRowChange={(row: AccountAttribute) => {
          if (!onSelectedRowChange) {
            return;
          }
          if (row && row.name !== removedAttribute?.name) {
            onSelectedRowChange(row);
          }
        }}
        getRowId={(row) => row.id}
        renderNoData={
          <EmptyResult heading={t('customAttributes.noAttributes')} />
        }
        loadingText={t('customAttributes.loadingAttributes')}
        loadData={useGetAttributesByTypeQuery}
      />
      {removedAttribute ? (
        <DeleteAttributeDialog
          open={open}
          setOpen={setOpen}
          attribute={removedAttribute}
          type={type}
        />
      ) : null}
    </>
  );
}

export default CustomAttributesGrid;
