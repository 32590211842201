import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@mui/joy';
import {
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/pro-regular-svg-icons';
import IconWrapper from './IconWrapper';

interface MuteProps {
  status: boolean | undefined;
  onStatusChanged: any;
}

function Mute({ status, onStatusChanged }: MuteProps) {
  const { t } = useTranslation('voice');
  return (
    <IconWrapper>
      <IconButton
        variant="soft"
        color="voice"
        onClick={() => onStatusChanged(!status)}
      >
        <FontAwesomeIcon
          icon={status ? faMicrophoneSlash : faMicrophone}
          color="white"
        />
      </IconButton>
      <Typography level="body-sm">
        {status ? `${t('unmute')}` : `${t('mute')}`}
      </Typography>
    </IconWrapper>
  );
}

export default Mute;
