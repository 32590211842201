import React, { useContext, useState } from 'react';
import { Group, Sender } from '@sakari-io/sakari-typings';
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';

import { useTranslation } from 'react-i18next';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@mui/joy/Button';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/joy';
import { NumberWithFlag, Logo, Tooltip } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChannelChips from '../../ChipContexts/ChannelChips';
import OverFlowCell from '../../../molecules/OverFlowCell';
import { AccountContext } from '../../../../contexts/account.context';
import StatusChip from '../../ChipContexts/StatusChip';
import EmptyResult from '../../../molecules/EmptyResult';
import {
  useGetPhoneNumbersQuery,
  useLazyRequestPhoneNumberVerificationCodeQuery,
} from '../../../../api';
import DataGrid from '../../../molecules/DataGrid';
import DeletePhoneNumberDialog from './DeletePhoneNumber';
import { getStatus } from '../../../../utils/regulatory';

interface PhoneNumbersGridProps {
  filter?: object;
  onSelectedRowChange?: (sender: Sender) => any;
}

function PhoneNumbersGrid({
  filter,
  onSelectedRowChange,
}: PhoneNumbersGridProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const [selectedId, setSelectedId] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [requestVerificationCode] =
    useLazyRequestPhoneNumberVerificationCodeQuery();

  const cellOverflowSx = {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '.MuiTypography-root': { textOverflow: 'ellipsis' },
  };

  const columns: GridColDef[] = [
    {
      field: 'number',
      headerName: 'Number',
      width: 160,
      resizable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Stack
            sx={{
              columnGap: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box sx={cellOverflowSx}>
              <NumberWithFlag mobile={params.row} size={13} />
            </Box>
          </Stack>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueFormatter: (value: string): string => t(`linetypes.${value}`),
      renderCell: (params: GridCellParams) => {
        return (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={cellOverflowSx}
          >
            <Logo size={30} provider={params.row.provider} />
            <Typography level="body-md">
              {(params.formattedValue || params.value) as React.ReactNode}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'channels',
      headerName: t('channels'),
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <Stack direction="row" spacing={1} sx={cellOverflowSx}>
          <ChannelChips
            value={_.keys(_.pickBy(params.row.channels, (v) => v.active))}
            noLabel
          />
        </Stack>
      ),
    },
    {
      field: 'groups',
      headerName: t('groups.title'),
      width: 200,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <OverFlowCell
            data={params.row.groups}
            containerSx={{
              p: 1,
              ml: -1,
            }}
            renderItem={(group: Group) => (
              <Chip
                key={group.id}
                title={group.name}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/groups/${group.id}`);
                }}
              >
                {group.name}
              </Chip>
            )}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: t('status'),
      width: 100,
      resizable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { status, type } = params.row;
        if (status === 'unverified' && type !== 'senderid') {
          return (
            <Tooltip title="Raise a ticket with Support to verify your number">
              <Button
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={(e) => {
                  e.stopPropagation();
                  if (account) {
                    requestVerificationCode({
                      accountId: account.id,
                      request: params.row.id,
                    })
                      .unwrap()
                      .then(() => {
                        // eslint-disable-next-line no-alert
                        alert('OTP has been requested');
                        // setShowVerify
                      });
                    //TODO missing error handling
                  }
                }}
                disabled
              >
                Verify
              </Button>
            </Tooltip>
          );
        }

        return <StatusChip status={status} />;
      },
    },
    {
      field: 'compliance',
      headerName: 'Compliance',
      width: 130,
      resizable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        const { country, type, channels, provider } = params.row;
        const status = getStatus(
          country,
          type,
          channels.sms.supportedDestinations,
          channels.sms.regulatory,
        );
        if (status && ['sakari'].includes(provider)) {
          if (status === 'rejected') {
            return (
              <Button
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/regulatory/register/${type}`, {
                    state: { sender: params.row },
                  });
                }}
                fullWidth
              >
                {t(`action.resubmit`)}
              </Button>
            );
          }

          if (!['approved', 'verified', 'pending'].includes(status)) {
            return (
              <Button
                size="sm"
                variant="outlined"
                color="neutral"
                disabled={
                  account?.plan?.name === 'Trial' && type !== 'tollfree'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/regulatory/register/${type}`, {
                    state: { sender: params.row },
                  });
                }}
                fullWidth
              >
                {t(`regulatory.actions.register.${type}`)}
              </Button>
            );
          }

          return <StatusChip status={status} />;
        }
        return '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      align: 'right',
      getActions: (params: GridRowParams) => {
        // Conditional on compliance status
        const { type, channels, id } = params.row;
        const regulatoryStatus = channels?.sms?.regulatory?.status;
        const buttons = [
          <Tooltip title={t('delete')} arrow placement="top">
            <IconButton
              size="smRound"
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeleteDialog(true);
                setSelectedId(id);
              }}
            >
              <FontAwesomeIcon icon={regular('trash')} />
            </IconButton>
          </Tooltip>,
        ];
        if (
          (['US', 'CA'].includes(params.row.country) &&
            type === 'local' &&
            regulatoryStatus === 'unregistered') ||
          (['US', 'CA'].includes(params.row.country) &&
            type === 'tollfree' &&
            regulatoryStatus === 'unverified')
        ) {
          buttons.push();
        }

        return buttons;
      },
    },
  ];

  return (
    <>
      <DeletePhoneNumberDialog
        id={selectedId}
        onClose={() => {
          setSelectedId('');
          setOpenDeleteDialog(false);
        }}
        open={openDeleteDialog}
      />
      <DataGrid
        getRowId={(row) => row.id || row.number}
        loadingText={t('senders.loadingSenders')}
        columns={columns}
        filter={filter}
        onSelectedRowChange={onSelectedRowChange}
        renderNoData={
          <EmptyResult item="senders" heading={t('senders.noSenders')} />
        }
        loadData={useGetPhoneNumbersQuery}
      />
    </>
  );
}

export default PhoneNumbersGrid;
