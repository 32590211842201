import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from '@mui/joy';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../molecules/Menu/DropdownMenu';

interface AddContactsDropdownButtonProps
  extends Pick<ButtonProps, 'disabled' | 'size'> {
  onAdd: () => any;
  onUpload: () => any;
}

function AddContactsDropdownButton({
  size = 'md',
  onAdd,
  onUpload,
}: AddContactsDropdownButtonProps) {
  const { t } = useTranslation();

  const handleSelect = (id: string) => {
    if (id === 'import') {
      onUpload();
    } else if (id === 'add-manually') {
      onAdd();
    }
  };

  return (
    <DropdownMenu
      onSelect={(e, item) => handleSelect(item.id || '')}
      items={[
        {
          id: 'import',
          label: `${t('action.import')} ${t('items.csv_one')}`,
        },
        {
          id: 'add-manually',
          label: t('action.addManually'),
        },
      ]}
      button={
        <Button size={size} startDecorator={<FontAwesomeIcon icon={faPlus} />}>
          {`${t('action.add')} ${t('items.contact_other')}`}
        </Button>
      }
    />
  );
}

export default AddContactsDropdownButton;
