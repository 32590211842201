import React from 'react';
import { Group } from '@sakari-io/sakari-typings';
import GroupSelect from '../../forms/GroupSelect';

interface GroupSelectStyledProps {
  account: any;
  value?: Group;
  onChange: (val: Group) => any;
}

function GroupSelectStyled({
  account,
  value,
  onChange,
}: GroupSelectStyledProps) {
  return (
    <GroupSelect
      accountId={account?.id || ''}
      value={value}
      size="sm"
      onChange={(group) => onChange(group)}
      disabled={!value?.senders?.length}
      collapsedStyle={{
        minWidth: '0',
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '55px',
        color: 'white',
        border: '1px solid rgba(255, 255, 255, 0.20)',
        '&:hover, &:active, &:focus': {
          color: 'white',
          background: 'rgba(255, 255, 255, 0.15)',
          borderColor: 'rgba(255, 255, 255, 0.30)',
        },
      }}
      placement="bottom"
      expandedStyle={{
        color: 'white',
        background: 'rgb(25, 46, 84)',
        border: '1px solid rgba(255, 255, 255, 0.20)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

        '#loading-box': {
          filter: 'invert(1) brightness(200) opacity(0.95)',
        },
        '.MuiInput-root': {
          color: 'white',
          backgroundColor: 'var(--joy-palette-voice-softBg)',
          border: 'none',
          '&:hover, &:active, &:focus, &:focus-within': {
            color: 'white',
            border: 'none',
          },
        },
        '.MuiMenuList-root:hover::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.25)',
        },
        '.MuiMenuItem-root': {
          color: 'white',
          '&:not(.Mui-selected):hover': {
            color: 'white',
            background: 'rgba(255, 255, 255, 0.05)',
          },
        },
        '.Mui-disabled': {
          backgroundColor: 'transparent',
          opacity: 0.4,
        },
      }}
    />
  );
}

export default GroupSelectStyled;
