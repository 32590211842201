import React from 'react';
import CloseAccount from './CloseAccount';
import PlansSelect from './PlansSelect';
import RecurringItems from './RecurringItems';

function Plans() {
  return (
    <>
      {/* Plans Select */}
      <PlansSelect />
      {/* Recurring Items */}
      <RecurringItems />
      {/* Available Items TBD */}
      <CloseAccount />
    </>
  );
}
export default Plans;
