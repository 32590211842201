import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/joy/Button';
import { Group, Role } from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import Setting from '../../../templates/forms/SettingForm';
import { useSyncUsersMutation } from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import GroupSelect from '../GroupSelect';
import Select from '../../../atoms/inputs/Select';
import { AccountContext } from '../../../../contexts/account.context';
import Helper from '../../../../utils/helper';

interface UserSyncProps {
  value: {
    group: Group;
    role: Role; // 'Administrator' | 'CampaignManager' | 'Sender' | 'ReadOnly';
    lastRun?: string;
  };
  onChange: (value: { group: Group; role: string } | undefined | false) => any;
}

function UserSync({ value, onChange }: UserSyncProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);

  const [syncUsers, { isLoading }] = useSyncUsersMutation({});

  logger.info('userSync', value);

  const roles = [
    {
      label: t('roles.administrator'),
      value: 'Administrator',
    },
    {
      label: t('roles.campaignmanager'),
      value: 'CampaignManager',
    },
    {
      label: t('roles.sender'),
      value: 'Sender',
    },
    {
      label: t('roles.readonly'),
      value: 'ReadOnly',
    },
  ];

  const handleSync = async () => {
    try {
      await syncUsers({
        data: { group: value.group.id, role: value.role },
        type: 'hubspot',
      })
        .unwrap()
        .then((res) => {
          dispatch(
            showToast({
              severity: 'info',
              message: `${res.data.added} ${t(
                'integrations.hubspot.userSync.usersAdded',
              )}`,
            }),
          );
        });
    } catch (e) {
      dispatch(
        showToast({
          severity: 'error',
          message: t('users.invite.error'),
        }),
      );
    }
  };

  return (
    <>
      <Setting
        label={t('integrations.hubspot.userSync.defaultUserRole.label')}
        description={t(
          'integrations.hubspot.userSync.defaultUserRole.description',
        )}
        spacer="0px 30px 0px 0px"
        required
      >
        <Select
          options={roles}
          fullWidth
          value={value.role}
          onChange={(evt) => {
            onChange({ group: value.group, role: evt });
          }}
          placeholder={t('selectRole')}
        />
      </Setting>

      <Setting
        label={t('integrations.hubspot.userSync.defaultUserGroup.label')}
        description={t(
          'integrations.hubspot.userSync.defaultUserGroup.description',
        )}
        spacer="0px 30px 0px 0px"
        required
      >
        <GroupSelect
          accountId={account?.id || ''}
          value={value.group}
          onChange={(group: Group) => onChange({ group, role: value.role })}
          fullWidth
        />
      </Setting>

      <Setting
        label={value.lastRun ? 'Last synced on' : ' '}
        description={
          value.lastRun
            ? Helper.getLocalizedDateTime(value.lastRun, {
                dateStyle: 'long',
                timeStyle: 'short',
              })
            : undefined
        }
        spacer="0px 30px 0px 0px"
      >
        <Button
          onClick={handleSync}
          disabled={!value.group || !value.role || isLoading}
        >
          {t('integrations.hubspot.userSync.sync')}
        </Button>
      </Setting>
    </>
  );
}

export default UserSync;
