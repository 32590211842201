import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from '@mui/joy';
import React from 'react';
import { Tooltip } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Helper from '../../../utils/helper';

const renderHelperText = (helperText: any) => {
  if (typeof helperText === 'string') {
    return helperText;
  }
  if (typeof helperText === 'object' || helperText?.message) {
    return Helper.getAllFirstErrors(helperText).join(', ');
  }
  return helperText[0];
};

export interface InputControlProps extends FormControlProps {
  label?: string | React.ReactNode;
  helperText?: any;
  labelInfo?: string;
  fullWidth?: boolean;
  error?: boolean;
  noGap?: boolean;
}

function InputControl({
  label,
  helperText,
  children,
  sx,
  fullWidth,
  error,
  labelInfo,
  noGap,
  ...rest
}: InputControlProps): JSX.Element {
  const isHorizontal = rest.orientation === 'horizontal';
  const labelHorizontalMargin = {
    sm: '0.25rem',
    md: '0.50rem',
    lg: '0.75rem',
  };

  return (
    <FormControl
      sx={{
        gap: noGap ? 0 : 1,
        '.MuiFormLabel-root': {
          justifyContent: 'flex-start',
          fontWeight: 'var(--joy-fontWeight-lg)',
          alignItems: 'center',
          ...(isHorizontal && {
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            verticalAlign: 'middle',
            pt: `${labelHorizontalMargin[rest.size || 'md']} !important`,
          }),
        },
        width: fullWidth ? '100%' : 'auto',
        ...sx,
      }}
      error={error}
      {...rest}
    >
      {!!label && (
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1,
            alignContent: 'center',
            overflow: 'hidden',
          }}
        >
          <FormLabel
            component={Typography}
            title={typeof label === 'string' ? label : undefined}
            endDecorator={
              labelInfo && (
                <Tooltip
                  size={rest.size || 'md'}
                  title={labelInfo}
                  placement="top"
                  variant="outlined"
                  arrow
                  sx={{
                    maxWidth: '20rem',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{
                      color: 'var(--joy-palette-neutral-300)',
                    }}
                  />
                </Tooltip>
              )
            }
          >
            <Typography fontSize="13.5px" fontWeight={500}>
              {label}
            </Typography>
          </FormLabel>
        </Stack>
      )}
      <Stack sx={{ flexDirection: 'column', gap: 1, flex: 2 }}>
        {children}
        {helperText && (
          <FormHelperText
            sx={{ color: error ? 'var(--joy-palette-danger-400)' : '' }}
          >
            {renderHelperText(helperText)}
          </FormHelperText>
        )}
      </Stack>
    </FormControl>
  );
}
export default InputControl;
