import { logger } from '@sakari-io/sakari-components';
import { Group } from '@sakari-io/sakari-typings';

const isCountrySupported = (group: Group, country: string) => {
  // logger.info('group', JSON.stringify(group, null, 2));
  // logger.info('country', country);
  const sender = group.senders?.find((s) => {
    const result = s.channels?.sms?.supportedDestinations?.[country];
    logger.info(
      'result',
      result,
      s.channels?.sms?.supportedDestinations?.[country],
    );
    return result;
  });

  return !!sender;
};

export default isCountrySupported;
