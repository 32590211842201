import React, { useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Template } from '@sakari-io/sakari-typings';
import {
  useDeleteTemplateMutation,
  useGetTemplatesQuery,
} from '../../../../api';
import OverlayPopup from '../../../templates/OverlayPopup';
import SearchableMenu from '../../../molecules/Menu/SearchableMenu';
import { AccountContext } from '../../../../contexts/account.context';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import SaveTemplate from '../../forms/Composer/Template/Save';

interface TemplateMenuButtonProps {
  onSelect: (value: Template) => void;
  disabled?: boolean;
  message?: string;
}

function LabelComponent({ item }: { item: Template }) {
  return (
    <Typography
      title={item.name}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {item.name}
    </Typography>
  );
}

function ActionIconComponent({
  item,
  handleOpenDialog,
  handleDelete,
  setOpenMenu,
  t,
}: {
  item: Template;
  handleOpenDialog: (item: Template) => void;
  handleDelete: (item: Template) => void;
  setOpenMenu: (open: boolean) => void;
  t: (key: string) => string;
}) {
  return (
    <Stack
      sx={{
        position: 'relative',
        gap: '2px',
        flexDirection: 'row',
      }}
    >
      <IconButton
        size="smRound"
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDialog(item);
          setOpenMenu(false);
        }}
      >
        <Tooltip title={t('action.edit')} placement="top-start">
          <FontAwesomeIcon icon={solid('pencil')} />
        </Tooltip>
      </IconButton>
      <IconButton
        size="smRound"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(item);
          setOpenMenu(false);
        }}
      >
        <Tooltip title={t('action.delete')} placement="top-start">
          <FontAwesomeIcon icon={solid('trash-can')} />
        </Tooltip>
      </IconButton>
    </Stack>
  );
}

function TemplateMenuButton({
  onSelect,
  disabled,
  message,
}: TemplateMenuButtonProps) {
  const { t } = useTranslation();

  const getLabel = useCallback(
    (item: Template) => <LabelComponent item={item} />,
    [t],
  );

  const [deleteTemplate] = useDeleteTemplateMutation();
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);

  const [query, setQuery] = useState<string>('');

  const { data } = useGetTemplatesQuery(
    account
      ? {
          accountId: account.id,
          request: {
            type: 'sms',
            name: query,
            limit: 20,
            orderBy: 'createdAt-desc',
          },
        }
      : skipToken,
  );

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleOpenDialog = (template?: Template) => {
    setOpenMenu(false);
    setDialogOpen(true);
    setSelectedTemplate(
      template ?? {
        name: '',
        template: message,
        type: 'sms',
      },
    );
  };

  const handleSelect = (item: Template) => {
    onSelect(item);
    setOpenMenu(false);
  };

  const handleDelete = (item: any) => {
    deleteTemplate(item.id)
      .unwrap()
      .then(() => {
        dispatch(
          showToast({
            severity: 'success',
            message: t('template.deletedTemplateMessage'),
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data?.error?.message,
          }),
        );
      });
  };

  const renderActionIcon = useCallback(
    (item: Template) => (
      <ActionIconComponent
        item={item}
        handleOpenDialog={handleOpenDialog}
        handleDelete={handleDelete}
        setOpenMenu={setOpenMenu}
        t={t}
      />
    ),
    [handleOpenDialog, handleDelete, t],
  );

  return (
    <>
      <OverlayPopup
        open={openMenu}
        onOpenClose={setOpenMenu}
        placement="top-start"
        renderButton={(setAnchorEl, onClick) => (
          <Tooltip title={t('templates')} size="sm">
            <IconButton ref={setAnchorEl} onClick={onClick} disabled={disabled}>
              <FontAwesomeIcon icon={regular('objects-column')} />
            </IconButton>
          </Tooltip>
        )}
        renderPopup={() => (
          <SearchableMenu
            variant="outlined"
            onQueryChanged={setQuery}
            onSelect={handleSelect}
            items={data?.data || []}
            noOptionsText={t('No templates found')}
            getLabel={getLabel}
            actionIcon={renderActionIcon}
            footer={
              <Button onClick={() => handleOpenDialog()} fullWidth>
                {t('action.create')}
              </Button>
            }
            sx={{
              maxHeight: 332,
              width: 250,
            }}
          />
        )}
      />

      <SaveTemplate
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onChange={handleSelect}
        value={selectedTemplate}
      />
    </>
  );
}

export default TemplateMenuButton;
