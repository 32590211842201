import { faSitemap } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Option, Typography } from '@mui/joy';
import React from 'react';
import { WorkflowProperty } from '@sakari-io/sakari-typings';

interface WorkflowPropertyItemProps {
  value: WorkflowProperty;
}

function WorkflowPropertyItem({ value }: WorkflowPropertyItemProps) {
  return (
    <Option value={value.name} label={value.name}>
      <FontAwesomeIcon icon={faSitemap} />
      <Typography>{value.name}</Typography>
      <Typography
        level="body-sm"
        sx={{ color: 'var(--joy-palette-neutral-500)' }}
      >
        {value?.dataType}
      </Typography>
    </Option>
  );
}

export default WorkflowPropertyItem;
