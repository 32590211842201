import _ from 'lodash';
import Stack from '@mui/joy/Stack';
import { useTranslation } from 'react-i18next';
import { Schedule as ScheduleType } from '@sakari-io/sakari-typings';
import React, { useState } from 'react';
import { logger, DateTimePicker } from '@sakari-io/sakari-components';
import Radios from '../../../../ui/molecules/Radios';
import Checkboxes, {
  CheckboxData,
} from '../../../../ui/molecules/inputs/Checkboxes';

import SimpleTime from '../../../../ui/organisms/forms/SimpleTime';
import TimezoneAutoComplete from '../../../../ui/organisms/forms/TimezoneAutoComplete';

import Select from '../../../../ui/atoms/inputs/Select';

const cronToDate = (cronParts?: CronParts) => {
  if (cronParts?.frequency === 'O' && !cronParts?.year) {
    return new Date();
  }
  return new Date(
    cronParts?.year || 0,
    cronParts?.month || 0,
    cronParts?.dayOfMonth || 0,
    cronParts?.hour || 0,
    cronParts?.minute || 0,
  );
};

const FREQUENCIES: CheckboxData[] = [
  {
    label: 'Once',
    value: 'O',
  },
  {
    label: 'Daily',
    value: 'D',
  },
  {
    label: 'Monthly',
    value: 'M',
  },
  {
    label: 'On Demand',
    value: 'OD',
  },
];

const WEEK: CheckboxData[] = [
  {
    label: 'Sunday',
    value: '0',
  },
  {
    label: 'Monday',
    value: '1',
  },
  {
    label: 'Tuesday',
    value: '2',
  },
  {
    label: 'Wednesday',
    value: '3',
  },
  {
    label: 'Thursday',
    value: '4',
  },
  {
    label: 'Friday',
    value: '5',
  },
  {
    label: 'Saturday',
    value: '6',
  },
];

const getCronExpression = (cronParts: CronParts) => {
  if (!cronParts) {
    return undefined;
  }
  // logger.info('getCronExpression', cronParts);

  switch (cronParts.frequency) {
    case 'O':
      return `${cronParts.minute || 0} ${cronParts.hour || 0} ${
        cronParts.dayOfMonth
      } ${cronParts.month} ? ${cronParts.year}`;
    case 'D':
      return `${cronParts.minute || 0} ${cronParts.hour || 0} * * ${(
        cronParts.daysOfWeek || []
      ).join(',')}`;
    case 'M':
      return `${cronParts.minute || 0} ${cronParts.hour || 0} ${
        cronParts.dayOfMonth
      } * *`;
    case 'OD':
      return undefined;
    default:
      throw new Error('Invalid schedule frequency');
  }
};

const getDayOfMonthSuffix = (i: number) => {
  switch (i) {
    case 1:
    case 21:
    case 31:
      return 'st';
    case 2:
    case 22:
      return 'nd';
    case 3:
    case 23:
      return 'rd';
    default:
      return 'th';
  }
};

/*
1. Minute
2. Hour
3. Day of month
4. month
5. day of week
6. year
*/

interface CronParts {
  frequency: 'OD' | 'O' | 'D' | 'M';
  timezone?: string;
  minute?: number;
  hour?: number;
  dayOfMonth?: number;
  month?: number;
  daysOfWeek?: number[];
  year?: number;
}

const getCronParts = (schedule?: ScheduleType): CronParts => {
  if (!schedule) {
    return {
      frequency: 'OD',
    };
  }

  const parts = (schedule.cron || '').split(' ');

  logger.info('getCronParts', schedule.cron, parts);

  return {
    frequency: schedule.frequency,
    timezone: schedule.timezone,
    minute: Number(parts[0]),
    hour: Number(parts[1]),
    dayOfMonth: Number(parts[2]),
    month: Number(parts[3]),
    daysOfWeek: !['*', '?'].includes(parts[4])
      ? (parts[4] || '').split(',').map((n) => Number(n))
      : undefined,
    year: parts[5] ? Number(parts[5]) : undefined,
  };
};

interface ScheduleProps {
  value?: ScheduleType;
  onChange: (value: ScheduleType) => any;
}

function Schedule({ value, onChange }: ScheduleProps) {
  const { t } = useTranslation();

  const [cronParts, setCronParts] = useState(getCronParts(value));

  const handleChange = (value: {
    [key: string]: string | number | number[];
  }) => {
    const result: CronParts = _.assign({}, cronParts, value);
    setCronParts(result);
    const cron = getCronExpression(result);
    onChange({
      frequency: result.frequency,
      timezone: result.timezone,
      cron,
    });
  };

  const now = new Date();

  return (
    <Stack spacing={1} className="schedule">
      <div>
        <div className="inline">
          <Radios
            label={`${t('frequency')} (required)`}
            value={cronParts?.frequency || ''}
            onChange={(frequency: string) => handleChange({ frequency })}
            items={FREQUENCIES}
          />
        </div>
      </div>
      {['M', 'O', 'D'].includes(cronParts?.frequency) ? (
        <TimezoneAutoComplete
          label="Timezone"
          value={cronParts.timezone}
          onChange={(tz) => {
            if (tz) {
              handleChange({ timezone: tz });
            }
          }}
        />
      ) : null}
      {cronParts?.frequency === 'O' ? (
        <DateTimePicker
          minDateTime={now}
          value={cronToDate(cronParts)}
          onChange={(date: Date | null) =>
            date &&
            handleChange({
              year: date?.getFullYear(),
              month: date?.getMonth(),
              dayOfMonth: date?.getDate(),
              hour: date?.getHours(),
              minute: date?.getMinutes(),
            })
          }
        />
      ) : null}
      {cronParts?.frequency === 'D' ? (
        <div>
          <Checkboxes
            label={`${t('daysOfWeek')} (required)`}
            size="sm"
            value={(cronParts.daysOfWeek || []).reduce(
              (res, d: number) => {
                res[d] = true;
                return res;
              },
              {} as { [key: string]: boolean },
            )}
            onChange={(days) => {
              const daysOfWeek = _.keys(_.pickBy(days)).map((d) => Number(d));
              handleChange({
                daysOfWeek,
              });
            }}
            items={WEEK}
          />
        </div>
      ) : null}

      {cronParts?.frequency === 'M' ? (
        <Select
          // label="Day Of Month"
          value={cronParts.dayOfMonth}
          onChange={(newValue) => {
            handleChange({ dayOfMonth: newValue || 1 });
          }}
          slotProps={{
            listbox: {
              sx: {
                maxHeight: '150px',
                overflowY: 'overlay',
              },
            },
          }}
          options={_.range(1, 28).map((i) => ({
            label: `${i}${getDayOfMonthSuffix(i)}`,
            value: i,
          }))}
        />
      ) : null}

      {['M', 'D'].includes(cronParts?.frequency) ? (
        <SimpleTime
          label="Time"
          value={{ hour: cronParts.hour || 0, minute: cronParts.minute || 0 }}
          onChange={({ hour, minute }) => {
            // logger.info('time', hour, minute);
            handleChange({ hour, minute });
          }}
        />
      ) : null}
    </Stack>
  );
}

export default Schedule;
