import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PhoneStatus } from '../../types';

interface CallsState {
  phoneStatus: PhoneStatus;
}

const initialState: CallsState = {
  phoneStatus: PhoneStatus.NONE,
};

export const callsSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    // phone widget
    displayPhoneComponent: (state, action: PayloadAction<PhoneStatus>) => {
      state.phoneStatus = action.payload;
    },
  },
});

export const { displayPhoneComponent } = callsSlice.actions;

export default callsSlice.reducer;
