import React, { useState, useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Typography, Stack, MenuList } from '@mui/joy';
import { WorkflowProperty } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logger } from '@sakari-io/sakari-components';
import OverlayPopup from '../../../../ui/templates/OverlayPopup';
import SearchableMenu from '../../../../ui/molecules/Menu/SearchableMenu';
import { useGetAttributesByTypeQuery } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import SimpleDrawerHeader from '../../../../ui/molecules/SimpleDrawerHeader';
import PropertyTypeMenu from './PropertyTypeMenu';
import { iconMap, labelMap } from './utils';
import HoverMenuItem from './HoverMenuItem';

interface PropertiesMenuProps {
  open: boolean;
  onClose: () => void;
  inputRef: any;
  //need to add dataType option
  onChange: (value: any) => void;
  properties: WorkflowProperty[];
  fullWidth?: boolean;
  disabledOptions?: string[];
}

function PropertiesMenu({
  open,
  onClose,
  inputRef,
  onChange,
  properties,
  fullWidth = true,
  disabledOptions,
}: PropertiesMenuProps) {
  const { account } = useContext(AccountContext);

  const [menuType, setMenuType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const { data: attributes, isLoading } = useGetAttributesByTypeQuery(
    account
      ? {
          accountId: account.id,
          request: {
            type: 'contacts',
            limit: 5,
            q: searchTerm,
          },
        }
      : skipToken,
  );

  const contactProperties = [
    {
      label: 'First Name',
      value: 'firstName',
      dataType: 'string',
    },
    {
      label: 'Last Name',
      value: 'lastName',
      dataType: 'string',
    },
    {
      label: 'Email',
      value: 'email',
      dataType: 'string',
    },
    {
      label: 'Mobile',
      value: 'mobile',
      dataType: 'string',
    },
    ...(attributes?.data?.map((c: any) => {
      return {
        label: c.label,
        value: c.name,
        dataType: c.dataType,
      };
    }) ?? []),
  ];

  const handleClose = () => {
    onClose();
    setMenuType('');
  };

  const handleSelectType = (type: any) => {
    setMenuType(type);

    if (type === 'output') {
      handleClose();
    }
  };

  const renderOptions = () => {
    if (menuType === 'contact') {
      return contactProperties;
    }

    return (properties || []).map((property) => ({
      value: property.name,
      label: property.name,
      dataType: property.dataType,
    }));
  };

  const renderFilteredOptions = () => {
    if (!searchTerm) {
      return renderOptions();
    }

    return renderOptions().filter((option) => {
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  const renderLabel = (item: any) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {menuType && (
          <FontAwesomeIcon
            icon={iconMap[menuType].icon}
            style={{ color: iconMap[menuType].color }}
          />
        )}
        <Typography>{item.label}</Typography>
      </Stack>
    );
  };

  return (
    <>
      <PropertyTypeMenu
        onSelectType={handleSelectType}
        inputRef={inputRef}
        open={open && !menuType}
        onClose={handleClose}
        onChange={onChange}
        properties={properties}
        fullWidth={fullWidth}
      />

      {menuType && (
        <OverlayPopup
          buttonRef={inputRef}
          fullWidth={fullWidth}
          open={open}
          onOpenClose={handleClose}
          renderPopup={() => (
            <SearchableMenu
              ref={inputRef}
              isLoading={isLoading}
              onQueryChanged={(newVal) => {
                setSearchTerm(newVal);
              }}
              onSelect={logger.info}
              getLabel={(item) => renderLabel(item)}
              header={
                <SimpleDrawerHeader
                  hideClose
                  backAction={() => setMenuType('')}
                  title={
                    <Typography
                      sx={{ color: 'var(--joy-palette-primary-500)' }}
                    >
                      {labelMap[menuType]}
                    </Typography>
                  }
                  sx={{
                    padding: 0,
                  }}
                />
              }
            >
              <MenuList sx={{ border: 'none' }}>
                {renderFilteredOptions()?.map((option) => (
                  <HoverMenuItem
                    key={option?.label}
                    icon={iconMap[menuType]?.icon}
                    color={iconMap[menuType]?.color}
                    label={option.label}
                    disabled={disabledOptions?.includes(
                      option.label.toLowerCase(),
                    )}
                    onSelect={() => {
                      onChange(
                        menuType !== 'workflow'
                          ? {
                              name: option?.label,
                              nodeId: 'trigger',
                              path: `${menuType}.${option.value}`,
                              type: 'output',
                              dataType: option.dataType,
                            }
                          : {
                              name: option?.value,
                              type: 'workflow',
                            },
                      );
                      handleClose();
                    }}
                  />
                ))}
              </MenuList>
            </SearchableMenu>
          )}
        />
      )}
    </>
  );
}

export default PropertiesMenu;
