import React, { useState } from 'react';
import { PhoneNumber } from '@sakari-io/sakari-typings';
import SmsChannelDetails from './SmsChannelDetails';
import VoiceChannelDetails from './VoiceChannelDetails';
import { TabbedNavigation } from '../../../../ui';

interface ChannelDetailsProps {
  sender?: PhoneNumber;
}

function ChannelDetails({ sender }: ChannelDetailsProps) {
  const [selected, setSelected] = useState(0);

  return (
    <TabbedNavigation
      sx={{
        '.MuiTabList-root': {
          '--ListItem-paddingX': '1rem',
        },
        borderRadius: 'inherit',
      }}
      tabs={[
        {
          label: '💬 SMS',
          value: 0,
          content: <SmsChannelDetails sender={sender} />,
          hidden: !sender?.channels?.sms,
        },
        {
          label: '📞 Voice',
          value: 1,
          content: <VoiceChannelDetails sender={sender} />,
          hidden: !sender?.channels?.voice,
        },
      ]}
      selectedTab={selected}
      onTabSelected={setSelected}
      divider
      horizontalPadding={0}
    />
  );
}

export default ChannelDetails;
