import {
  List,
  SakariAPIResponse,
  SearchablePaginationRequest,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';
import { getAccountId, buildQuery } from './common';
import { CONTACT_TAG, LIST_TAG } from './tags';
import { AccountIdWith } from '../types';
import { extendedApi as contactsApi } from './contacts';

export const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getLists: builder.query<
      SakariAPIResponse<List[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/lists?${buildQuery(request)}`,
      providesTags: [
        LIST_TAG,
        {
          type: LIST_TAG,
          id: 'LIST',
        },
      ],
    }),
    createList: builder.mutation<SakariAPIResponse<List>, Partial<List>>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/lists`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [LIST_TAG],
      async onQueryStarted(list, { dispatch, queryFulfilled, getState }) {
        if (!list.name) return;

        let latestQueryArgs: any = null;

        await extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: LIST_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getLists') {
              if (
                !latestQueryArgs ||
                originalArgs.request.offset > latestQueryArgs.request.offset
              ) {
                latestQueryArgs = originalArgs;
              }
            }
          });

        if (!latestQueryArgs) return;

        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getLists',
            latestQueryArgs,
            (draft) => {
              draft.data = [
                ...draft.data,
                { id: 'new', name: list.name || '' },
              ];
            },
          ),
        );
        try {
          await queryFulfilled;
          // patchResult.undo();
        } catch {
          patchResult.undo();
        }
      },
    }),
    getList: builder.query<SakariAPIResponse<List>, AccountIdWith<string>>({
      query: ({ accountId, request: id }) =>
        `accounts/${accountId}/lists/${id}`,
      providesTags: (res, err, args) => [
        LIST_TAG,
        { type: LIST_TAG, id: args.request },
      ],
    }),
    updateList: builder.mutation<SakariAPIResponse<List>, Partial<List>>({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/lists/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (res, err, args) => [
        LIST_TAG,
        { type: LIST_TAG, id: args.id },
      ],
      async onQueryStarted(
        { id, ...data },
        { dispatch, queryFulfilled, getState },
      ) {
        if (!id) return;
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getList',
            { accountId: getAccountId() || '', request: id },
            (draft) => {
              draft.data = Object.assign(draft.data, data);
            },
          ),
        );

        let patchResultList: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: LIST_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getLists') {
              patchResultList = dispatch(
                extendedApi.util.updateQueryData(
                  'getLists',
                  originalArgs,
                  (draft) => {
                    const index = draft.data.findIndex((c) => c.id === id);

                    if (index >= 0) {
                      draft.data[index] = Object.assign(
                        draft.data[index],
                        data,
                      );
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchResultList?.undo();
        }
      },
    }),
    deleteList: builder.mutation<any, any>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/lists/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, err, args) => [
        LIST_TAG,
        { type: LIST_TAG, id: 'LIST' },
        {
          type: LIST_TAG,
          id: args,
        },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: LIST_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getLists') {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getLists',
                  originalArgs,
                  (draft) => {
                    const index = draft.data.findIndex((c) => c.id === id);

                    if (index >= 0) {
                      draft.data.splice(index, 1);
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    // No optimistic update needed as this occurs in the Contacts Tab not the Lists Tab
    addContactsToList: builder.mutation<SakariAPIResponse<any>, any>({
      query: ({ listId, filter }) => ({
        url: `accounts/${getAccountId()}/bulk/contacts/listadd?listId=${listId}`,
        method: 'POST',
        body: filter,
      }),
      invalidatesTags: (res, err, args) => [
        LIST_TAG,
        CONTACT_TAG,
        {
          type: LIST_TAG,
          id: args.listId,
        },
      ],
      async onQueryStarted(
        { listId, filter },
        { dispatch, queryFulfilled, getState },
      ) {
        const ids = filter.attributes?.[0].values;
        if (!ids) return;
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: LIST_TAG, id: listId }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getContacts') {
              patchResult = dispatch(
                contactsApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    for (const id of ids) {
                      dispatch(
                        contactsApi.endpoints.getContact.initiate({
                          accountId: originalArgs.accountId,
                          request: id,
                        }),
                      )
                        .unwrap()
                        .then((res) => {
                          if (res.data) {
                            draft.data.push(res.data);
                          }
                        });
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    removeContactsFromList: builder.mutation<SakariAPIResponse<any>, any>({
      query: ({ listId, filter }) => ({
        url: `accounts/${getAccountId()}/bulk/contacts/listremove?listId=${listId}`,
        method: 'POST',
        body: filter,
      }),
      invalidatesTags: (res, err, args) => [
        LIST_TAG,
        CONTACT_TAG,
        {
          type: LIST_TAG,
          id: args.listId,
        },
      ],
      async onQueryStarted(
        { listId, filter },
        { dispatch, queryFulfilled, getState },
      ) {
        const ids = filter.attributes?.[0].values;
        if (!ids) return;
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: LIST_TAG, id: listId }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getContacts') {
              patchResult = dispatch(
                contactsApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    for (const id of ids) {
                      const index = draft.data.findIndex((c) => c.id === id);
                      if (index >= 0) {
                        draft.data.splice(index, 1);
                      }
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetListsQuery,
  useCreateListMutation,
  useGetListQuery,
  useUpdateListMutation,
  useDeleteListMutation,
  useAddContactsToListMutation,
  useRemoveContactsFromListMutation,
} = extendedApi;
