import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Sheet,
  SheetProps,
  Stack,
  Table,
  TableProps as TableBaseProps,
  Tooltip,
} from '@mui/joy';
import _ from 'lodash';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { Loader } from '@sakari-io/sakari-components';
import EmptyResult from '../EmptyResult';

export interface Column {
  header: string;
  field: string;
  renderCell?: (row: any) => React.ReactNode;
  noData?: React.ReactNode | string;
  cellProps?: any;
  renderHeader?: (header: string) => React.ReactNode;
  headerProps?: any;
}

interface TableProps extends TableBaseProps {
  columns: Column[];
  rows: any[];
  showFooter?: boolean;
  useQuery?: UseQuery<any>;
  page?: number;
  onPageChanged?: (page: number) => void;
  sheetProps?: SheetProps;
  showRowSelection?: boolean;
  tableHeader?: React.ReactNode;
  hasMore?: boolean;
  onSelectRow?: (row: any) => void;
  isRowSelected?: (row: any) => boolean;
  onRowsPerPageChanged?: (rowsPerPage: number) => void;
  loading?: boolean;
  noData?: React.ReactNode | string;
}

function DataTable({
  tableHeader,
  columns,
  rows,
  showFooter = false,
  useQuery,
  page,
  onPageChanged,
  sheetProps,
  showRowSelection = false,
  hasMore,
  onSelectRow,
  isRowSelected,
  onRowsPerPageChanged,
  loading,
  noData,
  children,
  ...rest
}: TableProps) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (onPageChanged) {
      onPageChanged(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (onRowsPerPageChanged) {
      onRowsPerPageChanged(rowsPerPage);
    }
  }, [rowsPerPage]);

  const handlePageChange = (action: 'next' | 'back') => {
    setCurrentPage((prev) => (action === 'next' ? prev + 1 : prev - 1));
  };

  const renderCell = (
    column: Column,
    row: { [key: string]: any },
    index: number,
  ) => {
    const cellData = row[column.field];
    const { noData, renderCell: renderColumnCell, cellProps } = column;
    const cellKey = `${column.field}-${index}`;
    if (renderColumnCell) {
      return <td {...cellProps}>{renderColumnCell(row)}</td>;
    }

    let renderedCell = cellData;

    switch (typeof cellData) {
      case 'object':
        renderedCell = cellData.label
          ? cellData.label
          : JSON.stringify(cellData);
        break;
      case 'string':
        renderedCell = cellData;
        break;
      case 'number':
        renderedCell = cellData?.toLocaleString() || '0';
        break;
      case 'boolean':
        renderedCell = cellData ? t('yes') : t('no');
        break;
      default:
        renderedCell = noData || 'Error: No data available';
        break;
    }

    return (
      <td key={cellKey} {...cellProps}>
        {renderedCell}
      </td>
    );
  };

  const renderHeader = (column: Column) => {
    const { renderHeader: renderColHeader, headerProps } = column;
    if (renderColHeader) {
      return (
        <th key={column.field} {...headerProps}>
          {renderColHeader(column.header)}
        </th>
      );
    }
    return (
      <th key={column.field} {...headerProps}>
        {column.header}
      </th>
    );
  };

  return (
    <Sheet
      {...{
        variant: 'outlined',
        sx: {
          p: 0,
          borderRadius: 'var(--joy-radius-md)',
        },
        ...sheetProps,
      }}
    >
      <Table {...rest}>
        {tableHeader && <caption>{tableHeader}</caption>}
        <thead>
          <tr>{columns.map((column) => renderHeader(column))}</tr>
        </thead>
        <tbody>
          {loading ? (
            <Stack minHeight={200} justifyContent="center" alignItems="center">
              <Loader size={100} />
            </Stack>
          ) : (
            rows.map((row, index) => (
              <tr
                key={index}
                onClick={() => onSelectRow && onSelectRow(row)}
                aria-selected={isRowSelected && isRowSelected(row)}
              >
                {columns.map((column) => renderCell(column, row, index))}
              </tr>
            ))
          )}
          {rows.length === 0 ? (
            <tr>
              <td
                colSpan={columns.length}
                style={{
                  pointerEvents: 'none',
                  height: 200,
                }}
              >
                {noData || <EmptyResult />}
              </td>
            </tr>
          ) : (
            rows.length < rowsPerPage &&
            _.range(rowsPerPage - rows.length).map((i) => (
              <tr key={i}>
                <td
                  colSpan={columns.length}
                  style={{
                    pointerEvents: 'none',
                  }}
                />
              </tr>
            ))
          )}
        </tbody>
        {showFooter && (
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <Stack
                  sx={{
                    flexFlow: 'row nowrap',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 2,
                  }}
                >
                  {showRowSelection && (
                    <FormControl orientation="horizontal">
                      <FormLabel>rows per page</FormLabel>
                      <Select
                        sx={{
                          width: '5em',
                        }}
                        value={rowsPerPage}
                        onChange={(e, value) => setRowsPerPage(value as number)}
                      >
                        {[5, 10, 15, 20].map((value) => (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <Tooltip title="Previous page">
                    <IconButton
                      size={rest.size || 'md'}
                      variant="outlined"
                      disabled={currentPage === 0}
                      onClick={() => handlePageChange('back')}
                    >
                      <FontAwesomeIcon icon={regular('chevron-left')} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Next page">
                    <IconButton
                      size={rest.size || 'md'}
                      variant="outlined"
                      disabled={!hasMore}
                      onClick={() => handlePageChange('next')}
                    >
                      <FontAwesomeIcon icon={regular('chevron-right')} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </td>
            </tr>
          </tfoot>
        )}
        {children}
      </Table>
    </Sheet>
  );
}
export default DataTable;
