import React from 'react';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { ContactUpdate } from '@sakari-io/sakari-typings';
import PropertyMultipleWrapper from '../PropertyMultipleWrapper';
import PropertyUpdate from '../PropertyUpdate';
import ContactAttributePopup from '../ContactAttributePopup';

function PropertyUpdateMulti({ value, onChange, disabled, ...props }: any) {
  const { t } = useTranslation('workflows');

  const disabledOptions = (value || []).map(
    (v: ContactUpdate) => v.property?.name?.toLowerCase() ?? '',
  );

  const handleChange = (v: ContactUpdate) => {
    return onChange(v);
  };

  return (
    <PropertyMultipleWrapper
      component={PropertyUpdate}
      addDeleteButton
      keyProp="property.name"
      customAddButton={
        <ContactAttributePopup
          renderButton={
            <Button fullWidth disabled={disabled}>
              {t('edit.addProperty')}
            </Button>
          }
          fullWidth
          hideButtonOnOpen
          value={value}
          onChange={handleChange}
          disabledOptions={disabledOptions || disabled}
          disabled={disabled}
          {...props}
        />
      }
      sx={{ alignItems: 'flex-end' }}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      {...props}
    />
  );
}

export default PropertyUpdateMulti;
