import React, { useState, useContext } from 'react';
import Papa from 'papaparse';
import FieldMappings from '../../organisms/forms/FieldMappings';

import FileUpload from '../../organisms/FileUpload';
import FileUploadResult from '../FileUploadResult';
import {
  AttributeMapping,
  generateFieldMappings,
} from '../../../utils/fieldValidationMapping';
import { getPrimaryAttributes } from '../../../constants';
import { AccountContext } from '../../../contexts/account.context';

interface ContactUploadProps {
  accept: string;
  data?: string;
  onData: (data?: string) => any;
  fieldMappings: AttributeMapping[];
  onFieldMappingsChanged: (fieldMappings: AttributeMapping[]) => any;
}

function ContactUpload({
  accept,
  data,
  onData,
  fieldMappings,
  onFieldMappingsChanged,
}: ContactUploadProps) {
  const [file, setFile] = useState<File>();
  const [columns, setColumns] = useState<string[]>();
  const { contactAttributes } = useContext(AccountContext);

  if (data && file) {
    return (
      <>
        <FileUploadResult
          file={file}
          onRemove={() => {
            onData(undefined);
            setFile(undefined);
          }}
        />
        {columns ? (
          <FieldMappings
            columns={columns}
            value={fieldMappings}
            onChange={(fieldMappings) => {
              onFieldMappingsChanged(fieldMappings);
            }}
          />
        ) : null}
      </>
    );
  }

  return (
    <FileUpload
      accept={accept}
      onSuccess={(files, data) => {
        onData(data);
        Papa.parse(data || '', {
          header: true,
          preview: 1,
          complete: (results) => {
            setColumns(results.meta.fields);
            onFieldMappingsChanged(
              generateFieldMappings(
                getPrimaryAttributes(),
                results.meta.fields,
                contactAttributes,
              ),
            );
          },
        });

        setFile(files[0]);
      }}
    />
  );
}

export default ContactUpload;
