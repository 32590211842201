import React, { useState } from 'react';
import { Typography, Link, Stack } from '@mui/joy';
// TODO: Replace with @mui/joy tables when available
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Radios from '../../molecules/Radios';

const styles = {
  tableContainer: {
    minHeight: '175px',
  },
};

interface SetupDomainInstructionsProps {
  domain: string;
}

function SetupDomainInstructions({ domain }: SetupDomainInstructionsProps) {
  const { t } = useTranslation();

  const [domainType, setDomainType] = useState<string>('root');

  const options = [
    { label: 'root', value: 'root' },
    { label: 'subdomain', value: 'subdomain' },
  ];
  const records =
    domainType === 'root'
      ? ['15.197.129.14', '3.33.150.176']
      : ['urlshortener.sakari.io'];

  // TODO: Move this into SetupDomainDialog? Or keep as a separate component?
  // TODO: Replace with @mui/joy tables when available
  return (
    <Stack gap={2} padding={1}>
      <Typography>{t('customDomain.dialog.instructions')}</Typography>
      <Radios row value={domainType} onChange={setDomainType} items={options} />
      <TableContainer sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('customDomain.dialog.table.type')}</TableCell>
              <TableCell>{t('customDomain.dialog.table.host')}</TableCell>
              <TableCell>{t('customDomain.dialog.table.value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record) => (
              <TableRow key={record}>
                <TableCell>{domainType === 'root' ? 'A' : 'CNAME'}</TableCell>
                <TableCell>{domainType === 'root' ? '@' : domain}</TableCell>
                <TableCell>{record}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <Typography level="body-md" textColor="text.secondary">
          {t('customDomain.dialog.support')}
        </Typography>
        <Link
          href="https://support.sakari.io/hc/en-us/articles/4402725716756"
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
          level="body-md"
        >
          https://support.sakari.io/hc/en-us/articles/4402725716756
        </Link>
      </div>
    </Stack>
  );
}

export default SetupDomainInstructions;
