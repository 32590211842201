import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import RecurringItemsDataGrid from '../../../ui/organisms/datagrids/RecurringItemsDataGrid';
import { AccountContext } from '../../../contexts/account.context';

function RecurringItems() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);

  if (!account || account?.plan?.name === 'Trial') return null;

  return (
    <Stack gap={2} minHeight={500}>
      <Typography level="title-lg">
        {t('settings.billing.plans.currentRecurringItems.label')}
      </Typography>
      <RecurringItemsDataGrid account={account} />
    </Stack>
  );
}
export default RecurringItems;
