import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-final-form';

import { Button, Table, IconButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { InternationalNumber } from '@sakari-io/sakari-typings';
import { FieldArray } from 'react-final-form-arrays';
import { Tooltip, NumberWithFlag } from '@sakari-io/sakari-components';
import PhoneInput from '../../../../../../ui/atoms/inputs/PhoneInput';
import VerifyDialog from './VerifyDialog';
import FieldWrapper from '../../../../../../utils/FieldWrapper';
import Select from '../../../../../../ui/atoms/inputs/Select';
import StatusChip from '../../../../../../ui/organisms/ChipContexts/StatusChip';
import { showToast } from '../../../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../../../redux';

interface HostedNumber {
  number: InternationalNumber;
  provider: string;
  status: string;
  verificationId?: string;
}

const VERIFICATION_STATUS = {
  UNVERIFIED: 'unverified',
  VERIFIED: 'verified',
};

function VerifyNumber() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const form = useForm();
  const { values } = form.getState();

  useEffect(() => {
    if (values?.hostedNumbers.length === 0) {
      handleAddNumber();
    }
  }, []);

  const handleAddNumber = () => {
    form.mutators.push('hostedNumbers', {
      number: {
        country: values?.country?.code?.toLowerCase() || 'us',
        number: '',
      },
      provider: 'Other',
      status: VERIFICATION_STATUS.UNVERIFIED,
    } as HostedNumber);
  };
  const [showVerify, setShowVerify] = useState<InternationalNumber>();

  const providers = ['AirCall', 'Google Voice', 'Microsoft Teams', 'Other'];

  return (
    <>
      <Table
        noWrap
        borderAxis="none"
        width="0"
        sx={{
          width: 600,
          borderRadius: '8px',
          'th:last-of-type, td:last-of-type': {
            width: '15%',
          },
          td: {
            overflow: 'visible',
            verticalAlign: 'top',
          },
        }}
      >
        <thead>
          <tr>
            <th>Number</th>
            <th>Provider</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          <FieldArray name="hostedNumbers">
            {({ fields }) => (
              <>
                {fields.map((fName, index) => {
                  const { number, status, provider, verificationId } =
                    fields.value[index];
                  return (
                    <tr key={index}>
                      {verificationId ? (
                        <td
                          colSpan={2}
                          style={{
                            paddingLeft: '12px',
                          }}
                        >
                          <NumberWithFlag mobile={number} />
                        </td>
                      ) : (
                        <>
                          <td>
                            <FieldWrapper
                              name={`${fName}.number`}
                              component={PhoneInput}
                              type="tel"
                              autoFocus
                              defaultCountry={values?.country?.code?.toLowerCase()}
                            />
                          </td>
                          <td
                            style={{
                              minWidth: '200px',
                            }}
                          >
                            <FieldWrapper
                              name={`${fName}.provider`}
                              component={Select}
                              placeholder={t('selectProvider')}
                              options={providers}
                            />
                          </td>
                        </>
                      )}
                      <td>
                        {status === VERIFICATION_STATUS.UNVERIFIED ? (
                          <Button
                            disabled={provider !== 'Other' || !number?.number}
                            onClick={() => {
                              const isDuplicated = _.some(
                                fields.value,
                                (n, ix) =>
                                  n.number.number === number.number &&
                                  ix !== index,
                              );

                              if (isDuplicated) {
                                dispatch(
                                  showToast({
                                    message: t(
                                      'addSenders.phonenumber.byo.verify.error.duplicate',
                                    ),
                                    severity: 'warning',
                                  }),
                                );

                                return;
                              }

                              setShowVerify(number);
                            }}
                          >
                            {t('action.verify')}
                          </Button>
                        ) : (
                          <StatusChip status={status} />
                        )}
                        {fields.value.length > 1 && (
                          <IconButton
                            size="sm"
                            onClick={() => fields.remove(index)}
                            sx={{
                              ml: 2,
                            }}
                          >
                            <Tooltip title={t('action.delete')} placement="top">
                              <FontAwesomeIcon icon={regular('xmark')} />
                            </Tooltip>
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={3}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleAddNumber();
                      }}
                    >
                      {`${t('action.add')} ${t('items.number_one')}`}
                    </Button>
                  </td>
                </tr>
              </>
            )}
          </FieldArray>
        </tbody>
      </Table>

      {showVerify ? (
        <VerifyDialog
          number={showVerify}
          channel="call"
          open={!!showVerify}
          onClose={(result) => {
            if (result) {
              const index = _.findIndex(
                values?.hostedNumbers,
                (n: HostedNumber) => n.number.number === showVerify?.number,
              );
              form.change(
                `hostedNumbers[${index}].status`,
                VERIFICATION_STATUS.VERIFIED,
              );
              form.change(`hostedNumbers[${index}].verification`, result);
            } else {
              dispatch(
                showToast({
                  message: 'Number not verified',
                  severity: 'warning',
                }),
              );
            }
            setShowVerify(undefined);
          }}
        />
      ) : null}
    </>
  );
}

export default VerifyNumber;
