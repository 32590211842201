import { AccountAttribute } from '@sakari-io/sakari-typings';
import { Attribute } from '../constants';

export interface AttributeMapping extends Attribute {
  column?: string;
  core?: boolean;
}

const COMMON_MAPPINGS: any = {
  cellnumber: 'mobile',
  cellnbr: 'mobile',
  cell: 'mobile',
  cellphone: 'mobile',
  phonenumber: 'mobile',
  phonenbr: 'mobile',
  phone: 'mobile',
  mobilenumber: 'mobile',
  mobilenbr: 'mobile',
  first: 'firstname',
  last: 'lastname',
  given: 'firstname',
  surname: 'lastname',
  email: 'email',
};

const findColumn = (columns: string[], field: any) => {
  // logger.info('field', field);
  // logger.info('findC olumn', columns, field);
  return columns.find((column: any) => {
    // logger.info('column', column);
    // (COMMON_MAPPINGS[column] || column)
    let heading = column
      .toLowerCase()
      .replace(new RegExp('[^A-Za-z]', 'gi'), '');
    heading = COMMON_MAPPINGS[heading] || heading;
    // logger.info('heading', heading);
    // logger.info('attribute', field.attribute);
    return [
      field.name?.toLowerCase(),
      field.label?.toLowerCase(),
      field.attribute?.toLowerCase(),
      ...(field.alternatives || []),
    ].includes(heading);
  });
};

const generateFieldMappings = (
  fields: any,
  headings: any,
  customAttributes: AccountAttribute[],
): AttributeMapping[] => {
  // logger.info('generateFieldMappings', fields, headings, customAttributes);
  const fieldMappings = (fields || []).map((field: any) => {
    return {
      attribute: field.attribute,
      // display: field.display,
      column: findColumn(headings, field),
      mandatory: field.mandatory,
      default: field.default,
      core: true,
    };
  });
  // logger.info('fields mappings', fieldMappings);

  // headings
  //   .filter(
  //     (heading: any) => !fieldMappings.find((fm: any) => fm.column === heading)
  //   )
  //   .forEach((heading: any) => {
  //     fieldMappings.push({
  //       attribute: heading.replace(/ /g, ''),
  //       display: heading,
  //       column: heading,
  //       mandatory: false,
  //       core: false,
  //     });
  //   });

  (customAttributes || [])
    .filter(
      (attr) => !fieldMappings.find((fm: any) => fm.attribute === attr.name),
    )
    .forEach((attr) => {
      // logger.info('checking', attr);
      fieldMappings.push({
        attribute: attr.name,
        display: attr.label,
        column: findColumn(headings, attr),
        mandatory: false,
        core: false,
      });
    });

  return fieldMappings;
};

const generateFieldConfig = (headings: any, fieldMappings: any) => {
  // logger.info('generateFC', headings, fieldMappings);
  const fields = headings.map((heading: any) => {
    const mapping = getMapping(heading, fieldMappings);
    return {
      label: mapping ? mapping.attribute : null,
      value: heading,
      default: mapping ? mapping.default : null,
    };
  });

  fieldMappings
    .filter(
      (mapping: any) => mapping.mandatory && mapping.default !== undefined,
    )
    .forEach((mapping: any) => {
      if (!fields.find((field: any) => field.label === mapping.attribute)) {
        fields.push({
          label: mapping.attribute,
          value: mapping.attribute,
          default: mapping.default,
        });
      }
    });

  // logger.info('fields', fields);

  return fields;
};

const getMapping = (heading: any, fieldMappings: any) => {
  const mapping = fieldMappings.find(
    (mapping: any) => mapping.column === heading,
  );
  return mapping;
};

export { generateFieldMappings, generateFieldConfig };
