import { Stack, Typography } from '@mui/joy';
import { Logo, Loader, logger } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Account } from '@sakari-io/sakari-typings';
import { useGetInstalledIntegrationsQuery } from '../../../../../api';
import SelectableCardGroup from '../../../../../ui/molecules/groups/SelectableCardGroup';
import EmptyResult from '../../../../../ui/molecules/EmptyResult';
import { Option } from '../../../../../types/ui';

function SelectIntegration({
  value,
  onSelect,
  account,
}: {
  value: Option | undefined;
  onSelect: (value: Option) => void;
  account: Account;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: integrations, isLoading } = useGetInstalledIntegrationsQuery({
    accountId: account.id,
    request: {
      features: 'phonenumbers',
    },
  });

  const availableIntegrations = integrations?.data?.map((integration) => {
    return {
      id: integration.sourceId,
      label: integration.name,
    };
  });

  useEffect(() => {
    if (integrations?.data?.length === 1) {
      logger.info(integrations.data);
      onSelect({
        id: integrations.data[0].sourceId,
        label: integrations.data[0].name,
      });
    }
  }, [integrations]);

  if (isLoading)
    return <Loader size={200} label="Getting available integrations..." />;

  return availableIntegrations && availableIntegrations.length > 0 ? (
    <SelectableCardGroup
      width={150}
      height={150}
      options={availableIntegrations}
      value={value as Option}
      onChange={(value) => onSelect(value as Option)}
      renderOption={(option) => (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <Logo size={100} provider={option.id} />
          </div>
          <Typography>{option.label}</Typography>
        </Stack>
      )}
    />
  ) : (
    <EmptyResult
      heading={t('No Integrations Connected')}
      item="integrations"
      buttonText="Connect"
      onButtonClicked={() => navigate('../marketplace')}
    />
  );
}
export default SelectIntegration;
