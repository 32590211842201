import { useAuth0 } from '@auth0/auth0-react';
import { Stack, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uploadFile from '../../../../api/direct';
import { UploadedFile } from '../../../../types/ui';
import FileUploadCard, {
  FileUploadCardProps,
} from '../../cards/FileUploadCard';
import FileUpload, { FileUploadProps } from '../../FileUpload';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

interface FileUploadComboProps {
  value: UploadedFile[];
  onChange: (files: UploadedFile[]) => void;
  uploadProps: Omit<FileUploadProps, 'onSuccess'>;
  cardProps: Omit<FileUploadCardProps, 'file' | 'onDelete' | 'isComplete'>;
  hideOnUpload?: boolean;
  helperText?: string;
  error?: boolean;
  onBlur?: () => void;
}

function FileUploadCombo({
  value,
  onChange,
  uploadProps = {
    maxFiles: 1,
    accept: 'files',
  },
  cardProps = {
    showPreview: true,
  },
  hideOnUpload,
  helperText,
  error,
  onBlur,
}: FileUploadComboProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [files, setFiles] = useState<UploadedFile[]>(value || []);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    onChange(files);
  }, [files]);

  const onDelete = (file: UploadedFile) =>
    setFiles((prev) => prev.filter((f) => f.id !== file.id));

  const handleUpload = async (droppedFiles: any[]) => {
    const fileUploadedCount = files.length + droppedFiles.length;

    if (uploadProps.maxFiles && fileUploadedCount > uploadProps.maxFiles) {
      return;
    }

    const filtered = droppedFiles.filter((f) => {
      const isDuplicate = files.some((file) => file.id === f.id);
      if (isDuplicate) {
        return false;
      }
      return true;
    });

    try {
      setIsUploading(true);
      const uploaded = await Promise.all(
        filtered.map(async (file) => {
          const link = await uploadFile(
            await getAccessTokenSilently(),
            file.type,
            file.data,
          );

          return {
            ...file,
            link,
            isUploaded: !!link,
          };
        }),
      );
      setFiles((prev) => [...prev, ...uploaded]);
    } catch (error) {
      dispatch(
        showToast({
          severity: 'error',
          message: t('somethingWrong'),
        }),
      );
    }
    setIsUploading(false);
  };

  return (
    <Stack gap={1}>
      {(hideOnUpload && files?.length > 0) ||
      files?.length === uploadProps.maxFiles ? null : (
        <FileUpload
          onSuccess={(files: any[]) => {
            handleUpload(files);
          }}
          isLoading={isUploading}
          onBlur={onBlur}
          error={error}
          {...uploadProps}
        />
      )}
      <Stack gap={1}>
        {files.map((file) => (
          <FileUploadCard
            key={file.id}
            {...{ file, onDelete, isComplete: file.isUploaded }}
            {...cardProps}
          />
        ))}
      </Stack>

      {helperText ? (
        <Typography level="body-md" color={error ? 'danger' : 'neutral'}>
          {helperText}
        </Typography>
      ) : null}
    </Stack>
  );
}
export default FileUploadCombo;
