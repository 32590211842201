import React, { useEffect, useState } from 'react';
import { WorkflowNodeProperty } from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import Select from '../../../../../../ui/atoms/inputs/Select';
import { ConfigFieldProps } from '../PropertyField';
import config from '../../../../../../config';
import handlebars from '../../../../../../utils/handlebars';

const enrich = (url: string, formData: any) => {
  return handlebars.compile(url)(formData);
};

interface ApiSelectProps extends ConfigFieldProps<string> {
  property: WorkflowNodeProperty;
  disabled?: boolean;
  accountId: string;
  url: string;
  authenticated?: boolean;
  valuePath?: string;
  labelPath?: string;
  formData?: any;
}

function ApiSelect({
  label,
  helperText,
  property,
  value,
  onChange,
  disabled,
  accountId,
  url,
  authenticated,
  valuePath,
  labelPath,
  formData,
}: ApiSelectProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!url) {
      return;
    }

    getAccessTokenSilently()
      .then((token: string) => {
        return axios({
          url: config.apiUrl + `/accounts/${accountId}${enrich(url, formData)}`,
          headers: authenticated
            ? {
                Authorization: 'Bearer ' + token,
              }
            : undefined,
        });
      })
      .then((resp) => {
        setData(
          resp.data.data.map((r: any) => ({
            label: labelPath ? r[labelPath] : r.label,
            value: valuePath ? r[valuePath] : r.value,
          })),
        );
      });
  }, [formData.connectionId]);

  return (
    <InputControl label={label} helperText={helperText} fullWidth>
      <Select
        fullWidth
        required={property?.mandatory}
        disabled={disabled}
        options={data}
        value={value}
        onChange={onChange}
      />
    </InputControl>
  );
}

export default ApiSelect;
