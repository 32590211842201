import { Edge } from 'reactflow';

//TODO this isn;t really a hook - move elsewhere
const findReachableNodes = (
  startNodeId: string,
  edges: Edge[],
  reachableNodes: string[] = [],
) => {
  const incomingEdges = edges.filter(({ target }) => target === startNodeId);
  incomingEdges.forEach(({ source }) => {
    if (!reachableNodes.includes(source)) {
      reachableNodes.push(source);
      findReachableNodes(source, edges, reachableNodes);
    }
  });

  return reachableNodes;
};

export default findReachableNodes;
