import React from 'react';
import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../../utils/FieldWrapper';
import { TextField } from '../../../../ui';
import GroupSelect from '../../../../ui/organisms/forms/GroupSelect';

interface DetailsProps {
  accountId: string;
  name?: string;
}

function Details({ accountId, name = 'details' }: DetailsProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <FieldWrapper
        name={`${name}.name`}
        component={TextField}
        label={`${t('campaignName')}`}
        fullWidth
        required
      />

      <FieldWrapper
        name={`${name}.description`}
        component={TextField}
        label={t('campaignDescription')}
      />

      <FieldWrapper
        orientation="vertical"
        component={GroupSelect}
        name={`${name}.phoneNumberFilter.group`}
        label={t('numberGroup')}
        accountId={accountId}
        fullWidth
        required
      />
    </Stack>
  );
}

export default Details;
