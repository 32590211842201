import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '@sakari-io/sakari-components';
import General from './General';
import Invoices from './Invoices';
import Plans from './Plans';
import { PageHeader, TabbedNavigation } from '../../ui';
import { AccountContext } from '../../contexts/account.context';

function BillingPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { account, isLoading } = useContext(AccountContext);

  const isTrial = account?.plan?.name === 'Trial';

  const navItems: any[] = [
    {
      value: 0,
      label: t('settings.general.title'),
      url: '/billing',
      content: <Route path="" element={<General />} />,
      hidden: isTrial,
    },
    {
      value: 1,
      label: t('settings.billing.plans.title'),
      url: '/billing/plans',
      content: <Route path="plans" element={<Plans />} />,
    },
    {
      value: 2,
      label: t('settings.billing.invoices.title'),
      url: '/billing/invoices',
      content: <Route path="invoices" element={<Invoices />} />,
      hidden: isTrial,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (isTrial && location.pathname !== '/billing/plans') {
      navigate('/billing/plans');
    } else {
      setSelectedTab(
        navItems.findIndex((item) => location.pathname === item.url),
      );
    }
  }, []);

  if (isLoading) return <Loader size={200} />;

  return (
    <>
      <PageHeader title={t('settings.billing.title')} />
      <TabbedNavigation
        tabs={navItems}
        selectedTab={selectedTab}
        onTabSelected={setSelectedTab}
        variant="underlined"
        divider
        childrenSx={{
          gap: 3,
        }}
      >
        <Routes>
          {navItems.map((tab) => (
            <Fragment key={tab.value}>{tab.content}</Fragment>
          ))}
        </Routes>
      </TabbedNavigation>
    </>
  );
}
export default BillingPage;
