import React, { useContext, useState } from 'react';
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Webhook } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip, Stack, IconButton, Chip } from '@mui/joy';
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import DataGrid from '../../../../../ui/molecules/DataGrid';
import {
  useDeleteWebhookMutation,
  useGetWebhooksQuery,
} from '../../../../../api/webhooks';
import WebhookToolbar from './Toolbar';
import ManageWebhookDialog from './ManageWebhookDialog';
import { useAppDispatch } from '../../../../../redux';
import DeleteWebhookDialog from './DeleteWebhookDialog';
import EmptyResult from '../../../../../ui/molecules/EmptyResult';
import { AccountContext } from '../../../../../contexts/account.context';
import { showToast } from '../../../../../redux/reducers/toast';

interface WebhookFilter {
  type: string;
}

function WebhookGrid() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const [dialog, setDialog] = useState<'delete' | 'manage' | null>('delete');
  const [selectedWebhook, setSelectedWebhook] = useState<Webhook>();
  const [deleteWebhook] = useDeleteWebhookMutation();
  const dispatch = useAppDispatch();
  const [webhookFilter] = useState<WebhookFilter>({
    type: 'webhook',
  });
  const [selectedWebhookId, setSelectedWebhookId] = useState<
    string | undefined
  >();

  const columns: GridColDef[] = [
    {
      field: 'eventTypes',
      headerName: t('settings.api.webhooks.gridColumns.eventTypes'),
      // minWidth: 500,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const categories = params.row.eventTypes.map((value: string) => {
          return (
            <Box marginBottom={0.5} marginRight={0.5} key={value}>
              <Chip key={value} color="neutral">
                {value}
              </Chip>
            </Box>
          );
        });
        return (
          <Stack direction="row" flexWrap="wrap">
            {categories}
          </Stack>
        );
      },
    },
    {
      field: 'destination',
      headerName: t('settings.api.webhooks.gridColumns.destination'),
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'disabled',
      headerName: t('settings.api.webhooks.gridColumns.status'),
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return params.row.disabled ? (
          <Chip color="danger">
            {t(`settings.api.webhooks.gridColumns.disabled`)}
          </Chip>
        ) : (
          <Chip color="success">
            {t(`settings.api.webhooks.gridColumns.enabled`)}
          </Chip>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      hideable: false,
      sortable: false,
      disableColumnMenu: true,
      getActions: (params: GridRowParams) => [
        <Tooltip
          title={t('settings.api.webhooks.gridColumns.editButtonTooltip')}
          arrow
          placement="top"
        >
          <IconButton
            size="smRound"
            onClick={() => {
              setSelectedWebhook(params.row);
              setDialog('manage');
            }}
          >
            <FontAwesomeIcon icon={faPen} className="actionButton" />
          </IconButton>
        </Tooltip>,
        <Tooltip
          title={t('settings.api.webhooks.gridColumns.deleteButtonTooltip')}
          arrow
          placement="top"
        >
          <IconButton
            onClick={() => {
              setSelectedWebhookId(params.row.id);
              setDialog('delete');
            }}
            size="smRound"
          >
            <FontAwesomeIcon
              className="blockIcon actionButton"
              icon={faTrash}
            />
          </IconButton>
        </Tooltip>,
      ],
    },
  ];
  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        gap: 2,
      }}
    >
      <WebhookToolbar onCreateClick={() => setDialog('manage')} />
      <ManageWebhookDialog
        open={dialog === 'manage'}
        webhook={selectedWebhook}
        setOpen={(state) => setDialog(!state ? null : 'manage')}
        onClose={() => {
          setDialog(null);
          setSelectedWebhook(undefined);
        }}
      />
      {selectedWebhookId && (
        <DeleteWebhookDialog
          open={dialog === 'delete'}
          onClose={async () => {
            // TODO no error handling
            await deleteWebhook({
              accountId: account?.id || '',
              request: selectedWebhookId,
            });
            dispatch(
              showToast({
                severity: 'info',
                message: t('settings.api.webhooks.delete.successMessage'),
              }),
            );

            setSelectedWebhookId(undefined);
            setDialog(null);
          }}
        />
      )}
      <DataGrid
        columns={columns}
        getRowHeight={() => {
          return 'auto';
        }}
        filter={{
          accountId: account?.id,
          request: webhookFilter,
        }}
        loadData={useGetWebhooksQuery}
        loadingText={t('settings.api.webhooks.loadingWebhookMessage')}
        renderNoData={
          <EmptyResult heading={t('settings.api.webhooks.noWebhookMessage')} />
        }
      />
    </Stack>
  );
}

export default WebhookGrid;
