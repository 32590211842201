import React from 'react';
import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import FieldWrapper from '../../../../utils/FieldWrapper';
import { TextField } from '../../../../ui';
import Grid from '../../../../ui/templates/Grid';
import PhoneInput from '../../../../ui/atoms/inputs/PhoneInput';
import AddressAutocomplete from '../../../../ui/organisms/forms/AddressAutocomplete';

function BusinessInfo() {
  const { t } = useTranslation();

  return (
    <>
      <Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={TextField}
            name="business.name"
            label="Business Name"
            placeholder="e.g. Sakari"
            required
          />
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={TextField}
            name="business.site"
            label="Business Website"
            placeholder="https://"
            required
          />
        </Grid>
      </Grid>

      <Typography fontWeight={900}>{t('contactInfo')}</Typography>
      <Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={TextField}
            name="contact.firstName"
            label={t('form.name.first')}
            required
          />
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={TextField}
            name="contact.lastName"
            label={t('form.name.last')}
            required
          />
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={TextField}
            name="contact.email"
            label={`${t('form.business.label')} ${t('form.contact.label')} ${t(
              'form.email.label',
            )} `}
            type="email"
            placeholder="you@company.com"
            required
          />
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={PhoneInput}
            name="contact.phone"
            label={t('form.phonenumber.label')}
            required
          />
        </Grid>
      </Grid>

      <Typography fontWeight={900}>{t('form.address.corporate')}</Typography>
      <Grid>
        <Grid item gridColumn={{ xs: 12 }}>
          <FieldWrapper
            name="business.address"
            component={AddressAutocomplete}
            isRequired={(field: string) => !['line2'].includes(field)}
          />
          {/* <FieldWrapper
            component={TextField}
            name="business.address.street"
            label={`${t('form.business.label')} ${t('form.address.label')}`}
            required
          />
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <TextField
            name="business.address.street2"
            label={`${t('form.business.label')} ${t('form.address.label')} 2`}
          />
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={TextField}
            name="business.address.city"
            label={t('form.city.label')}
            required
          />
        </Grid>

        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          {!stateSupportedCountries.includes(
            values.business.address.country.code
          ) ? (
            <FieldWrapper
              component={TextField}
              required
              name="business.address.state"
              label={t('form.state.label')}
            />
          ) : (
            <FieldWrapper
              component={StateAutocomplete}
              required
              name="business.address.state"
              disableClearable
            />
          )}
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={TextField}
            name="business.address.postalCode"
            label={t('form.postalCode.label')}
            required
          />
        </Grid>
        <Grid item gridColumn={{ xs: 12, md: 6 }}>
          <FieldWrapper
            component={CountryAutocomplete}
            required
            name="business.address.country"
            disableClearable
          /> */}
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessInfo;
