import React, { ReactNode, SyntheticEvent } from 'react';
import { Stack } from '@mui/joy';
import PhoneInputBase from 'react-phone-input-2';
import { InternationalNumber } from '@sakari-io/sakari-typings';
import CopyButton from '../../../organisms/ButtonContexts/CopyButton';
import InputControl from '../../../molecules/InputControl';
import Helper from '../../../../utils/helper';

export interface PhoneInputProps {
  label?: string;
  value?: InternationalNumber;
  onChange: (value: InternationalNumber) => any;
  error?: boolean;
  helperText?: string;
  size?: 'sm' | 'md';
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  editOnClick?: boolean;
  orientation?: 'horizontal' | 'vertical';
  endDecorator?: ReactNode;
  labelWidth?: number;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  autoFocus?: boolean;
  defaultCountry?: string;
  sx?: any;
  inputProps?: any;
}
type Mode = 'view' | 'edit';

function PhoneInput({
  size = 'md',
  label,
  value,
  onChange,
  error,
  helperText,
  disabled,
  fullWidth,
  required,
  editOnClick,
  orientation,
  endDecorator,
  labelWidth,
  onBlur,
  readOnly,
  autoFocus = false,
  defaultCountry = 'us',
  sx,
  inputProps,
}: PhoneInputProps) {
  const [mode, setMode] = React.useState<'view' | 'edit'>('view');

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (editOnClick) {
      if (
        (e.target as HTMLElement).matches('#copy-button') ||
        ((e.target as Element).parentNode as HTMLElement).matches(
          '#copy-button',
        )
      )
        return;

      let newMode: Mode = 'view';
      switch (mode) {
        case 'view':
          newMode = e.type === 'click' ? 'edit' : 'view';
          break;
        case 'edit':
          newMode = 'edit';
          break;
        default:
          break;
      }
      setMode(newMode);
    }
  };

  return (
    <InputControl
      size={size}
      required={required}
      orientation={orientation}
      error={error}
      helperText={helperText}
      label={label}
      noGap
      sx={{
        width: fullWidth ? '100%' : 'auto',
        '& .MuiFormLabel-root': {
          mt: '0 !important',
          alignContent: 'center',
          minWidth: labelWidth,
        },

        '.country-list': {
          background: `var(--joy-palette-background-surface)`,
          color: 'text.primary',
          fontSize: `var(--joy-fontSize-${size})`,
          fontFamily: 'var(--joy-fontFamily-body)',
          '& .country': {
            '&.active, &.highlight, &:hover': {
              background: `var(--joy-palette-background-level1)`,
            },
          },
        },
        '.react-tel-input': {
          width: '100%',
          boxSizing: 'border-box',

          '& .form-control': {
            fontFamily: 'var(--joy-fontFamily-body)',
            width: '100%',
            ...(size === 'sm' && {
              height: '2rem',
            }),
            ...(size === 'md' && {
              height: '2.5rem',
            }),
            borderRadius: 'var(--joy-radius-sm)',
            padding: '0 1rem 0 3.5rem',
            fontSize: `var(--joy-fontSize-${size})`,
            background: `var(--joy-palette-background-surface)`,
            border: 'solid 1px',
            color: 'var(--joy-palette-neutral-plainColor)',

            '&.invalid-number': {
              borderColor: 'var(--joy-palette-danger-outlinedBorder)',
              color: 'var(--joy-palette-danger-outlinedColor)',
            },

            ...(disabled && {
              color: 'var(--joy-palette-neutral-plainDisabledColor)',
            }),
            cursor: disabled ? 'default' : 'initial',
            borderColor: 'var(--joy-palette-neutral-outlinedBorder)',

            ...(disabled && {
              borderColor: 'var(--joy-palette-neutral-outlinedDisabledBorder)',
            }),
            '&:hover': {
              boxShadow: `0 0 1px var(--joy-palette-neutral-outlinedBorder)`,
              outlineWidth: 'var(--joy-focus-border-width, 2px) ',

              color: 'var(--joy-palette-neutral-outlinedHoverColor)',

              ...(disabled && {
                borderColor:
                  'var(--joy-palette-neutral-outlinedDisabledBorder)',
              }),
            },
            '&:focus, &:focus-visible, &:focus-within, &:active': {
              outlineWidth: 'var(--joy-focus-border-width, 2px) ',
              border: '1px solid',
              borderColor: 'var(--joy-palette-focusVisible)',
              '&.invalid-number': {
                borderColor: 'var(--joy-palette-danger-outlinedBorder)',
                color: 'var(--joy-palette-danger-outlinedColor)',
              },
            },

            ...(editOnClick && {
              ...(orientation === 'horizontal' && {
                '& .MuiFormLabel-root': {
                  color: 'text.tertiary',
                },
                '#copy-button': {
                  opacity: 0,
                  position: 'absolute',
                  right: '0rem',
                  top: '0rem',
                },
                '&:hover': {
                  '#copy-button': { opacity: 1 },
                },
              }),
              ...(mode === 'view'
                ? {
                    borderColor: 'transparent',
                    cursor: 'default',
                    '&:hover': {
                      backgroundColor: 'background.level1',
                    },
                  }
                : mode === 'edit' && {
                    '&:focus, &:focus-visible, &:focus-within, &:active': {
                      border: `1px solid `,
                      borderColor: 'var(--joy-palette-neutral-outlinedBorder)',
                      boxShadow: 'var(--joy-shadow-sm)',
                      color: 'text.primary',
                      '&.invalid-number': {
                        borderColor: 'var(--joy-palette-danger-outlinedBorder)',
                        color: 'var(--joy-palette-danger-outlinedColor)',
                      },
                    },
                  }),
            }),
          },
          ...(readOnly && {
            cursor: 'default',
            pointerEvents: 'none',
            '&:hover, &:focus, &:focus-visible, &:focus-within, &:active': {
              color: 'initial',
              borderWidth: '0',
              boxShadow: 'none !important',
              outline: 'none !important',
              background: 'initial !important',
            },
          }),
        },
        ...sx,
      }}
    >
      <Stack
        sx={{
          flexFlow: 'row nowrap',
          gap: 2,
          alignItems: 'center',
          flex: '0 1 auto',
        }}
      >
        <PhoneInputBase
          isValid={(value, country: any) => {
            return Helper.validatePhoneNumber(value, country?.iso2) || !error;
          }}
          inputProps={{
            readOnly,
            onClick: handleClick,
            onFocus: handleClick,
            onKeyPressCapture: (e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                setMode('view');
              } else {
                const input = e.target as HTMLInputElement;
                setTimeout(() => {
                  const len = input.value.length;
                  input.setSelectionRange(len, len);
                }, 0);
              }
            },
            onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
              setMode('view');
              if (onBlur) {
                onBlur(e);
              }
            },
            autoFocus,
            autoComplete: 'phone',
            ...inputProps,
          }}
          disabled={disabled}
          disableDropdown={readOnly || disabled}
          value={value?.number?.replace('+', '')}
          country={defaultCountry}
          specialLabel=""
          onChange={(number, country: any) => {
            const newVal = {
              number:
                number?.length && !number.startsWith('+')
                  ? `+${number}`
                  : number,
              country: country?.countryCode?.toUpperCase(),
            };
            onChange(newVal);
          }}
        />
        {editOnClick && !!value ? (
          <CopyButton size="sm" text={value?.number} />
        ) : null}
        {endDecorator}
      </Stack>
    </InputControl>
  );
}

export default PhoneInput;
