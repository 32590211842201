import { WorkflowNodePropertyCondition } from '@sakari-io/sakari-typings';
import React, { HTMLInputTypeAttribute } from 'react';
import { Field, FieldProps, FieldRenderProps, FormSpy } from 'react-final-form';
import { InputWrapper } from './FieldWrapper';
import { isPropertyConditionValid } from './workflows/nodevalidation';

interface WorkflowConfigFieldWrapperProps<T, P>
  extends FieldProps<T | T[], any> {
  label?: string | React.ReactNode;
  type?: HTMLInputTypeAttribute;
  component:
    | React.ComponentType<FieldProps<T | T[], FieldRenderProps<T | T[]>> & P>
    | React.FunctionComponent<P>;
  condition?: string;
  disabled?: boolean;
}
/** Note: This is a generic component that can be used to wrap any input component
 * the input component must accept a value and onChange prop, and it must be
 * return the new value in the onChange callback
 * It can also be used as a template for creating other generic components.
 * @param component - the input component to wrap
 * @paaram name - the name of the field as seen in form values
 * @param label - the label of the field
 * @param type - the type of input, defaults to text
 */
function WorkflowConfigFieldWrapper<T, P>({
  label,
  type,
  component: Component,
  conditions,
  disabled,
  ...rest
}: WorkflowConfigFieldWrapperProps<T | T[], P>) {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const valid = !(conditions || []).find(
          (condition: WorkflowNodePropertyCondition) => {
            const r = isPropertyConditionValid(condition, values);
            return !r;
          },
        );

        if (!valid) {
          return null;
        }

        // TODO restore
        // if (condition && !values[condition]) {
        //   form.change(rest.name, null);
        //   return null;
        // }

        return (
          <Field
            label={label}
            type={type || 'text'}
            disabled={disabled}
            render={(props) => (
              <InputWrapper
                key={rest.name}
                component={Component}
                config={values}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          />
        );
      }}
    </FormSpy>
  );
}

export default WorkflowConfigFieldWrapper;
