import React, { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';

import { Stack } from '@mui/joy';

import { Loader, Textarea } from '@sakari-io/sakari-components';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contexts/account.context';
import { useCreatePartnerMutation } from '../../../api/partners';

import { useAppDispatch } from '../../../redux';
import { showToast } from '../../../redux/reducers/toast';
import FieldWrapper from '../../../utils/FieldWrapper';
import TextField from '../../../ui/atoms/inputs/TextField';
import { CountryAutocomplete, PageHeader } from '../../../ui';
import PageSection from '../../../ui/templates/PageSection';
import ConfirmationDialog from '../../../ui/molecules/ConfirmationDialog';
import Select from '../../../ui/atoms/inputs/Select';

import Setting from '../../../ui/templates/forms/SettingForm';
import PartnerUsers from '../../../ui/organisms/forms/PartnerUsers';
import Container from '../../../ui/templates/Container';

function PartnerSignup() {
  const navigate = useNavigate();

  const { account, profile, isLoading } = useContext(AccountContext);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [createPartner, { isLoading: isSubmitting }] =
    useCreatePartnerMutation();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onHandleSubmit = async (values: any) => {
    try {
      const body = {
        user: values.user.id,
        paypalEmail: values.paypalEmail,
        linkedInURL: values?.linkedInURL,
        country: values?.country?.code,
        customerLocation: values?.customerLocation?.code,
        numberEmployees: values?.numberEmployees,
        numberClients: values?.numberClients,
        joinDescription: values?.joinDescription,
      };

      await createPartner({
        accountId: account?.id || '',
        request: body,
      })
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/settings');

            dispatch(
              showToast({
                severity: 'info',
                message: t('partnerSignup.submitSuccess'),
                timeout: 5000,
              }),
            );
          }
        });
    } catch (err: any) {
      dispatch(
        showToast({
          severity: 'error',
          message: `${err.data.error.message}`,
        }),
      );
    }
  };

  const schema = Yup.object().shape({
    user: Yup.object()
      .shape({
        id: Yup.string().required(t('partnerSignup.sakariUser.invalid')),
      })
      .nullable(),
    paypalEmail: Yup.string()
      .required(t('partnerSignup.paypalEmail.validation'))
      .email(t('partnerSignup.paypalEmail.invalid')),
    linkedInURL: Yup.string()
      .matches(/linkedin\.com/, {
        message: t('partnerSignup.linkedInURL.invalid'),
        excludeEmptyString: true,
      })
      .nullable(),
    country: Yup.object()
      .required(
        t('validation.pleaseSelect', {
          field: t('country'),
        }),
      )
      .nullable(),
    customerLocation: Yup.object()
      .required(t('partnerSignup.customerLocation.invalid'))
      .nullable(),
    numberEmployees: Yup.string()
      .required(
        t('validation.pleaseSelect', {
          field: t('partnerSignup.numberEmployees.label').toLowerCase(),
        }),
      )
      .nullable(),
    numberClients: Yup.string()
      .required(
        t('validation.pleaseSelect', {
          field: t('partnerSignup.numberClients.label').toLowerCase(),
        }),
      )
      .nullable(),
    joinDescription: Yup.string().required(
      t('partnerSignup.joinDescription.invalid'),
    ),
  });

  const validate = makeValidate(schema);

  const initialValues = {
    user: profile ?? null,
    paypalEmail: profile ? profile.email : '',
    country: {
      code: 'US',
    },
    numberEmployees: '',
    linkedInURL: '',
    joinDescription: '',
    numberClients: '',
    customerLocation: {
      code: 'US',
    },
  };

  if (isLoading) {
    return <Loader size={200} />;
  }

  return (
    <Form
      onSubmit={onHandleSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ values, hasValidationErrors }) => (
        <>
          <PageHeader
            title={t('partnerSignup.title')}
            ctaAction={() => setShowConfirmation(true)}
            ctaDisabled={hasValidationErrors}
            ctaText="Submit Application"
            loading={isSubmitting}
          />
          <Container>
            <Stack direction="column" spacing={3} maxWidth={700}>
              <PageSection title="Primary Contact">
                <Stack direction="column" spacing={3} maxWidth={700}>
                  <Setting label={t('partnerSignup.sakariUser.label')} required>
                    <FieldWrapper
                      name="user"
                      account={account}
                      component={PartnerUsers}
                      placeholder={t('partnerSignup.sakariUser.placeholder')}
                      required
                    />
                  </Setting>
                  <Setting
                    label={t('partnerSignup.paypalEmail.label')}
                    description={t('partnerSignup.paypalEmail.description')}
                    required
                  >
                    <FieldWrapper
                      name="paypalEmail"
                      fullWidth
                      component={TextField}
                      type="email"
                      required
                    />
                  </Setting>
                </Stack>
              </PageSection>
              <PageSection title={t('partnerSignup.yourCompany')}>
                <Stack direction="column" spacing={3} maxWidth={700}>
                  <Setting label={t('country')} required>
                    <FieldWrapper
                      component={CountryAutocomplete}
                      fullWidth
                      name="country"
                      required
                      label={t('form.country.label')}
                    />
                  </Setting>
                  <Setting
                    label={t('partnerSignup.numberEmployees.label')}
                    required
                  >
                    <FieldWrapper
                      name="numberEmployees"
                      fullWidth
                      component={Select}
                      placeholder={t(
                        'partnerSignup.numberEmployees.placeholder',
                      )}
                      options={[
                        {
                          label: t(
                            'partnerSignup.numberEmployees.selfEmployed',
                          ),
                          value: 'self-employed',
                        },
                        {
                          label: '2-5',
                          value: '2-5',
                        },
                        {
                          label: '6-10',
                          value: '6-10',
                        },
                        {
                          label: '11-50',
                          value: '11-50',
                        },
                        {
                          label: '51-200',
                          value: '51-200',
                        },
                        {
                          label: '201-500',
                          value: '201-500',
                        },
                        {
                          label: '500+',
                          value: '500+',
                        },
                      ]}
                      required
                    />
                  </Setting>
                  <Setting
                    label={t('partnerSignup.linkedInURL.label')}
                    description={t('validation.optional')}
                  >
                    <FieldWrapper
                      name="linkedInURL"
                      fullWidth
                      component={TextField}
                      placeholder="www.linkedin.com/company/your-company"
                      type="url"
                    />
                  </Setting>
                  <Setting
                    label={t('partnerSignup.joinDescription.label')}
                    required
                  >
                    <FieldWrapper
                      name="joinDescription"
                      fullWidth
                      maxRows={10}
                      minRows={3}
                      component={Textarea}
                      required
                    />
                  </Setting>
                </Stack>
              </PageSection>

              <PageSection title={t('partnerSignup.yourCustomers')}>
                <Stack direction="column" spacing={3} maxWidth={700}>
                  <Setting
                    label={t('partnerSignup.customerLocation.label')}
                    required
                  >
                    <FieldWrapper
                      component={CountryAutocomplete}
                      fullWidth
                      name="customerLocation"
                      required
                      label={t('form.country.label')}
                    />
                  </Setting>
                  <Setting
                    label={t('partnerSignup.numberClients.label')}
                    required
                  >
                    <FieldWrapper
                      name="numberClients"
                      required
                      fullWidth
                      component={Select}
                      placeholder={t('partnerSignup.numberClients.placeholder')}
                      options={[
                        {
                          label: t('partnerSignup.numberClients.noneYet'),
                          value: '0',
                        },
                        {
                          label: '1',
                          value: '1',
                        },
                        {
                          label: '2-10',
                          value: '2-10',
                        },
                        {
                          label: '11-50',
                          value: '11-50',
                        },
                        {
                          label: '51-200',
                          value: '51-200',
                        },
                      ]}
                    />
                  </Setting>
                </Stack>
              </PageSection>
            </Stack>
          </Container>
          {showConfirmation && (
            <ConfirmationDialog
              open={showConfirmation}
              onClose={(result) => {
                if (result && values) {
                  onHandleSubmit(values);
                }
                setShowConfirmation(false);
              }}
              loading={isSubmitting}
              confirmLabel="partnerSignup.confirmation.action"
              header={t('partnerSignup.confirmation.header')}
              content={t('partnerSignup.confirmation.content')}
            />
          )}
        </>
      )}
    />
  );
}

export default PartnerSignup;
