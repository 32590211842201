import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, useDebouncedValue } from '@sakari-io/sakari-components';
import PhoneNumbersGrid from '../../ui/organisms/datagrids/PhoneNumbersGrid';
import Container from '../../ui/templates/Container';
import { AccountContext } from '../../contexts/account.context';
import { AlertType } from '../../ui/organisms/alerts/StatusAlert';
import SearchableDataGrid from '../../ui/organisms/datagrids/SearchableDataGrid';
import PageHeader from '../../ui/organisms/headers/PageHeader';
import ToastSnackbar from '../../ui/organisms/alerts/ToastSnackbar';

function Senders() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const location = useLocation();

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);

  const [dialog, setDialog] = useState<{ show: boolean; type: AlertType }>({
    show: false,
    type: 'success',
  });

  const createNewAction = () => {
    return navigate('/senders/phonenumbers/add');
  };

  useEffect(() => {
    // check if there is a state in navigation
    if (location.state?.addSender) {
      if (location.state?.addSender?.success) {
        setDialog({ show: true, type: 'success' });
      } else {
        setDialog({ show: true, type: 'error' });
      }
    }
  }, []);

  return (
    <>
      <PageHeader
        title="Senders"
        ctaText={t('senders.add.title')}
        ctaAction={createNewAction}
      />
      <Container spacing={1}>
        {account?.plan?.name === 'Trial' && (
          <Alert
            actionTitle={t('businessInfo.upgradeNow')}
            actionOnClick={() => navigate('/billing')}
            severity="info"
            title={t('businessInfo.onFreeTrial')}
            subTitle={t('businessInfo.someFeaturesNotAvail')}
          />
        )}
        {account ? (
          <SearchableDataGrid
            value={searchTerm}
            onChange={setSearchTerm}
            renderDataGrid={() => (
              <PhoneNumbersGrid
                filter={{
                  accountId: account.id,
                  request: {
                    q: debouncedSearchTerm,
                  },
                }}
                onSelectedRowChange={(row: any) =>
                  navigate(`phonenumbers/${row.id}`)
                }
              />
            )}
          />
        ) : null}
      </Container>
      {location.state?.addSender && (
        <ToastSnackbar
          open={dialog.show}
          status={dialog.type}
          variant="outlined"
          onClose={() => {
            setDialog({
              show: false,
              type: 'success',
            });
          }}
          placement={{
            vertical: 'top',
            horizontal: 'center',
          }}
          fullWidth
          style={{
            position: 'absolute',
          }}
          sx={{
            margin: '16px',
            whiteSpace: 'pre-wrap',
          }}
          title={location.state?.addSender?.title || `Success!`}
          message={
            location.state?.addSender?.message ||
            'Sender(s) added successfully.'
          }
        />
      )}
    </>
  );
}

export default Senders;
