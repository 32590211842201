import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Stack,
  Sheet,
} from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAuth0 } from '@auth0/auth0-react';
import { Condition, ContactFilter } from '@sakari-io/sakari-typings';
import { Tooltip } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import BulkActionDialog from '../../Dialogs/BulkActionDialog';
import {
  useCountContactsMutation,
  useAddContactsToListMutation,
  useDeleteContactsMutation,
  useBlockContactsMutation,
} from '../../../../api';
import AddContactsDropdownButton from '../../ButtonContexts/AddContactsDropdownButton';
import { ListItemDataRow } from '../../../../types';
import AddToListMenuButton from '../../ButtonContexts/AddToListMenuButton';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import { downloadContacts } from '../../../../utils/downloads';

import OverlayPopup from '../../../templates/OverlayPopup';
import Radios from '../../../molecules/Radios';
import { AccountContext } from '../../../../contexts/account.context';
import { UserRole } from '../../../../constants';

interface ContactsToolbarProps {
  multi: boolean;
  condition?: Condition[];
  onCreateCampaign: (filter: ContactFilter) => any;
  filters: any;
  setFilters: (filters: any) => void;
  onUploadContacts: (list: string) => any;
  onAddContact: () => any;
}

function ContactsToolbar({
  multi,
  condition,
  onCreateCampaign,
  onUploadContacts,
  onAddContact,
  filters,
  setFilters,
}: ContactsToolbarProps) {
  const { account, userRole } = useContext(AccountContext);
  const isReadOnly = userRole === UserRole.READ_ONLY;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [action, setAction] = useState('');

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [countContacts, { isLoading: isCounting }] = useCountContactsMutation();
  const [addContactsToList] = useAddContactsToListMutation();

  const [deleteContacts] = useDeleteContactsMutation();
  const [blockContacts] = useBlockContactsMutation();

  const { getAccessTokenSilently } = useAuth0();

  const FILTER_OPTIONS = [
    {
      label: t('contacts.allContacts'),
      value: 'all',
    },
    {
      label: t('contacts.onlyBlockedContacts'),
      value: 'blocked',
    },
    {
      label: t('contacts.onlyInvalidContacts'),
      value: 'invalid',
    },
    {
      label: t('contacts.onlyOptedInContacts'),
      value: 'optIn',
    },
  ];

  useEffect(() => {
    if (multi) {
      countContacts({
        attributes: condition,
        valid: filters?.valid,
        invalid: filters?.invalid,
        blocked: filters?.blocked,
        unblocked: filters?.unblocked,
        optIn: filters?.optIn,
      })
        .unwrap()
        .then(({ data }) => {
          setSelectedCount(data);
        });
    }
  }, [multi, condition]);

  const onDownload = async () => {
    dispatch(
      showToast({
        severity: 'info',
        message: 'Your download will start shortly',
      }),
    );

    const accessToken = await getAccessTokenSilently();
    const data = await downloadContacts(accessToken, {
      attributes: condition,
    });

    const fileUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'contacts.csv');
    document.body.appendChild(link);
    link.click();
  };

  const onAddToList = (selected: ListItemDataRow) => {
    addContactsToList({
      listId: selected.id,
      filter: {
        attributes: condition,
      },
    }).then(() => {
      dispatch(
        showToast({ severity: 'info', message: 'Contacts added to list' }),
      );
    });
  };
  const [contactStatusFilter, setContactStatusFilter] = useState('all');
  const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);

  useEffect(() => {
    switch (contactStatusFilter) {
      case 'invalid': {
        setFilters({ valid: false, invalid: true });
        break;
      }
      case 'blocked': {
        setFilters({ blocked: true, unblocked: false });
        break;
      }
      case 'optIn': {
        setFilters({ optIn: true, blocked: false });
        break;
      }
      default: {
        setFilters(undefined);
        break;
      }
    }
  }, [contactStatusFilter]);

  if (!account) {
    return null;
  }

  return (
    <Stack direction="row" gap={2} pb={2} alignItems="center">
      <OverlayPopup
        open={openFilterMenu}
        onOpenClose={setOpenFilterMenu}
        placement="bottom-end"
        renderButton={(setAnchorEl, onClick) => (
          <IconButton
            variant="outlined"
            sx={{ transform: 'scale(0.8)' }}
            onClick={onClick}
            ref={setAnchorEl}
          >
            <FontAwesomeIcon icon={regular('bars-filter')} />
          </IconButton>
        )}
        renderPopup={() => (
          <Sheet sx={{ padding: 2, borderRadius: 2 }}>
            <Radios
              size="sm"
              value={contactStatusFilter}
              items={FILTER_OPTIONS}
              onChange={(e) => {
                setContactStatusFilter(e);
              }}
            />
          </Sheet>
        )}
      />
      <Box flex={1} />
      {!multi ? (
        <>
          <IconButton
            onClick={onDownload}
            size="md"
            sx={{ borderRadius: '50%' }}
          >
            <FontAwesomeIcon icon={solid('arrow-down-to-line')} />
          </IconButton>

          <AddContactsDropdownButton
            onAdd={onAddContact}
            onUpload={() => onUploadContacts('')}
            disabled={isReadOnly}
          />
        </>
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            className="contactToolbar"
            spacing={1}
          >
            {isCounting ? (
              <CircularProgress size="sm" />
            ) : (
              <Box sx={{ alignSelf: 'center', whiteSpace: 'nowrap' }}>
                {selectedCount} selected
              </Box>
            )}

            <Tooltip title="Download CSV" arrow placement="bottom">
              <IconButton
                onClick={onDownload}
                size="md"
                sx={{ borderRadius: '50%' }}
              >
                <FontAwesomeIcon icon={solid('arrow-down-to-line')} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Block Selected Contacts" arrow placement="bottom">
              <IconButton
                onClick={() => {
                  setAction('block');
                  setOpenDeleteDialog(true);
                }}
                size="md"
                sx={{ borderRadius: '50%' }}
                disabled={isReadOnly}
              >
                <FontAwesomeIcon icon={solid('ban')} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Selected Contacts" arrow placement="bottom">
              <IconButton
                onClick={() => {
                  setAction('delete');
                  setOpenDeleteDialog(true);
                }}
                size="md"
                sx={{ borderRadius: '50%' }}
                disabled={isReadOnly}
              >
                <FontAwesomeIcon icon={solid('trash')} />
              </IconButton>
            </Tooltip>

            {!isReadOnly && (
              <AddToListMenuButton account={account} onSelect={onAddToList} />
            )}

            <Button
              variant="solid"
              color="primary"
              onClick={() =>
                onCreateCampaign({
                  attributes: condition,
                })
              }
              disabled={isReadOnly}
              size="md"
            >
              Create Campaign
            </Button>
          </Stack>

          <BulkActionDialog
            action={action}
            onClose={(result) => {
              setOpenDeleteDialog(false);

              if (result) {
                const filter = {
                  attributes: condition,
                };

                if (action === 'delete') {
                  deleteContacts(filter)
                    .unwrap()
                    .then(() => {
                      dispatch(
                        showToast({
                          severity: 'info',
                          message: `${selectedCount} contact${selectedCount > 1 ? 's' : ''} deleted`,
                        }),
                      );
                    })
                    .catch((err) => {
                      dispatch(
                        showToast({
                          severity: 'error',
                          message:
                            err.data?.error?.message ??
                            'Unable to delete contacts',
                        }),
                      );
                    });
                } else {
                  blockContacts(filter)
                    .unwrap()
                    .then(() => {
                      dispatch(
                        showToast({
                          severity: 'info',
                          message: `${selectedCount} contact${selectedCount > 1 ? 's' : ''} blocked`,
                        }),
                      );
                    })
                    .catch((err) => {
                      dispatch(
                        showToast({
                          severity: 'error',
                          message:
                            err.data?.error?.message ??
                            'Unable to block contacts',
                        }),
                      );
                    });
                }
              }
            }}
            open={openDeleteDialog}
            isCounting={isCounting}
            selectedItemsCount={selectedCount}
          />
        </>
      )}
    </Stack>
  );
}

export default ContactsToolbar;
