import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import * as Sentry from '@sentry/react';
import { logger } from '@sakari-io/sakari-components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './utils/notifications';
import { STAGE } from './config/environment';

Sentry.init({
  dsn: 'https://1a4ce9ee5f5a44c9ac56fb470cf601dc@o61047.ingest.sentry.io/6160202',
  environment: STAGE,
  integrations: [new Sentry.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend: (event, hint) => {
    // capture events only if it's not from a library
    if (window.location.hostname === 'localhost') {
      logger.info('Sentry beforeSend', event.exception, hint);
      return null;
    }

    // if
    return event;
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
