import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Stack } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { PageHeader } from '../../ui';
import Container from '../../ui/templates/Container';
import SelectableCardGroup from '../../ui/molecules/groups/SelectableCardGroup';
import { NavigationItem } from '../../utils/navigation';

function Tools() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const options: Partial<
    NavigationItem & { id: string; icon: IconDefinition }
  >[] = [
    {
      id: 'link-tracking',
      label: 'Link Tracking',
      url: '/tools/links',
      icon: regular('link'),
    },
    {
      id: 'qr-code-generator',
      label: 'QR Code Generator',
      url: '/tools/qr',
      icon: regular('qrcode'),
    },
  ];

  return (
    <>
      <PageHeader title={t('tools')} />
      <Container>
        <SelectableCardGroup
          variant="outlined"
          options={options}
          onChange={(option: any) => option.url && navigate(option.url)}
          height={200}
          width={200}
          renderOption={(option: any) => {
            return (
              <Stack gap={2}>
                <FontAwesomeIcon icon={option.icon} fontSize="26pt" />
                {option.label}
              </Stack>
            );
          }}
          containerSx={{
            width: '100%',
            display: 'flex',
            gap: 2,
          }}
        />
      </Container>
    </>
  );
}

export default Tools;
