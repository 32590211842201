import { InputProps } from '@mui/joy';
import { EditableInput } from '@sakari-io/sakari-components';
import React from 'react';
import InputControl, {
  InputControlProps,
} from '../../../molecules/InputControl';

interface EditableTextProps
  extends Omit<InputProps, 'onChange'>,
    Pick<
      InputControlProps,
      'label' | 'error' | 'helperText' | 'fullWidth' | 'required' | 'ref'
    > {
  onChange?: (value: string) => void;
  labelWidth?: number;
}

function EditableText({
  label,
  error,
  helperText,
  size,
  fullWidth,
  labelWidth,
  required,
  onChange,
  ref,
  ...rest
}: EditableTextProps) {
  const ControlProps = {
    label,
    error,
    helperText,
    orientation: 'horizontal' as const,
    size,
    fullWidth,
    required,
    ref,
  };

  return (
    <InputControl
      sx={{
        '& .MuiFormLabel-root': {
          mt: '0 !important',
          alignContent: 'center',
          color: 'text.tertiary',
          minWidth: labelWidth,
        },
      }}
      {...ControlProps}
    >
      <EditableInput
        onChange={(e: any) => onChange?.(e.target.value ?? '')}
        {...rest}
      />
    </InputControl>
  );
}

export default EditableText;
