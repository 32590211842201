import {
  SakariAPIResponse,
  SearchablePaginationRequest,
  User,
  UserInvite,
  SyncUsersResponse,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { getAccountId, buildQuery } from './common';
import { AccountIdWith } from '../types';

const USER_TAG = 'User';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      SakariAPIResponse<User[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/users?${buildQuery(request)}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: USER_TAG,
                id,
              })),
              USER_TAG,
            ]
          : [USER_TAG],
    }),

    updateUser: builder.mutation<
      SakariAPIResponse<User>,
      Partial<User> & Pick<User, 'id'>
    >({
      query: ({ id, ...update }) => ({
        url: `accounts/${getAccountId()}/users/${id}`,
        method: 'PUT',
        body: update,
      }),
      invalidatesTags: (result, error, arg) => [{ type: USER_TAG, id: arg.id }],
    }),

    removeUser: builder.mutation<SakariAPIResponse, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: USER_TAG, id: arg }],
    }),

    addUsers: builder.mutation<
      SakariAPIResponse<SyncUsersResponse>,
      UserInvite[]
    >({
      query: (users) => ({
        url: `accounts/${getAccountId()}/bulk/users`,
        method: 'POST',
        body: users,
      }),
      invalidatesTags: [USER_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useUpdateUserMutation,
  useRemoveUserMutation,
  useAddUsersMutation,
} = extendedApi;
