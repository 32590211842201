import { Stack, Typography } from '@mui/joy';
import { differenceInDays } from 'date-fns';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface TrialContentProps {
  expiryDate: string;
}

function TrialContent({ expiryDate }: TrialContentProps) {
  const { t } = useTranslation();
  const daysLeft = differenceInDays(new Date(expiryDate ?? 0), new Date());
  const isExpired = daysLeft <= 0;

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: 200,
      }}
    >
      <Typography
        level="h4"
        sx={{
          ...(isExpired && {
            color: 'var(--joy-palette-danger-500) !important',
          }),
        }}
      >
        {`${t('settings.billing.plans.trial')} 
        ${
          isExpired
            ? `${_.capitalize(t('statusType.expired'))}!`
            : _.capitalize(t('items.plan_one'))
        }`}
      </Typography>
      {!isExpired && <Typography level="h1">{daysLeft}</Typography>}
      <Typography>
        {isExpired
          ? t('settings.billing.plans.upgradePlanToContinue')
          : t('settings.billing.plans.daysRemaining')}
      </Typography>
    </Stack>
  );
}
export default TrialContent;
