import React from 'react';
import { User } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, Stack, ListDivider } from '@mui/joy';
import { AvatarChip } from '@sakari-io/sakari-components';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

interface UserLineItemProps {
  user: User;
  sx?: any;
  onRemove: (user: User) => any;
}

function UserLineItem({ user, sx, onRemove }: UserLineItemProps) {
  return (
    <>
      <ListDivider />
      <ListItem key={user.id}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
          sx={sx}
        >
          <AvatarChip person={user} size="md" />

          <Box
            onClick={() => onRemove(user)}
            sx={{
              cursor: 'pointer',
              color: '#667085',
              '&:hover': { fontWeight: 'bolder', color: 'black' },
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </Box>
        </Stack>
      </ListItem>
    </>
  );
}

export default UserLineItem;
