import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  LinearProgress,
  Stack,
  Box,
  IconButton,
  Card,
  CardProps,
} from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@sakari-io/sakari-components';
import { UploadedFile } from '../../../../types/ui';
import Helper from '../../../../utils/helper';
import MediaPreview from '../../Dialogs/MediaPreview';

export interface FileUploadCardProps extends CardProps {
  file: UploadedFile;
  onDelete: (file: UploadedFile) => void;
  progress?: number;
  isComplete?: boolean;
  showPreview?: boolean;
  showProgress?: boolean;
}

function FileUploadCard({
  file,
  onDelete,
  progress,
  showPreview,
  showProgress,
  isComplete,
  ...rest
}: FileUploadCardProps) {
  const { t } = useTranslation();
  const { size, preview } = file;

  const [openPreview, setOpenPreview] = useState(false);

  let startDecorator;

  if (showPreview) {
    if (preview) {
      startDecorator = (
        <img
          alt="Preview"
          src={preview}
          style={{ maxHeight: '20px', maxWidth: '20px' }}
        />
      );
    } else {
      startDecorator = <FontAwesomeIcon icon={regular('file')} />;
    }
  } else {
    startDecorator = null;
  }

  return (
    <>
      <Card variant="outlined" {...rest}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            gap={1}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => setOpenPreview(true)}
          >
            <Typography level="body-md" startDecorator={startDecorator}>
              {file?.name}
            </Typography>
            {!!size && (
              <Typography level="body-sm" textColor="text.tertiary">
                {Helper.formatBytes(size)}
              </Typography>
            )}
          </Stack>
          <Tooltip title={t('action.delete')}>
            <IconButton size="smRound" onClick={() => onDelete(file)}>
              <FontAwesomeIcon icon={regular('trash-can')} />
            </IconButton>
          </Tooltip>
        </Stack>

        {showProgress ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box width="100%">
              <LinearProgress
                sx={{
                  borderRadius: 2,
                  height: '8px',
                }}
                value={isComplete ? 100 : progress}
                determinate={isComplete}
              />
            </Box>
            <Typography
              level="body-sm"
              width="3rem"
            >{`${(isComplete ? 1 : progress || 0) * 100}%`}</Typography>
          </Stack>
        ) : null}
      </Card>
      {preview && (
        <MediaPreview
          open={openPreview}
          imageString={preview}
          onClose={() => setOpenPreview(false)}
        />
      )}
    </>
  );
}

export default FileUploadCard;
