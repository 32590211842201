import { SakariAPIResponse } from '@sakari-io/sakari-typings';
import { formatISO } from 'date-fns';
import { sakariApi } from './rtk';
import { getAccountId, buildGridQuery } from './common';
import { AccountIdWith } from '../types';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    // TODO: Add typings
    getCalls: builder.query<SakariAPIResponse<any>, AccountIdWith<any>>({
      query: ({ request }) => ({
        url: `accounts/${getAccountId()}/calls?${buildGridQuery(
          request,
          (v: any, k: any) => {
            if (['from', 'to'].includes(k)) {
              return v ? formatISO(v) : v;
            }

            return v;
          },
        )}`,
        method: 'GET',
      }),
    }),

    getCall: builder.query<SakariAPIResponse<any>, any>({
      query: ({ id }) => `accounts/${getAccountId()}/calls/${id}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCallsQuery, useGetCallQuery } = extendedApi;
