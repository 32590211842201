import React from 'react';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Group } from '@sakari-io/sakari-typings';
import GroupSelect from '../../../ui/organisms/forms/GroupSelect';

interface NewMessageButtonProps {
  accountId: string;
  group?: Group;
  onClick: (group?: Group) => void;
  disabled?: boolean;
}

function NewMessageButton({
  accountId,
  group,
  disabled,
  onClick,
}: NewMessageButtonProps) {
  const { t } = useTranslation();
  return (
    <>
      {group?.id && ['unassigned', 'all'].includes(group.id) ? (
        <GroupSelect
          accountId={accountId}
          value={group}
          onChange={onClick}
          disabled={disabled}
          altLabel={t('newItem', { item: t('items.message_one') })}
          variant="primary"
          collapsedStyle={{ minWidth: 100, fontWeight: 600 }}
        />
      ) : (
        <Button disabled={disabled} onClick={() => onClick(group)}>
          {t('newItem', { item: t('items.message_one') })}
        </Button>
      )}
    </>
  );
}
export default NewMessageButton;
