import React from 'react';
import { Stack } from '@mui/joy';

/**
 *
 * @param sidenav
 * @returns template for a full screen layout with a side navigation
 */
function FullScreenWithSideNav({
  sidenav,
  main,
  position = 'left',
}: {
  sidenav: React.ReactNode;
  main: React.ReactNode;
  position?: 'left' | 'right';
}) {
  return (
    <Stack
      sx={{
        position: 'relative',
        top: 0,
        left: 0,
        height: '100%',
        width: '100vw',
        overflow: 'hidden',
        flexFlow: 'row nowrap',
      }}
    >
      {position === 'left' ? sidenav : null}
      <Stack
        sx={{
          minHeight: '100%',
          minWidth: 0,
          position: 'relative',
          flex: 'auto',
          flexFlow: 'column nowrap',
          backgroundColor: 'background.level1',
        }}
      >
        {main}
      </Stack>
      {position === 'right' ? sidenav : null}
    </Stack>
  );
}
export default FullScreenWithSideNav;
