import React from 'react';
import { useNavigate } from 'react-router-dom';
import Group, { GroupViewMode } from '..';
import AutoResponsesGrid from '../../../ui/organisms/datagrids/AutoResponsesGrid';

function AutoResponses() {
  const navigate = useNavigate();

  return (
    <Group view={GroupViewMode.AUTORESPONDERS}>
      <AutoResponsesGrid onSelectedRowChange={(row: any) => navigate(row.id)} />
    </Group>
  );
}

export default AutoResponses;
