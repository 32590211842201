import React from 'react';
import { Stack, Switch } from '@mui/joy';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Setting from '../../ui/templates/forms/SettingForm';
import CustomDomain from '../../ui/organisms/Dialogs/CustomDomain';
import FieldWrapper from '../../utils/FieldWrapper';

function Advanced() {
  const { t } = useTranslation();
  const { values } = useFormState();

  return (
    <Stack
      spacing={2}
      sx={{
        '.MuiSwitch-root': {
          alignSelf: 'flex-start',
        },
      }}
    >
      <Setting
        label={t('settings.advanced.validatePhoneNumbers.label')}
        description={t('settings.advanced.validatePhoneNumbers.description')}
      >
        <FieldWrapper
          component={Switch}
          type="checkbox"
          name="options.verifyNumberType"
        />
      </Setting>

      <Setting
        label={t('settings.advanced.universalMMS.label')}
        description={t('settings.advanced.universalMMS.description')}
      >
        <FieldWrapper
          component={Switch}
          type="checkbox"
          name="options.universalMMS"
        />
      </Setting>

      {/* <Setting
        label={t('settings.advanced.autoLinkShorten.label')}
        description={t('settings.advanced.autoLinkShorten.description')}
      >
        <FieldWrapper
          component={Switch}
          type="checkbox"
          name="options.autoLinkShorten"
        />
      </Setting> */}

      <Setting
        label={t('settings.advanced.customDomain.label')}
        description={t('settings.advanced.customDomain.description')}
        labelInfo={values?.plan.name === 'Trial' && t('trialFeature')}
      >
        <CustomDomain />
      </Setting>
      <Setting
        label={t('settings.advanced.contactTracking.label')}
        description={t('settings.advanced.contactTracking.description')}
      >
        <FieldWrapper
          component={Switch}
          name="options.contactTracking"
          type="checkbox"
        />
      </Setting>
    </Stack>
  );
}

export default Advanced;
