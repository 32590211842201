import React, { useContext, useState } from 'react';
import { Stack } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader } from '@sakari-io/sakari-components';
import { useGetLinkSourceStatsQuery } from '../../../../../api';
import { AccountContext } from '../../../../../contexts/account.context';
import colorArray from './colors';
import Container from '../../../../templates/Container';
import InsightsChart from '../InsightsChart';
import SelectLinkMetric, {
  LinkMetric,
} from '../InsightsChart/SelectLinkMetric';
import SelectTimeRange, { TimeRange } from '../InsightsChart/SelectTimeRange';

interface SourceDetailProps {
  source: any;
  linkId: string;
  index: number;
}

function SourceDetail({ source, linkId, index }: SourceDetailProps) {
  const { account } = useContext(AccountContext);

  // TODO: use this for the chart
  const [metric, setMetric] = useState<LinkMetric>('totalClicks');
  const [timeRange, setTimeRange] = useState<TimeRange>('last7days');

  const { data, isLoading } = useGetLinkSourceStatsQuery(
    account && source
      ? { accountId: account.id, request: { sourceId: source.id, linkId } }
      : skipToken,
  );

  let content;

  if (isLoading) {
    content = <Loader size={100} label="Drawing chart..." />;
  } else if (data?.data) {
    content = (
      <InsightsChart
        sources={[
          {
            source,
            color: colorArray[index],
            data: data.data,
          },
        ]}
      />
    );
  } else {
    content = <div>No data</div>;
  }

  return (
    <Container height={350}>
      <Stack
        sx={{
          p: 2,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          sx={{
            gap: 2,
            flexDirection: 'row',
          }}
        >
          <SelectLinkMetric metric={metric} setMetric={setMetric} />
          <SelectTimeRange timeRange={timeRange} setTimeRange={setTimeRange} />
        </Stack>
        {/* <Button
          variant="outlined"
          color="neutral"
          startDecorator={<FontAwesomeIcon icon={regular('chart-line')} />}
          onClick={() => navigate(`${linkId}`)}
        >
          {t('links.viewInsights')}
        </Button> */}
      </Stack>
      <Stack height={500}>{content}</Stack>
    </Container>
  );
}
export default SourceDetail;
