import React from 'react';
import Chip, { ChipProps } from '@mui/joy/Chip';
import { Country } from '@sakari-io/sakari-typings';
import { Flag } from '@sakari-io/sakari-components';
import { Tooltip } from '@mui/joy';

interface CountryChipProps extends Omit<ChipProps, 'variant'> {
  country: Country;
  selected?: boolean;
  onToggle: (selected: boolean) => any;
}

function CountryChip({
  color = 'neutral',
  size = 'sm',
  country,
  selected,
  onToggle,
  ...chipProps
}: CountryChipProps) {
  const sizeMap = {
    sm: 16,
    md: 24,
    lg: 32,
  };
  const chip = (
    <Chip
      color={color}
      startDecorator={
        <Flag
          country={country}
          rounded
          size={sizeMap[size]}
          style={{
            ...(size === 'sm' && { marginLeft: '-4px' }),
            ...(size === 'md' && { marginLeft: '-8px' }),
          }}
        />
      }
      variant={selected ? 'solid' : 'outlined'}
      onClick={() => onToggle(!selected)}
      size={size}
      {...chipProps}
    >
      {country.code}
    </Chip>
  );

  if (country.name) {
    return (
      <Tooltip title={country.name} size={size} arrow>
        {chip}
      </Tooltip>
    );
  }

  return chip;
}

export default CountryChip;
