import React from 'react';
import _ from 'lodash';
import { Box, SvgIcon, Tooltip } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Message } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { format, parseISO } from 'date-fns';

interface MessageStatusProps {
  message: Message;
}

//TODO this should be centralized
const formatTime = (time?: string) => {
  if (!time) return undefined;
  const date = parseISO(time);
  return format(date, 'h:mm a');
};

const getTooltip = (message: Message, t: any) => {
  if (message.error) {
    return (
      message.error?.description ||
      t('failedToSend', {
        item: formatTime(message.updated?.at),
      })
    );
  }
  return _.capitalize(t(message.status));
};

const iconMap: { [key: string]: any } = {
  new: {
    icon: solid('circle-notch'),
    color: 'neutral',
  },
  scheduled: {
    icon: solid('clock'),
    color: 'neutral',
  },
  sent: {
    icon: solid('check'),
    color: 'neutral',
  },
  queued: {
    icon: solid('check'),
    color: 'neutral',
  },
  delivered: {
    icon: solid('check-double'),
    color: 'neutral',
  },
  read: {
    icon: solid('envelope-open'),
    color: 'neutral',
  },
  unread: {
    icon: solid('envelope'),
    color: 'neutral',
  },
  error: {
    icon: solid('triangle-exclamation'),
    color: 'danger',
  },
  // received: {
  //   icon: solid('check'),
  //   color: 'neutral',
  // },
};

const getIcon = (status: string, sendAt?: string) => {
  if (status === 'new' && sendAt) {
    status = 'scheduled';
  }
  const icon = iconMap[status];

  return icon ? (
    <SvgIcon color={icon.color} fontSize="xs">
      <FontAwesomeIcon icon={icon.icon} />
    </SvgIcon>
  ) : null;
};

function MessageStatus({ message }: MessageStatusProps) {
  const { t } = useTranslation();

  return (
    <Tooltip
      arrow
      title={getTooltip(message, t)}
      placement="bottom-end"
      size="sm"
      sx={{ maxWidth: 300 }}
    >
      <Box>{getIcon(message.status, message.sendAt)}</Box>
    </Tooltip>
  );
}

export default MessageStatus;
