import React from 'react';
import { Box, IconButton } from '@mui/joy';
import { WorkflowNodeType } from '@sakari-io/sakari-typings';

interface NodeMiniProps {
  type: WorkflowNodeType;
  sx?: any;
  size?: 'xs' | 'sm' | 'md';
}

function NodeMini({ type, sx, size = 'md' }: NodeMiniProps) {
  // const color1 = `hsl(from ${type?.color} h 100% 72%)`;
  // const color2 = type?.color;

  const iconSizing = {
    xs: {
      width: '20px',
      height: '20px',
      borderRadius: '4px',
      padding: 0,
    },
    sm: {
      width: '25px',
      height: '25px',
      borderRadius: '7px',
      padding: 0,
    },
    md: {
      width: '35px',
      height: '35px',
      borderRadius: '10px',
      padding: '10px 15px',
    },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        // background: `linear-gradient(to left bottom, ${color2}, ${color1})`,
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

        ...iconSizing[size],
        ...sx,
      }}
    >
      <IconButton variant="soft">
        <img
          src={`https://assets.sakari.io/images/workflows/icons/${type?.icon}`}
          alt={type?.name}
          height={24}
        />
      </IconButton>
    </Box>
  );
}

export default NodeMini;
