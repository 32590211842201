import React from 'react';
import { Stack } from '@mui/joy';
import GroupEdit from '../GroupEdit';

function General() {
  return (
    <Stack sx={{ width: '100%' }}>
      <GroupEdit />
    </Stack>
  );
}

export default General;
