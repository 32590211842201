import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { skipToken } from '@reduxjs/toolkit/query';
import {
  ContactUpdate,
  AccountAttribute,
  Property,
} from '@sakari-io/sakari-typings';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import { AccountContext } from '../../../../../../contexts/account.context';
import { getPrimaryAttributes } from '../../../../../../constants';
import { useGetAttributesByTypeQuery } from '../../../../../../api';

import DynamicAttributeSelect from '../../../../../../ui/molecules/DynamicAttributeSelect';
import { ConfigFieldProps } from '../PropertyField';

interface ContactAttributePopupProps
  extends ConfigFieldProps<ContactUpdate | Property | undefined> {
  fullWidth?: boolean;
  renderButton: (
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
    onClick: () => void,
  ) => React.ReactNode;
  hideButtonOnOpen?: boolean;
  disabledOptions?: string[];
  disabled?: boolean;
}

function LabelComponent({
  item,
  t,
}: {
  item: { label: string; name: string };
  t: (key: string) => string;
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        width: '100%',
        gap: '10px',
        '&:hover': {
          '& svg': {
            color: 'var(--joy-palette-primary-500)',
          },
        },
      }}
    >
      <FontAwesomeIcon icon={faUser} />
      {t(`${item.label || item.name}`)}
    </Stack>
  );
}

function ContactAttributePopup({
  value,
  onChange,
  fullWidth,
  renderButton,
  hideButtonOnOpen,
  disabledOptions,
  disabled,
}: ContactAttributePopupProps) {
  const { t } = useTranslation();

  const getLabel = useCallback(
    (item: any) => <LabelComponent item={item} t={t} />,
    [t],
  );

  const { account } = useContext(AccountContext);
  const [search, setSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const debouncedSearch = useDebouncedValue(search, 300);

  const LIMIT = 20;

  const { data: contactProperties, isLoading } = useGetAttributesByTypeQuery(
    account
      ? {
          accountId: account.id,
          request: {
            type: 'contacts',
            q: debouncedSearch,
            offset,
            limit: LIMIT,
          },
        }
      : skipToken,
  );
  useEffect(() => {
    setOffset(0);
  }, [debouncedSearch]);

  const primaryAttributes = getPrimaryAttributes()
    .map((attr) => attr.attribute)
    .map((attr) => ({
      name: attr,
      label: t(attr),
      value: attr,
    }));

  const allContactProperties = [
    ...(primaryAttributes || []),
    ...(contactProperties?.data || []),
  ];

  const isCoreAttribute = (name: string) =>
    !!primaryAttributes.find((a) => a.name === name);

  const handleChange = (newValue: AccountAttribute) => {
    onChange({
      name: isCoreAttribute(newValue.name)
        ? newValue.name
        : `attributes.${newValue.name}`,
      label: newValue.label,
    });
  };

  return (
    <DynamicAttributeSelect
      value={value}
      onChange={handleChange}
      attributes={
        (search.length ? contactProperties?.data : allContactProperties) || []
      }
      isLoading={isLoading}
      fetchAttributes={setSearch}
      renderLabel={getLabel}
      hideButtonOnOpen={hideButtonOnOpen}
      renderButton={renderButton}
      fullWidth={fullWidth}
      disabledOptions={disabledOptions}
      disabled={disabled}
    />
  );
}

export default ContactAttributePopup;
