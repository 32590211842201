import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button, IconButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FieldDivider from '../FieldDivider';

interface ValueProps<T> {
  value: T;
  onChange: (v: T) => any;
}

interface MultipleWrapperProps<T> {
  component: React.ComponentType<ValueProps<T>>;
  value: T[];
  onChange: (v: T[]) => void;
  addDeleteButton?: boolean;
  addFieldDivider?: boolean;
  sx?: any;
  disabled?: boolean;
}

function MultipleWrapper<T>({
  component: Component,
  value,
  onChange,
  addDeleteButton,
  addFieldDivider,
  sx,
  ...rest
}: MultipleWrapperProps<T>): React.ReactElement {
  const { t } = useTranslation('workflows');

  const { disabled } = rest;

  const handleAdd = () => {
    onChange([...value, {} as T]);
  };

  const handleDelete = (index: number, deleteField = false) => {
    if (deleteField && index < value.length - 1) {
      onChange([...value.slice(0, index + 1), ...value.slice(index + 2)]);
    } else {
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    }
  };

  return (
    <Stack sx={{ '& .MuiStack-root': { marginTop: '0px !important' } }}>
      {value.length > 0 ? (
        value.map((v, i) => (
          <Stack key={i}>
            <Stack direction="row" sx={{ ...sx }}>
              <Component
                {...rest}
                value={v}
                onChange={(newVal) =>
                  onChange([
                    ...value.slice(0, i),
                    newVal,
                    ...value.slice(i + 1),
                  ])
                }
              />

              {addDeleteButton && (
                <IconButton onClick={() => handleDelete(i)} disabled={disabled}>
                  <FontAwesomeIcon icon={regular('trash')} />
                </IconButton>
              )}
            </Stack>

            {addFieldDivider && i !== value?.length - 1 && (
              <FieldDivider
                buttonText={t('and')}
                onClick={() => handleDelete(i, true)}
                icon={<FontAwesomeIcon icon={regular('trash')} />}
                disabled={disabled}
              />
            )}
          </Stack>
        ))
      ) : (
        <Component
          value={{} as T}
          onChange={(newVal) => onChange([newVal])}
          {...rest}
        />
      )}
      <Stack alignItems="center" pt={1}>
        <Button
          variant="outlined"
          color="neutral"
          onClick={handleAdd}
          startDecorator={<FontAwesomeIcon icon={solid('plus')} />}
          disabled={disabled}
        >
          {t('add')}
        </Button>
      </Stack>
    </Stack>
  );
}

export default MultipleWrapper;
