import { PhoneNumber, TenDlcCampaign } from '@sakari-io/sakari-typings';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/joy';
import { Loader } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGet10DLCCampaignQuery,
  useGetPhoneNumbersQuery,
} from '../../../api';
import PageHeader from '../../../ui/organisms/headers/PageHeader';
import BulkRegister from './BulkRegister';
import TenDLCRegistration from './TenDLCRegistration';
import TollFreeVerification from './TollFreeVerification';
import { AccountContext } from '../../../contexts/account.context';
import TenDLCIntro from './TenDLCRegistration/TenDLCIntro';
import TollFreeIntro from './TollFreeVerification/TollFreeIntro';

const renderIntro = (
  subType: string,
  setConfirmed: Dispatch<SetStateAction<boolean>>,
  reason?: string,
) => {
  switch (subType) {
    case 'tollfree':
      return <TollFreeIntro setConfirmation={setConfirmed} />;
    case 'local':
      return <TenDLCIntro setConfirmation={setConfirmed} reason={reason} />;
    default:
      return null;
  }
};

const renderForm = (
  subType: string,
  selectedPhoneNumbers: PhoneNumber[],
  campaign?: TenDlcCampaign,
) => {
  switch (subType) {
    case 'tollfree':
      return <TollFreeVerification phoneNumbers={selectedPhoneNumbers} />;
    case 'local':
      return (
        <TenDLCRegistration
          senders={selectedPhoneNumbers}
          campaign={campaign}
        />
      );
    default:
      return null;
  }
};

function SendersRegisterPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subType } = useParams();
  const location = useLocation();
  const { account } = useContext(AccountContext);

  const initialPn = location.state?.sender as PhoneNumber;

  const { data, isLoading, isFetching } = useGetPhoneNumbersQuery(
    account && initialPn
      ? {
          accountId: account.id,
          request: {
            country: initialPn.country,
            type: initialPn.type,
            provider: initialPn.provider,
            status: 'active',
            limit: 50,
            regulatory: 'unregistered',
          },
        }
      : skipToken,
  );

  const { data: campaignData, isLoading: isLoadingCampaignData } =
    useGet10DLCCampaignQuery(
      account?.id && initialPn?.channels?.sms?.regulatory?.campaign?.id
        ? {
            accountId: account?.id,
            request: initialPn.channels.sms.regulatory.campaign.id,
          }
        : skipToken,
    );

  // const [similarSenders, setSimilarSenders] = useState<Sender[]>([]);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<
    PhoneNumber[]
  >([initialPn]);

  const [showForm, setShowForm] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (!initialPn || !subType) {
      navigate('../senders', { replace: true });
    }
  }, []);

  useEffect(() => {
    setShowForm(data?.data.length === 1 || data?.data.length === 0);
  }, [data]);

  return (
    <Stack
      sx={{
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        flexFlow: 'column',
        backgroundColor: 'background.default',
      }}
    >
      <PageHeader
        title={t('senders.register.title')}
        subheading={t(`senders.register.${subType}.title`)}
        backAction={() => navigate('../senders')}
        cancelText={t('cancelRegistration')}
        cancelLink="/senders"
      />
      <Stack sx={{ flexGrow: 1, overflow: 'hidden' }}>
        {/* Loader */}
        {(isLoading || isLoadingCampaignData || isFetching) && (
          <Stack
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader size={200} label="" />
          </Stack>
        )}

        {/* Show Confirmation Intro Page */}
        {!confirmed &&
          subType &&
          !isLoadingCampaignData &&
          renderIntro(
            subType,
            setConfirmed,
            campaignData?.data?.rejection?.description,
          )}

        {/* Show Bulk Registration */}
        {confirmed && !showForm && !isLoading && (
          <BulkRegister
            {...{
              initialPn,
              availablePhoneNumbers: data?.data || [],
              selectedPhoneNumbers,
              setSelectedPhoneNumbers,
              setShowForm,
              setConfirmed,
            }}
          />
        )}

        {/* Show Wizards */}
        {confirmed &&
          showForm &&
          subType &&
          renderForm(subType, selectedPhoneNumbers, campaignData?.data)}
      </Stack>
    </Stack>
  );
}
export default SendersRegisterPage;
