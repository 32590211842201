import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';

import { Loader } from '@sakari-io/sakari-components';
import { Box, Button, Stack } from '@mui/joy';
import { useResetPasswordMutation } from '../../api';

import Setting from '../../ui/templates/forms/SettingForm';
import { showToast } from '../../redux/reducers/toast';
import { useAppDispatch } from '../../redux';
import FieldWrapper from '../../utils/FieldWrapper';
import { TextField } from '../../ui';

function Security() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [newPasswordForm, setPasswordForm] = useState(false);

  const [resetPassword, { isLoading: isUpdating }] = useResetPasswordMutation();

  const submit = async (values: any) => {
    //TODO use handleApiMutationResponse
    await resetPassword(values)
      .unwrap()
      .then(() => {
        dispatch(
          showToast({
            severity: 'success',
            message: t('profileSettings.security.success'),
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message:
              err.data.error.message || t('profileSettings.security.error'),
          }),
        );
      })
      .finally(() => {
        setPasswordForm(false);
      });
  };

  const schema = Yup.object().shape({
    oldPassword: Yup.string().required(
      t('profileSettings.security.currentPassword'),
    ),
    newPassword: Yup.string()
      .required(t('profileSettings.security.newPassword'))
      .min(8, t('profileSettings.security.tooShort'))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
        t('profileSettings.security.mustContain'),
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        t('profileSettings.security.dontMatch'),
      )
      .required(t('profileSettings.security.confirm')),
  });

  const validate = makeValidate(schema);

  const renderForm = () => {
    if (isUpdating) {
      return <Loader size={60} />;
    }
    return newPasswordForm ? (
      <Form
        onSubmit={submit}
        initialValues={{}}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors }) => (
          <Stack spacing={2} width={{ sm: '300px' }}>
            <FieldWrapper
              component={TextField}
              name="oldPassword"
              label={t('profileSettings.security.password.oldPassword')}
              type="password"
              fullWidth
            />
            <FieldWrapper
              component={TextField}
              name="newPassword"
              label={t('profileSettings.security.password.newPassword')}
              type="password"
              fullWidth
            />
            <FieldWrapper
              component={TextField}
              name="confirmPassword"
              label={t('profileSettings.security.password.confirmPassword')}
              type="password"
              fullWidth
            />
            <Box>
              <Button
                variant="solid"
                onClick={handleSubmit}
                disabled={hasValidationErrors}
              >
                {t('profileSettings.security.reset')}
              </Button>
            </Box>
          </Stack>
        )}
      />
    ) : (
      <Button
        variant="outlined"
        color="neutral"
        onClick={() => setPasswordForm(true)}
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        {t('profileSettings.security.resetPassword')}
      </Button>
    );
  };

  return (
    <Stack spacing={3} height="100%">
      <Setting
        label={t('profileSettings.security.password.label')}
        description={t('profileSettings.security.password.description')}
      >
        {renderForm()}
      </Setting>
    </Stack>
  );
}

export default Security;
