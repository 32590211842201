import { Box, Grid } from '@mui/joy';
import { EventType, Webhook } from '@sakari-io/sakari-typings';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import isURL from 'validator/es/lib/isURL';
import {
  useCreateWebhookMutation,
  useUpdateWebhookMutation,
} from '../../../../../../api/webhooks';
import { AccountContext } from '../../../../../../contexts/account.context';
import FieldWrapper from '../../../../../../utils/FieldWrapper';
import { AppDialog, TextField } from '../../../../../../ui';
import { useAppDispatch } from '../../../../../../redux';
import { showToast } from '../../../../../../redux/reducers/toast';
import Checkboxes from '../../../../../../ui/organisms/Checkboxes';

const WEB_HOOK_TYPE_NAME = 'webhook';

interface ManageWebhookDialogProps {
  open: boolean;
  webhook?: Webhook;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

function ManageWebhookDialog({
  open,
  setOpen,
  webhook,
  onClose,
}: ManageWebhookDialogProps) {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notifications = [
    {
      value: EventType.MessageReceived,
      label: t('settings.api.webhooks.notificationTypes.messageReceived'),
    },
    {
      value: EventType.MessageSent,
      label: t('settings.api.webhooks.notificationTypes.messageSent'),
    },
    {
      value: EventType.MessageStatus,
      label: t('settings.api.webhooks.notificationTypes.messageStatus'),
    },
    {
      value: EventType.ContactCreated,
      label: t('settings.api.webhooks.notificationTypes.contactCreated'),
    },
    {
      value: EventType.ContactUpdated,
      label: t('settings.api.webhooks.notificationTypes.contactUpdated'),
    },
    {
      value: EventType.ContactRemoved,
      label: t('settings.api.webhooks.notificationTypes.contactRemoved'),
    },
    {
      value: EventType.ContactOptOut,
      label: t('settings.api.webhooks.notificationTypes.contactOptOut'),
    },
    {
      value: EventType.ContactOptIn,
      label: t('settings.api.webhooks.notificationTypes.contactOptIn'),
    },
    {
      value: EventType.ConversationStarted,
      label: t('settings.api.webhooks.notificationTypes.conversationStarted'),
    },
    {
      value: EventType.ConversationClosed,
      label: t('settings.api.webhooks.notificationTypes.conversationClosed'),
    },
  ];

  const [updateWebhook, { isLoading }] = useUpdateWebhookMutation();
  const [createWebhook] = useCreateWebhookMutation();

  const schema = Yup.object().shape({
    destination: Yup.string()
      .required(t('settings.api.webhooks.validations.destination.required'))
      .test('custom-url', t('customDomain.error.invalidURL'), (value) =>
        isURL(value || '', {
          require_protocol: false,
          require_valid_protocol: true,
        }),
      ),
  });

  const validate = makeValidate(schema);

  const onSubmit = async (values: any) => {
    const webhook: Webhook = {
      eventTypes: Object.entries(values.eventTypes)
        .map(([key, value]) => {
          if (value) {
            return key as EventType;
          }

          return undefined;
        })
        .filter((value) => value) as EventType[],
      type: WEB_HOOK_TYPE_NAME,
      destination: values.destination,
    };
    if (values?.id) {
      await updateWebhook({
        accountId: account?.id || '',
        request: {
          id: values?.id,
          ...webhook,
        },
      })
        .unwrap()
        .then(() => onClose());
    } else {
      await createWebhook({
        accountId: account?.id || '',
        request: webhook,
      })
        .unwrap()
        .catch((err) => {
          dispatch(
            showToast({
              severity: 'error',
              message:
                err?.data?.error?.message || t('settings.api.webhooks.error'),
            }),
          );
        });
    }
  };

  const formifyWebhook = (webhook?: Webhook) => ({
    ...webhook,
    eventTypes: webhook?.eventTypes.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {} as any),
  });

  return open ? (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={formifyWebhook(webhook)}
      render={({ handleSubmit, hasValidationErrors, dirty }) => {
        return (
          <AppDialog
            open={open}
            setOpen={setOpen}
            showHeaderIcon={false}
            header={t('settings.api.webhooks.manageWebhook.notificationTitle')}
            confirmButtonProps={{
              children: t('settings.api.webhooks.manageWebhook.saveButton'),
              type: 'submit',
              disabled: !dirty || hasValidationErrors,
              loading: isLoading,
              onClick: handleSubmit,
            }}
            cancelButtonProps={{
              children: t('cancel'),
              onClick: onClose,
            }}
            onClose={onClose}
          >
            <form onSubmit={handleSubmit}>
              <Grid container pb={2}>
                <FieldWrapper
                  component={Checkboxes}
                  items={notifications}
                  name="eventTypes"
                  size="sm"
                  columns={2}
                  row
                />
              </Grid>
              <Box className="destination">
                <FieldWrapper
                  component={TextField}
                  name="destination"
                  label={t(
                    'settings.api.webhooks.manageWebhook.destinationFieldLabel',
                  )}
                />
              </Box>
            </form>
          </AppDialog>
        );
      }}
    />
  ) : null;
}

export default ManageWebhookDialog;
