import React from 'react';
import { Chip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowProgress } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';

const getLastPropertyPart = (name: string) => {
  const parts = name.split('.');
  return parts[parts.length - 1];
};

interface WorkflowOutputPropertyChipProps {
  nodeId: string;
  path: string;
}

function WorkflowOutputPropertyChip({
  nodeId,
  path,
}: WorkflowOutputPropertyChipProps) {
  return (
    <Chip
      id={nodeId}
      color="primary"
      startDecorator={<FontAwesomeIcon icon={faArrowProgress} />}
      slotProps={{
        label: {
          id: nodeId,
        },
      }}
    >
      {_.startCase(getLastPropertyPart(path))}
    </Chip>
  );
}

export default WorkflowOutputPropertyChip;
