import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/joy';
import React, { useContext } from 'react';
import Setting from '../../../ui/templates/forms/SettingForm';

import SlackAssignGroups from '../../../ui/organisms/forms/SlackAssignGroups';
import FieldWrapper from '../../../utils/FieldWrapper';

import Integration from '../IntegrationEdit';
import { AccountContext } from '../../../contexts/account.context';

function Slack() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);

  return (
    <Integration name="slack">
      <Stack>
        <Setting
          label={t('integrations.slack.assignGroups.label')}
          description={t('integrations.slack.description')}
          spacer="0px 30px 0px 0px"
        >
          <FieldWrapper
            component={SlackAssignGroups}
            name="groups"
            account={account}
          />
        </Setting>
      </Stack>
    </Integration>
  );
}

export default Slack;
