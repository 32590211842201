import React, { useContext, useEffect } from 'react';
import { Link, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { useForm, useFormState } from 'react-final-form';
import { TenDlcBrand } from '@sakari-io/sakari-typings';
import { Loader } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGet10DLCBrandsQuery } from '../../../../../api';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import SelectableCardGroup from '../../../../../ui/molecules/groups/SelectableCardGroup';
import { AccountContext } from '../../../../../contexts/account.context';
import BrandDetails from './Details';
import StatusChip from '../../../../../ui/organisms/ChipContexts/StatusChip';

interface BrandFormProps {
  onSubmit?: (form: any) => void;
}

// interface Brand {
//   id: string;
//   companyName: string;
//   displayName: string;
//   email: string;
//   status: string;
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BrandForm({ onSubmit }: BrandFormProps) {
  const { t } = useTranslation();
  const { account, profile } = useContext(AccountContext);
  const { values } = useFormState();
  const { brand } = values;

  const form = useForm();

  const {
    data: brands,
    isLoading,
    isSuccess,
  } = useGet10DLCBrandsQuery(account ? account.id : skipToken);

  const createBrand = () =>
    form.change('brand', { status: 'NEW', email: profile?.email });

  useEffect(() => {
    if (isSuccess && brands?.data?.length === 0) {
      createBrand();
    }
  }, [isSuccess, brands, form]);

  if (isLoading) {
    return <Loader size={200} label="Searching for existing brands..." />;
  }

  if (brand?.status !== 'NEW') {
    return (
      <Stack
        sx={{
          flex: 1,
          gap: 2,
        }}
      >
        {brands?.data && brands.data.length > 0 ? (
          <FieldWrapper
            component={SelectableCardGroup}
            name="brand"
            options={brands.data}
            width={150}
            containerSx={{
              flexFlow: 'row wrap',
            }}
            renderOption={(option: TenDlcBrand) => {
              return (
                <Stack
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography fontWeight="bold">
                    {option.displayName}
                  </Typography>
                  <Typography>{option.id}</Typography>
                  <StatusChip status={option?.status?.toLowerCase() || ''} />
                </Stack>
              );
            }}
          />
        ) : (
          <Typography>{t('10dlc.brand.noBrandsFound')}</Typography>
        )}
        <Link onClick={() => createBrand()}>
          {t('10dlc.brand.addANewBrand')}
        </Link>
      </Stack>
    );
  }

  return (
    <Stack>
      <BrandDetails />
    </Stack>
  );
}

export default BrandForm;
