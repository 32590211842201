import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@sakari-io/sakari-components';
import _ from 'lodash';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';

function IncompleteButton() {
  const { t } = useTranslation();

  return (
    <Tooltip title={_.capitalize(t('statusType.incomplete'))} placement="top">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        color="var(--joy-palette-warning-500)"
        fontWeight={700}
      />
    </Tooltip>
  );
}

export default IncompleteButton;
