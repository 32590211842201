import React, { useState } from 'react';
import { Box, FormHelperText, Chip } from '@mui/joy';
import { Account, Group, Sender } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import { useParams } from 'react-router-dom';
import {
  NumberWithFlag,
  logger,
  useDebouncedValue,
  DropdownSelect,
} from '@sakari-io/sakari-components';
import GroupSendersTable from '../GroupSendersTable';
import { useGetSendersQuery } from '../../../../api';

interface GroupSendersProps {
  account: Account;
  value: Sender[];
  onChange: (value: Sender[]) => any;
  error?: string;
  helperText?: string;
}

function GroupSenders({
  account,
  value,
  onChange,
  error,
  helperText,
}: GroupSendersProps) {
  const { groupId } = useParams();
  const [input, setInput] = useState('');
  const debouncedInput = useDebouncedValue(input, 500);
  const { data } = useGetSendersQuery({
    accountId: account?.id,
    request: { q: debouncedInput },
  });
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <DropdownSelect
          size="md"
          inputValue={input}
          onChange={(v: any) => onChange([...value, v])}
          onInputChange={setInput}
          options={data?.data || []}
          placeholder={t('addSender')}
          value={null}
          getOptionLabel={(option: any) => option.identifier}
          getOptionDisabled={(option: Sender) => {
            const isSelected = value && value?.some((v) => v.id === option.id);
            const isAssigned = (option.groups || []).some(
              (g: Group) => g.id !== groupId,
            );

            return isSelected || isAssigned;
          }}
          renderOption={(props: any, option: any) => {
            logger.info('option', option);
            return (
              <AutocompleteOption
                key={option.id}
                component="li"
                sx={{
                  gap: 2,
                  '& > img': { mr: 2, flexShrink: 0 },
                  fontSize: 'sm',
                }}
                {...props}
              >
                {option.subType !== 'senderid' ? (
                  <NumberWithFlag mobile={option.identifier} />
                ) : (
                  option.identifier
                )}
                {(option.groups || []).map((group: Group) => (
                  <Chip key={group.name}>{group.name}</Chip>
                ))}
              </AutocompleteOption>
            );
          }}
        />
      </Box>
      {helperText ? (
        <FormHelperText color={error ? 'danger' : 'neutral'}>
          {helperText}
        </FormHelperText>
      ) : null}

      <GroupSendersTable value={value || []} onChange={onChange} />
    </>
  );
}

export default GroupSenders;
