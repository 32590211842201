/* eslint-disable consistent-return */
import {
  WorkflowVersionMetrics,
  WorkflowVersion,
  WorkflowEdge,
} from '@sakari-io/sakari-typings';
import { Metrics } from '../../Edit/ElementCard/MetricsTab';
import { MetricType } from '../../../../redux/reducers/workflow';

//TODO this doesn't need to be a hook
const getMetrics = (
  paths: WorkflowVersionMetrics['paths'] | undefined,
  total: number,
  versionData: WorkflowVersion | undefined,
  metricType: MetricType,
  nodeId?: string,
  edgeId?: string,
  // eslint-disable-next-line consistent-return
) => {
  if (!paths) return;

  // store count of edges
  const edgeCountMap = paths.reduce(
    (acc, { edge, count }) => {
      if (edge !== undefined && count !== undefined) {
        acc[edge] = (acc[edge] || 0) + count;
      }
      return acc;
    },
    {} as Record<string, number>,
  );

  // add count to edges and filter them
  const edgesWithCount = (versionData?.definition?.edges || [])
    .map((edge: WorkflowEdge) => {
      if (edgeCountMap[edge.id]) {
        return {
          ...edge,
          count: edgeCountMap[edge.id],
        };
      }
      return edge;
    })
    .filter((edge: WorkflowEdge & { count?: number }) => !!edge.count);

  // calculate total attempts for each source node
  const totalAttemptsMap = edgesWithCount.reduce(
    (acc, edge: WorkflowEdge & { count?: number }) => {
      if (!acc[edge.source]) {
        acc[edge.source] = 0;
      }
      acc[edge.source] += edge.count!;
      return acc;
    },
    {} as Record<string, number>,
  );

  // map each edge to its metrics w/ total attempt count
  const fullMetrics = edgesWithCount.map(
    (edge: WorkflowEdge & { count?: number }) => {
      return {
        passed: edge.type === 'standard' ? edge.count : 0,
        error: edge.type === 'error' ? edge.count : 0,
        timeout: edge.type === 'timeout' ? edge.count : 0,
        edgeId: edge.id,
        sourceId: edge.source,
        targetId: edge.target,
        totalAttempts: totalAttemptsMap[edge.source],
      };
    },
  );

  let metrics;

  if (edgeId) {
    // metric for specific edge by edgeId
    metrics = fullMetrics?.filter((edge) => edge.edgeId === edgeId);

    if (metrics.length > 0) {
      const filteredMetrics = {
        ...metrics[0],
        passed: metrics[0].passed !== 0 ? metrics[0].passed : undefined,
        error: metrics[0].error !== 0 ? metrics[0].error : undefined,
        timeout: metrics[0].timeout !== 0 ? metrics[0].timeout : undefined,
      };

      // if metric type is percentage
      if (metricType === MetricType.PERCENTAGE) {
        filteredMetrics.passed = metrics[0].totalAttempts
          ? parseFloat(
              (
                ((metrics[0].passed || 0) / metrics[0].totalAttempts) *
                100
              ).toFixed(0),
            )
          : 0;
        filteredMetrics.error = metrics[0].totalAttempts
          ? parseFloat(
              (
                ((metrics[0].error || 0) / metrics[0].totalAttempts) *
                100
              ).toFixed(0),
            )
          : 0;
        filteredMetrics.timeout = metrics[0].totalAttempts
          ? parseFloat(
              (
                ((metrics[0].timeout || 0) / metrics[0].totalAttempts) *
                100
              ).toFixed(0),
            )
          : 0;

        // remove error if it's 0
        if (filteredMetrics.error === 0) {
          delete filteredMetrics.error;
        }

        // remove timeout if it's 0
        if (filteredMetrics.timeout === 0) {
          delete filteredMetrics.timeout;
        }
      }

      return filteredMetrics;
    }
  } else if (nodeId) {
    // calc attempts for incoming edges to the node
    const incomingEdges = fullMetrics.filter(
      (edge) => edge.targetId === nodeId,
    );
    const totalIncomingAttempts = incomingEdges.reduce(
      (acc, edge) =>
        acc + (edge.passed || 0) + (edge.error || 0) + (edge.timeout || 0),
      0,
    );

    // metrics for edges coming from the node
    metrics = fullMetrics.filter((edge) => edge.sourceId === nodeId);

    const countsMap = metrics.reduce(
      (acc: Record<string, Metrics>, edge) => {
        if (!acc[edge.sourceId]) {
          acc[edge.sourceId] = {
            passed: 0,
            error: 0,
            timeout: 0,
            totalAttempts: totalIncomingAttempts,
          };
        }
        if (edge.passed !== undefined) {
          acc[edge.sourceId].passed =
            (acc[edge.sourceId].passed ?? 0) + edge.passed;
        }
        if (edge.error !== undefined) {
          acc[edge.sourceId].error =
            (acc[edge.sourceId].error ?? 0) + edge.error;
        }
        if (edge.timeout !== undefined) {
          acc[edge.sourceId].timeout =
            (acc[edge.sourceId].timeout ?? 0) + edge.timeout;
        }
        return acc;
      },
      {} as Record<string, Metrics>,
    );

    const combinedMetrics = Object.keys(countsMap).map((sourceId) => {
      const passed = countsMap[sourceId]?.passed;
      let error = countsMap[sourceId]?.error;
      let timeout = countsMap[sourceId]?.timeout;
      const totalAttempts = countsMap[sourceId]?.totalAttempts;

      const calculatePercentage = (value: number | undefined) => {
        if (totalAttempts) {
          return parseFloat((((value ?? 0) / totalAttempts) * 100).toFixed(0));
        }
        if (value) {
          return parseFloat((((value ?? 0) / total) * 100).toFixed(0));
        }
        return 0;
      };

      const passedPercentage = calculatePercentage(passed);
      let errorPercentage: number | undefined = calculatePercentage(error);
      let timeoutPercentage: number | undefined = calculatePercentage(timeout);

      if (metricType === MetricType.PERCENTAGE) {
        if (errorPercentage === 0) {
          errorPercentage = undefined;
        }
        if (timeoutPercentage === 0) {
          timeoutPercentage = undefined;
        }
        return {
          sourceId,
          passed: passedPercentage,
          error: errorPercentage,
          timeout: timeoutPercentage,
        };
      }

      if (error === 0) {
        error = undefined;
      }
      if (timeout === 0) {
        timeout = undefined;
      }

      return {
        sourceId,
        passed,
        error,
        timeout,
      };
    });

    return combinedMetrics[0];
  }
};

export default getMetrics;
