import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, IconButton, Grid } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Group } from '@sakari-io/sakari-typings';
import _ from 'lodash';
import { styled } from '@mui/joy/styles';
import GroupSelect from '../GroupSelect';
import { getAccountId } from '../../../../api/common';

export interface GroupAssignment {
  id?: string;
  group?: Group;
  destination?: {
    id: string;
    name: string;
  };
  email?: string;
}

export interface AssignGroupProps {
  value: GroupAssignment;
  onChange: (value: GroupAssignment) => any;
  removable: boolean;
  onRemove: () => void;
  renderDestination: (
    value: GroupAssignment,
    onChange: (value: GroupAssignment) => any,
  ) => React.ReactNode;
}

function AssignGroup({
  value,
  onChange,
  removable,
  onRemove,
  renderDestination,
}: AssignGroupProps) {
  const { t } = useTranslation();

  const StyledTableRow = styled(Stack)(() => ({
    flex: 1,
    '&:last-child, &:last-child': {
      borderBottom: 0,
    },
  }));

  const StyledGrid = styled(Grid)(() => ({
    marginLeft: '0px !important',
    flexGrow: 1,
  }));

  return (
    <StyledTableRow
      direction="row"
      spacing={3}
      alignItems="center"
      borderBottom="1px solid var(--joy-palette-neutral-outlinedBorder)"
      width="100%"
    >
      <StyledGrid direction="row" padding="10px" width="50%" lg={6}>
        <GroupSelect
          noGap
          accountId={getAccountId() || ''}
          value={value.group}
          onChange={(group) => onChange(_.assign({}, value, { group }))}
          fullWidth
          size="md"
        />
      </StyledGrid>

      <StyledGrid>{t('to')}</StyledGrid>

      <StyledGrid width="50%">{renderDestination(value, onChange)}</StyledGrid>

      <StyledGrid>
        <IconButton
          variant="plain"
          color="neutral"
          sx={{
            visibility: removable ? 'visible' : 'hidden',
            borderRadius: '50%',
          }}
          size="sm"
          onClick={onRemove}
        >
          <FontAwesomeIcon icon={regular('trash')} />
        </IconButton>
      </StyledGrid>
    </StyledTableRow>
  );
}

export default AssignGroup;
