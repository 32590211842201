import React, { useContext } from 'react';
import { Workflow } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Stack, Typography } from '@mui/joy';
import { useDuplicateWorkflowMutation } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import TextField from '../../../../ui/atoms/inputs/TextField';
import { useAppDispatch } from '../../../../redux';
import { handleApiMutationResponse } from '../../../../utils/api';
import FormDialog from '../../../../components/molecules/FormDialog';
import FieldWrapper from '../../../../utils/FieldWrapper';

interface DuplicateWorkflowDialogProps {
  open: boolean;
  onClose: () => void;
  workflow: Workflow;
}

function DuplicateWorkflowDialog({
  open,
  onClose,
  workflow,
}: DuplicateWorkflowDialogProps) {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation('workflows');
  const dispatch = useAppDispatch();
  const [duplicateWorkflow] = useDuplicateWorkflowMutation();

  const schema = Yup.object().shape({
    name: Yup.string().required(t('error.name')),
  });

  const validate = makeValidate(schema);

  const onSubmit = async ({ name }: any) => {
    if (!account || !workflow) return undefined;

    const request = {
      accountId: account.id,
      request: { id: workflow?.id, name },
    };

    return handleApiMutationResponse(
      duplicateWorkflow(request).unwrap(),
      dispatch,
      {
        successMessage: t('edit.duplicate.success'),
        defaultErrorMessage: t('edit.duplicate.error'),
      },
    ).then(() => onClose());
  };

  return (
    <FormDialog
      open={open}
      title={t('edit.duplicate.header')}
      color="primary"
      size="lg"
      primaryAction={onSubmit}
      primaryTitle="Duplicate"
      cancelAction={onClose}
      cancelTitle="Cancel"
      validate={validate}
      initialValues={{ name: `${workflow.name} - ${t('create.copy')}` }}
    >
      <Stack spacing={1}>
        <Typography level="body-md">
          This will duplicate the most recently saved version of this workflow
        </Typography>
        <FieldWrapper
          component={TextField}
          name="name"
          label={t('edit.newName')}
          fullWidth
        />
      </Stack>
    </FormDialog>
  );
}

export default DuplicateWorkflowDialog;
