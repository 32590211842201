import React from 'react';
import { Modal, ModalProps } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import Drawer from '../../templates/Drawer';
import DrawerHeader from '../DrawerHeader';

interface AppDrawerProps extends ModalProps {
  position?: 'top' | 'bottom' | 'left' | 'right';
  header?: React.ReactNode | string;
  headerContent?: React.ReactNode;
  sx?: SxProps;
}

function AppDrawer({
  open,
  onClose,
  hideBackdrop,
  header,
  headerContent,
  position = 'right',
  children,
  sx,
}: AppDrawerProps) {
  return (
    <Modal
      hideBackdrop={hideBackdrop}
      open={open}
      onClose={onClose}
      keepMounted
    >
      <div aria-hidden>
        <Drawer
          isOpen={open}
          position={position}
          sx={{
            width: '380px',
            overflow: 'auto',
            borderRadius: '16px 0 0 16px',
            ...sx,
          }}
        >
          {header && (
            <DrawerHeader
              title={typeof header === 'string' ? header : undefined}
            >
              {typeof header !== 'string' && header}
              {headerContent}
            </DrawerHeader>
          )}

          {children}
        </Drawer>
      </div>
    </Modal>
  );
}
export default AppDrawer;
