import config from './config';

let getAccessTokenSilently = null;

const sec = {
  getAccessTokenSilently: () => {
    if (
      !getAccessTokenSilently ||
      typeof getAccessTokenSilently !== 'function'
    ) {
      return null;
    }
    return getAccessTokenSilently({
      audience: config.auth0.audience,
      scope: config.auth0.scopes,
    });
  },
  setAccessTokenSilently: (func) => {
    getAccessTokenSilently = func;
  },
};

export default sec;
