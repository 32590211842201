import React from 'react';
import {
  ListItem,
  ListItemContent,
  Typography,
  ListItemDecorator,
  IconButton,
  Tooltip,
} from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Contact } from '@sakari-io/sakari-typings';
import { NumberWithFlag } from '@sakari-io/sakari-components';
import { format } from '@sakari-io/sakari-common';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface ContactListItemProps {
  contact: Contact;
  onSelect: (contact: Contact) => any;
  onAction: (contact: Contact) => any;
}

function ContactListItem({
  contact,
  onSelect,
  onAction,
}: ContactListItemProps) {
  const isCallable = contact.mobile && contact.valid && !contact.blocked;

  return (
    <ListItem
      key={contact.id}
      sx={{
        borderBottom: `1px solid ${'var(--joy-palette-voice-softBg)'}`,
      }}
      onClick={() => onSelect(contact)}
    >
      <ListItemContent>
        <Typography
          sx={{
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          {contact.firstName || contact.lastName
            ? format.name(contact)
            : 'Unknown'}
        </Typography>

        <Typography level="body-xs" sx={{ color: 'white' }}>
          <NumberWithFlag mobile={contact?.mobile || ''} size={13} />
        </Typography>
      </ListItemContent>
      <Tooltip
        size="sm"
        title={isCallable ? `Call ${contact?.mobile?.number}` : 'Unreachable'}
        placement="left"
      >
        <ListItemDecorator>
          <IconButton
            size="smRound"
            onClick={() => onAction(contact)}
            disabled={!isCallable}
            sx={{
              '&:hover': {
                background: 'transparent',
                svg: {
                  filter: 'drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5))',
                },
              },
            }}
          >
            <FontAwesomeIcon
              icon={isCallable ? solid('phone') : solid('phone-xmark')}
              style={{
                color: 'white',
                opacity: isCallable ? 1 : 0.5,
              }}
            />
          </IconButton>
        </ListItemDecorator>
      </Tooltip>
    </ListItem>
  );
}

export default ContactListItem;
