import React from 'react';
import { IconButton, IconButtonProps } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CallStatus } from '@sakari-io/sakari-typings';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';

export interface DialIconButtonProps extends IconButtonProps {
  disabled: boolean;
  onClick: () => void;
  status?: CallStatus;
}

function DialIconButton({ disabled, status, onClick }: DialIconButtonProps) {
  const calling = status
    ? [CallStatus.Initiated, CallStatus.Ringing].includes(status)
    : false;
  const variant = !calling ? 'success' : 'danger';

  return (
    <IconButton
      variant="solid"
      sx={{
        alignItems: 'center',
        background: `linear-gradient(180deg, var(--joy-palette-${variant}-400) 0%, var(--joy-palette-${variant}-800) 100%)`,
        svg: {
          opacity: 1,
          rotate: !calling ? 0 : '135deg',
          transform: 'scale(1.3)',
        },
        ':hover': {
          backgroundColor: `var(--joy-palette-${variant}-softHoverBg)`,
        },
        ':disabled': {
          background: `linear-gradient(180deg, var(--joy-palette-${variant}-600) 0%, var(--joy-palette-${variant}-900) 100%)`,
          svg: {
            opacity: 0.5,
          },
        },
        boxShadow:
          '0px 1.76px 3.52px -1.76px rgba(16, 24, 40, 0.06), 0px 3.52px 7.04px -1.76px rgba(16, 24, 40, 0.10)',
        // ...sx,
        width: '52.8px',
        height: '52.8px',
      }}
      onClick={onClick}
      // onClick={() => inputNumber && onDial(inputNumber)}
      disabled={disabled} //! inputNumber?.number?.length || !group
    >
      <FontAwesomeIcon icon={faPhone} color="white" />
    </IconButton>
  );
}

export default DialIconButton;
