import { IDable } from '@sakari-io/sakari-typings';
import { ReactElement } from 'react';

export enum ContactDrawerMode {
  VIEW = 'view',
  EDIT = 'edit',
  ADD = 'add',
  CAMPAIGN = 'campaign',
  IMPORT = 'import',
}

export enum CampaignDrawerMode {
  ADD = 'add',
  EDIT = 'edit',
}

export interface ListItemDataRow {
  id: string;
  name: string;
}

export interface StepSimple {
  id: string;
  label?: string;
}
export interface StepDetail extends StepSimple {
  title: string;
  description?: string | ReactElement;
  isLast?: boolean;
  pageComponent?: any;
  isVisible: (state: any) => any;
  subLabel?: (values: any) => string | undefined;
}

export interface Option extends IDable {
  label: string;
  value?: any;
  description?: string;
}

export interface ConversationFilter {
  closed?: boolean;
  blocked?: boolean;
  unread?: boolean;
  q?: string;
}

export interface SenderOptions extends IDable {
  incoming?: {
    action?: 'forward' | 'voicemail';
    forwardTo?: string;
    message?: string;
    transcribe?: boolean;
  };
  universalReply?: boolean;
  destinations?: string[];
}

export interface UploadedFile extends File, IDable {
  link?: string;
  preview?: string;
  isUploaded?: boolean;
}

export interface Language extends IDable {
  country: string;
  label?: string;
}
