import * as Yup from 'yup';
import { Hours } from '@sakari-io/sakari-typings';

const hasNoOverlap = (value: Hours, allValues: Hours[]) => {
  if (allValues.length <= 1) return true;

  return allValues.every((test) => {
    if (test === value) return true;
    if (
      Math.max(value.start.hour, test.start.hour) >=
      Math.min(value.end.hour, test.end.hour)
    ) {
      return true;
    }
    return false;
  });
};

const isEndAfterStart = (value: Hours) => {
  const { start, end } = value;
  if (
    start.hour > end.hour ||
    (start.hour === end.hour && start.minute >= end.minute)
  ) {
    return false;
  }
  return true;
};

const timeCheck = (
  days: string[],
  validTimeRangeMessage: string,
  noOverlapsMessage: string,
) => {
  const timeRangeScheme = Yup.array().of(
    Yup.object()
      .shape({
        start: Yup.object()
          .shape({
            hour: Yup.number().required().integer().min(0).max(23),
            minute: Yup.number().required().integer().min(0).max(59),
          })
          .required(),
        end: Yup.object()
          .shape({
            hour: Yup.number().required().integer().min(0).max(23),
            minute: Yup.number().required().integer().min(0).max(59),
          })
          .required(),
      })
      .test('end-after-start', validTimeRangeMessage, (value) =>
        isEndAfterStart(value),
      )
      .test('no-overlaps', noOverlapsMessage, (value: any, context: any) =>
        hasNoOverlap(value, context.parent),
      ),
  );
  return Yup.object().shape({
    times: Yup.object().shape(
      days.reduce((acc: any, day) => {
        acc[day] = timeRangeScheme;
        return acc;
      }, {}),
    ),
  });
};

export default timeCheck;
