import React, { FC, useEffect } from 'react';
import {
  WithAuthenticationRequiredOptions,
  useAuth0,
} from '@auth0/auth0-react';
import VerifyEmail from '../pages/VerifyEmail';

// eslint-disable-next-line react/jsx-no-useless-fragment
const defaultOnRedirecting = (): JSX.Element => <></>;

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

const withAccountRequired = <P extends object>(
  Component: React.ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      claimCheck = (): boolean => true,
      loginOptions,
    } = options;

    /**
     * The route is authenticated if the user has valid auth and there are no
     * JWT claim mismatches.
     */
    const routeIsAuthenticated = isAuthenticated && claimCheck(user);

    useEffect(() => {
      if (isLoading || routeIsAuthenticated) {
        return;
      }
      const opts = {
        ...loginOptions,
        appState: {
          ...(loginOptions && loginOptions.appState),
          returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
        },
      };
      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }, [
      isLoading,
      routeIsAuthenticated,
      loginWithRedirect,
      loginOptions,
      returnTo,
    ]);

    if (routeIsAuthenticated) {
      if (!user?.email_verified) {
        return <VerifyEmail />;
      }

      return <Component {...props} />;
      // return (<Box></Box>)
    }
    return onRedirecting();
  };
};

function EmailVerifiedRoute({
  component,
}: {
  component: React.ComponentType<object>;
}) {
  const Component = withAccountRequired(component);
  return <Component />;
}

export default EmailVerifiedRoute;
