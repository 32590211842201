import React from 'react';
import { Button, Stack, Typography } from '@mui/joy';
import SearchBar, { SearchBarProps } from '../../../molecules/inputs/SearchBar';

interface SearchableDataGridProps extends SearchBarProps {
  renderDataGrid: () => JSX.Element;
  gridHeight?: number;
  title?: string;
  action?: any;
  actionLabel?: string | JSX.Element;
}
function SearchableDataGrid({
  renderDataGrid,
  title,
  action,
  size = 'md',
  actionLabel,
  ...searchBoxProps
}: SearchableDataGridProps) {
  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        minWidth="70%"
        mb="8px"
      >
        <SearchBar fullWidth size={size} {...searchBoxProps} />
        {action && actionLabel ? (
          <Button color="primary" onClick={action}>
            {actionLabel}
          </Button>
        ) : null}
      </Stack>
      <Stack
        sx={{
          height: '100%',
          pt: 0,
          columnGap: 2,
        }}
      >
        {title && <Typography level="h4">{title}</Typography>}
        {renderDataGrid()}
      </Stack>
    </>
  );
}
export default SearchableDataGrid;
