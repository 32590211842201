import React from 'react';
import { Avatar, AvatarGroup, Box, Link, Stack, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';

const EMPTY_STATE_BASE_DIR = 'https://assets.sakari.io/images/empty/';

interface Auth0TemplateProps {
  children?: React.ReactNode;
  header?: string;
  subheader?: string;
  other?: React.ReactNode;
}

function Auth0Template({
  header,
  subheader,
  other,
  children,
}: Auth0TemplateProps) {
  const { t } = useTranslation();
  const BackdropImages = [
    {
      src: `${EMPTY_STATE_BASE_DIR}referral.png`,
      sx: {
        transform: 'rotate(20deg)',
        top: '-1rem',
        left: '-5rem',
      },
    },
    {
      src: `${EMPTY_STATE_BASE_DIR}campaigns.png`,
      sx: {
        top: '-5rem',
        right: '-5rem',
      },
    },
    {
      src: `${EMPTY_STATE_BASE_DIR}contacts.png`,
      sx: {
        transform: 'rotate(36.135deg)',
        bottom: '-5rem',
        left: '-5rem',
      },
    },
    {
      src: `${EMPTY_STATE_BASE_DIR}phonenumbers.png`,
      sx: {
        transform: 'rotate(9deg)',
        bottom: '-1rem',
        right: '-5rem',
      },
    },
  ];
  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '100vh',
        backgroundColor: 'white',
        overflow: 'hidden',
      }}
    >
      <Stack
        display={{ xxs: 'none', xs: 'none', sm: 'none', md: 'flex' }}
        sx={{
          position: 'relative',
          flex: 1,
          height: '100%',
          background:
            'var(--joy-palette-gradient-primary, linear-gradient(41deg, #1871ED 0%, #6FABFF 100%))',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack
          sx={{
            paddingX: 12,
            justifyContent: 'flex-start',
            gap: 4,
          }}
        >
          {BackdropImages.map((backdrop) => (
            <Box
              key={backdrop.src}
              sx={{
                height: '15rem',
                position: 'absolute',
                filter: 'brightness(0) invert(1) opacity(0.5)',
                pointerEvents: 'none',
                ...backdrop.sx,
              }}
            >
              <img
                alt="Background"
                src={backdrop.src}
                style={{ height: '100%' }}
              />
            </Box>
          ))}
          <Stack gap={4}>
            {/* Hide at 960px vw */}
            <Typography level="h1" textColor="white">
              {header ?? t('registration.getStarted')}
            </Typography>
            <Typography textColor="white">
              {subheader ?? (
                <>
                  {t('registration.createFreeAccount') + ' '}
                  <em>{t('registration.noCreditCardRequired')}</em>
                </>
              )}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexFlow: 'row nowrap',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {other ?? (
              <>
                <AvatarGroup>
                  {[0, 1, 2, 3, 4].map((n, i) => (
                    <Avatar
                      key={i}
                      src={`/assets/avatars/Avatar-${n}.png`}
                      sx={{
                        img: {
                          transform: 'scale(1.2)',
                          objectPosition: 'center',
                        },
                      }}
                    />
                  ))}
                </AvatarGroup>
                <Typography textColor="white">
                  {t('registration.joinUsers')}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          height: '100%',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            overflow: 'auto',
          }}
        >
          <Stack
            sx={{
              flex: 1,
              gap: 3,
              px: 4,
              pt: 4,
              alignItems: 'center',
              justifyContent: 'center',
              '& > *': {
                width: '100%',
                maxWidth: '360px',
              },
              pb: {
                xxs: 2,
                xs: 4,
                sm: 4,
                md: 0,
              },
            }}
          >
            {children}
          </Stack>
        </Stack>
        <Stack
          sx={{
            height: 'fit-content',
            padding: {
              xxs: 2,
              xs: 2,
              sm: 2,
              md: 4,
            },
            boxShadow: {
              xxs: '0px -1px 5px rgba(0, 0, 0, 0.1)',
              xs: '0px -1px 5px rgba(0, 0, 0, 0.1)',
              sm: '0px -1px 5px rgba(0, 0, 0, 0.1)',
              md: 'none',
              lg: 'none',
            },
            justifyContent: 'space-between',
            flexFlow: 'row nowrap',
          }}
        >
          <Typography level="body-sm">© Sakari 2024</Typography>
          <Link
            level="body-sm"
            color="neutral"
            href="mailto:help@sakari.io?subject=Account%20Registration%Help"
            startDecorator={<FontAwesomeIcon icon={regular('envelope')} />}
            target="_blank"
            rel="noopener noreferrer"
          >
            help@sakari.io
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Auth0Template;
