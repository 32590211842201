import React from 'react';
import { Account, AvailableIntegration, User } from '@sakari-io/sakari-typings';
import {
  Modal,
  ModalClose,
  ModalDialog,
  ModalDialogProps,
  ModalProps,
} from '@mui/joy';
import NativeIntegrationDetailsDialog from './NativeIntegrationDetailsDialog';
import ZapierIntegrationDetailsDialog from './ZapierIntegrationDetailsDialog';

const renderComponent = (
  account: Account,
  integration: AvailableIntegration | null,
  user: User,
) => {
  switch (integration?.source) {
    case 'sakari':
      return (
        <NativeIntegrationDetailsDialog
          account={account}
          integration={integration}
          user={user}
        />
      );
    case 'zapier':
      return <ZapierIntegrationDetailsDialog integration={integration} />;
    default:
      return null;
  }
};
interface AvailableIntegrationDetailsDialogProps
  extends ModalDialogProps,
    Pick<ModalProps, 'open' | 'onClose'> {
  account: Account;
  integration: AvailableIntegration | undefined;
  user: User;
}

function AvailableIntegrationDetailsDialog({
  account,
  integration,
  user,
  open,
  onClose,
  ...rest
}: AvailableIntegrationDetailsDialogProps) {
  if (integration === undefined) {
    return null;
  }
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog {...rest}>
        <ModalClose />
        {renderComponent(account, integration, user)}
      </ModalDialog>
    </Modal>
  );
}

export default AvailableIntegrationDetailsDialog;
