/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import isURL from 'validator/es/lib/isURL';
import { makeValidate } from 'mui-rff';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { Typography, Stack } from '@mui/joy';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateAccountMutation } from '../../api';
import { AccountContext } from '../../contexts/account.context';
import GeneralPage from './General';
import Users from './Users';
import Advanced from './Advanced';
import Reporting from './Reporting';
import SupportInfo from './SupportInfo';
import API from './API';
import { showToast } from '../../redux/reducers/toast';
import { useAppDispatch } from '../../redux';
import { PageHeader, TabbedNavigation } from '../../ui';
import { NavTabProps } from '../../ui/organisms/headers/TabbedNavigation';

const tabHasError = (tab: NavTabProps, errors: any) =>
  (tab.fields || []).find((field: string) => _.get(errors, field));

const getTabTitle = (tab: NavTabProps, errors: any) => {
  const hasError = tabHasError(tab, errors);
  if (hasError) {
    return (
      <Typography
        endDecorator={
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            style={{
              color: 'var(--joy-palette-warning-500)',
            }}
          />
        }
      >
        {tab.label}
      </Typography>
    );
  }

  return tab.label;
};

const getFirstError = (errors: any): string | undefined => {
  if (_.isString(errors)) {
    return errors;
  }

  const keys = _.keys(errors || {});
  if (keys.length) {
    return getFirstError(errors[keys[0]]);
  }

  return errors;
};

function AccountSettingsPage() {
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation();

  const { account } = useContext(AccountContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const navigationItems: NavTabProps[] = [
    {
      value: 0,
      label: t('settings.general.title'),
      url: '/settings',
      content: <GeneralPage />,
      fields: ['name', 'defaults'],
    },
    {
      label: t('settings.users.title'),
      url: '/settings/users',
      fields: ['options.autoEnroll'],
      content: <Users />,
    },
    {
      label: t('settings.advanced.title'),
      url: '/settings/advanced',
      content: <Advanced />,
    },
    {
      label: t('settings.reporting.title'),
      url: '/settings/reporting',
      content: <Reporting />,
    },
    {
      label: t('settings.supportinfo.title'),
      url: '/settings/supportinfo',
      fields: ['info', 'externalName'],
      content: <SupportInfo />,
    },
    { label: t('settings.api.title'), url: '/settings/api', content: <API /> },
  ];

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  useEffect(() => {
    setSelectedTab(
      _.findIndex(navigationItems, (ni) => ni.url === pathname) || 0,
    );
  }, [pathname]);

  const schema = Yup.object().shape({
    // general validation
    name: Yup.string().required('Account name is required.'),
    billing: Yup.object().shape({
      name: Yup.string().required('Legal business name is required.'),
    }),
    defaults: Yup.object().shape({
      country: Yup.object()
        .shape({
          code: Yup.string(),
        })
        .required(
          t('validation.isRequired', {
            field: t('form.country.label'),
          }),
        )
        .nullable(),
    }),

    // support info validation
    info: Yup.object().shape({
      name: Yup.string().required('External name is required.'),
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email address is required'),
      address: Yup.object().shape({
        line1: Yup.string().required('Street address is required.'),
        line2: Yup.string(),
        city: Yup.string().required('City is required.'),
        state: Yup.string().required('State is required.'),
        postalCode: Yup.string().required('Postal code is required.'),
        country: Yup.object()
          .shape({
            code: Yup.string().required('Country is required.'),
          })
          .nullable(),
      }),
      website: Yup.string()
        .url(t('form.website.invalid'))
        .required('Website is required.'),
      phone: Yup.object()
        .shape({
          number: Yup.string().required('Phone number is required.'),
        })
        .nullable(),
    }),

    // users validation
    options: Yup.object().shape({
      autoEnroll: Yup.object()
        .shape({
          enabled: Yup.boolean(),
          domain: Yup.string().when('enabled', {
            is: (enabled?: boolean) => enabled,
            then: Yup.string()
              .required(
                t('validation.isRequired', {
                  field: t('form.domain.label'),
                }),
              )
              .test(
                'custom-url',
                t('validation.isInvalid', {
                  field: t('form.domain.label'),
                }),
                (value) =>
                  isURL(value || '', {
                    require_protocol: false,
                    require_valid_protocol: true,
                  }),
              ),
          }),
          role: Yup.string()
            .when('enabled', {
              is: (enabled?: boolean) => enabled,
              then: Yup.string().required(
                t('validation.isRequired', {
                  field: t('selectRole'),
                }),
              ),
            })
            .nullable(),
          notifyEmail: Yup.string().when('enabled', {
            is: (enabled?: boolean) => enabled,
            then: Yup.string()
              .email(
                t('validation.isInvalid', {
                  field: t('form.email.label'),
                }),
              )
              .required(
                t('validation.isRequired', {
                  field: t('form.email.label'),
                }),
              ),
          }),
        })
        .nullable(),
    }),
  });

  const validate = makeValidate(schema);
  const dispatch = useAppDispatch();

  const submit = async (values: any) => {
    const autoEnrolled = values.options.autoEnroll.enabled;
    const payload = autoEnrolled
      ? values
      : _.assign(values, {
          options: {
            ...values.options,
            autoEnroll: false,
          },
        });

    //TODO use handleApiMutationResponse
    await updateAccount(payload)
      .unwrap()
      .then(() => {
        dispatch(
          showToast({
            severity: 'info',
            message: t('settings.save.success'),
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err?.data?.error?.message || t('settings.save.error'),
          }),
        );
      });
  };

  const initialValues = useMemo(
    () => ({
      ...account,
      options: {
        ...account?.options,
        autoEnroll: {
          ...account?.options?.autoEnroll,
          enabled: !!account?.options?.autoEnroll?.domain,
        },
      },
    }),
    [account],
  );

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      validate={validate}
      keepDirtyOnReinitialize
      render={({
        handleSubmit,
        form,
        hasValidationErrors,
        errors,
        submitting,
        values,
      }) => {
        const pristine = _.isEqual(values, initialValues);

        if (hasValidationErrors) {
          // blur all invalid fields
          form.getRegisteredFields().forEach((field) => {
            if (
              form.getFieldState(field)?.error &&
              !form.getFieldState(field)?.touched
            ) {
              form.blur(field);
            }
          });
        }

        return (
          <Stack
            component="form"
            onSubmit={handleSubmit}
            style={{
              height: '100vh',
            }}
          >
            <PageHeader
              title={t('settings.title')}
              ctaAction={handleSubmit}
              ctaDisabled={pristine || hasValidationErrors || submitting}
              loading={isUpdating}
              ctaText={hasValidationErrors ? `⚠️ ${t('save')}` : t('save')}
              ctaTooltip={
                hasValidationErrors ? getFirstError(errors) : undefined
              }
            />
            <TabbedNavigation
              tabs={navigationItems}
              selectedTab={selectedTab}
              onTabSelected={(tab) => {
                setSelectedTab(tab);
                window.history.pushState(null, '', navigationItems[tab].url);
              }}
              renderLabel={(item: NavTabProps) => getTabTitle(item, errors)}
              disableNavLink
              divider
            >
              {navigationItems[selectedTab].content ?? null}
            </TabbedNavigation>

            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              style={{ textAlign: 'right', fontSize: 10, padding: 5 }}
              onClick={() => window.location.reload()}
            >
              Version: {process.env.REACT_APP_NOW}
            </div>
          </Stack>
        );
      }}
    />
  );
}

export default AccountSettingsPage;
