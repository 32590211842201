import React, { ReactElement, useState } from 'react';
import { Stack } from '@mui/joy';
import { useAuth0 } from '@auth0/auth0-react';
import { add } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useGetUserProfileQuery } from '../../../../api';
import { downloadReport } from '../../../../utils/downloads';
import SelectableCardGroup from '../../../molecules/groups/SelectableCardGroup';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import { Option } from '../../../../types/ui';
import { AppDialog } from '../../..';
import { AppDialogProps } from '../../../molecules/AppDialog';

const getStartDate = (window: string) => {
  switch (window) {
    case '1w':
      return add(new Date(), { weeks: -1 });
    case '1m':
      return add(new Date(), { months: -1 });
    case '3m':
      return add(new Date(), { months: -3 });
    default:
      throw new Error('Invalid time window');
  }
};

interface ReportsDialogProps extends AppDialogProps {
  type: 'opt-out' | 'summary' | 'detailed';
}

function ReportsDialog({ type, open, setOpen, ...rest }: ReportsDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const options: any[] = [
    {
      id: '1w',
      label: `1 ${t('summaryReport.week')}`,
    },
    {
      id: '1m',
      label: `1 ${t('summaryReport.month')}`,
    },
    {
      id: '3m',
      label: `3 ${t('summaryReport.months')}`,
    },
  ];

  const [dateWindow, setDateWindow] = useState<Option>(options[0]);

  let pathType: string;

  if (type === 'summary') {
    pathType = 'dailycounts';
  } else if (type === 'opt-out') {
    pathType = 'optout';
  } else if (type === 'detailed') {
    pathType = 'detailed';
  } else {
    pathType = '';
  }

  const { getAccessTokenSilently } = useAuth0();
  const { data } = useGetUserProfileQuery();
  const userEmail = data?.data?.email;

  const handleDownload = async () => {
    // start and end dates for api
    const accessToken = await getAccessTokenSilently();
    const start = getStartDate(dateWindow?.id || '1w');
    const end = new Date();
    if (setOpen) {
      setOpen(false);
    }

    dispatch(
      showToast({
        severity: 'info',
        message:
          type === 'detailed'
            ? 'Your report will be sent to your email shortly'
            : 'Your report will begin to download shortly',
      }),
    );
    if (setOpen) {
      setOpen(false);
    }
    // starting file download
    const data = await downloadReport(
      accessToken,
      pathType,
      start,
      end,
      userEmail || '',
    );

    if (type === 'detailed') {
      return;
    }

    const fileUrl = window.URL.createObjectURL(new Blob([data]));

    const link = document.createElement('a');
    link.href = fileUrl;

    link.setAttribute('download', `${type}-report.csv`);
    document.body.appendChild(link);
    link.click();
  };

  function ReportDownloadOptions() {
    return (
      <Stack spacing={2}>
        <SelectableCardGroup
          aspectRatio="2/1"
          width={100}
          options={options}
          value={dateWindow}
          onChange={(newVal) => setDateWindow(newVal as Option)}
          label={t('settings.reporting.pleaseSelectRange.label')}
        />
      </Stack>
    );
  }

  const REPORTS: { [key: string]: { title: string; content?: ReactElement } } =
    {
      detailed: {
        title: 'Message Detail Report',
        content: <ReportDownloadOptions />,
      },
      summary: { title: 'Summary Report', content: <ReportDownloadOptions /> },
      'opt-out': { title: 'Opted Out Report' },
    };

  return (
    <AppDialog
      open={open}
      setOpen={setOpen}
      title={REPORTS[type]?.title}
      content={REPORTS[type]?.content}
      cancelButtonProps={{
        children: t('cancel'),
        onClick: () => setOpen && setOpen(false),
      }}
      confirmButtonProps={{
        children: t('settings.reporting.download.label'),
        onClick: () => handleDownload(),
        disabled: (type !== 'opt-out' && !dateWindow) || !dateWindow,
      }}
      onSubmit={() => handleDownload()}
      {...rest}
    />
  );
}

export default ReportsDialog;
