import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { IDable } from '@sakari-io/sakari-typings';
import SelectableCardGroup from '../../../ui/molecules/groups/SelectableCardGroup';

const options = [
  {
    id: 'OOO',
  },
  {
    id: 'inOffice',
  },
  {
    id: 'always',
  },
  {
    id: 'custom',
  },
];

interface SendDuringOptionsProps {
  value: IDable;
  onChange: (value: IDable) => any;
}

function SendDuringOptions({
  value,
  onChange,
}: SendDuringOptionsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center">
      <SelectableCardGroup
        value={value}
        onChange={(val) => onChange(val as IDable)}
        options={options}
        aspectRatio="3/2"
        width={150}
        renderOption={(option: IDable) => (
          <Stack textAlign="center">
            <Typography fontWeight="bold">
              {option?.id
                ? t(`groupsNav.autoResponders.customMessage.${option.id}`)
                : t(`groupsNav.autoResponders.customMessage.always`)}
            </Typography>
            <Typography level="body-sm">
              {option.id === 'custom'
                ? t('groupsNav.autoResponders.customMessage.chooseYourHours')
                : ''}
            </Typography>
          </Stack>
        )}
      />
    </Stack>
  );
}

export default SendDuringOptions;
