import React from 'react';
import { useTranslation } from 'react-i18next';
import { Account, User } from '@sakari-io/sakari-typings';
import AppMenu from '../../../../molecules/Menu';

const removeScript = (script: HTMLScriptElement) => {
  document.body.removeChild(script);
};

export const handleContactSupport = (prefill?: {
  [key: string]: {
    value: string;
    readOnly?: boolean;
  };
}) => {
  const script = document.createElement('script');
  const prefillString = prefill ? JSON.stringify(prefill) : '{}';

  let scriptText = `
    zE('webWidget', 'show');
    zE('webWidget', 'open');
  `;

  if (prefill) {
    scriptText = `
      zE('webWidget', 'prefill', ${prefillString});
    ${scriptText}`;

    if (prefill.description) {
      const settingsString = JSON.stringify({
        contactForm: {
          name: "Contact Sakari's Support Team",
          fields: [
            {
              id: 'description',
              prefill: {
                '*': prefill.description.value,
              },
            },
          ],
        },
      });

      scriptText = `
        zE('webWidget', 'updateSettings', ${settingsString});
      ${scriptText}`;
    }
  }

  script.text = scriptText;
  document.body.appendChild(script);
  removeScript(script);
};
interface SupportMenuProps {
  account?: Account;
  profile?: User;
}
function SupportMenu({ account, profile }: SupportMenuProps) {
  const { t } = useTranslation();
  return (
    <AppMenu
      items={[
        {
          id: 'help-center',
          label: t('supportMenu.helpCenter'),
          onClick: () =>
            window.open('https://support.sakari.io', '_blank', 'noopener'),
        },
        {
          label: t('supportMenu.contactSupport'),
          onClick: () =>
            handleContactSupport({
              name: {
                value: profile?.firstName || account?.name || '',
              },
              email: {
                value: profile?.email || account?.info?.email || '',
              },
            }),
        },
      ]}
    />
  );
}
export default SupportMenu;
