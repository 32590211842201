import { Box } from '@mui/joy';
import React from 'react';
import { useFormState } from 'react-final-form';
import { StepDetail } from '../../../types';
import Stepper, { StepperProps } from '../Stepper';

interface WizardStepsProps extends Omit<StepperProps, 'steps'> {
  steps: Partial<StepDetail>[];
  withFade?: boolean;
}

function WizardSteps({
  activeStep,
  steps,
  orientation = 'vertical',
  withFade,
  gap = 3,
  connectors = true,
  ...rest
}: WizardStepsProps) {
  const { values } = useFormState();
  return (
    <Box position="relative" width="100%" id="wizard-steps">
      {withFade && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2,
            right: 0,
            top: 0,
            left: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            // background:
            //   activeStep + 1 !== steps.length
            //     ? `linear-gradient(to ${
            //         orientation === 'vertical' ? 'bottom' : 'right'
            //       },  rgba(var(--joy-palette-background-defaultChannel)/0) 0%,
            //     rgba(var(--joy-palette-background-defaultChannel)/0) ${
            //       ((activeStep + 1) / steps.length) * 100
            //     }%,rgba(var(--joy-palette-background-defaultChannel)/1) 100%)`
            //     : 'none',
          }}
        />
      )}
      <Stepper
        steps={steps.map((step, index) => ({
          id: index.toString(),
          label: step.label,
          subLabel: step.subLabel ? step.subLabel(values) : undefined,
        }))}
        gap={gap}
        connectors={connectors}
        activeStep={activeStep}
        orientation={orientation}
        {...rest}
      />
    </Box>
  );
}

export default WizardSteps;
