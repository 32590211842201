import React, { useContext, useEffect, useState } from 'react';
import { Box, Slider, Typography } from '@mui/joy';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faVolume } from '@fortawesome/pro-regular-svg-icons';
import { logger } from '@sakari-io/sakari-components';
import config from '../../../config';
import { AccountContext } from '../../../contexts/account.context';
import CallBarPopup from '../../../ui/organisms/CallBar/CallBarPopup';

export interface RecordingProps {
  callId: string;
  type: 'voicemail' | 'recording';
  theme?: 'dark' | 'light';
  auto?: boolean;
  onClose?: any;
  autoClose?: boolean;
}

// TODO: Improve error handling
function Recording({
  callId,
  type,
  theme,
  auto,
  onClose,
  autoClose,
}: RecordingProps) {
  const { account } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth0();

  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    const audio = new Audio();
    setAudioPlayer(audio);

    const fetchAudio = async () => {
      const token = await getAccessTokenSilently();
      try {
        const response = await axios({
          url: `${config.apiUrl}/accounts/${account?.id}/calls/${callId}/${type}`,
          headers: { Authorization: `Bearer ${token}` },
        });
        logger.info('response.headers.loc', response.headers.location);
        if (response.headers?.location) {
          audio.src = response.headers.location;
        }

        if (auto && audio) {
          handlePlayAudio(audio);
        }
      } catch (error) {
        // TODO show toast
        logger.error('Error fetching audio', error);
      }
    };

    fetchAudio();

    return () => {
      audio.pause();
      URL.revokeObjectURL(audio.src);
    };
  }, [callId, getAccessTokenSilently, account?.id]);

  useEffect(() => {
    if (audioPlayer) {
      const handleLoadedMetadata = () => {
        setDuration(audioPlayer.duration);
      };

      const handleEnded = () => {
        setIsPlaying(false);
        setCurrentTime(0);

        if (autoClose && onClose) {
          onClose();
        }
      };

      const handleTimeUpdate = () => {
        setCurrentTime(audioPlayer.currentTime);
      };

      audioPlayer.addEventListener('timeupdate', handleTimeUpdate);
      audioPlayer.addEventListener('loadedmetadata', handleLoadedMetadata);
      audioPlayer.addEventListener('ended', handleEnded);

      return () => {
        audioPlayer.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audioPlayer.removeEventListener('ended', handleEnded);
        audioPlayer.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
    return undefined;
  }, [audioPlayer]);

  const handlePlayAudio = (audio: HTMLAudioElement) => {
    audio
      .play()
      .then(() => {
        audio.volume = volume;
        setIsPlaying(true);
      })
      .catch((error: any) => {
        logger.error('Error playing audio', error);
      });
  };

  const togglePlayPause = () => {
    if (audioPlayer) {
      setIsPlaying((prev) => {
        if (!prev) {
          handlePlayAudio(audioPlayer);
        } else {
          audioPlayer.pause();
        }
        return !prev;
      });
    }
  };

  const onVolumeChange = (event: any, newValue: any) => {
    const newVolume = newValue / 100;
    if (audioPlayer) {
      audioPlayer.volume = newVolume;
      setVolume(newVolume);
    }
  };

  const onSeek = (event: any, newValue: any) => {
    const newTime = (newValue / 100) * duration;
    if (audioPlayer) {
      audioPlayer.currentTime = newTime;
    }
  };

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const button = (setAnchorEl: any) => {
    return (
      <Box ref={setAnchorEl}>
        <FontAwesomeIcon
          icon={faVolume}
          color={theme === 'dark' ? 'white' : 'var(--joy-palette-primary-500)'}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: 300,
        background:
          theme === 'dark'
            ? 'var(--joy-palette-voice-softBg)'
            : 'var(--joy-palette-primary-100)',
        padding: '0px 12px 0px 8px',
        borderRadius: '40px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px',
        }}
      >
        <Box
          sx={{
            width: '34px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={togglePlayPause}
        >
          {isPlaying ? (
            <FontAwesomeIcon
              icon={faPause}
              color={
                theme === 'dark' ? 'white' : 'var(--joy-palette-primary-500)'
              }
            />
          ) : (
            <FontAwesomeIcon
              icon={faPlay}
              color={
                theme === 'dark' ? 'white' : 'var(--joy-palette-primary-500)'
              }
            />
          )}
        </Box>
        <Box sx={{ width: '74px', display: 'flex', alignItems: 'center' }}>
          <Typography
            level="body-xs"
            sx={{
              color:
                theme === 'dark' ? 'white' : 'var(--joy-palette-neutral-900)',
            }}
          >
            {formatTime(currentTime)} / {formatTime(duration)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          <Slider
            aria-label="time-indicator"
            value={(currentTime / duration) * 100 || 0}
            onChange={onSeek}
            sx={{
              color: 'white',
              '& .MuiSlider-thumb': { width: 8, height: 8 },
            }}
          />
        </Box>
        <Box
          sx={{
            width: '34px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 12px',
            cursor: 'pointer',
          }}
          onClick={() => setShowPopup(!showPopup)}
        >
          <CallBarPopup
            setShowPopup={setShowPopup}
            showPopup={showPopup}
            title=""
            button={button}
            size={{
              display: 'flex',
              height: '34px',
              alignItems: 'center',
              padding: '0 6px',
            }}
          >
            <Box>
              <Slider
                aria-label="Volume"
                value={volume * 100}
                onChange={onVolumeChange}
                sx={{
                  color: 'white',
                  width: 100,
                  '& .MuiSlider-thumb': { width: 8, height: 8 },
                }}
              />
            </Box>
          </CallBarPopup>
        </Box>
      </Box>
    </Box>
  );
}

export default Recording;
