import React, { useState } from 'react';
import { Stack } from '@mui/joy';
import { Category } from '@sakari-io/sakari-typings';
import Accordion from '../../../../ui/templates/Accordion';
import SearchBar from '../../../../ui/molecules/inputs/SearchBar';

interface PropertiesAccordionProps {
  header: React.ReactNode;
  items: Category[];
  renderItem: (item: Category, searchTerm: string) => React.ReactNode;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

function PropertiesAccordion({
  header,
  items,
  renderItem,
  searchTerm,
  setSearchTerm,
}: PropertiesAccordionProps) {
  const [open, setOpen] = useState(true);

  const filteredItems = items.filter((item) =>
    item?.label?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Accordion
      header={header}
      content={
        <Stack spacing={2} p={1}>
          <SearchBar value={searchTerm} onChange={setSearchTerm} />

          <Stack spacing={1}>
            {filteredItems.map((item) => renderItem(item, searchTerm))}
          </Stack>
        </Stack>
      }
      isOpen={open}
      setIsOpen={setOpen}
      sx={{
        backgroundColor: 'var(--joy-palette-common-white)',
        border: '1px solid var(--joy-palette-divider)',
        padding: '0.5rem',
        borderRadius: 'var(--joy-radius-md)',
      }}
      noSpacer
    />
  );
}

export default PropertiesAccordion;
