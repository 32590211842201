import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Box, Typography, Grid, SvgIcon } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@sakari-io/sakari-components';
import AssignGroup, { GroupAssignment } from './AssignGroup';

const styles = {
  add: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    paddingTop: '10px',
    width: '100%',
    justifyContent: 'flex-start',
  },
  inviteButton: { marginRight: '48px' },
};

export interface AssignGroupsProps {
  value: GroupAssignment[];
  onChange: (value: GroupAssignment[]) => any;
  renderDestination: (
    value: GroupAssignment,
    onChange: (value: GroupAssignment) => any,
  ) => React.ReactNode;
  destinationTooltip?: string;
  groupTooltip?: string;
  renderDestinationLabel?: string;
}

function AssignGroups({
  value,
  onChange,
  renderDestination,
  renderDestinationLabel,
  groupTooltip,
  destinationTooltip,
}: AssignGroupsProps) {
  const { t } = useTranslation();

  const handleRemove = (row: GroupAssignment) => {
    onChange(
      value.filter((r) => {
        return r.id !== row.id;
      }),
    );
  };

  const addRow = () => {
    return onChange([...value, { id: uuid() }]);
  };

  return (
    <Stack sx={{ width: 'clamp(600px, 650px, 700px)' }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid
          sx={{
            border: '1px solid var(--joy-palette-neutral-outlinedBorder)',
            width: '100%',
            padding: '0',
            borderRadius: '6px',
            minHeight: '150px',
          }}
        >
          <Box
            sx={{
              borderBottom:
                '1px solid var(--joy-palette-neutral-outlinedBorder)',
              width: '100%',
            }}
          >
            <Grid display="flex">
              <Grid xs={6}>
                <Typography
                  endDecorator={
                    groupTooltip && (
                      <Tooltip
                        title={groupTooltip}
                        arrow
                        sx={{
                          width: 300,
                        }}
                        placement="top"
                      >
                        <SvgIcon color="neutral" fontSize="sm">
                          <FontAwesomeIcon icon={solid('circle-info')} />
                        </SvgIcon>
                      </Tooltip>
                    )
                  }
                >
                  {t('group')}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography
                  endDecorator={
                    renderDestinationLabel &&
                    destinationTooltip && (
                      <Tooltip
                        title={destinationTooltip}
                        arrow
                        sx={{
                          width: 300,
                        }}
                        placement="top"
                      >
                        <SvgIcon color="neutral" fontSize="sm">
                          <FontAwesomeIcon icon={solid('circle-info')} />
                        </SvgIcon>
                      </Tooltip>
                    )
                  }
                >
                  {renderDestinationLabel}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid
            spacing={1}
            width="100%"
            sx={{ justifyContent: 'space-between' }}
          >
            {!value.length ? (
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',

                  p: 2,
                }}
              >
                <Typography>
                  {t('integrations.slack.assignGroups.noGroupsConfigured')}
                </Typography>
              </Stack>
            ) : (
              (value || []).map((row: GroupAssignment, index: number) => (
                <AssignGroup
                  renderDestination={renderDestination}
                  key={index}
                  removable
                  value={row}
                  onChange={(row) =>
                    onChange(
                      value.map((r) => {
                        if (r.id === row.id) {
                          return row;
                        }
                        return r;
                      }),
                    )
                  }
                  onRemove={() => handleRemove(row)}
                />
              ))
            )}
          </Grid>
        </Grid>
        <Box sx={styles.add} onClick={addRow}>
          <FontAwesomeIcon size="sm" icon={solid('plus')} />
          <Typography level="body-md">
            {t('users.invite.addAnother')}
          </Typography>
        </Box>
      </Grid>
    </Stack>
  );
}

export default AssignGroups;
