import React, { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Chip } from '@mui/joy';
import { CircularProgress } from '@sakari-io/sakari-components';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountContext } from '../../../../../contexts/account.context';
import { useGetLinkQuery } from '../../../../../api';

const startDecorator = <FontAwesomeIcon icon={faLink} />;

interface TrackedLinkChipProps {
  id: string;
}

function TrackedLinkChip({ id }: TrackedLinkChipProps) {
  const { account } = useContext(AccountContext);

  const { data, isFetching } = useGetLinkQuery(
    account
      ? {
          accountId: account.id,
          request: id,
        }
      : skipToken,
  );

  if (isFetching) {
    return (
      <Chip color="primary" startDecorator={startDecorator}>
        <CircularProgress size="sm" />
      </Chip>
    );
  }

  return (
    <Chip
      color="primary"
      startDecorator={startDecorator}
      title={data?.data?.destinationUrl}
    >
      {data?.data?.name ?? data?.data?.destinationUrl ?? id}
    </Chip>
  );
}

export default TrackedLinkChip;
