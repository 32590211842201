import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import reducers from './reducers';
import { sakariApi } from '../api/rtk';
import { voiceApi } from '../api/voice';
import rtkQueryLogger from '../api/rtkQueryLogger';

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      sakariApi.middleware,
      voiceApi.middleware,
      rtkQueryLogger,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
