import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';
import _ from 'lodash';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { WorkflowNodeType, WorkflowEdgeType } from '@sakari-io/sakari-typings';
import { Loader } from '@sakari-io/sakari-components';
import { faSplit } from '@fortawesome/pro-solid-svg-icons';
import Helper from '../../../../utils/helper';
import TextField from '../../../../ui/atoms/inputs/TextField';
import {
  useCreateWorkflowMutation,
  useGetWorkflowNodeTypesQuery,
} from '../../../../api';
import FieldWrapper from '../../../../utils/FieldWrapper';
import { AccountContext } from '../../../../contexts/account.context';
import { actions } from '../../../../redux/reducers/workflow';
import { handleApiMutationResponse } from '../../../../utils/api';
import ElementGroup from '../../Edit/ElementGroup';
import FormDialog from '../../../../components/molecules/FormDialog';

interface CreateWorkflowDialogProps {
  open: boolean;
  onClose: () => void;
}

function CreateWorkflowDialog({ open, onClose }: CreateWorkflowDialogProps) {
  const { t } = useTranslation('workflows');
  const { data, isLoading } = useGetWorkflowNodeTypesQuery({
    limit: 100,
  });
  const [createWorkflow] = useCreateWorkflowMutation();

  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const triggers = data?.data.filter((node) => node.type === 'trigger');

  const schema = Yup.object().shape({
    name: Yup.string().required(t('error.name')),
    trigger: Yup.object().required('Trigger type is not selected'),
  });

  const validate = makeValidate(schema);

  const newNodeId = Helper.generateMongoId();

  const onSubmit = (values: any) => {
    const payload = {
      accountId: account?.id || '',
      request: {
        name: values.name,
        definition: {
          nodes: [
            {
              id: newNodeId,
              type: _.pick(values.trigger, ['id', 'name']) as WorkflowNodeType,
              config: {},
            },
          ],
          edges: [
            {
              id: Helper.generateMongoId(),
              source: newNodeId,
              type: WorkflowEdgeType.Standard,
            },
          ],
        },
      },
    };

    return handleApiMutationResponse(
      createWorkflow(payload).unwrap(),
      dispatch,
      {
        onSuccess: (result) => {
          onClose();
          navigate(`/workflows/${result.data.id}`);
          dispatch(actions.enterEditMode());
        },
        defaultErrorMessage: t('error.createError'),
      },
    );
  };

  return (
    <FormDialog
      open={open}
      validate={validate}
      title={t('create.newWorkflow')}
      primaryAction={onSubmit}
      primaryTitle={t('create.newWorkflow')}
      cancelAction={onClose}
      cancelTitle={t('action.cancel')}
      icon={faSplit}
      resetOnClose
      size="lg"
    >
      <Stack
        direction="row"
        width="100%"
        sx={{
          maxHeight: '500px',
        }}
      >
        <Stack
          p={2}
          sx={{
            flex: 1,
          }}
        >
          {isLoading && <Loader />}

          <Stack p={1}>
            <FieldWrapper
              component={TextField}
              name="name"
              label={t('name')}
              placeholder={t('create.nameExample')}
              fullWidth
            />
          </Stack>

          {triggers?.length && (
            <>
              <Typography level="title-md">
                {t('create.enrollmentTrigger')}
              </Typography>
              <Stack p={1} mt={1} sx={{ overflow: 'scroll' }}>
                <FieldWrapper
                  component={ElementGroup}
                  name="trigger"
                  options={triggers}
                />
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </FormDialog>
  );
}

export default CreateWorkflowDialog;
