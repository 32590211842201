import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, EditableLabel, logger } from '@sakari-io/sakari-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Sheet, Stack } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Container from '../../../../ui/templates/Container';
import { useGetLinkQuery, useUpdateLinkMutation } from '../../../../api';
import { PageHeader, StatusAlert } from '../../../../ui';
import { AccountContext } from '../../../../contexts/account.context';
import InsightsChart from '../../../../ui/organisms/datagrids/LinksGrid/InsightsChart';
import { useGetLinkSourcesStatsQuery } from '../../../../api/links';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import SourcesList from './SourcesList';
import SelectLinkMetric, {
  LinkMetric,
} from '../../../../ui/organisms/datagrids/LinksGrid/InsightsChart/SelectLinkMetric';
import SelectTimeRange, {
  TimeRange,
} from '../../../../ui/organisms/datagrids/LinksGrid/InsightsChart/SelectTimeRange';
import colorArray from '../../../../ui/organisms/datagrids/LinksGrid/Sources/colors';
import TextLink from '../../../../ui/molecules/TextLink';

function LinkDetail() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  const params = useParams();
  const { data: linkData, isLoading: isLoadingLinkData } = useGetLinkQuery(
    params.linkId && account
      ? { accountId: account.id, request: params.linkId }
      : skipToken,
  );
  const [showInfo, setShowInfo] = useLocalStorage('show-source-info', true);
  const [metric, setMetric] = useState<LinkMetric>('totalClicks');
  const [timeRange, setTimeRange] = useState<TimeRange>('last7days');
  const [updateLink] = useUpdateLinkMutation();

  const [selectedSources, setSelectedSources] = useState<{
    [key: string]: boolean;
  }>({});

  const { data: sourcesStats, isLoading: isLoadingSourcesStats } =
    useGetLinkSourcesStatsQuery(
      account && params.linkId
        ? {
            accountId: account.id,
            request: {
              linkId: params.linkId || '',
            },
          }
        : skipToken,
    );

  const allSourcesStats = useMemo(() => {
    if (isLoadingSourcesStats) {
      return [];
    }
    return sourcesStats?.data?.map((source: any, ix: number) => ({
      ...source,
      color: colorArray[ix],
    }));
  }, [sourcesStats?.data]);

  if (isLoadingLinkData || isLoadingSourcesStats) {
    return <Loader size={200} />;
  }

  if (!allSourcesStats) {
    return <div>No source data available</div>;
  }

  const handleLinkUpdate = (value: any, field: string) => {
    const request: { [key: string]: any } = {
      id: params.linkId,
      name: linkData?.data.name,
      destinationUrl: linkData?.data.destinationUrl,
    };
    request[field] = value;
    updateLink({
      accountId: account?.id || '',
      request,
    }).unwrap();
  };

  return (
    <>
      <PageHeader
        title={' '}
        optionalContent={
          <Stack>
            <EditableLabel
              level="title-lg"
              value={linkData?.data?.name}
              onChange={(newValue: any) => handleLinkUpdate(newValue, 'name')}
              onCancel={() => logger.info('todo implement cancel')}
              placeholder={t('links.fields.name')}
            />
            <TextLink
              level="body-md"
              href={linkData?.data?.destinationUrl}
              target="_blank"
            />
          </Stack>
        }
        backAction={() => navigate('/tools/links')}
      />
      <Container>
        <Stack gap={3} direction="row" flex={1}>
          <Sheet
            variant="outlined"
            sx={{
              display: 'flex',
              flexFlow: 'column nowrap',
              flex: 1,
              borderRadius: 'var(--joy-radius-md)',
            }}
          >
            <SourcesList
              sources={allSourcesStats}
              selected={selectedSources}
              setSelected={setSelectedSources}
            />
            <StatusAlert
              status="info"
              message={t('links.source.info')}
              sx={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 'inherit',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                flex: 0,
                display: showInfo ? 'flex' : 'none',
              }}
              handleClose={() => {
                setShowInfo(!showInfo);
              }}
            >
              <Link
                level="body-sm"
                href="https://support.sakari.io/hc/en-us/articles/18145365394068"
                target="_blank"
              >
                {t('learnMore')}
              </Link>
            </StatusAlert>
          </Sheet>
          <Sheet
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 2,
              borderRadius: 'var(--joy-radius-md)',
              p: 2,
            }}
          >
            <Stack
              sx={{
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                p: 2,
              }}
            >
              <SelectLinkMetric metric={metric} setMetric={setMetric} />
              <SelectTimeRange
                timeRange={timeRange}
                setTimeRange={setTimeRange}
              />
            </Stack>
            <InsightsChart
              sources={allSourcesStats?.filter(
                ({ source }: any) => selectedSources[source?.id],
              )}
            />
          </Sheet>
        </Stack>
      </Container>
    </>
  );
}
export default LinkDetail;
