import React, { FC, useContext, useEffect } from 'react';
import {
  WithAuthenticationRequiredOptions,
  useAuth0,
} from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../contexts/account.context';
import { ROLES_CLAIM_KEY } from '../constants';
import ErrorPage from '../pages/Misc/ErrorPage';

// eslint-disable-next-line react/jsx-no-useless-fragment
const defaultOnRedirecting = (): JSX.Element => <></>;

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

const WithAuthRequired = <P extends object>(
  Component: React.ComponentType<P>,
  requiredRoles?: string[],
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => {
  const navigate = useNavigate();

  const {
    account,
    error,
    isLoading: isLoadingAccount,
  } = useContext(AccountContext);

  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      claimCheck = (): boolean => true,
      loginOptions,
    } = options;

    /**
     * The route is authenticated if the user has valid auth and there are no
     * JWT claim mismatches.
     */
    const routeIsAuthenticated = isAuthenticated && claimCheck(user);
    const role = user?.[ROLES_CLAIM_KEY][account?.id || ''];

    useEffect(() => {
      if (isLoading || routeIsAuthenticated) {
        return;
      }
      const opts = {
        ...loginOptions,
        appState: {
          ...(loginOptions && loginOptions.appState),
          returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
        },
      };
      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }, [
      isLoading,
      routeIsAuthenticated,
      loginWithRedirect,
      loginOptions,
      returnTo,
    ]);

    if (routeIsAuthenticated) {
      if (isLoadingAccount) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
      }

      if (error) {
        return <ErrorPage code={error.data?.error?.code ?? error.status} />;
      }

      if (!account) navigate('/accounts');

      if (
        requiredRoles &&
        requiredRoles.length > 0 &&
        !requiredRoles.includes(role)
      ) {
        return <ErrorPage code="401" />;
      }

      return <Component {...props} />;
    }
    return onRedirecting();
  };
};

function AuthenticatedRoute({
  component,
  requiredRoles,
}: {
  component: React.ComponentType<object>;
  requiredRoles?: string[];
}) {
  const Component = WithAuthRequired(component, requiredRoles);
  return <Component />;
}

export default AuthenticatedRoute;
