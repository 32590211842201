import React, { useState } from 'react';
import { IconButton, Link, Card, CardCover, CircularProgress } from '@mui/joy';
import { IFileUpload } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import MediaPreview from '../../organisms/Dialogs/MediaPreview';

interface MediaThumbnailProps {
  size: number;
  file: IFileUpload;
  onDelete?: (id: string | undefined) => void;
}

function MediaThumbnail({ size, file, onDelete }: MediaThumbnailProps) {
  const [previewImageUrl, setPreviewImageUrl] = useState<string>('');
  const [openPreviewImageDialog, setOpenPreviewImageDialog] =
    useState<boolean>(false);

  const onImagePreview = (url: string) => {
    setPreviewImageUrl(url);
    setOpenPreviewImageDialog(true);
  };

  const isPdf = file.type?.toLowerCase().includes('pdf');

  const isImage = file.type?.toLowerCase().includes('image');

  const downloadPDFFile = () => {
    const a = document.createElement('a') as any;
    a.style = 'display: none';
    a.href = file.url;
    a.target = '_blank';
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <MediaPreview
        open={openPreviewImageDialog}
        imageString={previewImageUrl}
        onClose={() => {
          setOpenPreviewImageDialog(false);
        }}
      />
      <Card
        sx={{
          width: size,
          height: size,
          p: 1,
          overflow: 'clip',
          '&:hover': {
            cursor: 'pointer',
          },
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => {
          onImagePreview(file.url);
        }}
      >
        <CardCover
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {file.isUploaded && (
            <>
              {onDelete && (
                <IconButton
                  size="xsRound"
                  aria-label="delete"
                  onClick={() => onDelete(file.id)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <FontAwesomeIcon icon={regular('xmark')} />{' '}
                </IconButton>
              )}
              {isPdf && (
                <Link onClick={downloadPDFFile}>
                  <FontAwesomeIcon icon={regular('file-pdf')} size="6x" />{' '}
                </Link>
              )}
              {isImage && (
                <img
                  alt=""
                  src={file.url}
                  loading="lazy"
                  style={{ maxWidth: size, maxHeight: size }}
                />
              )}
            </>
          )}
        </CardCover>
        {file.isUploaded === false && <CircularProgress size="md" />}
      </Card>
    </>
  );
}

export default MediaThumbnail;
