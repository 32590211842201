import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  FormHelperText,
  Input,
  Chip,
  ChipDelete,
} from '@mui/joy';
import Button from '@mui/joy/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface TagsProps {
  value: Array<string>;
  onChange: (value: Array<string>) => any;
  label?: string;
  error?: boolean;
  helperText?: string;
}

function Tags({ value, onChange, label, error, helperText }: TagsProps) {
  const [input, setInput] = useState('');

  // event listener for adding tags via 'enter' keystroke
  const addTagInput = document.getElementById('tagInput');

  addTagInput?.addEventListener('keypress', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById('addTagBtn')?.click();
    }
  });

  return (
    <Stack spacing={2}>
      <Typography level="h4" fontWeight="bold">
        {label}
      </Typography>
      <Stack
        direction="row"
        sx={{ gap: '10px', width: '100%', marginTop: '0px !important' }}
      >
        <Input
          id="tagInput"
          fullWidth
          value={input}
          onChange={(evt) => setInput(evt.target.value)}
          placeholder="Add a tag"
        />

        <Button
          id="addTagBtn"
          disabled={input.length === 0}
          variant="soft"
          color="neutral"
          onClick={() => {
            onChange([...value, input]);
            setInput('');
          }}
        >
          <FontAwesomeIcon icon={solid('plus')} />
        </Button>
      </Stack>

      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        sx={{ gap: '8px' }}
      >
        {(value || []).map((tag: string, index) => (
          <Box key={index} sx={{ maxWidth: '100%' }}>
            <Chip
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%',
              }}
              variant="outlined"
              size="sm"
              endDecorator={
                <ChipDelete
                  variant="plain"
                  onClick={() => onChange(value.filter((t) => t !== tag))}
                />
              }
            >
              {tag}
            </Chip>
          </Box>
        ))}
      </Stack>
      {helperText ? (
        <FormHelperText color={error ? 'danger' : 'neutral'}>
          {helperText}
        </FormHelperText>
      ) : null}
    </Stack>
  );
}

export default Tags;
