import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../redux/reducers/workflow';

interface AutoSaveProps {
  values: any;
}

function AutoSave({ values }: AutoSaveProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.updateNodeConfig(values));
    dispatch(actions.checkForChanges());
  }, [values]);

  return <div />;
}

export default AutoSave;
