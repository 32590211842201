import { ListItem, Typography, Stack } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { IDable } from '@sakari-io/sakari-typings';
import { Loader } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import MenuItem from '../../../atoms/MenuItem';
import Menu, { AppMenuProps } from '..';
import SearchBar from '../../inputs/SearchBar';

export interface SearchableMenuProps<T extends IDable>
  extends Omit<AppMenuProps, 'items'> {
  placeholder?: string;
  onQueryChanged: (searchTerm: string) => void;
  header?: React.ReactNode;
  onSelect: (item: any) => void;
  items?: any[];
  getLabel?: (item: T) => any;
  wrap?: boolean;
  loadingText?: string;
  noOptionsText?: string | React.ReactNode;
  isLoading?: boolean;
  showResultsInfo?: { total: number; position: 'top' | 'bottom' };
  isDisabled?: (item: T) => boolean;
  actionIcon?: (item: T) => React.ReactNode;
}

function SearchableMenu<T extends IDable>({
  items,
  placeholder,
  onQueryChanged,
  header,
  wrap = false,
  noOptionsText,
  isLoading = false,
  loadingText,
  onSelect,
  showResultsInfo,
  isDisabled,
  children,
  getLabel,
  actionIcon,
  sx,
  ...rest
}: SearchableMenuProps<T>) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [showConfirmAction, setShowConfirmAction] = useState<T | null>(null);

  const sharedProps = {
    color: rest.color || 'neutral',
    size: rest.size || 'md',
    // variant: rest.variant || 'outlined',
  };

  useEffect(() => {
    onQueryChanged(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (showConfirmAction) {
      setTimeout(() => setShowConfirmAction(null), 3000);
    }
  }, [showConfirmAction]);

  const handleKeyDown = (e: any) => {
    e.stopPropagation();
    if (e.key === 'Escape') {
      setSearchTerm('');
    }
  };

  const getResultsDisplay = () => {
    if (isLoading) {
      return (
        <Stack padding="15px" flex={1} id="loading-box">
          <Loader
            size={100}
            label={loadingText || t('loadingItems', { items: 'items' })}
          />
        </Stack>
      );
    }

    if (!items) return null;

    if (items && items?.length > 0) {
      return items.map((item: T, index: number) => (
        <MenuItem
          key={item.id || index}
          wrap={wrap}
          onClick={() => {
            onSelect(item);
          }}
          variant={rest.variant !== 'outlined' ? rest.variant : 'plain'}
          autoFocus
          label={(getLabel && getLabel(item)) || item.id}
          disabled={isDisabled?.(item)}
          endIcon={actionIcon && actionIcon(item)}
          sx={{
            '.MuiIconButton-root': {
              opacity: 0,
            },
            '&:hover .MuiIconButton-root': {
              opacity: 0.7,
            },
          }}
          {...sharedProps}
        />
      ));
    }

    return (
      <ListItem key="no-options">
        {typeof noOptionsText === 'string'
          ? noOptionsText || `${t('no')} ${t('items.option_other')}`
          : noOptionsText}
      </ListItem>
    );
  };

  const getResultsInfoText = () =>
    `${t('showingOf', {
      count: items?.length || 0,
      total: Math.max(showResultsInfo?.total || 0, items?.length || 0),
      items: '',
    })}`;

  return (
    <Menu
      header={
        <Stack spacing={2}>
          {header || null}
          <SearchBar
            key="searchbar"
            autoFocus
            helperText={
              showResultsInfo?.position === 'top' ? getResultsInfoText() : ''
            }
            value={searchTerm}
            onChange={setSearchTerm}
            onKeyDown={handleKeyDown}
            isLoading={isLoading}
            variant={rest.variant}
            {...sharedProps}
          />
        </Stack>
      }
      footer={
        showResultsInfo?.position === 'bottom' && (
          <Typography level="body-sm" textAlign="end" key="results-info">
            {getResultsInfoText()}
          </Typography>
        )
      }
      sx={{
        '& .MuiMenuList-root': {
          backgroundColor: 'transparent',
          ...(isLoading && {
            minHeight: 200,
          }),
        },
        maxHeight: 250,
        ...sx,
      }}
      {...rest}
    >
      {getResultsDisplay()}
      {children}
    </Menu>
  );
}

export default SearchableMenu;
