import { ThunkDispatch } from 'redux-thunk';
import { logger } from '@sakari-io/sakari-components';
import { showToast } from '../redux/reducers/toast';

interface ApiMutationOptions {
  successMessage?: string;
  defaultErrorMessage?: string;
  onSuccess?: (result: any) => any;
  onError?: (error: any) => any;
}

// eslint-disable-next-line import/prefer-default-export
export const handleApiMutationResponse = (
  promise: Promise<any>,
  dispatch: ThunkDispatch<any, any, any>,
  options?: ApiMutationOptions,
) => {
  return promise
    .then((res) => {
      logger.log('res', res);
      if (res.success) {
        if (options?.onSuccess) {
          options.onSuccess(res);
        }

        if (options?.successMessage) {
          dispatch(
            showToast({
              severity: 'success',
              message: options?.successMessage,
            }),
          );
        }
      }
    })
    .catch((err) => {
      logger.error('err', err);
      if (options?.onError) {
        options.onError(err);
      }
      dispatch(
        showToast({
          severity: 'error',
          message: err.data?.error?.message || options?.defaultErrorMessage,
        }),
      );
    });
};
