import React, { useState } from 'react';
import { Account, Campaign as CampaignType } from '@sakari-io/sakari-typings';
import { Typography, Stack, Avatar, Chip } from '@mui/joy';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Accordion } from '../../../ui';
import ExecutionReport from './ExecutionReport';
import CampaignMenu from './CampaignMenu';

interface CampaignProps {
  account: Account;
  campaign: CampaignType;
  onAction: (action: string, campaign?: CampaignType) => any;
}

function Campaign({ account, campaign, onAction }: CampaignProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion
      component="li"
      id={campaign.id}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disableOpen={!campaign.lastJob}
      divider
      variant="outlined"
      sx={{
        width: '100%',
        overflow: 'hidden',
        ...(isOpen && {
          filter: 'var(--joy-shadow-open)',
        }),
        '&:hover': {
          filter: 'var(--joy-shadow-hover)',
        },

        zIndex: isOpen ? 1 : 0,
      }}
      header={
        <Stack
          sx={{
            flexFlow: 'row nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
            gap: 2,
          }}
        >
          <Avatar color="primary" size="lg" variant="soft">
            <FontAwesomeIcon icon={solid('mobile-button')} />
          </Avatar>

          <Stack flex={1} gap={1}>
            <Typography level="title-lg">{campaign.name}</Typography>
            <Stack sx={{ flexFlow: 'column nowrap', flex: 1, gap: 1 }}>
              <Typography level="body-md" textColor="text.tertiary">
                {campaign.lastJob
                  ? `${_.capitalize(t('executedAt'))}: ${format(
                      parseISO(campaign.lastJob.created.at),
                      'PPPpp',
                    )}`
                  : t('noExecutionsCompleted')}
              </Typography>
              <Typography level="body-md" textColor="text.tertiary">
                {campaign.nextExecution
                  ? `${_.capitalize(t('action.next'))}: ${format(
                      parseISO(campaign.nextExecution),
                      'PPPpp',
                    )}`
                  : t('noExecutionsScheduled')}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Chip
              variant="plain"
              size="lg"
              startDecorator={<FontAwesomeIcon icon={solid('circle')} />}
              sx={{
                '.MuiChip-label': {
                  color: 'var(--joy-palette-text-tertiary)',
                },
                '.MuiChip-startDecorator': {
                  ...(campaign.paused
                    ? {
                        color: 'var(--joy-palette-warning-500)',
                      }
                    : {
                        color: 'var(--joy-palette-primary-500)',
                      }),
                },
              }}
            >
              {_.capitalize(
                campaign.paused
                  ? t('statusType.paused')
                  : t('statusType.active'),
              )}
            </Chip>
            <CampaignMenu
              campaign={campaign}
              onAction={(action) => onAction(action, campaign)}
            />
          </Stack>
        </Stack>
      }
      content={
        <ExecutionReport account={account} campaign={campaign} open={isOpen} />
      }
    />
  );
}

export default Campaign;
