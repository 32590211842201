import { Slider } from '@mui/joy';
import React, { useEffect } from 'react';

interface PlanSliderProps {
  currentCommitment: number;
  value: number;
  onChange: (value: number) => void;
  step?: number;
  min?: number;
  max?: number;
}

function PlanSlider({
  currentCommitment,
  value,
  onChange,
  step = 5,
  min = 0,
  max = 100,
}: PlanSliderProps) {
  const [slider, setSlider] = React.useState<number[]>([
    currentCommitment,
    value,
  ]);

  const [currentThumb, setCurrentThumb] = React.useState<number>(0);

  useEffect(() => {
    if (value < currentCommitment) {
      setCurrentThumb(1);
      setSlider([value, currentCommitment]);
    } else {
      setCurrentThumb(0);
      setSlider([currentCommitment, value]);
    }
  }, [value, currentCommitment]);

  const handleChange = (e: Event, newVal: any, activeThumb: number) => {
    if (newVal[activeThumb] < currentCommitment) {
      onChange((newVal as number[])[0]);
    } else {
      onChange((newVal as number[])[1]);
    }
  };
  return (
    <Slider
      value={slider}
      onChange={handleChange}
      // onChangeCommitted={(e, value) =>
      //   onChange((value as number[])[currentThumb === 0 ? 1 : 0])
      // }
      step={step}
      min={min}
      max={max}
      valueLabelDisplay="on"
      valueLabelFormat={(value) =>
        value === currentCommitment ? `Current` : null
      }
      sx={{
        width: 'clamp(300px, 80%, 960px)',
        '--Slider-trackBackground': 'var(--joy-palette-primary-500)',
        '&:hover': {
          '--Slider-railBackground': 'var(--joy-palette-primary-100)',
        },
        [`[data-index="${currentThumb}"]`]: {
          height: 'calc(var(--Slider-trackSize) * 1.5)',
          width: 'calc(var(--Slider-trackSize) * 1.5)',
          '& > .MuiSlider-valueLabel': {
            bottom: '-10px',
            background: 'transparent',
            color: 'text.primary',
            boxShadow: 'none',
            '&::before': {
              display: 'none',
            },
          },
        },
        [`[data-index]:not([data-index="${currentThumb}"])`]: {
          '& > .MuiSlider-valueLabel': {
            display: 'none',
          },
        },
      }}
    />
  );
}

export default PlanSlider;
