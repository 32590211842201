import { logger } from '@sakari-io/sakari-components';

const clearCookies = () => {
  logger.info('clearCookies');
  localStorage.removeItem('account');
  localStorage.removeItem('accountId');
  localStorage.removeItem('groupId');
  localStorage.removeItem('default.group');
  localStorage.removeItem('pinned-lists');
};

export default clearCookies;
