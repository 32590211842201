import { useState } from 'react';

function useHover() {
  const [isHovered, setIsHovered] = useState(false);

  const eventHandlers = {
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };

  const setHovered = (value: boolean) => setIsHovered(value);

  return [isHovered, eventHandlers, setHovered] as const;
}

export default useHover;
