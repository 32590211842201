import { useState, useEffect, useCallback, useRef } from 'react';

/**
 * Custom hook to calculate the number of items that can fit inside a container.
 *
 * @param itemWidth - The width of a single item in px.
 * @returns {Object} An object containing:
 * - containerRef: A ref to attach to the HTMLDivElement container.
 * - visibleItems: The number of items that can fit inside the container based on the itemWidth.
 */
const useVisibleItems = (itemWidth: number) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [visibleItems, setVisibleItems] = useState(0);

  const calculateVisibleItems = useCallback(() => {
    if (containerRef.current && itemWidth) {
      const containerWidth = containerRef.current.offsetWidth;
      const numItems = Math.floor(containerWidth / itemWidth);
      if (visibleItems !== numItems) {
        setVisibleItems(numItems);
      }
    }
  }, [containerRef, itemWidth]);

  useEffect(() => {
    calculateVisibleItems();
    window.addEventListener('resize', calculateVisibleItems);
    const resizeObserver = new ResizeObserver(calculateVisibleItems);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }

      window.removeEventListener('resize', calculateVisibleItems);
    };
  }, [calculateVisibleItems]);

  return { containerRef, visibleItems };
};

export default useVisibleItems;
