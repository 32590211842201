import React, { useState } from 'react';
import { Select, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { WorkflowProperty, WorkflowVersion } from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import CreateWorkflowPropertyMenuItem from '../../AddPropertyDialog/CreateWorkflowPropertyMenuItem';
import AddPropertyDialog from '../../AddPropertyDialog';
import WorkflowPropertyItem from './WorkflowPropertyItem';

interface WorkflowPropertiesSelectProps {
  label?: string;
  helperText?: string;
  value: string; // 'name' of workflow property
  onChange: (value?: string) => void;
  properties: WorkflowProperty[];
  version: WorkflowVersion;
  disabled?: boolean;
}

function WorkflowPropertiesSelect({
  label,
  helperText,
  value,
  onChange,
  properties,
  version,
  disabled,
}: WorkflowPropertiesSelectProps) {
  const { t } = useTranslation('workflows');
  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <InputControl label={label} helperText={helperText}>
      <Select
        onChange={(evt, v) => {
          if (v) {
            onChange(v);
          }
        }}
        value={value}
        disabled={disabled}
        placeholder={value || t('workflowProperty.description')}
      >
        {!properties?.length && (
          <Stack p={1}>
            <Typography p={1} level="body-sm">
              {t('workflowProperties.noWorkflowProperties')}
            </Typography>
          </Stack>
        )}

        {properties?.map((option) => (
          <WorkflowPropertyItem key={option.name} value={option} />
        ))}

        <CreateWorkflowPropertyMenuItem
          onClick={() => setDialogOpen(true)}
          endDecorator={
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{
                color: 'var(--joy-palette-primary-500)',
              }}
            />
          }
        />

        {isDialogOpen ? (
          <AddPropertyDialog
            open={isDialogOpen}
            onClose={(val?: WorkflowProperty) => {
              setDialogOpen(false);
              if (val) {
                onChange(val.name);
              }
            }}
            version={version}
          />
        ) : null}
      </Select>
    </InputControl>
  );
}

export default WorkflowPropertiesSelect;
