import React from 'react';
import AlertBase from '@mui/joy/Alert';
import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface PendingCardProps {
  name?: string;
}

function PendingCard({ name = '10DLC' }: PendingCardProps) {
  const { t } = useTranslation();
  const days = '3-5';
  return (
    <AlertBase
      sx={{
        padding: '16px 8px',
        justifyContent: 'center',
        borderRadius: '4px',
      }}
    >
      <Typography
        fontWeight={400}
        sx={{ color: 'var(--joy-palette-primary-600)' }}
      >
        {t('senders.register.platformFreeTrial.numberPending', {
          name,
          days,
        })}
      </Typography>
    </AlertBase>
  );
}

export default PendingCard;
