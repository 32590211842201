import { Sheet, SheetProps, Button, Stack } from '@mui/joy';
import React, { useRef, useEffect } from 'react';
import {
  AnimatePresence,
  useAnimation,
  motion,
  Transition,
} from 'framer-motion';

export interface DrawerProps extends SheetProps {
  persistent?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  minDepth?: number;
  isOpen: boolean;
  transition?: Transition;
  ctaText?: string;
  ctaAction?: (values: any) => void;
  ctaDisabled?: boolean;
  loading?: boolean;
  footerDivider?: boolean;
  ctaSx?: any;
  dialog?: React.ReactNode;
}

function Drawer({
  key,
  children,
  position = 'left',
  isOpen,
  persistent,
  minDepth = 0,
  sx,
  transition = {
    duration: 0.8,
    default: {
      ease: 'easeInOut',
    },
  },
  ctaText,
  ctaAction,
  ctaDisabled,
  loading,
  footerDivider,
  ctaSx,
  dialog,
  ...rest
}: DrawerProps) {
  const drawerRef = useRef<HTMLDivElement | null>(null);

  const getPersistentDimensions = () => {
    let width;
    let height;

    if (position === 'left' || position === 'right') {
      width = isOpen ? 'auto' : `${minDepth}px`;
    } else {
      width = '100%';
    }

    if (position === 'top' || position === 'bottom') {
      height = isOpen ? 'auto' : `${minDepth}px`;
    } else {
      height = '100%';
    }

    return { width, height };
  };

  const tempDrawer = useAnimation();

  useEffect(() => {
    tempDrawer.start({
      [position]: isOpen ? 0 : '-100%',
      transition,
    });
  }, [isOpen, position, tempDrawer]);

  if (persistent) {
    return (
      <Sheet
        key={key || 'persistent-drawer'}
        slotProps={{
          root: {
            component: motion.div,
            initial: false,
            animate: isOpen ? 'open' : 'closed',
            variants: {
              open: {
                ...getPersistentDimensions(),
                transition,
              },
              closed: {
                ...getPersistentDimensions(),
                transition,
              },
            },
          },
        }}
        ref={drawerRef}
        sx={{
          zIndex: 'var(--joy-zIndex-drawer) ',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}
        {...rest}
      >
        {children}
      </Sheet>
    );
  }

  return (
    <AnimatePresence>
      <Sheet
        key={key || 'drawer'}
        ref={drawerRef}
        slotProps={{
          root: {
            component: motion.div,
            animate: tempDrawer,
          },
        }}
        sx={{
          zIndex: 'var(--joy-zIndex-drawer) ',
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          [position]: '-100%',
          width: position === 'left' || position === 'right' ? 'auto' : '100%',
          height: position === 'top' || position === 'bottom' ? 'auto' : '100%',
          ...sx,
        }}
        {...rest}
      >
        {children}

        {ctaText && ctaAction ? (
          <Stack
            sx={{
              width: 'webkit-fill-available',
              top: '90%',
              padding: '15px',
              alignItems: 'flex-end',
              borderTop: footerDivider
                ? '1px solid var(--joy-palette-neutral-200)'
                : 'none',
              ...ctaSx,
            }}
          >
            <Stack sx={{ alignItems: 'flex-end', position: 'fixed' }}>
              <Button
                disabled={ctaDisabled}
                variant="solid"
                color="primary"
                onClick={ctaAction}
                aria-label={ctaText}
                loading={loading}
                sx={{
                  width: 'max-content',
                }}
              >
                {ctaText}
              </Button>
            </Stack>
          </Stack>
        ) : null}
        {dialog || null}
      </Sheet>
    </AnimatePresence>
  );
}

export default Drawer;
