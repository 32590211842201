import React, { useContext } from 'react';
import { Stack, Alert, Typography } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Campaign } from '@sakari-io/sakari-typings';
import { useNavigate } from 'react-router-dom';
import { logger } from '@sakari-io/sakari-components';
import {
  useExecuteCampaignMutation,
  useGetExecuteCampaignPreviewQuery,
  useGetBalanceQuery,
} from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import { AccountContext } from '../../../../contexts/account.context';
import ConfirmationDialog from '../../../../ui/molecules/ConfirmationDialog';

interface ExecuteCampaignProps {
  open: boolean;
  onClose: () => void;
  campaign: Campaign;
}

function ExecuteCampaign({ open, onClose, campaign }: ExecuteCampaignProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);
  const { data, isFetching: isFetchingPreview } =
    useGetExecuteCampaignPreviewQuery(
      account && open && campaign
        ? {
            accountId: account?.id,
            request: campaign?.id,
          }
        : skipToken,
      {
        refetchOnMountOrArgChange: true,
      },
    );
  const { currentData, isFetching: isFetchingBalance } = useGetBalanceQuery(
    account?.id || skipToken,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );
  const [executeCampaign, { isLoading }] = useExecuteCampaignMutation();

  const preview = data?.data;

  const isOnDemand = campaign?.schedule?.frequency === 'OD';
  const isLargeExecution = (preview?.contacts ?? 0) >= 9999;
  const hasInsufficientBalance =
    (currentData?.data?.balance ?? 0) < (preview?.estimatedPrice ?? 0);
  const isThirdPartyList =
    typeof campaign?.filters?.list !== 'string' &&
    !!campaign?.filters?.list?.source;

  const showWarning =
    isLargeExecution || hasInsufficientBalance || isThirdPartyList;

  const getWarningMessage = () => {
    if (hasInsufficientBalance) {
      return t('campaignSchedule.insufficientBalance');
    }
    if (isThirdPartyList) {
      return t('campaignSchedule.thirdPartyList', {
        country: account?.defaults.country?.code,
      });
    }
    if (isLargeExecution) {
      return t('campaignSchedule.largeExecution');
    }
    return '';
  };

  const handleExecute = () => {
    executeCampaign({ id: campaign.id })
      .unwrap()
      .then((result) => {
        logger.info('result', result);
        onClose();
        dispatch(
          showToast({
            severity: 'success',
            message: 'Campaign has been submitted for execution',
          }),
        );
      })
      .catch((err) => {
        logger.error('err', err.data?.error || err);
        dispatch(
          showToast({
            severity: 'error',
            message:
              err.data?.error?.message ||
              'Unable to submit campaign. Please reach out to support@sakari.io prior to resubmitting',
          }),
        );
      });
  };

  const getConfirmLabel = () => {
    if (hasInsufficientBalance) {
      return 'settings.billing.general.credits.manualCredit.label';
    }
    if (isOnDemand) {
      return 'action.execute';
    }

    return 'action.confirm';
  };

  const getHeader = () => {
    if (isFetchingPreview) {
      return t('calculating');
    }
    if (isOnDemand) {
      return t('campaignSchedule.executeCampaign');
    }
    return t('campaignSchedule.title');
  };

  const handleOnClose = (result?: boolean) => {
    if (result && hasInsufficientBalance) {
      navigate('/billing');
    } else if (result && isOnDemand) {
      handleExecute();
    }
    onClose();
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={handleOnClose}
      confirmLabel={getConfirmLabel()}
      hideCancel={!isOnDemand}
      loading={isLoading || isFetchingPreview}
      header={getHeader()}
      width={500}
      content={
        <>
          <Stack gap={1}>
            <Stack direction="row">
              <Typography sx={{ flex: 1 }} level="body-md">
                {t('campaignSchedule.accountBalance')}
              </Typography>
              <Typography>
                $
                {isFetchingBalance
                  ? '......'
                  : currentData?.data?.balance.toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ flex: 1 }} level="body-md">
                {t('campaignSchedule.messagesToSend')}
              </Typography>
              <Typography>
                {isFetchingPreview ? '......' : data?.data?.contacts}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ flex: 1 }} level="body-md">
                {t('campaignSchedule.estimatedPrice')}
              </Typography>
              <Typography>
                $
                {isFetchingPreview
                  ? '......'
                  : data?.data?.estimatedPrice.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
          {!isFetchingPreview && showWarning && (
            <Alert
              color={hasInsufficientBalance ? 'danger' : 'neutral'}
              variant="soft"
              size="sm"
              sx={{
                flexDirection: 'column',
              }}
            >
              {getWarningMessage()}
            </Alert>
          )}
        </>
      }
    />
  );
}

export default ExecuteCampaign;
