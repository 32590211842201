import React, { useContext } from 'react';
import { Stack, Box, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AccountAttribute } from '@sakari-io/sakari-typings';
import Select from '../../../atoms/inputs/Select';
import { AccountContext } from '../../../../contexts/account.context';
import { CORE_ATTRIBUTES } from '../../../../constants';

interface FieldMappingProps {
  attribute: string;
  value?: string;
  columns: string[];
  onChange: (value: string) => any;
  onDelete: (value: string) => any;
}

function FieldMapping({
  attribute,
  value = '',
  columns,
  onChange,
  onDelete,
}: FieldMappingProps) {
  const { t } = useTranslation();
  const { contactAttributes } = useContext(AccountContext);

  const getCustomAttributeLabel = (attribute: string) =>
    contactAttributes.find((a: AccountAttribute) => a.name === attribute)
      ?.label || attribute;

  const renderOptions = () => {
    const options = columns.map((column) => ({
      value: column,
      label: column,
    }));

    return options;
  };

  const getLabel = (attribute: string) =>
    CORE_ATTRIBUTES.includes(attribute)
      ? t(attribute)
      : getCustomAttributeLabel(attribute);

  return (attribute?.length ?? 0) > 0 ? (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        '& .MuiFormControl-root > .MuiStack-root:first-child': {
          width: '40%',
        },
      }}
    >
      <Select
        fullWidth
        orientation="horizontal"
        size="sm"
        options={renderOptions()}
        label={getLabel(attribute)}
        value={value}
        onChange={(evt) => onChange(evt)}
        required={attribute === 'mobile'}
        helperText={attribute === 'mobile' && !value ? 'Required Field' : ''}
      />
      <Box sx={{ flex: 1 }}>
        <IconButton
          size="smRound"
          onClick={() => onDelete(attribute)}
          disabled={attribute === 'mobile'}
          sx={{ opacity: 0.5 }}
        >
          <FontAwesomeIcon
            icon={
              attribute === 'mobile' ? solid('trash-slash') : solid('trash')
            }
          />
        </IconButton>
      </Box>
    </Stack>
  ) : null;
}

export default FieldMapping;
