import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Contact,
  Workflow,
  WorkflowExecution,
} from '@sakari-io/sakari-typings';
import { Loader } from '@sakari-io/sakari-components';
import { IconButton, Stack, Tooltip, Typography } from '@mui/joy';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactHeader from '../ContactHeader';
import ExecutionsAccordion from './ExecutionsAccordion';
import { useGetWorkflowExecutionsQuery } from '../../../../../../api';
import { AccountContext } from '../../../../../../contexts/account.context';
import EmptyResult from '../../../../../../ui/molecules/EmptyResult';
import { useAppDispatch } from '../../../../../../redux';
import { showToast } from '../../../../../../redux/reducers/toast';
import DrawerHeader from '../../DrawerHeader';

interface VersionsListProps {
  workflow: Workflow;
  contact: Contact;
  onExecutionClick: (execution: WorkflowExecution) => void;
  onBack: () => void;
}

function VersionsList({
  workflow,
  contact,
  onExecutionClick,
  onBack,
}: VersionsListProps) {
  const { t } = useTranslation('workflows');
  const { account } = useContext(AccountContext);
  const dispatch = useAppDispatch();

  const {
    data: executions,
    isLoading,
    isFetching,
    isError,
    error,
    refetch: refetchExecutions,
  } = useGetWorkflowExecutionsQuery(
    account
      ? {
          accountId: account.id,
          request: {
            workflowId: workflow.id,
            contactId: contact.id,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        showToast({
          message: (error as Error)?.message || 'Error loading data',
          severity: 'error',
        }),
      );
    }
  }, [isError]);

  const executionsByVersion = _.chain(executions?.data)
    .groupBy((item) => {
      return item.version.name;
    })
    .map((value, key) => {
      return {
        version: key,
        executions: value,
      };
    })
    .value();

  const versionsList = () => {
    return executions?.data.length ? (
      executionsByVersion.map((version: any) => (
        <ExecutionsAccordion
          key={version.id}
          version={version}
          onExecutionClick={onExecutionClick}
        />
      ))
    ) : (
      <Stack sx={{ padding: '24px' }} gap={2}>
        <img
          src="https://assets.sakari.io/images/empty/executions.svg"
          alt="No executions found"
          height="100px"
        />
        <EmptyResult
          heading={t(`contactExecutions.noExecutions`)}
          text={t(`contactExecutions.noExecutionsFound`)}
        />
      </Stack>
    );
  };

  return (
    <>
      <DrawerHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{t('contactExecutions.executions')}</Typography>
            <Tooltip title="Refresh executions">
              <IconButton
                size="smRound"
                onClick={() => refetchExecutions()}
                disabled={isFetching}
              >
                <FontAwesomeIcon icon={faRotateRight} />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      />
      <ContactHeader onBack={onBack} contact={contact} />
      <Stack
        gap={1}
        sx={{
          marginTop: '8px',
          padding: '0 12px 24px 12px',
          height: `${window.innerHeight - 226}px`,
          overflow: 'auto',
        }}
      >
        {isLoading ? (
          <Loader size={100} label={t(`contactExecutions.loadingVersions`)} />
        ) : (
          versionsList()
        )}
      </Stack>
    </>
  );
}

export default VersionsList;
