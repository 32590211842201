import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorPage from './ErrorPage';

function CallBackPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const type = params.get('error');
  const message = params.get('error_description');

  if (!type) {
    navigate('/');
    return null;
  }

  const getCode = (type: string | null): string => {
    if (!type) return '500';

    if (
      [
        'access_denied',
        'invalid_request',
        'unauthorized_client',
        'unsupported_response_type',
      ].includes(type)
    ) {
      return '401';
    }
    return '500';
  };
  return <ErrorPage code={getCode(type)} message={message ?? undefined} />;
}
export default CallBackPage;
