import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, IconButton, Stack, SvgIcon, Typography } from '@mui/joy';
import { Account, Conversation, Group } from '@sakari-io/sakari-typings';
import React, { useState } from 'react';
import { Avatar, logger, PhoneNumberChip } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { isAfter, parseISO } from 'date-fns';
import { format } from '@sakari-io/sakari-common';
import { useNavigate } from 'react-router-dom';
import PeoplePicker from '../../forms/PeoplePicker';
import Helper from '../../../../utils/helper';
import AppMenu from '../../../molecules/Menu';
import OverlayPopup from '../../../templates/OverlayPopup';
import DrawerToggleButton from '../../ButtonContexts/DrawerToggleButton';
import isCountrySupported from '../../../../utils/groups';
import {
  useArchiveConversationMutation,
  useBlockContactMutation,
  useHideConversationMutation,
  useMarkConversationAsUnreadMutation,
} from '../../../../api';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import AppDialog from '../../../molecules/AppDialog';

const isArchived = (conversation: Conversation) =>
  isAfter(new Date(), parseISO(conversation.closed));

interface ConversationHeaderProps {
  account: Account;
  group?: Group;
  conversation?: Conversation;
  setConversation: (conversation?: Conversation) => any;
  drawers: string[];
  onToggleDrawer: (drawer: string) => void;
  isReadOnly?: boolean;
}

function ConversationHeader({
  account,
  group,
  conversation,
  setConversation,
  drawers,
  onToggleDrawer,
  isReadOnly,
}: ConversationHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isCompressed = drawers.includes('conversation-list');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<
    'archive' | 'block' | 'hide' | undefined
  >();

  const [hideConversation] = useHideConversationMutation();
  const [archiveConversation] = useArchiveConversationMutation();
  const [blockContact] = useBlockContactMutation();
  const [markConversationAsUnRead] = useMarkConversationAsUnreadMutation();

  // const handleChannelSelect = (channel: string) => {
  //   logger.info('channel', channel);
  //   if (channels.includes(channel)) {
  //     setChannels(channels.filter((c: any) => c !== channel));
  //   } else {
  //     setChannels([...channels, channel]);
  //   }
  // };
  const handleArchiveConversation = () => {
    if (conversation?.id) {
      //TODO use handleApiMutationResponse
      archiveConversation(conversation)
        .unwrap()
        .then(() => {
          dispatch(
            showToast({
              severity: 'info',
              message: t('conversationHasBeenArchived'),
            }),
          );
          setConversation(undefined);
        })
        .catch((err) => {
          logger.info(err);
          dispatch(
            showToast({
              severity: 'error',
              message:
                err?.data?.error?.message ||
                t('conversationCouldNotBeArchived'),
            }),
          );
        });
    }
  };

  const handleBlockContact = () => {
    if (conversation?.contact) {
      blockContact(conversation.contact.id)
        .unwrap()
        .then(() => {
          dispatch(
            showToast({
              severity: 'info',
              message: t('hasBeenBlocked', {
                name:
                  format.name(conversation.contact) ||
                  t('items.contact_one').toUpperCase(),
              }),
            }),
          );
        })
        .catch((err) => {
          logger.info(err);
          dispatch(
            showToast({
              severity: 'error',
              message: `${format.name(conversation.contact) || 'Contact'} could not be blocked`,
            }),
          );
        });
    }
  };

  const handleHideConversation = () => {
    if (conversation?.id) {
      hideConversation(conversation)
        .unwrap()
        .then(() => {
          dispatch(
            showToast({
              severity: 'info',
              message: 'Conversation has been hidden',
            }),
          );
          setConversation(undefined);
        })
        .catch((err) => {
          logger.info(err);
          dispatch(
            showToast({
              severity: 'error',
              message: 'Conversation could not be hidden',
            }),
          );
        });
    }
  };

  const handleSelectAction = (action: typeof openDialog) => {
    setOpenMenu(false);
    setOpenDialog(action);
  };

  const dialogMap = {
    archive: {
      title: t('archiveConv'),
      description: t('archiveConvQuote'),
      action: handleArchiveConversation,
    },
    block: {
      title: t('blockContact'),
      description: t('blockContactQuote'),
      action: handleBlockContact,
    },
    hide: {
      title: t('hideConv'),
      description: t('hideConvQuote'),
      action: handleHideConversation,
    },
  };

  if (!conversation) return null;

  return (
    <Stack
      id="conversations-header"
      sx={{
        width: '100%',
        py: 1,
        px: 2,
        gap: 1,
        borderBottom: `1px solid`,
        borderColor: 'divider',
        background: 'var(--joy-palette-background-surface)',
      }}
    >
      {!['new', 'draft'].includes(conversation?.id || '') &&
      conversation.contact?.id !== 'new' ? (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: '0',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Avatar person={conversation.contact} />
            <Stack>
              <Typography>
                {Helper.getNameOrMobileNumber(conversation.contact)}
              </Typography>
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Chip
                  color="primary"
                  onClick={
                    conversation?.group?.id
                      ? () => navigate(`/groups/${conversation.group?.id}`)
                      : undefined
                  }
                >
                  {conversation.group?.name ?? 'Unassigned'}
                </Chip>
                {conversation.phoneNumber && (
                  <PhoneNumberChip
                    key={conversation.phoneNumber.number}
                    color="primary"
                    number={conversation.phoneNumber}
                    onClick={() => {
                      navigate(
                        `/senders/phonenumbers/${conversation.phoneNumber?.id}`,
                      );
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton
              size="smRound"
              onClick={() =>
                onToggleDrawer(isCompressed ? 'none' : 'conversation-list')
              }
            >
              {isCompressed ? (
                <FontAwesomeIcon icon={solid('expand')} />
              ) : (
                <FontAwesomeIcon icon={solid('compress')} />
              )}
            </IconButton>
            <OverlayPopup
              open={openMenu}
              onOpenClose={setOpenMenu}
              placement="bottom-end"
              renderButton={(setAnchorEl, onClick) => (
                <IconButton
                  size="smRound"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={onClick}
                  ref={setAnchorEl}
                >
                  <FontAwesomeIcon icon={regular('ellipsis')} />
                </IconButton>
              )}
              renderPopup={() => (
                <AppMenu
                  items={[
                    // {
                    //   label: 'Hide Conversation',
                    //   onClick: () => handleSelectAction('hide'),
                    //   disabled: isArchived(conversation) || isReadOnly,
                    // },
                    {
                      label: 'Archive Conversation',
                      onClick: () => handleSelectAction('archive'),
                      disabled: isArchived(conversation) || isReadOnly,
                    },
                    {
                      label: 'Block Contact',
                      onClick: () => handleSelectAction('block'),
                      disabled: isReadOnly,
                    },
                    {
                      label: 'Mark as Unread',
                      onClick: () => {
                        markConversationAsUnRead(conversation);
                        setConversation(undefined);
                      },
                      disabled: isReadOnly,
                    },
                  ]}
                />
              )}
            />
            <DrawerToggleButton
              sx={{ ml: 1 }}
              onClick={() => onToggleDrawer('contact-details')}
              isOpen={drawers.includes('contact-details')}
              tooltip={
                drawers.includes('contact-details')
                  ? 'Hide Contact Details'
                  : 'Show Contact Details'
              }
              direction="left"
            />
          </Stack>
        </Stack>
      ) : (
        group && (
          <Box maxWidth="90%">
            <PeoplePicker
              account={account}
              value={conversation.contact ? [conversation.contact] : []}
              onChange={(contacts) => {
                // return setConversation(c  reateConversation(profile, contacts[0], 'sms'));
                return setConversation(
                  _.assign({}, conversation, { contact: contacts[0] }),
                );
              }}
              isOptionDisabled={(contact) => {
                if (!contact?.mobile || (contact?.id).startsWith('new'))
                  return false;
                const notSupported = !isCountrySupported(
                  group,
                  contact.mobile?.country,
                );

                return (
                  (notSupported && !group.useSharedPool) ||
                  !contact.valid ||
                  !!contact.blocked
                );
              }}
              onAddContact={(newContact) => logger.info([newContact])}
              defaultCountry={account?.defaults?.country?.code}
            />
            <Typography
              textColor="neutral.500"
              endDecorator={
                <Chip color="primary">{conversation?.group?.name}</Chip>
              }
            >
              From:
            </Typography>
          </Box>
        )
      )}

      {/* <ChannelBar onSelect={handleChannelSelect} channels={channels} /> */}
      {openDialog ? (
        <AppDialog
          open={!!openDialog}
          setOpen={() => setOpenDialog(undefined)}
          headerIcon={
            <SvgIcon color="danger" fontSize="xl3">
              <FontAwesomeIcon icon={solid('exclamation-triangle')} />
            </SvgIcon>
          }
          header={dialogMap[openDialog].title}
          content={dialogMap[openDialog].description}
          confirmButtonProps={{
            onClick: () => dialogMap[openDialog].action(),
            children: t('action.confirm'),
            color: 'danger',
          }}
          cancelButtonProps={{
            onClick: () => setOpenDialog(undefined),
            children: t('action.cancel'),
            variant: 'outlined',
          }}
          buttonLayout={{
            position: 'center',
          }}
        />
      ) : null}
    </Stack>
  );
}
export default ConversationHeader;
