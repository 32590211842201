import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Chip,
  CircularProgress,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/joy';
import ListDivider from '@mui/joy/ListDivider/ListDivider';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account, Role, User } from '@sakari-io/sakari-typings';
import _ from 'lodash';
import { parseISO, differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { format } from '@sakari-io/sakari-common';
import MenuItem from '../../../../atoms/MenuItem';
import Menu, { AppMenuProps } from '../../../../molecules/Menu';
import OverlayPopup from '../../../../templates/OverlayPopup';
import SwitchAccountMenu from '../SwitchAccountMenu';
import Helper from '../../../../../utils/helper';
import { useGetAccountsQuery, useGetBalanceQuery } from '../../../../../api';
import { AccountContext } from '../../../../../contexts/account.context';

interface SettingsMenuProps extends AppMenuProps {
  role: Role;
  profile: User;
  account: Account;
  onClose?: () => void;
}

function SettingsMenu({
  role,
  profile,
  account,
  variant = 'outlined',
  onClose,
  ...rest
}: SettingsMenuProps) {
  const { t } = useTranslation();
  const { refreshUserRole } = useContext(AccountContext);
  const navigate = useNavigate();
  const {
    data: balanceData,
    refetch,
    isFetching,
  } = useGetBalanceQuery(account.id);

  const [open, setOpen] = useState(false);
  const [hoverOn, setHoverOn] = useState('');

  const name = format.name(profile);

  const { plan, trialExpiry, balance } = account;

  const daysLeft = trialExpiry
    ? differenceInDays(parseISO(trialExpiry), new Date())
    : 0;
  const trialMessage = ` - ${daysLeft > 0 ? `${daysLeft} days left` : t('expired')}`;
  const planLabel = `${plan.name}${plan.name === 'trial' ? trialMessage : ''}`;

  const handleClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const UserSection = (
    <Stack onDoubleClick={() => refreshUserRole()} direction="row" gap={2}>
      <Avatar size="md" alt={name}>
        {Helper.getNameInitials(profile)}
      </Avatar>
      <Stack>
        <Typography>{name}</Typography>
        <Typography level="body-sm">{profile.email}</Typography>
      </Stack>
    </Stack>
  );

  const AccountInfoSection = (
    <Stack gap={1}>
      <Typography level="body-md">
        {_.capitalize(t('items.account_one'))}
      </Typography>
      <Typography level="body-sm">
        <FontAwesomeIcon icon={solid('building')} /> {account.name}
      </Typography>
      <Stack
        sx={{
          flexFlow: 'row wrap',
          gap: 1,
          justifyContent: 'flex-start',
        }}
      >
        <Chip variant="solid" size="sm" color="primary">
          {planLabel}
        </Chip>
        <Chip
          variant="outlined"
          size="sm"
          onClick={() => refetch()}
          endDecorator={
            !isFetching ? (
              <CircularProgress size="xxs" />
            ) : (
              <FontAwesomeIcon icon={solid('refresh')} />
            )
          }
        >
          {`$ ${Helper.formatNumberWithCommas(
            balanceData?.data?.balance ?? balance ?? 0,
            2,
          )}`}
        </Chip>
      </Stack>
    </Stack>
  );

  return (
    <Menu header={UserSection} {...{ ...rest, variant }}>
      <ListDivider />
      <ListSubheader>{AccountInfoSection}</ListSubheader>
      {role === Role.Administrator ? (
        <>
          <MenuItem
            onMouseEnter={() => setHoverOn('')}
            label={t('settings.title')}
            startIcon={<FontAwesomeIcon icon={solid('gear')} />}
            onClick={() => {
              handleClick();
              navigate('/settings');
            }}
          />
          <MenuItem
            onMouseEnter={() => setHoverOn('')}
            label={t('settings.billing.title')}
            startIcon={<FontAwesomeIcon icon={solid('money-check')} />}
            onClick={() => {
              handleClick();
              navigate('/billing');
            }}
          />
          {(!account.partner?.submitted && !account.partner?.approved) ||
          account.partner?.rejected ? (
            <MenuItem
              onMouseEnter={() => setHoverOn('')}
              label="Become a Partner"
              startIcon={<FontAwesomeIcon icon={solid('handshake')} />}
              onClick={() => {
                handleClick();
                navigate('/partnersignup');
              }}
            />
          ) : null}
        </>
      ) : null}
      <ListDivider />
      <MenuItem
        onMouseEnter={() => setHoverOn('')}
        label={t('profileSettings.title')}
        startIcon={<FontAwesomeIcon icon={solid('user-gear')} />}
        onClick={() => {
          handleClick();
          navigate('/profile');
        }}
      />
      <MenuItem
        onMouseEnter={() => setHoverOn('')}
        label={t('profileSettings.notifications.title')}
        startIcon={<FontAwesomeIcon icon={solid('bell')} />}
        onClick={() => {
          handleClick();
          navigate('/profile/notifications');
        }}
      />
      <ListDivider />
      <OverlayPopup
        open={open}
        onOpenClose={() => setOpen((prev) => !prev)}
        renderButton={(setAnchorEl, toggleOpen) => {
          return (
            <MenuItem
              component="button"
              ref={setAnchorEl}
              startIcon={<FontAwesomeIcon icon={solid('exchange-alt')} />}
              endIcon={<FontAwesomeIcon icon={solid('chevron-right')} />}
              onClick={toggleOpen}
              onMouseEnter={() => {
                setHoverOn('switch');
              }}
            >
              {t('switchAccount')}
            </MenuItem>
          );
        }}
        placement="right-end"
        renderPopup={() =>
          hoverOn && (
            <SwitchAccountMenu getAccountsQuery={useGetAccountsQuery} />
          )
        }
      />
      <MenuItem
        onMouseEnter={() => setHoverOn('')}
        label={t('logout')}
        startIcon={<FontAwesomeIcon icon={solid('arrow-right')} />}
        onClick={() => {
          navigate('/logout');
        }}
      />
    </Menu>
  );
}

export default SettingsMenu;
