import { CallHistory, Contact, Group, User } from '@sakari-io/sakari-typings';
import { Suggestion } from './address';
import { Channel } from './decomm';
import { Sort } from './sort';
import { SystemNotification } from './systemnotifications';

import {
  ContactDrawerMode,
  ConversationFilter,
  StepDetail,
  ListItemDataRow,
} from './ui';

export { ContactDrawerMode };

interface AccountIdWith<T> {
  accountId: string;
  request: T;
}

interface ActiveCall extends Omit<CallHistory, 'contact'> {
  contact: Partial<Contact>;
  target?: {
    user?: User;
    group?: Group;
  };
}

export enum PhoneStatus {
  NONE = 'none',
  PHONE = 'phone',
  FAILED = 'failed',
  CALLING = 'calling',
}

export type {
  Channel,
  ConversationFilter,
  ListItemDataRow,
  Sort,
  StepDetail,
  Suggestion,
  SystemNotification,
  AccountIdWith,
  ActiveCall,
};
