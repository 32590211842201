import React from 'react';
import { useTranslation } from 'react-i18next';
import { SakariAPIResponse, Domain } from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import SetupDomainInstructions from '../../SetupDomainInstructions';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import { AppDialog } from '../../..';

interface SetupDomainDialogProps {
  open: boolean;
  onClose: () => void;
  domain: string;
  verify?: boolean;
  onCreate: (domain: string) => Promise<SakariAPIResponse<Domain>>;
  onVerify: (domain: string) => Promise<SakariAPIResponse<Domain>>;
}

function SetupDomainDialog({
  open,
  onClose,
  domain,
  verify,
  onVerify,
  onCreate,
}: SetupDomainDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    onClose();

    try {
      if (verify) {
        logger.info('verify domain', domain);
        const verifying = await onVerify(domain);
        let severity: 'info' | 'warning' | 'error';
        let message;

        if (verifying.data.renewAt) {
          severity = 'info';
          message = t('customDomain.verify.success');
        } else if (verifying.data.id) {
          severity = 'warning';
          message = t('customDomain.verify.fail');
        } else {
          severity = 'error';
          message = t('customDomain.verify.error');
        }
        dispatch(
          showToast({
            severity,
            message,
          }),
        );
      } else {
        await onCreate(domain);
        dispatch(
          showToast({
            severity: 'info',
            message: t('customDomain.create.success'),
          }),
        );
      }
    } catch (err: any) {
      if (err?.data?.error) {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data.error.message,
          }),
        );
      } else {
        dispatch(
          showToast({
            severity: 'error',
            message: t(`customDomain.${verify ? 'verify' : 'create'}.error`),
          }),
        );
      }
    }
  };

  return (
    <AppDialog
      open={open}
      setOpen={onClose}
      title={t('customDomain.dialog.title')}
      content={<SetupDomainInstructions domain={domain} />}
      confirmButtonProps={{
        onClick: () => handleSubmit(),
        children: verify
          ? t('customDomain.verify.action')
          : t('customDomain.setup'),
      }}
    />
  );
}

export default SetupDomainDialog;
