import * as React from 'react';
import { ModalDialogProps } from '@mui/joy/ModalDialog';
import ConfirmationDialog from '../ConfirmationDialog';

export interface AlertDialogProps extends ModalDialogProps {
  open: boolean;
  onClose: () => void;
  type: 'success' | 'danger' | 'warning' | 'info';
  header?: string | React.ReactNode;
  headerIcon?: React.ReactNode;
  closeLabel: string;
}

function AlertDialog({
  open,
  onClose,
  header,
  headerIcon,
  content,
  closeLabel,
  size = 'md',
  type,
  ...rest
}: AlertDialogProps) {
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      header={header}
      headerIcon={headerIcon}
      content={content}
      confirmLabel={closeLabel}
      size={size}
      type={type}
      hideCancel
      {...rest}
    />
  );
}

export default AlertDialog;
