import React, { cloneElement, SyntheticEvent, useRef, useState } from 'react';
import { Button } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import OverlayPopup, {
  OverlayPopupProps,
} from '../../../templates/OverlayPopup';
import { MenuItemProps } from '../../../atoms/MenuItem';
import AppMenu, { AppMenuProps } from '..';

interface DropdownMenuProps
  extends AppMenuProps,
    Pick<OverlayPopupProps, 'placement' | 'offset' | 'arrow'> {
  button: string | JSX.Element;
  items: MenuItemProps[];
  width?: number | string;
}

/** A simple menu that opens when a button is clicked. To handle more complex or custom popups use the OverlayPopup component.
 * Extends PopperProps and MenuProps for additional customization.
 * Required props: button, items
 * @param button - The button that will be used to open the menu.
 * @param items - The items that will be rendered in the menu. Each item must have a label and onClick prop.
 * @param placement - The placement of the menu relative to the button.
 * @param offset - The offset of the menu relative to the button.
 * @param arrow - Whether to show an arrow pointing to the button.
 * @param buttonStyle - The sx style of the button.
 */
function DropdownMenu({
  button,
  items,
  placement = 'bottom-end',
  offset,
  arrow,
  width,
  onSelect,
  sx,
  ...rest
}: DropdownMenuProps) {
  const buttonRef = useRef<HTMLButtonElement>();
  const [open, setOpen] = useState(false);

  const handleSelect = (e: SyntheticEvent<any>, item: MenuItemProps) => {
    if (onSelect) {
      onSelect(e, item);
    }
    setOpen(false);
  };

  const PopUpProps = {
    placement,
    offset,
    arrow,
  };

  return (
    <OverlayPopup
      open={open}
      onOpenClose={setOpen}
      renderButton={(setAnchorEl, onClick) =>
        typeof button === 'string' ? (
          <Button
            ref={(ref) => {
              setAnchorEl(ref);
              buttonRef.current = ref as any;
            }}
            variant="outlined"
            color="neutral"
            endDecorator={<FontAwesomeIcon icon={regular('chevron-down')} />}
            onClick={() => {
              setOpen((prev) => !prev);
              onClick(true);
            }}
            sx={{
              justifyContent: 'space-between',
              width,
              fontWeight: 300,
              color: 'text.secondary',
              backgroundColor: 'background.surface',
              '&:hover, &:active, &:focus': {
                backgroundColor: 'background.surface',
                color: 'text.primary',
              },
            }}
          >
            {button}
          </Button>
        ) : (
          cloneElement(button, {
            ref: (ref: any): any => {
              setAnchorEl(ref);
              buttonRef.current = ref as any;
            },
            onClick,
          })
        )
      }
      renderPopup={() => (
        <AppMenu
          onSelect={handleSelect}
          items={items}
          sx={{
            minWidth: buttonRef?.current?.offsetWidth,
            width,
            ...sx,
          }}
          {...rest}
        />
      )}
      {...PopUpProps}
    />
  );
}

export default DropdownMenu;
