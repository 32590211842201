import React, { useState } from 'react';
import { Stack } from '@mui/joy';
import { Group, InternationalNumber } from '@sakari-io/sakari-typings';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { logger } from '@sakari-io/sakari-components';
import DialIconButton from './DialIconButton';
import PhoneNumberInput from '../../../molecules/PhoneNumberInput';
import DialpadButtons from './DialpadButtons';
import { ActiveCall } from '../../../../types';

export interface DialpadProps {
  group?: Group;
  onDial: (number: InternationalNumber) => void;
  call?: ActiveCall;
}

function Dialpad({ group, call, onDial }: DialpadProps) {
  const [number, setNumber] = useState<InternationalNumber>({
    country: 'US', // TODO should be the account default country
    number: '',
  });

  const isValid = (number: InternationalNumber) => {
    if (!number?.number) {
      return false;
    }

    try {
      const phoneNumber = parsePhoneNumber(
        number.number,
        number.country as CountryCode,
      );
      return phoneNumber?.isValid();
    } catch (err: any) {
      logger.info('unable to validate phone number', number, err.message);
      return false;
    }
  };

  return (
    <Stack alignItems="center" spacing={2}>
      <PhoneNumberInput value={number} onChange={setNumber} />

      <DialpadButtons
        onClick={(key) =>
          setNumber({
            country: number.country,
            number: number.number + key,
          })
        }
      />
      <DialIconButton
        status={call?.status}
        disabled={!group || !isValid(number)}
        onClick={() => onDial(number)}
      />
    </Stack>
  );
}

export default Dialpad;
