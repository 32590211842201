import React from 'react';
import { Button, Link, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface TollFreeIntroProps {
  setConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}

function TollFreeIntro({ setConfirmation }: TollFreeIntroProps) {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        margin: 'auto',
        width: 'clamp(300px, 50%, 600px)',
        gap: 1,
      }}
    >
      <Typography>{t('senders.register.tollfree.intro')}</Typography>
      <Link
        level="body-md"
        target="_blank"
        title="More on US Toll Free Phone Number Registration"
        href="https://support.sakari.io/hc/en-us/articles/5717101787924-US-Toll-Free-Phone-Number-Registration"
      >
        {t('moreInfo')}
      </Link>
      <Button
        size="lg"
        onClick={() => setConfirmation(true)}
        sx={{
          mt: 1,
          width: 'fit-content',
        }}
      >
        Get Started
      </Button>
    </Stack>
  );
}
export default TollFreeIntro;
