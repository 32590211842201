import React, { useContext, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Box, Button, IconButton, Stack, Typography } from '@mui/joy';
import { ShortenedLink } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import SearchableMenu from '../../../molecules/Menu/SearchableMenu';
import { AccountContext } from '../../../../contexts/account.context';
import { useGetLinksQuery } from '../../../../api';

interface InsertLinkDialogProps {
  onSelect: (item?: ShortenedLink) => void;
  onCreateLink: () => void;
}

function InsertLinkDialog({ onSelect, onCreateLink }: InsertLinkDialogProps) {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);

  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebouncedValue(query, 500);
  const { data, isLoading } = useGetLinksQuery(
    account
      ? {
          accountId: account.id,
          request: { q: debouncedQuery || '' },
        }
      : skipToken,
  );

  return (
    <SearchableMenu
      items={data?.data || []}
      isLoading={isLoading}
      loadingText={t('loadingItems', { items: 'links' })}
      getLabel={(item: any) => (
        <Box
          sx={{
            py: 1,
          }}
        >
          <Typography level="body-md">{item.name ?? '[Untitled]'}</Typography>
          <Typography
            level="body-sm"
            overflow="hidden"
            textOverflow="ellipsis"
            title={item.destinationUrl}
          >
            {item.destinationUrl}
          </Typography>
        </Box>
      )}
      onQueryChanged={setQuery}
      onSelect={(item: ShortenedLink) => onSelect(item)}
      actionIcon={(item) => (
        <IconButton
          component="a"
          sx={{
            backgroundColor: 'background.surface',
            filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
          }}
          size="sm"
          href={item.destinationUrl}
          target="_blank"
          title={item.destinationUrl}
        >
          <FontAwesomeIcon icon={regular('arrow-up-right-from-square')} />
        </IconButton>
      )}
      sx={{
        backgroundColor: 'background.level1',
        width: 320,
        maxHeight: 424,
        '& .MuiMenuItem-root:hover .MuiTypography-body2': {
          color: 'primary.main',
        },
      }}
      footer={
        <Stack
          sx={{
            pt: 1,
            gap: 1,
          }}
        >
          <Button onClick={() => onCreateLink()} fullWidth>
            {t('links.createNew.label')}
          </Button>
          <Button
            onClick={() => onSelect(undefined)}
            variant="outlined"
            color="neutral"
            fullWidth
          >
            {t('action.cancel')}
          </Button>
        </Stack>
      }
    />
  );
}
export default InsertLinkDialog;
