import React from 'react';
import { states } from '@sakari-io/sakari-common';
import { useTranslation } from 'react-i18next';
import { logger, Autocomplete } from '@sakari-io/sakari-components';
import { AutocompleteProps } from '../../../clonedfromcomponents';

type State = {
  code: string;
  name: string;
  abbrev: string[];
};

export type StateAutocompleteProps = Omit<
  AutocompleteProps<State | null>,
  'options' | 'onChange' | 'value'
> & {
  value: string;
  onChange: (value: string) => void;
};

function StateAutocomplete({
  label,
  value,
  onChange,
  ...rest
}: StateAutocompleteProps) {
  const { t } = useTranslation();
  const statesWithCountry = states.map((state: State) => {
    const Provinces = [
      'AB',
      'BC',
      'MB',
      'NB',
      'NL',
      'NS',
      'NT',
      'NU',
      'ON',
      'PE',
      'QC',
      'SK',
      'YT',
    ];

    logger.info('state', state);
    return {
      ...state,
      country: Provinces.includes(state.code) ? 'Canada' : 'US',
    };
  });

  type StateWithCountry = typeof statesWithCountry;

  return (
    <Autocomplete
      options={statesWithCountry.sort(
        (a: StateWithCountry, b: StateWithCountry) =>
          b.country.localeCompare(a.country),
      )}
      getOptionLabel={(option: StateWithCountry) => option?.name || ''}
      filterOptions={(options: any, state: any) =>
        options.filter((o: any) =>
          o?.name.toLowerCase().includes(state.inputValue.toLowerCase()),
        )
      }
      isOptionEqualToValue={(option: any, value: any) =>
        option?.code === value?.code
      }
      groupBy={(option: StateWithCountry) => option.country}
      label={label || t('form.state.label')}
      autoHighlight
      blurOnSelect
      value={rest.disabled ? null : ({ name: value } as State)}
      onChange={(newVal: any) => newVal && onChange((newVal as State).code)}
      {...rest}
    />
  );
}

export default StateAutocomplete;
