import React, { useState, useRef, useContext } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { WorkflowVersion, Workflow } from '@sakari-io/sakari-typings';
import { Stack, Typography, Chip, Card, IconButton, Tooltip } from '@mui/joy';
import { AvatarChip } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import VersionPopper from '../VersionPopper';
import EnrollExistingDialog from '../../../../Edit/EnrollExistingDialog';
import { AccountContext } from '../../../../../../contexts/account.context';

export interface VersionCardProps {
  version: WorkflowVersion;
  workflow: Workflow;
  openPopperId: string;
  setOpenPopperId: (id: string) => any;
  onVersionChange: (versionId: string) => any;
  selected?: boolean;
}

function VersionCard({
  version,
  workflow,
  openPopperId,
  setOpenPopperId,
  onVersionChange,
  selected,
}: VersionCardProps) {
  const { t } = useTranslation('workflows');
  const { userRole } = useContext(AccountContext);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [openEnrollExistingDialog, setOpenEnrollExistingDialog] =
    useState(false);

  const getChip = () => {
    if (version.active)
      return <Chip color="primary">{t('versions.active')}</Chip>;
    if (version.readonly) return null;
    return <Chip color="neutral">{t('versions.draft')}</Chip>;
  };

  const handleSelectVersion = (version: WorkflowVersion) => {
    onVersionChange(version.id);
  };

  const disabledPermission = userRole === 'ReadOnly' || userRole === 'Sender';

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          height: 'auto',
          padding: '12px 16px',
          cursor: 'pointer',
          border: selected
            ? '1px solid var(--joy-palette-primary-500)'
            : 'default',
        }}
        onClick={() => {
          handleSelectVersion(version);
        }}
        id="versionCard"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="column">
            <Typography>{version.name}</Typography>
            <Typography level="body-sm">
              {format(new Date(version?.updated.at), 'PPpp')}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip
              title={disabledPermission ? t('readOnly') : null}
              arrow
              placement="top"
            >
              <span>
                <IconButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    if (openPopperId === version.id) {
                      setOpenPopperId('');
                    } else {
                      setOpenPopperId(version.id);
                    }
                  }}
                  size="smRound"
                  ref={anchorRef}
                  disabled={disabledPermission}
                >
                  <FontAwesomeIcon
                    icon={solid('ellipsis-v')}
                    color="var(--joy-palette-neutral-400)"
                    fontWeight="bold"
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="space-between"
          justifyContent="space-between"
        >
          <AvatarChip person={version?.updated.by || {}} />
          {getChip()}
        </Stack>

        {openPopperId && (
          <VersionPopper
            openPopper={openPopperId === version.id}
            setOpenPopper={() => setOpenPopperId('')}
            anchorRef={anchorRef}
            workflow={workflow}
            version={version}
            onVersionChange={onVersionChange}
            setOpenEnrollExistingDialog={setOpenEnrollExistingDialog}
          />
        )}
      </Card>
      {openEnrollExistingDialog && (
        <EnrollExistingDialog
          workflow={workflow}
          version={version}
          onClose={setOpenEnrollExistingDialog}
        />
      )}
    </>
  );
}

export default VersionCard;
