import { ListItemContent, Typography, ListItemDecorator } from '@mui/joy';
import React, { useState } from 'react';
import { Account, Contact } from '@sakari-io/sakari-typings';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import _ from 'lodash';
import {
  Avatar,
  Tooltip,
  useDebouncedValue,
  Autocomplete,
} from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import Helper from '../../../../utils/helper';
import { useGetContactsQuery } from '../../../../api';

interface PeoplePickerProps {
  account: Account;
  value: Contact[];
  onChange: (contacts: Contact[]) => void;
  limit?: number;
  isOptionDisabled?: (contact: Contact | undefined) => boolean;
  onAddContact?: (contact: Contact) => void;
  defaultCountry?: string;
  tagsLimit?: number;
  disableCloseOnSelect?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

function PeoplePicker({
  account,
  value: contacts,
  onChange,
  limit = 1,
  onAddContact,
  isOptionDisabled,
  defaultCountry = 'US',
  tagsLimit,
  ...rest
}: PeoplePickerProps) {
  const isActive = limit ? contacts && contacts.length < limit : true;
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedSearchKey = useDebouncedValue(inputValue, 500);
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetContactsQuery({
    accountId: account.id,
    request:
      debouncedSearchKey !== ''
        ? {
            limit: 20,
            q: debouncedSearchKey,
          }
        : { limit: 20 },
  });

  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleChange = (newContact: any) => {
    onChange(newContact);
    setInputValue('');
  };

  const handleAddContact = (contact: Contact) => {
    if (onAddContact) {
      onAddContact(contact);
    }
    setInputValue('');
  };

  return (
    <Autocomplete
      id="people-picker"
      autoFocus
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple
      variant="plain"
      limitTags={tagsLimit}
      onChange={handleChange}
      value={contacts}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      loading={isLoading || isFetching}
      defaultValue={[]}
      isOptionEqualToValue={(option: any, value: any) =>
        option?.id === value?.id
      }
      options={(!isFetching && data?.data) || []}
      filterOptions={(options: any, state: any) => {
        const { inputValue } = state;

        const hasMatches = options.length > 0;

        const mobile = {
          number: new AsYouType(defaultCountry as CountryCode).input(
            inputValue,
          ),
          country: defaultCountry,
        };

        const validMobile = Helper.validatePhoneNumber(
          inputValue as string,
          defaultCountry,
        );

        if (validMobile && !hasMatches) {
          return [
            {
              id: 'new-' + inputValue,
              mobile,
            },
            ...options,
          ];
        }
        return options;
      }}
      getOptionLabel={(option: any) =>
        Helper.getNameOrMobileNumber(option) ?? ''
      }
      renderOption={(props: any, option: any) => {
        const newVal = (option?.id).startsWith('new');

        if (newVal) {
          return (
            <AutocompleteOption
              key={option.id}
              onClick={() => handleAddContact(option)}
              {...props}
            >
              <ListItemDecorator>
                <FontAwesomeIcon icon={solid('plus')} />
              </ListItemDecorator>
              {`${t('add')} "${option.mobile?.number}"`}
            </AutocompleteOption>
          );
        }

        return option ? (
          <Tooltip
            key={option.id}
            title={
              props['aria-disabled'] &&
              (option?.valid
                ? t('contactRequestedNoFurtherCommunication')
                : t('numberIsInvalid'))
            }
            size="sm"
            placement="top-start"
            arrow
            followCursor
          >
            <AutocompleteOption
              sx={{
                gap: 2,
                ...(props['aria-disabled'] && {
                  opacity: 0.5,
                }),
              }}
              {...props}
            >
              <ListItemDecorator>
                <Avatar person={option} />
              </ListItemDecorator>
              <ListItemContent>
                <Typography
                  {...(rest.size === 'sm' && {
                    level: 'body-md',
                  })}
                >
                  {Helper.getNameOrMobileNumber(option)}
                </Typography>
                <Typography
                  level={rest.size === 'sm' ? 'body-xs' : 'body-sm'}
                  textColor="text.tertiary"
                  endDecorator={
                    !option.valid || !!option.blocked ? (
                      <FontAwesomeIcon icon={solid('triangle-exclamation')} />
                    ) : null
                  }
                >
                  {option.mobile?.number}
                </Typography>
              </ListItemContent>
              {props['aria-selected'] ? (
                <ListItemDecorator>
                  <FontAwesomeIcon icon={solid('check')} />
                </ListItemDecorator>
              ) : null}
            </AutocompleteOption>
          </Tooltip>
        ) : null;
      }}
      slotProps={{
        root: {
          sx: {
            '--joy-focus-thickness': 0,
          },
        },
        input: {
          disabled: !isActive,
        },
        popupIndicator: {
          sx: {
            display: 'none',
          },
        },
        clearIndicator: {
          sx: {
            display: 'none',
          },
        },
        listbox: {
          sx: {
            overflowY: 'overlay',
            zIndex: 'calc(var(--joy-zIndex-modal) + 1)',
            maxHeight: 250,
          },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [30, 10],
              },
            },
          ],
        },
      }}
      noOptionsText={t('noneFound', { items: t('items.contact_other') })}
      placeholder={
        isActive && contacts?.length && contacts.length === 0 ? t('search') : ''
      }
      startDecorator={_.capitalize(t('to')) + ':'}
      getOptionDisabled={isOptionDisabled}
      {...rest}
    />
  );
}

export default PeoplePicker;
