import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  Stack,
  IconButton,
  Button,
  TypographySystem,
} from '@mui/joy';
import { EmptyState } from '@sakari-io/sakari-components';
import React from 'react';

interface EmptyResultProps {
  heading?: string;
  icon?: IconDefinition;
  text?: any;
  onIconClicked?: () => void;
  item?: string;
  buttonText?: string;
  onButtonClicked?: () => void;
  textColor?: string;
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
}

function EmptyResult({
  heading = 'No Results Found',
  icon,
  text,
  textColor = 'text.secondary',
  onIconClicked,
  item,
  buttonText,
  onButtonClicked,
  size = 'md',
  children,
}: EmptyResultProps) {
  const sizeMap: {
    [key in 'sm' | 'md' | 'lg']: {
      img: string;
      heading: keyof TypographySystem;
      text: keyof TypographySystem;
    };
  } = {
    sm: {
      img: '160px',
      heading: 'title-lg',
      text: 'body-sm',
    },
    md: {
      img: '230px',
      heading: 'h4',
      text: 'body-md',
    },
    lg: {
      img: '300px',
      heading: 'h3',
      text: 'body-lg',
    },
  };
  return (
    <Stack
      sx={{
        textAlign: 'center',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '80%',
        flex: 1,
      }}
    >
      {item && <EmptyState item={item} size={sizeMap[size].img} />}
      <Typography
        level={sizeMap[size].heading}
        fontWeight="bold"
        textColor={textColor}
      >
        {heading}
      </Typography>
      {text && (
        <Typography sx={{ display: 'block' }} level={sizeMap[size].text}>
          {text}
        </Typography>
      )}
      {icon && (
        <IconButton
          onClick={onIconClicked}
          color="primary"
          variant="solid"
          sx={{ borderRadius: '50%' }}
        >
          <FontAwesomeIcon icon={icon} />
        </IconButton>
      )}
      {buttonText && <Button onClick={onButtonClicked}>{buttonText}</Button>}
      {children}
    </Stack>
  );
}

export default EmptyResult;
