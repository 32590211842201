import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/pro-regular-svg-icons';
import DialpadButtons from '../../../Phone/Dialpad/DialpadButtons';
import IconsWrapper from '../IconWrapper';
import CallBarPopup from '../../CallBarPopup';

interface KeypadProps {
  onDigits: (digits: string) => any;
}

function Keypad({ onDigits }: KeypadProps) {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const button = (setAnchorEl: any) => {
    return (
      <Box ref={setAnchorEl}>
        <IconsWrapper>
          <IconButton
            variant="soft"
            color="voice"
            onClick={() => setShowPopup(!showPopup)}
          >
            <FontAwesomeIcon icon={faKeyboard} color="white" />
          </IconButton>
          <Typography level="body-sm">Keypad</Typography>
        </IconsWrapper>
      </Box>
    );
  };

  return (
    <CallBarPopup
      setShowPopup={setShowPopup}
      showPopup={showPopup}
      title="Keypad"
      button={button}
    >
      <Box padding={3}>
        <DialpadButtons onClick={onDigits} />
      </Box>
    </CallBarPopup>
  );
}

export default Keypad;
