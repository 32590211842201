import {
  Group,
  List,
  WorkflowDefinition,
  WorkflowEdgeType,
  WorkflowNodeProperty,
  WorkflowNodePropertyType,
} from '@sakari-io/sakari-typings';
import { Node, Edge } from 'reactflow';
import _ from 'lodash';

// interface NodeData {
//   id: string;
//   type: WorkflowNodeType;
//   config: WorkflowNodeConfig;
// }

interface EdgeData {
  edge: {
    id: string;
    source: string;
    target?: string;
    value?: string;
    type: WorkflowEdgeType;
  };
  mode: number;
  rule: any;
}

export const formatWorkflowDefinition = (
  nodes: Node[],
  edges: Edge[],
): WorkflowDefinition => {
  let updatedNodes = nodes; //.map((n) => n.data as NodeData);
  let updatedEdges = edges.map((e) => (e.data as EdgeData).edge);

  nodes?.forEach((n) => {
    if (n.data.type.id === 'actionPlaceholder') {
      const result = deletePlaceholders(n, updatedNodes, updatedEdges);
      updatedNodes = result.nodes;
      updatedEdges = result.edges;
    }
  });

  const finalNodes = updatedNodes
    .filter(({ data }) => {
      return data.type && data.type.id !== 'actionPlaceholder';
    })
    .map((node) => ({
      id: node.id,
      type: {
        id: node.data.type.id,
        type: node.data.type.type,
        name: node.data.type.name,
      },
      config: _.mapValues(node.data.config, (v: any, k: string) => {
        const prop = (node.data.type.properties || []).find(
          (p: WorkflowNodeProperty) => p.name === k,
        );
        if (prop) {
          // prune any unnecessary data
          switch (prop.type) {
            case WorkflowNodePropertyType.Groups:
              return (v || []).map((g: Group) => _.pick(g, ['id', 'name']));
            case WorkflowNodePropertyType.Lists:
              return (v || []).map((l: List) => _.pick(l, ['id', 'name']));
            case WorkflowNodePropertyType.Group:
            case WorkflowNodePropertyType.List:
              return v ? _.pick(v, ['id', 'name']) : undefined;
            default:
              return v;
          }
        }

        return v;
      }),
    }));

  return {
    nodes: finalNodes,
    edges: updatedEdges,
  };
};

export const isNodeConnectable = (type: string) => {
  return type !== 'trigger' && type !== 'actionPlaceholder';
};

const deletePlaceholders = (
  currentNode: Node | undefined,
  nodes: Node[],
  edges: EdgeData['edge'][],
) => {
  if (currentNode?.data?.type?.id !== 'actionPlaceholder')
    return { nodes, edges };

  const incomingEdge = edges?.find((e) => e?.target === currentNode?.id);
  const outgoingEdge = edges?.find((e) => e?.source === currentNode?.id);

  const edges1 = edges
    .filter((e) => e.id !== outgoingEdge?.id)
    .map((e) => {
      if (e.id === incomingEdge?.id) {
        return {
          ...e,
          target: outgoingEdge?.target,
          data: {
            ...(e as Edge)?.data,
            edge: {
              ...(e as Edge)?.data?.edge,
              target: outgoingEdge?.target,
            },
          },
        };
      }

      return e;
    });

  const nodes1 = nodes?.filter(({ id }) => id !== currentNode?.id);

  currentNode = undefined;
  return { nodes: nodes1, edges: edges1 };
};
