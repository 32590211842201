import React, { useState } from 'react';
import { Box, Stack } from '@mui/joy';
import {
  CampaignExecution,
  Campaign,
  Account,
} from '@sakari-io/sakari-typings';
import ExecutionChart from '../ExecutionChart';
import ExecutionsGrid from '../../../../ui/organisms/datagrids/ExecutionsGrid';

interface ExecutionReportProps {
  account: Account;
  campaign: Campaign;
  open?: boolean;
}

function ExecutionReport({ account, campaign, open }: ExecutionReportProps) {
  const [selectedExecutionRow, setSelectedExecutionRow] = useState<
    CampaignExecution | undefined
  >(undefined);
  if (!campaign || !open) {
    return null;
  }

  return (
    <Stack direction="row" padding={2} minHeight={400}>
      <ExecutionsGrid
        campaign={campaign}
        selectedRow={selectedExecutionRow}
        onSelectedRowChange={(execution) => setSelectedExecutionRow(execution)}
      />
      {selectedExecutionRow ? (
        <Box width={400}>
          <ExecutionChart account={account} execution={selectedExecutionRow} />
        </Box>
      ) : null}
    </Stack>
  );
}

export default ExecutionReport;
