import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autoresponder } from '@sakari-io/sakari-typings';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import { useDeleteAutoResponderMutation } from '../../../../api';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface DeleteAutoResponderDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  autoResponder: Autoresponder;
}

function DeleteAutoResponderDialog({
  open,
  setOpen,
  autoResponder,
}: DeleteAutoResponderDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteAutoResponder] = useDeleteAutoResponderMutation();

  const handleDelete = () => {
    deleteAutoResponder(autoResponder.id)
      .unwrap()
      .then((res) => {
        dispatch(
          showToast({
            severity: res.success ? 'info' : 'error',
            message: res.success
              ? t('groupsNav.autoResponders.delete.success')
              : t('groupsNav.autoResponders.delete.error'),
          }),
        );
      });
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={(result) => {
        setOpen(false);
        if (result) {
          handleDelete();
        }
      }}
      type="danger"
      confirmLabel="yesDelete"
      header={t('groupsNav.autoResponders.delete.label')}
      content={t('cannotUndoAction')}
    />
  );
}

export default DeleteAutoResponderDialog;
