import React from 'react';
import {
  Modal,
  Typography,
  Divider,
  ModalClose,
  ModalDialog,
  ModalProps,
  Stack,
  Button,
  ModalDialogProps,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';

export interface SimpleDialogProps
  extends Omit<ModalProps, 'onClose' | 'children' | 'title' | 'content'>,
    Pick<ModalDialogProps, 'layout' | 'sx'> {
  onClose: (result: boolean) => void;
  title?: string | React.ReactNode;
  content: React.ReactNode | string;
  confirmText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  noDivider?: boolean;
  slotProps?: any;
  confirmButtonProps?: any;
  cancelButtonProps?: any;
  noTitleDivider?: boolean;
  noContentDivider?: boolean;
  contentSx?: any;
}

function SimpleDialog({
  onClose,
  title,
  content,
  confirmText = 'action.confirm',
  cancelText = 'action.cancel',
  confirmDisabled,
  confirmLoading,
  children,
  layout,
  noDivider,
  slotProps,
  sx,
  contentSx,
  confirmButtonProps,
  cancelButtonProps,
  noTitleDivider,
  noContentDivider,
  ...rest
}: SimpleDialogProps) {
  const { t } = useTranslation();

  const handleDivider = (dividerType: 'title' | 'content') => {
    if (
      noDivider ||
      (dividerType === 'title' && noTitleDivider) ||
      (dividerType === 'content' && noContentDivider)
    ) {
      return null;
    }
    return <Divider inset="context" />;
  };

  return (
    <Modal onClose={() => onClose?.(false)} slotProps={slotProps} {...rest}>
      <ModalDialog
        layout={layout}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 0,
          width: 454,
          overflow: 'hidden',
          ...sx,
        }}
      >
        {!cancelText && <ModalClose />}
        {typeof title === 'string' ? (
          <>
            <Typography
              level="title-lg"
              sx={{
                p: 2,
              }}
            >
              {title}
            </Typography>
            {handleDivider('title')}
          </>
        ) : (
          <>
            {title}
            {handleDivider('title')}
          </>
        )}
        <Stack p={2} gap={2} flex={1} overflow="auto" sx={{ ...contentSx }}>
          {typeof content === 'string' ? (
            <Typography>{content}</Typography>
          ) : (
            content
          )}
        </Stack>
        {children}

        {handleDivider('content')}

        {confirmText || cancelText ? (
          <Stack
            sx={{
              backgroundColor: 'inherit',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              p: 2,
              gap: 2,
              position: 'sticky',
              bottom: 0,
            }}
          >
            {cancelText ? (
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => onClose?.(false)}
                {...cancelButtonProps}
              >
                {t(cancelText)}
              </Button>
            ) : null}
            {confirmText ? (
              <Button
                onClick={() => onClose?.(true)}
                disabled={confirmDisabled}
                loading={confirmLoading}
                {...confirmButtonProps}
              >
                {t(confirmText)}
              </Button>
            ) : null}
          </Stack>
        ) : null}
      </ModalDialog>
    </Modal>
  );
}
export default SimpleDialog;
