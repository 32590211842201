import {
  Webhook,
  SakariAPIResponse,
  SearchablePaginationRequest,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';
import { buildGridQuery } from './common';
import { WEBHOOKS_TAG } from './tags';
import { AccountIdWith } from '../types';

interface GetWebhooksRequest extends SearchablePaginationRequest {
  type: string;
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getWebhooks: builder.query<
      SakariAPIResponse<Webhook[]>,
      AccountIdWith<GetWebhooksRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/notifications?${buildGridQuery(request)}`,
      providesTags: (result: any = []) => [
        ...(result?.data || []).map(({ id }: { id: string }) => ({
          type: WEBHOOKS_TAG,
          id,
        })),
      ],
    }),
    deleteWebhook: builder.mutation<
      SakariAPIResponse<Webhook>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/notifications/${request}`,
        method: 'DELETE',
      }),
      invalidatesTags: [WEBHOOKS_TAG],
    }),
    updateWebhook: builder.mutation<
      SakariAPIResponse<Webhook>,
      AccountIdWith<Partial<Webhook> & Pick<Webhook, 'id'>>
    >({
      query: ({ accountId, request: { id, ...data } }) => ({
        url: `accounts/${accountId}/notifications/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: WEBHOOKS_TAG, id: arg.request.id },
      ],
    }),
    createWebhook: builder.mutation<
      SakariAPIResponse<Webhook>,
      AccountIdWith<Webhook>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/notifications`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [WEBHOOKS_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWebhooksQuery,
  useDeleteWebhookMutation,
  useUpdateWebhookMutation,
  useCreateWebhookMutation,
} = extendedApi;
