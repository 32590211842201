import React, { useState } from 'react';
import { useReactFlow, Node } from 'reactflow';
import { Button, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../../redux/reducers/workflow';

interface IncompleteNodesButtonProps {
  incompleteNodes: Node[];
}

function IncompleteNodesButton({
  incompleteNodes,
}: IncompleteNodesButtonProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation('workflows');
  const { fitView } = useReactFlow();
  const [currentNodeIndex, setCurrentNodeIndex] = useState(0);

  const handlePanToNode = () => {
    if (incompleteNodes.length === 0) return;

    const node = incompleteNodes[currentNodeIndex];

    dispatch(actions.setCurrentRFNode(node));

    fitView({ nodes: [node], padding: 0.1 });
    setCurrentNodeIndex(
      (prevIndex) => (prevIndex + 1) % incompleteNodes.length,
    );
  };

  return (
    incompleteNodes.length > 0 && (
      <Button
        variant="outlined"
        color="neutral"
        sx={{
          backgroundColor: 'transparent',
          '&:hover .MuiTypography-root': {
            color: 'var(--joy-palette-grey-900)',
          },
        }}
        onClick={handlePanToNode}
      >
        <Typography
          level="body-sm"
          sx={{
            color: 'var(--joy-palette-common-white)',
            gap: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            color="var(--joy-palette-warning-500)"
            fontWeight={700}
          />
          {`${incompleteNodes.length} ${t('issues')}`}
        </Typography>
      </Button>
    )
  );
}

export default IncompleteNodesButton;
