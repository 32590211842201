import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog, { ModalDialogProps } from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { ModalClose, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import getDefaultIcon from '../dialogs';

export interface ConfirmationDialogProps
  extends Omit<ModalDialogProps, 'content'> {
  open: boolean;
  onClose: (result?: boolean) => void;
  type?: 'success' | 'danger' | 'warning' | 'info';
  header?: string | React.ReactNode;
  headerIcon?: React.ReactNode;
  content?: string | React.ReactNode;
  confirmLabel: string | React.ReactNode;
  cancelLabel?: string;
  disableConfirm?: boolean;
  hideCancel?: boolean;
  width?: number;
  loading?: boolean;
}

function ConfirmationDialog({
  open,
  onClose,
  type,
  header,
  headerIcon,
  content,
  confirmLabel,
  cancelLabel = 'Cancel',
  hideCancel = false,
  size = 'md',
  width,
  sx,
  children,
  disableConfirm,
  loading,
  ...rest
}: ConfirmationDialogProps) {
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby="alert-dialog-modal-title"
      aria-describedby="alert-dialog-modal-description"
      open={open}
      onClose={() => onClose()}
    >
      <ModalDialog
        role="alertdialog"
        variant={rest.variant || 'outlined'}
        color={rest.color || 'neutral'}
        sx={{
          display: 'flex',
          flexFlow: 'column',
          ...(size === 'sm' && {
            width: 'clamp(320px, 50%, 360px)',
            py: 2,
            px: 3,
            gap: 2,
          }),
          ...(size === 'md' && {
            width: 'clamp(320px, 50%, 480px)',
            py: 4,
            px: 6,
            gap: 4,
          }),
          ...(size === 'lg' && {
            width: 'clamp(320px, 50%, 640px)',
            py: 6,
            px: 9,
            gap: 6,
          }),
          width: width || 'auto',
          ...sx,
        }}
        size={size}
        {...rest}
      >
        <ModalClose onClick={() => onClose()} />
        <Stack sx={{ alignItems: 'center', gap: 2 }}>
          {headerIcon || getDefaultIcon(type, size)}
          {typeof header === 'string' ? (
            <Typography id="alert-dialog-modal-title" level="h4">
              {header}
            </Typography>
          ) : (
            header
          )}
        </Stack>
        {typeof content === 'string' ? (
          <Typography
            id="alert-dialog-modal-description"
            textColor="text.tertiary"
            sx={{ display: 'flex', alignSelf: 'center' }}
          >
            {content}
          </Typography>
        ) : (
          content
        )}
        {children}
        <Stack
          sx={{
            gap: 1,
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          {!hideCancel ? (
            <Button
              onClick={() => onClose()}
              fullWidth
              size={size}
              color="neutral"
              variant="outlined"
            >
              {t(cancelLabel)}
            </Button>
          ) : null}

          <Button
            onClick={() => onClose(true)}
            size={size}
            fullWidth
            color={type}
            variant="solid"
            loading={loading}
            disabled={disableConfirm}
          >
            {typeof confirmLabel === 'string' ? t(confirmLabel) : confirmLabel}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default ConfirmationDialog;
