import React, { useState, useEffect } from 'react';

import {
  GridCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-pro';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip, Chip, IconButton } from '@mui/joy';

import { Account, Invoice } from '@sakari-io/sakari-typings';
import { format, parseISO } from 'date-fns';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import Helper from '../../../../utils/helper';
import EmptyResult from '../../../molecules/EmptyResult';
import DataGrid from '../../../molecules/DataGrid';
import { useGetInvoicesQuery } from '../../../../api';

function InvoicesGrid({ account }: { account: Account }) {
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'items',
      headerName: t('invoices.grid.headings.name'),
      // width: 300,
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const description =
          (params.row.lines || []).length > 1
            ? (
                params.row.lines.find((l: any) => !l.plan?.metadata?.country) ||
                params.row.lines[0]
              ).description
            : params.row.lines[0].description;

        return (
          <Box
            sx={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            title={description}
          >
            {description}
          </Box>
        );
      },
    },
    {
      field: 'date',
      headerName: t('invoices.grid.headings.date'),
      width: 250,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value: any) => format(parseISO(value), 'PP'),
    },
    {
      field: 'total',
      headerName: t('invoices.grid.headings.total'),
      width: 250,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value) => `$${Helper.formatNumberWithCommas(value, 2)}`,
    },
    {
      field: 'status',
      headerName: '',
      width: 150,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const getPaymentStatus = ({ paid, refunded, total }: any) => {
          if (refunded) {
            if (refunded < total) {
              return 'partialrefund';
            }

            return 'refunded';
          }

          if (paid) {
            return 'paid';
          }

          return 'outstanding';
        };
        const status = getPaymentStatus(params.row);
        const isPaid = params.row.paid;
        const label = t(`invoices.grid.types.${status}`);
        return <Chip color={isPaid ? 'success' : 'danger'}>{label}</Chip>;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      getActions: (params: GridRowParams) => {
        return [
          <Tooltip title="Download" arrow placement="top" key="download">
            <IconButton
              size="smRound"
              onClick={() => window.open(params.row.invoicePdf, '_blank')}
            >
              <FontAwesomeIcon icon={faCloudArrowDown} />
            </IconButton>
          </Tooltip>,
        ];
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<Invoice>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadedRows, setLoadedRows] = useState<Invoice[]>([]);

  useEffect(() => {
    setLoadedRows([]);
    setPage(0);
  }, [setPage]);

  return (
    <DataGrid
      columns={columns}
      selectedRow={selectedRow}
      onSelectedRowChange={setSelectedRow}
      filter={{ accountId: account.id, request: {} }}
      loadData={useGetInvoicesQuery}
      renderNoData={
        <EmptyResult
          heading={t('settings.billing.invoices.noInvoices')}
          item="invoices"
        />
      }
    />
  );
}

export default InvoicesGrid;
