import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import React, { useContext, useState } from 'react';
import { Stack, Button, Typography } from '@mui/joy';
import { logger } from '@sakari-io/sakari-components';
import { StepDetail } from '../../../../../types/ui';

import WizardPage from '../../../../../ui/templates/forms/WizardPage';
import WizardSteps from '../../../../../ui/molecules/WizardSteps';
import Country from '../Country';
import NumberType from './NumberType';
import ChooseNumber from './ChooseNumber';

import {
  useBuyPhoneNumbersMutation,
  useBuyPhoneNumbersPreviewMutation,
} from '../../../../../api';
import { useAppDispatch } from '../../../../../redux';
import { showToast } from '../../../../../redux/reducers/toast';
import Alphanumeric from './Alphanumeric';
import Success from '../Success';
import ConfirmPurchaseDialog from '../ConfirmPurchaseDialog';
import ConfirmationDialog from '../../../../../ui/molecules/ConfirmationDialog';
import { AccountContext } from '../../../../../contexts/account.context';

function BuyMode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { country } = useParams();
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);

  const [
    buyPhoneNumbersPreview,
    { isLoading: isPreviewing, data: previewData },
  ] = useBuyPhoneNumbersPreviewMutation();

  const [buyPhoneNumbers, { isLoading: isBuying }] =
    useBuyPhoneNumbersMutation();

  const [showDialog] = useState<'success' | 'verify' | 'ticket' | undefined>(
    undefined,
  );

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showRaiseTicket, setShowRaiseTicket] = useState(false);

  const ALL_STEPS: StepDetail[] = [
    {
      id: 'country',
      title: t('addSenders.phonenumber.selectCountry.title'),
      label: t('addSenders.phonenumber.selectCountry.label'),
      subLabel: (values) => values.country?.name,
      pageComponent: <Country name="country" />,
      isVisible: () => true,
    },
    {
      id: 'lineType',
      label: t('addSenders.phonenumber.selectNumberType.label'),
      title: t('addSenders.phonenumber.selectNumberType.title'),
      subLabel: (values) =>
        values.lineType?.type
          ? t(`linetypes.${values.lineType?.type}`)
          : undefined,
      pageComponent: <NumberType name="lineType" />,
      isVisible: () => true,
    },
    {
      id: 'alphaNumeric',
      label: t('addSenders.phonenumber.alphaNumeric.label'),
      title: t('addSenders.phonenumber.alphaNumeric.title'),
      subLabel: (values) => values.alphaNum || null,
      pageComponent: <Alphanumeric name="alphaNum" />,
      isVisible: (values) => values?.lineType?.type === 'senderid',
    },
    {
      id: 'numbers',
      label: t('addSenders.phonenumber.selectNumbers.label'),
      title: '',
      description: (
        <Typography level="title-lg" fontWeight={700}>
          {t('addSenders.phonenumber.selectNumbers.chooseNumber')}
        </Typography>
      ),
      subLabel: (values) =>
        values.numbers
          ? `${values.numbers.length} number${values.numbers.length > 1 ? 's' : ''} selected`
          : undefined,
      pageComponent: (
        <ChooseNumber
          name="numbers"
          lineTypeName="lineType"
          countryName="country"
        />
      ),
      isVisible: (values) =>
        !['senderid', 'other'].includes(values?.lineType?.type) &&
        values?.lineType?.supported === 'SELF_SERVICE',
    },
  ];

  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const currentStep = ALL_STEPS[currentStepIdx];

  const moveToPreviousStep = (values: any) => {
    if (currentStepIdx === 0) {
      return currentStep;
    }
    // findLastIndex
    const previousIdx = ALL_STEPS.reduce((res, step, idx) => {
      if (idx < currentStepIdx && step.isVisible(values)) {
        return idx;
      }

      return res;
    }, currentStepIdx);
    if (previousIdx >= 0) {
      setCurrentStepIdx(previousIdx);
      return ALL_STEPS[previousIdx];
    }

    return currentStep;
  };

  const moveToNextStep = (values: any) => {
    const nextIdx = ALL_STEPS.findIndex(
      (step, idx) => idx > currentStepIdx && step.isVisible(values),
    );
    if (nextIdx >= 0) {
      setCurrentStepIdx(nextIdx);
      return ALL_STEPS[nextIdx];
    }

    return currentStep;
  };

  const schema = Yup.object().shape({
    country: Yup.object({
      code: Yup.string().required(
        t('validation.isRequired', {
          field: t('form.country.label'),
        }),
      ),
    }),
    lineType: Yup.object()
      .when('country', {
        is: () => currentStep.id === 'lineType',
        then: Yup.object({
          type: Yup.string().required(
            t('validation.isRequired', {
              field: t('addSenders.phonenumber.selectNumberType.field'),
            }),
          ),
        }),
      })
      .nullable(),
    numbers: Yup.array().when('country', {
      is: () => currentStep.id === 'numbers',
      then: Yup.array()
        .of(Yup.string())
        .min(1, t('validation.minSelect', { min: 1 }))
        .required(),
    }),
    alphaNum: Yup.string().when('country', {
      is: () => currentStep.id === 'alphaNumeric',
      then: Yup.string()
        .required(t('addSenders.phonenumber.alphaNumeric.error'))
        .min(3, t('validation.minLength', { count: 3 }))
        .max(11, t('validation.exceededMaxLength', { count: 11 }))
        .matches(
          /^(?=.*[a-zA-Z].*)[0-9A-Za-z]+$/,
          t('validation.regexCheck', { field: 'ID' }),
        ),
    }),
  });

  const validate = makeValidate(schema);

  const initialValues = {};

  const handleBack = (values: any) => {
    if (currentStep.id === 'lineType') {
      values.lineType = undefined;
    }
    moveToPreviousStep(values);
  };

  const handleOnSubmit = (values: any) => {
    if (
      currentStep?.id === 'lineType' &&
      values.lineType.supported === 'TICKET'
    ) {
      setShowRaiseTicket(true);
      return;
    }

    if (['numbers', 'alphaNumeric'].includes(currentStep.id)) {
      buyPhoneNumbersPreview({
        provider: 'sakari',
        type: values.lineType.type,
        numbers:
          currentStep.id === 'numbers'
            ? values.numbers?.map((n: string) => ({
                number: n,
                country: values.country.code,
              }))
            : [
                {
                  number: values.alphaNum,
                  country: values.country.code,
                },
              ],
      })
        .unwrap()
        .then(() => {
          setShowConfirmDialog(true);
        })
        .catch((err) => {
          dispatch(
            showToast({
              severity: 'error',
              message: err.data.error?.message || t('somethingWrong'),
            }),
          );
        });
    }

    moveToNextStep(values);
  };

  const renderNextButton = (
    values: any,
    hasValidationErrors: boolean,
    hasSubmitErrors: boolean,
    submitting: boolean,
  ) => {
    const getLabel = () => {
      if (currentStep.isLast) {
        return t('action.addToSubscription');
      }
      return t('action.next');
    };

    return (
      <Button
        color="primary"
        disabled={
          hasValidationErrors ||
          hasSubmitErrors ||
          (currentStep.id === 'numbers' && !values?.numbers?.length)
        }
        loading={submitting || isBuying || isPreviewing}
        fullWidth
        type="submit"
      >
        {getLabel()}
      </Button>
    );
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({
        values,
        handleSubmit,
        hasValidationErrors,
        hasSubmitErrors,
        submitting,
      }) => {
        const filteredSteps = ALL_STEPS.filter((s) => s.isVisible(values));
        return (
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Stack
              sx={{
                py: 1,
                pl: 4,
                pr: 2,
                width: 224,
                alignItems: 'center',
              }}
            >
              <WizardSteps
                orientation="vertical"
                steps={filteredSteps}
                activeStep={filteredSteps.findIndex(
                  (s) => s.id === currentStep.id,
                )}
                withFade
              />
            </Stack>

            <Stack
              sx={{
                paddingY: 1,
                paddingLeft: 4,
                paddingRight: { md: 12, xs: 6 },
                overflow: 'auto',
                width: '100%',
              }}
              spacing={2}
            >
              <WizardPage currentStep={currentStep} />
              <Stack
                sx={{
                  columnGap: 2,
                  height: 'fit-content',
                  flexDirection: 'row',
                  width: '400px',
                }}
              >
                <Button
                  variant="outlined"
                  color="neutral"
                  onClick={() => handleBack(values)}
                  fullWidth
                >
                  {currentStep.isLast
                    ? t('action.startOver')
                    : t('action.back')}
                </Button>
                {renderNextButton(
                  values,
                  hasValidationErrors,
                  hasSubmitErrors,
                  submitting,
                )}
              </Stack>
            </Stack>
            {showDialog && (
              <Success type={showDialog} open={showDialog !== undefined} />
            )}
            {showConfirmDialog && account?.plan && (
              <ConfirmPurchaseDialog
                open={showConfirmDialog}
                setOpen={setShowConfirmDialog}
                values={values}
                price={previewData?.data?.price}
                plan={account?.plan}
              />
            )}

            <ConfirmationDialog
              open={showRaiseTicket}
              onClose={async (result) => {
                if (result && values.country?.code && values.lineType) {
                  try {
                    const result = await buyPhoneNumbers({
                      // country: country.code,
                      type: values.lineType.type,
                      numbers: [
                        {
                          country: values.country.code,
                          number: '',
                        },
                      ],
                      provider: 'sakari',
                    }).unwrap();

                    logger.info('result', result);
                    dispatch(
                      showToast({
                        severity: 'success',
                        message: 'Your request has been raised',
                      }),
                    );

                    return navigate('/senders');
                  } catch (err) {
                    dispatch(
                      showToast({
                        severity: 'error',
                        message:
                          'Unable to raise ticket with our support team. Please email them directly at help@sakari.io',
                      }),
                    );
                  }
                } else {
                  setShowRaiseTicket(false);
                }
                return null;
              }}
              confirmLabel="Raise Ticket"
              cancelLabel="Cancel"
              header="Raise Ticket"
              content={
                <>
                  <div>
                    Your chosen line type is not currently available through our
                    self service platform.
                  </div>
                  <div>
                    Our support team will reach out with additional information
                    and questions to get your desired line type setup.
                  </div>
                </>
              }
            />
          </form>
        );
      }}
    />
  );
}
export default BuyMode;
