import React, { useState, useEffect } from 'react';
import { Stack, Typography, Box } from '@mui/joy';
import { useSelector, useDispatch } from 'react-redux';
import { WorkflowEdge } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { Node, Edge } from 'reactflow';
import Radios, { RadioData } from '../../../../ui/molecules/Radios';
import { actions } from '../../../../redux/reducers/workflow';
import ChildrenEdgeSelect from './ChildrenEdgeSelect';
import Dialog from '../../../../components/molecules/Dialog';

interface DeleteHoverDialogProps {
  id: string;
  open: boolean;
  onClose: () => void;
}

// TODO Rename DeleteHoverDialog
// TODO use Redux form validation to validate selections
function DeleteHoverDialog({ id, open, onClose }: DeleteHoverDialogProps) {
  const { t } = useTranslation('workflows');
  const dispatch = useDispatch();

  const { nodes, edges } = useSelector((state: any) => state.workflow.present);
  const node = nodes.find((n: Node) => n.id === id);

  const [behavior, setBehavior] = useState<string>('');
  const [edge, setEdge] = useState<WorkflowEdge>();

  const [downstreamNodes, setDownstreamNodes] = useState<string[]>([]);
  const outputEdges = edges
    .map(({ data }: Edge) => data?.edge)
    .filter(({ source, target }: WorkflowEdge) => source === id && target);

  useEffect(() => {
    const dsNodes: string[] = [];
    const findDownstreamNodes = (nodeId: string) => {
      const currentEdges = edges.filter(
        ({ source }: Edge) => source === nodeId,
      );
      currentEdges.forEach((e: Edge) => {
        if (e.data?.edge?.target && !dsNodes.includes(e.target)) {
          dsNodes.push(e.target);
          findDownstreamNodes(e.target);
        }
      });
    };
    findDownstreamNodes(id);
    setDownstreamNodes(dsNodes);
  }, []);

  useEffect(() => {
    if (outputEdges.length === 1) {
      setEdge(outputEdges[0]);
    }
  }, [outputEdges]);

  const handleDelete = () => {
    dispatch(actions.deleteNode(behavior !== 'deleteAll' ? edge : undefined));
    onClose();
  };

  /*
LOGIC:
 1. If node is a trigger
     - show just a confirmation
 2. If node is last node in branch
     - show just a confirmation
 3. If node is has one output branch
     - show
        1. Delete current node and all downstream nodes
        2. Delete node and connect sole output branch to previous node
 4. Otherwise, node has multiple branches
     - show 
        1. Delete node and all nodes on subsequent branches
        2. Delete node and connect choosen branch to previous node
*/

  const getDialogTitle = () => {
    if (node?.type === 'trigger') {
      return t('edit.deleteNode.confirmDeleteTrigger');
    }
    return t('edit.deleteNode.deleteNodes');
  };

  const getDescription = () => {
    if (node?.type === 'trigger') {
      return t('edit.deleteNode.trigger');
    }

    if (!downstreamNodes.length || node?.type === 'actionPlaceholder') {
      return 'The selected action will be removed from the workflow';
    }

    return t('edit.deleteNode.deleteAll');
  };

  const getOptions = (): RadioData<string>[] => {
    if (node?.type === 'trigger') {
      return [];
    }

    if (node?.type === 'actionPlaceholder') {
      return [];
    }

    const options = [
      {
        label: downstreamNodes.length
          ? t('edit.deleteNode.allDownstream')
          : 'Delete node', // TODO
        value: 'deleteAll',
      },
    ];

    switch (outputEdges?.length) {
      case 0:
        break;
      case 1:
        options.push({
          label: t('edit.deleteNode.connectFollowing'),
          value: 'connectBranch',
        });
        break;
      default:
        options.push({
          label: t('edit.deleteNode.connectBranch'),
          value: 'connectBranch',
        });
    }

    return options;
  };

  const options = getOptions();

  return (
    <Dialog
      color="danger"
      open={open}
      onClose={onClose}
      title={getDialogTitle()}
      primaryAction={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleDelete();
      }}
      disablePrimary={options.length > 1 && behavior === ''}
      primaryTitle={t('confirm')}
      cancelAction={onClose}
      cancelTitle={t('cancel')}
    >
      <Stack spacing={1}>
        {options?.length > 1 ? (
          <Radios items={options} value={behavior} onChange={setBehavior} />
        ) : (
          <Typography level="body-sm">{getDescription()}</Typography>
        )}

        {behavior === 'connectBranch' && outputEdges.length >= 2 && (
          <Stack spacing={1}>
            <ChildrenEdgeSelect
              options={outputEdges}
              value={edge}
              onChange={setEdge}
            />

            <Box
              p={1}
              sx={{
                width: '100%',
                border: '1px solid var(--joy-palette-divider)',
                backgroundColor: 'var(--joy-palette-divider)',
                borderRadius: '4px',
              }}
            >
              <Typography level="body-sm">
                {t('edit.deleteNode.allNodesAboveWouldConnect')}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
}

export default DeleteHoverDialog;
