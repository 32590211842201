import React from 'react';
import { useTranslation } from 'react-i18next';
import TabbedNavigation, {
  NavTabProps,
} from '../../ui/organisms/headers/TabbedNavigation';
import PageHeader from '../../ui/organisms/headers/PageHeader';

export enum IntegrationsViewMode {
  MARKETPLACE = 0,
  INSTALLED = 1,
}

interface MarketplaceProps {
  children?: React.ReactNode;
  view: IntegrationsViewMode;
}

function IntegrationsPage({ children, view }: MarketplaceProps) {
  const { t } = useTranslation();

  const navigationItems: NavTabProps[] = [
    {
      label: t('integrations.marketplace.title'),
      url: '/marketplace',
      value: 0,
    },
    {
      label: t('integrations.installed.title'),
      url: '/integrations',
      value: 1,
    },
  ];

  const tabUrlProvider = (tab: number) => {
    if (tab === 0) {
      return '/marketplace';
    }
    if (tab === 1) {
      return '/integrations';
    }
    return '';
  };

  return (
    <>
      <PageHeader title="Integrations" />
      <TabbedNavigation
        divider
        tabs={navigationItems}
        selectedTab={view}
        onTabSelected={(tab) => tabUrlProvider(tab)}
      >
        {children}
      </TabbedNavigation>
    </>
  );
}

export default IntegrationsPage;
