import React from 'react';
import { Card, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

function CustomTooltip(props: any) {
  const { t } = useTranslation();
  const { payload } = props;

  const value = payload[0]?.value;
  const { label } = props;
  const description = t(
    `campaigns.status.${payload[0]?.payload?.id}.description`,
  );
  return (
    <Card
      variant="outlined"
      size="sm"
      sx={{
        maxWidth: 140,
      }}
    >
      <Typography level="body-md" fontWeight={700}>
        {label} : {value}
      </Typography>
      <Typography level="body-sm">{description}</Typography>
    </Card>
  );
}

export default CustomTooltip;
