/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, Button } from '@mui/joy';
import {
  ErrorResponse,
  PhoneNumber,
  TenDlcCampaign,
} from '@sakari-io/sakari-typings';
import { makeValidate } from 'mui-rff';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { logger } from '@sakari-io/sakari-components';
import {
  useRegister10DLCBrandMutation,
  useRegister10DLCCampaignMutation,
  useAssociate10DLCCampaignMutation,
  useUpdateAndResubmit10DLCCampaignMutation,
} from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import WizardPage from '../../../../ui/templates/forms/WizardPage';
import WizardSteps from '../../../../ui/molecules/WizardSteps';
import { StepDetail } from '../../../../types';
import Brand from './Brand';
import Campaigns from './Campaigns';
import ConfirmationDialog from '../../../../ui/molecules/ConfirmationDialog';
import Checkboxes from '../../../../ui/organisms/Checkboxes';
import FieldWrapper from '../../../../utils/FieldWrapper';
import CampaignDetails from './CreateCampaign/CampaignDetails';
import Success from '../../SendersAdd/PhoneNumber/Success';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

const phoneRegExp = /^\+\d{11,14}/gm;

interface TenDLCRegistrationProps {
  senders: PhoneNumber[];
  campaign?: TenDlcCampaign;
}

function TenDLCRegistration({ senders, campaign }: TenDLCRegistrationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const showError = (error: ErrorResponse) => {
    dispatch(
      showToast({
        severity: 'error',
        message:
          (error?.error as any)?.message ||
          error?.error?.description ||
          t('somethingWrong'),
      }),
    );
  };

  const { account } = useContext(AccountContext);

  const [registerBrand, { isLoading: registeringBrand }] =
    useRegister10DLCBrandMutation();

  const [registerCampaign, { isLoading: registeringCampaign }] =
    useRegister10DLCCampaignMutation();

  const [associateCampaign, { isLoading: associatingCampaign }] =
    useAssociate10DLCCampaignMutation();

  const [updateAndResubmitCampaign] =
    useUpdateAndResubmit10DLCCampaignMutation();

  const isRegistering = registeringBrand || registeringCampaign;

  const initialValues = useMemo(() => {
    logger.info('senders', senders);

    const defaultValues = {
      isResubmitting: senders[0].channels?.sms?.regulatory?.rejected,
      numbers: senders,
      brand: campaign?.brandId
        ? {
            id: campaign.brandId,
          }
        : undefined,
      campaign: campaign && {
        ...campaign,
        optin: {
          message: campaign.optin.message,
          keywords: campaign.optin.keywords.join(', '),
        },
      },
    };

    return defaultValues;
  }, []);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [steps] = useState<StepDetail[]>([
    {
      id: 'brand',
      label: t('senders.register.10dlc.brand.label'),
      title: t('senders.register.10dlc.brand.title'),
      subLabel: (values) => values.brand?.displayName,
      pageComponent: <Brand />,
      isVisible: (values) => !values.isResubmitting,
    },
    {
      id: 'selectcampaign',
      label: 'Campaign',
      title: t('senders.register.10dlc.campaign.select'),
      subLabel: (values) =>
        values.campaign?.campaignId ||
        values.campaign?.useCases?.map((uc: any) => uc.label).join(', '),
      pageComponent: account && <Campaigns account={account} />,
      isVisible: () => true,
    },
    {
      id: 'campaigndetails',
      label: t('senders.register.10dlc.campaign.details'),
      title: t('senders.register.10dlc.campaign.details'),
      subLabel: (values) => {
        if (!values?.campaign?.features) return '';

        const features = Object.entries(values.campaign.features)
          .filter(([key, val]) => val)
          .map(([key, val]) =>
            t(`senders.register.10dlc.campaign.features.${key}.label`),
          )
          .join(', ');

        if (values.campaign.lowVolume) {
          return `(${t(
            'senders.register.10dlc.campaign.isLowVolume.title',
          )}) ${features}`;
        }

        return features;
      },
      pageComponent: account && <CampaignDetails />,
      isVisible: (values) =>
        !values.campaign?.id && values.campaign?.useCases?.length,
      isLast: true,
    },
    // {
    //   id: 'review',
    //   label: t('senders.register.review.label'),
    //   title: t('senders.register.review.title'),
    //   pageComponent: <Review />,
    //   isVisible: () => true,
    //   isLast: true,
    // },
  ]);

  const [step, setStep] = useState(0);
  const currentStep = useMemo(() => steps[step].id.toString(), [steps, step]);

  useEffect(() => {
    if (initialValues.isResubmitting) {
      logger.info('initialValues', initialValues);
      // if resubmitting a campaign, skip to campaign edit form, and select the campaign to populate the form
      setStep(2);
    }
  }, [initialValues]);

  const schema = Yup.object().shape({
    brand: Yup.object().when([], {
      is: () => currentStep === 'brand',
      then: Yup.object()
        .shape({
          id: Yup.string().when('status', {
            is: 'NEW',
            then: Yup.string(),
            otherwise: Yup.string().required(t('10dlc.errors.brand.required')),
          }),
          status: Yup.string().required(t('10dlc.errors.brand.required')),
          //  Company and Brand Info
          email: Yup.string().when('id', {
            is: undefined,
            then: Yup.string()
              .email(t('10dlc.errors.brand.email.invalid'))
              .max(100, t('10dlc.errors.exceededMaxLength', { count: 100 }))
              .required(t('10dlc.errors.brand.email.required')),
          }),

          phone: Yup.mixed().when('id', {
            is: undefined,
            then:
              Yup.object({
                number: Yup.string()
                  .matches(phoneRegExp, t('10dlc.errors.brand.phone.invalid'))
                  .required(t('10dlc.errors.brand.phone.required')),
                country: Yup.string().max(
                  2,
                  t('10dlc.errors.exceededMaxLength', { count: 2 }),
                ),
              }) ||
              Yup.string()
                .matches(phoneRegExp, t('10dlc.errors.brand.phone.invalid'))
                .required(t('10dlc.errors.brand.phone.required')),
          }),
          entityType: Yup.string().when('id', {
            is: undefined,
            then: Yup.string().required(
              t('10dlc.errors.brand.entityType.required'),
            ),
          }),
          vertical: Yup.string().when('id', {
            is: undefined,
            then: Yup.string().required(
              t('10dlc.errors.brand.vertical.required'),
            ),
          }),
          companyName: Yup.string().when('id', {
            is: undefined,
            then: Yup.string()
              .max(255, t('10dlc.errors.exceededMaxLength', { count: 255 }))
              .required(t('10dlc.errors.brand.companyName.required')),
          }),
          displayName: Yup.string().when('id', {
            is: undefined,
            then: Yup.string().max(
              255,
              t('10dlc.errors.exceededMaxLength', { count: 255 }),
            ),
            // .required(t('10dlc.errors.brand.displayName.required')),
          }),
          address: Yup.object().when('id', {
            is: undefined,
            then: Yup.object().shape({
              line1: Yup.string()
                .max(100, t('10dlc.errors.exceededMaxLength', { count: 100 }))
                .required(t('10dlc.errors.brand.address.required')),
              city: Yup.string()
                .max(100, t('10dlc.errors.exceededMaxLength', { count: 100 }))
                .required(t('10dlc.errors.brand.address.city.required')),
              state: Yup.mixed().test(
                'valid-state',
                'Invalid state',
                function (value) {
                  const { path, createError } = this;
                  if (typeof value === 'string') {
                    return Yup.string().required().isValidSync(value);
                  }
                  if (
                    typeof value === 'object' &&
                    value !== null &&
                    'code' in value
                  ) {
                    return Yup.object()
                      .shape({ code: Yup.string().required() })
                      .nullable()
                      .isValidSync(value);
                  }
                  return createError({
                    path,
                    message: t('validation.pleaseEnter', {
                      field: t('form.state.label').toLowerCase(),
                    }),
                  });
                },
              ),
              postalCode: Yup.string()
                .max(10, t('10dlc.errors.exceededMaxLength', { count: 10 }))
                .required(t('10dlc.errors.brand.address.postalCode.required')),

              country: Yup.object({
                code: Yup.string()
                  .max(2, t('10dlc.errors.exceededMaxLength', { count: 2 }))
                  .required(t('10dlc.errors.brand.address.country.required')),
              })
                .required(t('10dlc.errors.brand.address.country.required'))
                .nullable(),
            }),
          }),
          website: Yup.string().when('id', {
            is: undefined,
            then: Yup.string()
              .url('Please enter a valid URL')
              .max(100, t('10dlc.errors.exceededMaxLength', { count: 100 })),
          }),

          // Tax Info
          tax: Yup.object().when(['id', 'entityType'], {
            is: (id: string, entityType: string) =>
              !['SOLE_PROPRIETOR'].includes(entityType) && id === undefined,
            then: Yup.object().shape({
              id: Yup.string()
                .max(21, t('10dlc.errors.exceededMaxLength', { count: 21 }))
                .required(t('10dlc.errors.brand.tax.id.required')),

              country: Yup.object({
                code: Yup.string()
                  .max(2, t('10dlc.errors.exceededMaxLength', { count: 2 }))
                  .required(t('10dlc.errors.brand.address.country.required')),
              }),
            }),
          }),

          // Stock Info
          stock: Yup.object().when(['entityType', 'id'], {
            is: (entityType: string, id: string) =>
              entityType === 'PUBLIC_PROFIT' && id === undefined,
            then: Yup.object().shape({
              symbol: Yup.string()
                .max(10, t('10dlc.errors.exceededMaxLength', { count: 10 }))
                .required(t('10dlc.errors.brand.stock.symbol.required')),
              exchange: Yup.string()
                .required(t('10dlc.errors.brand.stock.exchange.required'))
                .nullable(true),
            }),
          }),

          // // Sole Prop Info
          // ownerFirstName: Yup.string().when(['status', 'entityType'], {
          //   is: (status: string, entityType: any) =>
          //     status === 'NEW' && ['SOLE_PROPRIETOR'].includes(entityType),
          //   then: Yup.string()
          //     .max(100, t('10dlc.errors.exceededMaxLength', { count: 100 }))
          //     .required(t('10dlc.errors.brand.owner.firstName.required')),
          // }),
          // ownerLastName: Yup.string().when(['status', 'entityType'], {
          //   is: (status: string, entityType: any) =>
          //     status === 'NEW' && ['SOLE_PROPRIETOR'].includes(entityType),
          //   then: Yup.string()
          //     .max(100, t('10dlc.errors.exceededMaxLength', { count: 100 }))
          //     .required(t('10dlc.errors.brand.owner.lastName.required')),
          // }),
          // ownerMobile: Yup.mixed().when(['status', 'entityType'], {
          //   is: (status: string, entityType: string) =>
          //     status === 'NEW' && ['SOLE_PROPRIETOR'].includes(entityType),
          //   then:
          //     Yup.object({
          //       number: Yup.string()
          //         .matches(phoneRegExp, t('10dlc.errors.brand.phone.invalid'))
          //         .required(t('10dlc.errors.brand.owner.mobile.required')),
          //       country: Yup.string().max(
          //         2,
          //         t('10dlc.errors.exceededMaxLength', { count: 2 })
          //       ),
          //     }) ||
          //     Yup.string()
          //       .matches(phoneRegExp, t('10dlc.errors.brand.phone.invalid'))
          //       .required(t('10dlc.errors.brand.owner.mobile.required')),
          // }),
        })
        .required(t('validation.required')),
    }),
    // TODO: Add max subUseCases validation 5
    campaign: Yup.object()
      .when(['brand'], {
        is: () => ['selectcampaign', 'campaigndetails'].includes(currentStep),
        then: Yup.object({
          id: Yup.string().when('status', {
            is: 'NEW',
            then: Yup.string(),
            otherwise: Yup.string().required(
              t('10dlc.errors.campaign.required'),
            ),
          }),
          status: Yup.string(),
          useCases: Yup.array()
            .of(Yup.object({}))
            .when('status', {
              is: 'NEW',
              then: Yup.array()
                .of(Yup.object({}))
                .min(1, t('10dlc.errors.campaign.usecases.minimum'))
                .max(5, t('10dlc.errors.campaign.usecases.maximum'))
                .required(t('10dlc.errors.campaign.usecases.minimum')),
            }),
          lowVolume: Yup.boolean(),

          description: Yup.string().when([], {
            is: () => currentStep === 'campaigndetails',
            then: Yup.string()
              .min(40, t('validation.minLength', { count: 40 }))
              .max(500, t('10dlc.errors.exceededMaxLength', { count: 500 }))
              .required(t('10dlc.errors.campaign.description.required')),
          }),
          messageFlow: Yup.string().when([], {
            is: () => currentStep === 'campaigndetails',
            then: Yup.string()
              .min(40, t('validation.minLength', { count: 40 }))
              .max(500, t('10dlc.errors.exceededMaxLength', { count: 500 }))
              .required(t('10dlc.errors.campaign.messageFlow.required')),
          }),
          samples: Yup.array().when([], {
            is: () => currentStep === 'campaigndetails',
            then: Yup.array()
              .of(
                Yup.string()
                  .min(20, t('validation.minLength', { count: 20 }))
                  .max(500, t('10dlc.errors.exceededMaxLength', { count: 500 }))
                  .required(t('10dlc.errors.campaign.samples.required')),
              )
              .min(1, t('10dlc.errors.campaign.samples.minimum')),
          }),
          features: Yup.object().when([], {
            is: () => currentStep === 'campaigndetails',
            then: Yup.object(),
          }),

          optin: Yup.object().when(['features'], {
            is: (features: any) =>
              features?.subscriberOptin && currentStep === 'campaigndetails',
            then: Yup.object().shape({
              keywords: Yup.string()
                .required('Required')
                .min(4, t('validation.minLength', { count: 4 })),
              message: Yup.string()
                .required('Required')
                .min(20, t('validation.minLength', { count: 20 }))
                .max(320, t('10dlc.errors.exceededMaxLength', { count: 320 })),
            }),
          }),
          toc: Yup.boolean().when([], {
            is: () => currentStep === 'campaigndetails',
            then: Yup.boolean()
              .equals([true], t('10dlc.errors.campaign.toc'))
              .required(),
          }),
        }).nullable(),
      })
      .nullable(),
  });

  const validate = makeValidate(schema);

  const handleOnSubmit = async (values: any, form: any) => {
    switch (currentStep) {
      case 'selectcampaign':
        if (values.campaign?.id) {
          return associateCampaign({
            campaign: values.campaign,
            numbers: values.numbers?.map((pn: any) => pn.number),
          })
            .unwrap()
            .then(() => setShowSuccess(true))
            .catch(({ data }) => showError(data));
        }
        setStep(step + 1);

        break;
      case 'campaigndetails':
        setShowConfirmation(true);
        break;
      default:
        setStep(step + 1);
    }
    return null;
  };

  const handleBack = (values: any, form: any) => {
    const changeStep = () =>
      step > 0 ? setStep((prev) => prev - 1) : history.back();

    if (currentStep === 'brand') {
      if (values.brand?.status === 'NEW') {
        form.change('brand', {});
      } else changeStep();
    } else if (currentStep === 'selectcampaign') {
      if (values.campaign?.status === 'NEW') {
        form.change('campaign', {});
      } else changeStep();
    } else {
      changeStep();
    }
  };

  const findOrCreateBrand = async (input: any) => {
    if (input?.id) {
      return input;
    }

    const {
      companyName,
      displayName,
      address,
      entityType,
      phone,
      email,
      website,
      tax,
      vertical,
    } = input;

    const brandRequest = {
      companyName,
      displayName,
      address: {
        street: [address.line1, address.line2].filter(Boolean).join(', '),
        city: address.city,
        state: address.state?.code || address.state,
        postalCode: address.postalCode,
        country: address.country?.code,
      },
      entityType,
      phone: phone?.number,
      email,
      website,
      tax: {
        id: tax.id,
        country: tax.country?.code,
      },
      vertical,
    };

    return registerBrand(brandRequest)
      .unwrap()
      .then(({ data }) => data);
  };

  const getUseCases = ({ useCases, subUseCases, useCase }: any) => {
    if (useCases?.length) {
      return useCases;
    }

    if (subUseCases?.length) {
      return subUseCases.map((id: string) => ({ id }));
    }

    if (useCase) {
      return [{ id: useCase }];
    }

    return undefined;
  };

  const createCampaign = async (brand: any, input: any, numbers: any[]) => {
    const {
      description,
      samples,
      features,
      messageFlow,
      optin,
      lowVolume,
      toc,
      privacyPolicyLink,
      termsAndConditionsLink,
      embeddedLinkSample,
      supportingDocuments,
    } = input;

    const useCases = getUseCases(input);

    if (!useCases?.length) {
      throw new Error('You have not selected a use case');
    }

    let useCase;
    if (useCases.length > 1) {
      if (lowVolume) {
        useCase = 'LOW_VOLUME';
      } else {
        useCase = 'MIXED';
      }
    } else {
      useCase = useCases[0]?.id;
    }

    const request: TenDlcCampaign = {
      brandId: brand.id,
      description,
      useCase,
      subUseCases:
        useCases.length > 1 ? useCases.map((uc: any) => uc.id) : undefined,
      samples,
      features: {
        embeddedLink: features.embeddedLink || false,
        embeddedPhone: features.embeddedPhone || false,
        numberPool: features.numberPool || false,
        ageGated: features.ageGated || false,
        directLending: features.directLending || false,
        subscriberOptin: features.subscriberOptin || false,
        subscriberOptout: true,
        subscriberHelp: true,
        termsAndConditions: toc || false,
      },
      messageFlow,
      optin: {
        keywords: ['START'],
        message: optin?.message,
      },
      privacyPolicyLink,
      termsAndConditionsLink,
      embeddedLinkSample,
      supportingDocuments,
      numbers: numbers?.map((pn: any) => pn.number),
    };

    return registerCampaign(request).unwrap();
  };

  const handleApplication = async (values: any, form: any) => {
    if (values?.campaign?.rejected) {
      logger.info('resubmitting campaign');
      return updateAndResubmitCampaign({
        ...values.campaign,
        optin: {
          message: values.campaign.optin.message,
          keywords: ['START'],
        },
      }).unwrap();
    }

    logger.info('values', values);
    const brand = await findOrCreateBrand(values.brand);

    if (!values?.campaign?.id) {
      return createCampaign(brand, values.campaign, values.numbers);
    }

    return associateCampaign({
      campaign: values.campaign,
      numbers: values.numbers?.map((pn: any) => pn.number),
    }).unwrap();
  };

  return (
    <>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({
          handleSubmit,
          hasValidationErrors,
          hasSubmitErrors,
          submitting,
          values,
          form,
        }) => {
          // logger.info({ errors, values });
          // logger.info('form', hasValidationErrors, hasSubmitErrors, submitting);
          // logger.info('errors', errors, submitErrors);
          // logger.info('values', values);
          return (
            <form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                overflow: 'hidden',
                flex: 1,
              }}
            >
              <Stack
                sx={{
                  py: 8,
                  pl: 4,
                  pr: 2,
                  width: 224,
                  alignItems: 'center',
                }}
              >
                <WizardSteps
                  orientation="vertical"
                  steps={steps.filter((s) => s.isVisible(values))}
                  activeStep={step}
                  withFade
                />
              </Stack>
              <Stack
                sx={{
                  overflowY: 'overlay',
                  overflowX: 'hidden',
                  flex: 1,
                }}
              >
                <Stack
                  sx={{
                    paddingY: 8,
                    paddingLeft: 8,
                    paddingRight: { md: 12, xs: 6 },
                    width: 'clamp(600px, 100%, 820px)',
                    gap: 2,
                  }}
                >
                  <WizardPage currentStep={steps[step]} />
                  <Stack
                    sx={{
                      columnGap: 2,
                      height: 'fit-content',
                      flexDirection: 'row',
                      width: '400px',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="neutral"
                      onClick={() => handleBack(values, form)}
                      disabled={
                        (values?.brand?.status !== 'NEW' && step === 0) ||
                        submitting ||
                        values?.isResubmitting
                      }
                      fullWidth
                    >
                      {t('action.back')}
                    </Button>
                    <Button
                      color="primary"
                      disabled={hasValidationErrors || hasSubmitErrors}
                      loading={isRegistering || associatingCampaign}
                      fullWidth
                      type="submit"
                    >
                      {t('action.next')}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>

              <ConfirmationDialog
                open={showConfirmation}
                header="Please confirm the following"
                loading={isRegistering || associatingCampaign}
                content={
                  <FieldWrapper
                    component={Checkboxes}
                    name="confirmation"
                    items={[
                      {
                        value: 'optIn',
                        label: t(
                          'senders.register.10dlc.campaign.confirmations.optIn',
                        ),
                      },
                      {
                        value: 'optOut',
                        label: t(
                          'senders.register.10dlc.campaign.confirmations.optOut',
                        ),
                      },
                      {
                        value: 'charges',
                        label: t(
                          'senders.register.10dlc.campaign.confirmations.charges',
                        ),
                      },
                    ]}
                  />
                }
                disableConfirm={
                  values.confirmation === undefined ||
                  !(
                    Object.values(values?.confirmation)?.length === 3 &&
                    Object.values(values?.confirmation).every((v) => v)
                  )
                }
                confirmLabel={
                  campaign ? t('action.resubmit') : t('action.submit')
                }
                cancelLabel={t('action.cancel')}
                onClose={async (result) => {
                  if (result) {
                    await handleApplication(values, form)
                      .then((res) =>
                        res.success ? setShowSuccess(true) : logger.info(res),
                      )
                      .catch((err) => {
                        logger.info('err', err);
                        showError(err.data);
                      });
                    // logger.info('appResult', appResult);
                    setShowConfirmation(false);
                    navigate('/senders');
                  } else {
                    setShowConfirmation(false);
                  }
                }}
              />
            </form>
          );
        }}
      />
      <Success open={showSuccess} type="success" />
    </>
  );
}
export default TenDLCRegistration;
