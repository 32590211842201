import React from 'react';
import { Stack } from '@mui/joy';
import { AccountAttribute } from '@sakari-io/sakari-typings';
import { DatePicker, DateTimePicker } from '@sakari-io/sakari-components';
import ConditionChip from './ConditionChip';
import { TextField } from '../../../../ui';

interface ConditionValueProps {
  value: string | number;
  onChange: (val: string | number | Date) => any;
  onDelete?: (val: string | number | Date) => any;
  size?: 'sm' | 'md';
  dataType?: AccountAttribute['dataType'];
}

function ConditionValue({
  value,
  onChange,
  onDelete,
  size,
  dataType,
}: ConditionValueProps) {
  const handleChange = (
    newValue: string | Date | null,
    dataType?: AccountAttribute['dataType'],
  ) => {
    if (!newValue) return;
    switch (dataType) {
      case 'number':
        onChange(parseFloat(newValue as string));
        break;
      default:
        onChange(newValue);
        break;
    }
  };

  const renderFieldByType = (dataType?: AccountAttribute['dataType']) => {
    switch (dataType) {
      case 'date':
        return (
          <DatePicker
            value={new Date(value)}
            onChange={(val: Date) => handleChange(val, dataType)}
            size={size ?? 'md'}
          />
        );
      case 'datetime':
        return (
          <DateTimePicker
            value={new Date(value)}
            onChange={(val: Date) => handleChange(val, dataType)}
            size={size ?? 'md'}
          />
        );
      case 'number':
      case 'text':
      default:
        return (
          <TextField
            value={value}
            onChange={(val) => handleChange(val, dataType)}
            fullWidth
            size={size ?? 'md'}
            slotProps={{
              input: {
                type: dataType === 'number' ? 'number' : 'text',
              },
            }}
          />
        );
    }
  };
  return (
    <Stack direction="row" alignItems="center" gap={1} flex={1}>
      {renderFieldByType(dataType)}

      {onDelete ? (
        <ConditionChip
          variant="solid"
          color="primary"
          onClick={() => onDelete(value)}
          type="or"
          action="remove"
        />
      ) : null}
    </Stack>
  );
}

export default ConditionValue;
