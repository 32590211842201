import { Typography } from '@mui/joy';
import React from 'react';
import { StepDetail } from '../../../types/ui';

interface WizardProps {
  currentStep: StepDetail;
}

function WizardPage({ currentStep }: WizardProps) {
  return (
    <>
      {/* <Typography
              level="body-md"
              fontWeight="bold"
              sx={{
                color: 'text.secondary',
              }}
            >
              {t('step')} {stepIndex + 1}
            </Typography> */}
      <Typography level="title-lg">{currentStep?.title}</Typography>
      <Typography
        component="span"
        level="body-md"
        sx={{
          color: 'text.secondary',
        }}
      >
        {currentStep?.description}
      </Typography>
      {currentStep?.pageComponent}
    </>
  );
}
export default WizardPage;
