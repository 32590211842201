import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Account } from '@sakari-io/sakari-typings';
import React from 'react';
import DataGrid from '../../../molecules/DataGrid';
import EmptyResult from '../../../molecules/EmptyResult';
import { useGetInvoiceItemsQuery } from '../../../../api/accounts';
// TODO: Update API to return the correct data

type InvoiceItem = {
  type: string;
  description: string;
  count: number;
};

interface RecurringItemsDataGridProps {
  account: Account;
}

function RecurringItemsDataGrid({ account }: RecurringItemsDataGridProps) {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: t('settings.billing.plans.currentRecurringItems.name'),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
    {
      field: 'count',
      headerName: t('settings.billing.plans.currentRecurringItems.qty'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      valueGetter: (value) => value || 1,
    },
    // {
    //   field: 'price',
    //   headerName: t('settings.billing.plans.currentRecurringItems.price'),
    //   flex: 1,
    //   renderCell: (params) => {
    //     return `$${params.row?.price?.toFixed(2) || 0}/mo`;
    //   },
    // },
    // {
    //   field: 'total',
    //   headerName: t('settings.billing.plans.currentRecurringItems.total'),
    //   flex: 1,
    //   renderCell: (params) => {
    //     const total = params.row?.price * params.row?.qty || 0;
    //     return `$${total?.toFixed(2)}/mo`;
    //   },
    // },
  ];

  return (
    <DataGrid
      loadData={useGetInvoiceItemsQuery}
      filter={account ? { accountId: account.id } : undefined}
      columns={columns}
      renderNoData={<EmptyResult />}
      getRowId={(row: InvoiceItem) => `${row.type}-${row.description}`}
      disableRowSelectionOnClick
    />
  );
}
export default RecurringItemsDataGrid;
