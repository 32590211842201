import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../ui';
import PhoneInput from '../../../ui/atoms/inputs/PhoneInput';
import URLInput from '../../../ui/atoms/inputs/URLInput';
import AddressAutocomplete from '../../../ui/organisms/forms/AddressAutocomplete';
import FieldWrapper from '../../../utils/FieldWrapper';

function SupportInfo() {
  const { t } = useTranslation();
  return (
    <>
      <FieldWrapper
        name="supportInfo.email"
        label={t('registration.supportInfo.email.label')}
        placeholder={t('registration.supportInfo.email.placeholder')}
        component={TextField}
        type="email"
        required
        fullWidth
        autoFocus
      />
      <FieldWrapper
        name="supportInfo.phone"
        label={t('registration.supportInfo.phone.label')}
        placeholder={t('registration.supportInfo.phone.placeholder')}
        component={PhoneInput}
        required
        fullWidth
      />
      <FieldWrapper
        name="supportInfo.website"
        placeholder={t('registration.supportInfo.website.placeholder')}
        label={t('registration.supportInfo.website.label')}
        component={URLInput}
        required
        fullWidth
      />
      <FieldWrapper
        name="supportInfo.address"
        label={t('registration.supportInfo.address.label')}
        placeholder={t('registration.supportInfo.address.placeholder')}
        component={AddressAutocomplete}
        required
        fullWidth
      />

      <FieldWrapper
        component={TextField}
        fullWidth
        id="promoCode"
        name="promoCode"
        label={t('registration.supportInfo.promoCode.label')}
        placeholder={t('registration.supportInfo.promoCode.placeholder')}
      />
    </>
  );
}
export default SupportInfo;
