import React, { useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '../TextField';

interface URLInputProps extends TextFieldProps {
  hideLabel?: boolean;
}
function URLInput({ hideLabel, value, onChange, ...rest }: URLInputProps) {
  const [input, setInput] = useState('');

  useEffect(() => {
    if (value) setInput(value.toString()?.replace('https://', ''));
  }, [value]);

  const handleChange = (newVal: string) => {
    const val = newVal?.replace('https://', '') || '';
    if (onChange) {
      onChange(`https://${val}`);
    }
  };

  return (
    <TextField
      inputType="url"
      value={input}
      onChange={handleChange}
      {...rest}
    />
  );
}
export default URLInput;
