import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconButton, MenuList, MenuItem } from '@mui/joy';
import OverlayPopup, {
  OverlayPopupProps,
} from '../../../../templates/OverlayPopup';

interface ListActionMenuProps
  extends Pick<OverlayPopupProps, 'placement' | 'offset'> {
  onDelete: () => void;
  disabled?: boolean;
}

function ListActionMenu({ onDelete, disabled, ...rest }: ListActionMenuProps) {
  const [open, setOpen] = useState(false);

  const handleSelect = (action: () => void) => {
    action();
    setOpen(false);
  };

  return (
    <OverlayPopup
      open={open}
      onOpenClose={setOpen}
      renderButton={(setAnchorEl, onClick) => (
        <IconButton ref={setAnchorEl} onClick={onClick}>
          <FontAwesomeIcon icon={regular('ellipsis-vertical')} />
        </IconButton>
      )}
      renderPopup={() => (
        <MenuList variant="outlined">
          <MenuItem onClick={() => handleSelect(onDelete)} disabled={disabled}>
            Delete List
          </MenuItem>
        </MenuList>
      )}
      {...rest}
    />
  );
}

export default ListActionMenu;
