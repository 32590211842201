import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { WorkflowProperty, WorkflowVersion } from '@sakari-io/sakari-typings';
import TextField from '../../../../../ui/atoms/inputs/TextField';
import Select from '../../../../../ui/atoms/inputs/Select';
import {
  useCreateWorkflowPropertyMutation,
  useGetWorkflowPropertiesQuery,
} from '../../../../../api';
import { AccountContext } from '../../../../../contexts/account.context';
import { useAppDispatch } from '../../../../../redux';
import FieldWrapper from '../../../../../utils/FieldWrapper';

import FormDialog from '../../../../../components/molecules/FormDialog';
import { handleApiMutationResponse } from '../../../../../utils/api';

interface AddPropertyDialogProps {
  open: boolean;
  onClose: (property?: WorkflowProperty) => void;
  version: WorkflowVersion;
}

function AddPropertyDialog({ open, onClose, version }: AddPropertyDialogProps) {
  const { t } = useTranslation('workflows');
  const params = useParams();

  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);

  const [createProperty, { isLoading }] = useCreateWorkflowPropertyMutation();

  const { data } = useGetWorkflowPropertiesQuery(
    account
      ? {
          accountId: account.id,
          request: {
            workflowId: params.workflowId || '',
            versionId: version.id,
          },
        }
      : skipToken,
  );

  const dataTypeOptions = [
    {
      label: t('string'),
      value: 'string',
    },
    {
      label: t('number'),
      value: 'number',
    },
    // {
    //   label: t('boolean'),
    //   value: 'boolean',
    // },
  ];

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(t('edit.pleaseEnterPropertyName'))
      .test('unique-name', t('edit.thisPropertyNameExists'), function (value) {
        if (isLoading) {
          return true;
        }
        return !data?.data?.some((item) => item.name === value);
      }),
    dataType: Yup.string().nullable().required(t('edit.pleaseSelectDataType')),
  });

  const validate = makeValidate(schema);

  const handleSubmit = async (values: any) => {
    return handleApiMutationResponse(
      createProperty({
        accountId: account?.id || '',
        request: {
          workflowId: params?.workflowId || '',
          name: values?.name,
          dataType: values?.dataType,
          versionId: version?.id,
        },
      }).unwrap(),
      dispatch,
      {
        successMessage: t('edit.propertyCreated'),
        defaultErrorMessage: t('edit.propertyCreateError'),
        onSuccess: (val) => {
          onClose(val?.data);
        },
      },
    );
  };

  return (
    <FormDialog
      title={t('edit.createNewWorkflowProperty')}
      open={open}
      color="primary"
      primaryTitle={t('workflowProperty.saveNewProperty')}
      initialValues={{
        name: '',
        dataType: '',
      }}
      validate={validate}
      primaryAction={handleSubmit}
      cancelAction={() => onClose()}
      cancelTitle="Cancel"
    >
      <Stack spacing={2}>
        <Stack
          spacing={1}
          sx={{
            minWidth: '320px',
          }}
        >
          <FieldWrapper
            component={TextField}
            name="name"
            label={t('edit.propertyName')}
            placeholder={t('edit.propertyName')}
            fullWidth
          />
          <FieldWrapper
            component={Select}
            name="dataType"
            options={dataTypeOptions}
            label={t('edit.dataType')}
            placeholder={t('edit.dataType')}
            fullWidth
          />
        </Stack>
      </Stack>
    </FormDialog>
  );
}

export default AddPropertyDialog;
