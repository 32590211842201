import React, { useState } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import {
  Card,
  FormControl,
  FormLabel,
  Link,
  Stack,
  Autocomplete,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Country } from '@sakari-io/sakari-typings';
import TextField, { TextFieldProps } from '../../../atoms/inputs/TextField';
import CountryAutocomplete from '../CountryAutocomplete';
import StateAutocomplete, {
  StateAutocompleteProps,
} from '../StateAutocomplete';
import { AutocompleteProps } from '../../../clonedfromcomponents';

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country?: Country;
  postalCode: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getConsolidatedAddress = (value: Address) => {
  if (!value) {
    return '';
  }
  const { line1, line2, city, state, postalCode, country } = value;
  const address = [line1, line2, city, state, postalCode, country?.name]
    .filter((x) => !!x)
    .join(', ');

  return address;
};

interface AddressAutocompleteProps
  extends Omit<
    AutocompleteProps<any>,
    'value' | 'onChange' | 'options' | 'helperText'
  > {
  other: string;
  value: any;
  onChange: (value: Address) => void;
  fullWidth?: boolean;
  hideToggle?: boolean;
  isRequired?: (name: string) => boolean;
  name: string;
  helperText?: any;
}

function AddressAutocomplete({
  value,
  onChange,
  label,
  required,
  error,
  helperText,
  fullWidth,
  hideToggle,
  isRequired,
  orientation,
  placeholder,
}: AddressAutocompleteProps) {
  const {
    // ready,
    value: val,
    suggestions: { loading, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    // debounce: 300,
  });

  const { t } = useTranslation();
  const [showManualInput, setShowManualInput] = useState(false);
  const [manualInput, setManualInput] = useState<string>(
    getConsolidatedAddress(value),
  );

  const handleInput = async (newInput: string) => {
    setManualInput(newInput);
    setValue(newInput);
  };

  const handleSelect = async () => {
    clearSuggestions();
  };

  const handleOnChange = (newVal: any) => {
    if (!newVal || !newVal.place_id) {
      return;
    }
    getDetails({
      placeId: newVal.place_id,
    }).then((details) => {
      const payload = details.address_components.reduce(
        (acc: any, curr: any) => {
          const { long_name: longName, short_name: shortName, types } = curr;
          const [type] = types;
          switch (type) {
            case 'street_number':
              acc.line1 = `${longName}${acc.line1 ? ` ${acc.line1}` : ''}`;
              break;
            case 'route':
              acc.line1 = `${acc.line1 ? `${acc.line1} ` : ''}${longName}`;
              break;
            case 'premise':
              acc.line1 = longName;
              break;
            case 'subpremise':
              acc.line2 = longName;
              break;
            case 'locality':
              acc.city = longName;
              break;
            case 'postal_town':
            case 'administrative_area_level_2':
              if (!acc.city) {
                acc.city = longName;
              }
              break;
            case 'administrative_area_level_1':
              acc.state = shortName ?? longName;
              break;
            case 'postal_code':
              acc.postalCode = longName;
              break;
            case 'country':
              acc.country = { code: shortName, name: longName };
              break;
            default:
              break;
          }
          return acc;
        },
        {},
      );
      payload.description = newVal.description;
      onChange(payload);
    });
  };

  const handleManualInputChange = (field: string, newVal: any) => {
    onChange({
      ...value,
      [field]: newVal,
    });
  };

  const fieldHasError = (field: string) => {
    if (!error) return false;
    return helperText[field] && helperText[field].length > 0;
  };

  const sharedStateProps: TextFieldProps & StateAutocompleteProps = {
    name: 'state',
    value: value?.state ?? '',
    label: t('form.state.label'),
    onChange: (newVal: string) => handleManualInputChange('state', newVal),
    required: isRequired ? isRequired('state') : false,
    error: fieldHasError('state'),
    helperText: helperText?.state?.[0],
  };

  return (
    <Stack
      sx={{
        ...(fullWidth && {
          width: '100%',
        }),
        ...(orientation === 'horizontal' && {
          flexFlow: 'row nowrap',
          ...(!!label && {
            gap: 1,
          }),
        }),
      }}
    >
      <FormControl required={required} error={error}>
        {label ? (
          <FormLabel
            required
            sx={{
              alignSelf: 'flex-start',
              ...(orientation === 'horizontal' && {
                margin: '0 0.75rem 0 0',
                mt: '1rem',
              }),
            }}
          >
            {label}
          </FormLabel>
        ) : null}
      </FormControl>
      <Stack flex={1} gap={1}>
        {showManualInput ? (
          <Card
            sx={{
              width: '100%',
              gap: 1,
            }}
          >
            <CountryAutocomplete
              name="country"
              label={t('form.country.label')}
              value={
                (value?.country || { code: 'US', name: 'United States' }) as any
              }
              onChange={(val: Country | (string | Country)[] | null) =>
                handleManualInputChange('country', val)
              }
              required={isRequired ? isRequired('country') : true}
              autoFocus
              error={fieldHasError('country')}
              helperText={helperText?.country?.[0]}
            />
            <TextField
              name="line1"
              label={t('form.address.line1.label')}
              placeholder={t('form.address.line1.placeholder')}
              value={value?.line1}
              onChange={(newVal) => handleManualInputChange('line1', newVal)}
              fullWidth={fullWidth}
              required={isRequired ? isRequired('line1') : true}
              error={fieldHasError('line1')}
              helperText={helperText?.line1?.[0]}
            />
            <TextField
              name="line2"
              label={t('form.address.line2.label')}
              placeholder={t('form.address.line2.placeholder')}
              value={value?.line2}
              onChange={(newVal) => handleManualInputChange('line2', newVal)}
              fullWidth={fullWidth}
              required={isRequired ? isRequired('line2') : false}
              error={fieldHasError('line2')}
            />
            <TextField
              name="city"
              label={t('form.city.label')}
              value={value?.city}
              onChange={(newVal) => handleManualInputChange('city', newVal)}
              required={isRequired ? isRequired('city') : true}
              error={fieldHasError('city')}
              helperText={helperText?.city?.[0]}
            />
            <Stack
              sx={{
                gap: 1,
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                '> *': {
                  minWidth: 0,
                  flex: 1,
                },
              }}
            >
              {value?.country?.code &&
              ['US', 'CA'].includes(value.country.code) ? (
                <StateAutocomplete {...sharedStateProps} />
              ) : (
                <TextField {...sharedStateProps} />
              )}
              <TextField
                name="postalCode"
                label={t('form.postalCode.label')}
                value={value?.postalCode}
                onChange={(newVal: any) =>
                  handleManualInputChange('postalCode', newVal)
                }
                required={isRequired ? isRequired('postalCode') : false}
                error={fieldHasError('postalCode')}
                helperText={helperText?.postalCode?.[0]}
              />
            </Stack>
          </Card>
        ) : (
          <Autocomplete
            placeholder={placeholder}
            value={value}
            onChange={(evt, val) => handleOnChange(val)}
            options={data}
            filterOptions={(x: any) => x}
            getOptionLabel={(option: any) =>
              option?.description || option?.formatted_address || ''
            }
            inputValue={manualInput} // || manualInput?.line1 || defaultValue || ''}
            onInputChange={(e: any, newInput: any) => handleInput(newInput)}
            onSelect={() => handleSelect()}
            loading={val.length > 0 && loading}
            disableClearable={!value}
            freeSolo
            required={required}
            error={error}
          />
        )}
        {hideToggle && showManualInput ? null : (
          <Link
            component="button"
            onClick={() => setShowManualInput((prev) => !prev)}
            type="button"
          >
            {showManualInput
              ? 'Find address automatically'
              : 'Enter address manually'}
          </Link>
        )}
      </Stack>
    </Stack>
  );
}

export default AddressAutocomplete;
