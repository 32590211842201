import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Contact, Condition, List } from '@sakari-io/sakari-typings';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader, useDebouncedValue } from '@sakari-io/sakari-components';
import {
  useUpdateListMutation,
  useCreateListMutation,
  useDeleteListMutation,
  useGetListsQuery,
} from '../../api';
import Sidebar from './ListsSidebar';
import ListToolbar from '../../ui/organisms/headers/ListToolbar';
import ContactsGrid from '../../ui/organisms/datagrids/ContactsGrid';
import { useAppDispatch } from '../../redux';
import { showToast } from '../../redux/reducers/toast';
import { AccountContext } from '../../contexts/account.context';
import useLocalStorage from '../../hooks/useLocalStorage';
import EmptyResult from '../../ui/molecules/EmptyResult';
import { UserRole } from '../../constants';

const LIMIT = 20;

export enum ListMode {
  ADD = 'add',
  VIEW = 'view',
  CONTACTS = 'contacts',
}

interface ListsProps {
  accountId: string;
  onSelectedRowChange: (contact: Contact) => any;
  onUploadContacts: (list: string) => any;
  onAddContact: (list: string) => any;
}

function Lists({
  accountId,
  onSelectedRowChange,
  onUploadContacts,
  onAddContact,
}: ListsProps) {
  const { t } = useTranslation();
  const { listId } = useParams();
  const dispatch = useAppDispatch();

  const { account, userRole } = useContext(AccountContext);
  const isReadOnly = userRole === UserRole.READ_ONLY;

  const [limit, setLimit] = useState<number>(LIMIT);
  const [offset, setOffset] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);

  const [foundLists, setFoundLists] = useState<List[]>([]);

  const getQuery = (offset: number) =>
    account && offset >= 0
      ? {
          accountId: account?.id,
          request: {
            offset,
            limit,
            q: debouncedSearchTerm,
          },
        }
      : skipToken;

  const {
    data: currentData,
    isLoading,
    isFetching,
  } = useGetListsQuery(getQuery(offset));

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      setOffset(0);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (isFetching) return;

    const currentLists = currentData?.data || [];

    setFoundLists((prev) => {
      if (offset === 0) {
        return currentLists;
      }
      return _.uniqBy([...prev, ...currentLists], 'id');
    });
  }, [currentData, isFetching]);

  const [multi, setMulti] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [conditions, setConditions] = useState<Condition[]>();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const [selectedList, setSelectedList] = useState<string>();
  const [listMode, setListMode] = useState<ListMode>();
  const [pinned, setPinned] = useLocalStorage<List[]>(
    `${accountId}-pinned`,
    [],
  );

  const [createContactList, { isLoading: isCreatingContactList }] =
    useCreateListMutation();
  const [updateContactList] = useUpdateListMutation();
  const [deleteContactList, { isLoading: isDeletingList }] =
    useDeleteListMutation();

  useEffect(() => {
    if (selectedList) {
      return;
    }
    if (listId && !selectedList) {
      setSelectedList(listId);
    } else {
      const found = foundLists.find((l) => l.id === selectedList);
      if (found) {
        setSelectedList(found.id);
      } else if (pinned?.length) {
        setSelectedList(pinned[0].id);
      } else setSelectedList(foundLists[0]?.id || '');
    }
  }, [foundLists]);

  useEffect(() => {
    setListMode(selectedList === 'add' ? ListMode.ADD : ListMode.VIEW);
  }, [selectedList]);

  const handleSelection = (
    newSelectionModel: GridRowSelectionModel,
    attributes?: Condition,
  ) => {
    setSelectionModel(newSelectionModel);
    setMulti(!!newSelectionModel.length);
    if (newSelectionModel.length > 0) {
      setConditions(attributes ? [attributes] : undefined);
    } else {
      setConditions(undefined);
    }
  };

  const handleAddNewList = () => {
    createContactList({ name: `List ${new Date().toDateString()}` })
      .unwrap()
      .then((resp) => {
        if (resp.success) {
          dispatch(
            showToast({
              severity: 'info',
              message: `${resp.data.name} created`,
            }),
          );
          setFoundLists((prev) => [...prev, resp?.data]);
          setSelectedList(resp.data.id);
        }
      })

      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data?.error?.message ?? 'Error creating list',
          }),
        );
      })
      .finally(() => setListMode(ListMode.CONTACTS));
  };

  const onSaveContactList = async (name: string, selected: string) => {
    if (listMode === ListMode.ADD) {
      handleAddNewList();
    } else {
      handleUpdatePinnedList({ id: selected, name });

      await updateContactList({ id: selected, name })
        .unwrap()
        .then((resp) => {
          dispatch(
            showToast({
              severity: 'info',
              message: `${resp?.data.name} updated`,
            }),
          );
          setFoundLists((prev) =>
            prev.map((l) => (l.id === selected ? resp.data : l)),
          );
        })
        .catch((err) => {
          dispatch(
            showToast({
              severity: 'error',
              message: err.data?.error?.message ?? 'Error updating list',
            }),
          );
        });
      setListMode(ListMode.VIEW);
    }
  };

  const handleUpdatePinnedList = (list: List) => {
    if (list) {
      const newList = _.map(pinned, (item) => {
        if (item.id === list.id) {
          return { ...item, name: list.name };
        }
        return item;
      });
      setPinned(newList);
    }
  };

  const handleTogglePinnedList = (list: List) => {
    if (pinned?.find((p) => p.id === list.id)) {
      setPinned(pinned.filter((p) => p.id !== list.id));
    } else {
      setPinned([...(pinned || []), list]);
    }
  };

  const handleRemovePinnedList = (listId: string) => {
    if (listId) {
      const newList = _.filter(pinned, (item) => item.id !== listId);
      setPinned(newList);
    }
  };

  const handleDeleteList = async (list: List) => {
    if (list) {
      await deleteContactList(list?.id)
        .unwrap()
        .then((res) => {
          if (res.success) {
            dispatch(
              showToast({
                severity: 'info',
                message: `${list?.name} list has been deleted`,
              }),
            );
            handleRemovePinnedList(list?.id);
            setSearchTerm('');
            setSelectedList('');
            setFoundLists((prev) => prev.filter((l) => l.id !== list.id));
          }
        })
        .catch((err) => {
          dispatch(
            showToast({
              severity: 'error',
              message: err.data?.error?.message ?? 'Error deleting list',
            }),
          );
        });
    }
  };

  const handleAddToList = async (
    action: 'manual' | 'upload',
    list?: string,
  ) => {
    if (list) {
      if (action === 'manual') {
        await onAddContact(list);
      } else {
        await onUploadContacts(list);
      }
    }
  };

  if (isLoading) {
    return <Loader size={200} hideBackdrop />;
  }

  if (
    (!isFetching && !selectedList && foundLists?.length === 0) ||
    (foundLists?.length === 1 && isDeletingList)
  ) {
    return (
      <Stack flex={1} p={2}>
        <EmptyResult
          heading={t('firstList')}
          text={t('firstListDescription')}
          item="lists"
          buttonText="Add List"
          onButtonClicked={() => handleAddNewList()}
        />
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        flexFlow: 'row nowrap',
        height: '100%',
        gap: sidebarOpen ? 2 : 0,
      }}
    >
      <Sidebar
        width={300}
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        selected={selectedList || ''}
        setSelected={setSelectedList}
        onAdd={handleAddNewList}
        pinned={pinned}
        onTogglePinned={handleTogglePinnedList}
        lists={foundLists}
        filter={{
          offset,
          q: searchTerm,
          limit,
        }}
        onChangeFilter={(filter: {
          offset: number;
          q: string;
          limit: number;
        }) => {
          setOffset(filter.offset);
          setLimit(filter.limit);
          setSearchTerm(filter.q);
        }}
        hasMore={currentData?.data?.length === limit || isFetching}
        isReadOnly={isReadOnly || isCreatingContactList}
      />

      <Stack
        sx={{
          flex: 1,
          display: 'none',
          overflow: 'hidden',
          ...(selectedList && {
            display: 'flex',
          }),
        }}
      >
        <ListToolbar
          onSave={onSaveContactList}
          onUploadContacts={() => handleAddToList('upload', selectedList)}
          onAddContact={() => handleAddToList('manual', selectedList)}
          onDeleteList={handleDeleteList}
          filter={{
            list: selectedList,
            attributes: conditions,
          }}
          multi={multi}
          selectedContactCount={selectionModel.length}
        />

        <ContactsGrid
          accountId={accountId}
          filter={{
            list: selectedList,
            attributes: conditions,
          }}
          onFilterChange={(attrs) => setConditions(attrs)}
          onSelectedRowChange={onSelectedRowChange}
          onUploadContacts={() => handleAddToList('upload', selectedList)}
          onAddContact={() => handleAddToList('manual', selectedList)}
          onSelectionModelChange={handleSelection}
        />
      </Stack>
    </Stack>
  );
}

export default Lists;
