import React, { useState } from 'react';
import { Box, Card, Stack } from '@mui/joy';

import {
  Edge,
  Handle,
  Node,
  NodeProps,
  Position,
  useReactFlow,
} from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import {
  Mode,
  SubMode,
  actions,
  ACTION_PLACEHOLDER_NODE_CONFIG,
} from '../../../../redux/reducers/workflow';
import AddNodeButton from '../EdgeTypes/AddNodeButton';
import DeleteHoverButton from '../DeleteHoverButton';
import DeleteHoverDialog from '../DeleteHoverDialog';
import useHover from '../../../../hooks/useHover';

interface PlaceholderNodeProps extends NodeProps {
  id: string;
  data: {
    mode: Mode;
    disabled?: boolean;
  };
}

function PlaceholderNode({ id, data }: PlaceholderNodeProps) {
  const { getEdges } = useReactFlow();
  const dispatch = useDispatch();
  const { nodes, currentRFNode, mode, subMode } = useSelector(
    (state: any) => state.workflow.present,
  );

  const disabled = data?.disabled;
  const chosen = id === currentRFNode?.id;

  const hoverEditMode = {
    borderColor: 'var(--joy-palette-focusVisible)',
    cursor: 'pointer',
    boxShadow: '0 0 10px var(--joy-palette-focusVisible)',
    color: 'var(--joy-palette-focusVisible)',
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [isHovered, hoverEventHandlers, setHovered] = useHover();

  const renderDeleteHoverButton = () => {
    return isHovered && mode === Mode.EDIT && subMode !== SubMode.DRAGGING ? (
      <DeleteHoverButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...hoverEventHandlers}
        onClick={() => {
          setOpenDialog(true);
          setHovered(false);
        }}
      />
    ) : null;
  };

  // red dot
  if (data?.mode !== Mode.EDIT && data?.mode !== Mode.SELECTION) {
    return (
      <div style={{ display: 'flex', width: 350, justifyContent: 'center' }}>
        <div
          style={{
            height: '30px',
            width: '30px',
            backgroundColor: 'red',
            borderRadius: '99px',
          }}
        >
          <Handle
            type="target"
            style={{
              opacity: 0,
              width: '100%', // increase target area to make it easier to connect
              height: '100%',
            }}
            position={Position.Top}
            isConnectable={false}
            id="b"
          />
        </div>
      </div>
    );
  }

  const sourceEdge = getEdges().find(({ target }) => target === id);
  const targetEdge = getEdges().find(({ source }) => source === id);
  if (!sourceEdge) {
    return null;
  }

  if (sourceEdge && targetEdge) {
    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Stack
          alignItems="center"
          width={350}
          sx={{ padding: '0px 10px 0px' }}
          // ref={ref}
        >
          <Card
            tabIndex={0}
            {...hoverEventHandlers}
            sx={{
              cursor: 'pointer',
              opacity: disabled ? 0.5 : 1,
              pointerEvents: disabled ? 'none' : 'auto',
              padding: 0,
              borderRadius: '22px',
              gap: 2,
              width: '280px',
              height: '80px',
              '--RadioGroup-gap': 0,
              '&:hover': hoverEditMode,
              '&:focus': hoverEditMode,
              border: '2px dashed var(--joy-palette-neutral-200)',
              backgroundColor: 'var(--joy-palette-common-white)',
              ...(chosen && {
                ...hoverEditMode,
              }),
            }}
            orientation="horizontal"
          >
            <Handle
              type="source"
              style={{
                opacity: 0,
                width: '100%', // increase target area to make it easier to connect
                height: '100%',
              }}
              position={Position.Bottom}
              isConnectable={false}
              id="a"
            />
            <Handle
              type="target"
              style={{
                opacity: 0,
                width: '100%', // increase target area to make it easier to connect
                height: '100%',
              }}
              position={Position.Top}
              isConnectable={false}
              id="b"
            />
            {renderDeleteHoverButton()}
          </Card>
          {openDialog && (
            <DeleteHoverDialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              id={id}
            />
          )}
        </Stack>
      </Box>
    );
  }

  const handleEdgeClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (data?.mode !== Mode.EDIT) return;

    if (!sourceEdge.target) {
      dispatch(actions.insertPlaceholderNode(sourceEdge.data?.edge as Edge));
    } else {
      dispatch(
        actions.setCurrentRFNode(
          nodes.find(({ id }: Node) => id === sourceEdge.target),
        ),
      );
      dispatch(actions.setNodeType(ACTION_PLACEHOLDER_NODE_CONFIG));
    }
  };

  return (
    <Stack width={350} alignItems="center">
      <AddNodeButton
        edge={sourceEdge.data?.edge}
        nodeId={sourceEdge.target}
        disabled={data?.mode !== Mode.EDIT}
      />
      <div style={{ height: 50, width: 10, borderWidth: 1 }} />
      <Handle
        type="source"
        style={{
          opacity: 0,
          width: '30px',
          height: '100%',
          cursor: 'pointer',
          zIndex: 99,
          position: 'absolute',
        }}
        position={Position.Bottom}
        // isConnectable
        isConnectable={false}
        onClick={handleEdgeClick}
        id="a"
      />

      <Handle
        type="target"
        style={{
          opacity: 0,
          width: 1, // increase target area to make it easier to connect
          height: 11,
        }}
        position={Position.Top}
        isConnectable={false}
        id="b"
      />
    </Stack>
  );
}

export default PlaceholderNode;
