import { Link, Stack, SvgIcon, Typography } from '@mui/joy';
import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Account, TenDlcCampaign } from '@sakari-io/sakari-typings';
import { Loader, Tooltip } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useGet10DLCCampaignsQuery } from '../../../../../api';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import SelectableCardGroup from '../../../../../ui/molecules/groups/SelectableCardGroup';
import CreateCampaign from '../CreateCampaign';
import { useAppDispatch } from '../../../../../redux';
import { showToast } from '../../../../../redux/reducers/toast';
import StatusChip from '../../../../../ui/organisms/ChipContexts/StatusChip';

interface CampaignsProps {
  account: Account;
}

function Campaigns({ account }: CampaignsProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { values } = useFormState();
  const form = useForm();

  const {
    data: campaigns,
    isLoading,
    isError,
  } = useGet10DLCCampaignsQuery(
    values.brand?.id && account.id
      ? {
          accountId: account.id,
          request: values.brand?.id,
        }
      : skipToken,
  );

  useEffect(() => {
    if (!isLoading && campaigns?.data?.length === 0) {
      handleCreateNewCampaign();
    }
  }, [campaigns?.data, values.brand]);

  const handleCreateNewCampaign = () => {
    form.change('campaign', {
      status: 'NEW',
      features: {
        subscriberOptout: true,
        subscriberHelp: true,
      },
    });
  };

  useEffect(() => {
    if (isError) {
      dispatch(
        showToast({
          message: campaigns?.error?.description || 'Something went wrong',
          severity: 'error',
        }),
      );
    }
  }, [isError]);

  const renderOption = (option: TenDlcCampaign) => {
    return (
      <>
        {option.description && (
          <Tooltip
            title={
              <Typography level="body-sm">
                <b>Description:</b> {option.description}
              </Typography>
            }
            sx={{
              maxWidth: 320,
            }}
            variant="outlined"
            followCursor
          >
            <SvgIcon
              sx={{
                pointerEvents: 'auto',
                position: 'absolute',
                top: 10,
                right: 10,
              }}
              fontSize="sm"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </SvgIcon>
          </Tooltip>
        )}
        <Stack
          key={option.id}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 1,
            zIndex: 1000,
          }}
        >
          <Typography fontWeight="bold">
            {t(
              `senders.register.10dlc.campaign.usecases.${option.useCase}.name`,
            )}
          </Typography>
          <Typography>{option.campaignId}</Typography>

          <StatusChip status={option.status ?? ''} />
        </Stack>
      </>
    );
  };

  if (isLoading) {
    return <Loader size={200} label="Searching for existing campaigns..." />;
  }

  if (values?.campaign?.status !== 'NEW' && values.brand?.id) {
    return (
      <Stack gap={2} maxWidth="100%">
        {campaigns?.data && campaigns?.data?.length > 0 ? (
          <>
            <Typography>
              Select a use case to assign to this phone number
            </Typography>
            <Stack
              direction="row"
              gap={2}
              flexWrap="wrap"
              flex={1}
              justifyItems="flex-start"
              sx={{
                ' > *': { minHeight: '153px' },
              }}
            >
              <FieldWrapper
                component={SelectableCardGroup}
                isDisabled={(option: TenDlcCampaign) =>
                  (option.status && ['expired'].includes(option.status)) ||
                  option.expired
                }
                name="campaign"
                options={campaigns.data}
                containerSx={{
                  flexFlow: 'row wrap',
                }}
                renderOption={renderOption}
                width={150}
              />
            </Stack>
          </>
        ) : (
          <Typography>{t('10dlc.noCampaignsFound')}</Typography>
        )}

        <Link onClick={() => handleCreateNewCampaign()}>
          {t('10dlc.addANewCampaign')}
        </Link>
      </Stack>
    );
  }

  return (
    <Stack gap={2} maxWidth="100%">
      <CreateCampaign />
    </Stack>
  );
}

export default Campaigns;
