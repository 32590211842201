import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../../components/molecules/Dialog';

interface SaveFailureDialogProps {
  open: boolean;
  onClose: () => void;
}

function SaveFailureDialog({ open, onClose }: SaveFailureDialogProps) {
  const { t } = useTranslation('workflows');
  const navigate = useNavigate();

  return (
    <Dialog
      title={t('edit.unableToSave')}
      open={open}
      onClose={onClose}
      color="danger"
      primaryAction={() => navigate('/workflows')}
      primaryTitle="Discard"
      cancelTitle="Stay in workflow"
      cancelAction={() => onClose()}
    >
      <Stack spacing={2} p={1}>
        <Typography>{t('edit.weAreUnableToSaveWorkflow')}</Typography>
      </Stack>
    </Dialog>
  );
}

export default SaveFailureDialog;
