import React from 'react';
import Integration from '../IntegrationEdit';

function AirCall() {
  return (
    <Integration name="aircall">
      {/* <Setting label={t('pipedrive.companyName.label')}>
      <TextField name="companyName"/>
    </Setting>

    <Setting label={t('pipedrive.companyDomain.label')}>
      <TextField name="companyDomain"/>
    </Setting>

    <Setting
      label={t('pipedrive.timelineType.label')}
      description={t('pipedrive.timelineType.description')}
    >
      <Select
        fullWidth
        name="timelineType"
        data={timelineTypeOptions}
      />
    </Setting> */}
    </Integration>
  );
}

export default AirCall;
