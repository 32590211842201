export const ACCOUNT_TAG = 'account';
export const CAMPAIGN_TAG = 'campaign';
export const CAMPAIGN_EXECUTION_TAG = 'CampaignExecution';
export const EXECUTION_REPORT_TAG = 'ExecutionReport';
export const EXECUTION_REPORT_PREVIEW_TAG = 'ExecutionReportPreview';
export const CONTACT_TAG = 'contact';
export const CONVERSATION_TAG = 'conversation';
export const MESSAGE_TAG = 'Message';
export const GROUP_TAG = 'Group';
export const LIST_TAG = 'list';
export const PAYMENT_METHOD_TAG = 'PaymentMethod';
export const BALANCE_TAG = 'AccountBalance';
export const SENDER_TAG = 'senders';
export const PROFILE_TAG = 'Profile';
export const WEBHOOKS_TAG = 'webhook';
export const TEMPLATE_TAG = 'template';
export const AUTO_RESPONDER_TAG = 'AutoResponder';
export const OFFICE_HOURS_TAG = 'OfficeHours';
export const ATTRIBUTES_TAG = 'CustomAttributes';
export const PHONE_NUMBER_TAG = 'PhoneNumber';
export const LINK_TAG = 'Link';
export const LEAD_TAG = 'Lead';
export const PARTNER_TAG = 'Partner';
