export interface IntegrationProperty {
  name: string;
  label?: string;
  value?: number;
  visible: boolean;
  position?: number;
}

export interface AccountIntegrationProperty {
  accountId: string;
  properties: IntegrationProperty[];
}

export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] => {
  const result = Array.from(list);
  const x = list[startIndex];
  result.splice(startIndex, 1);
  result.splice(endIndex, 0, x);
  return result;
};
