import React, { useMemo } from 'react';
import './index.scss';
import _ from 'lodash';
import { Box, Stack, IconButton, Card } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AccountAttribute, Condition } from '@sakari-io/sakari-typings';
import ConditionValue from '../ConditionValue';
import ConditionChip from '../ConditionChip';
import Helper from '../../../../../utils/helper';
import AttributeSelect from '../../../../../ui/organisms/forms/AttributeSelect/index';
import ComparatorPicker from '../../../../../ui/organisms/forms/ComparatorPicker';
import { COMPARATORS } from '../../../../../constants';

interface ConditionCardProps {
  attributes: AccountAttribute[];
  onChange: (value: Condition) => any;
  value: Condition;
  onDelete: (value: Condition) => any;
  disabled?: boolean;
}

function ConditionCard({
  attributes,
  onChange,
  value,
  onDelete,
  disabled,
}: ConditionCardProps) {
  const { t } = useTranslation();

  const ATTRIBUTES: AccountAttribute[] = [
    {
      id: 'firstName',
      name: 'firstName',
      label: t('form.name.first'),
      dataType: 'text',
      type: 'contacts',
    },
    {
      id: 'lastName',
      name: 'lastName',
      label: t('form.name.last'),
      dataType: 'text',
      type: 'contacts',
    },
    {
      id: 'mobile',
      name: 'mobile',
      label: t('form.phonenumber.mobile'),
      dataType: 'text',
      type: 'contacts',
    },
    {
      id: 'email',
      name: 'email',
      label: t('form.email.label'),
      dataType: 'text',
      type: 'contacts',
    },
  ];

  const attributeOptions: AccountAttribute[] = useMemo(() => {
    return _.uniqBy(
      [
        ...ATTRIBUTES,
        ...attributes.map((attr) => ({
          ...attr,
          name: `attributes.${attr.name}`,
        })),
      ],
      'id',
    );
  }, [attributes]);

  const attributeObj = useMemo(
    () => attributeOptions.find((option) => option.name === value.attribute),
    [attributeOptions, value.attribute],
  );

  const isMultipleValuesSupported = (comparator: string) => {
    if (!comparator) {
      return false;
    }

    const comparatorDetails = COMPARATORS.find(
      ({ value }) => value === comparator,
    );
    return comparatorDetails?.multipleValues;
  };

  const handleAttributeChange = (newVal: AccountAttribute) => {
    if (newVal) {
      onChange(
        _.assign({}, value, {
          attribute: newVal.name,
          comparator: '=',
        }),
      );
    }
  };

  const handleComparatorChange = (comparator: any) => {
    onChange(_.assign({}, value, { comparator }));
  };

  const handleValueChange = (index: number, newValue: string) => {
    onChange(
      _.assign({}, value, {
        value: Helper.asArray(value.value).map((value: string, i: number) =>
          i === index ? newValue : value,
        ),
      }),
    );
  };

  const handleAddValue = () => {
    onChange(
      _.assign({}, value, {
        value: _.isArray(value.value) ? [...value.value, ''] : value.value,
      }),
    );
  };

  const handleDeleteValue = (index: number) => {
    if ((value?.value as (string | number)[])?.length === 1) {
      return;
    }

    onChange(
      _.assign({}, value, {
        value: (value.value as (string | number)[]).filter(
          (value: string | number, i: number) => {
            return i !== index;
          },
        ),
      }),
    );
  };

  return (
    <Card
      variant="soft"
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      <AttributeSelect
        value={attributeObj}
        onChange={(v: any) => handleAttributeChange(v as AccountAttribute)}
        overrideAttributes={attributeOptions}
        disabled={disabled}
        type="contacts"
        size="sm"
      />

      <Stack
        sx={{
          flexFlow: 'row nowrap',
          flex: 1,
          gap: 1,
        }}
      >
        {attributeObj ? (
          <Box width={150}>
            <ComparatorPicker
              attribute={attributeObj}
              onChange={handleComparatorChange}
              value={value.comparator}
            />
          </Box>
        ) : null}

        {value.attribute ? (
          <Stack flex={1} gap={1}>
            {Helper.asArray(value.value)?.map((val: any, index: number) => (
              <ConditionValue
                key={index}
                value={val}
                onChange={(v: any) => handleValueChange(index, v)}
                onDelete={
                  (value.value as (string | number)[]).length > 1
                    ? () => handleDeleteValue(index)
                    : undefined
                }
                size="sm"
                dataType={
                  attributeOptions.find((attr) => attr.name === value.attribute)
                    ?.dataType
                }
              />
            ))}
          </Stack>
        ) : null}
      </Stack>

      <Stack direction="row" alignItems="center">
        {isMultipleValuesSupported(value.comparator) ? (
          <ConditionChip
            color="primary"
            variant="plain"
            onClick={() => handleAddValue()}
            type="or"
            action="add"
          />
        ) : null}

        <Box sx={{ flex: 1 }} />

        <IconButton
          color="neutral"
          sx={{ borderRadius: '50%', backgroundColor: 'transparent' }}
          onClick={() => onDelete(value)}
          disabled={disabled}
        >
          <FontAwesomeIcon fontSize={16} icon={faTrash} />
        </IconButton>
      </Stack>
    </Card>
  );
}

export default ConditionCard;
