import React from 'react';
import { Chip } from '@mui/joy';
import { faSitemap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface WorkflowChipProps {
  name: string;
  onClick: () => void;
}

function WorkflowChip({ name, onClick }: WorkflowChipProps) {
  return (
    <Chip
      color="primary"
      startDecorator={<FontAwesomeIcon icon={faSitemap} />}
      onClick={onClick}
    >
      {name}
    </Chip>
  );
}

export default WorkflowChip;
