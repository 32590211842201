import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux';
import { useDeletePhoneNumberMutation } from '../../../../../api/phonenumbers';
import { showToast } from '../../../../../redux/reducers/toast';
import AppDialog from '../../../../molecules/AppDialog';

interface DeleteWebhookDialogProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

function DeletePhoneNumberDialog({
  open,
  onClose,
  id,
}: DeleteWebhookDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deletePhoneNumber] = useDeletePhoneNumberMutation();
  const [isOpen, setIsOpen] = React.useState(open);

  const handleDelete = async () => {
    await deletePhoneNumber(id)
      .unwrap()
      .then(() => {
        dispatch(
          showToast({
            severity: 'info',
            message: t('senders.phoneNumberDeletedMessage'),
          }),
        );
        onClose();
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data.error.message,
          }),
        );
        onClose();
      });
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <AppDialog
      open={isOpen}
      setOpen={setIsOpen}
      onClose={() => onClose()}
      content={t('senders.senderDeletingConfirmationMessage')}
      confirmButtonProps={{
        children: t('yesDelete'),
        color: 'danger',
        onClick: handleDelete,
      }}
      cancelButtonProps={{
        children: t('cancel'),
        onClick: onClose,
      }}
      header={t('senders.senderDeletingHeading')}
      color="danger"
    />
  );
}

export default DeletePhoneNumberDialog;
