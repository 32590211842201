import { Modal, ModalDialog } from '@mui/joy';
import React, { useEffect, useState } from 'react';

const styles = {
  image: {
    maxWidth: '100%',
    maxHeight: '80vh',
  },
};
interface MediaPreviewProps {
  open: boolean;
  imageString: string;
  onClose: () => void;
}

function MediaPreview({
  open = false,
  imageString = '',
  onClose,
}: MediaPreviewProps) {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  return (
    <Modal
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
        onClose();
      }}
    >
      <ModalDialog sx={{ textAlign: 'center', overflow: 'hidden' }}>
        <img style={styles.image} src={imageString} alt="" />
      </ModalDialog>
    </Modal>
  );
}

export default MediaPreview;
