import { BrowserRouter as Router } from 'react-router-dom';
import { SakariCSSVarProvider, logger } from '@sakari-io/sakari-components';
import { useAuth0 } from '@auth0/auth0-react';
import LogRocket from 'logrocket';
import React, { useContext, useEffect } from 'react';
import sec from './security';
import ToastSnackbar from './ui/organisms/alerts/GlobalToastSnackbar';
import Routing from './Routing';
import { AccountContext } from './contexts/account.context';

function Main() {
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useContext(AccountContext);

  sec.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (
      profile &&
      !profile.email.includes('@sakari.io') &&
      window.location.hostname === 'hub.sakari.io'
    ) {
      logger.info('initializing logrocket', profile);
      LogRocket.init(`xg8gjb/sakari-hub-prod`);

      LogRocket.identify(profile.id, {
        name: `${profile.firstName} ${profile.lastName}`,
        email: profile.email || '',

        // Add your own custom user variables here, ie:
        // subscriptionType: 'pro'
      });
    }
  }, [profile]);

  return (
    <SakariCSSVarProvider>
      <ToastSnackbar />
      <Router>
        <Routing />
      </Router>
    </SakariCSSVarProvider>
  );
}

export default Main;
