import { Box, Checkbox, IconButton, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import { ConversationFilter } from '../../../../types';
import SearchBar from '../../../molecules/inputs/SearchBar';
import OverlayPopup from '../../../templates/OverlayPopup';
import AppMenu from '../../../molecules/Menu';

interface ConversationsHeaderProps {
  filter: ConversationFilter;
  onFilterChange: (filter: ConversationFilter) => any;
}

function ConversationsHeader({
  filter,
  onFilterChange,
}: ConversationsHeaderProps) {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const debouncedQuery = useDebouncedValue(query, 300);

  const handleFilterChange = (key: string, value: any) => {
    onFilterChange({ ...filter, [key]: value });
  };

  useEffect(() => {
    if (filter.q !== debouncedQuery) {
      handleFilterChange('q', debouncedQuery);
    }
  }, [debouncedQuery]);

  return (
    <Stack
      sx={{
        borderBottom: `1px solid`,
        borderColor: 'divider',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& .MuiFormControl-root': {
          flex: 1,
          minWidth: 0,
        },
      }}
    >
      <SearchBar
        variant="plain"
        value={query}
        onChange={(newVal) => setQuery(newVal)}
        fullWidth
        sx={{
          '--joy-focus-thickness': 0,
        }}
      />
      <OverlayPopup
        open={openMenu}
        onOpenClose={setOpenMenu}
        placement="bottom-end"
        renderButton={(setAnchorEl, onClick) => (
          <Box pr={1}>
            <IconButton
              variant="outlined"
              onClick={onClick}
              ref={setAnchorEl}
              size="xsRound"
            >
              <FontAwesomeIcon icon={regular('bars-filter')} />
            </IconButton>
          </Box>
        )}
        renderPopup={() => (
          <AppMenu
            size="sm"
            onSelect={(e, item) => {
              if (!item.id) return;
              handleFilterChange(
                item.id,
                !filter[item.id as keyof ConversationFilter],
              );
              setOpenMenu(false);
            }}
            items={[
              // {
              //   id: 'blocked',
              //   label: 'Blocked',
              //   startIcon: <Checkbox checked={filter.blocked} />,
              // },
              {
                id: 'closed',
                label: 'Archived',
                startIcon: <Checkbox checked={filter.closed || false} />,
              },
              {
                id: 'unread',
                label: 'Unread',
                startIcon: <Checkbox checked={filter.unread || false} />,
              },
            ]}
          />
        )}
      />
    </Stack>
  );
}

export default ConversationsHeader;
