import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { useParams, useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Loader } from '@sakari-io/sakari-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetWorkflowQuery,
  useGetWorkflowNodeTypesQuery,
  useGetWorkflowVersionQuery,
  useGetWorkflowVersionMetricsQuery,
} from '../../../api';
import WorkflowEdit from '../Edit';
import { AccountContext } from '../../../contexts/account.context';
import WorkflowHeader from '../Edit/WorkflowHeader';
import { actions } from '../../../redux/reducers/workflow';
import EmptyResult from '../../../ui/molecules/EmptyResult';

function WorkflowCanvas() {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation('workflows');
  const params = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const nodeId = searchParams.get('nid');
  const edit = searchParams.get('edit');

  const [versionId, setVersionId] = useState<string>();

  const { data: nodeTypesData, isFetching: isFetchingTypes } =
    useGetWorkflowNodeTypesQuery({ limit: 100 });

  const { data, isFetching, isSuccess, isError } = useGetWorkflowQuery(
    account && params.workflowId
      ? { accountId: account.id, request: params.workflowId }
      : skipToken,
  );

  const { metricDateRange } = useSelector(
    (state: any) => state.workflow.present,
  );

  const {
    data: versionData,
    isFetching: isFetchingVersion,
    isSuccess: isSuccessVersion,
  } = useGetWorkflowVersionQuery(
    account && params.workflowId && isSuccess && versionId
      ? {
          accountId: account.id,
          request: {
            workflowId: params.workflowId,
            versionId,
          },
        }
      : skipToken,
  );

  const { data: metricsData } = useGetWorkflowVersionMetricsQuery(
    account &&
      params.workflowId &&
      isSuccess &&
      isSuccessVersion &&
      versionId &&
      versionData?.data.readonly
      ? {
          accountId: account.id,
          request: {
            workflowId: params.workflowId,
            versionId,
            q: metricDateRange,
          },
        }
      : skipToken,
  );

  const [initialFetch, setInitialFetch] = useState<boolean>(true);

  useEffect(() => {
    if (data && initialFetch) {
      setInitialFetch(false);
      if (data.data?.versions?.length === 1) {
        setVersionId(data.data.versions[0].id);
      } else {
        const active = findActiveVersion(data.data.versions);
        if (active) {
          setVersionId(active.id);
        } else {
          const lastActive = _.maxBy(data.data.versions, 'lastActive');
          setVersionId(lastActive?.id);
        }
      }
    }
  }, [data, initialFetch]);

  const findActiveVersion = (versions: any) => {
    return _.find(versions, (item) => item.active);
  };

  useEffect(() => {
    if (versionData?.data) {
      dispatch(
        actions.setVersion({
          version: versionData.data,
          nodeId: nodeId ?? undefined,
          edit: !!edit,
        }),
      );
    }
  }, [versionData, nodeId, edit, dispatch]);

  useEffect(() => {
    if (metricsData?.data && !!versionData?.data?.readonly) {
      dispatch(actions.addMetrics(metricsData?.data));
    }
  }, [metricsData]);

  useEffect(() => {
    if (nodeTypesData?.data?.length) {
      const nodeTypesById = _.keyBy(nodeTypesData?.data, (nt) => nt.id);
      dispatch(actions.setNodeTypes(nodeTypesById));
    }
  }, [nodeTypesData]);

  if (isFetching || isFetchingVersion || isFetchingTypes) {
    return (
      <Loader
        size={150}
        label={`${t('loadingWorkflow')}...`}
        altImage="https://assets.sakari.io/images/fun-spinner.gif"
      />
    );
  }

  if (isError || !data?.data || !versionData?.data) {
    return (
      <EmptyResult
        item="campaigns"
        heading={t('workflow.emptyState.noWorkflow')}
      />
    );
  }

  return (
    <ReactFlowProvider>
      <WorkflowHeader
        divider
        workflow={data?.data!}
        version={versionData?.data}
        onVersionChange={setVersionId}
      />
      {data?.data && versionData?.data && (
        <WorkflowEdit
          workflow={data?.data}
          version={versionData?.data}
          onVersionChange={setVersionId}
        />
      )}
    </ReactFlowProvider>
  );
}

export default WorkflowCanvas;
