import React, { useContext } from 'react';
import { Stack } from '@mui/joy';
import { Form } from 'react-final-form';
import {
  WorkflowNodeType,
  WorkflowNodeConfig,
  WorkflowNodeProperty,
  WorkflowVersion,
  Workflow,
} from '@sakari-io/sakari-typings';

import { useDispatch } from 'react-redux';
import { logger } from '@sakari-io/sakari-components';
import { AccountContext } from '../../../../contexts/account.context';
import { Drawer } from '../../../../ui';
import NodeConfigHeader from './NodeConfigHeader';
import PropertyField from './NodeProperties/PropertyField';
import FieldWrapper from '../../../../utils/FieldWrapper';
import ReEnrollTriggerOption from './NodeProperties/ReEnrollTriggerOption';

import { actions, Mode } from '../../../../redux/reducers/workflow';
import AutoSave from './AutoSave';
import { isNodeComplete } from '../../../../utils/workflows/nodevalidation';
import { WorkflowEdgeProps } from '../../Canvas/EdgeTypes/WorkflowEdge/index.js';

interface ConfigDrawerProps {
  workflow: Workflow;
  version: WorkflowVersion;
  nodeType: WorkflowNodeType;
  nodeId: string;
  config: WorkflowNodeConfig;
  onClose: (config?: WorkflowNodeConfig) => any;
  edges?: WorkflowEdgeProps[];
  mode: Mode;
  hidden?: boolean;
}

function ConfigDrawer({
  workflow,
  version,
  nodeType,
  nodeId,
  config,
  onClose,
  edges,
  mode,
  hidden,
}: ConfigDrawerProps) {
  const { account } = useContext(AccountContext);
  const dispatch = useDispatch();

  if (!nodeType) return null;

  const formSave = (values: any) => {
    if (!account) {
      return;
    }

    onClose(values);
  };

  const handleHighlight = (nId: string) => {
    logger.info('handleHighlight', nId);
    dispatch(actions.highlightNode(nId));
  };

  const renderField = (property: WorkflowNodeProperty) => {
    return (
      <Stack spacing={2} key={property.name}>
        <PropertyField
          account={account!}
          workflow={workflow}
          version={version}
          property={property}
          key={property.name}
          mode={mode}
          onHighlight={handleHighlight}
        />
      </Stack>
    );
  };

  const checkIncomplete = (values: any) => {
    const incomplete = !isNodeComplete(nodeType, values, edges).valid;
    return incomplete;
  };

  if (!account) {
    return null;
  }

  return (
    <Form
      key={nodeId}
      onSubmit={formSave}
      initialValues={config}
      render={({ handleSubmit, values }) => {
        return (
          <Drawer
            persistent
            variant="outlined"
            position="right"
            isOpen={!!nodeType}
            sx={{
              width: 'clamp(350px, 100%, 400px) !important',
              backgroundColor: 'var(--joy-palette-background-level1)',
              position: 'absolute',
              right: 0,
              zIndex: 99,
              height: '100%',
              visibility: hidden ? 'hidden' : 'visible',
            }}
          >
            <AutoSave values={values} />
            <form
              onSubmit={handleSubmit}
              style={{
                width: '100%',
              }}
            >
              <NodeConfigHeader
                onClick={onClose}
                divider
                incomplete={checkIncomplete(values)}
                type={nodeType}
                hideClose={mode === Mode.SELECTION}
              />

              <Stack
                spacing={2}
                p={2}
                sx={{
                  overflow: 'scroll',
                  width: '100%',
                  height: 'calc(100% - 100px)',
                  position: 'absolute',
                }}
              >
                {(nodeType.properties || []).map((property) => {
                  return renderField(property);
                })}

                <Stack
                  pr={1.5}
                  sx={{
                    position: 'fixed',
                    bottom: '20px',
                  }}
                >
                  {nodeType?.supportsReenrollment && (
                    <FieldWrapper
                      component={ReEnrollTriggerOption}
                      name="reenroll"
                    />
                  )}
                </Stack>
              </Stack>
            </form>
          </Drawer>
        );
      }}
    />
  );
}

export default ConfigDrawer;
