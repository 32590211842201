import React, { useState } from 'react';
import { Button, Link, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import TextField from '../../../atoms/inputs/TextField';
import InputControl from '../../../molecules/InputControl';

const extractSheetId = (input: string) => {
  if (input.includes('/')) {
    const parts = input.split('/');
    const index = parts.indexOf('d');
    if (index > -1 && index + 1 < parts.length) {
      return parts[index + 1];
    }
    return null;
  }
  return input;
};

interface SheetsConnectProps {
  onConnect: (sheetId: string) => void;
  disabled?: boolean;
}

function SheetConnect({ onConnect, disabled }: SheetsConnectProps) {
  const { t } = useTranslation('workflows');

  const [sheetUrl, setSheetUrl] = useState('');

  return (
    <>
      <Stack spacing={1}>
        <InputControl label="Sheet">
          <TextField
            fullWidth
            value={sheetUrl}
            onChange={(v) => setSheetUrl(v)}
            placeholder={t('edit.inputGoogleSheetsUrl')}
            disabled={disabled}
          />
        </InputControl>
        <Link
          level="body-sm"
          underline="none"
          sx={{
            ':hover': {
              opacity: 0.8,
            },
          }}
          onClick={() =>
            window.open('https://sheets.google.com', '', 'noopener')
          }
        >
          {t('edit.goToGoogleSheets')}
        </Link>
      </Stack>

      <Button
        fullWidth
        disabled={disabled || !sheetUrl}
        onClick={() => {
          const sheetId = extractSheetId(sheetUrl);
          if (sheetId) {
            onConnect(sheetId);
          }
        }}
      >
        {t('edit.connectSheet')}
      </Button>
    </>
  );
}

export default SheetConnect;
