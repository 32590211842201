import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/joy';
import { useCookies } from 'react-cookie';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Logo, Loader, logger } from '@sakari-io/sakari-components';
import { useCreateAccountMutation } from '../../api';
import SupportInfo from './SupportInfo';
import { useAppDispatch } from '../../redux';
import { AccountContext } from '../../contexts/account.context';
import BusinessInfo from './BusinessInfo';
import Auth0Template from '../../ui/templates/Auth0Template';
import LogoutLink from '../../ui/organisms/ButtonContexts/LogoutLink';
import useSessionStorage from '../../hooks/useSessionStorage';
import { handleApiMutationResponse } from '../../utils/api';

function AccountRegistration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { changeAccount, profile, isFetchingProfile } =
    useContext(AccountContext);

  const [createAccount, { isLoading: isCreatingAccount, isSuccess }] =
    useCreateAccountMutation();

  const [cookies] = useCookies(['referrer']);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showOnboarding, setShowOnboarding] =
    useSessionStorage<boolean>('showOnboarding');

  useEffect(() => {
    if (!isFetchingProfile && !(profile as any)?.tacAccepted) {
      navigate('/createprofile');
    }
  }, [isFetchingProfile, profile]);

  const formSave = async (values: any) => {
    const { name, questionnaire, country } = values.businessInfo;
    const { ...support } = values.supportInfo;
    const { promoCode } = values;

    const payload: any = {
      name,
      defaults: {
        country,
      },
      support: {
        ...support,
        phone: support.phone.number,
      },
      questionnaire,
      promoCode,
    };

    if (cookies.referrer) {
      payload.referrer = cookies.referrer;
    }

    await handleApiMutationResponse(createAccount(payload).unwrap(), dispatch, {
      onSuccess: (result) => {
        if (result.data?.id) {
          logger.info('result', result);
          changeAccount(result.data?.id);
          setShowOnboarding(true);
          navigate('/');

          if ((window as any).dataLayer && profile) {
            (window as any).dataLayer.push({
              event: 'newAccount',
              email: profile.email,
              account_id: result.data.id,
            });
          }
        } else {
          logger.error('No account id in response');
        }
      },
      defaultErrorMessage: t('businessInfo.error'),
    });
  };

  // steps
  const steps = [
    {
      id: 'businessInfo',
      label: t('businessInfo.companyInfo'),
      component: <BusinessInfo />,
      description: '',
    },
    {
      id: 'supportInfo',
      label: t('registration.supportInfo.title'),
      component: <SupportInfo />,
      description: t('registration.supportInfo.description'),
    },
  ];

  const [step, setStep] = useState(steps[0]);

  const schema = Yup.object().shape({
    businessInfo: Yup.object().when([], {
      is: () => step.id === 'businessInfo',
      then: Yup.object().shape({
        name: Yup.string().required(t('validation.required')),
        country: Yup.object()
          .shape({
            code: Yup.string().required(
              t('validation.pleaseSelect', {
                field: t('form.country.label'),
              }),
            ),
          })
          .nullable(),
        questionnaire: Yup.object().shape({
          industry: Yup.string().required(t('validation.required')).nullable(),
          monthlyUsage: Yup.string()
            .required(t('businessInfo.enterUsage'))
            .nullable(),
          integrations: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string(),
              }),
            )
            .nullable(),
        }),
      }),
    }),
    supportInfo: Yup.object().when([], {
      is: () => step.id === 'supportInfo',
      then: Yup.object().shape({
        email: Yup.string()
          .email(
            t('validation.isInvalid', {
              field: t('form.email.label'),
            }),
          )
          .required(t('validation.required')),
        phone: Yup.object().shape({
          number: Yup.string().required(t('validation.required')),
          country: Yup.string(),
        }),
        website: Yup.string().required(t('validation.required')),
        address: Yup.object()
          .shape({
            line1: Yup.string().required(t('validation.required')),
            line2: Yup.string(),
            city: Yup.string().required(t('validation.required')),
            state: Yup.string().required(t('validation.required')),
            country: Yup.object()
              .shape({
                code: Yup.string().required(
                  t('validation.pleaseSelect', {
                    field: t('form.country.label'),
                  }),
                ),
              })
              .nullable(),
            postalCode: Yup.string(),
          })
          .nullable(),
      }),
    }),
    promoCode: Yup.string(),
  });

  const validate = makeValidate(schema);

  const handleOnSubmit = (values: any) => {
    switch (step.id) {
      case 'businessInfo':
        setStep(steps[1]);
        break;
      case 'supportInfo':
        formSave(values);
        break;
      default:
        break;
    }
  };

  if (isSuccess) {
    return <Loader size={200} label={t('businessInfo.creatingAccount')} />;
  }

  return (
    <Auth0Template>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <Logo size={50} />
        <Typography level="h3" sx={{ textAlign: 'center' }}>
          {step.label}
        </Typography>

        <Typography level="body-md" sx={{ textAlign: 'center' }}>
          {step.description}
        </Typography>
      </Stack>
      <Form
        onSubmit={handleOnSubmit}
        validate={validate}
        initialValues={{}}
        render={({ handleSubmit, pristine, hasValidationErrors }) => {
          return (
            <>
              <Stack
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                sx={{
                  borderRadius: '6px',
                  backgroundColor: 'white',
                  gap: 2,
                }}
              >
                {step.component}
              </Stack>
              <Stack
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Button
                  fullWidth
                  onClick={handleSubmit}
                  disabled={pristine || hasValidationErrors}
                  loading={isCreatingAccount}
                >
                  {t('action.continue')}
                </Button>
                <LogoutLink />
              </Stack>
            </>
          );
        }}
      />
    </Auth0Template>
  );
}
export default AccountRegistration;
