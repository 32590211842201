import React from 'react';
import _ from 'lodash';
import Box from '@mui/joy/Box';
import MessageSkeleton from '../MessageSkeleton';

function MessagesSkeleton() {
  return (
    <Box
      style={{
        overflow: 'hidden',
        flex: 1,
      }}
    >
      {_.range(0, 3).map((i) => (
        <MessageSkeleton key={i} outgoing={i % 2 === 0} />
      ))}
    </Box>
  );
}

export default MessagesSkeleton;
