import React from 'react';
import {
  Account,
  Contact,
  InstalledIntegration,
} from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import AppList, { AppListProps } from '../../../molecules/AppList';
import Integration from '../../cards/IntegrationCard';

interface IntegrationsListProps
  extends Omit<AppListProps<InstalledIntegration>, 'items'> {
  account: Account;
  integrations: InstalledIntegration[];
  contact: Contact;
}

function IntegrationsList({
  account,
  integrations,
  contact,
  ...rest
}: IntegrationsListProps) {
  const { t } = useTranslation();
  const activeIntegrations = (integrations || []).filter(
    (integration) =>
      (contact as any)[integration.sourceId]?.id ||
      (contact as any)[integration.sourceId]?.vid,
  );

  return (
    <AppList
      sx={{ border: 0 }}
      items={activeIntegrations}
      noItemsText={t('noneFound', { items: t('integration') })}
      renderItem={(integration) => {
        return (
          <Integration
            key={integration.sourceId}
            account={account}
            contact={contact}
            integration={integration}
          />
        );
      }}
      {...rest}
    />
  );
}
export default IntegrationsList;
