import { useTranslation } from 'react-i18next';
import React from 'react';
import { Role } from '@sakari-io/sakari-typings';
import Select, { StyledSelectProps } from '../../../atoms/inputs/Select';
import { Option as OptionType } from '../../../../types/ui';

interface RoleSelectProps
  extends Omit<
    StyledSelectProps<OptionType>,
    'value' | 'onChange' | 'options'
  > {
  value: string;
  onChange: (role: Role) => any;
  label?: string;
  fullwidth?: boolean;
  error?: boolean;
  helperText?: string;
  orientation?: 'horizontal' | 'vertical';
}

function RoleSelect({
  label,
  fullwidth,
  error,
  helperText,
  orientation,
  size,
  color,
  ...args
}: RoleSelectProps) {
  const { t } = useTranslation();
  const ControlProps = {
    label,
    fullwidth,
    error,
    helperText,
    orientation,
    size,
    color,
  };

  return (
    <Select
      sx={{ width: fullwidth ? '100%' : 'auto' }}
      options={[
        { value: Role.Administrator, label: t('roles.administrator') },
        { value: Role.CampaignManager, label: t('roles.campaignmanager') },
        { value: Role.Sender, label: t('roles.sender') },
        { value: Role.ReadOnly, label: t('roles.readonly') },
      ]}
      {...ControlProps}
      {...args}
    />
  );
}

export default RoleSelect;
