import React, { useState } from 'react';
import { Stack } from '@mui/joy';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '../../../../../ui/atoms/inputs/TextField';
import MetricsTab, { Metrics } from '../../../Edit/ElementCard/MetricsTab';
import {
  Mode,
  MetricType,
  actions,
} from '../../../../../redux/reducers/workflow';

interface BranchLabelProps {
  value: string;
  onChange: (val: string) => any;
  editable?: boolean;
  metrics?: Metrics;
}

function BranchLabel({ value, onChange, editable, metrics }: BranchLabelProps) {
  const [focused, setFocused] = useState(false);

  const dispatch = useDispatch();

  const { mode, metricType } = useSelector(
    (state: any) => state.workflow.present,
  );

  return !focused ? (
    <Stack alignItems="center">
      <Stack
        sx={{
          cursor: 'pointer',
          zIndex: 99,
          color:
            value === '⚠️'
              ? 'white'
              : '1px solid var(--joy-palette-neutral-600)',
          border:
            value === '⚠️'
              ? 'var(--joy-palette-danger-500)'
              : '1px solid var(--joy-palette-neutral-300)',
          borderRadius: '50px',
          background:
            value === '⚠️'
              ? 'var(--joy-palette-danger-500)'
              : 'var(--Base-White, #FFF)',
          minWidth: '80px',
          minHeight: '30px',
          padding: '5px 14px',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow:
            '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        }}
        onClick={() => {
          if (!editable) return;
          setFocused((prev) => !prev);
        }}
      >
        {value}
      </Stack>
      {metrics && (mode === Mode.VIEW || mode === Mode.VERSIONS) && (
        <MetricsTab
          metrics={metrics}
          hideIcon
          percentage={metricType === MetricType.PERCENTAGE}
        />
      )}
    </Stack>
  ) : (
    <TextField
      autoFocus
      value={value}
      onBlur={() => setFocused(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') setFocused(false);
      }}
      onChange={(v) => {
        onChange(v);
        dispatch(actions.checkForChanges());
      }}
    />
  );
}

export default BranchLabel;
