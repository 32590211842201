import { faInfoCircle, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography, Link, Tooltip } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface FeatureListItemProps {
  feature: string;
  tooltip?: string | React.ReactNode;
}

function FeatureListItem({ feature, tooltip }: FeatureListItemProps) {
  return (
    <>
      <Typography
        level="body-sm"
        textColor="primary.700"
        startDecorator={<FontAwesomeIcon icon={faCheck} />}
        endDecorator={
          tooltip && (
            <Tooltip variant="plain" title={tooltip} placement="top" arrow>
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{
                  color: 'var(--joy-palette-primary-300)',
                  marginLeft: 2,
                }}
              />
            </Tooltip>
          )
        }
      >
        {feature}
      </Typography>
    </>
  );
}

function FeaturesList() {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" gap={1}>
      <Typography textColor="primary.700" fontWeight={600} mb={1}>
        All plans come with
      </Typography>
      <FeatureListItem
        feature={t(`settings.billing.plans.features.segmentsCharged`)}
        tooltip={
          <>
            {t('settings.billing.plans.features.sentReceivedCharge')}{' '}
            <Link
              href="https://support.sakari.io/hc/en-us/articles/360039248071"
              target="_blank"
            >
              {t('settings.billing.plans.features.whatIsASegment')}
            </Link>
          </>
        }
      />
      <FeatureListItem
        feature={t(`settings.billing.plans.features.freeNumberInfo`)}
        tooltip={t(`settings.billing.plans.features.additionalNumbers`)}
      />
      <FeatureListItem
        feature={t(`settings.billing.plans.features.rolloverCredits`)}
        tooltip={t(`settings.billing.plans.features.unusedCredits`)}
      />
      <FeatureListItem
        feature={t(`settings.billing.plans.features.unlimitedUsersContacts`)}
        // tooltip="Sent and received segments charged"
      />
    </Stack>
  );
}
export default FeaturesList;
