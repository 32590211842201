import React from 'react';
import {
  ListItemButton,
  ListItem,
  IconButton,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { List } from '@sakari-io/sakari-typings';
import { Tooltip, IntegrationIcon } from '@sakari-io/sakari-components';
import config from '../../../../../config';

interface ListItemProps {
  list: List;
  onPinned: (list: List) => any;
  onClick: (list: List) => React.MouseEventHandler<HTMLDivElement> | undefined;
  selected?: boolean;
  pinned?: boolean;
}

function ListsListItem({
  list,
  onClick,
  onPinned,
  selected,
  pinned,
}: ListItemProps) {
  return (
    <ListItem
      id={list.source ? list.source.id : list.id}
      variant={selected ? 'soft' : 'plain'}
      color={selected ? 'primary' : 'neutral'}
      endAction={
        <Tooltip title={pinned ? 'Unpin list' : 'Pin list'} placement="right">
          <IconButton
            onClick={() => onPinned(list)}
            color={selected ? 'primary' : 'neutral'}
            sx={{
              transform: 'rotate(45deg)',
              opacity: pinned ? 0.3 : 0,
              '&:hover': {
                opacity: 0.6,
              },
            }}
          >
            <FontAwesomeIcon
              icon={pinned ? solid('thumbtack') : regular('thumbtack')}
            />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemButton onClick={() => onClick(list)}>
        {list.source?.integration && (
          <ListItemDecorator>
            <IntegrationIcon
              stage={config.stage}
              integration={list.source.integration}
            />
          </ListItemDecorator>
        )}
        <ListItemContent
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {list.name}
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

export default ListsListItem;
