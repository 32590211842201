import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, IconButton, Button, Tooltip, Typography } from '@mui/joy';
import { useSelector, useDispatch } from 'react-redux';
import {
  Workflow,
  WorkflowVersion,
  WorkflowEdge,
} from '@sakari-io/sakari-typings';
import { faClockRotateLeft, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Node } from 'reactflow';
import {
  ACTION_PLACEHOLDER_TYPE,
  Mode,
  actions,
} from '../../../../../redux/reducers/workflow';
import {
  useDeactivateWorkflowMutation,
  useUpdateWorkflowDefinitionMutation,
} from '../../../../../api';
import { AccountContext } from '../../../../../contexts/account.context';
import SaveFailureDialog from '../../SaveFailureDialog';
import { formatWorkflowDefinition } from '../../../Canvas/hooks/formatWorkflowDefinition';
import { handleApiMutationResponse } from '../../../../../utils/api';
import EnrollExistingDialog from '../../EnrollExistingDialog';
import Dialog from '../../../../../components/molecules/Dialog';
import EditWorkflowVersion from '../../../../../ui/organisms/Dialogs/EditWorkflowVersion';
import { isNodeComplete } from '../../../../../utils/workflows/nodevalidation';
import IncompleteNodesButton from '../IncompleteNodesButton';
import { WorkflowEdgeProps } from '../../../Canvas/EdgeTypes/WorkflowEdge/index.js';

const getIncompleteNodes = (
  nodes: Node[],
  edges: WorkflowEdgeProps[],
): Node[] => {
  const incompleteNodes = nodes.filter((n: Node) => {
    if (!n.data.type || n.type === ACTION_PLACEHOLDER_TYPE) {
      return false;
    }
    return !isNodeComplete(n.data.type, n.data.config, edges).valid;
  });

  return incompleteNodes;
};

interface HeaderActionsProps {
  workflow: Workflow;
  version: WorkflowVersion;
  onVersionChange: (verisonId: string) => any;
}

function HeaderActions({
  workflow,
  version,
  onVersionChange,
}: HeaderActionsProps) {
  const { t } = useTranslation('workflows');
  const { account, userRole } = useContext(AccountContext);
  const dispatch = useDispatch();

  const { nodes, edges, mode, hasChanges, currentRFNode } = useSelector(
    (state: any) => state.workflow.present,
  );

  const [updateWorkflow, { isLoading: isUpdating }] =
    useUpdateWorkflowDefinitionMutation();
  const [deactivateWorkflow] = useDeactivateWorkflowMutation();

  const [openOverrideDialog, setOpenOverrideDialog] = useState(false);
  const [openSaveFailureDialog, setOpenSaveFailureDialog] = useState(false);
  const [openEnrollExistingDialog, setOpenEnrollExistingDialog] =
    useState(false);
  const [buttonClicked, setButtonClicked] = useState<
    'activate' | 'discard' | ''
  >('');

  const edgesOfNode = edges.filter(
    (edge: WorkflowEdge) => edge.source === currentRFNode?.id,
  );

  const hasOnlyTriggerNode =
    nodes.length === 1 && nodes[0].data?.type?.type === 'trigger';

  const incompleteNodes = getIncompleteNodes(nodes, edgesOfNode);

  const renderSubmitCta = () => {
    switch (mode) {
      case Mode.EDIT:
        return (
          <Button
            disabled={hasOnlyTriggerNode}
            variant="solid"
            color="primary"
            onClick={() => {
              setButtonClicked('activate');
              setOpenEnrollExistingDialog(true);
            }}
          >
            {t('activate.label')}
          </Button>
        );
      case Mode.SELECTION:
        return (
          <Button
            onClick={() => {
              dispatch(actions.enterEditMode());
            }}
          >
            {t('exitSelectorMode')}
          </Button>
        );
      default:
        return null;
    }
  };

  const handleDiscardChanges = () => {
    if (version?.definition) {
      dispatch(actions.setVersion({ version, forceInit: true }));
    }
    dispatch(actions.enterViewMode());
    setButtonClicked('');
  };

  const exitEditorMode = () => {
    dispatch(actions.enterViewMode());
  };

  const handleUpdateWorkflow = async (force = false) => {
    if (!hasChanges) {
      return exitEditorMode();
    }

    dispatch(actions.setCurrentRFNode(undefined));

    const formattedWorkflowDefinition = formatWorkflowDefinition(nodes, edges);

    return handleApiMutationResponse(
      updateWorkflow({
        accountId: account?.id ?? '',
        request: {
          workflowId: workflow?.id,
          versionId: version?.id,
          ...formattedWorkflowDefinition,
          updatedAt: force ? 'force' : version.updated?.at,
        },
      }).unwrap(),
      dispatch,
      {
        onError: (err) => {
          if (err?.data?.error?.code === 'WRFL-038') {
            setOpenOverrideDialog(true);
          } else {
            setOpenSaveFailureDialog(true);
          }
        },
        onSuccess: () => {
          exitEditorMode();
        },
        successMessage: t('edit.workflowUpdated'),
        defaultErrorMessage: t('edit.workflowUpdateError'),
      },
    );
  };

  const onDeactivate = (wf: Workflow) => {
    const payload = {
      accountId: account!.id,
      request: { workflowId: wf?.id, versionId: version.id },
    };

    return handleApiMutationResponse(
      deactivateWorkflow(payload).unwrap(),
      dispatch,
      {
        successMessage: t('deactivate.success', { name: wf.name }),
        defaultErrorMessage: t('deactivate.error'),
      },
    );
  };

  const disabledPermission = userRole === 'ReadOnly' || userRole === 'Sender';

  const handleCloseWarningDialog = () => {
    setButtonClicked('');
    setOpenEnrollExistingDialog(false);
  };

  return (
    <>
      {[Mode.EDIT, Mode.SELECTION].includes(mode) ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.5}
          sx={{ overflow: 'hidden' }}
        >
          <IncompleteNodesButton incompleteNodes={incompleteNodes} />

          <Button
            variant="outlined"
            color="neutral"
            onClick={() => setButtonClicked('discard')}
            disabled={!hasChanges}
          >
            {t('edit.discard.header')}
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => handleUpdateWorkflow()}
            disabled={isUpdating}
            loading={isUpdating}
          >
            {t('edit.exit')}
          </Button>

          {renderSubmitCta()}
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Tooltip
            title={t('contactExecutions.executionsTooltip')}
            arrow
            placement="top"
          >
            <IconButton
              variant="outlined"
              sx={{
                borderRadius: 'var(--joy-radius-md)',
                backgroundColor:
                  mode === Mode.CONTACTS
                    ? 'var(--joy-palette-neutral-200)'
                    : 'default',
              }}
              onClick={() =>
                mode === Mode.CONTACTS
                  ? dispatch(actions.enterViewMode())
                  : dispatch(actions.showContacts())
              }
            >
              <FontAwesomeIcon icon={faUser} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('versions.versionsTooltip')} arrow placement="top">
            <IconButton
              variant="outlined"
              sx={{
                borderRadius: 'var(--joy-radius-md)',
                backgroundColor:
                  mode === Mode.VERSIONS
                    ? 'var(--joy-palette-neutral-200)'
                    : 'default',
              }}
              onClick={() =>
                mode === Mode.VERSIONS
                  ? dispatch(actions.enterViewMode())
                  : dispatch(actions.showVersions())
              }
            >
              <FontAwesomeIcon icon={faClockRotateLeft} />
            </IconButton>
          </Tooltip>
          <EditWorkflowVersion
            workflow={workflow}
            version={version}
            component={'header'}
            onVersionChange={onVersionChange}
          />
          <Tooltip
            title={disabledPermission ? t('readOnly') : null}
            arrow
            placement="top"
          >
            <span>
              <Button
                disabled={disabledPermission}
                variant="solid"
                color={version.active ? 'warning' : 'primary'}
                onClick={() => {
                  if (version.active) {
                    onDeactivate(workflow);
                  } else {
                    setButtonClicked('activate');
                    setOpenEnrollExistingDialog(true);
                  }
                }}
              >
                {version.active ? t('deactivate.label') : t('activate.label')}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      )}
      {buttonClicked === 'discard' && (
        <Dialog
          color="danger"
          title={t(`edit.discard.discardDraft`)}
          open={buttonClicked === 'discard'}
          onClose={() => setButtonClicked('')}
          primaryTitle={t('edit.discard.label')}
          primaryAction={() => handleDiscardChanges()}
          cancelTitle={t('cancel')}
          cancelAction={() => setButtonClicked('')}
        >
          {t('edit.discard.content')}
        </Dialog>
      )}
      {buttonClicked === 'activate' && incompleteNodes.length > 0 && (
        <Dialog
          color="warning"
          title={`${incompleteNodes.length} ${t('edit.invalidNodes')}`}
          open={buttonClicked === 'activate'}
          onClose={handleCloseWarningDialog}
          primaryTitle={t('edit.viewNodes')}
          primaryAction={handleCloseWarningDialog}
          cancelTitle={t('cancel')}
          cancelAction={handleCloseWarningDialog}
        >
          <Stack spacing={2}>
            <Typography>{t('edit.someNodesRequireAttention')}</Typography>
          </Stack>
        </Dialog>
      )}
      <SaveFailureDialog
        open={openSaveFailureDialog}
        onClose={() => setOpenSaveFailureDialog(false)}
      />
      {openEnrollExistingDialog && !incompleteNodes.length && (
        <EnrollExistingDialog
          workflow={workflow}
          version={version}
          onClose={() => {
            setOpenEnrollExistingDialog(false);
            setButtonClicked('');
          }}
        />
      )}

      {openOverrideDialog && (
        <Dialog
          open={openOverrideDialog}
          color="danger"
          onClose={() => setOpenOverrideDialog(false)}
          title={t('workflow.updatedByOtherUser')}
          primaryTitle={t('confirm')}
          primaryAction={() => {
            handleUpdateWorkflow(true);
            setOpenOverrideDialog(false);
          }}
          cancelAction={() => setOpenOverrideDialog(false)}
          cancelTitle={t('cancel')}
        >
          {t('workflow.doYouWantToOverride')}
        </Dialog>
      )}
    </>
  );
}

export default HeaderActions;
