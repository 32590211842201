import React from 'react';
import { Stack } from '@mui/joy';
import { useField } from 'react-final-form';
import { TextField } from '../../ui';
import VerifyPhoneInput from '../../ui/organisms/forms/VerifyPhoneInput';
import FieldWrapper from '../../utils/FieldWrapper';

function Profile() {
  const mobileField = useField('mobile', { subscription: { dirty: true } });

  return (
    <Stack spacing={2} maxWidth={{ sm: '480px' }}>
      <Stack direction="row" spacing={1}>
        <FieldWrapper
          component={TextField}
          name="firstName"
          label="First Name"
          fullWidth
        />
        <FieldWrapper
          component={TextField}
          name="lastName"
          label="Last Name"
          fullWidth
        />
      </Stack>
      <FieldWrapper
        component={VerifyPhoneInput}
        modified={mobileField.meta.dirty}
        name="mobile"
        label="Phone Number"
      />
      <FieldWrapper
        component={TextField}
        fullWidth
        disabled
        name="email"
        label="Email"
      />
    </Stack>
  );
}

export default Profile;
