import { useTranslation } from 'react-i18next';
import { Card, CardProps } from '@mui/joy';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import Setting from '../../../ui/templates/forms/SettingForm';
import PaymentMethods from './PaymentMethods';
import Credits from './Credits';
import BillingInfo from './BillingInfo';
import { showToast } from '../../../redux/reducers/toast';
import { useAppDispatch } from '../../../redux';

function SectionCard({ children, ...props }: CardProps) {
  return (
    <Card
      variant="outlined"
      size="md"
      sx={{
        width: 600,
        p: 2,
        gap: 2,
        overflow: 'hidden',
      }}
      {...props}
    >
      {children}
    </Card>
  );
}

function BillingGeneral() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location?.search);

    if (params.get('plan')) {
      dispatch(
        showToast({
          severity: 'success',
          message: t('settings.billing.plans.planUpdateSuccess'),
        }),
      );
    }
  }, []);

  return (
    <>
      <Setting
        label={t('settings.billing.general.credits.label')}
        description={t('settings.billing.general.credits.description')}
      >
        <SectionCard id="billing-credits">
          <Credits />
        </SectionCard>
      </Setting>
      <Setting
        label={t('settings.billing.general.paymentMethods.label')}
        description={t('settings.billing.general.paymentMethods.description')}
      >
        <SectionCard id="manage-payment-methods">
          <PaymentMethods />
        </SectionCard>
      </Setting>
      <Setting
        label={t('settings.billing.general.info.label')}
        description={t('settings.billing.general.info.description')}
      >
        <SectionCard id="billing-info">
          <BillingInfo />
        </SectionCard>
      </Setting>
    </>
  );
}
export default BillingGeneral;
