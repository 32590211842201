import React, { ReactNode } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, IconButtonProps } from '@mui/joy';
import { Tooltip } from '@sakari-io/sakari-components';

interface DrawerToggleButtonProps extends IconButtonProps {
  onClick: () => void;
  isOpen: boolean;
  tooltip?: string;
  direction?: 'left' | 'right';
  icon?: ReactNode | JSX.Element;
}

function DrawerToggleButton({
  onClick,
  isOpen,
  tooltip,
  sx,
  direction = 'right',
  icon,
  ...rest
}: DrawerToggleButtonProps) {
  return (
    <Tooltip
      title={tooltip}
      placement="right"
      size="sm"
      modifiers={[{ name: 'offset', options: { offset: [42, 20] } }]}
    >
      <IconButton
        onClick={() => onClick()}
        variant="outlined"
        color="neutral"
        size="xsRound"
        sx={{
          zIndex: 'calc(var(--joy-zIndex-drawer) + 1)',
          backgroundColor: 'var(--joy-palette-background-surface)',
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          '& > svg': {
            color: 'text.tertiary',
          },
          ':hover': {
            backgroundColor: 'var(--joy-palette-background-level2)',
            borderColor: 'text.primary',
            '& > svg': {
              color: 'text.primary',
            },
          },
          ':active:active, :focus': {
            backgroundColor: 'var(--joy-palette-background-level1)',
            '& > svg': {
              color: 'text.tertiary',
            },
          },
          transition: 'transform 0.5s ease-in-out, border-color 0.4s ease-in',
          ...sx,
        }}
        {...rest}
      >
        {icon || (
          <FontAwesomeIcon
            icon={
              direction === 'right'
                ? solid('chevron-right')
                : solid('chevron-left')
            }
          />
        )}
      </IconButton>
    </Tooltip>
  );
}
export default DrawerToggleButton;
