import { Button, Checkbox, Link, List, Stack, Typography } from '@mui/joy';
import { Alert } from '@sakari-io/sakari-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TenDLCIntroProps {
  setConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  reason?: string;
}

function TenDLCIntro({ setConfirmation, reason }: TenDLCIntroProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  return (
    <Stack
      sx={{
        margin: 'auto',
        width: '80%',
        maxWidth: '1000px',
        // width: 'clamp(500px, 50%, 900px)',
        gap: 1,
        overflow: 'scroll',
      }}
      p={2}
    >
      {!!reason && (
        <Alert
          severity="warning"
          title={t('senders.register.10dlc.intro.rejectedCampaign')}
          subTitle={<Typography level="body-sm">{reason}</Typography>}
        />
      )}
      <Typography level="h1">10DLC Registration</Typography>
      <Typography mt={2}>
        {t('senders.register.10dlc.intro.description1')}
      </Typography>
      <Typography mt={2}>
        The following screens will create a submission with{' '}
        <Link
          href="https://support.sakari.io/hc/en-us/articles/28542616092948"
          target="_blank"
        >
          The Campaign Registry
        </Link>{' '}
        so your business can identify who you are to the carrier networks (Brand
        registration) and what types of messages you are sending, e.g.
        promotional, account notification, etc. (Campaign Registration).
      </Typography>
      <Typography mt={2}>
        {t('senders.register.10dlc.intro.description3')}
      </Typography>
      {/* <Link
        level="body-md"
        target="_blank"
        title={t('senders.register.10dlc.intro.more')}
        href="https://support.sakari.io/hc/en-us/articles/360056853072-US-Local-Phone-Number-Registration"
      >
        {t('moreInfo')}
      </Link> */}

      <Typography level="h2">What to Expect</Typography>
      <Typography level="h3">Timeline</Typography>
      <Typography level="body-md">
        For submissions that meet compliance requirements, approval is typically
        granted within 3-5 business days
      </Typography>
      <Typography level="h3" id="fees-list">
        {t('senders.register.10dlc.intro.fees.message')}
      </Typography>
      <List
        aria-labelledby="fees-list"
        sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside',
        }}
      >
        {!reason && (
          <li>{t('senders.register.10dlc.intro.fees.brandRegistration')}</li>
        )}
        <li>{t('senders.register.10dlc.intro.fees.campaignVerification')}</li>
        <li>{t('senders.register.10dlc.intro.fees.campaignMonthly')}</li>
      </List>

      <Typography level="h3">Requirements</Typography>
      <Typography level="body-md">
        The following are required for all campaigns.
      </Typography>
      <Typography level="body-md">Online Presence</Typography>
      <ul>
        <li>Working website / online presence (e.g. social media page)</li>
      </ul>
      <Typography level="body-md">SMS Disclosure, including:</Typography>
      <ul>
        <li>Brand name</li>
        <li>Opt-out Instructions (e.g. Reply STOP to opt-out)</li>
        <li>Message Frequency (# msgs/mo, msg frequency varies, etc)</li>
        <li>“Message and data rates may apply” disclaimer</li>
        <li>Help messages (Reply HELP for help)</li>
        <li>Link to a compliant Privacy Policy</li>
      </ul>

      <Typography level="body-md">
        Additional information on 10DLC requirements can be found on our{' '}
        <Link
          level="body-md"
          target="_blank"
          href="https://support.sakari.io/hc/en-us/categories/27945589594772"
        >
          Help Center
        </Link>
      </Typography>
      <Typography level="body-md">
        Please feel free to reach out to our support team for assistance or an
        initial review at{' '}
        <Link level="body-md" target="_blank" href="mailto:help@sakari.io">
          help@sakari.io
        </Link>
      </Typography>

      {/* <Link
        level="body-md"
        target="_blank"
        title={t('senders.register.10dlc.intro.more')}
        href="https://support.sakari.io/hc/en-us/articles/360056853072-US-Local-Phone-Number-Registration"
      >
        {t('moreInfo')}
      </Link> */}
      <Checkbox
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        label={t('senders.register.10dlc.intro.confirmation')}
        size="sm"
        sx={{
          mt: 1,
        }}
      />
      <Button
        size="lg"
        disabled={!checked}
        onClick={() => setConfirmation(checked)}
        sx={{
          mt: 1,
          width: 'fit-content',
        }}
      >
        {!reason
          ? t('senders.register.10dlc.intro.getStarted')
          : t('senders.register.10dlc.intro.startResubmission')}
      </Button>
    </Stack>
  );
}
export default TenDLCIntro;
