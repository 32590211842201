import React, { useState } from 'react';
import { Stack, Link } from '@mui/joy';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  AvailableIntegration,
  AuthenticationParameter,
} from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import AppDialog from '../../../molecules/AppDialog';
import { useConnectIntegrationMutation } from '../../../../api';
import ConnectionStatusAlert from '../../alerts/ConnectionStatusAlert';
import IntegrationInstalling from '../IntegrationInstallingDialog';
import FieldWrapper from '../../../../utils/FieldWrapper';
import { TextField } from '../../..';

interface IntegrationCredentialsDialogProps {
  open: boolean;
  onHide: (result?: any) => void;
  integration: AvailableIntegration;
}

function IntegrationCredentialsDialog({
  open,
  onHide,
  integration,
}: IntegrationCredentialsDialogProps) {
  const { t } = useTranslation();

  const [addCredentials, { isLoading: isConnecting }] =
    useConnectIntegrationMutation();
  const [status, setStatus] = useState('');

  const formSave = async (values: any) => {
    //TODO use handleApiMutationResponse
    await addCredentials({
      data: values,
      type: integration.sourceId,
    })
      .unwrap()
      .then(() => navigate(`/integrations/${integration.sourceId}`))
      .catch((e: Error) => {
        //TODO review this
        logger.error('err', e);
        return setStatus('error');
      });
  };

  // useEffect(() => {
  //   if (status) {
  //     setOpenDialog(false);
  //   }
  // }, [error, status]);

  const navigate = useNavigate();

  if (isConnecting) {
    return <IntegrationInstalling open />;
  }

  return !status ? (
    <Form
      onSubmit={formSave}
      render={({ handleSubmit, values, pristine }) => (
        <form onSubmit={handleSubmit}>
          <AppDialog
            confirmButtonProps={{
              children: 'Add',
              disabled: !values || pristine,
              onClick: handleSubmit,
            }}
            onClose={() => onHide()}
            autoClose={false}
            open={open}
            setOpen={logger.info}
            header={`Connect ${integration.name}`}
            content={
              <Stack spacing={2}>
                {integration.setupLink ? (
                  <Link href={integration.setupLink} />
                ) : null}
                {(integration.authentication?.parameters || []).map(
                  (parameter: AuthenticationParameter) => {
                    return (
                      <FieldWrapper
                        component={TextField}
                        name={parameter.name}
                        label={t(
                          `integrations.${integration.sourceId}.${parameter.name}.label`,
                        )}
                        helperText={t(
                          `integrations.${integration.sourceId}.${parameter.name}.description`,
                        )}
                        type={parameter.type}
                      />
                    );
                  },
                )}
                {integration.setupLink ? (
                  <Link href={integration.setupLink} target="_blank">
                    {t('moreInfo')}
                  </Link>
                ) : null}
              </Stack>
            }
          />
        </form>
      )}
    />
  ) : (
    <Stack>
      <ConnectionStatusAlert
        status={status}
        setStatus={setStatus}
        integration={integration}
      />
    </Stack>
  );
}

export default IntegrationCredentialsDialog;
