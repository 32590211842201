import React, { useContext } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useMakeCallMutation } from '../../api';
import { useAppDispatch } from '../../redux';
import { AccountContext } from '../../contexts/account.context';
import { displayPhoneComponent } from '../../redux/reducers/calls';
import { PhoneStatus } from '../../types';
import { showToast } from '../../redux/reducers/toast';

interface ReturnCallButtonProps {
  call: any;
  text?: string;
}

function ReturnCallButton({ call, text }: ReturnCallButtonProps) {
  const { t } = useTranslation();
  const { profile } = useContext(AccountContext);
  const [makeCall] = useMakeCallMutation();
  const dispatch = useAppDispatch();
  const disabled = !call.contact || !call.group;

  const startCall = async () => {
    if (!profile) return;

    await makeCall({
      from: call.group,
      to: call.contact,
      user: profile,
    })
      .unwrap()
      .then(() => {
        dispatch(displayPhoneComponent(PhoneStatus.CALLING));
      })
      .catch((error) => {
        dispatch(
          showToast({
            message: error.data?.error.message || t('somethingWrong'),
            severity: 'error',
          }),
        );
      });
  };

  return text ? (
    <Button
      variant="plain"
      color="voiceBtn"
      size="sm"
      disabled={disabled}
      sx={{
        color: 'var(--joy-palette-success-500)',
        '&:hover': { opacity: 0.8 },
      }}
      onClick={() => startCall()}
    >
      {text}
    </Button>
  ) : (
    <IconButton
      onClick={() => startCall()}
      size="smRound"
      color="neutral"
      disabled={disabled}
    >
      <FontAwesomeIcon icon={solid('phone')} />
    </IconButton>
  );
}

export default ReturnCallButton;
