import React, { ReactNode, useState } from 'react';
import { WorkflowNodeType } from '@sakari-io/sakari-typings';
import {
  Stack,
  Card,
  RadioProps,
  IconButton,
  Typography,
  Box,
  Chip,
} from '@mui/joy';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { faSnooze } from '@fortawesome/pro-solid-svg-icons';
import IncompleteButton from '../../../../ui/organisms/ButtonContexts/IncompleteButton';
import InlineTriggerLabel from './InlineTriggerLabel';
import MetricsTab, { Metrics } from './MetricsTab';
import { Mode, MetricType } from '../../../../redux/reducers/workflow';

export interface ElementCardProps extends Omit<RadioProps, 'component'> {
  type: WorkflowNodeType;
  selected?: boolean;
  setSelected?: (value: boolean) => void;
  renderSummary?: () => ReactNode;
  disableEditMode?: boolean;
  metrics?: Metrics;
  disabled?: boolean;
  onClick?: () => void;
  id?: string;
  incomplete?: boolean;
  omitInlineLabel?: boolean;
  isHovered?: boolean;
  expandable?: boolean;
  description?: string;
  paused?: boolean;
}

function ElementCard({
  type,
  renderSummary,
  selected,
  setSelected,
  disableEditMode,
  disabled,
  metrics,
  onClick,
  incomplete,
  id,
  omitInlineLabel,
  isHovered,
  expandable,
  description,
  paused,
  ...rest
}: ElementCardProps) {
  const { t } = useTranslation('workflows');
  const [expanded, setExpanded] = useState(false);

  const { nodes, mode, metricType } = useSelector(
    (state: any) => state.workflow.present,
  );

  const node = nodes.find((n: WorkflowNodeType) => n.id === id);

  const isInlineTrigger = node?.data?.type?.type === 'inlineTrigger';

  const isPlaceholder =
    type?.name === 'actionPlaceholder' || type?.name === 'trigger';

  const backgroundColorValue =
    isInlineTrigger || paused
      ? 'var(--grey-original-100-menu-hover-active, #F2F4F7)'
      : 'rgba(255, 255, 255, 0.20)';

  const backgroundColorSx = {
    backgroundColor: paused ? 'transparent' : backgroundColorValue,
    '&:hover': {
      cursor: 'default',
    },
  };

  const color1 = `hsl(from ${type?.color} h 100% 72%)`;
  const color2 = type?.color;

  //TODO review this - feels like a hack
  const heightValue = () => {
    if (expanded) return 'auto';
    if (expandable) return '82px';
    return '100%';
  };

  const cardBody = (
    <Stack spacing={1}>
      <Typography level="body-sm" fontWeight={700}>
        {type?.label}
        {type?.status === 'internal' && <Chip>Beta</Chip>}
      </Typography>
      {paused ? <Typography level="body-xs">{t('awaiting')}</Typography> : null}
      {description ? (
        <Typography level="body-xs">{description}</Typography>
      ) : null}
    </Stack>
  );

  const borderColorByMode =
    mode === Mode.SELECTION
      ? 'var(--joy-palette-info-500)'
      : 'var(--joy-palette-focusVisible)';

  const hoverEditMode = {
    borderColor: borderColorByMode,
    cursor: 'pointer',
    boxShadow: '0 0 10px #6CCCF9',
    color: borderColorByMode,
  };

  const handleClick = () => {
    if (!disabled) {
      if (setSelected) {
        setSelected(true);
      }
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <>
      {isInlineTrigger && !omitInlineLabel && <InlineTriggerLabel />}
      <Card
        onClick={handleClick}
        onKeyUp={(e) => {
          if (e.key === 'Enter') handleClick();
        }}
        tabIndex={0}
        sx={{
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
          padding: 0,
          borderRadius: '22px',
          gap: 2,
          width: '100%',
          height: heightValue(),
          overflow: expanded ? 'auto' : 'hidden',
          ...(expanded && {
            border: '1px solid var(--joy-palette-primary-500)',
          }),
          ...(paused && {
            border: '1px dashed var(--joy-palette-neutral-300)',
          }),
          alignItems: expandable ? 'flex-start' : 'center',
          '--RadioGroup-gap': 0,
          '&:hover': hoverEditMode,
          '&:focus': hoverEditMode,
          ...(isPlaceholder && {
            border: !selected
              ? '1px dashed var(--joy-palette-neutral-200)'
              : `1px dashed ${hoverEditMode?.borderColor}`,
            backgroundColor: 'var(--joy-palette-common-white)',
          }),
          ...(selected && hoverEditMode),

          ...(!disabled &&
            isHovered && {
              ...hoverEditMode,
            }),
          ...rest.sx,
        }}
        orientation="horizontal"
      >
        {!isPlaceholder && incomplete && type?.type !== 'trigger' && (
          <Box position="absolute" right={10} top={10}>
            <IncompleteButton />
          </Box>
        )}

        <Stack
          pr="1px"
          sx={{
            height: '100%',
            minHeight: expanded ? 'auto' : '80px',
            justifyContent: 'center',
            background: `linear-gradient(to left bottom, ${color1}, ${color2})`,
            borderRight: isInlineTrigger
              ? '1px solid var(--joy-palette-divider)'
              : 'none',
          }}
          p={isPlaceholder ? 0 : 2}
        >
          <IconButton
            tabIndex={-1}
            variant="soft"
            sx={{
              backgroundColor: backgroundColorSx,
              '&:hover': {
                backgroundColor: backgroundColorSx,
                cursor: 'default',
              },
            }}
          >
            {!isPlaceholder && (
              <img
                src={`https://assets.sakari.io/images/workflows/icons/${type?.icon}`}
                alt={type?.name}
                height={24}
              />
            )}
          </IconButton>
        </Stack>

        {expandable ? (
          <Stack
            sx={{
              width: '100%',
              paddingLeft: 0,
              paddingRight: '16px',
              overflow: 'hidden',
            }}
          >
            <Stack
              direction="row"
              onClick={() => {
                if (!paused && expandable) {
                  setExpanded(!expanded);
                }
              }}
              sx={{
                display: 'flex',
                height: '80px',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {cardBody}

              {paused ? (
                <FontAwesomeIcon
                  icon={faSnooze}
                  color="var(--joy-palette-focusVisible)"
                />
              ) : (
                <IconButton
                  size="sm"
                  variant="plain"
                  color={rest.color || 'neutral'}
                  style={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                >
                  <FontAwesomeIcon icon={regular('chevron-down')} />
                </IconButton>
              )}
            </Stack>
            <Stack sx={{ paddingBottom: '16px', overflow: 'hidden' }}>
              {renderSummary && renderSummary()}
            </Stack>
          </Stack>
        ) : (
          <Stack p={2} sx={{ paddingLeft: 0, justifyContent: 'center' }}>
            {cardBody}
            {renderSummary ? (
              renderSummary()
            ) : (
              <Typography level="body-xs">{type.description}</Typography>
            )}
          </Stack>
        )}
      </Card>

      {metrics &&
        (mode === Mode.VIEW || mode === Mode.VERSIONS) &&
        node?.data?.type?.type !== 'trigger' && (
          <MetricsTab
            metrics={metrics}
            percentage={metricType === MetricType.PERCENTAGE}
          />
        )}
    </>
  );
}

export default ElementCard;
