import React from 'react';
import { Stack, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { NumberWithFlag } from '@sakari-io/sakari-components';
import { useSearchPhoneNumbersQuery } from '../../../../api/phonenumbers';
import FeatureChip from '../../ChipContexts/FeatureChip';
import DataTable, { Column } from '../../../molecules/AppTable';

interface AvailablePhoneNumbersGridProps {
  filter: any;
  value: string[];
  onSelect: (number: any) => any;
}

function AvailablePhoneNumbersGrid({
  filter,
  value,
  onSelect,
}: AvailablePhoneNumbersGridProps) {
  const { t } = useTranslation();
  const columns: Column[] = [
    {
      field: 'number',
      header: t('addSenders.phonenumber.buy.number'),
      headerProps: {
        width: 200,
      },
      renderCell: (row) => <NumberWithFlag mobile={row?.number} />,
    },
    {
      field: 'features',
      header: t('addSenders.phonenumber.buy.support'),
      headerProps: {
        style: {
          textAlign: 'center',
          minWidth: 160,
        },
      },
      cellProps: {
        style: {
          minWidth: 160,
        },
      },
      renderCell: (row) => {
        return (
          <Stack
            sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              gap: 1,
              justifyContent: 'center',
            }}
          >
            {row?.features?.map((f: any) => <FeatureChip label={f} key={f} />)}
          </Stack>
        );
      },
    },
    {
      field: 'price',
      header: t('addSenders.phonenumber.buy.price'),
      headerProps: {
        style: {
          textAlign: 'center',
          minWidth: 80,
        },
      },
      cellProps: {
        style: {
          // display: 'flex',
          textAlign: 'center',
          minWidth: 80,
        },
      },
      renderCell: (row) => {
        return `$${(row?.price || 0).toFixed(2)}`;
      },
    },
    {
      field: 'actions',
      header: '',
      headerProps: {
        style: {
          width: 100,
        },
      },
      renderCell: (row) => {
        const selected = value.includes(row?.number);
        return (
          <Button
            color={selected ? 'neutral' : 'primary'}
            variant={selected ? 'plain' : 'solid'}
            size="sm"
            onClick={() => onSelect(row?.number)}
            fullWidth
          >
            {selected ? t('action.remove') : t('action.add')}
          </Button>
        );
      },
    },
  ];

  const { data, isFetching } = useSearchPhoneNumbersQuery(filter);

  return (
    <DataTable
      columns={columns}
      rows={data?.data || []}
      isRowSelected={(row) => value.includes(row?.number)}
      loading={isFetching}
      stickyHeader
      sheetProps={{
        sx: {
          p: 0,
          'td, th': {
            px: 2,
            py: 1,
          },
          borderRadius: 'var(--joy-radius-md)',
          height: 400,
          overflow: 'auto',
          '--TableCell-height': '45px',
          '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
          background: (
            theme,
          ) => `linear-gradient(${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
          backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'local, local, scroll, scroll',
          backgroundPosition:
            '0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%',
          backgroundColor: 'background.surface',
        },
      }}
    />
  );
}

export default AvailablePhoneNumbersGrid;
