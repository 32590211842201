import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Sender } from '@sakari-io/sakari-typings';
import { styled } from '@mui/joy/styles';
import { Stack } from '@mui/joy';
import _ from 'lodash';
import { NumberWithFlag } from '@sakari-io/sakari-components';
import { useNavigate } from 'react-router-dom';
import ChannelChips from '../../../ChipContexts/ChannelChips';
import StatusChip from '../../../ChipContexts/StatusChip';

interface GroupSenderRowProps {
  value: Sender;
  onRemove: (value: Sender) => any;
}

function GroupSenderRow({ value, onRemove }: GroupSenderRowProps) {
  const navigate = useNavigate();
  const StyledTd = styled('td')(() => ({
    padding: '16px !important',
    tableLayout: 'auto',
    contain: 'content',
  }));
  const activeChannels = _.keys(value.channels).filter(
    (c) =>
      value.channels &&
      value.channels[c as keyof typeof value.channels]?.active,
  );

  return (
    <>
      <StyledTd
        onClick={() => navigate(`/senders/phonenumbers/${value.id}`)}
        title={value.identifier}
        style={{
          cursor: 'pointer',
        }}
      >
        {value.subType !== 'senderid' ? (
          <NumberWithFlag mobile={value.identifier} />
        ) : (
          value.identifier
        )}
      </StyledTd>
      <StyledTd>
        <Stack direction="row" gap={1}>
          <ChannelChips value={activeChannels} noLabel />
        </Stack>
      </StyledTd>
      <StyledTd>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <StatusChip status={value.status} />
          <FontAwesomeIcon
            onClick={() => onRemove(value)}
            style={{
              cursor: 'pointer',
            }}
            icon={solid('xmark')}
          />
        </Stack>
      </StyledTd>
    </>
  );
}

export default GroupSenderRow;
