import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Account } from '@sakari-io/sakari-typings';
import AssignGroups from '../AssignGroups';
import ChannelSelect from '../AssignGroups/ChannelSelect';
import { GroupAssignment } from '../AssignGroups/AssignGroup';

interface SlackAssignGroupsProps {
  account: Account;
  value: GroupAssignment[];
  onChange: (value: GroupAssignment[]) => any;
}

function SlackAssignGroups({
  account,
  value,
  onChange,
}: SlackAssignGroupsProps) {
  const { t } = useTranslation();

  return (
    <AssignGroups
      value={value || [{ id: '1' }]}
      onChange={onChange}
      renderDestinationLabel={t('integrations.slack.channel.label')}
      groupTooltip={t('integrations.slack.assignGroups.description')}
      destinationTooltip={t('integrations.slack.channel.description')}
      renderDestination={(value, onChange) => (
        <ChannelSelect
          account={account}
          value={value.destination}
          onChange={(destination) =>
            onChange(_.assign({}, value, { destination }))
          }
        />
      )}
    />
  );
}

export default SlackAssignGroups;
