import { NumberDetails } from '@sakari-io/sakari-typings';

export const formatPayload = (values: any): any => {
  const { country, loa, hostedNumbers } = values;
  const payload = {
    hosted: true,
    type: 'local',
    provider: hostedNumbers[0].provider,
    country: country.code,
    numbers: hostedNumbers.map(
      (n: any): NumberDetails => ({
        number: n.number?.number,
        country: n.number?.country,
        ...(n.verification && {
          verification: {
            id: n.verification.verificationId,
            key: n.verification.verificationKey,
          },
        }),
      }),
    ),
    business: {
      name: loa.businessName,
      address: loa.address,
      contact: loa.authPerson,
    },
  };
  return payload;
};

export default { formatPayload };
