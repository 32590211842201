import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact, Group } from '@sakari-io/sakari-typings';
import { IconButton, Tooltip } from '@mui/joy';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayPopup, {
  OverlayPopupProps,
} from '../../../templates/OverlayPopup';
import { useGetGroupsQuery } from '../../../../api';
import SearchableMenu from '../../../molecules/Menu/SearchableMenu';

interface GroupMenuButtonProps
  extends Omit<
    OverlayPopupProps,
    'open' | 'onOpenClose' | 'renderButton' | 'renderPopup'
  > {
  accountId: string;
  contact: Contact;
  onSelectGroup: (group: Group) => void;
  icon?: ReactNode;
  disabled?: boolean;
  tooltip?: string;
  limit?: number;
}

function GroupMenuButton({
  accountId,
  contact,
  onSelectGroup,
  icon,
  disabled,
  tooltip,
  limit = 10,
  ...rest
}: GroupMenuButtonProps) {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const {
    data: groups,
    isLoading,
    isFetching,
  } = useGetGroupsQuery({
    accountId,
    request: {
      offset: 0,
      limit,
      q: query,
    },
  });

  const handleOnSelect = (group: Group) => {
    setOpen(false);
    onSelectGroup(group);
  };

  return (
    <OverlayPopup
      id={`messageContact-${contact.id}`}
      open={open}
      onOpenClose={setOpen}
      renderButton={(setAnchorEl, onClick) => (
        <Tooltip
          title={
            disabled
              ? t('validation.isInvalid', {
                  field: t('items.number_one').toLowerCase(),
                })
              : tooltip
          }
          arrow
          placement="top"
        >
          <div>
            <IconButton
              ref={setAnchorEl}
              size="smRound"
              onClick={onClick}
              disabled={disabled}
              color="primary"
            >
              {icon || <FontAwesomeIcon icon={solid('phone')} />}
            </IconButton>
          </div>
        </Tooltip>
      )}
      renderPopup={() => (
        <SearchableMenu
          onQueryChanged={setQuery}
          items={groups?.data || []}
          onSelect={handleOnSelect}
          getLabel={(group: Group) => group.name}
          isLoading={isLoading || isFetching}
          noOptionsText={t('noneFound', {
            items: t('items.group_other').toLowerCase(),
          })}
        />
      )}
      {...rest}
    />
  );
}

export default GroupMenuButton;
