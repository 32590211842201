import React, { SyntheticEvent } from 'react';
import { Button, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import PropertyMultipleWrapper from '../PropertyMultipleWrapper';
import GoogleColumnsPopup from '../GoogleColumnsPopup';
import PropertyUpdate from '../PropertyUpdate';

function GoogleColumnsMulti(props: any) {
  const { t } = useTranslation('workflows');

  const handleCreateNewItem = (e: SyntheticEvent) => ({
    column: e,
    value: '',
  });

  const { value, disabled } = props;

  const disabledOptions = (value || []).map(
    (v: any) => v?.column?.name?.toLowerCase() ?? '',
  );

  return (
    <PropertyMultipleWrapper
      component={PropertyUpdate}
      fieldLabel="column.name"
      keyProp="column.index"
      createNewItem={handleCreateNewItem}
      disabled={disabled}
      addDeleteButton
      customAddButton={
        <GoogleColumnsPopup
          renderButton={
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
              <Button
                variant="outlined"
                color="neutral"
                startDecorator={<FontAwesomeIcon icon={faPlus} />}
                disabled={disabled}
              >
                {t('column')}
              </Button>
            </Stack>
          }
          fullWidth
          hideButtonOnOpen
          disabledOptions={disabledOptions}
          disabled={disabled}
          {...props}
        />
      }
      sx={{ alignItems: 'flex-end' }}
      fullWidth
      {...props}
    />
  );
}

export default GoogleColumnsMulti;
