import React from 'react';
import { Button, Stack } from '@mui/joy';

interface ElementOutputMenuItemProps {
  label: string;
  onClick: (evt: any) => any;
}

function ElementOutputMenuItem({ label, onClick }: ElementOutputMenuItemProps) {
  return (
    <Button
      variant="outlined"
      color="neutral"
      fullWidth
      onClick={onClick}
      sx={{
        padding: 1,
        borderRadius: 'var(--joy-radius-lg)',
        backgroundColor: 'var(--joy-palette-common-white)',
        border: '1px solid var(--joy-palette-divider)',
        '&:hover': {
          backgroundColor: 'var(--joy-palette-common-white)',
          border: '1px solid var(--joy-palette-focusVisible)',
        },
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ position: 'absolute', left: 0, gap: '8px', padding: '6px' }}
      >
        {label}
      </Stack>
    </Button>
  );
}

export default ElementOutputMenuItem;
