import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, Stack, Typography } from '@mui/joy';
import { WorkflowExecution } from '@sakari-io/sakari-typings';
import { format } from 'date-fns';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface VersionExecutionProps {
  execution: WorkflowExecution;
  onClick: (execution: WorkflowExecution) => any;
}

function VersionExecution({ execution, onClick }: VersionExecutionProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      key={execution.id}
      onClick={() => onClick(execution)}
      sx={{
        padding: '12px 0',
        cursor: 'pointer',
        borderBottom: `1px solid ${'var(--joy-palette-background-level1)'}`,
      }}
    >
      <Typography level="body-sm">
        {format(new Date(execution.start), 'PPpp')}
      </Typography>
      <IconButton size="xs">
        <FontAwesomeIcon icon={faChevronRight} />
      </IconButton>
    </Stack>
  );
}

export default VersionExecution;
