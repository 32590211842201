import React from 'react';
import { ContactUpdate } from '@sakari-io/sakari-typings';
import _ from 'lodash';
import DynamicText from '../DynamicText';
import { ConfigFieldProps } from '../PropertyField';

interface PropertyUpdateProps extends ConfigFieldProps<ContactUpdate> {
  fieldLabel?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

function PropertyUpdate({
  value,
  onChange,
  fieldLabel,
  onHighlight,
  disabled,
  fullWidth,
}: PropertyUpdateProps) {
  const labelValue = fieldLabel
    ? _.get(value, fieldLabel)
    : value?.property?.label;
  return (
    <DynamicText
      value={value?.value as any}
      onChange={(updatedValue: any) => {
        onChange({
          ...value,
          value: updatedValue,
        });
      }}
      fullWidth={fullWidth}
      label={labelValue}
      onHighlight={onHighlight}
      disabled={disabled}
    />
  );
}

export default PropertyUpdate;
