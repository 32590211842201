import { SakariAPIResponse, ShortenedLink } from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { sakariApi } from './rtk';
import { AccountIdWith } from '../types';
import { buildGridQuery, getAccountId } from './common';
import { LINK_TAG } from './tags';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    // getDomains: builder.query<SakariAPIResponse<Dom[]>, SearchablePaginationRequest>({
    //   query: (params) =>
    //     `accounts/${getAccountId()}/domains`,
    //   providesTags: [INTEGRATION_TAG],
    // }),
    // TODO: Add typings
    getLinks: builder.query<
      SakariAPIResponse<ShortenedLink[]>,
      AccountIdWith<{ q: string }>
    >({
      query: ({ accountId, request }) => {
        logger.info('req', request);
        return {
          url: `accounts/${accountId}/links?${buildGridQuery(request)}`,
          method: 'GET',
          // body: request,
        };
      },
      providesTags: (result) => [
        { type: LINK_TAG, id: 'LIST' },
        ...(result?.data?.map((link: any) => ({
          type: LINK_TAG,
          id: link.id,
        })) || []),
      ],
    }),
    getLink: builder.query<
      SakariAPIResponse<ShortenedLink>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/links/${request}`,
        method: 'GET',
      }),
      providesTags: (result, error, { request: id }) => [
        { type: LINK_TAG, id },
      ],
    }),
    updateLink: builder.mutation<
      SakariAPIResponse<ShortenedLink>,
      AccountIdWith<Partial<ShortenedLink> & Pick<ShortenedLink, 'id'>>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/links/${request.id}`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: [LINK_TAG],
    }),
    getLinkSources: builder.query<
      SakariAPIResponse<any>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/links/${request}/sources`,
        method: 'GET',
      }),
    }),
    getLinkSource: builder.query<
      SakariAPIResponse<any>,
      AccountIdWith<{
        linkId: string;
        sourceId: string;
      }>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/links/${request.linkId}/sources/${request.sourceId}`,
        method: 'GET',
      }),
    }),
    getLinkSourceStats: builder.query<
      SakariAPIResponse<any[]>,
      AccountIdWith<{
        linkId: string;
        sourceId: string;
      }>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/links/${request.linkId}/sources/${request.sourceId}/stats`,
        method: 'GET',
      }),
    }),
    getLinkSourcesStats: builder.query<
      SakariAPIResponse<any>,
      AccountIdWith<{
        linkId: string;
      }>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/links/${request.linkId}/sources/`,
        method: 'GET',
      }),
      onQueryStarted: async (
        { accountId, request },
        { dispatch, queryFulfilled },
      ) => {
        const result = await queryFulfilled;
        // call link source stats endpoint for each source
        const promises = await result.data.data.map((source: any) =>
          dispatch(
            extendedApi.endpoints.getLinkSourceStats.initiate({
              accountId,
              request: {
                linkId: request.linkId,
                sourceId: source.id,
              },
            }),
          )
            .unwrap()
            .then((res) => {
              return {
                source,
                data: res.data,
              };
            }),
        );
        const sourcesStats = await Promise.all(promises);
        logger.info('query', { sourcesStats });
        // transform into timeseries data
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const timeseries = sourcesStats.reduce(
          (result: any, sourceStats: any) => {
            const { source, data: stats } = sourceStats;

            stats.forEach(
              (
                stat: {
                  date: string;
                  value: number;
                },
                index: number,
              ) => {
                const { date, value } = stat;
                if (!result[index]) {
                  result[index] = {
                    date: new Date(date),
                  };
                }
                result[index][source.name] = value;
              },
            );
            return result;
          },
          [],
        );

        // update cache with transformed data
        dispatch(
          extendedApi.util.updateQueryData(
            'getLinkSourcesStats',
            { accountId, request },
            (draft) => {
              draft.data = sourcesStats;
            },
          ),
        );
      },
    }),

    createLink: builder.mutation<
      SakariAPIResponse<ShortenedLink>,
      Partial<ShortenedLink & { name: string }> // TODO: update typing
    >({
      query: ({ destinationUrl, contactTracking, name }) => ({
        url: `accounts/${getAccountId()}/links`,
        method: 'POST',
        body: {
          destinationUrl,
          contactTracking,
          name,
        },
      }),
      invalidatesTags: () => [{ type: LINK_TAG, id: 'LIST' }],
    }),
    deleteLink: builder.mutation<SakariAPIResponse<ShortenedLink>, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/links/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: LINK_TAG, id: 'LIST' },
        { type: LINK_TAG, id },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: LINK_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getLinks') {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getLinks',
                  originalArgs,
                  (draft) => {
                    const index = draft.data.findIndex((l: any) => l.id === id);

                    if (index >= 0) {
                      draft.data.splice(index, 1);
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLinksQuery,
  useGetLinkQuery,
  useGetLinkSourcesQuery,
  useGetLinkSourceQuery,
  useGetLinkSourceStatsQuery,
  useGetLinkSourcesStatsQuery,
  useCreateLinkMutation,
  useDeleteLinkMutation,
  useUpdateLinkMutation,
} = extendedApi;
