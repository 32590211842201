import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { skipToken } from '@reduxjs/toolkit/query';
import { List, Stack } from '@mui/joy';
import { Contact } from '@sakari-io/sakari-typings';
import { logger, useDebouncedValue } from '@sakari-io/sakari-components';
import SearchBar from '../../../molecules/inputs/SearchBar';
import { useGetContactsQuery } from '../../../../api';
import ContactListItem from './ContactListItem';
import { Loader } from '../../..';

export interface ContactsListProps {
  account: any;
  onSelect: (contact: Contact) => void;
  onAction: (contact: Contact) => void;
}

export const LIMIT = 20;

function ContactsList({ account, onSelect, onAction }: ContactsListProps) {
  const { t } = useTranslation('voice');

  const [inputValue, setInputValue] = useState<string>('');
  const debouncedSearchKey = useDebouncedValue(inputValue, 500);

  const [offset, setOffset] = useState<number>(0);
  const [contacts, setContacts] = useState<Contact[]>([]);

  const getQuery = (os: number) =>
    offset >= 0
      ? {
          accountId: account.id,
          request: {
            q: debouncedSearchKey,
            offset: os,
            limit: LIMIT,
          },
        }
      : skipToken;

  const { data: currentData, isLoading: isLoadingCurrent } =
    useGetContactsQuery(getQuery(offset));

  const loadContacts = () => {
    if (currentData?.data.length === LIMIT) {
      setOffset(offset + LIMIT);
    } else {
      logger.info('No more data available');
    }
  };

  useEffect(() => {
    if (offset < 20) {
      setContacts(currentData?.data || []);
    } else {
      setContacts(_.concat(contacts, currentData?.data || []));
    }
  }, [currentData]);

  useEffect(() => {
    setOffset(0);
  }, [inputValue]);

  return (
    <Stack
      sx={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <SearchBar
        placeholder={t('search')}
        value={inputValue}
        onChange={setInputValue}
        fullWidth
        endDecorator={false}
        sx={{
          borderRadius: '0',
          background: 'rgba(255, 255, 255, 0.05)',
          color: 'white',
          border: 'none',
          mb: '4px',
          input: {
            color: 'white',
            '&::placeholder': {
              color: 'rgba(255, 255, 255, 0.5)',
              opacity: 1,
            },
          },
          '& .Mui-focused, &:focus, &:focus-visible, &:focus-within': {
            boxShadow: 'none',
            border: 'none',
          },
        }}
      />
      <Stack
        id="contactsHolder"
        sx={{
          flex: 1,
          overflowY: 'overlay',
          '&:hover': {
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.25)',
            },
          },
        }}
      >
        <InfiniteScroll
          scrollableTarget="contactsHolder"
          dataLength={contacts?.length}
          next={() => loadContacts()}
          hasMore
          scrollThreshold="75%"
          style={{
            height: '100%',
            marginRight: '0',
          }}
          loader={
            isLoadingCurrent && (
              <Stack
                sx={{
                  width: '100%',
                  minHeight: 350,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  filter: 'brightness(0%) invert(1) opacity(0.9) ',
                }}
              >
                <Loader size={200} label="Loading Contacts" />
              </Stack>
            )
          }
        >
          <List>
            {contacts?.map((contact: Contact) => (
              <ContactListItem
                contact={contact}
                onSelect={onSelect}
                onAction={onAction}
              />
            ))}
          </List>
        </InfiniteScroll>
      </Stack>
    </Stack>
  );
}

export default ContactsList;
