import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';
import Dialog from '../../../../components/molecules/Dialog';

interface ActivationWarningDialogProps {
  open: boolean;
  onAgree: () => void;
  onClose: () => void;
}

function ActivationWarningDialog({
  open,
  onAgree,
  onClose,
}: ActivationWarningDialogProps) {
  const { t } = useTranslation('workflows');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('activate.replace')}
      primaryAction={onAgree}
      primaryTitle={t('activate.acknowledge')}
      cancelTitle="Cancel"
      cancelAction={onClose}
      color="warning"
    >
      <Typography level="body-sm">{t('activate.replaceActive')}</Typography>
    </Dialog>
  );
}

export default ActivationWarningDialog;
