import React, { SyntheticEvent } from 'react';
import { Box, CardProps, MenuList, Sheet } from '@mui/joy';
import MenuItem, { MenuItemProps } from '../../atoms/MenuItem';

export interface AppMenuProps extends Omit<CardProps, 'onSelect'> {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  wrap?: boolean;
  items?: MenuItemProps[];
  onSelect?: (e: SyntheticEvent<any>, item: MenuItemProps) => void;
  children?: React.ReactNode;
}

function AppMenu({
  header,
  footer,
  wrap = false,
  items,
  children,
  onSelect,
  sx,
  ...rest
}: AppMenuProps) {
  const sharedProps = {
    color: rest.color || 'neutral',
    size: rest.size || 'md',
    variant: rest.variant || 'outlined',
  };

  const defaultAppMenuProps = {
    sx: {
      p: 0,
      borderRadius: `var(--joy-radius-${rest.size || 'md'})`,
      display: 'flex',
      flexFlow: 'column nowrap',
      ...sx,
    },
    ...sharedProps,
  };
  return (
    <Sheet {...{ ...defaultAppMenuProps, ...rest }}>
      {header && (
        <Box p={1.5} pb={0}>
          {header}
        </Box>
      )}
      <MenuList
        key={rest.id}
        sx={{
          '--List-radius': 'inherit',
          border: 'none',
          display: 'flex',
          flex: 1,
        }}
        {...sharedProps}
      >
        {items?.map((item, index: number) => (
          <MenuItem
            key={item.id || index}
            wrap={wrap}
            color={rest.color || 'neutral'}
            variant={rest.variant === 'outlined' ? 'plain' : rest.variant}
            onClick={(e) =>
              onSelect ? onSelect(e, item) : item.onClick && item.onClick(e)
            }
            {...item}
          />
        ))}
        {children}
      </MenuList>
      {footer && (
        <Box id="footer" p={1.5} pt={0}>
          {footer}
        </Box>
      )}
    </Sheet>
  );
}
export default AppMenu;
