import React, { useEffect } from 'react';
import { Loader, logger } from '@sakari-io/sakari-components';
import config from '../../config';
import clearCookies from '../../utils/authentication';

function Signup() {
  useEffect(() => {
    clearCookies();

    const params = new URLSearchParams(window.location.search); // id=123
    const email = params.get('email');

    const loginHint = email ? `&login_hint=${email}` : '';
    const url = `${
      config.auth0.domain
    }/authorize?response_type=code&client_id=${
      config.auth0.clientId
    }&redirect_uri=${config.auth0.callbackUrl}&scope=${encodeURIComponent(
      config.auth0.scopes,
    )}&screen_hint=signup${loginHint}`;
    logger.info('url', url);
    window.location.href = url;
  }, []);

  return <Loader size={200} />;
}

export default Signup;
