import React from 'react';
import { Typography, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Delay } from '@sakari-io/sakari-typings';
import _ from 'lodash';
import Select from '../../atoms/inputs/Select';

interface TimeDelayProps {
  value?: Delay;
  onChange: (value: Delay) => void;
  label?: string;
  helperLabel?: string;
  disabled?: boolean;
  maxDays?: number;
}

function TimeDelay({
  value,
  onChange,
  label,
  helperLabel,
  disabled,
  maxDays,
}: TimeDelayProps) {
  const { t } = useTranslation();

  const handleValueChange = (type: string, newValue: string) => {
    if (newValue && value) {
      const updatedValue = { ...value, [type]: Number(newValue) };
      onChange(updatedValue);
    }
  };

  const handleValues = (type: string) => {
    const values: string[] = [];
    let range = 11;

    if (type === 'hours') range = 25;
    if (type === 'minutes') range = 61;
    if (type === 'days' && maxDays) range = maxDays + 1;

    _.range(range).forEach((current) => {
      values.push(String(current));
    });

    return values;
  };
  const renderOptions = (type: string) => {
    return (
      <Stack sx={{ flexGrow: 1 }}>
        <Typography level="body-sm">{t(`links.timerange.${type}`)}</Typography>
        <Select
          options={handleValues(type)}
          onChange={(newValue) => {
            handleValueChange(type, newValue);
          }}
          sx={{ width: '100%' }}
          value={`${value?.[type as keyof Delay]}`}
          disabled={disabled}
        />
      </Stack>
    );
  };

  return (
    <>
      <Typography level="body-md" sx={{ fontWeight: 500 }}>
        {label}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'center',
          margin: '6px 0px !important',
          width: '100%',
          display: 'flex',
        }}
      >
        {renderOptions('days')}
        {renderOptions('hours')}
        {renderOptions('minutes')}
      </Stack>
      <Typography
        level="body-md"
        sx={{ margin: '0px !important', fontWeight: 500 }}
      >
        {helperLabel}
      </Typography>
    </>
  );
}

export default TimeDelay;
