import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { Loader, logger } from '@sakari-io/sakari-components';
import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import _ from 'lodash';
import Partners, { PartnersViewMode } from '.';
import Setting from '../../ui/templates/forms/SettingForm';
import { AccountContext } from '../../contexts/account.context';
import CopyButton from '../../ui/organisms/ButtonContexts/CopyButton';

import TextField from '../../ui/atoms/inputs/TextField';
import FieldWrapper from '../../utils/FieldWrapper';
import config from '../../config';
import { useGetUsersQuery } from '../../api';
import PartnerUsers from '../../ui/organisms/forms/PartnerUsers';
import { useUpdatePartnerMutation } from '../../api/partners';
import { useAppDispatch } from '../../redux';
import { showToast } from '../../redux/reducers/toast';

function Settings() {
  const { account, isLoading } = useContext(AccountContext);
  const [updatePartner] = useUpdatePartnerMutation();

  const { t } = useTranslation();

  const { partner } = account || {};

  const userData = (
    useGetUsersQuery({
      accountId: account?.id || '',
      request: {},
    })?.data?.data || []
  ).filter((user) => user.id === partner?.contact);

  const dispatch = useAppDispatch();

  const savePartnerSettings = async (values: any) => {
    logger.info('values', values);
    const body = {
      paypalEmail: values.paypalEmail || '',
      contact: values.user.id || '',
    };

    //TODO use handleApiMutationResponse
    await updatePartner({ accountId: account?.id || '', request: body })
      .unwrap()
      .then(() => {
        dispatch(
          showToast({
            severity: 'info',
            message: t('settings.save.success'),
          }),
        );
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err?.data?.error?.message || t('settings.save.error'),
          }),
        );
      });
  };

  const schema = Yup.object().shape({
    user: Yup.object()
      .shape({
        id: Yup.string().required(t('partnerSignup.sakariUser.invalid')),
      })
      .nullable(),
    paypalEmail: Yup.string()
      .required(t('partnerSignup.paypalEmail.validation'))
      .email(t('partnerSignup.paypalEmail.invalid')),
  });

  const validate = makeValidate(schema);

  if (isLoading) {
    return <Loader size={200} />;
  }

  return (
    <Form
      initialValues={{
        referralCode: account?.referralCode,
        user: userData[0],
        paypalEmail: partner?.paypalEmail || '',
      }}
      validate={validate}
      onSubmit={savePartnerSettings}
      render={({ handleSubmit, values, submitting }) => {
        const pristine =
          values.paypalEmail === partner?.paypalEmail &&
          _.isEqual(userData[0], values.user);
        return (
          <form id="partnerForm">
            <Partners
              view={PartnersViewMode.SETTINGS}
              title="Partner Settings"
              action={handleSubmit}
              actionText="Save"
              disableSubmit={pristine}
              loading={submitting}
            >
              <Stack spacing={3} direction="column">
                {account?.referralCode ? (
                  <Setting label="Referral Code">
                    <Typography
                      level="body-md"
                      fontWeight="lg"
                      endDecorator={
                        <CopyButton
                          text={`${config.auth0.callbackUrl}/?referrer=${values?.referralCode}`}
                        />
                      }
                      gutterBottom
                    >
                      {`${config.baseUrl}/?referrer=${values?.referralCode}`}
                    </Typography>
                  </Setting>
                ) : null}
                <Setting label={t('Primary Contact')}>
                  <FieldWrapper
                    name="user"
                    account={account}
                    component={PartnerUsers}
                    placeholder={t('partnerSignup.sakariUser.placeholder')}
                    required
                  />
                </Setting>
                <Setting label="PayPal Email">
                  <FieldWrapper
                    component={TextField}
                    name="paypalEmail"
                    fullWidth
                    placeholder="Please Enter Paypal Email for Collecting Payment"
                  />
                </Setting>
              </Stack>
            </Partners>
          </form>
        );
      }}
    />
  );
}

export default Settings;
