import React from 'react';
import { CannyFeedback, CannyProvider } from 'react-canny';
import { Loader, logger } from '@sakari-io/sakari-components';
import { useGetCannyTokenQuery } from '../../api';
import { getAccountId } from '../../api/common';
import { PageHeader } from '../../ui';
import Container from '../../ui/templates/Container';

const APP_ID = '5ca69cd78fdaad7f7cd2bfc9';
const BOARD_TOKEN = '369fd692-2ccc-135a-a7e3-92388629369b';

function FeatureRequestsPage() {
  const { data } = useGetCannyTokenQuery(getAccountId() || '');

  logger.info('data', data);
  if (!data) {
    return <Loader size={200} />;
  }

  return (
    <Container>
      <PageHeader title="Feature Requests" />
      <CannyProvider appId={APP_ID}>
        <CannyFeedback
          basePath="/featurerequests"
          boardToken={BOARD_TOKEN}
          ssoToken={data.data}
        />
      </CannyProvider>
    </Container>
  );
}

export default FeatureRequestsPage;
