import english from './en/index';
import french from './fr/index';
import german from './de/index';
import spain from './es/index';
import portugese from './pt/index';

const languages = {
  english,
  french,
  german,
  spain,
  portugese,
};

export default languages;
