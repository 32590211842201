import React from 'react';
import { Stack } from '@mui/joy';
import { AdvancedDelayTime } from '@sakari-io/sakari-typings';
import TimezoneAutoComplete from '../../organisms/forms/TimezoneAutoComplete';
import SimpleTime from '../../organisms/forms/SimpleTime';

interface TimeSelectProps {
  value: AdvancedDelayTime;
  onChange: (v: AdvancedDelayTime) => any;
  disabled?: boolean;
}

function TimeSelect({ value, onChange, disabled }: TimeSelectProps) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const defaultTimezone = tz || '';

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: 'flex',
        width: '100%',
        '& > *': {
          flexGrow: 1,
          flexShrink: 1,
          minWidth: 38,
        },
      }}
    >
      <SimpleTime
        value={{ hour: value?.hour || 0, minute: value?.minute || 0 }}
        sx={{
          '& .MuiSelect-root': {
            minWidth: '60px',
          },
          flexGrow: 1,
          flexShrink: 1,
          minWidth: 38,
        }}
        disabled={disabled}
        onChange={(e) =>
          onChange({
            hour: e.hour,
            minute: e.minute,
            timezone: value?.timezone || defaultTimezone,
          })
        }
      />
      <TimezoneAutoComplete
        value={value?.timezone}
        disabled={disabled}
        onChange={(e) =>
          onChange({
            hour: value?.hour || 0,
            minute: value?.minute || 0,
            timezone: e || defaultTimezone,
          })
        }
        sx={{
          '.MuiAutocomplete-input': {
            maxWidth: 150,
          },
          flexGrow: 1,
          flexShrink: 1,
          minWidth: 38,
        }}
      />
    </Stack>
  );
}

export default TimeSelect;
