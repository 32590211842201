import React, { useEffect } from 'react';
import { Stack } from '@mui/joy';
import { useForm, useFormState } from 'react-final-form';
import CustomHoursFieldArray from './CustomHoursFieldArray';
import HourSwitch from './HourSwitch';

interface CustomHourProps {
  name: string;
  day: string;
  officeHoursActive?: boolean;
}

function CustomHour({ day, name, officeHoursActive }: CustomHourProps) {
  const form = useForm();
  const fieldName = `${name}.times.${day}`;
  const values = useFormState().values[name];
  const dayValues = values?.times?.[day] || undefined;

  const [showAdd, setShowAdd] = React.useState(
    dayValues === undefined ? false : true,
  );

  useEffect(() => {
    setShowAdd(!!dayValues);
  }, [dayValues]);

  useEffect(() => {
    if (!officeHoursActive) {
      setShowAdd(false);
    }
  }, [officeHoursActive]);

  useEffect(() => {
    if (dayValues) {
      return;
    }
    if (values && !dayValues) {
      form.change(fieldName, undefined);
    }
  }, [dayValues]);

  useEffect(() => {
    if (!showAdd) {
      form.change(fieldName, undefined);
    }
  }, [showAdd]);

  return (
    <Stack
      sx={{
        p: 1,
        width: '100%',
        flexFlow: 'row noWrap',
        '&:not(:last-of-type)': {
          borderBottom: `1px solid var(--joy-palette-divider)`,
        },
      }}
    >
      <HourSwitch showAdd={showAdd} setShowAdd={setShowAdd} day={day} />
      {showAdd ? (
        <Stack flex="1 1 0">
          <CustomHoursFieldArray
            fieldName={fieldName}
            value={dayValues}
            mutators={form.mutators}
            showAdd={showAdd}
          />
        </Stack>
      ) : null}
    </Stack>
  );
}

export default CustomHour;
