import React from 'react';
import { Switch as JoySwitch, SwitchProps as SwitchBaseProps } from '@mui/joy';
import InputControl, {
  InputControlProps,
} from '../../../molecules/InputControl';

interface SwitchProps
  extends Omit<SwitchBaseProps, 'onBlur' | 'onFocus'>,
    Omit<
      InputControlProps,
      'value' | 'onChange' | 'slotProps' | 'slots' | 'children'
    > {}
function Switch({
  label,
  fullWidth,
  required,
  error,
  helperText,
  orientation,
  size,
  disabled,
  sx,
  ...rest
}: SwitchProps) {
  const ControlProps = {
    label,
    fullWidth,
    required,
    error,
    helperText,
    orientation,
    size,
    disabled,
    sx,
  };
  return (
    <InputControl {...ControlProps}>
      <JoySwitch {...rest} />
    </InputControl>
  );
}
export default Switch;
