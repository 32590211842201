import React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';
import { logger, Textarea, Autocomplete } from '@sakari-io/sakari-components';
import Grid from '../../../../ui/templates/Grid';
import { Option } from '../../../../types/ui';
import Helper from '../../../../utils/helper';

import FieldWrapper from '../../../../utils/FieldWrapper';
import FileUploadCombo from '../../../../ui/organisms/forms/FileUploadCombo';

const USE_CASES = [
  '2FA',
  'App Notifications',
  'Appointments',
  'Auctions',
  'Auto / Dealership Services',
  'Banking',
  'Billing',
  'Booking Confirmations',
  'Business Updates',
  'COVID-19 Alerts',
  'Career Training',
  'Chatbot',
  'Conversational / Alerts',
  'Courier Services & Deliveries',
  'Educational',
  'Emergency Alerts',
  'Employee Alerts / Notifications',
  'Events & Planning',
  'Financial Services',
  'Fraud Alerts',
  'Fundraising',
  'General Marketing',
  'HR / Staffing',
  'Healthcare',
  'Housing Community Updates',
  'Insurance Services',
  'Job Alerts',
  'Legal Services',
  'Mixed',
  'Motivational Reminders',
  'Notary Notifications',
  'Notifications',
  'Order Notifications',
  'Political',
  'Public Works',
  'Real Estate Services',
  'Receipt Notifications',
  'Religious Services',
  'Repair and Diagnostics Alerts',
  'Rewards Program',
  'Surveys',
  'System Alerts',
  'Waitlist Alerts',
  'Webinar Reminders',
  'Workshop Alerts',
];

const optinMethods = [
  {
    id: 'ivr',
    label: 'Interaction Voice Response (IVR)',
  },
  {
    id: 'keyword',
    label: 'Keyword',
  },
  {
    id: 'pos',
    label: 'POS / On-site',
  },
  {
    id: 'paper',
    label: 'Paper form',
  },
  {
    id: 'url',
    label: 'Weblink/URL',
  },
  {
    id: 'other',
    label: 'Other',
  },
];

const MessageVolume = [
  10, 100, 1000, 10000, 100000, 250000, 500000, 750000, 1000000, 5000000,
  10000000,
];

function CampaignInfo() {
  const { t } = useTranslation();
  const { phoneNumbers, campaign } = useFormState().values;

  const usecaseOptions: Option[] = USE_CASES.map((option) => ({
    id: option,
    label: option,
    description: option,
  }));
  // const usecaseOptions = USE_CASES;
  logger.info('usecaseOptions', usecaseOptions);

  const optinInfoPlaceholder = (method: string): string => {
    const defaultMsg = `${t(
      `senders.register.tollfree.campaign.optin.placeholder.default`,
    )}`;
    if (method) {
      return `${defaultMsg}\n\n${t(
        `senders.register.tollfree.campaign.optin.placeholder.${method}`,
      )}`;
    }

    return defaultMsg;
  };

  return (
    <>
      <Grid>
        <Grid item gridColumn="span 12">
          <FieldWrapper
            component={Autocomplete}
            name="campaign.useCase"
            label={t('senders.register.tollfree.campaign.useCase.label')}
            options={usecaseOptions}
            getOptionLabel={(option: Option) => {
              return option?.label || '';
            }}
            required
            disableClearable
          />
        </Grid>
        <Grid item gridColumn="span 12">
          <FieldWrapper
            component={Textarea}
            name="campaign.description"
            label={t('senders.register.tollfree.campaign.description.label')}
            placeholder={t(
              'senders.register.tollfree.campaign.description.placeholder',
            )}
            sx={{
              '::placeholder': {
                whiteSpace: 'pre-wrap',
              },
            }}
            minRows={4}
            maxLength={501}
            required
          />
        </Grid>
      </Grid>

      <Typography fontWeight={900}>
        {t('senders.register.tollfree.campaign.optin.label')}
      </Typography>

      <Grid>
        <Grid item gridColumn="span 12">
          <FieldWrapper
            component={Autocomplete}
            name="campaign.optinMethod"
            label={t('senders.register.tollfree.campaign.optin.method')}
            options={optinMethods}
            required
            disableClearable
          />
        </Grid>

        <Grid item gridColumn="span 12">
          <FieldWrapper
            component={Textarea}
            name="campaign.optinInfo"
            label={t('senders.register.tollfree.campaign.optin.info')}
            placeholder={optinInfoPlaceholder(campaign?.optinMethod?.id)}
            minRows={4}
            maxLength={501}
            required
          />
        </Grid>
        <Grid item gridColumn="span 12">
          <Typography
            level="body-md"
            fontWeight={900}
            sx={{
              '::after': {
                content: '" *"',
                color: 'danger.500',
              },
            }}
          >
            Please upload an image supporting your opt-in process
          </Typography>
          <Typography level="body-md" mb={2}>
            Provide an image of the opt-in process and what a subscriber is
            agreeing to. This should be where the customer’s phone number is
            entered by the customer agreeing to receive the SMS.
          </Typography>

          <FieldWrapper
            component={FileUploadCombo}
            name="campaign.optinFile"
            uploadProps={{
              maxFiles: 1,
              accept: 'image',
            }}
            hideOnUpload
          />
        </Grid>
      </Grid>

      <Typography fontWeight={900}>{t('volume')}</Typography>

      <Grid>
        {phoneNumbers.length > 1 ? (
          <Grid item gridColumn="span 12">
            <FieldWrapper
              component={Textarea}
              name="campaign.multipleNumbersReason"
              label={t(
                'senders.register.tollfree.campaign.multipleNumbers.label',
              )}
              placeholder={t(
                'senders.register.tollfree.campaign.multipleNumbers.placeholder',
              )}
              helperText={t(
                'senders.register.tollfree.campaign.multipleNumbers.helperText',
              )}
              minRows={4}
              required
            />
          </Grid>
        ) : null}
        <Grid item gridColumn={{ xs: 12 }}>
          <FieldWrapper
            component={Autocomplete}
            name="campaign.messageVolume"
            label={t('senders.register.tollfree.campaign.messageVolume.label')}
            options={MessageVolume}
            getOptionLabel={(option: number) =>
              option ? Helper.formatNumberWithCommas(option) : ''
            }
            disableClearable
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
export default CampaignInfo;
