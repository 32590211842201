import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../../../../ui/molecules/ConfirmationDialog';

interface DeleteWebhookDialogProps {
  open: boolean;
  onClose: () => void;
}

// TODO can this be made even more generic?
function DeleteWebhookDialog({ open, onClose }: DeleteWebhookDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      content={t('cannotUndoAction')}
      confirmLabel="yesDelete"
      header={t('settings.api.webhooks.delete.title')}
      type="danger"
    />
  );
}

export default DeleteWebhookDialog;
