import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@sakari-io/sakari-components';
import FieldWrapper from '../../../../utils/FieldWrapper';

import FileUploadCombo from '../../../../ui/organisms/forms/FileUploadCombo';

function SampleMessages() {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      {[1, 2].map((i, ix) => (
        <FieldWrapper
          component={Textarea}
          key={i}
          minRows={4}
          maxLength={251}
          label={`Sample ${i}`}
          name={`samples.${ix}`}
          placeholder={t(`senders.register.tollfree.samples.placeholder${i}`)}
        />
      ))}
      <Typography fontWeight={900}>
        {t('senders.register.tollfree.supportingDocs.title')}
      </Typography>
      <Typography level="body-md">
        {t('senders.register.tollfree.supportingDocs.description')}
      </Typography>
      <FieldWrapper
        component={FileUploadCombo}
        name="files"
        uploadProps={{
          accept: 'files',
          maxFiles: 3,
        }}
      />
      <Typography level="body-sm" textColor="text.secondary">
        Please upload up to 3 files.
      </Typography>
    </Stack>
  );
}
export default SampleMessages;
