import React from 'react';
import {
  FormLabel,
  FormHelperText,
  Stack,
  FormControl,
  FormControlProps,
  Typography,
} from '@mui/joy';
import { Tooltip } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface SettingProps extends FormControlProps {
  label?: string;
  labelInfo?: string;
  description?: string;
  spacer?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
}

function Setting({
  label,
  labelInfo,
  description,
  children,
  spacer,
  sx,
  ...rest
}: SettingProps) {
  return (
    <FormControl
      sx={{
        flexFlow: 'row nowrap',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        gap: 2,
        '& .MuiFormLabel-root': {
          display: 'inline',
          fontWeight: 'var(--joy-fontWeight-lg)',
        },
        ...sx,
      }}
      {...rest}
    >
      {(!!label || !!description) && (
        <Stack
          direction="column"
          sx={{ padding: spacer || null, flex: '1', minWidth: 200 }}
        >
          {label ? (
            <FormLabel
              component={Typography}
              title={typeof label === 'string' ? label : undefined}
              endDecorator={
                labelInfo && (
                  <Tooltip
                    size={rest.size || 'md'}
                    title={labelInfo}
                    placement="top"
                    variant="outlined"
                    arrow
                    sx={{
                      maxWidth: '20rem',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={solid('info-circle')}
                      style={{
                        color: 'var(--joy-palette-neutral-300)',
                      }}
                    />
                  </Tooltip>
                )
              }
            >
              {label}
            </FormLabel>
          ) : null}
          {description ? <FormHelperText>{description}</FormHelperText> : null}
        </Stack>
      )}
      <Stack sx={{ flex: '3', alignItems: 'flex-start' }}>{children}</Stack>
    </FormControl>
  );
}

export default Setting;
