import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from '@sakari-io/sakari-typings';
import { format } from '@sakari-io/sakari-common';
import { logger } from '@sakari-io/sakari-components';
import { useBlockContactMutation } from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface BlockContactDialogProps {
  open: boolean;
  onClose: (result?: boolean) => void;
  contact: Contact;
}

function BlockContactDialog({
  open,
  onClose,
  contact,
}: BlockContactDialogProps) {
  const [blockContact] = useBlockContactMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleBlock = async () => {
    return blockContact(contact.id)
      .then(() => {
        dispatch(
          showToast({
            severity: 'info',
            message: `${format.name(contact) || 'Contact'} has been blocked`,
          }),
        );
      })
      .catch((err) => {
        logger.info(err);
        dispatch(
          showToast({
            severity: 'error',
            message: `${format.name(contact) || 'Contact'} could not be blocked`,
          }),
        );
      });
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={(result) => {
        if (result) {
          handleBlock();
        }
        onClose(result);
      }}
      header={`${t('block')} 
        ${format.name(contact) || t('items.contact_one')}?`}
      content={t('cannotUndoAction')}
      confirmLabel="yesBlock"
      type="danger"
    />
  );
}

export default BlockContactDialog;
