import React from 'react';
import { SakariGlyph } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import { Link, Stack, Typography } from '@mui/joy';
import { IDable } from '@sakari-io/sakari-typings';
import Stepper from '../../../ui/molecules/Stepper';
import AppDialog from '../../../ui/molecules/AppDialog';
import Restrictions from '../Restrictions';
import OptInQR from '../OptInQR';
import PendingCard from './PendingCard';

type ButtonType = IDable & {
  onClick: () => void;
  label: string;
};

interface PftDialogProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  currentStep: IDable;
  steps: IDable[];
  buttons: ButtonType[];
}

function PftDialog({
  open,
  setOpen,
  currentStep,
  steps,
  buttons,
}: PftDialogProps) {
  const { t } = useTranslation();

  const button = buttons.find((b) => b.id === currentStep.id);

  return (
    <AppDialog
      open={open}
      setOpen={setOpen}
      size="sm"
      headerIcon={<SakariGlyph size={30} />}
      header={
        <Stack spacing={3}>
          <Typography level="h4" fontWeight={700}>
            {t('senders.register.platformFreeTrial.startTitle')}
          </Typography>

          <Stepper
            steps={steps}
            activeStep={steps.findIndex((s) => s.id === currentStep.id)}
            connectors
            activeShadow
            checkVisited
          />
        </Stack>
      }
      confirmButtonProps={
        button
          ? {
              children: button?.label,
              onClick: () => {
                buttons.find((b) => b.id === currentStep.id)?.onClick();
              },
              size: 'md',
            }
          : undefined
      }
      buttonLayout={{
        position: 'center',
        reverse: false,
        fullWidth: false,
      }}
      sx={{
        padding: '30px',
      }}
      content={
        <Stack spacing={2}>
          {currentStep.id === 'pending' ? <PendingCard /> : null}
          <Stack direction="row" spacing={2}>
            <Stack
              spacing={2}
              sx={{
                maxWidth: '250px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Restrictions />

              <>
                <Typography level="title-lg" sx={{ fontWeight: 700 }}>
                  {t('senders.register.platformFreeTrial.nextSteps.label')}
                </Typography>
                <Typography level="body-md">
                  {t(
                    `senders.register.platformFreeTrial.nextSteps.${currentStep.id}`,
                  )}{' '}
                  <Link
                    underline="always"
                    target="_blank"
                    rel="noopener"
                    sx={{
                      color: 'primary.light',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: 'primary.main',
                      },
                    }}
                    href="https://support.sakari.io/hc/en-us/articles/20314374147092"
                  >
                    {' '}
                    {t('senders.register.platformFreeTrial.learnMore')}
                  </Link>
                </Typography>
              </>
            </Stack>
            <OptInQR />
          </Stack>
        </Stack>
      }
    />
  );
}

export default PftDialog;
