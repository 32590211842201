import React from 'react';
import { Sender } from '@sakari-io/sakari-typings';
import Table from '@mui/joy/Table';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/joy/styles';
import GroupSenderRow from './GroupSenderRow';

interface GroupSendersTableProps {
  value: Sender[];
  onChange: (value: Sender[]) => any;
}

function GroupSendersTable({ value, onChange }: GroupSendersTableProps) {
  const { t } = useTranslation();

  const StyledTh = styled('th')(() => ({
    padding: '16px !important',
    borderBottom: '1px solid var(--joy-palette-divider) !important',
  }));

  return (
    <Table>
      {value && value.length > 0 && (
        <thead>
          <tr>
            <StyledTh>{t('groupSendersTable.sender')}</StyledTh>
            <StyledTh>{t('groupSendersTable.channels')}</StyledTh>
            <StyledTh>{t('groupSendersTable.status')}</StyledTh>
          </tr>
        </thead>
      )}

      <tbody>
        {value.map((sender) => (
          <tr key={sender.id}>
            <GroupSenderRow
              key={sender.id}
              value={sender}
              onRemove={(sender) =>
                onChange((value || []).filter((s) => sender.id !== s.id))
              }
            />
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default GroupSendersTable;
