import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography, Switch, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader } from '@sakari-io/sakari-components';
import _ from 'lodash';
import { daysOfWeekShort } from '../../../constants';
import { useGetOfficeHoursQuery } from '../../../api';
import CustomHours from '../../../ui/organisms/forms/CustomHours';
import Group, { GroupViewMode } from '..';
import { AccountContext } from '../../../contexts/account.context';

function OfficeHours() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const params = useParams();

  const { data, isLoading } = useGetOfficeHoursQuery(
    account && params.groupId
      ? { accountId: account.id, request: params.groupId }
      : skipToken,
  );

  const [officeHoursActive, setOfficeHoursActive] = useState(
    _.values(data?.data?.times).some((day) => !_.isEmpty(day)),
  );

  useEffect(() => {
    if (!isLoading && data?.data) {
      const hasTimes = _.values(data?.data?.times).some(
        (day) => !_.isEmpty(day),
      );
      setOfficeHoursActive(hasTimes);
    }
  }, [data?.data]);

  if (isLoading) return <Loader size={200} label="Fetching Office Hours..." />;

  return (
    <Group view={GroupViewMode.OFFICEHOURS}>
      <Box
        sx={{
          flexShrink: 1,
          backgroundColor: 'white',
          border: `1px solid ${'var(--joy-palette-neutral-outlinedBorder)'}`,
          borderRadius: '6px',
          padding: '16px 32px',
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        <Stack
          direction="row"
          padding="24px 0px"
          justifyContent="space-between"
          width="100%"
        >
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Switch
                size="md"
                checked={officeHoursActive}
                onClick={() => setOfficeHoursActive(!officeHoursActive)}
              />
              <Typography fontWeight="700">
                {t('groupsNav.officeHours.setWithAutoresponder')}
              </Typography>
            </Stack>

            <Typography>{t('groupsNav.officeHours.toggleThis')}</Typography>
          </Stack>
        </Stack>

        <Stack sx={{ display: officeHoursActive ? 'block' : 'none' }}>
          <CustomHours
            rffName="officeHours"
            days={daysOfWeekShort}
            officeHoursActive={officeHoursActive}
          />
        </Stack>
      </Box>
    </Group>
  );
}

export default OfficeHours;
