import React from 'react';
import { List } from '@mui/joy';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Account, Campaign as CampaignType } from '@sakari-io/sakari-typings';
import CampaignListItem from '../CampaignListItem';

interface CampaignsProps {
  account: Account;
  campaigns: CampaignType[];
  loadCampaigns: any;
  onSelectedCampaign: (action: string, campaign?: CampaignType) => any;
}

function CampaignList({
  account,
  campaigns,
  loadCampaigns,
  onSelectedCampaign,
}: CampaignsProps) {
  return (
    <InfiniteScroll
      scrollableTarget="campaignList"
      dataLength={campaigns.length}
      next={() => loadCampaigns()}
      hasMore
      style={{
        display: 'flex',
        overflow: 'hidden',
        zIndex: 0,
      }}
      loader={null}
    >
      <List
        sx={{
          gap: 2,
        }}
      >
        {(campaigns || []).map((campaign: CampaignType) => {
          return (
            <CampaignListItem
              key={campaign.id}
              onAction={onSelectedCampaign}
              account={account}
              campaign={campaign}
            />
          );
        })}
      </List>
    </InfiniteScroll>
  );
}

export default CampaignList;
