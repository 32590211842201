import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Stack, Typography } from '@mui/joy';
import DetailsRow from './DetailsRow';

interface MessageRowProps {
  message: any;
}

function MessageRow({ message }: MessageRowProps) {
  const { t } = useTranslation('workflows');
  return (
    <Stack gap={0.5}>
      {message && (
        <Stack sx={{ marginBottom: '6px' }}>
          <Card
            sx={{
              padding: '6px',
              backgroundColor: 'var(--joy-palette-primary-500)',
            }}
          >
            {message.media.length > 0 && (
              <img
                src="https://assets.sakari.io/images/sakari-logo-only.png"
                alt="Sakari"
                width="100%"
                height="auto"
              />
            )}
            <Typography level="body-xs" sx={{ color: 'white' }}>
              {message.message}
            </Typography>
          </Card>
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography level="body-xs" sx={{ color: 'white' }}>
              {message.created?.at}
            </Typography>
          </Stack>
        </Stack>
      )}
      <DetailsRow
        title={t('contactExecutions.segments')}
        details={message.segments}
      />
      <DetailsRow
        title={t('contactExecutions.price')}
        details={`$${message.price}`}
      />
    </Stack>
  );
}

export default MessageRow;
