// import { useRequestEmailVerificationMutation } from "../../api";
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/joy';
import { Logo, logger } from '@sakari-io/sakari-components';
import { useAuth0 } from '@auth0/auth0-react';
import { showToast } from '../../redux/reducers/toast';
import { useAppDispatch } from '../../redux';
import { useRequestEmailVerificationQuery } from '../../api';
import Auth0Template from '../../ui/templates/Auth0Template';
import LogoutLink from '../../ui/organisms/ButtonContexts/LogoutLink';

function VerifyEmail() {
  const [skip, setSkip] = useState(true);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const { user } = useAuth0();

  logger.info('auth0user', user);
  const { isSuccess, isLoading } = useRequestEmailVerificationQuery(
    email || '',
    { skip: skip || !email },
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          severity: 'success',
          message: 'Verification email has been resent',
        }),
      );
    }
  }, [isSuccess]);

  return (
    <Auth0Template>
      <Stack alignItems="center" spacing={2} maxWidth={350}>
        <Logo size={50} />

        <Typography level="h4" sx={{ textAlign: 'center' }}>
          Verify Email
        </Typography>

        <Typography sx={{ textAlign: 'center' }}>
          We've sent an email to {email}
        </Typography>

        <Typography sx={{ textAlign: 'center' }}>
          Please click on the link in the email to verify your email
        </Typography>

        {/* <Stack direction="column"> */}
        <Button
          disabled={isLoading || isSuccess}
          onClick={() => {
            setSkip(false);
          }}
        >
          Resend Verification Email
        </Button>

        <LogoutLink />
        {/* </Stack> */}
      </Stack>
    </Auth0Template>
  );
}

export default VerifyEmail;
