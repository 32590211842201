import { Typography, Modal, ModalDialog, Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface SuccessProps {
  open: boolean;
  type: 'success' | 'ticket' | 'verify';
}

function Success({ open, type }: SuccessProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const views = [
    {
      type: 'success',
      emojies: '🎉 🎊 🥳',
      heading: 'Wohoo!',
      message: t('10dlcSuccess'),
    },
    {
      type: 'ticket',
      emojies: '🎉 🎊 🥳',
      heading: 'Almost There!',
      message: t('10dlcTicket'),
    },
    {
      type: 'verify',
      emojies: '🎉 🎊 🥳',
      heading: 'Registration Pending!',
      message: t('10dlcVerify'),
    },
  ];

  const view = views.find((view) => view.type === type);

  const handleClose = () => {
    navigate('/senders');
  };

  return (
    <Modal open={open}>
      <ModalDialog
        layout="fullscreen"
        variant="solid"
        color="primary"
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: 3,
          '.MuiTypography-root': {
            color: 'white',
          },
        }}
      >
        <Typography level="h2">{view?.emojies}</Typography>
        <Typography level="h1">{view?.heading}</Typography>
        <Typography
          level="h3"
          sx={{
            maxWidth: 'clamp(300px, 80%, 800px)',
            whiteSpace: 'pre-line',
          }}
        >
          {view?.message}
        </Typography>
        <Button
          variant="soft"
          color="neutral"
          onClick={handleClose}
          sx={{
            width: 'fit-content',
          }}
        >
          {t('returnToSenders')}
        </Button>
      </ModalDialog>
    </Modal>
  );
}

export default Success;
