import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton } from '@mui/joy';
import { Lead } from '@sakari-io/sakari-typings';
import {
  GridColDef,
  GridCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { format as formatH } from '@sakari-io/sakari-common';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import ActionLeadDialog from '../../Dialogs/ActionLeadDialog';
import EmptyResult from '../../../molecules/EmptyResult';
import StatusChip from '../../ChipContexts/StatusChip';
import { useGetLeadsQuery } from '../../../../api';
import DataGrid from '../../../molecules/DataGrid';

interface Filter {
  expired?: number;
}

interface LeadsGridProps {
  accountId: string;
  filter?: Filter;
  onSelectedRowChange?: (group: Lead) => any;
}

function LeadsGrid({ accountId, filter, onSelectedRowChange }: LeadsGridProps) {
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteLead, setDeleteLead] = useState<string>('');
  const [filters, setFilters] = useState<Filter | undefined>(filter);
  const onDelete = async (values: any) => {
    setDeleteLead(values.id);
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    if (filter) {
      setFilters(filter);
    }
  }, [filter]);

  const columns: GridColDef[] = [
    {
      field: `firstName`,
      headerName: 'Name',
      headerClassName: 'gridHeaders',
      flex: 1,
      filterable: false,
      pinnable: false,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      valueGetter: (value, row) => formatH.name(row),
    },
    {
      field: 'email',
      headerName: 'Email',
      headerClassName: 'gridHeaders',
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
    },
    {
      field: 'domain',
      headerName: 'Domain',
      headerClassName: 'gridHeaders',
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
    },
    {
      field: 'created.at',
      headerName: 'Created At',
      headerClassName: 'gridHeaders',
      flex: 0.5,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (value, row) =>
        format(parseISO(row.created.at), 'MMM d, yyyy'),
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: 'gridHeaders',
      // flex: 1,
      width: 150,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <StatusChip status={params.row.status} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      resizable: false,
      getActions: (params: GridRowParams) => {
        const actions = [];
        actions.push(
          <Tooltip
            title={t('deleteItem', { item: t('partnerSettings.leads') })}
            arrow
            placement="top"
          >
            <IconButton onClick={() => onDelete(params.row)}>
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>,
        );

        return actions;
      },
    },
  ];

  if (!accountId) {
    return null;
  }

  return (
    <>
      <DataGrid
        loadingText={t('loadingItems', {
          items: t('partnerSettings.leads'),
        })}
        filter={{ accountId, request: filters }}
        columns={columns}
        renderNoData={<EmptyResult item="leads" heading="No Leads" />}
        loadData={useGetLeadsQuery}
        onSelectedRowChange={onSelectedRowChange}
      />

      <ActionLeadDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        lead={deleteLead}
      />
    </>
  );
}

export default LeadsGrid;
