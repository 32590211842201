import React, { useContext, useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/joy';
import {
  ToggleButtonGroup,
  Button,
  Loader,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import { Country, Plan } from '@sakari-io/sakari-typings';
import { findCountry } from '@sakari-io/sakari-common';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import _ from 'lodash';
import { AccountContext } from '../../../contexts/account.context';
import {
  useGetAccountPlanQuery,
  useGetPlanPricingQuery,
  useCreateCheckoutSessionMutation,
  useChangePlanMutation,
  useEnterpriseInquiryMutation,
} from '../../../api/accounts';
import { CountryAutocomplete } from '../../../ui';
import PlanCard from './PlanCard';
import PlanContent from './PlanCard/PlanContent';
import EnterpriseForm from './PlanCard/EnterpriseForm';
import PlanSlider from './PlanSlider';
import FeaturesList from '../FeaturesList';
import { handleApiMutationResponse } from '../../../utils/api';
import { useAppDispatch } from '../../../redux';
import BillingInfoDialog from './BillingInfoDialog';
import PartnerBanner from './PartnerBanner';
import PassthroughBanner from './PassthroughBanner';
import TrialContent from './PlanCard/TrialContent';
import ChangePlanDialog from './ChangePlanDialog';

function getInitialPlanType(
  isEligibleForPartner: boolean,
  currentPlan?: { name?: string },
): string {
  if (isEligibleForPartner) {
    return 'partner';
  }

  if (
    currentPlan?.name &&
    ['passthrough', 'partner'].includes(currentPlan.name)
  ) {
    return currentPlan.name;
  }

  return 'standard';
}

interface CommitRangeType {
  min: number;
  max: number;
  current: number;
}

const getCommitRange = (
  newFrequency: Plan['billingFrequency'],
  planType: string,
  currentPlan?: Plan,
): CommitRangeType => {
  const defaults: {
    [key: string]: number;
  } = {
    partner: 0,
    standard: 25,
    passthrough: 50,
  };

  const defaultMin = defaults[planType];
  const maxCommitment = currentPlan?.maxCommitment ?? 1500;
  const minCommitment = currentPlan?.minCommitment ?? defaultMin;
  const commitment = currentPlan?.commitment || defaultMin;

  if (!currentPlan || currentPlan.billingFrequency === newFrequency) {
    return {
      min: minCommitment,
      max: maxCommitment,
      current: commitment,
    };
  }
  const frequencyFactor = newFrequency === 'annual' ? 11 : 1 / 11;

  return {
    min: minCommitment * frequencyFactor,
    max: maxCommitment, // TODO: should we also multiply max by frequencyFactor?
    current: commitment * frequencyFactor,
  };
};

function PlansSelect() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // get current plan data
  const { account } = useContext(AccountContext);

  const currentPlan = account?.plan;
  const isLegacy =
    currentPlan?.name &&
    !['Trial', 'passthrough', 'partner', 'standard'].includes(currentPlan.name);

  const isEligibleForPartner = !!account?.partner?.approved;

  const [planType, setPlanType] = React.useState<string>(
    getInitialPlanType(isEligibleForPartner, currentPlan),
  );

  const [frequency, setFrequency] = React.useState<Plan['billingFrequency']>(
    currentPlan?.billingFrequency ?? 'monthly',
  );

  const commitRange = useMemo(
    () => getCommitRange(frequency, planType, currentPlan),
    [planType, frequency],
  );

  useEffect(() => {
    if (planType === 'partner') {
      setFrequency('monthly');
    }
  }, [planType]);

  const [dialog, setDialog] = React.useState<'billing' | 'summary' | null>(
    null,
  );

  const [billingInfo, setBillingInfo] = React.useState<any>();

  const [newCommitment, setNewCommitment] = React.useState<number>(
    currentPlan
      ? Math.max(currentPlan.commitment ?? 0, currentPlan.minCommitment ?? 0)
      : 0,
  );

  const debouncedCommitment = useDebouncedValue(newCommitment, 500);

  const isUpdatingCommitment = debouncedCommitment !== newCommitment;

  const [country, setCountry] = React.useState<Country>(
    findCountry(account?.defaults?.country?.code || 'US') as Country,
  );

  const isNewPlan =
    newCommitment !== commitRange.current ||
    currentPlan?.billingFrequency !== frequency ||
    currentPlan?.name !== planType;

  const { data: planData, isLoading: isLoadingPlans } = useGetAccountPlanQuery(
    account?.id ?? skipToken,
  );

  const currentPlanSegmentPrice =
    (planData?.data as any)?.pricing?.[country.code]?.sms.outbound ?? 0;

  const { data, isFetching } = useGetPlanPricingQuery(
    isNewPlan
      ? {
          plan: planType,
          country: country?.code ?? 'US',
          commitment: debouncedCommitment ?? 0,
          frequency: isEligibleForPartner ? 'monthly' : frequency,
        }
      : skipToken,
  );

  const segmentPrice = data?.data?.pricing?.[country?.code ?? 'US']?.sms ?? 0;

  useEffect(() => {
    if (!currentPlan) return;
    if (currentPlan?.billingFrequency !== frequency) {
      setNewCommitment(
        Math.max(Math.round(commitRange.current), commitRange.min),
      );
    } else if (data?.data?.minCommitment) {
      setNewCommitment(
        Math.max(currentPlan.commitment ?? 0, data?.data?.minCommitment ?? 0),
      );
    } else {
      setNewCommitment(commitRange.current);
    }
  }, [commitRange, data?.data?.minCommitment]);

  const [
    createSession,
    { isLoading: isCreatingSession, isSuccess: redirectingToSession },
  ] = useCreateCheckoutSessionMutation();

  const [updatePlan, { isLoading: isUpdatingPlan }] = useChangePlanMutation();
  const [
    sendInquiry,
    { isLoading: isSendingInquiry, isSuccess: hasSentInquiry },
  ] = useEnterpriseInquiryMutation();

  const handleChangeFrequency = (newFrequency: Plan['billingFrequency']) =>
    setFrequency((prev) => newFrequency ?? prev);

  const handleChangeButton = () => {
    if (currentPlan?.name === 'Trial') {
      setDialog('billing');
    } else {
      setDialog('summary');
    }
  };

  const handleChangePlan = () => {
    const planInfo: any = {
      name: planType,
      frequency,
      commitment: data?.data?.commitment,
      credit: data?.data?.credit,
    };

    handleApiMutationResponse(updatePlan(planInfo).unwrap(), dispatch, {
      successMessage: t('settings.billing.plans.planUpdateSuccess'),
      defaultErrorMessage: t('settings.billing.plans.planUpdateError'),
    });
  };

  const handleConfirmChange = () => {
    if (currentPlan?.name === 'Trial') {
      handleCheckout();
    } else {
      handleChangePlan();
    }
  };

  const handleSendInquiry = async (values: {
    volume: string;
    message: string;
  }) => {
    // send inquiry
    handleApiMutationResponse(sendInquiry(values).unwrap(), dispatch, {
      successMessage: t('settings.billing.plans.enterprise.dialog.success'),
      defaultErrorMessage: t('settings.billing.plans.enterprise.dialog.error'),
    });
  };

  const handleCheckout = async () => {
    if (!account?.id) {
      return Promise.resolve();
    }
    const res = await createSession({
      accountId: account.id,
      request: {
        plan: planType,
        commitment: debouncedCommitment?.toString() ?? '0',
        frequency,
        ...billingInfo,
      },
    }).unwrap();

    if (res.data) {
      window.location.href = res.data;
    }
    return res;
  };

  if (isCreatingSession || redirectingToSession) {
    return (
      <Stack
        sx={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 'modal',
          backgroundColor: 'background.body',
        }}
      >
        <Loader size={300} label={t('redirectingToCheckout')} />
      </Stack>
    );
  }

  return (
    <>
      <Stack alignItems="center" gap={4} mt={4}>
        <Box width={408}>
          {planType !== 'partner' && (
            <ToggleButtonGroup
              variant="solid"
              value={frequency}
              onChange={handleChangeFrequency}
              buttons={[
                {
                  label: t('monthly'),
                  value: 'monthly',
                  type: 'text',
                },
                !isEligibleForPartner && {
                  label: `${t('annual')} - ${t('settings.billing.plans.freeMonth')} 🤑`,
                  value: 'annual',
                  type: 'text',
                },
              ]}
              rounded
              fullWidth
            />
          )}
        </Box>
        <Typography level="h1" textColor="primary.700">
          {t('settings.billing.plans.choosePlan')}
        </Typography>
        <PlanSlider
          value={newCommitment}
          onChange={(value) => setNewCommitment(value)}
          currentCommitment={commitRange?.current ?? 0}
          min={data?.data?.minCommitment ?? commitRange.min}
          max={commitRange.max + 20}
          step={10}
        />
        <Stack direction={'row'} gap={2} alignItems={'center'} flex={1}>
          <Typography textColor="primary.700" whiteSpace="nowrap">
            {t('settings.billing.plans.sendingMessagesTo')}
          </Typography>
          <CountryAutocomplete
            size="sm"
            value={country}
            onChange={(newCountry) => {
              if (!newCountry) return;
              setCountry(newCountry as Country);
            }}
            filterOptions={(options, country) =>
              options.filter(
                (o) =>
                  o.supported &&
                  o.name
                    .toLowerCase()
                    .includes(country.inputValue.toLowerCase()),
              )
            }
            sx={{
              width: 200,
            }}
            disableClearable
            rounded
          />
        </Stack>

        {account?.partner?.approved && (
          <PartnerBanner planType={currentPlan?.name} />
        )}

        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
            width: 'clamp(300px, 80%, 960px)',
          }}
        >
          <PlanCard
            type="current"
            isLoading={isLoadingPlans}
            title={
              currentPlan?.name !== 'Trial'
                ? `${_.capitalize(t('statusType.current'))}${isLegacy ? ` - Legacy` : ''}`
                : undefined
            }
            provider={
              currentPlan?.name === 'passthrough' ? 'twilio' : undefined
            }
          >
            {currentPlan?.name === 'Trial' && account?.trialExpiry ? (
              <TrialContent expiryDate={account.trialExpiry} />
            ) : (
              <PlanContent
                frequency={currentPlan?.billingFrequency ?? 'monthly'}
                commitment={currentPlan?.commitment ?? 0}
                segmentPrice={currentPlanSegmentPrice}
                credit={currentPlan?.credit ?? 0}
              />
            )}
          </PlanCard>

          {commitRange.max < newCommitment ? (
            <PlanCard
              type="new"
              title={t('settings.billing.plans.enterprise.title')}
            >
              <EnterpriseForm
                onSubmit={handleSendInquiry}
                isLoading={isSendingInquiry}
                isSuccess={hasSentInquiry}
              />
            </PlanCard>
          ) : (
            isNewPlan && (
              <PlanCard
                type="new"
                isLoading={isFetching}
                title={_.capitalize(t('statusType.new'))}
                subtitle={
                  newCommitment < commitRange.current
                    ? t('settings.billing.plans.billedNextCycle')
                    : t('settings.billing.plans.billedToday')
                }
                provider={planType === 'passthrough' ? 'twilio' : undefined}
              >
                <PlanContent
                  frequency={frequency}
                  commitment={
                    isUpdatingCommitment
                      ? newCommitment
                      : data?.data?.commitment
                  }
                  credit={
                    isUpdatingCommitment
                      ? newCommitment
                      : data?.data?.credit || newCommitment
                  }
                  segmentPrice={segmentPrice}
                />
                <Button
                  fullWidth
                  onClick={handleChangeButton}
                  disabled={isFetching || isUpdatingCommitment}
                >
                  {t('settings.billing.plans.changePlan.title')}
                </Button>
              </PlanCard>
            )
          )}
        </Stack>
        <FeaturesList />
        {/* Switch Passthrough - Sakari Banner */}
        {currentPlan?.passThroughPlanEligible && (
          <PassthroughBanner
            planType={planType}
            isDisabled={isCreatingSession || isUpdatingPlan}
            onToggle={() => {
              setPlanType((prev) =>
                prev === 'standard' ? 'passthrough' : 'standard',
              );
            }}
          />
        )}
      </Stack>

      {/* BillingInfoModal */}
      <BillingInfoDialog
        open={dialog === 'billing'}
        onClose={(result) => {
          if (result) {
            setBillingInfo(result);
            setDialog('summary');
          } else {
            setDialog(null);
          }
        }}
        initialValues={{
          name: account?.name,
          email: account?.info?.email,
          address: account?.info?.address,
          tax: {
            type: 'unknown',
          },
        }}
        submitting={isCreatingSession}
      />

      {/* SummaryDialog */}
      <ChangePlanDialog
        open={dialog === 'summary'}
        onClose={(result) => {
          if (result) {
            handleConfirmChange();
          } else {
            setDialog(null);
          }
        }}
        frequency={frequency}
        newCommitment={newCommitment}
        isTrial={currentPlan?.name === 'Trial'}
        isUpgrading={
          commitRange?.current < newCommitment ||
          currentPlan?.billingFrequency !== frequency
        }
        nextBillingDate={account?.nextBillingDate}
      />
    </>
  );
}
export default PlansSelect;
