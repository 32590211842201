import React from 'react';
import {
  Stack,
  Typography,
  IconButton,
  Divider,
  SvgIcon,
  Sheet,
} from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import StatusAlert from '../../organisms/alerts/StatusAlert';

interface FileUploadResultProps {
  file: File;
  onRemove: (file: File) => any;
}

function FileUploadResult({ file, onRemove }: FileUploadResultProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Typography level="body-md" fontWeight="lg" textColor="text.primary">
        {t('uploadedFile')}
      </Typography>

      <Divider />

      <StatusAlert
        size="lg"
        status="warning"
        title={t('contactsMayExist')}
        message={t('overwrittenWarning')}
      />

      <Sheet
        variant="outlined"
        sx={{
          backgroundColor: 'background.level1',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '12px',
          padding: 2,
          gap: 2,
          overflow: 'hidden',
        }}
      >
        <SvgIcon color="primary">
          <FontAwesomeIcon icon={solid('file-csv')} />
        </SvgIcon>
        <Typography
          level="body-md"
          sx={{
            flex: 1,
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          title={file.name}
        >
          {file.name}
        </Typography>
        <IconButton
          onClick={() => onRemove(file)}
          size="sm"
          sx={{ opacity: 0.5 }}
        >
          <FontAwesomeIcon icon={solid('trash')} />
        </IconButton>
      </Sheet>
    </Stack>
  );
}

export default FileUploadResult;
