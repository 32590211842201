import React, { useContext, useState, useEffect } from 'react';

import { Contact, ContactFilter } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import ContactsTab from './ContactsTab';
import ContactDetails from '../../ui/organisms/drawers/ContactDetails';
import { ContactDrawerMode } from '../../types';

import Lists from '../Lists';
import CampaignDetails from '../Campaigns/CampaignDetails';
import FileUploadDrawer from '../../ui/organisms/drawers/UploadContactDrawer';
import { AccountContext } from '../../contexts/account.context';
import CustomAttributes from './CustomAttributes';

import PageHeader from '../../ui/organisms/headers/PageHeader';
import TabbedNavigation from '../../ui/organisms/headers/TabbedNavigation';

export enum ContactsViewMode {
  CONTACTS = 0,
  LISTS = 1,
  ATTRIBUTES = 2,
}

interface ContactsPageProps {
  view: ContactsViewMode;
}

function Contacts({ view }: ContactsPageProps) {
  const { t } = useTranslation();

  const { account } = useContext(AccountContext);
  const [mode, setMode] = useState<ContactDrawerMode | null>(
    ContactDrawerMode.VIEW,
  );
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const checkTab = () => {
    switch (view) {
      case ContactsViewMode.CONTACTS:
        return setSelectedTab(0);
      case ContactsViewMode.LISTS:
        return setSelectedTab(1);
      case ContactsViewMode.ATTRIBUTES:
        return setSelectedTab(2);
      default:
        return 0;
    }
  };

  const [filter, setFilter] = useState<ContactFilter>();

  const [showFileUpload, setShowFileUpload] = useState(false);

  const newContact: Contact = {
    id: 'new',
    mobile: {
      country: 'US',
      number: '',
    },
  };
  const [contact, setContact] = useState<Contact>(newContact);

  const onUploadContacts = (list?: string) => {
    setShowFileUpload(!showFileUpload);
    if (list) setFilter({ list });
  };

  const onAddContact = (list?: string) => {
    setContact(newContact);
    setMode(ContactDrawerMode.ADD);
    setDrawerOpen(true);
    if (list) setFilter({ list });
  };

  const onContactClicked = (row: Contact, mode: ContactDrawerMode) => {
    setContact(row);
    setMode(mode);
    setDrawerOpen(true);
  };

  useEffect(() => {
    checkTab();
  }, []);

  const tabs = [
    {
      value: 0,
      label: 'All Contacts',
      url: '/contacts',
    },
    {
      value: 1,
      label: 'Lists',
      url: '/lists',
    },
    {
      value: 2,
      label: 'Custom Attributes',
      url: '/attributes',
    },
  ];

  const tabUrlProvider = (tab: number) => {
    if (tab === 0) {
      return `/contacts/${ContactsViewMode.CONTACTS}`;
    }
    if (tab === 1) {
      return `/contacts/${ContactsViewMode.LISTS}`;
    }
    if (tab === 2) {
      return `/contacts/${ContactsViewMode.ATTRIBUTES}`;
    }
    return '';
  };

  return (
    <>
      <PageHeader title={t('contacts.title')} />
      <TabbedNavigation
        tabs={tabs}
        selectedTab={view}
        onTabSelected={(tab) => tabUrlProvider(tab)}
        divider
      >
        {selectedTab === 0 ? (
          <ContactsTab
            accountId={account?.id || ''}
            selectedRow={contact}
            onSelectedRowChange={(contact) =>
              onContactClicked(contact, ContactDrawerMode.VIEW)
            }
            onCreateCampaign={(filter) => {
              setFilter(filter);
              setMode(ContactDrawerMode.CAMPAIGN);
              setDrawerOpen(true);
            }}
            onUploadContacts={onUploadContacts}
            onAddContact={onAddContact}
          />
        ) : null}

        {selectedTab === 1 ? (
          <Lists
            accountId={account?.id || ''}
            onUploadContacts={onUploadContacts}
            onAddContact={onAddContact}
            onSelectedRowChange={(contact: Contact) =>
              onContactClicked(contact, ContactDrawerMode.VIEW)
            }
          />
        ) : null}

        {selectedTab === 2 ? <CustomAttributes type="contacts" /> : null}
      </TabbedNavigation>

      {mode === ContactDrawerMode.CAMPAIGN && drawerOpen && (
        <CampaignDetails
          onClose={() => {
            setMode(ContactDrawerMode.VIEW);
            setDrawerOpen(false);
          }}
          campaign={{
            id: '',
            name: '',
            filters: { list: { id: '', name: 'Selected Contact(s)', filter } },
          }}
        />
      )}

      {mode &&
        [
          ContactDrawerMode.ADD,
          ContactDrawerMode.EDIT,
          ContactDrawerMode.VIEW,
        ].includes(mode) &&
        account &&
        drawerOpen && (
          <ContactDetails
            account={account}
            contactId={contact.id}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            listId={filter?.list}
          />
        )}
      <FileUploadDrawer
        open={showFileUpload}
        onClose={() => setShowFileUpload(false)}
        listId={filter?.list}
      />
    </>
  );
}

export default Contacts;
