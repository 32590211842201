import React from 'react';
import { Box } from '@mui/joy';
import { Account, IntegrationChannel } from '@sakari-io/sakari-typings';
import _ from 'lodash';
import { useGetChannelsQuery } from '../../../../api';
import Select from '../../../atoms/inputs/Select';

interface ChannelSelectProps {
  account: Account;
  size?: 'sm' | 'md';
  value?: any;
  fullWidth?: boolean;
  onChange: (id: string) => any;
}

function ChannelSelect({
  account,
  size,
  value,
  fullWidth,
  onChange,
}: ChannelSelectProps) {
  const { data } = useGetChannelsQuery(account.id);

  const channels = data?.data
    ? _.concat(
        [{ id: '', name: 'Select Channel' } as IntegrationChannel],
        data.data.channels,
      )
    : [{ id: '', name: 'Select Channel' } as IntegrationChannel];

  const renderChannelOptions = () =>
    channels.map((channel: Pick<IntegrationChannel, 'id' | 'name'>) => {
      return {
        value: channel.id,
        label: channel.name,
      };
    });

  return (
    <Select
      size={size || 'md'}
      noGap
      options={renderChannelOptions()}
      value={value?.id}
      onChange={(evt) => {
        const channel = data?.data?.channels?.find(
          (c: IntegrationChannel) => c.id === evt,
        );
        if (channel) {
          onChange(channel);
        }
      }}
      sx={{
        maxWidth: '100%',
      }}
      fullWidth={fullWidth}
    >
      {data?.data?.channels?.length === 0 && (
        <Box textAlign="center" sx={{ marginBottom: 1, paddingX: 1 }}>
          No Channels Found
        </Box>
      )}
    </Select>
  );
}

export default ChannelSelect;
