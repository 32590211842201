import React, { useState, useRef } from 'react';
import { Select, Stack, Typography } from '@mui/joy';
import {
  WorkflowDataReference,
  WorkflowProperty,
  WorkflowNodeType,
} from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logger, InputControl } from '@sakari-io/sakari-components';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import PropertiesMenu from '../../../../PropertiesMenu';
import { iconMap } from '../../../../PropertiesMenu/utils';
import NodeMini from '../../../NodeMini';

interface PropertySelectProps {
  properties: WorkflowProperty[];
  value?: WorkflowDataReference;
  onChange: (ref?: WorkflowDataReference) => any;
  nodeType: WorkflowNodeType;
  menuComponent?: React.ComponentType<any>;
  onHighlight?: (nodeId: string) => void;
  label?: string;
  helperText?: string;
  labelInfo?: string;
  fullWidth?: boolean;
  error?: boolean;
  disabled?: boolean;
}

function PropertySelect({
  properties,
  value,
  onChange,
  nodeType,
  menuComponent,
  onHighlight,
  label,
  helperText,
  labelInfo,
  fullWidth,
  error,
  disabled,
}: PropertySelectProps) {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <InputControl
      label={label}
      helperText={helperText}
      labelInfo={labelInfo}
      fullWidth={fullWidth}
      error={error}
      disabled={disabled}
    >
      <Select
        ref={inputRef}
        value={{ label: value?.name, value }}
        onChange={logger.info}
        disabled={disabled}
        placeholder={
          <Stack direction="row" spacing={1} alignItems="center">
            {value?.type === 'output' &&
            value?.nodeId &&
            value?.nodeId !== 'trigger' ? (
              <NodeMini type={nodeType} size="sm" />
            ) : (
              <FontAwesomeIcon
                icon={
                  value?.path
                    ? iconMap[value?.path]?.icon
                    : iconMap[value?.type || '']?.icon
                }
                style={{ color: 'var(--joy-palette-primary-500)' }}
              />
            )}

            <Typography>{value?.name}</Typography>
          </Stack>
        }
        indicator={
          <FontAwesomeIcon
            icon={faChevronDown}
            onClick={() => setOpen((prev) => !prev)}
          />
        }
        onClick={() => {
          if (disabled) return;
          setOpen(true);
        }}
        slots={{
          listbox: menuComponent ?? PropertiesMenu,
        }}
        slotProps={{
          listbox: {
            open,
            onClose: () => setOpen(false),
            inputRef,
            onChange: (v: any) => onChange(v),
            properties,
          },
          button: {
            onMouseOver: () => value?.nodeId && onHighlight?.(value?.nodeId),
            onMouseOut: () => onHighlight?.(''),
          },
        }}
      />
    </InputControl>
  );
}

export default PropertySelect;
