import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonProps } from '@mui/joy/Button';
import { Account } from '@sakari-io/sakari-typings';
import { ListItemDataRow } from '../../../../types';
import OverlayPopup from '../../../templates/OverlayPopup';
import SearchableMenu from '../../../molecules/Menu/SearchableMenu';
import { useGetListsQuery } from '../../../../api';

interface AddToListMenuButtonProps
  extends Pick<
    ButtonProps,
    'color' | 'variant' | 'component' | 'disabled' | 'fullWidth'
  > {
  account: Account;
  onSelect: (value: ListItemDataRow) => void;
  size?: 'sm' | 'md' | 'lg';
}

function AddToListMenuButton({
  account,
  onSelect,
  ...rest
}: AddToListMenuButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const { data: lists } = useGetListsQuery({
    accountId: account?.id,
    request: {
      limit: 5,
      q: query,
    },
  });

  return (
    <OverlayPopup
      open={open}
      onOpenClose={setOpen}
      renderButton={(setAnchorEl, onClick) => (
        <Button
          ref={setAnchorEl}
          variant="outlined"
          color="neutral"
          endDecorator={<FontAwesomeIcon icon={solid('plus')} />}
          onClick={onClick}
          sx={{
            whiteSpace: 'nowrap',
          }}
          {...rest}
        >
          {t('addToList.label')}
        </Button>
      )}
      renderPopup={() => (
        <SearchableMenu
          onSelect={(item) => {
            onSelect(item);
            setOpen(false);
          }}
          noOptionsText={t('noAddToListMessage')}
          onQueryChanged={setQuery}
          items={lists?.data?.map((t) => ({ name: t.name, id: t.id })) || []}
          getLabel={(item: ListItemDataRow) => item.name}
          size={rest.size}
        />
      )}
    />
  );
}

export default AddToListMenuButton;
