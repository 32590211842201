import * as React from 'react';
import Box from '@mui/joy/Box';
import Checkbox from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import _ from 'lodash';
import { FormControl, FormHelperText } from '@mui/joy';

export interface CheckboxData {
  label: string | number | React.ReactElement;
  value: string;
  disabled?: boolean;
}

interface CheckboxesProps {
  label?: string;
  items: CheckboxData[];
  helperText?: string;
  value: { [key: string]: boolean };
  onChange: (v: { [key: string]: boolean }) => any;
  size?: 'sm' | 'md';
  row?: boolean;
}

function Checkboxes({
  label,
  items,
  helperText,
  value = {},
  onChange,
  size,
  row,
}: CheckboxesProps) {
  const testGetListItem = (
    evt: React.ChangeEvent<HTMLInputElement>,
    item: CheckboxData,
  ) => {
    onChange(
      _.assign({}, value, { [item.value]: evt.target.checked || false }),
    );
  };

  return (
    <FormControl>
      <Typography level="body-md" fontWeight="lg" mb={1}>
        {label}
      </Typography>
      <Box role="group">
        <List
          size={size || 'sm'}
          sx={{ display: 'flex', flexDirection: row ? 'row' : 'column' }}
        >
          {items.map((item: any) => (
            <ListItem key={item.value}>
              <Checkbox
                label={item.label || item.value}
                checked={value[item.value]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  testGetListItem(event, item)
                }
                name={item.value}
                disabled={item.disabled}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default Checkboxes;
