import React from 'react';
import { Stack, Typography } from '@mui/joy';

interface DetailsRowProps {
  title: string;
  details: any;
}

function DetailsRow({ title, details }: DetailsRowProps) {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%" gap={2}>
      <Typography level="body-xs">{title}</Typography>
      <Typography level="body-xs">{details}</Typography>
    </Stack>
  );
}

export default DetailsRow;
