import React from 'react';
import { SupportedDestinations } from '@sakari-io/sakari-typings';
import CountryChips from '../../../../../../ui/organisms/ChipContexts/CountryChips';
import RestrictionConfirmDialog from '../../../../../PlatformFreeTrial/RestrictionsConfirmDialog';

interface RestrictionCheckProps {
  value: SupportedDestinations;
  onChange: (countries: SupportedDestinations) => any;
}

function RestrictionCheck({ value, onChange }: RestrictionCheckProps) {
  const [open, setOpen] = React.useState(false);

  const handleToggleChange = (countries: SupportedDestinations) => {
    if (!value.US && countries.US) {
      setOpen(true);
    } else {
      onChange(countries);
    }
  };

  const handleConfirm = () => {
    const updatedCountries = { ...value, US: true };
    onChange(updatedCountries);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <CountryChips
        value={value}
        onChange={(countries: SupportedDestinations) =>
          handleToggleChange(countries)
        }
      />

      <RestrictionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    </>
  );
}

export default RestrictionCheck;
