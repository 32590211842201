import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';
import Dialog from '../../../../components/molecules/Dialog';

interface ActivationFailureDialogProps {
  open: boolean;
  onClose: () => void;
  message?: string;
}

function ActivationFailureDialog({
  open,
  onClose,
  message,
}: ActivationFailureDialogProps) {
  const { t } = useTranslation('workflows');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('activate.failure')}
      primaryAction={onClose}
      primaryTitle={t('activate.acknowledge')}
      color="primary"
    >
      {message && <Typography level="body-md">{message}.</Typography>}
      <Typography level="body-sm">{t('activate.failureMessage')}</Typography>
    </Dialog>
  );
}

export default ActivationFailureDialog;
