import React, { useContext, useEffect } from 'react';
import { Stack } from '@mui/joy';
import { useParams, useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Group } from '@sakari-io/sakari-typings';
import _ from 'lodash';
import { Loader, NumberWithFlag } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useAddSenderToGroupMutation,
  useGetPhoneNumberQuery,
  useRemoveSenderFromGroupMutation,
  useUpdatePhoneNumberMutation,
} from '../../../api';
import ChannelDetails from './ChannelDetails';
import SenderDetails from './SenderDetails';
import PageSection from '../../../ui/templates/PageSection';
import PageHeader from '../../../ui/organisms/headers/PageHeader';
import Container from '../../../ui/templates/Container';
import { showToast } from '../../../redux/reducers/toast';
import { useAppDispatch } from '../../../redux';
import { AccountContext } from '../../../contexts/account.context';
import SenderGroups from '../../../ui/organisms/forms/SenderGroups';
import FieldWrapper from '../../../utils/FieldWrapper';

function SenderEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { senderId } = useParams();
  const { account } = useContext(AccountContext);

  const { data, isLoading, error } = useGetPhoneNumberQuery(
    account && senderId !== 'add'
      ? {
          accountId: account?.id,
          request: senderId || '',
        }
      : skipToken,
  );
  const pn = data?.data;

  const [updatePhoneNumber, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdatePhoneNumberMutation();

  const [
    addToGroup,
    { isLoading: isAddingToGroup, isSuccess: isAddedToGroup },
  ] = useAddSenderToGroupMutation();

  const [removeFromGroup] = useRemoveSenderFromGroupMutation();

  useEffect(() => {
    if (error && 'data' in error) {
      if (error.status === 404) {
        navigate('/senders');
        dispatch(
          showToast({
            message: 'Sender not found',
            severity: 'info',
          }),
        );
      }
    }
    if (!isLoading && (!data || !account)) {
      navigate('/senders');
    }
  }, [senderId, navigate, error]);

  useEffect(() => {
    if (isAddedToGroup || isUpdated) {
      navigate('/senders');
      dispatch(
        showToast({
          message: t('updateSuccess', { item: 'Sender' }),
          severity: 'success',
        }),
      );
    }
  }, [isAddedToGroup, isUpdated]);

  const showError = (error: any) => {
    dispatch(
      showToast({
        message:
          error?.data?.error?.message ||
          error?.message ||
          t('senders.updateError'),
        severity: 'error',
      }),
    );
  };

  const updateGroupAssignment = async (selectedGroups: Group[]) => {
    if (!account || !pn) return;

    const groupsToRemove = (pn.groups || []).filter((group) => {
      return !selectedGroups.find((selectedGroup) => {
        return selectedGroup.id === group.id;
      });
    });
    const groupsToAdd = selectedGroups.filter((group) => {
      return !pn.groups?.find((currentGroup) => {
        return currentGroup.id === group.id;
      });
    });

    if (groupsToRemove.length) {
      groupsToRemove.forEach(async (group) => {
        await removeFromGroup({
          accountId: account.id,
          request: {
            senderId: pn.id,
            groupId: group.id,
          },
        }).catch((error) => {
          showError(error);
        });
      });
    }

    if (groupsToAdd.length > 0) {
      groupsToAdd.forEach(async (group) => {
        await addToGroup({
          accountId: account.id,
          request: {
            senderId: pn.id,
            groupId: group.id,
          },
        }).catch((error) => {
          showError(error);
        });
      });
    }
  };

  const formSave = (values: any) => {
    if (values.groups !== pn?.groups) {
      updateGroupAssignment(values.groups);
    } else {
      if (
        values.channels.voice.incoming.action !== 'forward' &&
        values.channels.voice.incoming.forwardTo
      ) {
        delete values.channels.voice.incoming.forwardTo;
      }
      updatePhoneNumber(values)
        .unwrap()
        .catch((err) => {
          showError(err);
        });
    }
  };

  if (isLoading || !data?.data) {
    return <Loader size={200} />;
  }

  const initialValues = {
    ...pn,
    channels: {
      sms: {
        ...pn?.channels?.sms,
      },
      voice: {
        ...pn?.channels?.voice,
        incoming: {
          action: pn?.channels?.voice?.incoming?.action || 'none',
          forwardTo: pn?.channels?.voice?.incoming?.forwardTo,
          message: pn?.channels?.voice?.incoming?.message,
          transcribe: pn?.channels?.voice?.incoming?.transcribe,
        },
      },
      whatsapp: {
        ...pn?.channels?.whatsapp,
      },
    },
  };

  return (
    <Form
      onSubmit={formSave}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      render={({
        handleSubmit,
        hasValidationErrors,
        dirty,
        values,
        submitting,
      }) => {
        return (
          <form
            style={{
              backgroundColor: `var(--joy-palette-background-level1)`,
              overflow: 'scroll',
              height: '100vh',
            }}
          >
            <Stack spacing={3}>
              <PageHeader
                title={
                  ['local', 'tollfree', 'mobile'].includes(pn?.type || '') &&
                  pn?.number ? (
                    <NumberWithFlag mobile={pn} />
                  ) : (
                    pn?.number
                  )
                }
                backAction={() => navigate('/senders')}
                ctaAction={handleSubmit}
                ctaText="Save"
                ctaDisabled={
                  !dirty || hasValidationErrors || _.isEqual(values, pn)
                }
                loading={isUpdating || isAddingToGroup || submitting}
              />

              <Container pb={1} spacing={3}>
                {pn?.channels ? (
                  <PageSection padding={0}>
                    <ChannelDetails sender={pn} />
                  </PageSection>
                ) : null}
                <Stack direction="row" spacing={3}>
                  <PageSection title={t('details')}>
                    <SenderDetails sender={pn} />
                  </PageSection>

                  <PageSection title={t('groups.title')}>
                    <FieldWrapper component={SenderGroups} name="groups" />
                  </PageSection>
                </Stack>
              </Container>
            </Stack>
          </form>
        );
      }}
    />
  );
}

export default SenderEdit;
