import {
  SakariAPIResponse,
  SearchablePaginationRequest,
  Template,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { getAccountId, buildQuery } from './common';
import { TEMPLATE_TAG } from './tags';
import { AccountIdWith } from '../types';

interface GetTemplatesRequest extends SearchablePaginationRequest {
  type?: string;
  name?: string;
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query<
      SakariAPIResponse<Template[]>,
      AccountIdWith<GetTemplatesRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/templates?${buildQuery(request)}`,
      providesTags: () => [TEMPLATE_TAG],
    }),
    saveTemplate: builder.mutation<
      SakariAPIResponse<Template>,
      Omit<Template, 'id'>
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/templates`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [TEMPLATE_TAG],
    }),
    updateTemplate: builder.mutation<
      SakariAPIResponse<Template>,
      Partial<Template> & Pick<Template, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/templates/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [TEMPLATE_TAG],
    }),
    deleteTemplate: builder.mutation<Template, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TEMPLATE_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTemplatesQuery,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useSaveTemplateMutation,
} = extendedApi;
