/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import BandwidthRtc, { RtcStream } from '@bandwidth/webrtc-browser';

const bandwidthRtc = new BandwidthRtc();

interface BandwidthProps {
  token: any;
  muted: boolean | undefined;
}

function Bandwidth({ token, muted }: BandwidthProps) {
  const [remoteStream, setRemoteStream] = useState<RtcStream>();

  useEffect(() => {
    if (token) {
      bandwidthRtc
        .connect({
          deviceToken: token,
        })
        .then(async () => {
          await bandwidthRtc.publish({
            audio: true,
            video: false,
          });
        });
    }
  }, [token]);

  useEffect(() => {
    bandwidthRtc.onStreamAvailable((rtcStream: RtcStream) => {
      setRemoteStream(rtcStream);
    });
    bandwidthRtc.onStreamUnavailable(() => {
      setRemoteStream(undefined);
      bandwidthRtc.unpublish();
    });
  });

  useEffect(() => {
    if (token) {
      bandwidthRtc.setMicEnabled(!muted);
    }
  }, [muted]);

  if (!remoteStream) {
    return <div />;
  }

  return (
    <div id="voice-bandwidth">
      {token && (
        <video
          id="video"
          playsInline
          autoPlay
          style={{ display: 'none' }}
          ref={(videoElement) => {
            if (
              videoElement &&
              remoteStream &&
              videoElement.srcObject !== remoteStream.mediaStream
            ) {
              videoElement.srcObject = remoteStream.mediaStream;
            }
          }}
        />
      )}
    </div>
  );
}

export default Bandwidth;
