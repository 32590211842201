import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useCookies } from 'react-cookie';

import { styled } from '@mui/joy/styles';
import {
  DataGridPro,
  DataGridProProps,
  GridRowSelectionModel,
  GridColumnVisibilityModel,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageSizeSelector,
  GridColDef,
  GridValidRowModel,
  gridClasses,
} from '@mui/x-data-grid-pro';

import { Box, Checkbox, IconButton, LinearProgress, Stack } from '@mui/joy';
import {
  Condition,
  IDable,
  SakariAPIResponse,
} from '@sakari-io/sakari-typings';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Loader } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import EmptyResult from '../EmptyResult';
import ColumnVisibility from './ColumnVisibility';

import { showToast } from '../../../redux/reducers/toast';
import { useAppDispatch } from '../../../redux';

const getColumnsWithVisibility = (
  columns: readonly GridColDef<any, any, any>[],
  cookie: string,
): readonly GridColDef<GridValidRowModel>[] => {
  const actionColumn = columns.find((col) => col.type === 'actions') || {
    field: 'actions',
    type: 'actions',
    headerAlign: 'right',
    align: 'right',
    pinnable: false,
    sortable: false,
    getActions: () => [],
  };

  return [
    ...columns.filter((col) => col.type !== 'actions'),
    {
      ...actionColumn,
      renderHeader: () => (
        <Box
          sx={{
            p: 1,
          }}
        >
          <ColumnVisibility
            columns={columns.filter((col) => col.type !== 'actions')}
            cookie={cookie}
          />
        </Box>
      ),
    },
  ];
};

const StyledDataGrid = styled(DataGridPro)<DataGridProProps>(({ theme }) => ({
  borderRadius: theme.radius.md,
  overflow: 'hidden',
  fontFamily: theme.fontFamily.body,
  '--DataGrid-containerBackground': theme.palette.background.surface,
  '--DataGrid-pinnedBackground': theme.palette.background.surface,
  [`& .${gridClasses.root}`]: {
    backgroundColor: theme.palette.background.surface,
  },

  [`& .${gridClasses.columnHeader}::after`]: {
    content: 'none',
  },

  // focus styling
  [`& .${gridClasses.cell}, & .${gridClasses.columnHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    '&:not(data-field="__check__")': {
      justifyContent: 'flex-start',
    },
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },

  [`& .${gridClasses['columnHeader--withLeftBorder']}, & .${gridClasses['columnHeader--withRightBorder']}`]:
    {
      border: 'none',
    },

  // row hover styling for actions
  [`& .${gridClasses.row}`]: {
    backgroundColor: theme.palette.background.surface,

    [`& .${gridClasses.row}-firstVisible`]: {
      borderTop: 'none',
    },
    [`& .${gridClasses.actionsCell}`]: {
      opacity: 0,
    },
    [`&:hover .${gridClasses.actionsCell}`]: {
      fontFamily: theme.fontFamily.body,
      opacity: 1,
    },
    [`:hover .${gridClasses['cell--pinnedRight']}`]: {
      backgroundColor: 'red',
    },
  },
  [`.${gridClasses['cell--pinnedRight']},  & .${gridClasses['filler--pinnedRight']}`]:
    {
      borderLeft: 'none',
      background: 'transparent',

      // background: `linear-gradient(to right, rgba(0, 0, 0 / 0), var(--joy-palette-background-surface) 70%)`,
    },
  [`.${gridClasses['cell--pinnedLeft']},  & .${gridClasses['filler--pinnedLeft']}`]:
    {
      borderRight: 'none',
      // background: `linear-gradient(to right, rgba(0, 0, 0 / 0), var(--joy-palette-background-surface) 70%)`,
    },
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const { totalRowCount } = apiRef.current.state.rows;
  const hasMore = totalRowCount > (page + 1) * pageSize;

  const handlePageChange = (newPage: number) => {
    apiRef.current.setPage(newPage);
  };

  return (
    <Stack
      sx={{
        flexFlow: 'row nowrap',
        justifyContent: 'flex-end',
        px: 1,
        gap: 1,
      }}
    >
      <IconButton
        size="smRound"
        disabled={page === 0}
        onClick={() => handlePageChange(page - 1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </IconButton>
      <IconButton
        size="smRound"
        disabled={!hasMore}
        onClick={() => handlePageChange(page + 1)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </IconButton>
    </Stack>
  );
}

function StyledOverlay({ children }: { children?: React.ReactNode }) {
  return (
    <Stack
      sx={{
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        cursor: 'default',
      }}
    >
      {children}
    </Stack>
  );
}

const getQuery = (filter: any, offset: number, limit: number) => {
  if (limit <= 0 || limit === 0 || filter === skipToken) {
    return skipToken;
  }

  if (filter?.request) {
    return {
      accountId: filter.accountId,
      request: _.assign(
        {
          limit,
          offset,
        },
        filter.request,
      ),
    };
  }

  return _.assign(
    {
      limit,
      offset,
    },
    filter,
  );
};

interface DataGridProps<T, F>
  extends Omit<DataGridProProps, 'sx' | 'rows' | 'onSelectionModelChange'> {
  filter?: F;
  loadData: UseQuery<any>;
  renderNoData?: React.ReactNode;
  loadingText?: string;
  onSelectedRowChange?: (row: T) => void;
  onSelectionModelChange?: (
    newSelectionModel: GridRowSelectionModel,
    attributes?: Condition,
    selectedAll?: boolean,
    selected?: T[],
  ) => void;
  selectedRow?: T;
  cookie?: string;
  showColumnVisibility?: boolean;
  autoSelect?: (data: T[]) => void;
}

function DataGrid<T extends IDable, F>({
  filter,
  renderNoData = <EmptyResult />,
  loadData,
  loadingText = 'Loading data...',
  onSelectedRowChange,
  onSelectionModelChange,
  cookie,
  columns,
  paginationModel,
  showColumnVisibility = false,
  autoSelect,
  slots,
  // disableVirtualization = true,
  ...rest
}: DataGridProps<T, F>) {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(
    paginationModel?.pageSize || -1,
  );
  const [cookies, setCookie] = useCookies([cookie || '']);

  const { data, isLoading, isFetching, isError, error } = loadData(
    getQuery(filter, (page || 0) * pageSize, pageSize + 1),
  );

  useEffect(() => {
    if (!isLoading && data && autoSelect) {
      autoSelect((data as SakariAPIResponse<T[]>)?.data);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      dispatch(
        showToast({
          message: (error as Error)?.message || 'Error loading data',
          severity: 'error',
        }),
      );
    }
  }, [isError]);

  const getCriteria = (
    data: T[],
    selectionModel: GridRowSelectionModel,
    selectedAll?: boolean,
  ): Condition => {
    if (selectedAll) {
      return {
        attribute: 'id',
        comparator: 'nin',
        value: data
          .filter(({ id }) => selectionModel.indexOf(id) < 0)
          .map(({ id }) => id),
      };
    }
    return {
      attribute: 'id',
      comparator: 'in',
      value: selectionModel.map((id) => id.toString()),
    };
  };

  const handleSelection = (newSelectionModel: GridRowSelectionModel) => {
    if (onSelectionModelChange) {
      let selectedAll;
      if (
        (data as SakariAPIResponse<T[]>).data.length ===
        newSelectionModel.length + 1
      ) {
        selectedAll = true;
      } else if (newSelectionModel.length === 0) {
        selectedAll = false;
      }

      const attributes = getCriteria(
        (data as SakariAPIResponse<T[]>).data.slice(0, pageSize),
        newSelectionModel,
        selectedAll,
      );

      const selected = (data as SakariAPIResponse<T[]>).data.filter(
        ({ id }) => newSelectionModel.indexOf(id) >= 0,
      );
      onSelectionModelChange(
        newSelectionModel,
        attributes,
        selectedAll,
        selected,
      );
    }
  };

  const rowCount =
    page * pageSize + ((data as SakariAPIResponse<T[]>)?.data?.length || 0);

  return (
    <StyledDataGrid
      slots={{
        // eslint-disable-next-line react/no-unstable-nested-components
        baseCheckbox: ({
          sx,
          touchRippleRef,
          size,
          color,
          value,
          onChange,
          ...rest
        }) => {
          return (
            <Checkbox
              value={value as any}
              onChange={(evt) => {
                if (onChange) {
                  onChange(evt, evt.target.checked);
                }
              }}
              {...rest}
            />
          );
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        noRowsOverlay: () => <StyledOverlay>{renderNoData}</StyledOverlay>,
        // eslint-disable-next-line react/no-unstable-nested-components
        noResultsOverlay: () => <StyledOverlay>{renderNoData}</StyledOverlay>,
        // eslint-disable-next-line react/no-unstable-nested-components
        loadingOverlay: () => {
          if (isLoading) {
            return (
              <StyledOverlay>
                <Loader size={200} label={loadingText} />
              </StyledOverlay>
            );
          }
          if (isFetching) {
            return <LinearProgress />;
          }
          return null;
        },
        pagination: CustomPagination,
        // eslint-disable-next-line react/no-unstable-nested-components
        detailPanelExpandIcon: () => (
          <FontAwesomeIcon
            icon={solid('caret-right')}
            style={{
              opacity: 0.5,
            }}
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        detailPanelCollapseIcon: () => (
          <FontAwesomeIcon
            icon={solid('caret-down')}
            style={{
              opacity: 0.5,
            }}
          />
        ),
        headerFilterMenu: null,
      }}
      slotProps={{
        baseTextField: {
          label: undefined,
          placeholder: 'Search...',
          sx: {
            input: {
              fontFamily: 'var(--joy-fontFamily-body)',
            },
          },
        },
      }}
      loading={isLoading || isFetching}
      rows={(data as SakariAPIResponse<T[]>)?.data?.slice(0, pageSize) || []}
      rowCount={rowCount}
      onRowClick={({ row }) =>
        onSelectedRowChange ? onSelectedRowChange(row) : null
      }
      onColumnVisibilityModelChange={(model: GridColumnVisibilityModel) => {
        if (cookie) {
          setCookie(cookie, model);
        }
      }}
      columnVisibilityModel={cookie ? cookies[cookie] : undefined}
      sortingMode="server"
      filterMode="server"
      onRowSelectionModelChange={handleSelection}
      disableRowSelectionOnClick
      keepNonExistentRowsSelected
      pagination
      paginationMode="server"
      autoPageSize
      onPaginationModelChange={({ pageSize: newPageSize, page: newPage }) => {
        setPage(newPage);
        setPageSize((prev) => {
          if (page === 0 && newPageSize < prev) {
            return prev;
          }
          return newPageSize;
        });
      }}
      columns={
        showColumnVisibility && cookie
          ? getColumnsWithVisibility(columns, cookie)
          : columns
      }
      getRowClassName={(params) => {
        if (params.id === (rest.selectedRow as T)?.id) {
          return 'Mui-selected';
        }
        if (rest?.isRowSelectable && !rest.isRowSelectable(params)) {
          return 'Mui-disabled';
        }
        return '';
      }}
      {...rest}
    />
  );
}

export default DataGrid;
