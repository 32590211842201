import React from 'react';
import {
  Button,
  IconButton,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  ListItemProps,
  Typography,
} from '@mui/joy';
import { isAfter, endOfMonth } from 'date-fns';

import { PaymentIcon } from '@sakari-io/sakari-components';
import { PaymentMethod } from '@sakari-io/sakari-typings';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

type PaymentMethodAction = 'makeDefault' | 'remove';

interface PaymentMethodItemProps extends ListItemProps {
  paymentMethod: PaymentMethod;
  loading?: boolean;
  isSelected?: boolean;
  setAction: (action: PaymentMethodAction, id: string) => void;
}

function PaymentMethodItem({
  paymentMethod,
  setAction,
  loading,
  isSelected,
  ...rest
}: PaymentMethodItemProps) {
  const { t } = useTranslation();
  const { id, last4, expiry, brand, default: isDefault } = paymentMethod;

  const expiryDate = new Date(expiry.year, expiry.month - 1);
  const isExpired = isAfter(new Date(), endOfMonth(expiryDate));

  const handleAction = (action: PaymentMethodAction) => {
    setAction(action, id);
  };

  return (
    <ListItem
      key={id}
      data-testid="payment-method-item"
      variant="outlined"
      sx={{
        boxSizing: 'border-box',
        p: 2,
        gap: 2,
        alignItems: 'flex-start',
        ...(isExpired && {
          borderColor: 'danger.500',
          backgroundColor: 'danger.50',
        }),
      }}
      {...rest}
    >
      {/* Icon */}
      <ListItemDecorator>
        <PaymentIcon brand={brand} />
      </ListItemDecorator>
      {/* Payment Method Info, Expiry, make Primary */}
      <ListItemContent
        sx={{
          gap: 1,
        }}
      >
        <Typography
          fontWeight={500}
          textColor={isExpired ? 'danger.700' : 'primary'}
        >
          {t('settings.billing.general.paymentMethods.endingIn', {
            last4,
          })}
        </Typography>
        <Typography
          level="body-md"
          mb={1}
          textColor={isExpired ? 'danger.400' : 'neutral.400'}
        >
          {t('settings.billing.general.paymentMethods.expiry')} {expiry.month}/
          {expiry.year}
        </Typography>
        {isDefault || isExpired ? (
          <Typography fontWeight={500} color={isExpired ? 'danger' : 'primary'}>
            {isDefault &&
              t('settings.billing.general.paymentMethods.yourDefaultCard')}
            {isExpired && 'Cannot be used for new transactions'}
          </Typography>
        ) : (
          <Button
            variant="outlined"
            onClick={() => handleAction('makeDefault')}
            disabled={loading && !isSelected}
            loading={loading && isSelected}
            title={t(
              'settings.billing.general.paymentMethods.makeDefault.label',
            )}
          >
            {t('settings.billing.general.paymentMethods.makeDefault.label')}
          </Button>
        )}
      </ListItemContent>
      {/* Remove Button */}
      {isDefault ? null : (
        <IconButton
          title={t('action.remove')}
          variant="plain"
          color={isExpired ? 'danger' : 'neutral'}
          onClick={() => handleAction('remove')}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faTrash} />
        </IconButton>
      )}
    </ListItem>
  );
}
export default PaymentMethodItem;
