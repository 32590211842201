import { Stack, Typography, FormHelperText, RadioGroup, Radio } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { TextField } from '../../../ui';

interface TriggerMessageProps {
  value: string;
  onChange: (value: string) => any;
  error?: boolean;
  helperText?: string;
}

function TriggerMessage({
  value,
  onChange,
  error,
  helperText,
}: TriggerMessageProps) {
  const { t } = useTranslation();
  const [type, setType] = useState<'any' | 'trigger'>(
    value ? 'trigger' : 'any',
  );

  useEffect(() => {
    if (type === 'any') {
      onChange('');
    }
  }, [type]);

  const handleOnChange = (e: any) => {
    setType(e.target.value);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        borderBottom: `1px solid ${'var(--joy-palette-neutral-outlinedBorder)'}`,
        padding: '40px',
        paddingTop: '24px',
      }}
    >
      <Typography fontWeight="bold">
        {t('groupsNav.autoResponders.triggerAutoResponder')}
      </Typography>

      <Typography level="body-md">
        {t('groupsNav.autoResponders.keyWordDirections')}
      </Typography>

      <Stack>
        <RadioGroup value={type} defaultValue="any" onChange={handleOnChange}>
          <Radio
            value="any"
            label={t('groupsNav.autoResponders.anyInboundMessage')}
          />
          <Radio
            value="trigger"
            sx={{
              '& .MuiRadio-radio': {
                position: 'relative',
                alignSelf: 'center',
              },
            }}
            label={
              <TextField
                sx={{ width: '30%' }}
                type="text"
                value={value}
                placeholder={t('groupsNav.autoResponders.enterKeyWord')}
                onChange={(val) => onChange(val)}
                onFocus={() => setType('trigger')}
              />
            }
          />
        </RadioGroup>
      </Stack>

      {helperText ? (
        <FormHelperText color={error ? 'danger' : 'neutral'}>
          {helperText}
        </FormHelperText>
      ) : null}
    </Stack>
  );
}

export default TriggerMessage;
