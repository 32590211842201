import _ from 'lodash';
import qs from 'query-string';
import {
  Contact,
  ContactFilter,
  ContactToken,
  ContactTokenParams,
  IntegrationProperty,
  UploadContactsResponse,
  List,
  SakariAPIResponse,
  SearchablePaginationRequest,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { getAccountId, buildQuery, buildGridQuery } from './common';
import { CONTACT_TAG, CONVERSATION_TAG, LIST_TAG } from './tags';
import { AttributeMapping } from '../utils/fieldValidationMapping';
import { AccountIdWith } from '../types';
import { extendedApi as listsApi } from './lists';
import { extendedApi as conversationsApi } from './conversations';

interface ContactIntegrationPropertiesParams {
  id: string;
  integration: string;
}

interface UploadContactsPayload {
  data: string;
  fieldMappings: AttributeMapping[];
  listId?: string;
  list?: string;
  hidden?: number;
}

interface SearchableContactsRequest extends SearchablePaginationRequest {
  listId?: string;
}

export const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<
      SakariAPIResponse<Contact[]>,
      AccountIdWith<SearchableContactsRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/contacts?${buildGridQuery(request)}`,
      providesTags: (result, error, arg) => [
        CONTACT_TAG, // needed to auto reload when contacts uploaded
        { type: CONTACT_TAG, id: 'LIST' },
        { type: LIST_TAG, id: arg.request.listId },
        ...(result?.data || []).map(({ id }: { id: string }) => ({
          type: CONTACT_TAG,
          id,
        })),
      ],
    }),
    getContact: builder.query<
      SakariAPIResponse<Contact>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: id }) =>
        `accounts/${accountId}/contacts/${id}`,
      providesTags: (result, error, arg) => [
        CONTACT_TAG,
        { type: CONTACT_TAG, id: arg.request },
      ],
    }),
    createContact: builder.mutation<
      SakariAPIResponse<Contact>,
      Partial<Contact> & Pick<Contact, 'id'>
    >({
      query: (contact) => ({
        url: `accounts/${getAccountId()}/contacts`,
        method: 'POST',
        body: contact,
      }),
      async onQueryStarted(contact, { dispatch, queryFulfilled, getState }) {
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: CONTACT_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (
              endpointName === 'getContacts' &&
              !originalArgs.request.listId &&
              originalArgs.request.offset === 0
            ) {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    draft.data = [...draft.data, contact];
                  },
                ),
              );
            }
          });

        try {
          const result = await queryFulfilled;
          patchResult.undo();
          extendedApi.util
            .selectInvalidatedBy(getState(), [
              { type: CONTACT_TAG, id: 'LIST' },
            ])
            .forEach(({ endpointName, originalArgs }) => {
              if (
                endpointName === 'getContacts' &&
                originalArgs.request.offset === 0
              ) {
                dispatch(
                  extendedApi.util.updateQueryData(
                    'getContacts',
                    originalArgs,
                    (draft) => {
                      const newContact = result.data.data;
                      draft.data = [...draft.data, newContact];
                    },
                  ),
                );
              }
            });
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateContact: builder.mutation<
      SakariAPIResponse<Contact>,
      Partial<Contact> & Pick<Contact, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/contacts/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => {
        const res = [
          { type: CONTACT_TAG, id: 'LIST' },
          { type: CONTACT_TAG, id: arg.id },
        ];
        return res;
      },
      async onQueryStarted(
        { id, ...data },
        { dispatch, queryFulfilled, getState },
      ) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getContact',
            { accountId: getAccountId() || '', request: id },
            (draft) => {
              draft.data = Object.assign(draft.data, data);
            },
          ),
        );

        let patchResultList: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [
            { type: CONTACT_TAG, id: 'LIST' },
            { type: CONVERSATION_TAG, id: 'LIST' },
          ])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getContacts') {
              patchResultList = dispatch(
                extendedApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    const index = draft.data.findIndex((c) => c.id === id);

                    if (index >= 0) {
                      draft.data[index] = Object.assign(
                        draft.data[index],
                        data,
                      );
                    }
                  },
                ),
              );
            }

            if (endpointName === 'getConversations') {
              patchResultList = dispatch(
                conversationsApi.util.updateQueryData(
                  'getConversations',
                  originalArgs,
                  (draft) => {
                    for (const conversation of draft.data) {
                      const { contact } = conversation;

                      if (contact && contact.id === id) {
                        conversation.contact = Object.assign(contact, data);
                      }
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchResultList?.undo();
        }
      },
    }),
    deleteContact: builder.mutation<SakariAPIResponse<Contact>, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/contacts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, error, arg) => [
        { type: CONTACT_TAG, id: arg },
        { type: CONTACT_TAG, id: 'LIST' },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: CONTACT_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getContacts') {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    const index = draft.data.findIndex((c) => c.id === id);

                    if (index >= 0) {
                      draft.data.splice(index, 1);
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),

    blockContact: builder.mutation<SakariAPIResponse<Contact>, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/contacts/${id}/block`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: (res, error, arg) => [
        CONVERSATION_TAG,
        { type: CONTACT_TAG, id: 'LIST' },
        { type: CONTACT_TAG, id: arg },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: CONTACT_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getContacts') {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    const index = draft.data.findIndex((c) => c.id === id);

                    if (index >= 0) {
                      draft.data[index].blocked = new Date().toString();
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    countContacts: builder.mutation<SakariAPIResponse<number>, ContactFilter>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/bulk/contacts/count`,
        method: 'POST',
        body: data,
      }),
    }),
    blockContacts: builder.mutation<SakariAPIResponse<number>, ContactFilter>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/bulk/contacts/block`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (res, err, args) => {
        const contacts = (args.attributes?.[0]?.value as string[])?.map(
          (id: string) => ({
            type: CONTACT_TAG,
            id,
          }),
        );
        return [
          CONTACT_TAG,
          { type: CONTACT_TAG, id: 'LIST' },
          ...(contacts || []),
        ];
      },
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        const ids = data.attributes?.[0].value as string[];
        if (!ids) return;

        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: CONTACT_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getContacts') {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    for (const id of ids) {
                      const index = draft.data.findIndex((c) => c.id === id);

                      if (index >= 0) {
                        draft.data[index].blocked = new Date().toString();
                      }
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    // downloadContacts: builder.mutation<
    //   SakariAPIResponse<string>,
    //   ContactFilter
    // >({
    //   query: (data) => ({
    //     url: `accounts/${getAccountId()}/bulk/contacts/download`,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
    deleteContacts: builder.mutation<SakariAPIResponse<number>, ContactFilter>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/bulk/contacts/delete`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (res, err, args) => {
        const contacts = (args.attributes?.[0]?.value as string[])?.map(
          (id: string) => ({
            type: CONTACT_TAG,
            id,
          }),
        );
        return [
          CONTACT_TAG,
          { type: CONTACT_TAG, id: 'LIST' },
          ...(contacts || []),
        ];
      },
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        const ids = data.attributes?.[0].value as string[];
        if (!ids) return;

        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [{ type: CONTACT_TAG, id: 'LIST' }])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getContacts') {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    for (const id of ids) {
                      const index = draft.data.findIndex((c) => c.id === id);

                      if (index >= 0) {
                        draft.data.splice(index, 1);
                      }
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    uploadContacts: builder.mutation<
      SakariAPIResponse<UploadContactsResponse>,
      UploadContactsPayload
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/contacts?${qs.stringify(
          _.pick(data, ['listId', 'list', 'hidden']),
        )}`,
        method: 'POST',
        headers: data.fieldMappings
          .filter((m) => m.column)
          .reduce(
            (res: any, m) => {
              res[`X-Sakari-Mapping-${m.attribute}`] = m.column;
              return res;
            },
            {
              'Content-type': 'text/csv',
            },
          ),
        body: data.data,
      }),
      invalidatesTags: (res, error, arg) => [
        CONTACT_TAG,
        { type: CONTACT_TAG, id: 'LIST' },
        { type: LIST_TAG, id: arg.listId },
      ],
    }),
    getContactIntegrationProperties: builder.query<
      SakariAPIResponse<IntegrationProperty[]>,
      AccountIdWith<ContactIntegrationPropertiesParams>
    >({
      query: ({ accountId, request: { id, integration } }) =>
        `accounts/${accountId}/contacts/${id}/${integration}`,
    }),
    getContactTokens: builder.query<
      SakariAPIResponse<ContactToken[]>,
      AccountIdWith<ContactTokenParams>
    >({
      query: ({ accountId, request: { id, ...params } }) =>
        `accounts/${accountId}/contacts/${id}/tokens?${buildQuery(params)}`,
    }),

    getContactLists: builder.query<
      SakariAPIResponse<List[]>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: id }) =>
        `accounts/${accountId}/contacts/${id}/lists`,
      providesTags: (result, error, arg) => [
        { type: CONTACT_TAG, id: arg.request },
      ],
    }),

    deleteContactFromList: builder.mutation<SakariAPIResponse<any>, any>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/lists/${data.listId}/contacts/${
          data.contactId
        }`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CONTACT_TAG, id: arg.contactId },
        { type: LIST_TAG, id: arg.listId },
        LIST_TAG,
      ],
      async onQueryStarted(
        { listId, contactId },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getContactLists',
            { accountId: getAccountId() || '', request: contactId },
            (draft) => {
              draft.data = draft.data.filter((c) => c.id !== listId);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    addContactToList: builder.mutation<SakariAPIResponse<any>, any>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/lists/${data.listId}/contacts/${
          data.contactId
        }`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CONTACT_TAG, id: arg.contactId },
        { type: LIST_TAG, id: arg.listId },
      ],
      async onQueryStarted(
        { listId, contactId },
        { dispatch, queryFulfilled, getState },
      ) {
        const patchResult = await dispatch(
          extendedApi.util.updateQueryData(
            'getContactLists',
            { accountId: getAccountId() || '', request: contactId },
            (draft) => {
              dispatch(
                listsApi.endpoints.getList.initiate({
                  accountId: getAccountId() || '',
                  request: listId,
                }),
              )
                .unwrap()
                .then((list) => {
                  if (
                    draft.data.find((c) => c.id === list.data?.id) ===
                      undefined &&
                    list.data
                  )
                    draft.data.push(list.data);
                });
            },
          ),
        );

        let listPatchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [
            { type: CONTACT_TAG, id: 'LIST' },
            { type: LIST_TAG, id: listId },
          ])
          .forEach(({ endpointName, originalArgs }) => {
            if (
              endpointName === 'getContacts' &&
              originalArgs.request.offset === 0 &&
              originalArgs.request.listId === listId
            ) {
              listPatchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getContacts',
                  originalArgs,
                  (draft) => {
                    dispatch(
                      extendedApi.endpoints.getContact.initiate({
                        accountId: getAccountId() || '',
                        request: contactId,
                      }),
                    )
                      .unwrap()
                      .then((contact) => {
                        if (contact.data) {
                          draft.data.unshift(contact?.data);
                        }
                      });
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          listPatchResult?.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContactsQuery,
  useGetContactQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,

  useBlockContactMutation,
  useUploadContactsMutation,

  useCountContactsMutation,
  useDeleteContactsMutation,
  useBlockContactsMutation,

  useGetContactTokensQuery,
  useGetContactIntegrationPropertiesQuery,

  useGetContactListsQuery,
  useDeleteContactFromListMutation,
  useAddContactToListMutation,
} = extendedApi;
