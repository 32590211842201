import React, { useState } from 'react';
import { Stack, Button, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import CloseAccountDialog from '../../../ui/organisms/Dialogs/CloseAccountDialog';

function CloseAccount() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <Stack gap={2}>
      <Typography level="title-lg">
        {t('settings.general.closeAccount.title')}
      </Typography>
      <Button variant="outlined" color="danger" onClick={() => setOpen(true)}>
        {t('settings.general.closeAccount.label')}
      </Button>
      <CloseAccountDialog open={open} onClose={() => setOpen(false)} />
    </Stack>
  );
}
export default CloseAccount;
