import { Typography, Stack, Button } from '@mui/joy';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { logger } from '@sakari-io/sakari-components';
import { useBuyPhoneNumbersMutation } from '../../../../../api';
import WizardSteps from '../../../../../ui/molecules/WizardSteps';
import { Option, StepSimple } from '../../../../../types/ui';
import ImportNumbers from './ImportNumbers';
import SelectIntegration from './SelectIntegration';
import { AccountContext } from '../../../../../contexts/account.context';
import { useAppDispatch } from '../../../../../hooks';
import { showToast } from '../../../../../redux/reducers/toast';

export interface ProviderNumber {
  id: string;
  sourceId: string;
  source: string;
  number: string;
  error: string;
  available: boolean;
  country: string;
}

function ImportMode() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);
  const [importNumbers, { isLoading }] = useBuyPhoneNumbersMutation();

  const [provider, setProvider] = useState<Option>({
    id: '',
    label: '',
  });

  const [numbers, setNumbers] = useState<ProviderNumber[]>([]);

  const [steps] = useState<StepSimple[]>([
    {
      id: 'integration',
      label: t('addSenders.phonenumber.integration.label'),
    },
    {
      id: 'import',
      label: t('addSenders.phonenumber.import.label'),
    },
  ]);

  const [currentStep, setCurrentStep] = useState(0);

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleSubmit = async () => {
    const selectedNumbers = numbers.filter((n) => n.available);
    const data = selectedNumbers.map((n) => ({
      number: n.number,
      provider: n.source,
      country: n.country,
    }));

    // TODO: review this when API is ready
    return importNumbers({
      type: 'local',
      provider: provider.id,
      numbers: data,
    })
      .unwrap()
      .then((res) => {
        logger.info(res);
        if (res.success) {
          navigate('/senders', {
            state: {
              addSender: {
                success: true,
                message: t('addSenders.phonenumber.import.success'),
              },
            },
          });
        } else
          dispatch(
            showToast({
              message: t('addSenders.phonenumber.import.error'),
              severity: 'error',
            }),
          );
      })
      .catch((err) => {
        navigate('/senders');
        dispatch(
          showToast({
            message: err.data?.error
              ? err.data.error.message
              : t('somethingWrong'),
            severity: 'error',
          }),
        );
      });
  };

  if (account === undefined) return null;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{}}
      // validate={validate}
      render={({ handleSubmit }) => {
        return (
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
            }}
          >
            <Stack
              direction="row"
              sx={{
                flex: '1 1 auto',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
              }}
            >
              <Stack
                sx={{
                  py: 8,
                  pl: 4,
                  pr: 2,
                  width: 224,
                  alignItems: 'center',
                }}
              >
                <WizardSteps
                  orientation="vertical"
                  steps={steps.map((s) => ({
                    ...s,
                    ...(s.id === 'integration' && {
                      subLabel: () => provider.label,
                    }),
                    ...(s.id === 'import' &&
                      numbers.length && {
                        subLabel: () =>
                          `${numbers
                            .filter((n) => n.available)
                            .length.toString()} selected`,
                      }),
                  }))}
                  activeStep={currentStep}
                  withFade
                />
              </Stack>
              <Stack
                sx={{
                  paddingY: 8,
                  paddingLeft: 8,
                  paddingRight: { md: 12, xs: 6 },
                  overflow: 'auto',
                  width: '100%',
                  height: '100%',
                }}
                spacing={2}
              >
                <Typography level="title-lg">
                  {currentStep === 0 &&
                    t('addSenders.phonenumber.integration.title')}
                  {currentStep === 1 &&
                    t('addSenders.phonenumber.import.title')}
                </Typography>
                <Stack
                  sx={{
                    maxWidth: 820,
                    flex: 1,
                  }}
                >
                  {currentStep === 0 && (
                    <SelectIntegration
                      value={provider}
                      onSelect={setProvider}
                      account={account}
                    />
                  )}
                  {currentStep === 1 && (
                    <ImportNumbers
                      provider={provider.id}
                      onSelect={setNumbers}
                      account={account}
                      isLoading={isLoading}
                    />
                  )}
                </Stack>

                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="neutral"
                    onClick={() =>
                      currentStep !== 0 ? handleBack() : history.back()
                    }
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                  <Button
                    variant="solid"
                    onClick={() => {
                      if (currentStep < steps.length - 1) {
                        return handleNext();
                      }
                      handleSubmit();
                      return null;
                    }}
                    loading={isLoading}
                    disabled={
                      (currentStep === 0 && !provider.id) ||
                      (currentStep === 1 && !numbers.length)
                    }
                  >
                    {currentStep < steps.length - 1
                      ? t('action.next')
                      : t('action.import')}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        );
      }}
    />
  );
}
export default ImportMode;
