import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Skeleton from '@mui/material/Skeleton';
import MediaThumbnail from '../MediaThumbnail';
import Helper from '../../../utils/helper';
import config from '../../../config';

interface SecureMediaProps {
  messageId: string;
  index: number;
  type: string;
  fileName: string;
}

function SecureMedia({ messageId, index, type, fileName }: SecureMediaProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<string>('');
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then((token: string) => {
      fetch(
        `${
          config.apiUrl
        }/accounts/${Helper.getAccountId()}/messages/${messageId}/media/${index}`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        },
      )
        .then((resp) => {
          return resp.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            setData(reader.result as string);
            setLoading(false);
          };
          reader.readAsDataURL(blob);
        });
    });
  }, [messageId, index]);

  if (loading) {
    return (
      <Skeleton variant="rectangular" sx={{ ml: 1 }} width={72} height={100} />
    );
  }

  return (
    <MediaThumbnail
      size={128}
      file={{
        id: `${messageId}-${index}`,
        name: fileName,
        type,
        url: data,
        isUploaded: true,
      }}
    />
  );
}

export default SecureMedia;
