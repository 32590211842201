/* eslint-disable no-console */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, COMMAND_PRIORITY_NORMAL, createCommand } from 'lexical';
import { logger } from '@sakari-io/sakari-components';
import { VariableNode } from '../nodes/VariableNode';

export const $createVariableNode = (
  id: string,
  params: string[],
  escaped: boolean,
  onHover?: (nodeId?: string) => any,
): VariableNode => {
  logger.info('createVariableNode');
  return new VariableNode(id, params, escaped, onHover);
};

export const INSERT_VARIABLE_COMMAND = createCommand('create_variable');

export default function TemplatingPlugin() {
  const [editor] = useLexicalComposerContext();

  if (!editor.hasNode(VariableNode)) {
    throw new Error(
      'TemplatingPlugin: "VariableNode" not registered on editor',
    );
  }

  editor.registerCommand(
    INSERT_VARIABLE_COMMAND,
    (payload: any) => {
      const varNode = $createVariableNode(
        payload.name,
        payload.params,
        payload.escaped,
        payload.onHover,
      );
      const selection = $getSelection();
      selection?.insertNodes([varNode]);
      return true;
    },
    COMMAND_PRIORITY_NORMAL,
  );

  return null;
}
