import React, { useState, useCallback, useEffect } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from '@mui/joy/IconButton';
import { Stack, Typography, CircularProgress } from '@mui/joy';
import _ from 'lodash';
import { logger } from '@sakari-io/sakari-components';

const getAcceptableTypes = (accept: Accept | string): Accept => {
  switch (accept) {
    case 'csv':
      return {
        'text/csv': ['.csv'],
      };
    case 'text':
      return {
        'text/*': ['.csv', '.txt', '.rtf'],
      };
    case 'image':
      return {
        'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
      };
    case 'video':
      return {
        'video/*': ['.mp4', '.mov'],
      };
    case 'pdf':
      return {
        'application/pdf': ['.pdf'],
      };
    case 'files':
      return {
        'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
        'text/*': ['.csv', '.txt', '.rtf'],
        'application/pdf': ['.pdf'],
      };

    default:
      return typeof accept !== 'string' ? accept : { '': [] };
  }
};

export interface FileUploadProps {
  maxSize?: number;
  maxFiles?: number;
  accept: Accept | string;
  onSuccess: (files: File[], data: string) => any;
  disabled?: boolean;
  isLoading?: boolean;
  onBlur?: () => void;
  error?: boolean;
  onStartUpload?: (uploadCount: number) => void;
  uploadCount?: number;
}

function FileUpload({
  accept,
  maxSize = 5,
  maxFiles = 1,
  onSuccess,
  disabled,
  isLoading,
  onBlur,
  error,
  onStartUpload,
  uploadCount,
}: FileUploadProps) {
  const { t } = useTranslation();

  const acceptedTypes = Object.values(getAcceptableTypes(accept))
    .flat()
    .join(', ');

  const [errors, setErrors] = useState<any[] | null>(null);

  const handleFileRejectionErrors = (fileRejections: any[]) => {
    if (fileRejections.length > 0) {
      // logger.info('fileRejections', fileRejections);
      const errorMsgs: string[] = fileRejections
        .map((file: any) => file.errors)
        .flat();
      // logger.info(errorMsgs);
      setErrors(_.uniqBy(errorMsgs, 'code'));

      setTimeout(() => {
        setErrors(null);
      }, 3000);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      // logger.info('isOverLimit', isOverLimit, acceptedFiles);
      const isOverLimit =
        maxFiles < fileRejections.length + acceptedFiles.length;
      if (disabled) return;
      if (fileRejections) {
        handleFileRejectionErrors(fileRejections);
      }

      if (isOverLimit) {
        setErrors([t('validation.maxUpload', { count: maxFiles })]);
        return;
      }
      if (acceptedFiles.length < 1) {
        return;
      }

      onStartUpload?.(acceptedFiles.length);

      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        switch (file.type) {
          case 'text/csv':
            reader.readAsText(file, 'UTF-8');
            break;

          case 'application/pdf':
            reader.readAsText(file, 'UTF-8');
            break;
          default:
            reader.readAsArrayBuffer(file);
        }
        reader.onabort = () => logger.info('file reading was aborted');
        reader.onerror = (evt) => logger.info('file reading has failed', evt);
        reader.onload = (evt) => {
          const selectedFiles: File[] = acceptedFiles.map((file: any) => {
            return {
              ...file,
              name: file.name,
              type: file.type,
              size: file.size,
              preview: URL.createObjectURL(file),
              id: uuidv4(),
              data: evt.target?.result,
            };
          });
          onSuccess(selectedFiles, evt.target?.result as string);
        };
      });
    },
    [],
  );

  // TODO: Handle Errors - file size too large, name too large, max files exceeded
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: getAcceptableTypes(accept),
    maxFiles,
    maxSize: maxSize * 1024 * 1024,
    disabled,
    onFileDialogOpen: () => onBlur?.(),
    onDragEnter: () => onBlur?.(),
    multiple: maxFiles > 1,
  });

  useEffect(() => {
    if (fileRejections) {
      handleFileRejectionErrors(fileRejections);
    }
  }, [fileRejections]);

  let className = '';

  if (disabled || isDragReject) {
    className = 'disabled';
  } else if (error || errors) {
    className = 'error';
  } else if (isDragActive || isFocused) {
    className = 'active';
  }

  return (
    <Stack
      sx={{
        transition: 'all 0.2s ease-in-out',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 24px',
        cursor: 'pointer',
        gap: '12px',
        border: '1px dashed ',
        borderColor: 'neutral.outlinedBorder',
        borderRadius: '12px',

        '.MuiIconButton-root': {
          backgroundColor: 'background.level2',
          outline: '8px solid var(--joy-palette-background-level1)',
          '& svg': {
            color: 'var(--joy-palette-text-secondary)',
          },
          transition: 'all 0.2s ease-in-out',

          '&:disabled': {
            backgroundColor: 'background.level2',
            '& svg': {
              color: 'var(--joy-palette-text-tertiary)',
            },
          },
        },
        '&.active, &:hover': {
          border: '1px dashed ',
          borderColor: 'primary.outlinedBorder',
          backgroundColor: 'primary.softBg',

          '& .MuiTypography-root': {
            color: 'primary.outlinedColor',
            '&:first-of-type': {
              color: 'primary.solidHoverBg',
            },
          },

          '& .MuiIconButton-root': {
            backgroundColor: 'primary.solidBg',
            outline: '8px solid var(--joy-palette-primary-softHoverBg)',

            '& svg': {
              color: 'white',
            },
          },
        },

        '&.disabled': {
          cursor: 'no-drop',
          backgroundColor: 'background.level1',
          '& .MuiTypography-root': {
            color: 'text.secondary',
            '&:first-of-type': {
              color: 'text.tertiary',
            },
          },
          '&:focus, &:hover': {
            cursor: 'no-drop',
            backgroundColor: 'divider',
            border: '1px dashed ',
            borderColor: 'neutral.outlinedBorder',
          },
        },

        '&.error, &.error:hover': {
          '&:hover': {
            backgroundColor: 'danger.softBg',
          },
          borderColor: 'danger.outlinedBorder',

          '& .MuiTypography-root ': {
            color: 'danger.outlinedColor',
            '&:first-of-type': {
              color: 'danger.solidHoverBg',
            },
          },

          '.MuiIconButton-root': {
            backgroundColor: 'danger.outlinedColor',
            outline: '8px solid var(--joy-palette-danger-softHoverBg)',
            '& svg': {
              color: 'white',
            },
          },
        },
      }}
      {...getRootProps({
        className: className,
      })}
    >
      <input
        {...getInputProps({
          disabled: isLoading || disabled || isDragReject,
        })}
      />
      {isDragReject || errors ? (
        <>
          <IconButton size="smRound" variant="solid">
            <FontAwesomeIcon icon={regular('circle-exclamation')} />
          </IconButton>
          {errors?.map((err) => (
            <Typography color="danger" fontWeight={600} key={err.code}>
              {t(`dropzone.errors.${err.code}`)}
            </Typography>
          ))}
          {/* <Typography fontWeight={500}>{errors?.map}</Typography> */}
        </>
      ) : (
        (!isDragActive || isDragAccept) && (
          <>
            {isLoading ? (
              <>
                <CircularProgress />
                <Typography color="primary">
                  {t('feedback.uploadingFile', { count: uploadCount ?? 1 })}
                  ...
                </Typography>
              </>
            ) : (
              <>
                <IconButton
                  size="smRound"
                  variant="solid"
                  disabled={disabled}
                  className={isDragActive ? 'active' : ''}
                >
                  <FontAwesomeIcon icon={regular('cloud-arrow-up')} />
                </IconButton>
                <Stack
                  sx={{
                    flexFlow: 'row nowrap',
                    gap: '0.5rem',
                  }}
                >
                  <Typography color="primary" fontWeight={500}>
                    {t('clickToUpload')}
                  </Typography>
                  <Typography color="neutral">
                    {` ${t('or')} ${t('dragAndDrop')}`}
                  </Typography>
                </Stack>
              </>
            )}
          </>
        )
      )}
      {acceptedTypes !== '' && !isLoading ? (
        <Typography level="body-md" color="neutral">
          {`${t('validation.onlyItemsSupported', {
            items: acceptedTypes,
          })} (${maxSize} MB)`}
        </Typography>
      ) : null}
    </Stack>
  );
}

export default FileUpload;
