import React, { useEffect, useState } from 'react';

import Stack from '@mui/joy/Stack';
import { logger } from '@sakari-io/sakari-components';
import { useGetSystemNotificationsQuery } from '../../../../api';
import { SystemNotification as SystemNotificationType } from '../../../../types';
import { StatusAlert } from '../../..';
import { StatusAlertProps } from '../StatusAlert';

interface SystemNotificationProps
  extends Omit<StatusAlertProps, 'title' | 'message' | 'status'> {
  notification: SystemNotificationType;
}

// TODO: Refactor to use Joy instead of Material UI
function SystemNotification({
  notification,
  sx,
  ...rest
}: SystemNotificationProps) {
  const { id, severity = 'other', title, description } = notification;
  const [visible, setVisible] = useState<boolean>(true);

  const hide = (sn: SystemNotificationType) => {
    const hiddenNotifications = JSON.parse(
      localStorage.getItem('hidden-notifications') || '[]',
    );
    hiddenNotifications.push(sn.id);
    logger.info('hiddenNotifications', hiddenNotifications);
    localStorage.setItem(
      'hidden-notifications',
      JSON.stringify(hiddenNotifications),
    );
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <StatusAlert
      id={id}
      status={severity}
      title={title}
      message={description}
      handleClose={() => hide(notification)}
      variant={rest.variant || 'solid'}
      sx={{
        '&:hover': {
          filter: 'var(--joy-shadow-hover)',
        },
        ...sx,
      }}
      {...rest}
    />
  );
}

function SystemNotifications({
  notifications,
}: {
  notifications?: SystemNotificationType[];
}) {
  const { data } = useGetSystemNotificationsQuery();
  const [notificationData, setNotificationData] = useState<
    SystemNotificationType[]
  >(notifications || []);

  useEffect(() => {
    if (data?.length) {
      setNotificationData(data);
    }
  }, [data]);

  if (!notificationData?.length) {
    return null;
  }

  const hiddenNotifications = JSON.parse(
    localStorage.getItem('hidden-notifications') || '[]',
  );

  return (
    <Stack
      sx={{
        width: '100%',
        padding: '5px',
        zIndex: '2500',
        position: 'fixed',
        opacity: '0.9',
      }}
      spacing={1}
    >
      {notificationData
        .filter((sn) => hiddenNotifications.indexOf(sn.id) < 0)
        .map((sn) => (
          <SystemNotification key={sn.id} notification={sn} />
        ))}
    </Stack>
  );
}

export default SystemNotifications;
