import _ from 'lodash';
import { io, Socket } from 'socket.io-client';
import { logger } from '@sakari-io/sakari-components';
import { getAccountId } from '../api/common';

const sockets: { [url: string]: Socket } = {};
let activeSockets = 0;

function getSocket(url: string, token: string) {
  const accountId = getAccountId();

  let socket = sockets[url];

  if (!socket && token && accountId) {
    logger.info('initializing socket connection');
    try {
      socket = io(`${url}?accountId=${accountId}`, {
        auth: {
          token,
        },
        reconnectionAttempts: 5,
      });

      if (socket) {
        socket.connect();

        socket.on('error', (error) => {
          logger.error('socket connection error:', error);
          // handle socket connection errors here
        });

        socket.on('connect', () => {
          activeSockets++;
          logger.info('socket connected');
          logger.info('active sockets', activeSockets);
        });
        socket.on('disconnect', (reason) => {
          activeSockets--;
          logger.info('socket disconnected', reason);
          logger.info('active sockets', activeSockets);
        });
      }
    } catch (error) {
      logger.info('error connecting to socket', error);
    }
  }

  if (socket && !socket?.connected) {
    socket.connect();
  }

  return socket;
}

export function disconnectSocket(url: string) {
  const socket = sockets[url];
  if (socket?.connected) {
    try {
      socket.close();
    } catch (error) {
      logger.info('error disconnecting socket', error);
    }
  }
}

export function disconnectSockets() {
  _.keys(sockets).forEach(disconnectSocket);
}

export default getSocket;
