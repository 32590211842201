import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/joy';
import { Workflow, WorkflowVersion } from '@sakari-io/sakari-typings';
import { AccountContext } from '../../../../../../contexts/account.context';
import Popper from '../../../../../../ui/templates/Popper';
import MenuItem from '../../../../../../ui/atoms/MenuItem';
import Menu from '../../../../../../ui/molecules/Menu';
import { handleApiMutationResponse } from '../../../../../../utils/api';
import {
  useDeactivateWorkflowMutation,
  useDeleteWorkflowVersionMutation,
} from '../../../../../../api';
import EditWorkflowVersion from '../../../../../../ui/organisms/Dialogs/EditWorkflowVersion';

interface VersionPopperProps {
  openPopper: boolean;
  setOpenPopper: (val: boolean) => any;
  anchorRef: any;
  workflow: Workflow;
  version: WorkflowVersion;
  setOpenEnrollExistingDialog: (val: boolean) => any;
  onVersionChange: (versionId: string) => any;
}

function VersionPopper({
  openPopper,
  setOpenPopper,
  anchorRef,
  workflow,
  version,
  setOpenEnrollExistingDialog,
  onVersionChange,
}: VersionPopperProps) {
  const { t } = useTranslation('workflows');
  const { account } = useContext(AccountContext);
  const dispatch = useDispatch();

  const [deactivateWorkflow] = useDeactivateWorkflowMutation();
  const [deleteWorkflowVersion] = useDeleteWorkflowVersionMutation();

  const onDeactivate = async () => {
    const payload = {
      accountId: account!.id,
      request: { workflowId: workflow?.id, versionId: version.id },
    };
    setOpenPopper(false);

    return handleApiMutationResponse(
      deactivateWorkflow(payload).unwrap(),
      dispatch,
      {
        successMessage: t('deactivate.success', { name: workflow.name }),
        defaultErrorMessage: t('deactivate.error'),
      },
    );
  };

  const excludeDraft = workflow?.versions?.slice(0, -1);
  const lastUpdatedVersion = _.maxBy(
    excludeDraft,
    (version: any) => new Date(version.updated?.at),
  );

  const onDeleteVersion = () => {
    const payload = {
      accountId: account!.id,
      request: { workflowId: workflow?.id, versionId: version.id },
    };
    setOpenPopper(false);

    return handleApiMutationResponse(
      deleteWorkflowVersion(payload).unwrap(),
      dispatch,
      {
        onSuccess: () => {
          if (lastUpdatedVersion) {
            onVersionChange(lastUpdatedVersion.id);
          }
        },
        successMessage: t('versions.deleteSuccess', { name: workflow.name }),
        defaultErrorMessage: t('versions.deleteError'),
      },
    );
  };

  const renderActionCard = () => {
    return (
      <>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            if (!version.active) {
              setOpenEnrollExistingDialog(true);
            } else {
              onDeactivate();
            }
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <FontAwesomeIcon
              icon={!version.active ? solid('toggle-off') : solid('toggle-on')}
              fontWeight="bold"
            />
            <Typography level="body-md">
              {!version.active
                ? t('versions.activate')
                : t('versions.deactivate')}
            </Typography>
          </Stack>
        </MenuItem>

        <EditWorkflowVersion
          workflow={workflow}
          version={version}
          component={'drawer'}
          onVersionChange={onVersionChange}
        />

        {!version.readonly &&
          workflow?.versions &&
          workflow?.versions?.length > 1 && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                onDeleteVersion();
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <FontAwesomeIcon icon={solid('trash-can')} fontWeight="bold" />
                <Typography level="body-md">{t('versions.discard')}</Typography>
              </Stack>
            </MenuItem>
          )}
      </>
    );
  };

  return (
    <Popper
      open={openPopper}
      onOpenClose={() => {
        setOpenPopper(!openPopper);
      }}
      anchorEl={anchorRef.current}
      offset={
        !version.readonly &&
        workflow?.versions &&
        workflow?.versions?.length > 1
          ? [70, -18]
          : [56, -18]
      }
      placement="left"
    >
      <Menu>{renderActionCard()}</Menu>
    </Popper>
  );
}

export default VersionPopper;
