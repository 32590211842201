import _ from 'lodash';
import {
  Invoice,
  SakariAPIResponse,
  Account,
  AccountBalance,
  Plan,
  ApiCredential,
  PaymentMethod,
  CloseAccount,
  AccountAttribute,
  SearchablePaginationRequest,
  SupportInfo,
  BillingInfo,
} from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { sakariApi } from './rtk';

import {
  DEFAULT_PAGINATION,
  getAccountId,
  buildQuery,
  buildGridQuery,
} from './common';
import {
  ACCOUNT_TAG,
  PAYMENT_METHOD_TAG,
  BALANCE_TAG,
  ATTRIBUTES_TAG,
  PROFILE_TAG,
} from './tags';
import { AccountIdWith } from '../types';
import config from '../config';

// TODO move to developer project
interface CheckoutRequest {
  plan: string;
  commitment: string;
  frequency: string;
  segmentUnderstanding: boolean;
  address: any;
  tax?: {
    id: string;
    type?: string;
  };
  name: string;
  email: string;
}

interface MutateAttributeRequest {
  name?: string;
  type?: string;
  data?: Partial<AccountAttribute>;
}

interface AttributesByTypeRequest extends SearchablePaginationRequest {
  type: string;
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    createAccount: builder.mutation<
      SakariAPIResponse<Account>,
      Partial<Account>
    >({
      query: (body) => ({
        url: `accounts?v2=1`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ACCOUNT_TAG, PROFILE_TAG],
    }),
    updateAccount: builder.mutation<
      SakariAPIResponse<Account>,
      Partial<Account>
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [ACCOUNT_TAG],
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        // update account cache
        if (!data.id) return;

        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getAccount', data.id, (draft) => {
            draft.data = { ...draft.data, ...data };
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    getAccounts: builder.query<SakariAPIResponse<Account[]>, object>({
      query: (params = DEFAULT_PAGINATION) => `accounts?${buildQuery(params)}`,
      providesTags: (result) => [
        ACCOUNT_TAG,
        ...(result?.data?.map((a) => ({ type: ACCOUNT_TAG, id: a.id })) || []),
      ],
    }),
    getAccount: builder.query<SakariAPIResponse<Account>, string>({
      query: (accountId) => `accounts/${accountId}`,
      providesTags: (result, query, args) => [
        ACCOUNT_TAG,
        { type: ACCOUNT_TAG, id: args || '' },
      ],
    }),
    getBalance: builder.query<SakariAPIResponse<AccountBalance>, string>({
      query: (id) => ({
        url: `accounts/${id}/balance`,
        method: 'GET',
      }),
      providesTags: [BALANCE_TAG],
    }),
    getAccountPlan: builder.query<SakariAPIResponse<Plan>, string>({
      query: (accountId) => `accounts/${accountId}/plan`,
    }),
    getAccountPlans: builder.query<
      SakariAPIResponse<Plan[]>,
      AccountIdWith<SearchablePaginationRequest | { frequency: string }>
    >({
      query: ({
        accountId,
        request = { frequency: 'monthly', DEFAULT_PAGINATION },
      }) => `accounts/${accountId}/plans?${buildQuery(request)}`,
    }),
    createCheckoutSession: builder.mutation<
      SakariAPIResponse<string>,
      AccountIdWith<CheckoutRequest>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/checkout?${buildQuery({
          success_url: `${config.baseUrl}/billing?success=1`,
          cancel_url: `${config.baseUrl}/billing?cancel=1`,
        })}`,
        method: 'POST',
        body: request,
      }),
    }),
    getPaymentMethods: builder.query<
      SakariAPIResponse<PaymentMethod[]>,
      string
    >({
      query: (accountId) => `accounts/${accountId}/paymentmethods`,
      providesTags: [PAYMENT_METHOD_TAG],
    }),
    addPaymentMethod: builder.mutation<SakariAPIResponse<string>, null>({
      query: () => ({
        url: `accounts/${getAccountId()}/paymentmethods?${buildQuery({
          success_url: `${config.baseUrl}/billing`,
          cancel_url: `${config.baseUrl}/billing`,
        })}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [PAYMENT_METHOD_TAG],
    }),
    makePaymentMethodDefault: builder.mutation<
      SakariAPIResponse<PaymentMethod>,
      Pick<PaymentMethod, 'id'>
    >({
      query: ({ id }) => ({
        url: `accounts/${getAccountId()}/paymentmethods/${id}/default`,
        method: 'PUT',
      }),
      invalidatesTags: [PAYMENT_METHOD_TAG],
    }),
    deletePaymentMethod: builder.mutation<
      SakariAPIResponse<PaymentMethod>,
      string
    >({
      query: (paymentId) => ({
        url: `accounts/${getAccountId()}/paymentmethods/${paymentId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: [PAYMENT_METHOD_TAG],
    }),
    getPlanPricing: builder.query<
      SakariAPIResponse<any>,
      {
        plan: string;
        country: string;
        commitment: number;
        credit?: number;
        frequency: Plan['billingFrequency'];
      }
    >({
      query: (args) => `prices?${buildQuery(args)}`,
    }),
    changePlan: builder.mutation<
      SakariAPIResponse<Plan>,
      {
        name: string;
        frequency: 'monthly' | 'annual';
        commitment: number;
        credit: number;
      }
    >({
      query: (args) => ({
        url: `accounts/${getAccountId()}/plan`,
        method: 'PUT',
        body: args,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult: any;

        try {
          patchResult = dispatch(
            extendedApi.util.updateQueryData(
              'getAccount',
              getAccountId() ?? ' ',
              (draft) => {
                if (draft.data) {
                  const newPlan = {
                    ...draft.data.plan,
                    name: args.name,
                    billingFrequency: args.frequency,
                    commitment: args.commitment,
                    credit: args.credit,
                  };
                  draft.data.plan = newPlan;
                }
              },
            ),
          );

          const { data } = await queryFulfilled;

          dispatch(
            extendedApi.util.updateQueryData(
              'getAccount',
              getAccountId() ?? ' ',
              (draft) => {
                if (draft.data) {
                  draft.data.plan = data.data;
                }
              },
            ),
          );
          dispatch(
            extendedApi.util.updateQueryData(
              'getAccountPlan',
              getAccountId() ?? ' ',
              (draft) => {
                if (draft.data) {
                  draft.data = data.data;
                }
              },
            ),
          );
        } catch (err) {
          logger.error('error updating plan in cache', err);
          patchResult.undo();
        }
      },
    }),
    recordSegmentUnderstanding: builder.mutation<
      SakariAPIResponse<boolean>,
      null
    >({
      query: () => ({
        url: `accounts/${getAccountId()}/segmentunderstanding`,
        method: 'POST',
      }),
      invalidatesTags: [ACCOUNT_TAG],
    }),
    enterpriseInquiry: builder.mutation<
      SakariAPIResponse<boolean>,
      { volume: string; message: string }
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/plan/enterprise`,
        method: 'POST',
        body: data,
      }),
    }),
    getInvoices: builder.query<
      SakariAPIResponse<Invoice[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/invoices?${buildGridQuery(request)}`,
    }),
    getInvoiceItems: builder.query<
      SakariAPIResponse<any[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId }) => `accounts/${accountId}/invoiceitems`,
    }),
    refreshApiCredentials: builder.mutation<
      SakariAPIResponse<ApiCredential>,
      null
    >({
      query: () => ({
        url: `accounts/${getAccountId()}/credentials`,
        method: 'POST',
        body: {},
      }),
    }),
    closeAccount: builder.mutation<
      SakariAPIResponse<Account[]>,
      Partial<CloseAccount>
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/close`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [ACCOUNT_TAG],
    }),
    topUpCredit: builder.mutation<
      SakariAPIResponse<boolean>,
      {
        amount: number;
      }
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/topup`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [BALANCE_TAG],
    }),
    getCannyToken: builder.query<SakariAPIResponse<string>, string>({
      query: (id) => ({
        url: `accounts/${id}/canny/token`,
        method: 'GET',
      }),
    }),

    getAttributes: builder.query<
      SakariAPIResponse<AccountAttribute[]>,
      AccountIdWith<object | undefined>
    >({
      query: ({ accountId }) => `accounts/${accountId}/attributes`,
      providesTags: [ATTRIBUTES_TAG],
    }),

    getAttributesByType: builder.query<
      SakariAPIResponse<AccountAttribute[]>,
      AccountIdWith<AttributesByTypeRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/attributes/${request.type}?${buildGridQuery(
          _.omit(request, ['type']),
        )}`,
      providesTags: [ATTRIBUTES_TAG],
    }),

    getAttribute: builder.query<
      SakariAPIResponse<AccountAttribute>,
      AccountIdWith<{ type: string; attributeId: string }>
    >({
      query: ({ accountId, request: { type, attributeId } }) =>
        `accounts/${accountId}/attributes/${type}/${attributeId}`,
      providesTags: [ATTRIBUTES_TAG],
    }),

    deleteAttribute: builder.mutation<
      SakariAPIResponse<AccountAttribute>,
      MutateAttributeRequest
    >({
      query: ({ name, type }) => ({
        url: `accounts/${getAccountId()}/attributes/${type}/${name}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ATTRIBUTES_TAG],
    }),

    createAttribute: builder.mutation<
      SakariAPIResponse<AccountAttribute>,
      MutateAttributeRequest
    >({
      query: ({ data, type }) => ({
        url: `accounts/${getAccountId()}/attributes/${type}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [ATTRIBUTES_TAG],
    }),

    updateAttribute: builder.mutation<
      SakariAPIResponse<AccountAttribute>,
      MutateAttributeRequest
    >({
      query: ({ name, data, type }) => ({
        url: `accounts/${getAccountId()}/attributes/${type}/${name}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [ATTRIBUTES_TAG],
    }),
    updateSupportInfo: builder.mutation<
      SakariAPIResponse<Account>,
      AccountIdWith<SupportInfo>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/supportinfo`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: [ACCOUNT_TAG],
    }),
    updateBillingInfo: builder.mutation<
      SakariAPIResponse<Account>,
      AccountIdWith<BillingInfo>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/billing`,
        method: 'PUT',
        body: request,
      }),
      onQueryStarted: async (
        { accountId, request },
        { dispatch, queryFulfilled },
      ) => {
        // update business info on account cache
        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getAccount', accountId, (draft) => {
            draft.data.billing = request;
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
      // invalidatesTags: [ACCOUNT_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useGetAccountsQuery,
  useGetAccountQuery,
  useGetBalanceQuery,
  useGetAccountPlanQuery,
  useGetAccountPlansQuery,
  useCreateCheckoutSessionMutation,
  useGetPaymentMethodsQuery,
  useAddPaymentMethodMutation,
  useMakePaymentMethodDefaultMutation,
  useDeletePaymentMethodMutation,
  useGetPlanPricingQuery,
  useChangePlanMutation,
  useRecordSegmentUnderstandingMutation,
  useEnterpriseInquiryMutation,
  useGetInvoicesQuery,
  useGetInvoiceItemsQuery,
  useRefreshApiCredentialsMutation,
  useCloseAccountMutation,
  useTopUpCreditMutation,
  useGetCannyTokenQuery,
  useGetAttributesQuery,
  useGetAttributesByTypeQuery,
  useGetAttributeQuery,
  useDeleteAttributeMutation,
  useCreateAttributeMutation,
  useUpdateAttributeMutation,
  useUpdateSupportInfoMutation,
  useUpdateBillingInfoMutation,
} = extendedApi;
