import React from 'react';
import { getBezierPath } from 'reactflow';

interface CustomConnectionLineProps {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  connectionLineStyle?: any;
}

function CustomConnectionLine({
  fromX,
  fromY,
  toX,
  toY,
  connectionLineStyle,
}: CustomConnectionLineProps) {
  const [edgePath] = getBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY,
  });

  const lineStyle = {
    ...connectionLineStyle,
    stroke: 'var(--joy-palette-primary-500)',
    strokeWidth: 2,
    strokeDasharray: '5, 5',
  };

  const circleStyle = {
    fill: 'var(--joy-palette-primary-500)',
    stroke: 'var(--joy-palette-primary-500)',
    strokeWidth: 1.5,
  };

  return (
    <g>
      <path style={lineStyle} fill="none" d={edgePath} />
      <circle cx={toX} cy={toY} r={3} style={circleStyle} />
    </g>
  );
}

export default CustomConnectionLine;
