import React, { useEffect } from 'react';
import AlertBase from '@mui/joy/Alert';
import { Typography, Stack, Button, Link } from '@mui/joy';
import { Account, PhoneNumber } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PftDialog from '../PftDialog';

interface PlatformFreeTrialProps {
  account: Account;
  showPft: boolean;
  phoneNumbers: PhoneNumber[];
}

function PlatformFreeTrial({
  account,
  showPft,
  phoneNumbers,
}: PlatformFreeTrialProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submittedCampaign = phoneNumbers.find(
    (number) =>
      number?.channels?.sms?.regulatory?.campaign?.id &&
      !['CCLISUC', 'C6ALDIQ'].includes(
        number?.channels?.sms?.regulatory?.campaign?.id,
      ),
  );

  const isTrial: boolean = account?.plan.name === 'Trial';

  const [open, setOpen] = React.useState(false);

  const steps = [
    {
      id: 'upgrade',
      label: t('action.upgrade'),
      description: t('senders.register.platformFreeTrial.sendingLimit'),
    },
    {
      id: '10dlc',
      label: t('senders.register.submitRegistration'),
      description: t('senders.register.platformFreeTrial.10dlcInfo'),
    },
    {
      id: 'pending',
      label: t('senders.register.waitForApproval'),
      description: t('senders.register.platformFreeTrial.pendingInfo'),
    },
    {
      id: 'approved',
      label: t('regulatory.headers.approved'),
      description: '',
    },
  ];

  const buttons = [
    {
      id: 'upgrade',
      label: t('action.upgrade'),
      onClick: () => {
        navigate('/billing');
      },
    },
    {
      id: '10dlc',
      label: t('senders.register.platformFreeTrial.submit10dlc'),
      onClick: () => {
        navigate('/senders');
      },
    },
  ];

  const [currentStep, setCurrentStep] = React.useState<any>(steps[0]);

  useEffect(() => {
    if (account && showPft) {
      if (submittedCampaign) {
        setCurrentStep(steps[2]);
      } else if (isTrial) {
        setCurrentStep(steps[0]);
      } else {
        setCurrentStep(steps[1]);
      }
    }
  }, [account, isTrial, showPft, submittedCampaign]);

  const button = buttons.find((button) => button.id === currentStep.id);

  const renderBannerTitle = () => {
    if (isTrial) return null;
    if (!isTrial && currentStep?.id !== 'pending') {
      return t('senders.register.platformFreeTrial.getA10dlcNumber');
    }
    if (currentStep?.id === 'pending') {
      return t('senders.register.platformFreeTrial.yourNumberIsPending');
    }
    return null;
  };

  return (
    <>
      <AlertBase
        variant="solid"
        color="warning"
        sx={{
          display: showPft ? 'flex' : 'none',
          flexGrow: 1,
          width: '100%',
          borderRadius: 'inherit',
          alignItems: 'center',
          cursor: 'pointer',
          height: '50px',
          '.MuiTypography-root:first-of-type': {
            paddingBlock: 0,
          },
          '&:hover': {
            '& .MuiTypography-root, & .MuiLink-root': {
              color: 'var(--joy-palette-warning-solidColor)',
            },
            backgroundColor: 'var(--joy-palette-warning-solidHoverBg)',
          },
        }}
        onClick={() => setOpen(true)}
        startDecorator={
          <Stack
            direction="row"
            spacing={1}
            alignItems="baseline"
            sx={{ marginLeft: '10px' }}
          >
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              {renderBannerTitle()}
            </Typography>

            {/* <Typography
              level="body-sm"
              sx={{ color: 'var(--joy-palette-primary-600)' }}
            >
              {trialDaysLeft}
            </Typography> */}
          </Stack>
        }
        endDecorator={
          button ? (
            <Button
              color="warning"
              variant="outlined"
              onClick={(e) => {
                button?.onClick();
                e.stopPropagation();
              }}
            >
              {button?.label}
            </Button>
          ) : undefined
        }
      >
        <Stack
          sx={{ width: '100%' }}
          alignItems="center"
          justifyContent="center"
          direction="row"
          spacing={1}
        >
          <Typography level="body-md">{currentStep?.description}</Typography>

          <Link
            color="warning"
            level="body-md"
            underline="always"
            sx={{
              color: 'text.primary',

              fontWeight: 500,
              '&:hover': {
                fontWeight: 700,
              },
            }}
            href="https://support.sakari.io/hc/en-us/articles/20314374147092"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {t('senders.register.platformFreeTrial.learnMore')}
          </Link>
        </Stack>
      </AlertBase>

      <PftDialog
        open={open}
        setOpen={setOpen}
        currentStep={currentStep}
        steps={steps}
        buttons={buttons}
      />
    </>
  );
}

export default PlatformFreeTrial;
