import React from 'react';
import {
  Typography,
  Stack,
  Card,
  CardContent,
  AspectRatio,
  Button,
  Chip,
} from '@mui/joy';
import { AvailableIntegration } from '@sakari-io/sakari-typings';
import { getIntegrationIcon } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';

import config from '../../../../config';

interface IntegrationCardItemProps {
  integration: AvailableIntegration;
  onClick: (integration: AvailableIntegration) => any;
}

function IntegrationCardItem({
  integration,
  onClick,
}: IntegrationCardItemProps) {
  const { t } = useTranslation();
  const { name, description, categories } = integration;
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 'md',
        border: `1px solid ${'var(--joy-palette-neutral-outlinedBorder)'}`,
        gap: 2,
      }}
      orientation="horizontal"
    >
      <AspectRatio
        variant="outlined"
        ratio="1"
        sx={{
          height: '100%',
          minWidth: 128,
          backgroundColor: 'transparent',
          borderRadius: '20px',
        }}
      >
        <img
          src={getIntegrationIcon(config.stage, integration.id, 128)}
          alt={integration.name}
          style={{ objectFit: 'cover' }}
        />
      </AspectRatio>
      <CardContent
        sx={{
          gap: 2,
          flex: 1,
          overflow: 'auto',
        }}
      >
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {name}
        </Typography>
        <Typography
          level="body-md"
          sx={{
            flex: 1,
            alignContent: 'flex-start ',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            color: 'var(--joy-palette-neutral)',
            fontWeight: 400,
          }}
        >
          {description}
        </Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Stack
            sx={{
              flexFlow: 'row wrap',
              gap: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {(categories || []).map((category) => (
              <Chip key={category} color="neutral" size="sm">
                {t(`integrations.categories.${category}`)}
              </Chip>
            ))}
          </Stack>
          <Button size="sm" onClick={() => onClick(integration)}>
            {t('action.view')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default IntegrationCardItem;
