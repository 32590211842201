/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/joy';
import { Workflow, WorkflowVersion } from '@sakari-io/sakari-typings';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import Dialog from '../../../../components/molecules/Dialog';
import Select from '../../../atoms/inputs/Select';
import Radios from '../../../molecules/Radios';
import { actions } from '../../../../redux/reducers/workflow';
import { useDuplicateWorkflowVersionMutation } from '../../../../api';
import { handleApiMutationResponse } from '../../../../utils/api';
import { AccountContext } from '../../../../contexts/account.context';

interface EditWorkflowVersionProps {
  workflow: Workflow;
  version: WorkflowVersion;
  component: string;
  onVersionChange: (versionId: string) => any;
}

function EditWorkflowVersion({
  workflow,
  version,
  component,
  onVersionChange,
}: EditWorkflowVersionProps) {
  const { t } = useTranslation('workflows');
  const dispatch = useDispatch();
  const { account, userRole } = useContext(AccountContext);

  const [open, setOpen] = useState<any>(false);
  const [behavior, setBehavior] = useState<string>('editDraft');
  const [versionId, setVersionId] = useState<string>(version.id);
  const [hasDraft, setHasDraft] = useState<boolean>();

  const options = [
    {
      label: t(`versions.draftInProgress`),
      value: 'editDraft',
    },
    {
      label: t(`versions.overwrite`),
      value: 'overwrite',
    },
  ];

  const versions = workflow.versions || [];
  const draft = versions.find((vers) => !vers.readonly);

  useEffect(() => {
    setHasDraft(
      _.some(workflow.versions, (version) => version.readonly !== true),
    );
  }, [versions]);

  useEffect(() => {
    if (behavior === 'overwrite') setVersionId(versionId ?? '');
  }, [behavior]);

  const primaryAction = () => {
    if (behavior === 'editDraft' && draft) {
      onVersionChange(draft.id);
      dispatch(actions.enterEditMode());
      setOpen(false);
    } else {
      handleEditWorkflow();
    }
  };

  const editVersion = () => {
    if (version.readonly) {
      if (!hasDraft) {
        handleEditWorkflow();
        return;
      }
      setOpen(true);
      return;
    }

    if (!version.readonly && hasDraft) {
      dispatch(actions.enterEditMode());
    }
  };

  const [duplicateWorkflowVersion, { isLoading: isLoadingDuplicate }] =
    useDuplicateWorkflowVersionMutation();

  const handleEditWorkflow = async () => {
    return handleApiMutationResponse(
      duplicateWorkflowVersion({
        accountId: account?.id ?? '',
        request: {
          workflowId: workflow.id,
          versionId,
          discardDraft: true,
        },
      }).unwrap(),
      dispatch,
      {
        onSuccess: (result) => {
          onVersionChange(result.data.id);
        },
        defaultErrorMessage: t('versions.editError'),
      },
    ).then(() => {
      if (version.readonly && hasDraft) {
        setOpen(false);
      }
      dispatch(actions.enterEditMode());
    });
  };

  const disabledPermission = userRole === 'ReadOnly' || userRole === 'Sender';

  return (
    <>
      {component === 'header' ? (
        <Tooltip
          title={disabledPermission ? t('readOnly') : t('edit.editWorkflow')}
          arrow
          placement="top"
        >
          <span>
            <IconButton
              variant="outlined"
              sx={{
                borderRadius: 'var(--joy-radius-md)',
              }}
              onClick={() => editVersion()}
              disabled={disabledPermission}
            >
              <FontAwesomeIcon icon={faPencil} />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            editVersion();
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <FontAwesomeIcon icon={solid('sitemap')} fontWeight="bold" />
            <Typography level="body-md">{t('versions.edit')}</Typography>
          </Stack>
        </MenuItem>
      )}
      <div onClick={(e) => e.stopPropagation()}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          title={t(`versions.edit`)}
          disablePrimary={!behavior}
          submitting={isLoadingDuplicate}
          primaryAction={() => primaryAction()}
          primaryTitle={t(`versions.confirm`)}
          cancelTitle={t(`cancel`)}
          cancelAction={() => setOpen(false)}
          color="primary"
        >
          <Typography level="body-sm">{t(`versions.editVersion`)}</Typography>
          <Radios items={options} value={behavior} onChange={setBehavior} />

          <Stack spacing={1}>
            <Select
              options={(versions || [])
                .filter((version: any) => version.readonly)
                .map((version: any) => {
                  return {
                    label: version.name,
                    value: version.id,
                  };
                })}
              fullWidth
              value={versionId}
              placeholder={t('edit.delete.selectBranch')}
              onChange={setVersionId}
              disabled={behavior !== 'overwrite'}
              required={behavior === 'overwrite'}
            />
          </Stack>
        </Dialog>
      </div>
    </>
  );
}

export default EditWorkflowVersion;
