import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { PhoneNumber } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { Logo, VerificationIcon } from '@sakari-io/sakari-components';
import _ from 'lodash';
import StatusChip from '../../../../ui/organisms/ChipContexts/StatusChip';

interface SenderDetailsProps {
  sender?: PhoneNumber;
}

function SenderDetails({ sender }: SenderDetailsProps) {
  const { t } = useTranslation();

  if (!sender) return null;

  const { type, provider, status, channels } = sender;

  const regulatory = channels?.sms?.regulatory;

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography>Status</Typography>
        <Typography level="body-md">
          <StatusChip status={status} />
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography>Type</Typography>
        <Typography
          level="body-md"
          endDecorator={
            regulatory?.submitted ? (
              <VerificationIcon
                submitted={
                  regulatory?.submitted ||
                  format(parseISO(new Date().toISOString()), 'd MMM yyyy')
                }
                verified={regulatory?.approved}
              />
            ) : null
          }
        >
          {t(`phonenumbers.types.${type}`)}
        </Typography>
      </Stack>
      {regulatory?.campaign?.useCase ? (
        <Stack spacing={1}>
          <Typography>Use Case</Typography>
          <Typography level="body-md">{regulatory.campaign.useCase}</Typography>
        </Stack>
      ) : null}
      {provider ? (
        <Stack spacing={1}>
          <Typography>Provider</Typography>
          <Typography
            level="body-md"
            endDecorator={<Logo provider={provider} size={30} />}
          >
            {_.capitalize(provider)}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
}

export default SenderDetails;
