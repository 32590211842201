import React, { useState } from 'react';
import {
  Contact,
  WorkflowExecution,
  Workflow as WorkflowType,
} from '@sakari-io/sakari-typings';
import Execution from './Execution';
import ContactsList from './ContactsList';
import VersionsList from './VersionsList';

interface ContactDetailsProps {
  workflow: WorkflowType;
}

export enum ContactDrawerStep {
  Contacts = 'contacts',
  Versions = 'versions',
  Executions = 'executions',
}

function ContactDetails({ workflow }: ContactDetailsProps) {
  const [currentPage, setCurrentPage] = useState(ContactDrawerStep.Contacts);
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [execution, setExecution] = useState<WorkflowExecution>();

  const handleContactClick = (contact: Contact) => {
    setSelectedContact(contact);
    setCurrentPage(ContactDrawerStep.Versions);
  };

  const handleExecutionClick = (ex: WorkflowExecution) => {
    setExecution(ex);
    setCurrentPage(ContactDrawerStep.Executions);
  };

  const handleBackToContacts = () => {
    setCurrentPage(ContactDrawerStep.Contacts);
  };

  const handleBackToJourneys = () => {
    setCurrentPage(ContactDrawerStep.Versions);
  };

  return (
    <>
      {currentPage === ContactDrawerStep.Contacts && (
        <ContactsList onClick={handleContactClick} />
      )}
      {selectedContact && currentPage === ContactDrawerStep.Versions && (
        <VersionsList
          workflow={workflow}
          contact={selectedContact}
          onExecutionClick={handleExecutionClick}
          onBack={handleBackToContacts}
        />
      )}
      {selectedContact &&
        execution &&
        currentPage === ContactDrawerStep.Executions && (
          <Execution
            workflow={workflow}
            onBack={handleBackToJourneys}
            contact={selectedContact}
            value={execution}
            setCurrentPage={setCurrentPage}
          />
        )}
    </>
  );
}

export default ContactDetails;
