import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { parseHandlebars } from '../utils/handlebars-parser';

export default function FormPlugin({
  value,
}: {
  value?: string;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (value && editor) {
      const parsed = parseHandlebars(value);
      const initialEditorState = editor.parseEditorState(
        JSON.stringify({ root: parsed }),
      );
      editor.setEditorState(initialEditorState);
    }
  }, [value, editor]);

  return null;
}
