/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { logger } from '@sakari-io/sakari-components';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { styled } from '@mui/joy';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { parseHandlebars } from '../utils/handlebars-parser';

const StyledEditor = styled(ContentEditable)(() => ({
  position: 'relative',
  flex: 1,
  width: '100%',
  overflow: 'auto',
  outline: 'none',
  p: {
    margin: 0,
  },
}));

function JoyPlainTextPlugin({
  value,
  placeholder,
  rowRange = [1, 5],
  ...props
}: any) {
  const [editor] = useLexicalComposerContext();
  const content = parseHandlebars(value ?? '');

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const state = editor.getEditorState();
    logger.info('PlainTextPlugin', state);
  };

  useEffect(() => {
    if (value && editor) {
      const initialEditorState = editor.parseEditorState(
        JSON.stringify({ root: content }),
      );
      editor.setEditorState(initialEditorState);
    }
  }, [editor, content]);

  return (
    <PlainTextPlugin
      {...props}
      contentEditable={
        <StyledEditor
          style={{
            minHeight: `${rowRange[0] * 1.5}rem`,
            maxHeight: `${rowRange[1] * 1.5}rem`,
          }}
        />
      }
      placeholder={<div />}
      ErrorBoundary={LexicalErrorBoundary}
    />
  );
}
export default JoyPlainTextPlugin;
