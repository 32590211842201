import { addYears } from 'date-fns';
import { Contact, CreateConversation, User } from '@sakari-io/sakari-typings';
import { format } from '@sakari-io/sakari-common';

const createConversation = (
  user?: User,
  contact?: Contact,
  type?: string,
): CreateConversation => {
  return {
    // contact: {},
    id: 'new',
    contact,
    closed: addYears(new Date(), 1).toISOString(),
    lastMessage: {
      id: '',
      status: 'new',
      message: `New message`,
      created: {
        at: new Date().toISOString(),
        by: {
          name: user ? format.name(user) : '',
        },
      },
      type,
    },
  };
};

export default createConversation;
