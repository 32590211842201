import React, { useContext, useMemo } from 'react';
import { Stack, Typography } from '@mui/joy';
import { useFormState } from 'react-final-form';
import { LineTypeMetaData } from '@sakari-io/sakari-typings';
import { Loader } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import { usePhoneNumbersMetadataQuery } from '../../../../../../api';

import SelectableCardGroup from '../../../../../../ui/molecules/groups/SelectableCardGroup';
import FieldWrapper from '../../../../../../utils/FieldWrapper';

import { AccountContext } from '../../../../../../contexts/account.context';

interface PhoneNumberTypeOptionsProps {
  name: string;
}

function PhoneNumberTypeOptions({ name }: PhoneNumberTypeOptionsProps) {
  const { t } = useTranslation();
  // if isSupported === ticket, skip choose number and create ticket in the end
  const { values } = useFormState();
  const { account } = useContext(AccountContext);

  const { data: countryData, isLoading } = usePhoneNumbersMetadataQuery({
    accountId: account?.id || '',
    request: values?.country?.code,
  });

  const lineTypes = useMemo(
    () =>
      (countryData?.data?.length && countryData?.data) || [
        {
          id: 'other',
          country: values?.country?.code,
          type: 'other',
          search: [],
          supported: 'TICKET',
        } as LineTypeMetaData,
      ],
    [countryData?.data],
  );

  const getIcon = (speed?: 'low' | 'medium' | 'high') => {
    switch (speed) {
      case 'low':
        return <>🐢</>;
      case 'medium':
        return <>🐰</>;
      case 'high':
        return <>🐆</>;
      default:
        return <>📞</>;
    }
  };

  if (isLoading) {
    return <Loader size={200} label="Loading line types..." />;
  }

  // if (!isLoading && !countryData?.data.length) {
  //   return (<>No line types found</>);
  // }

  return (
    <FieldWrapper
      component={SelectableCardGroup}
      name={name}
      options={lineTypes}
      height={200}
      width={175}
      containerSx={{
        flexFlow: 'row wrap',
      }}
      renderOption={(option: LineTypeMetaData) => {
        return (
          <Stack
            key={option.country + option.type}
            sx={{
              alignItems: 'center',
            }}
          >
            <Typography level="body-md" fontSize={32}>
              {getIcon(option.speed)}
            </Typography>
            <Typography level="body-md" fontWeight="bold">
              {t(`linetypes.${option.type}`)}
            </Typography>
            {option.pricing ? (
              <Typography level="body-sm">
                ${option.pricing?.price || 0} /{' '}
                {account?.plan.billingFrequency === 'annual' ? 'year' : 'month'}
              </Typography>
            ) : null}
            {option.pricing?.setup ? (
              <Typography level="body-sm">
                Setup: ${option.pricing.setup}
              </Typography>
            ) : null}
          </Stack>
        );
      }}
    />
  );
}

export default PhoneNumberTypeOptions;
