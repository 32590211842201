import React, { useState } from 'react';
import { Box, Button, Stack, Tooltip } from '@mui/joy';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import VerifyDialog from '../../Dialogs/VerifyDialog';
import PhoneInput, { PhoneInputProps } from '../../../atoms/inputs/PhoneInput';

interface VerifyPhoneInputProps extends PhoneInputProps {
  modified?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  skipVerificationDisabled?: boolean;
}

function VerifyPhoneInput({
  value,
  onChange,
  modified,
  isUpdating,
  isLoading,
  isFetching,
  skipVerificationDisabled = false,
  ...rest
}: VerifyPhoneInputProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        mb="auto"
        gap={2}
        width={rest.fullWidth ? '100%' : 'auto'}
      >
        <PhoneInput
          value={value}
          onChange={onChange}
          endDecorator={
            value?.verified ? (
              <Box>
                <Tooltip title={t('verified')}>
                  <FontAwesomeIcon
                    icon={solid('check-circle')}
                    style={{
                      color: '#4CAF50',
                      fontSize: '21px',
                    }}
                  />
                </Tooltip>
              </Box>
            ) : (
              !value?.verified &&
              !modified && (
                <Button
                  size="sm"
                  onClick={() => setDialogOpen(true)}
                  disabled={modified || isUpdating || isLoading || isFetching}
                >
                  {t('verify')}
                </Button>
              )
            )
          }
          {...rest}
        />
      </Stack>

      {dialogOpen && value ? (
        <VerifyDialog
          skipDisabled={skipVerificationDisabled}
          open={dialogOpen}
          onClose={(verified) => {
            if (verified) {
              onChange({
                ...value,
                verified: new Date().toISOString(),
              });
            }

            setDialogOpen(false);
          }}
          number={value}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default VerifyPhoneInput;
