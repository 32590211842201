import React from 'react';
import { faInfo, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition, faPartyHorn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ColorPaletteProp, ModalDialogProps } from '@mui/joy';
import ConcentricCircles from './ConcentricCircles';

const icons: { [key: string]: IconDefinition } = {
  primary: faInfo,
  success: faPartyHorn,
  danger: faWarning,
  warning: faWarning,
  neutral: faWarning,
};

interface ModalIconProps {
  color?: ColorPaletteProp;
  size?: ModalDialogProps['size'];
  icon?: IconDefinition;
}

function ModalIcon({ color = 'primary', size = 'md', icon }: ModalIconProps) {
  const colorRef = `var(--joy-palette-${color}-mainChannel)`;
  const avatarRadius = {
    sm: 16,
    md: 20,
    lg: 24,
  };
  return (
    <Avatar
      color={color}
      size={size}
      variant="soft"
      sx={{
        position: 'relative',
        overflow: 'visible',
        backgroundColor: `var(--joy-palette-${color}-100)`,
        color: `var(--joy-palette-${color}-500)`,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '320px',
          height: '320px',
          transform: 'translate(-50%, -50%)',
          zIndex: -1,
        }}
      >
        <ConcentricCircles
          spaceBetweenCircles={23}
          maxRadius={300}
          minRadius={avatarRadius[size]}
          color={colorRef}
        />
      </div>
      <FontAwesomeIcon icon={icon ?? icons[`${color}`]} />
    </Avatar>
  );
}

export default ModalIcon;
