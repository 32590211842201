/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  WorkflowNodeType,
  SakariAPIResponse,
  SearchablePaginationRequest,
  WorkflowVersion,
  WorkflowTemplate,
  Workflow,
  WorkflowProperties,
  WorkflowProperty,
  WorkflowRequest as CreateWorkflowRequest,
  WorkflowVersionMetrics,
  WorkflowDefinition,
  WorkflowExecution,
} from '@sakari-io/sakari-typings';
import { Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { logger } from '@sakari-io/sakari-components';
import { sakariApi } from './rtk';
import { buildQuery } from './common';
import { AccountIdWith } from '../types';

const WORKFLOWS_TAG = 'Workflows';
const WORKFLOW_TAG = 'Workflow';
const WORKFLOW_VERSION_TAG = 'WorkflowVersion';

const updateWorkflowsCache = async (
  {
    dispatch,
    queryFulfilled,
    getState,
  }: MutationLifecycleApi<any, any, any, any>,
  workflowId: string,
  updateRecipe: Recipe<SakariAPIResponse<Workflow[]>>,
) => {
  const patches = extendedApi.util
    .selectInvalidatedBy(getState(), [{ type: WORKFLOW_TAG, id: workflowId }])
    .filter(({ endpointName }) => endpointName === 'getWorkflows')
    .map(({ originalArgs }) => {
      return dispatch(
        extendedApi.util.updateQueryData(
          'getWorkflows',
          originalArgs,
          updateRecipe,
        ),
      );
    });

  try {
    await queryFulfilled;
  } catch {
    patches.forEach((patchResult) => patchResult.undo());
  }
};

function updateWorkflowStatus(
  { dispatch, getState }: MutationLifecycleApi<any, any, any, any>,
  workflowId: string,
  versionId: string | null,
  activate: boolean,
  accountId: string,
) {
  const updateVersionStatus = (v: WorkflowVersion) => {
    if (v.id === versionId && activate) {
      v.active = new Date().toISOString();
      v.readonly = true;
    } else {
      v.active = undefined;
    }

    return v;
  };

  const patches = extendedApi.util
    .selectInvalidatedBy(getState(), [{ type: WORKFLOW_TAG, id: workflowId }])
    .filter(({ endpointName }) => endpointName === 'getWorkflows')
    .map(({ originalArgs }) => {
      return dispatch(
        extendedApi.util.updateQueryData(
          'getWorkflows',
          originalArgs,
          (draft) => {
            draft.data = draft.data.map((workflow) => {
              if (workflow.id === workflowId) {
                workflow.versions =
                  workflow?.versions?.map((version) =>
                    updateVersionStatus(version),
                  ) || [];
              }
              return workflow;
            });
          },
        ),
      );
    });

  // update from view mode ( get single workflow )
  patches.push(
    dispatch(
      extendedApi.util.updateQueryData(
        'getWorkflow',
        { accountId, request: workflowId },
        (draft) => {
          (draft.data.versions || []).map(updateVersionStatus);
        },
      ),
    ),
  );

  // update from view mode ( get single workflow )\
  if (versionId) {
    patches.push(
      dispatch(
        extendedApi.util.updateQueryData(
          'getWorkflowVersion',
          { accountId, request: { workflowId, versionId } },
          (draft) => {
            updateVersionStatus(draft.data);
          },
        ),
      ),
    );
  }

  return patches;
}

interface WorkflowRequest {
  workflowId: string;
}

interface WorkflowVersionRequest extends WorkflowRequest {
  versionId: string;
  q?: Record<string, string>;
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflows: builder.query<
      SakariAPIResponse<Workflow[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/workflows?${buildQuery(request)}`,
      providesTags: (result) => [
        ...(result?.data?.map((workflow) => ({
          type: WORKFLOW_TAG,
          id: workflow.id,
        })) || []),
        WORKFLOWS_TAG,
      ],
    }),
    getWorkflow: builder.query<
      SakariAPIResponse<Workflow>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/workflows/${request}`,
      providesTags: (result) => [
        {
          type: WORKFLOW_TAG,
          id: result?.data.id!,
        },
      ],
    }),
    getWorkflowVersion: builder.query<
      SakariAPIResponse<WorkflowVersion>,
      AccountIdWith<WorkflowVersionRequest>
    >({
      query: ({ accountId, request: { workflowId, versionId } }) =>
        `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}`,
      providesTags: (result) => [
        {
          type: WORKFLOW_VERSION_TAG,
          id: result?.data.id,
        },
      ],
    }),
    getWorkflowNodeTypes: builder.query<
      SakariAPIResponse<WorkflowNodeType[]>,
      SearchablePaginationRequest
    >({
      query: (params) => `workflows/nodetypes?${buildQuery(params)}`,
    }),
    updateWorkflowName: builder.mutation<
      SakariAPIResponse<Workflow>,
      AccountIdWith<Workflow>
    >({
      query: ({ accountId, request: { id, ...data } }) => ({
        url: `accounts/${accountId}/workflows/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: WORKFLOW_TAG,
          id: arg.request.id,
        },
      ],
    }),
    updateWorkflowDefinition: builder.mutation<
      SakariAPIResponse<WorkflowDefinition>,
      AccountIdWith<WorkflowVersionRequest & { updatedAt: string }> // TODO this is missing the definiton props
    >({
      query: ({
        accountId,
        request: { workflowId, versionId, updatedAt, ...data },
      }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}/definition`,
        method: 'PUT',
        body: data,
        headers: {
          'x-sakari-updatekey': updatedAt,
        },
      }),
      onQueryStarted: async (
        { accountId, request: { workflowId, versionId } },
        { dispatch, queryFulfilled },
      ) => {
        try {
          const { data: updatedDefinition, meta } = await queryFulfilled;
          const updatedAt = meta?.response?.headers?.get('x-sakari-updatekey');
          dispatch(
            extendedApi.util.updateQueryData(
              'getWorkflowVersion',
              { accountId, request: { workflowId, versionId } },
              (draft) => {
                draft.data.definition = updatedDefinition.data;
                if (updatedAt) {
                  draft.data.updated.at = updatedAt;
                }
              },
            ),
          );
        } catch (err) {
          logger.warn('unable to update workflow definiton', err);
        }
      },
      // invalidatesTags: (result, error, args) => {
      //   if (error) {
      //     return [];
      //   }
      //   return [{ type: WORKFLOW_VERSION_TAG, id: args.request.versionId }];
      // },
    }),
    cancelWorkflowExecution: builder.mutation<
      SakariAPIResponse<Workflow>,
      AccountIdWith<{ workflowId: string; executionId: string }>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/workflows/${request.workflowId}/executions/${request.executionId}`,
        method: 'DELETE',
      }),
    }),
    deleteWorkflow: builder.mutation<
      SakariAPIResponse<Workflow>,
      AccountIdWith<WorkflowRequest>
    >({
      query: ({ accountId, request: { workflowId } }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (
        { request: { workflowId } }: AccountIdWith<WorkflowRequest>,
        api,
      ) =>
        updateWorkflowsCache(api, workflowId, (draft) => {
          draft.data = draft.data.filter((wf) => {
            if (wf.id === workflowId) {
              return false;
            }

            return wf;
          });
        }),
      invalidatesTags: (result, error, arg) => [
        { type: WORKFLOW_TAG, id: arg.request.workflowId },
        { type: WORKFLOWS_TAG },
      ],
    }),
    duplicateWorkflow: builder.mutation<
      SakariAPIResponse<Workflow>,
      AccountIdWith<Pick<Workflow, 'id'>>
    >({
      query: ({ accountId, request: { id, ...data } }) => ({
        url: `accounts/${accountId}/workflows/${id}/clone`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [WORKFLOWS_TAG],
    }),
    activateWorkflow: builder.mutation<
      SakariAPIResponse<Workflow>,
      AccountIdWith<WorkflowVersionRequest>
    >({
      query: ({ accountId, request: { workflowId, versionId } }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}/activate`,
        method: 'POST',
      }),
      onQueryStarted: async (
        { accountId, request: { workflowId, versionId } },
        api,
      ) => {
        const patches = updateWorkflowStatus(
          api,
          workflowId,
          versionId ?? null,
          true,
          accountId,
        );
        try {
          await api.queryFulfilled;
        } catch (err) {
          logger.warn(err);
          patches.map((patch) => patch.undo());
        }
      },
      // invalidatesTags: (result, error, arg) =>
      //   result ? [{ type: WORKFLOW_TAG, id: arg.request.workflowId }] : [],
    }),
    deactivateWorkflow: builder.mutation<
      SakariAPIResponse<Workflow>,
      AccountIdWith<WorkflowVersionRequest>
    >({
      query: ({ accountId, request: { workflowId, versionId } }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}/deactivate`,
        method: 'DELETE',
      }),
      onQueryStarted: async (
        { accountId, request: { workflowId, versionId } },
        api,
      ) => {
        const patches = updateWorkflowStatus(
          api,
          workflowId,
          versionId ?? null,
          false,
          accountId,
        );
        try {
          await api.queryFulfilled;
        } catch (err) {
          logger.warn(err);
          patches.map((patch) => patch.undo());
        }
      },
      // invalidatesTags: (result, error, arg) =>
      //   result ? [{ type: WORKFLOW_TAG, id: arg.request.workflowId }] : [],
    }),
    getWorkflowTemplates: builder.query<
      SakariAPIResponse<WorkflowTemplate[]>,
      string
    >({
      query: () => `/workflowtemplates`,
    }),
    createWorkflowProperty: builder.mutation<
      SakariAPIResponse<WorkflowProperty>,
      AccountIdWith<
        WorkflowVersionRequest & {
          name: string;
          dataType: WorkflowProperty['dataType'];
          versionId: string;
        }
      >
    >({
      query: ({ accountId, request: { workflowId, versionId, ...data } }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}/properties`,
        method: 'POST',
        body: data,
      }),

      onQueryStarted: (
        { accountId, request: { workflowId, name, dataType, versionId } },
        { dispatch, queryFulfilled },
      ) => {
        const patchResultWorkflowVersion = dispatch(
          extendedApi.util.updateQueryData(
            'getWorkflowVersion',
            { accountId, request: { workflowId, versionId } },
            (draft) => {
              if (!draft.data.definition.properties) {
                draft.data.definition.properties = [];
              }
              draft.data.definition.properties.push({ name, dataType });
            },
          ),
        );

        const patchResultWorkflowProperties = dispatch(
          extendedApi.util.updateQueryData(
            'getWorkflowProperties',
            { accountId, request: { workflowId, versionId } },
            (draft) => {
              if (!draft.data) {
                draft.data = [];
              }
              draft.data.push({ name, dataType });
            },
          ),
        );

        queryFulfilled
          .then((resp) => {
            dispatch(
              extendedApi.util.updateQueryData(
                'getWorkflowVersion',
                { accountId, request: { workflowId, versionId } },
                (draft) => {
                  if (resp.data.data?.created?.at) {
                    draft.data.updated.at = resp.data.data?.created?.at;
                  }
                },
              ),
            );
          })
          .catch(() => {
            patchResultWorkflowVersion.undo();
            patchResultWorkflowProperties.undo();
          });
      },
    }),

    getWorkflowProperties: builder.query<
      SakariAPIResponse<WorkflowProperties>,
      AccountIdWith<WorkflowVersionRequest>
    >({
      query: ({ accountId, request: { workflowId, versionId } }) =>
        `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}/properties`,
    }),
    createWorkflow: builder.mutation<
      SakariAPIResponse<Workflow>,
      AccountIdWith<CreateWorkflowRequest>
    >({
      query: ({ accountId, request: data }) => ({
        url: `accounts/${accountId}/workflows`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [WORKFLOWS_TAG],
    }),
    preactivateWorkflow: builder.mutation<
      SakariAPIResponse<{ count: number }>,
      AccountIdWith<WorkflowVersionRequest & { enrollExisting: boolean }>
    >({
      query: ({ accountId, request: { workflowId, versionId, ...data } }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}/preactivate`,
        method: 'POST',
        body: data,
      }),
    }),

    getWorkflowVersionMetrics: builder.query<
      SakariAPIResponse<WorkflowVersionMetrics>,
      AccountIdWith<WorkflowVersionRequest>
    >({
      query: ({ accountId, request: { workflowId, versionId, q } }) =>
        `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}/metrics?${buildQuery(q)}`,
    }),

    getWorkflowExecutions: builder.query<
      SakariAPIResponse<WorkflowExecution[]>,
      AccountIdWith<{ workflowId: string; contactId?: string }>
    >({
      query: ({ accountId, request: { workflowId, contactId } }) =>
        `accounts/${accountId}/workflows/${workflowId}/executions?contactId=${contactId}`,
    }),
    getWorkflowExecution: builder.query<
      SakariAPIResponse<WorkflowExecution>,
      AccountIdWith<{ workflowId: string; executionId?: string }>
    >({
      query: ({ accountId, request: { workflowId, executionId } }) =>
        `accounts/${accountId}/workflows/${workflowId}/executions/${executionId}`,
    }),

    duplicateWorkflowVersion: builder.mutation<
      SakariAPIResponse<WorkflowVersion>,
      AccountIdWith<
        WorkflowVersionRequest & {
          discardDraft: boolean;
        }
      >
    >({
      query: ({ accountId, request: { workflowId, ...data } }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}/versions`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, args) => [
        { type: WORKFLOW_TAG, id: args.request.workflowId },
      ],
      // onQueryStarted: (
      //   { accountId, request: { workflowId } },
      //   { dispatch, queryFulfilled },
      // ) => {
      //   queryFulfilled.then((resp) => {
      //     dispatch(
      //       extendedApi.util.updateQueryData(
      //         'getWorkflow',
      //         { accountId, request: workflowId },
      //         (draft) => {
      //           const existing = (draft.data.versions || []).filter(
      //             (v) => v.readonly,
      //           );
      //           draft.data.versions = [...existing, resp.data.data];
      //         },
      //       ),
      //     );
      //   });
      // },
    }),

    deleteWorkflowVersion: builder.mutation<
      SakariAPIResponse<WorkflowVersion>,
      AccountIdWith<WorkflowVersionRequest>
    >({
      query: ({ accountId, request: { workflowId, versionId } }) => ({
        url: `accounts/${accountId}/workflows/${workflowId}/versions/${versionId}`,
        method: 'DELETE',
      }),
      // onQueryStarted: (
      //   { accountId, request: { workflowId, versionId } },
      //   { dispatch, queryFulfilled },
      // ) => {
      //   const patchResult = dispatch(
      //     extendedApi.util.updateQueryData(
      //       'getWorkflow',
      //       { accountId, request: workflowId },
      //       (draft) => {
      //         const existing = (draft.data.versions || []).filter(
      //           (v) => v.id !== versionId,
      //         );
      //         draft.data.versions = existing;
      //       },
      //     ),
      //   );

      //   queryFulfilled.catch((err) => {
      //     patchResult.undo();
      //   });
      // },
      invalidatesTags: (result, error, args) => [
        { type: WORKFLOW_TAG, id: args.request.workflowId },
      ],
    }),
  }),
});

export const {
  useGetWorkflowsQuery,
  useGetWorkflowQuery,
  useGetWorkflowVersionQuery,
  useGetWorkflowNodeTypesQuery,
  useUpdateWorkflowNameMutation,
  useUpdateWorkflowDefinitionMutation,
  useDeleteWorkflowMutation,
  useDuplicateWorkflowMutation,
  useActivateWorkflowMutation,
  useDeactivateWorkflowMutation,
  useGetWorkflowTemplatesQuery,
  useCreateWorkflowMutation,
  useGetWorkflowPropertiesQuery,
  useCreateWorkflowPropertyMutation,
  usePreactivateWorkflowMutation,
  useGetWorkflowVersionMetricsQuery,
  useGetWorkflowExecutionsQuery,
  useGetWorkflowExecutionQuery,
  useDuplicateWorkflowVersionMutation,
  useDeleteWorkflowVersionMutation,
  useCancelWorkflowExecutionMutation,
} = extendedApi;
