import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Tweet } from 'react-tweet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Contact,
  Workflow,
  WorkflowExecution,
  WorkflowExecutionStep,
  WorkflowNodeTypeOutputDataType,
  WorkflowEdge,
} from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/query';
import { Chip, Stack, SvgIcon } from '@mui/joy';
import { AvatarChip, Loader } from '@sakari-io/sakari-components';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactHeader from '../ContactHeader';
import ElementCard from '../../../../Edit/ElementCard';
import DetailsRow from './DetailsRow';
import {
  useGetWorkflowExecutionQuery,
  useGetWorkflowNodeTypesQuery,
  useCancelWorkflowExecutionMutation,
} from '../../../../../../api';
import { AccountContext } from '../../../../../../contexts/account.context';
import MessageRow from './MessageRow';
import Helper from '../../../../../../utils/helper';
import { useAppDispatch } from '../../../../../../redux';
import { showToast } from '../../../../../../redux/reducers/toast';
import DrawerHeader from '../../DrawerHeader';
import FieldDivider from '../../../../Edit/ConfigDrawer/NodeProperties/FieldDivider';
import { Mode } from '../../../../../../redux/reducers/workflow';
import Dialog from '../../../../../../components/molecules/Dialog';
import { handleApiMutationResponse } from '../../../../../../utils/api';
import { ContactDrawerStep } from '../index';

interface ExecutionProps {
  workflow: Workflow;
  contact: Contact;
  onBack: () => void;
  value: WorkflowExecution;
  setCurrentPage: (page: ContactDrawerStep) => void;
}

function Execution({
  workflow,
  contact,
  onBack,
  value,
  setCurrentPage,
}: ExecutionProps) {
  const { t } = useTranslation('workflows');
  const { account } = useContext(AccountContext);
  const dispatch = useAppDispatch();

  const { data: nodeTypesData } = useGetWorkflowNodeTypesQuery({ limit: 100 });
  const nodeTypesById = _.keyBy(nodeTypesData?.data, (nt) => nt.id);

  const { mode } = useSelector((state: any) => state.workflow.present);

  const [cancelWorkflowExecution] = useCancelWorkflowExecutionMutation();
  const [openDialog, setOpenDialog] = useState(false);

  const {
    data: execution,
    isLoading,
    isError,
    error,
  } = useGetWorkflowExecutionQuery(
    account
      ? {
          accountId: account.id,
          request: {
            workflowId: workflow.id,
            executionId: value.id,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        showToast({
          message: (error as Error)?.message || 'Error loading data',
          severity: 'error',
        }),
      );
    }
  }, [isError]);

  const formatDetails = (details: any) => {
    if (typeof details === 'boolean') return details ? '✅' : '❌';
    if (typeof details === 'object') return JSON.stringify(details);
    return details;
  };

  const renderNodeContextItem = (key: string, context: any) => {
    switch (key) {
      case WorkflowNodeTypeOutputDataType.Message:
        return <MessageRow message={context[key]} />;
      case WorkflowNodeTypeOutputDataType.Group:
        return (
          <DetailsRow
            title={t('contactExecutions.group')}
            details={
              <Chip variant="soft" color="primary">
                {context[key].name}
              </Chip>
            }
          />
        );
      case WorkflowNodeTypeOutputDataType.Contact:
        return (
          <DetailsRow
            title={t('contactExecutions.contact')}
            details={<AvatarChip size="sm" person={context[key]} />}
          />
        );
      case WorkflowNodeTypeOutputDataType.Link:
        return (
          <DetailsRow
            title={t('contactExecutions.link')}
            details={
              <a href={context[key].destinationUrl} target="blank">
                {context[key].destinationUrl}
              </a>
            }
          />
        );
      case WorkflowNodeTypeOutputDataType.List:
        return (
          <DetailsRow
            title={t('contactExecutions.list')}
            details={
              <Chip variant="soft" color="primary">
                {context[key].name}
              </Chip>
            }
          />
        );
      case WorkflowNodeTypeOutputDataType.Object:
        return Object.entries(context[key]?.attributes)?.map(([key, value]) => (
          <DetailsRow key={key} title={key} details={String(value)} />
        ));
      case WorkflowNodeTypeOutputDataType.Tweet:
        return value ? <Tweet id={context[key]} /> : null;
      default:
        return key !== 'end' ? (
          <DetailsRow
            title={_.startCase(key)}
            details={<>{formatDetails(context[key])}</>}
          />
        ) : null;
    }
  };

  const renderNodeContext = (context: any) => {
    return (
      <Stack spacing={1}>
        {Object.keys(context)?.map((key) => {
          return renderNodeContextItem(key, context);
        })}
      </Stack>
    );
  };

  const hasOutput = (step: any) => {
    return Object.keys(step.node.context).length > 1;
  };

  const renderDividerLabel = (edge: WorkflowEdge) => {
    if (!edge) return null;

    switch (edge.type) {
      case 'standard':
        return edge.value !== undefined && edge.value !== null
          ? `${edge.value}`
          : undefined;

      case 'timeout':
        return '⏰';
      case 'error':
        return (
          <SvgIcon color="danger" fontSize="md">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </SvgIcon>
        );
      default:
        return undefined;
    }
  };

  const checkExecutionStatus = (index: number, status: string) => {
    return (
      mode === Mode.CONTACTS &&
      execution?.data?.status === status &&
      index === execution?.data?.steps.length - 1
    );
  };

  const isPaused = (index: number) => checkExecutionStatus(index, 'paused');
  const isErrored = (index: number) => checkExecutionStatus(index, 'error');

  const removeContactFromWorkflow = () => {
    return handleApiMutationResponse(
      cancelWorkflowExecution({
        accountId: account?.id ?? '',
        request: {
          workflowId: workflow?.id,
          executionId: value?.id,
        },
      }).unwrap(),
      dispatch,
      {
        onError: () => setOpenDialog(false),
        onSuccess: () => setCurrentPage(ContactDrawerStep.Contacts),
        successMessage: t('edit.delete.contact.success'),
        defaultErrorMessage: t('edit.delete.contact.error'),
      },
    );
  };

  const showCancelExecution = ['paused'].includes(
    execution?.data?.status || '',
  );

  const commonLabelStyle = {
    border: '1px solid var(--joy-palette-neutral-300)',
    borderRadius: '50px',
    background: 'var(--Base-White, #FFF)',
    minWidth: '134px',
    minHeight: '40px',
    padding: '5px 14px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow:
      '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  };

  const erroredLabelStyle = {
    ...commonLabelStyle,
    border: '1px solid var(--joy-palette-danger-300)',
  };

  return (
    <>
      <DrawerHeader title={t('contactExecutions.contactExecutions')} />
      <ContactHeader
        onBack={onBack}
        contact={contact}
        options={
          showCancelExecution
            ? [
                {
                  label: t('versions.cancelExecution'),
                  onClick: () => setOpenDialog((prev) => !prev),
                },
              ]
            : []
        }
      />
      <Stack
        sx={{
          height: `${window.innerHeight - 226}px`,
          overflow: 'auto',
          padding: '8px 16px 24px 16px',
        }}
      >
        {isLoading && (
          <Loader size={100} label={t(`contactExecutions.loadingExecutions`)} />
        )}
        {execution
          ? execution?.data?.steps?.map(
              (step: WorkflowExecutionStep, index) => (
                <Stack key={step.id}>
                  <ElementCard
                    type={nodeTypesById[step.node?.type?.id]}
                    description={
                      isPaused(index) ? '' : Helper.formatDateTime(step?.start)
                    }
                    renderSummary={() => renderNodeContext(step.node.context)}
                    expandable={hasOutput(step)}
                    paused={isPaused(index)}
                  />

                  {index < execution.data.steps.length - 1 && (
                    <Stack>
                      <FieldDivider
                        height="20px"
                        renderLabel={() => {
                          if (
                            step?.edge &&
                            !(
                              step?.edge?.type === 'standard' &&
                              (step?.edge?.value === undefined ||
                                step?.edge?.value === null)
                            )
                          ) {
                            return (
                              <Stack sx={{ ...commonLabelStyle }}>
                                {renderDividerLabel(step?.edge)}
                              </Stack>
                            );
                          }
                          return null;
                        }}
                      />
                    </Stack>
                  )}

                  {/* errored label for errored execution */}
                  {index === execution.data.steps.length - 1 &&
                    isErrored(index) && (
                      <>
                        <FieldDivider
                          height="40px"
                          showEndDivider={false}
                          color="var(--joy-palette-danger-300)"
                          renderLabel={() => {
                            return (
                              <Stack sx={{ ...erroredLabelStyle }}>
                                <SvgIcon color="danger" fontSize="md">
                                  <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                  />
                                </SvgIcon>
                              </Stack>
                            );
                          }}
                        />
                      </>
                    )}
                </Stack>
              ),
            )
          : null}
      </Stack>

      <Dialog
        open={openDialog}
        color="danger"
        title={t('versions.cancelExecution')}
        primaryTitle={t('confirm')}
        cancelTitle={t('cancel')}
        cancelAction={() => setOpenDialog(false)}
        primaryAction={removeContactFromWorkflow}
        onClose={() => setOpenDialog(false)}
      >
        {t('edit.delete.cancelExecutionConfirm')}
      </Dialog>
    </>
  );
}

export default Execution;
