import { ContactFilter } from '@sakari-io/sakari-typings';
import axios from 'axios';
import config from '../config';
import Helper from './helper';

const downloadContacts = async (accessToken: string, filter: ContactFilter) => {
  const url = `${
    config.apiUrl
  }/accounts/${Helper.getAccountId()}/bulk/contacts/download`;
  const result = await axios({
    url,
    method: 'POST',
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
    // data: {
    //   q,
    //   attributes,
    //   listId,
    // },
    data: filter,
  });

  return result.data;
};

const downloadReport = async (
  accessToken: string,
  type: string,
  start: Date,
  end: Date,
  email: string,
) => {
  let url;
  if (type !== 'detailed') {
    url = `${
      config.apiUrl
    }/accounts/${Helper.getAccountId()}/reports/${type}?start=${start.toISOString()}&end=${end.toISOString()}`;
  } else {
    url = `${
      config.apiUrl
    }/accounts/${Helper.getAccountId()}/reports/messages?email=${email}&start=${start.toISOString()}&end=${end.toISOString()}`;
  }
  const result = await axios({
    url,
    method: 'GET',
    headers: {
      Authorization: `bearer ${accessToken}`,
    },
    data: {},
  });
  return result.data;
};

export { downloadContacts, downloadReport };
