import React, { useContext, useState } from 'react';

import { makeValidate } from 'mui-rff';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import isURL from 'validator/es/lib/isURL';

import { useTranslation } from 'react-i18next';

import { Stack, Button } from '@mui/joy';
import VerifiedDomain from '../../../../pages/AccountSettings/VerifiedDomain';
import SetupDomainDialog from '../SetupDomainDialog';
import DeleteDomainDialog from '../DeleteDomainDialog';

import {
  useCreateDomainMutation,
  useVerifyDomainMutation,
  useDeleteDomainMutation,
} from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import { TextField } from '../../..';
import FieldWrapper from '../../../../utils/FieldWrapper';

enum DialogType {
  NONE = '',
  CREATE = 'create',
  VERIFY = 'verify',
  DELETE = 'delete',
}

function CustomDomain() {
  const [inputDomain, setInputDomain] = useState<string>('');
  const { account } = useContext(AccountContext);

  const [dialog, setDialog] = useState<DialogType>(DialogType.NONE);

  const [verify, setVerify] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const [createDomain] = useCreateDomainMutation();
  const [verifyDomain] = useVerifyDomainMutation();
  const [deleteDomain] = useDeleteDomainMutation();

  const schema = Yup.object().shape({
    domain: Yup.string().test(
      'custom-url',
      t('customDomain.error.invalidURL'),
      (value) =>
        isURL(value || '', {
          require_protocol: false,
          require_valid_protocol: true,
        }),
    ),
    contactTracking: Yup.string(),
  });

  const validate = makeValidate(schema);

  const openAddDialog = (values: any) => {
    setInputDomain(values?.domain);
    setDialog(DialogType.CREATE);
  };

  const openVerifyDialog = () => {
    setVerify(true);
    setInputDomain(account?.options?.customDomain?.domain || '');
    setDialog(DialogType.VERIFY);
  };

  const closeDialog = () => {
    if (dialog === DialogType.CREATE) {
      setInputDomain('');
      setVerify(false);
    }
    setDialog(DialogType.NONE);
  };

  const openDeleteDialog = () => {
    setDialog(DialogType.DELETE);
  };

  const handleAction = (action: string) => async (domain: string) => {
    setLoading(true);
    let result;
    switch (action) {
      case 'create':
        result = await createDomain(domain).unwrap();
        break;
      case 'delete':
        result = await deleteDomain(domain).unwrap();
        break;
      default:
        result = await verifyDomain(domain).unwrap();
    }
    setLoading(false);
    return result;
  };

  const handleCreate = handleAction('create');
  const handleVerify = handleAction('verify');
  const handleDelete = handleAction('delete');

  return (
    <>
      {account?.options?.customDomain?.id ? (
        <VerifiedDomain
          domain={account.options.customDomain.domain || ''}
          verified={!!account.options.customDomain.renewAt}
          loading={loading}
          onVerify={openVerifyDialog}
          onDelete={openDeleteDialog}
        />
      ) : (
        <Form
          onSubmit={openAddDialog}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, invalid }) => (
            <Stack direction="row" justifyContent="start" spacing={1}>
              <FieldWrapper
                component={TextField}
                name="domain"
                disabled={account?.plan?.name === 'Trial'}
              />
              <Button
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={invalid}
              >
                {t('customDomain.setup')}
              </Button>
            </Stack>
          )}
        />
      )}

      <SetupDomainDialog
        open={[DialogType.CREATE, DialogType.VERIFY].includes(dialog)}
        onClose={closeDialog}
        domain={inputDomain}
        verify={verify}
        onCreate={handleCreate}
        onVerify={handleVerify}
      />

      <DeleteDomainDialog
        open={dialog === DialogType.DELETE}
        onClose={closeDialog}
        domain={account?.options?.customDomain?.id || ''}
        onDelete={handleDelete}
      />
    </>
  );
}

export default CustomDomain;
