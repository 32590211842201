import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import AppDialog from '../../../molecules/AppDialog';

interface IntegrationInstallingProps {
  open: boolean;
}

function IntegrationInstalling({ open }: IntegrationInstallingProps) {
  const { t } = useTranslation();
  return (
    <AppDialog
      setOpen={() => open}
      open={open}
      header={
        <FontAwesomeIcon
          className="fa-spin"
          icon={duotone('spinner-third')}
          style={{ fontSize: '30px' }}
        />
      }
      content={t('installing')}
    />
  );
}

export default IntegrationInstalling;
