import React from 'react';
import { Box } from '@mui/joy';

interface GridColumn {
  xs?: string | number;
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
  xl?: string | number;
}

interface GridProps {
  item?: boolean;
  sx?: any;
  gridTemplateColumns?: string;
  gridColumn?: GridColumn | string;
  gap?: string | number;
  children: React.ReactNode;
}

// ** Custom Grid Component that uses CSS Grid Layout inspired by Material UI Grid
// * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout
// * https://mui.com/system/grid/
// **
function Grid({
  item,
  sx,
  gridTemplateColumns,
  gridColumn,
  gap,
  children,
}: GridProps) {
  const formatGridColumn = (gridColumn: number | string) => {
    if (typeof gridColumn === 'number') {
      return `span ${gridColumn}`;
    }
    return gridColumn;
  };

  const formatGridColumns = (gridColumns: GridColumn) => {
    const gridColumnsArray = Object.entries(gridColumns);
    const formattedGridColumns = gridColumnsArray.map(([key, value]) => [
      key,
      formatGridColumn(value),
    ]);
    return Object.fromEntries(formattedGridColumns);
  };

  const styles = {
    gridColumn: 'auto',
    ...sx,
  };

  if (gridColumn) {
    styles.gridColumn =
      typeof gridColumn === 'string'
        ? gridColumn
        : formatGridColumns(gridColumn);
  }

  if (item) {
    return <Box sx={styles}>{children}</Box>;
  }
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: gridTemplateColumns || 'repeat(12, 1fr)',
        gap: gap || 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
export default Grid;
