import React, { ReactNode } from 'react';
import { Stack } from '@mui/joy';
import { useSelector } from 'react-redux';
import { WorkflowNodeType } from '@sakari-io/sakari-typings';
import ElementCard from '../../Edit/ElementCard';
import useHover from '../../../../hooks/useHover';
import { Metrics } from '../../Edit/ElementCard/MetricsTab';

interface WorkflowCardProps {
  type: WorkflowNodeType;
  onClick?: () => void;
  id?: string;
  isHandleHovered?: boolean;
  disabled?: boolean;
  incomplete?: boolean;
  renderSummary?: () => ReactNode;
  metrics?: Metrics;
}

const WorkflowCard = React.forwardRef<HTMLDivElement, WorkflowCardProps>(
  (
    {
      type,
      onClick,
      id,
      disabled,
      incomplete,
      renderSummary,
      isHandleHovered,
      metrics,
    },
    ref,
  ) => {
    const [isHovered] = useHover();
    const { currentRFNode } = useSelector(
      (state: any) => state.workflow.present,
    );

    return (
      <Stack
        alignItems="center"
        width={300}
        sx={{ padding: '0px 10px 0px' }}
        ref={ref}
      >
        <ElementCard
          selected={id === currentRFNode?.id}
          incomplete={incomplete}
          id={id}
          isHovered={isHovered || isHandleHovered}
          disabled={disabled}
          renderSummary={renderSummary}
          type={type}
          onClick={onClick}
          metrics={metrics}
        />
      </Stack>
    );
  },
);

export default WorkflowCard;
