import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface ListDeleteDialogProps {
  open: boolean;
  onClose: (result?: boolean) => void;
}

function ListDeleteDialog({ open, onClose }: ListDeleteDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      confirmLabel="yesDelete"
      header={t('deleteList')}
      content={t('cannotUndoAction')}
      type="danger"
    />
  );
}

export default ListDeleteDialog;
