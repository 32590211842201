import { Stack } from '@mui/joy';
import React, { useContext } from 'react';
import { AccountContext } from '../../contexts/account.context';
import NotificationsGrid from '../../ui/organisms/datagrids/NotificationsGrid';

function Notifications() {
  const { account } = useContext(AccountContext);

  return (
    <Stack direction="row" flex={1}>
      {/* <Stack width={250}>
        <FormLabel>{t('profileSettings.notifications.config.label')}</FormLabel>
      </Stack> */}

      {account ? <NotificationsGrid account={account} /> : null}
    </Stack>
  );
}

export default Notifications;
