import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Sheet, SheetProps, Stack } from '@mui/joy';
import { AnimatePresence, motion } from 'framer-motion';

interface AccordionProps extends Omit<SheetProps, 'content'> {
  header: React.ReactNode;
  content?: React.ReactNode;
  toggleIcon?: any;
  hideToggle?: boolean;
  disableOpen?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  action?: () => void;
  divider?: boolean;
  noSpacer?: boolean;
}

function Accordion({
  header,
  content,
  sx,
  toggleIcon,
  hideToggle,
  isOpen,
  setIsOpen,
  disableOpen,
  action,
  divider,
  noSpacer,
  ...rest
}: AccordionProps) {
  return (
    <Sheet
      sx={{
        borderRadius: '8px',
        transition: '1s ease-in-out all',
        ...sx,
      }}
      {...rest}
    >
      <Stack
        sx={{
          width: '100%',
          overflow: 'hidden',
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
          ...(!isOpen && {
            borderBottomLeftRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
          }),
        }}
      >
        <Stack
          id={`accordion-header-${rest.id}`}
          sx={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: noSpacer ? 1 : 2,
            p: noSpacer ? 0 : 2,
          }}
        >
          {header}
          {!hideToggle && (
            <IconButton
              onClick={action || (() => setIsOpen(!isOpen))}
              disabled={disableOpen}
              size="sm"
              variant="plain"
              color={rest.color || 'neutral'}
              style={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            >
              {toggleIcon || <FontAwesomeIcon icon={regular('chevron-down')} />}
            </IconButton>
          )}
        </Stack>
        {divider && isOpen && <Divider />}
        <AnimatePresence>
          <motion.div
            id={`accordion-content-${rest.id}`}
            aria-hidden={!isOpen}
            initial={{
              height: 0,
              overflow: 'hidden',
            }}
            animate={{
              height: isOpen ? 'auto' : 0,
            }}
            exit={{
              height: 0,
              overflow: 'hidden',
            }}
          >
            {content}
          </motion.div>
        </AnimatePresence>
      </Stack>
    </Sheet>
  );
}

export default Accordion;
