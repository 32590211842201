import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import InviteUsers from '../../ui/organisms/forms/InviteUsers';
import Setting from '../../ui/templates/forms/SettingForm';
import UsersGrid from '../../ui/organisms/datagrids/UsersGrid';
import FieldWrapper from '../../utils/FieldWrapper';
import AutoEnroll from '../../ui/organisms/forms/AutoEnroll';
import { AccountContext } from '../../contexts/account.context';

function Users() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);

  return (
    <Stack spacing={2}>
      <Setting
        label={t('settings.users.autoEnroll.label')}
        description={t('settings.users.autoEnroll.description')}
      >
        <FieldWrapper component={AutoEnroll} name="options.autoEnroll" />
      </Setting>

      <Setting label={t('settings.users.invite.label')}>
        <InviteUsers />
      </Setting>

      <Setting
        label={t('settings.users.team.label')}
        description={t('settings.users.team.description')}
      >
        <div
          style={{
            width: '100%',
            height: '50vh',
          }}
        >
          {account && <UsersGrid account={account} />}
        </div>
      </Setting>
    </Stack>
  );
}

export default Users;
