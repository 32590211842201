import React from 'react';
import { Stack } from '@mui/joy';
import Metric from './Metric';

// on edges, metrics will have passed/error not both - on nodes, metrics will have both passed/error
export interface Metrics {
  passed?: number;
  error?: number;
  timeout?: number;
  totalAttempts?: number;
}

interface MetricsProps {
  metrics: Metrics;
  hideIcon?: boolean;
  percentage?: boolean;
}

function MetricsTab({ metrics, hideIcon, percentage }: MetricsProps) {
  return (
    <Stack
      direction="row"
      spacing={3}
      p={0.5}
      pl={1}
      pr={1}
      justifyContent="center"
      sx={{
        border: '1px solid var(--joy-palette-divider)',
        borderBottomLeftRadius: 'var(--joy-radius-lg)',
        borderBottomRightRadius: 'var(--joy-radius-lg)',
        borderTop: 'none',
        alignItems: 'center',
        width: 'fit-content',
        backgroundColor: 'var(--joy-palette-common-white)',
      }}
    >
      {metrics.passed !== undefined && (
        <Metric
          type="passed"
          value={metrics.passed}
          hideIcon={hideIcon}
          percentage={percentage}
        />
      )}

      {metrics.error !== undefined && (
        <Metric
          type="error"
          value={metrics.error}
          hideIcon={hideIcon}
          percentage={percentage}
        />
      )}

      {metrics.timeout !== undefined && (
        <Metric
          type="timeout"
          value={metrics.timeout}
          hideIcon={hideIcon}
          percentage={percentage}
        />
      )}
    </Stack>
  );
}

export default MetricsTab;
