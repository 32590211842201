import React, { ReactNode } from 'react';
import { IconButton, Input } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { LexicalEditor } from 'lexical';
import { WorkflowDataReference } from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import { actions } from '../../../../../../redux/reducers/workflow';
import { ConfigFieldProps } from '../PropertyField';
import Editor from '../../../../../../components/molecules/Editor';
import { INSERT_VARIABLE_COMMAND } from '../../../../../../components/molecules/Editor/plugins/TemplatingPlugin';
import ActionsPlugin from '../../../../../../components/molecules/Editor/plugins/ActionsPlugin';

function InputEditor(props: any) {
  const { disabled, value, onChange, onHighlight } = props;
  const dispatch = useDispatch();

  return (
    <Editor
      disabled={disabled}
      value={value ?? ''}
      onChange={(value: string) => {
        onChange({ target: { value } });
      }}
      containerSx={{
        flex: 1,
        pt: 0.5,
      }}
      orientation="horizontal"
      onHover={onHighlight}
      plugins={{
        end: (
          <ActionsPlugin
            renderActions={(editor: LexicalEditor) => (
              <IconButton
                sx={{ mr: -1 }}
                disabled={disabled}
                onClick={() =>
                  dispatch(
                    actions.enterSelectionMode({
                      callback: (val: WorkflowDataReference) => {
                        if (val) {
                          editor.focus(undefined, {
                            defaultSelection: 'rootEnd',
                          });

                          editor.dispatchCommand(INSERT_VARIABLE_COMMAND, {
                            name: val.type,
                            params: [val.nodeId, val.path] || [],
                          });
                        }
                      },
                    }),
                  )
                }
              >
                <FontAwesomeIcon icon={faAdd} />
              </IconButton>
            )}
          />
        ),
      }}
    />
  );
}

interface DynamicTextProps extends ConfigFieldProps<string> {
  startDecorator?: ReactNode;
  fullWidth?: boolean;
}

function DynamicText({
  label,
  value,
  placeholder,
  helperText,
  onChange,
  error,
  link,
  startDecorator,
  disabled,
  onHighlight,
  required,
  fullWidth,
}: DynamicTextProps) {
  return (
    <InputControl
      label={label}
      helperText={helperText}
      error={error}
      required={required}
      link={link}
      fullWidth={fullWidth}
    >
      <Input
        placeholder={placeholder}
        startDecorator={startDecorator}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        slots={{
          input: InputEditor,
        }}
        slotProps={{
          input: {
            onHighlight,
            disabled,
          },
        }}
      />
    </InputControl>
  );
}

export default DynamicText;
