import React from 'react';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, ChipProps, Typography } from '@mui/joy';

function ConditionChip({
  type,
  action,
  sx,
  ...chipProps
}: ChipProps & {
  type: 'and' | 'or';
  action: 'add' | 'remove';
}) {
  const typeMap = {
    and: {
      label: 'AND',
    },
    or: {
      label: 'OR',
    },
  };

  const actionMap = {
    add: faPlus,
    remove: faMinus,
  };

  return (
    <Chip
      size="md"
      sx={{
        fontWeight: 'bold',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        width: 'fit-content',
        '--Chip-paddingInline': '0.5rem',
        ...(chipProps.size === 'md' && {
          minHeight: '2.25rem',
          maxHeight: '2.25rem',
        }),
        ...(chipProps.variant === 'plain' && {
          backgroundColor: 'background.default',
          color: 'primary.main',
        }),
        ...(chipProps.variant === 'soft' && {
          color: 'primary.main',
        }),
        ...sx,
      }}
      {...chipProps}
    >
      <Typography
        startDecorator={<FontAwesomeIcon icon={actionMap[action]} />}
        textColor="inherit"
        fontWeight={700}
      >
        {typeMap[type].label}
      </Typography>
    </Chip>
  );
}
export default ConditionChip;
