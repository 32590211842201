import React from 'react';
import {
  Button,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  ModalProps,
  Stack,
  Typography,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { logger } from '@sakari-io/sakari-components';
import TextField from '../../../atoms/inputs/TextField';
import FieldWrapper from '../../../../utils/FieldWrapper';
import { useTopUpCreditMutation } from '../../../../api';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

interface ManualCreditDialogProps extends Omit<ModalProps, 'children'> {
  onClose?: (result: any) => void;
}

function ManualCreditDialog({ onClose, ...rest }: ManualCreditDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [topUpCredit, { isLoading }] = useTopUpCreditMutation();

  const handleTopUpCredit = async (values: any) => {
    logger.info({ values });

    await topUpCredit(values)
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              timeout: 10000,
              severity: 'success',
              message: t(
                'settings.billing.general.credits.manualCredit.success',
              ),
            }),
          );
          if (onClose) {
            onClose(true);
          }
        }
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data?.error?.message || t('somethingWrong'),
          }),
        );
      });
  };

  return (
    <Modal onClose={() => onClose?.(false)} {...rest}>
      <ModalDialog
        sx={{
          width: 400,
        }}
      >
        <ModalClose />
        <Typography level="title-lg" mb={2}>
          {t('settings.billing.general.credits.manualCredit.title')}
        </Typography>
        <Divider />

        <Form
          onSubmit={handleTopUpCredit}
          initialValues={{
            amount: 10,
          }}
          validate={(values) => {
            const errors: any = {};
            if (values.amount < 10) {
              errors.amount = t('validation.minAmount', {
                amount: '$10',
              });
            }
            if (values.amount > 2000) {
              errors.amount = t('validation.maxAmount', {
                amount: '$2000',
              });
            }
            return errors;
          }}
          render={({ handleSubmit, submitting, hasValidationErrors }) => (
            <>
              <Stack
                sx={{
                  py: 2,
                }}
              >
                <FieldWrapper
                  component={TextField}
                  name="amount"
                  inputType="currency"
                  type="number"
                  placeholder={0}
                  helperText={t(
                    'settings.billing.general.credits.manualCredit.description',
                  )}
                  slotProps={{
                    input: {
                      min: 10,
                      max: 2000,
                      step: 5,
                    },
                  }}
                />
                <Typography level="body-sm" textColor="secondary" marginTop={1}>
                  {t(
                    'settings.billing.general.credits.manualCredit.balanceNote',
                  )}
                </Typography>
              </Stack>
              <Divider inset="context" />
              <Stack direction="row" gap={2} pt={2} justifyContent="flex-end">
                <Button
                  onClick={handleSubmit}
                  disabled={hasValidationErrors}
                  loading={submitting || isLoading}
                >
                  {t('action.confirm')}
                </Button>
              </Stack>
            </>
          )}
        />
      </ModalDialog>
    </Modal>
  );
}
export default ManualCreditDialog;
