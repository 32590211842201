import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { BillingInfo, Country } from '@sakari-io/sakari-typings';
import { makeValidate } from 'mui-rff';
import BillingInfoForm, {
  billingInfoSchema as schema,
} from '../../General/BillingInfo/Form';
import Dialog, { DialogProps } from '../../../../components/molecules/Dialog';

interface BillingInfoDialogProps
  extends Pick<DialogProps, 'open' | 'submitting' | 'loadingText'> {
  onClose: (result: BillingInfo | false) => void;
  initialValues?: {
    country?: Country;
    postalCode?: string;
    [key: string]: any;
  };
}

function BillingInfoDialog({
  open,
  onClose,
  initialValues,
}: BillingInfoDialogProps) {
  const { t } = useTranslation();

  const handleFormSubmit = (values: any) => {
    onClose(values);
  };

  return (
    <Form
      initialValues={initialValues}
      validate={makeValidate(schema)}
      onSubmit={handleFormSubmit}
    >
      {({ values, handleSubmit, hasValidationErrors, submitting }) => (
        <Dialog
          open={open}
          title={t('settings.billing.general.info.review')}
          color="primary"
          onClose={() => onClose(false)}
          primaryTitle={t('action.confirm')}
          primaryAction={(result) => (result ? handleSubmit() : onClose(false))}
          cancelTitle={t('action.cancel')}
          cancelAction={() => onClose(false)}
          disablePrimary={
            hasValidationErrors || values.segmentUnderstanding === false
          }
          submitting={submitting}
          size="lg"
        >
          <BillingInfoForm isDisabled={() => false} />
        </Dialog>
      )}
    </Form>
  );
}
export default BillingInfoDialog;
