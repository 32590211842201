import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../../../ui/atoms/inputs/TextField';
import Select from '../../../ui/atoms/inputs/Select';
import FieldWrapper from '../../../utils/FieldWrapper';
import Setting from '../../../ui/templates/forms/SettingForm';
import Integration from '../IntegrationEdit';

const regionOptions = [
  {
    label: 'US',
    value: 'US',
  },
  {
    label: 'EU',
    value: 'EU',
  },
];

function Mailgun() {
  const { t } = useTranslation();

  return (
    <Integration name="mailgun">
      <Setting label={t('mailgun.apiKey.label')}>
        <FieldWrapper component={TextField} name="apiKey" />
      </Setting>

      <Setting label={t('mailgun.domain.label')}>
        <FieldWrapper component={TextField} name="domain" />
      </Setting>

      <Setting
        label={t('mailgun.region.label')}
        description={t('mailgun.region.description')}
      >
        <FieldWrapper
          component={Select}
          fullWidth
          name="region"
          options={regionOptions}
        />
      </Setting>
    </Integration>
  );
}

export default Mailgun;
