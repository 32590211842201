import React, { useContext, useState } from 'react';
import { Box, Stack, IconButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { EditableLabel, Tooltip } from '@sakari-io/sakari-components';
import { ContactFilter, List } from '@sakari-io/sakari-typings';
import { useAuth0 } from '@auth0/auth0-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import AddContactsDropdownButton from '../../ButtonContexts/AddContactsDropdownButton';
import ListDeleteDialog from '../../Dialogs/ListDeleteDialog';
import { useGetListQuery } from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import { downloadContacts } from '../../../../utils/downloads';
import ListActionMenu from './ActionMenu';
import BulkActionDialog from '../../Dialogs/BulkActionDialog';
import { useRemoveContactsFromListMutation } from '../../../../api/lists';
import RemoveContactDialog from '../../Dialogs/RemoveContactDialog';
import { AccountContext } from '../../../../contexts/account.context';
import { UserRole } from '../../../../constants';

interface ListToolbarProps {
  onSave: (name: string, list: string) => any;
  onAddContact: () => any;
  onUploadContacts: () => any;
  onDeleteList: (list: List) => any;
  filter: ContactFilter;
  multi?: boolean;
  selectedContactCount: number;
}

function ListToolbar({
  onSave,
  onAddContact,
  onUploadContacts,
  onDeleteList,
  filter,
  multi,
  selectedContactCount,
}: ListToolbarProps) {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { account, userRole } = useContext(AccountContext);
  const isReadOnly = userRole === UserRole.READ_ONLY;

  const [openDeleteContactsDialog, setOpenDeleteContactsDialog] =
    useState<boolean>(false);
  const [openDeleteListDialog, setOpenDeleteListDialog] =
    useState<boolean>(false);
  const [
    openRemoveContactsFromListDialog,
    setOpenRemoveContactsFromListDialog,
  ] = useState<boolean>(false);
  const [removeContactsFromList] = useRemoveContactsFromListMutation();

  const { data: list } = useGetListQuery(
    account && filter?.list && filter?.list !== 'add'
      ? {
          accountId: account.id,
          request: filter.list,
        }
      : skipToken,
  );

  const [action] = useState('');

  const onDownload = async () => {
    dispatch(
      showToast({
        severity: 'info',
        message: 'Your download will start shortly',
      }),
    );

    const accessToken = await getAccessTokenSilently();
    const data = await downloadContacts(accessToken, filter);

    const fileUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'contacts.csv');
    document.body.appendChild(link);
    link.click();
  };

  const removeContacts = () => {
    removeContactsFromList({
      listId: filter.list,
      filter: { q: filter.q, attributes: filter.attributes },
    }).then(() => {
      dispatch(
        showToast({
          severity: 'info',
          message: 'Removed Contact(s) from the list',
        }),
      );
    });
  };

  return (
    <Stack
      sx={{
        paddingBottom: 2,
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <EditableLabel
          value={
            filter?.list !== 'add' ? list?.data?.name || '' : 'New List Name'
          }
          onChange={(name: any) => {
            if (filter?.list === 'add') {
              if (list?.data) {
                onSave(name, list.data.id);
              } else {
                onSave(name, '');
              }
            } else if (list?.data) {
              onSave(name, list?.data.id);
            }
          }}
          onCancel={() => list?.data} // || lists[0])}
          placeholder="New list name"
          readOnly={isReadOnly}
        />
      </Box>
      <Stack
        sx={{
          flexFlow: 'row nowrap',
          alignItems: 'center',
        }}
      >
        {filter?.list !== 'add' && (
          <>
            <Tooltip
              title={`Download ${multi ? 'Selected' : 'All'} Contacts`}
              arrow
              placement="bottom"
            >
              <IconButton
                sx={{ borderRadius: '50%' }}
                size="md"
                onClick={() => onDownload()}
              >
                <FontAwesomeIcon icon={solid('arrow-down-to-line')} />
              </IconButton>
            </Tooltip>

            {/* {multi ? (
                      <Tooltip
                        title="Delete Selected Contacts"
                        arrow
                        placement="bottom"
                      >
                        <IconButton
                          sx={{ borderRadius: '50%' }}
                          size="md"
                          onClick={() => {
                            setAction('delete');
                            setOpenDeleteContactsDialog(true);
                          }}
                          disabled={isReadOnly}
                        >
                          <FontAwesomeIcon icon={solid('trash')} />
                        </IconButton>
                      </Tooltip>
                    ) : null} */}
            {multi ? (
              <>
                <Tooltip
                  title="Remove selected contacts from list"
                  arrow
                  placement="bottom"
                >
                  <IconButton
                    sx={{ borderRadius: '50%' }}
                    size="md"
                    onClick={() => {
                      setOpenRemoveContactsFromListDialog(true);
                    }}
                    disabled={isReadOnly}
                  >
                    <FontAwesomeIcon icon={solid('xmark')} />
                  </IconButton>
                </Tooltip>
                <RemoveContactDialog
                  selectedContactCount={selectedContactCount}
                  onClose={() => {
                    setOpenRemoveContactsFromListDialog(false);
                  }}
                  onSubmit={removeContacts}
                  open={openRemoveContactsFromListDialog}
                />
              </>
            ) : null}
          </>
        )}
        <ListDeleteDialog
          onClose={(result) => {
            setOpenDeleteListDialog(false);
            if (result && list?.data) {
              onDeleteList(list.data);
            }
          }}
          open={openDeleteListDialog}
        />
        <Box style={{ marginLeft: '20px' }}>
          <AddContactsDropdownButton
            onAdd={onAddContact}
            onUpload={onUploadContacts}
            disabled={filter?.list === 'add' || isReadOnly}
          />
        </Box>
        {filter?.list && (
          <ListActionMenu
            onDelete={() => setOpenDeleteListDialog(true)}
            disabled={isReadOnly}
            placement="bottom-end"
          />
        )}

        <BulkActionDialog
          action={action}
          onClose={() => {
            setOpenDeleteContactsDialog(false);
          }}
          open={openDeleteContactsDialog}
          selectedItemsCount={selectedContactCount}
        />
      </Stack>
    </Stack>
  );
}

export default ListToolbar;
