import React from 'react';
import { CircularProgress } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface BulkActionDialogProps {
  action: string;
  open: boolean;
  onClose: (result?: boolean) => void;
  selectedItemsCount: number;
  isCounting?: boolean;
}

function BulkActionDialog({
  action,
  open,
  onClose,
  selectedItemsCount,
  isCounting,
}: BulkActionDialogProps) {
  const { t } = useTranslation();

  let confirmLabel;

  if (isCounting) {
    confirmLabel = <CircularProgress size="sm" />;
  } else if (action === 'delete') {
    confirmLabel = 'yesDelete';
  } else {
    confirmLabel = 'yesBlock';
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      confirmLabel={confirmLabel}
      disableConfirm={isCounting}
      type="danger"
      header={
        isCounting
          ? 'Calculating..'
          : `${action === 'delete' ? t('delete') : t('block')} ${selectedItemsCount} ${t(
              'items.contact',
              {
                count: selectedItemsCount || 1,
              },
            )}?`
      }
      content={action === 'delete' ? t('cannotUndoAction') : undefined}
    />
  );
}

export default BulkActionDialog;
