import React from 'react';
import { IconButton, ButtonProps } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

interface DeleteFieldButtonProps {
  onClick: () => void;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  sx?: object;
  variant?: ButtonProps['variant'];
}

function DeleteFieldButton({
  onClick,
  disabled,
  size,
  sx,
  variant,
}: DeleteFieldButtonProps) {
  return (
    <IconButton
      variant={variant || 'outlined'}
      size={size || 'md'}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
    >
      <FontAwesomeIcon icon={faTrash} style={{ width: '12px' }} />
    </IconButton>
  );
}

export default DeleteFieldButton;
