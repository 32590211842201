import * as React from 'react';
import Checkbox, { CheckboxProps } from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import _ from 'lodash';
import { ListItemContent, Stack, Typography } from '@mui/joy';
import InputControl from '../../molecules/InputControl';

export interface CheckboxData {
  label: string | number | React.ReactElement;
  value: string;
  description?: string | number | React.ReactElement;
}

interface CheckboxesProps
  extends Omit<CheckboxProps, 'onChange' | 'value' | 'checked' | 'label'> {
  label?: string;
  items: CheckboxData[];
  helperText?: string;
  value: { [key: string]: boolean };
  onChange: (v: { [key: string]: boolean }) => any;
  row?: boolean;
  error?: boolean;
  disabled?: boolean;
  labelInfo?: string;
  fullWidth?: boolean;
  isDisabled?: (item: CheckboxData) => boolean;
  columns?: number;
  sx?: any;
}

function Checkboxes({
  id = 'checkbox-group',
  label,
  items,
  helperText,
  value = {},
  onChange,
  row,
  error,
  labelInfo,
  fullWidth,
  disabled,
  isDisabled,
  size = 'sm',
  columns = 1,
  sx,
  ...rest
}: CheckboxesProps) {
  const handleChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    item: CheckboxData,
  ) => {
    onChange(
      _.assign({}, value, { [item.value]: evt.target.checked || false }),
    );
  };

  return (
    <InputControl
      {...{
        id,
        label,
        helperText,
        error,
        disabled,
        labelInfo,
        fullWidth,
      }}
    >
      <Stack>
        <List
          size={size}
          aria-labelledby={id}
          role="group"
          orientation={row ? 'horizontal' : 'vertical'}
          sx={{
            '--ListItem-paddingY': 0,
            '--ListItem-paddingX': 0,
            '--ListItem-minHeight': 0,
            gap: `calc(var(--joy-radius-${size}) * 2)`,
            alignItems: 'flex-start',
            ...(row && {
              flexFlow: 'row wrap',
            }),

            '.MuiCheckbox-checkbox': {
              borderColor: error ? 'error.main' : 'initial',
            },
            '& .MuiListItem-root': {
              ...(row
                ? {
                    flexFlow: 'column nowrap',
                    alignItems: 'flex-start',
                    width: `calc(100% / ${columns} - (var(--joy-radius-${size}) * 2))`,
                  }
                : {
                    width: '100%',
                  }),
            },
            ...sx,
          }}
        >
          {items.map((item) => {
            return (
              <ListItem key={item.value}>
                <Checkbox
                  label={item.label}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, item)
                  }
                  value={item.value}
                  name={item.value}
                  checked={value[item.value]}
                  defaultChecked={value[item.value]}
                  disabled={disabled || (isDisabled && isDisabled(item))}
                  size={size}
                  {...rest}
                />
                {item.description && (
                  <ListItemContent
                    sx={{
                      marginTop: `0.25rem`,
                      marginLeft: `1.40rem`,
                    }}
                  >
                    <Typography level="body-sm">{item.description}</Typography>
                  </ListItemContent>
                )}
              </ListItem>
            );
          })}
        </List>
      </Stack>
    </InputControl>
  );
}

export default Checkboxes;
