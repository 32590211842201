import { Stack, Typography, Button } from '@mui/joy';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@sakari-io/sakari-components';
import Setting from '../../../ui/templates/forms/SettingForm';
import { useRefreshApiCredentialsMutation } from '../../../api';
import ConfirmCredentialsDialog from './ConfirmCredentialsDialog';
import { showToast } from '../../../redux/reducers/toast';
import { useAppDispatch } from '../../../redux';
import { AccountContext } from '../../../contexts/account.context';
import Webhooks from './Webhooks';
import CopyButton from '../../../ui/organisms/ButtonContexts/CopyButton';

function API() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [credentials, setCredentials] = useState<{
    id: string;
    secret: string;
  }>();

  const [addCredentials, { isLoading }] = useRefreshApiCredentialsMutation();

  const refreshCredentials = () => {
    setRefreshing(true);
    addCredentials(null)
      .unwrap()
      .then(({ data }) => setCredentials(data))
      .finally(() => {
        setRefreshing(false);
      });
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      showToast({
        severity: 'info',
        message: 'Copied to clipboard',
      }),
    );
  };

  const renderLoading = () => (
    <Stack height="183px">
      <Loader size={100} label="Loading API Credentials" />
    </Stack>
  );

  const renderSettings = () => (
    <>
      <Setting
        label={t('settings.api.accountId.label')}
        description={t('settings.api.accountId.description', {
          defaultValue: '',
        })}
      >
        <Typography
          sx={{
            '#account-id-button': {
              opacity: 0,
            },
            '&:hover': {
              '#account-id-button': { opacity: 1 },
            },
          }}
          onClick={() => {
            copyText(account?.id || '');
          }}
          endDecorator={
            <CopyButton id="account-id-button" text={account?.id} />
          }
        >
          {account?.id}
        </Typography>
      </Setting>

      <Setting
        label={t('settings.api.clientId.label')}
        description={t('settings.api.clientId.description')}
      >
        <Typography
          sx={{
            '#account-id-button': {
              opacity: 0,
            },
            '&:hover': {
              '#account-id-button': { opacity: 1 },
            },
          }}
          onClick={() => {
            copyText(credentials?.id || account?.apiCredentials?.id || '');
          }}
          endDecorator={
            <CopyButton
              id="account-id-button"
              text={credentials?.id || account?.apiCredentials?.id}
            />
          }
        >
          {credentials?.id || account?.apiCredentials?.id}
        </Typography>
      </Setting>

      <Setting
        label={t('settings.api.clientSecret.label')}
        description={t('settings.api.clientSecret.description')}
      >
        {credentials?.secret ? (
          <Typography
            sx={{
              '#secret-id-button': {
                opacity: 0,
              },
              '&:hover': {
                '#secret-id-button': { opacity: 1 },
              },
            }}
            onClick={() => {
              copyText(credentials.secret);
            }}
            endDecorator={
              <CopyButton id="secret-id-button" text={credentials.secret} />
            }
          >
            {credentials.secret}
          </Typography>
        ) : (
          <>
            <Typography>**************************</Typography>
            <Button
              disabled={isLoading || refreshing}
              onClick={() => setDialogOpen(true)}
              color="neutral"
              variant="outlined"
            >
              {t('settings.api.refresh.button')} Client Secret
            </Button>
          </>
        )}
      </Setting>
    </>
  );

  const renderGenerateSettings = () => (
    <Setting
      label={t('settings.api.generate.label')}
      description={t('settings.api.generate.description')}
    >
      <Button
        disabled={isLoading || refreshing}
        onClick={() => refreshCredentials()}
        color="neutral"
        variant="outlined"
      >
        {t('settings.api.generate.button')}
      </Button>
    </Setting>
  );

  const renderContent = () => {
    if (isLoading) {
      return renderLoading();
    }
    if (account?.apiCredentials || credentials) {
      return renderSettings();
    }
    return renderGenerateSettings();
  };

  return (
    <>
      <Stack spacing={2}>{renderContent()}</Stack>
      <Webhooks />
      <ConfirmCredentialsDialog
        open={dialogOpen}
        onClose={(result) => {
          setDialogOpen(false);
          if (result) {
            refreshCredentials();
          }
        }}
      />
    </>
  );
}

export default API;
