import React, { forwardRef } from 'react';
import {
  MenuItem as MenuItemBase,
  MenuItemProps as MenuItemPropsBase,
  ListItemDecorator,
  ListItemContent,
  Typography,
} from '@mui/joy';

export interface MenuItemProps
  extends Omit<MenuItemPropsBase, 'startAction' | 'endAction'> {
  label?: string;
  description?: string | React.ReactNode;
  itemChildren?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  wrap?: boolean;
  forwardedRef?: React.Ref<HTMLDivElement>;
  size?: 'sm' | 'md' | 'lg';
}

function CustomMenuItem({
  forwardedRef,
  label,
  description,
  itemChildren,
  startIcon,
  endIcon,
  wrap = false,
  children,
  sx,
  ...rest
}: MenuItemProps) {
  const renderItemDecorator = (icon?: React.ReactNode) => {
    if (!icon) return null;

    return <ListItemDecorator>{icon}</ListItemDecorator>;
  };

  const listItemSx = {
    overflow: 'hidden',
    whiteSpace: wrap ? 'wrap' : 'nowrap',
    textOverflow: 'ellipsis',
  };

  return (
    <MenuItemBase
      ref={forwardedRef}
      sx={{
        flex: 0,
        minBlockSize: 'auto',
        '& .MuiListItemDecorator-root:last-child': {
          justifyContent: 'flex-end',
          flex: '1',
          mr: 0,
        },
        ...sx,
      }}
      {...rest}
    >
      {renderItemDecorator(startIcon)}
      {label ? (
        <ListItemContent
          sx={{
            fontWeight: description ? 'bold' : 'normal',
            ...listItemSx,
          }}
        >
          {label}
          {description && (
            <Typography level="body-sm">{description}</Typography>
          )}
          {itemChildren || null}
        </ListItemContent>
      ) : null}
      {children}
      {renderItemDecorator(endIcon)}
    </MenuItemBase>
  );
}
const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>((props, ref) => (
  <CustomMenuItem {...props} forwardedRef={ref} />
));
export default MenuItem;
