import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import toastReducer from './toast';
import callsReducer from './calls';
import { sakariApi } from '../../api/rtk';
import conversationSlice from '../slices/conversation';
import themeSlice from '../slices/themeSlice';
import workflowsReducer from './workflow';
import { voiceApi } from '../../api/voice';

// TODO consider removing some of these
const reducers = combineReducers({
  login: loginReducer,
  toast: toastReducer,
  calls: callsReducer,
  [sakariApi.reducerPath]: sakariApi.reducer,
  [voiceApi.reducerPath]: voiceApi.reducer,
  conversation: conversationSlice.reducer,
  theme: themeSlice.reducer,
  workflow: workflowsReducer,
});

export default reducers;

// export type RootState = ReturnType<typeof reducers>;
