import React, { useState, useCallback, useContext, useMemo } from 'react';
import {
  GridSortModel,
  GridCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridColDef,
  GridActionsCellItem,
  GridFilterModel,
  GridFilterItem,
} from '@mui/x-data-grid-pro';

import {
  AccountAttribute,
  Condition,
  Contact,
  ContactFilter,
} from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Box, Stack, SvgIcon } from '@mui/joy';
import { NumberWithFlag, Tooltip } from '@sakari-io/sakari-components';
import _ from 'lodash';
import { faBan, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useGetContactsQuery } from '../../../../api';

import DataGrid from '../../../molecules/DataGrid';

import MessageMenuButton from '../../ButtonContexts/MessageMenuButton';
import CallMenuButton from '../../ButtonContexts/CallMenuButton';

import BlockContactDialog from '../../Dialogs/BlockContactDialog';
import EmptyResult from '../../../molecules/EmptyResult';
import AddContactsDropdownButton from '../../ButtonContexts/AddContactsDropdownButton';
import { AccountContext } from '../../../../contexts/account.context';
import { UserRole } from '../../../../constants';
import DeleteContactDialog from '../../Dialogs/DeleteContactDialog';
import Helper from '../../../../utils/helper';

const COOKIE_NAME = 'contacts-columns';
interface ContactsGridProps {
  accountId: string;
  filter?: ContactFilter;
  onFilterChange?: (filter?: Condition[]) => any;
  onSelectionModelChange: (
    model: GridRowSelectionModel,
    attributes?: Condition,
    selectedAllRows?: boolean,
  ) => any;
  selectedRow?: Contact;
  onSelectedRowChange: (contact: Contact) => any;
  onAddContact: () => any;
  onUploadContacts: () => any;
}

interface ExtendedContactFilter extends Omit<ContactFilter, 'attributes'> {
  attributes: string;
  listId?: string;
  [key: string]: any;
}

function ContactsGrid({
  accountId,
  filter,
  onFilterChange,
  selectedRow,
  onSelectedRowChange,
  onSelectionModelChange,
  onAddContact,
  onUploadContacts,
}: ContactsGridProps) {
  const { t } = useTranslation();
  const { contactAttributes, userRole, profile } = useContext(AccountContext);
  const isReadOnly = userRole === UserRole.READ_ONLY;

  const transformedFilter: ExtendedContactFilter = useMemo(() => {
    const newFilter: any = _.cloneDeep(filter) ?? {};

    if (newFilter.attributes) {
      newFilter.attributes = newFilter.attributes.filter((attr: Condition) => {
        if (attr.attribute.startsWith('attribute')) {
          return true;
        }
        newFilter[attr.attribute] = attr.value;
        return false;
      });
    }

    newFilter.attributes = newFilter.attributes
      ?.map((attr: Condition) => {
        return `${attr.attribute}|${attr.comparator}|${attr.value}`;
      })
      .join('|');
    newFilter.listId = newFilter.list;
    delete newFilter.id;

    return newFilter;
  }, [filter]);

  const handleFilterChange = useCallback(
    (model: GridFilterModel) => {
      const attributes: Condition[] = model?.items?.map(
        (item: GridFilterItem) => ({
          attribute: item.field,
          comparator: item.operator,
          value: item.value,
        }),
      );
      if (attributes) onFilterChange?.(attributes);
    },
    [onFilterChange],
  );

  const defaultColumns = [
    {
      field: 'firstName',
      headerName: t('firstName'),
    },
    {
      field: 'lastName',
      headerName: t('lastName'),
    },
    {
      field: 'mobile',
      headerName: t('mobile'),
      minWidth: 250,
      flex: 1,
      maxWidth: 350,
      renderCell: (params: GridCellParams) => {
        return (
          <Stack direction="row" alignItems="center" flex={1} gap={1}>
            <Box
              flex={1}
              sx={{
                ...(!params.row.valid && {
                  color: 'var(--joy-palette-danger-plainColor)',
                }),
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <NumberWithFlag mobile={params.row.mobile} size={13} rounded />
                {params.row.optIn ? (
                  <Tooltip
                    title={t('optedInOn', {
                      date: Helper.formatDateTime(params.row.optIn),
                    })}
                    placement="top"
                  >
                    <FontAwesomeIcon
                      icon={solid('badge-check')}
                      style={{ color: 'var(--joy-palette-primary-500)' }}
                    />
                  </Tooltip>
                ) : null}
              </Stack>
            </Box>
            {params.row.error ? (
              <Tooltip
                title={params.row.error.description ?? t('numberIsInvalid')}
                arrow
                placement="bottom"
              >
                <SvgIcon color="danger" fontSize="xs">
                  <FontAwesomeIcon icon={regular('circle-exclamation')} />
                </SvgIcon>
              </Tooltip>
            ) : null}

            {params.row.blocked && params.row.valid ? (
              <Tooltip title={t('blocked')} arrow placement="bottom">
                <SvgIcon fontSize="xs">
                  <FontAwesomeIcon icon={regular('ban')} />
                </SvgIcon>
              </Tooltip>
            ) : null}
          </Stack>
        );
      },
    },
    {
      field: 'email',
      headerName: t('email'),
    },
  ];

  const columns: GridColDef[] = useMemo(
    () => [
      ...defaultColumns.map((col) => ({
        ...col,
        minWidth: 150,
        flex: 1,
        filterable: true,
        pinnable: false,
        sortable: false,
        disableColumnMenu: true,
      })),
      ...contactAttributes.map((attr: AccountAttribute) => ({
        field: `attributes.${attr.name}`,
        headerName: attr.label,
        minWidth: 150,
        filterable: false,
        pinnable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridCellParams) => {
          if (!params.row.attributes) return '';

          const value = params.row.attributes[attr.name];

          if (!value) return '';

          if (attr?.dataType === 'date') {
            return (
              new Date(value)?.toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }) ?? ''
            );
          }
          if (attr?.dataType === 'datetime') {
            return (
              new Date(value)?.toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }) ?? ''
            );
          }
          return value;
        },
      })),
      {
        field: 'actions',
        type: 'actions',
        minWidth: 130,
        headerAlign: 'right',
        align: 'right',
        pinnable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {
          const actions = [];
          if (!params.row.blocked) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <FontAwesomeIcon
                    className="blockIcon actionButton"
                    icon={faBan}
                  />
                }
                onClick={(e: any) => onBlockContact(e, params.row)}
                label="Block"
                showInMenu
              />,
            );
            actions.push(
              <MessageMenuButton
                accountId={accountId}
                contact={params.row}
                disabled={params.row.valid !== true || isReadOnly}
                placement="bottom-end"
              />,
            );
            actions.push(
              <CallMenuButton
                accountId={accountId}
                contact={params.row}
                user={profile}
                disabled={params.row.valid !== true || isReadOnly}
                placement="bottom-end"
              />,
            );
          }

          actions.push(
            <GridActionsCellItem
              icon={
                <FontAwesomeIcon
                  className="blockIcon actionButton"
                  icon={faTrash}
                />
              }
              onClick={(e: any) => onDeleteContact(e, params.row)}
              label={t('action.delete')}
              showInMenu
            />,
          );

          return actions;
        },
      },
    ],
    defaultColumns,
  );

  const [openBlockDialog, setOpenBlockDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [sortModel] = useState<GridSortModel>([]);
  const [blockContact, setBlockContact] = useState<Contact>();
  const [deleteContact, setDeleteContact] = useState<Contact>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sort, setSort] = useState<string>('');

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    const sortValue = sortModel[0].field
      ? `${sortModel[0].field}-${sortModel[0].sort}`
      : '';
    setSort(sortValue);
  }, []);

  const onBlockContact = (e: any, contact: Contact) => {
    setBlockContact(contact);
    setOpenBlockDialog(true);
  };

  const onDeleteContact = (e: any, contact: Contact) => {
    setDeleteContact(contact);
    setOpenDeleteDialog(true);
  };

  const getEmptyResultHeading = () => {
    if (filter?.list && filter?.attributes) {
      return t('noneFound', {
        items: t('items.contact_other'),
      });
    }
    return t('getStarted');
  };

  const getEmptyResultText = () => {
    if (filter?.list && filter?.attributes) {
      return null;
    }
    return t('firstContact');
  };

  return (
    <>
      <DataGrid
        cookie={COOKIE_NAME}
        columns={columns}
        initialState={{
          pinnedColumns: { left: ['__check__'], right: ['actions'] },
        }}
        selectedRow={selectedRow}
        onSelectedRowChange={onSelectedRowChange}
        filter={{
          accountId,
          request: transformedFilter,
        }}
        loadData={useGetContactsQuery}
        loadingText="Loading contacts..."
        disableRowSelectionOnClick
        renderNoData={
          <EmptyResult
            item="contacts"
            heading={getEmptyResultHeading()}
            text={getEmptyResultText()}
          >
            <AddContactsDropdownButton
              onAdd={onAddContact}
              onUpload={onUploadContacts}
            />
          </EmptyResult>
        }
        sortModel={sortModel}
        onSortModelChange={onSortChange}
        checkboxSelection
        onSelectionModelChange={onSelectionModelChange}
        showColumnVisibility
        headerFilters
        disableColumnFilter
        onFilterModelChange={handleFilterChange}
      />

      {blockContact ? (
        <BlockContactDialog
          onClose={() => setOpenBlockDialog(false)}
          open={openBlockDialog}
          contact={blockContact}
        />
      ) : null}

      {deleteContact ? (
        <DeleteContactDialog
          onClose={() => setOpenDeleteDialog(false)}
          open={openDeleteDialog}
          contact={deleteContact}
        />
      ) : null}
    </>
  );
}

export default ContactsGrid;
