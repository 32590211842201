import React, { useState, useEffect, useContext } from 'react';
import { Stack, Grid, Switch } from '@mui/joy';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import _ from 'lodash';
import { logger } from '@sakari-io/sakari-components';
import { useGetGroupQuery, useDeleteGroupMutation } from '../../api';
import { useAppDispatch } from '../../redux';
import { showToast } from '../../redux/reducers/toast';
import PageSection from '../../ui/templates/PageSection';

import Tags from '../../ui/organisms/forms/TagsInput';

import FieldWrapper from '../../utils/FieldWrapper';
import { TextField } from '../../ui';

import Group, { GroupViewMode } from '../Group';
import GroupUsers from '../../ui/organisms/forms/GroupUsers';
import GroupSenders from '../../ui/organisms/forms/GroupSenders';
import ConfirmationDialog from '../../ui/molecules/ConfirmationDialog';
import { AccountContext } from '../../contexts/account.context';
import Setting from '../../ui/templates/forms/SettingForm';

function GroupEdit() {
  const { groupId } = useParams();
  const { account } = useContext(AccountContext);

  const { data, error, isError } = useGetGroupQuery(
    account && groupId && groupId !== 'add'
      ? {
          accountId: account.id,
          request: groupId,
        }
      : skipToken,
  );

  const { t } = useTranslation();

  logger.info('group data', data);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!groupId && groupId !== 'add' && isError) {
      dispatch(
        showToast({
          message: 'Error loading group',
          severity: 'error',
        }),
      );
      navigate('/504', { replace: true });
    }
    if (error && 'data' in error) {
      if (error.status === 404) {
        navigate('/groups');
        dispatch(
          showToast({
            message: 'Group not found',
            severity: 'info',
          }),
        );
      }
    }
  }, [groupId, navigate, error]);

  const [deleteGroup] = useDeleteGroupMutation();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onDelete = async () => {
    if (groupId) {
      deleteGroup(groupId || '');
      dispatch(
        showToast({
          severity: 'info',
          message: `${data?.data.name} ${t('groupDeleted')}`,
        }),
      );
      navigate('/groups');
    }
  };

  return (
    <Group
      view={GroupViewMode.GENERAL}
      secondaryAction={() => setShowDeleteDialog(true)}
    >
      {data?.data?.isDefault ? (
        <ConfirmationDialog
          open={showDeleteDialog}
          onClose={(result) => {
            setShowDeleteDialog(false);
            if (result) {
              window.open(
                'https://support.sakari.io/hc/en-us/articles/18181130879252',
                '_blank',
              );
            }
          }}
          header={_.startCase(
            t('unableToDeleteItem', {
              item: t('group'),
            }),
          )}
          type="warning"
          content={t('groups.defaultGroupCannotBeDeleted')}
          confirmLabel="learnMore"
          hideCancel
        />
      ) : (
        <ConfirmationDialog
          open={showDeleteDialog}
          onClose={(result) => {
            setShowDeleteDialog(false);
            if (result) {
              onDelete();
            }
          }}
          header={t('deleteItem', {
            item: t('group'),
          })}
          type="danger"
          content={t('deleteGroupConfirm')}
          confirmLabel="deleteGroup"
          onSubmit={onDelete}
        />
      )}

      <Grid container spacing={3}>
        <Grid sm={6}>
          <Stack spacing={3}>
            <PageSection title={t('groupName')} padding="25px 25px 40px 25px">
              <FieldWrapper name="name" component={TextField} fullWidth />
            </PageSection>
            <PageSection
              title="Users"
              padding={0}
              parentPadding="25px 25px 0px 15px"
            >
              <FieldWrapper
                component={GroupUsers}
                name="users"
                account={account}
              />
            </PageSection>
          </Stack>
        </Grid>
        <Grid sm={6}>
          <Stack spacing={3}>
            <PageSection title={t('addTag')} padding="25px 25px 40px 25px">
              <FieldWrapper name="tags" component={Tags} />
            </PageSection>
            <PageSection
              title={t('senders.title')}
              padding={0}
              parentPadding="25px 25px 0px 15px"
            >
              <FieldWrapper
                component={GroupSenders}
                name="senders"
                account={account}
              />
            </PageSection>

            <PageSection title="Additional Settings">
              <Setting
                label="Shared Pool Fallback"
                description="Use shared pool numbers when sending to a country not supported by this group’s numbers"
              >
                <FieldWrapper
                  type="checkbox"
                  component={Switch}
                  name="useSharedPool"
                />
              </Setting>
            </PageSection>
          </Stack>
        </Grid>
      </Grid>
    </Group>
  );
}

export default GroupEdit;
