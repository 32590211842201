import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Divider, Link, Typography, checkboxClasses } from '@mui/joy';
import { useFormState } from 'react-final-form';
import { TextField } from '../../../ui';
import FieldWrapper from '../../../utils/FieldWrapper';
import VerifyPhoneInput from '../../../ui/organisms/forms/VerifyPhoneInput';

function UserInfo() {
  const { t } = useTranslation();
  const { hasAccount } = useFormState().values;

  return (
    <>
      <FieldWrapper
        component={TextField}
        name="firstName"
        label={t('form.name.first')}
        fullWidth
        required
        autoFocus
      />
      <FieldWrapper
        component={TextField}
        name="lastName"
        label={t('form.name.last')}
        fullWidth
        required
      />
      <FieldWrapper
        component={VerifyPhoneInput}
        name="mobile"
        label={t('form.mobile.label')}
        skipVerificationDisabled={!hasAccount}
        fullWidth
        required
      />

      <FieldWrapper
        component={Checkbox}
        type="checkbox"
        size="sm"
        name="tacAccepted"
        sx={{ [`& > .${checkboxClasses.checkbox}`]: { position: 'relative' } }}
        label={
          <Typography level="body-md">
            I agree to Sakari's{' '}
            <Link href="https://www.sakari.io/terms-service" target="_blank">
              terms and conditions
            </Link>{' '}
            and{' '}
            <Link href="https://sakari.io/privacy-policy" target="_blank">
              privacy policy
            </Link>
          </Typography>
        }
      />
      <Divider />
      <Typography level="body-md">
        {t('registration.userInfo.optin.description')}
      </Typography>
      <FieldWrapper
        component={Checkbox}
        name="communication.email"
        type="checkbox"
        label={t('registration.userInfo.optin.email')}
        size="sm"
        sx={{
          alignSelf: 'flex-start',
        }}
      />
      <FieldWrapper
        component={Checkbox}
        name="communication.sms"
        type="checkbox"
        label={t('registration.userInfo.optin.sms')}
        size="sm"
        sx={{
          alignSelf: 'flex-start',
        }}
      />
    </>
  );
}
export default UserInfo;
