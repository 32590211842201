import React, { useContext, useEffect, useState } from 'react';
import { Logo, Loader, useDebouncedValue } from '@sakari-io/sakari-components';
import { Stack, Typography, Divider, Button, Box } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { Account } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import AccountListItem from './AccountListItem';
import { useGetAccountsQuery } from '../../api';
import LogoutLink from '../../ui/organisms/ButtonContexts/LogoutLink';
import { AccountContext } from '../../contexts/account.context';
import SearchBar from '../../ui/molecules/inputs/SearchBar';
import Auth0Template from '../../ui/templates/Auth0Template';

function SharedAccounts() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { changeAccount, profile, isLoading, isFetchingProfile } =
    useContext(AccountContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
  const {
    data,
    isLoading: isLoadingAccounts,
    isFetching: isFetchingAccounts,
  } = useGetAccountsQuery({
    name: debouncedSearchTerm,
    limit: 5,
  });

  useEffect(() => {
    if (!isFetchingProfile && (!profile || !(profile as any).tacAccepted)) {
      navigate('/createprofile');
    }
  }, [profile, isFetchingProfile]);

  useEffect(() => {
    if (!isLoadingAccounts && data?.data) {
      const numAccounts = data.data.length ?? 0;
      if (!searchTerm && data && numAccounts < 1) {
        navigate('/register');
      } else if (!debouncedSearchTerm && numAccounts === 1 && data?.data) {
        // Auto select account if only one account is found
        onSelectAccount(data.data[0]);
      }
    }
  }, [data, isLoadingAccounts]);

  const onSelectAccount = (account: Account) => {
    changeAccount(account.id);
    navigate('/inbox');
  };

  if (isLoading) {
    return <Loader label="Loading..." size={200} />;
  }

  if (isLoadingAccounts) {
    return <Loader label="Searching for Accounts..." size={200} />;
  }

  return (
    <Auth0Template>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <Logo size={50} />
        <Typography level="h4" fontWeight="bold" sx={{ textAlign: 'center' }}>
          {t('sharedAccounts.joinExisting')}
        </Typography>
        <Typography level="body-md">
          {t('sharedAccounts.contactAdmin')}
        </Typography>
      </Stack>
      <Stack
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2,
          width: 350,
          overflow: 'hidden',
          flex: '1 0 0',
          maxHeight: '35rem',
        }}
      >
        <SearchBar
          value={searchTerm}
          onChange={setSearchTerm}
          fullWidth
          sx={{ m: 0.5 }}
        />
        <Stack
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            overflow: 'auto',
            p: 0.5,
            gap: 1,
            flex: '1 0 0',
          }}
        >
          {isFetchingAccounts ? (
            <Box
              sx={{
                position: 'relative',
                textAlign: 'center',
                width: '100%',
                height: '100%',
                '.MuiTypography-root': {
                  fontSize: '1.5rem',
                },
              }}
            >
              <Loader label="Searching accounts..." size={150} />
            </Box>
          ) : (
            data?.data.map((acc) => (
              <AccountListItem
                key={acc.id}
                value={acc}
                onSelect={onSelectAccount}
              />
            ))
          )}
          {data?.data?.length === 0 && !isFetchingAccounts ? (
            <Typography sx={{ textAlign: 'center', marginY: 'auto' }}>
              {t('sharedAccounts.noneFound')}
            </Typography>
          ) : null}
        </Stack>
        {data?.data?.length ? <Divider>or</Divider> : null}

        <Button onClick={() => navigate('/register')}>
          {t('createAccount')}
        </Button>
        <LogoutLink />
      </Stack>
    </Auth0Template>
  );
}

export default SharedAccounts;
