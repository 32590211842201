import React, { useContext, useMemo } from 'react';
import * as Yup from 'yup';
import { Form } from 'react-final-form';
import { makeValidate } from 'mui-rff';
import { Contact, List } from '@sakari-io/sakari-typings';
import {
  Box,
  Typography,
  Stack,
  Sheet,
  IconButton,
  Tooltip,
  ListDivider,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DatePicker, DateTimePicker } from '@sakari-io/sakari-components';
import { ListItemDataRow } from '../../../../../types';

import FieldWrapper from '../../../../../utils/FieldWrapper';

import ContactDetailsHeader from '../ContactDetailsHeader';

import {
  useCreateContactMutation,
  useUpdateContactMutation,
  useAddContactToListMutation,
  useDeleteContactFromListMutation,
  useGetInstalledIntegrationsQuery,
} from '../../../../../api';

import { showToast } from '../../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../../redux';
import AddToListMenuButton from '../../../ButtonContexts/AddToListMenuButton';
import ListsList from '../../../Lists/ContactListsList';
import PhoneInput from '../../../../atoms/inputs/PhoneInput';
import EditableText from '../../../../atoms/inputs/EditableText';
import Container from '../../../../templates/Container';
import CopyButton from '../../../ButtonContexts/CopyButton';
import Helper from '../../../../../utils/helper';
import IntegrationsList from '../../../Lists/ContactIntegrationsList';
import MessageMenuButton from '../../../ButtonContexts/MessageMenuButton';
import { AccountContext } from '../../../../../contexts/account.context';
import { UserRole } from '../../../../../constants';
import CallMenuButton from '../../../ButtonContexts/CallMenuButton';

const fieldMinWidth = 70;

interface ContactEditProps {
  contact: Contact;
  setContact: (contact: Contact) => void;
  setDrawerOpen: (value: boolean) => void;
  onShowBlockDialog: () => void;
  onShowDeleteDialog: () => void;
  lists: List[];
  hideMessageButton?: boolean;
  listId?: string;
}

function ContactEdit({
  contact,
  setContact,
  setDrawerOpen,
  onShowBlockDialog,
  onShowDeleteDialog,
  lists,
  hideMessageButton,
  listId,
}: ContactEditProps) {
  const navigate = useNavigate();
  const { account, contactAttributes, userRole, profile } =
    useContext(AccountContext);
  const isReadOnly = userRole === UserRole.READ_ONLY;

  const displayName =
    contact?.id !== 'new' && Helper.getNameOrMobileNumber(contact);

  const [createContact] = useCreateContactMutation();
  const [updateContact] = useUpdateContactMutation();
  const [deleteContactFromList] = useDeleteContactFromListMutation();
  const [addContactToList] = useAddContactToListMutation();

  const { data: integrationsData } = useGetInstalledIntegrationsQuery(
    account
      ? {
          accountId: account?.id,
          request: {
            features: 'contacts',
          },
        }
      : skipToken,
  );

  const integrations = useMemo(
    () =>
      (integrationsData?.data || []).filter(
        (integration) =>
          (contact as any)[integration.sourceId]?.id ||
          (contact as any)[integration.sourceId]?.vid,
      ),
    [integrationsData, contact],
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const saveChanges = async (values: any) => {
    if (!values.email) values.email = '';
    switch (contact?.id) {
      case 'new':
        await createContact(values)
          .unwrap()
          .then(async (data: any) => {
            if (data.success) {
              setContact(data?.data);
              if (listId) {
                addNewContactToList(data?.data);
              }

              dispatch(
                showToast({
                  severity: 'info',
                  message: t('itemCreated', {
                    item: _.capitalize(t('items.contact_one')),
                  }),
                }),
              );
            }
            if (!data?.data.valid) {
              dispatch(
                showToast({
                  severity: 'warning',
                  message: data.data.error.description,
                }),
              );
              setTimeout(() => {
                setDrawerOpen(true);
              }, 2000);
            }
          })
          .catch((err) => {
            dispatch(
              showToast({
                severity: 'error',
                message: err.data.error.message,
              }),
            );
          });
        break;
      default:
        await updateContact(values)
          .unwrap()
          .then(() => {
            dispatch(
              showToast({
                severity: 'info',
                message: t('updateSuccess', {
                  item: _.capitalize(t('items.contact_one')),
                }),
              }),
            );
          })
          .catch((err) => {
            dispatch(
              showToast({
                severity: 'error',
                message: err?.data?.error?.message || 'Unknown error',
              }),
            );
          });
    }
  };

  const schema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    mobile: Yup.object().shape({
      number: Yup.string().required('Phone number is required'),
      country: Yup.string().required('Phone number is required'),
    }),
    email: Yup.string().email('Please enter a valid email.'),
    lists: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
      }),
    ),
  });

  const validate = makeValidate(schema);

  const addNewContactToList = (newContact: Contact) => {
    addContactToList({
      listId,
      contactId: newContact.id,
    })
      .then(() => {
        dispatch(
          showToast({
            severity: 'info',
            message: 'Contact added to list',
          }),
        );
      })
      .catch((err) => {
        showToast({
          severity: 'error',
          message: err.data.error.message,
        });
      });
  };

  const onAddToList = (selected: ListItemDataRow) => {
    addContactToList({
      listId: selected.id,
      contactId: contact.id,
    })
      .then(() => {
        dispatch(
          showToast({ severity: 'info', message: 'Contact added to list' }),
        );
      })
      .catch((err) => {
        showToast({
          severity: 'error',
          message: err.data.error.message,
        });
      });
  };

  const onRemoveFromList = async (selected: ListItemDataRow) => {
    deleteContactFromList({
      listId: selected.id,
      contactId: contact.id,
    })
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              severity: 'info',
              message: 'Contact removed from list',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data.error.message,
          }),
        );
      });
  };

  const renderOptInInfo = (
    status: 'optIn' | 'optOut',
    date: Date | string | null,
  ) => {
    if (!date) return null;

    return (
      <Stack direction="row" spacing={5}>
        <Typography level="body-sm" fontWeight={500}>
          {t(status)}
        </Typography>
        <Typography level="body-sm" fontWeight={500}>
          {Helper.formatDateTime(date)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Form
      onSubmit={saveChanges}
      initialValues={contact}
      validate={validate}
      render={({ form, hasValidationErrors, pristine, submitting }) => {
        return (
          <>
            <ContactDetailsHeader
              contact={contact}
              setDrawerOpen={(state) => {
                form.reset();
                setDrawerOpen(state);
              }}
              setOpenBlockDialog={onShowBlockDialog}
              setOpenDeleteDialog={onShowDeleteDialog}
              readOnly={isReadOnly}
              canSave={
                (contact.id === 'new' || !pristine) &&
                !hasValidationErrors &&
                !submitting
              }
              onSave={() => {
                form.submit();
              }}
            />
            <Container
              sx={{
                gap: 2,
                mt: 6,
                '& .MuiFormControl-root > .MuiStack-root:first-child': {
                  width: '25%',
                  overflow: 'hidden',
                },
              }}
            >
              <Stack gap={2}>
                {contact.id !== 'new' ? (
                  <>
                    <Typography
                      level="h4"
                      alignSelf="center"
                      sx={{
                        '#copy-button': {
                          position: 'absolute',
                          marginTop: '-0.75rem',
                          opacity: 0,
                        },
                        '&:hover #copy-button': {
                          opacity: 1,
                        },
                      }}
                      endDecorator={<CopyButton text={displayName} />}
                    >
                      {displayName}
                    </Typography>
                    <Stack
                      sx={{
                        flex: '1 0 1',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      {hideMessageButton ? null : (
                        <Box>
                          <MessageMenuButton
                            accountId={account?.id || ''}
                            contact={contact}
                            disabled={
                              !contact.valid || !!contact.blocked || isReadOnly
                            }
                            placement="bottom"
                          />
                        </Box>
                      )}
                      {/* {hideCallButton ? null : ( */}
                      <Box>
                        <CallMenuButton
                          accountId={account?.id || ''}
                          contact={contact}
                          user={profile}
                          disabled={
                            !contact.valid ||
                            !!contact.blocked ||
                            !profile ||
                            isReadOnly
                          }
                          placement="bottom"
                        />
                      </Box>
                      {/* )} */}

                      {contact?.email ? (
                        <Box>
                          <Tooltip
                            title={t('email') ?? ''}
                            placement="bottom"
                            arrow
                          >
                            <IconButton
                              sx={{ borderRadius: '50%' }}
                              color="neutral"
                              aria-label="email"
                              size="smRound"
                              onClick={() =>
                                window.open(`mailto:${contact.email}`)
                              }
                            >
                              <FontAwesomeIcon icon={solid('envelope')} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : null}
                    </Stack>
                  </>
                ) : null}
                <FieldWrapper
                  component={EditableText}
                  name="firstName"
                  label={t('firstName')}
                  size="sm"
                  readOnly={isReadOnly}
                  labelWidth={fieldMinWidth}
                />

                <FieldWrapper
                  component={EditableText}
                  label={t('lastName')}
                  name="lastName"
                  size="sm"
                  readOnly={isReadOnly}
                  labelWidth={fieldMinWidth}
                />
                <FieldWrapper
                  component={PhoneInput}
                  name="mobile"
                  label={`${t('mobile')}`}
                  size="sm"
                  required
                  type="tel"
                  editOnClick
                  orientation="horizontal"
                  readOnly={isReadOnly}
                  error={!contact?.valid}
                />

                {contact.optIn || contact.blocked ? (
                  <Stack direction="row" spacing={3}>
                    {contact.optIn
                      ? renderOptInInfo('optIn', contact.optIn)
                      : null}
                    {contact.blocked
                      ? renderOptInInfo('optOut', contact.blocked)
                      : null}
                  </Stack>
                ) : null}

                <FieldWrapper
                  component={EditableText}
                  label={t('email')}
                  name="email"
                  type="email"
                  fullWidth
                  size="sm"
                  placeholder="email@email.com"
                  readOnly={isReadOnly}
                  labelWidth={fieldMinWidth}
                />

                {contactAttributes.map((attr) => {
                  const props = {
                    id: attr.id,
                    label: attr.label,
                    name: `attributes.${attr.name}`,
                    size: 'sm',
                    readOnly: isReadOnly,
                  };
                  if (attr.dataType === 'datetime') {
                    return (
                      <FieldWrapper
                        key={`attributes.${attr.id}`}
                        component={DateTimePicker}
                        isEditable
                        {...props}
                      />
                    );
                  }
                  if (attr.dataType === 'date') {
                    return (
                      <FieldWrapper
                        key={`attributes.${attr.id}`}
                        component={DatePicker}
                        isEditable
                        {...props}
                      />
                    );
                  }
                  return (
                    <FieldWrapper
                      key={`attributes.${attr.id}`}
                      component={EditableText}
                      isEditable
                      type={attr.dataType === 'number' ? 'number' : 'text'}
                      // parse={(value) => {
                      //   if (
                      //     attr.dataType === 'number' &&
                      //     typeof value === 'string'
                      //   ) {
                      //     return Number.parseFloat(value);
                      //   }
                      //   return value;
                      // }}
                      {...props}
                    />
                  );
                })}

                {account && contact?.id !== 'new' && (
                  <>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography level="title-md">Lists</Typography>
                    </Stack>
                    <Sheet
                      variant="outlined"
                      sx={{
                        p: 1,
                        borderRadius: 2.5,
                      }}
                    >
                      <ListsList
                        size="sm"
                        lists={lists}
                        readOnly={isReadOnly}
                        onDelete={(selectedList) =>
                          onRemoveFromList(selectedList)
                        }
                        onAction={(selectedList) => {
                          setDrawerOpen(false);
                          navigate(`/lists/${selectedList.id}`);
                        }}
                        actionText={t('goToItem', {
                          item: t('items.list_one'),
                        })}
                      >
                        <ListDivider />
                        <AddToListMenuButton
                          disabled={isReadOnly}
                          account={account}
                          onSelect={onAddToList}
                          size="sm"
                          component="li"
                          variant="plain"
                          fullWidth
                        />
                      </ListsList>
                    </Sheet>

                    {integrations.length ? (
                      <>
                        <Typography level="title-md">
                          {t('integrations.title')}
                        </Typography>

                        <IntegrationsList
                          account={account}
                          integrations={integrations}
                          contact={contact}
                          size="sm"
                        />
                      </>
                    ) : null}
                  </>
                )}
              </Stack>
            </Container>
          </>
        );
      }}
    />
  );
}

export default ContactEdit;
