import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDisconnectIntegrationMutation } from '../../../../api';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface DisconnectDialogProps {
  open: boolean;
  type: string;
  setOpen: (open: boolean) => void;
  onDisconnect?: () => void;
}
function DisconnectDialog({
  open,
  type,
  setOpen,
  onDisconnect,
}: DisconnectDialogProps) {
  const { t } = useTranslation();
  const [deleteIntegration, { isLoading }] = useDisconnectIntegrationMutation();
  const navigate = useNavigate();

  const disconnect = async () => {
    await deleteIntegration(type)
      .unwrap()
      .then(() => {
        if (onDisconnect) {
          onDisconnect();
        }
        navigate('/integrations');
      });
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={async (result) => {
        if (result) {
          await disconnect();
        }
        setOpen(false);
      }}
      type="danger"
      confirmLabel="disconnect"
      content={t('disconnectConfirm')}
      loading={isLoading}
    />
  );
}

export default DisconnectDialog;
