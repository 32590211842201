import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { IconButton, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Tooltip, logger } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetLinksQuery, useDeleteLinkMutation } from '../../../../api';
import DataGrid from '../../../molecules/DataGrid';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';
import OverlayPopup from '../../../templates/OverlayPopup';
import AppMenu from '../../../molecules/Menu';
import AlertDialog from '../../../molecules/AlertDialog';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import SourcesDetailPanel from './Sources';
import { AccountContext } from '../../../../contexts/account.context';
import TextLink from '../../../molecules/TextLink';
import CopyButton from '../../ButtonContexts/CopyButton';

interface LinksGridProps {
  query?: string;
}

function LinksGrid({ query }: LinksGridProps) {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dialog, setDialog] = useState<'delete' | 'download' | undefined>(
    undefined,
  );
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [expanded, setExpanded] = useState<any[]>([]);

  const [deleteLink, { isLoading }] = useDeleteLinkMutation();

  const handleDelete = async (id: string) => {
    logger.info('deleting', id);
    await deleteLink(id)
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              severity: 'info',
              message: t('links.delete.success'),
            }),
          );
        }
      })
      .catch((err) =>
        dispatch(
          showToast({
            severity: 'error',
            message: err?.error?.message || t('links.delete.error'),
          }),
        ),
      );
  };

  const renderSourcesDetailPanel = (params: any) => {
    return <SourcesDetailPanel id={params.id} columns={params.columns} />;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('links.fields.name'),
      minWidth: 120,
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
    },
    {
      field: 'shortenedUrl',
      headerName: t('links.fields.shortUrl'),
      width: 180,
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          level="body-md"
          sx={{
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '#copy-button': {
              opacity: 0,
              transition: 'opacity 0.3s ease-in-out',
            },
            '&:hover': {
              cursor: 'pointer',
              '#copy-button': {
                opacity: 0.8,
              },
            },
          }}
          endDecorator={<CopyButton text={params.value} />}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'destinationUrl',
      headerName: t('links.fields.destinationLink'),
      width: 200,
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <TextLink
            level="body-md"
            href={params.value}
            color="neutral"
            label={params.value}
            hideIcon
            underline="none"
          />
        );
      },
    },
    {
      field: 'totalClicks',
      headerName: t('links.fields.totalClicks'),
      width: 130,
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      valueGetter: (value, row) => {
        return row.stats?.totalClicks || 0;
      },
    },
    {
      field: 'uniqueClicks',
      headerName: t('links.fields.uniqueClicks'),
      width: 130,
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      valueGetter: (value, row) => {
        return row.stats?.uniqueClicks || 0;
      },
    },
    // {
    //   field: 'uniqueClicks',
    //   headerName: t('links.fields.uniqueClicks'),
    //   width: 80,
    //   pinnable: false,
    //   hideable: false,
    //   disableColumnMenu: true,
    //   filterable: false,
    //   sortable: false,
    //   valueGetter: (params) => {
    //     return params.row.stats?.uniqueClicks || 0;
    //   },
    // },
    // {
    //   field: 'ctr',
    //   headerName: t('links.fields.ctr'),
    //   width: 80,
    //   pinnable: false,
    //   hideable: false,
    //   disableColumnMenu: true,
    //   filterable: false,
    //   sortable: false,
    //   valueGetter: (params) => {
    //     return params.row.stats?.ctr || 0;
    //   },
    // },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      minWidth: 150,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <Tooltip title={t('links.viewInsights')}>
          <IconButton onClick={() => navigate(params.row?.id)}>
            <FontAwesomeIcon icon={regular('chart-line')} />
          </IconButton>
        </Tooltip>,
        <OverlayPopup
          open={selected === params.row?.id && dialog === undefined}
          onOpenClose={(open) => {
            logger.info(open, params.row.id);
            setSelected(() => (open ? params.row.id : undefined));
          }}
          renderButton={(setAnchorEl, onClick) => (
            <Tooltip title="More Actions">
              <IconButton
                ref={setAnchorEl}
                onClick={() => onClick(selected === params.row?.id)}
              >
                <FontAwesomeIcon icon={regular('ellipsis-vertical')} />
              </IconButton>
            </Tooltip>
          )}
          placement="bottom-end"
          renderPopup={() => (
            <AppMenu
              sx={{ width: 200 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              items={[
                {
                  startIcon: <FontAwesomeIcon icon={regular('trash-can')} />,
                  label: 'Delete Link',
                  onClick: () => {
                    setDialog('delete');
                  },
                },
                {
                  startIcon: <FontAwesomeIcon icon={regular('download')} />,
                  label: 'Download .csv',
                  onClick: () => {
                    setDialog('download');
                  },
                  disabled: true,
                },
              ]}
            />
          )}
        />,
      ],
    },
  ];
  return (
    <>
      <DataGrid
        columns={columns}
        filter={
          account
            ? {
                accountId: account.id,
                request: { q: query },
              }
            : skipToken
        }
        loadData={useGetLinksQuery}
        renderNoData={<div>No links found</div>}
        detailPanelExpandedRowIds={expanded}
        onRowClick={(params) => {
          // add to expanded
          setExpanded((prev) => {
            if (prev.includes(params.id)) {
              return prev.filter((id) => id !== params.id);
            }
            return [...prev, params.id];
          });
        }}
        onDetailPanelExpandedRowIdsChange={(ids) => {
          setExpanded(ids);
        }}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={renderSourcesDetailPanel}
      />
      {selected && (
        <ConfirmationDialog
          type="danger"
          open={dialog === 'delete'}
          content={t('links.delete.confirm')}
          onClose={(result) => {
            if (result) {
              handleDelete(selected);
            }
            setDialog(undefined);
            setSelected(undefined);
          }}
          confirmLabel="yesDelete"
          disableConfirm={isLoading}
        />
      )}
      <AlertDialog
        open={dialog === 'download'}
        title={t('download')}
        onClose={() => setDialog(undefined)}
        type="info"
        closeLabel="Close"
      />
    </>
  );
}
export default LinksGrid;
