import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Stack, Typography } from '@mui/joy';
import React, { useContext, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Loader } from '@sakari-io/sakari-components';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import getSegmentPrice from '../../../../utils/pricing';
import AutoTopUp from './AutoTopUp';
import {
  useGetAccountPlanQuery,
  useGetBalanceQuery,
} from '../../../../api/accounts';
import Helper from '../../../../utils/helper';
import ManualCreditDialog from '../../../../ui/organisms/Dialogs/ManualCreditDialog';
import { AccountContext } from '../../../../contexts/account.context';

function Credits() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account, isFetchingAccount } = useContext(AccountContext);

  const { data: planData, isLoading: isLoadingPlan } = useGetAccountPlanQuery(
    account?.id || skipToken,
  );

  const { data: balance } = useGetBalanceQuery(account?.id || skipToken, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [showAddCredits, setShowAddCredits] = useState(false);

  const segmentPrice = useMemo(() => {
    return planData?.data
      ? getSegmentPrice(
          planData?.data,
          account?.defaults?.country?.code ?? 'US',
        )
      : 0.04;
  }, [planData]);

  if (isLoadingPlan || isFetchingAccount)
    return (
      <Box
        sx={{
          height: 300,
        }}
      >
        <Loader size={200} />
      </Box>
    );

  return (
    <>
      {/* Plan heading */}
      <Stack>
        <Typography level="h4">Current Plan</Typography>

        <Typography level="h2">
          ${account?.plan?.commitment} /
          {account?.plan?.billingFrequency === 'monthly' ? 'mo' : 'yr'}
        </Typography>
        {account?.nextBillingDate && (
          <Typography level="body-md">
            {t('settings.billing.general.credits.nextBillingDate', {
              date: Helper.getLocalizedDateTime(account?.nextBillingDate, {
                dateStyle: 'full',
              }),
            })}
          </Typography>
        )}
      </Stack>
      <Divider inset="context" />
      <Stack direction="row" gap={2}>
        <Stack flex={3}>
          <Typography level="h4">Credit Balance</Typography>

          <Typography
            level="h2"
            endDecorator={
              <Typography level="body-sm">
                {t('settings.billing.general.credits.creditsRemaining')}
              </Typography>
            }
          >
            ${Helper.formatNumberWithCommas(balance?.data?.balance || 0, 2)}
          </Typography>
          <Typography
            level="h4"
            color="primary"
            endDecorator={
              <Typography
                level="body-sm"
                endDecorator={
                  <Tooltip
                    variant="outlined"
                    arrow
                    title={t(
                      'settings.billing.general.credits.segmentCalculation',
                      {
                        price: segmentPrice,
                      },
                    )}
                    placement="top"
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      style={{
                        color: 'var(--joy-palette-primary-500)',
                      }}
                    />
                  </Tooltip>
                }
              >
                {t('settings.billing.general.credits.segmentsAvailable')}
              </Typography>
            }
          >
            {Helper.formatNumberWithCommas(
              Math.max(
                Math.floor((balance?.data?.balance ?? 0) / segmentPrice),
                0,
              ),
              0,
            )}
          </Typography>
        </Stack>

        {/* Manual Credits */}
        <Stack flex={1} gap={1}>
          <Button onClick={() => setShowAddCredits(true)} fullWidth>
            {t('settings.billing.general.credits.manualCredit.label')}
          </Button>
          <Button
            color="neutral"
            variant="outlined"
            onClick={() => navigate('plans')}
            fullWidth
          >
            {t('settings.billing.plans.changePlan.title')}
          </Button>
        </Stack>
      </Stack>

      {/* <Card variant="soft">
          <Typography level="body-md">Plan Recommendation</Typography>
        </Card> */}

      <Divider inset="context" />
      {/* Auto Top Up */}
      {account && <AutoTopUp account={account} />}
      <ManualCreditDialog
        open={showAddCredits}
        onClose={() => setShowAddCredits(false)}
      />
    </>
  );
}
export default Credits;
