import { Button, Divider, Stack } from '@mui/joy';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { makeValidate } from 'mui-rff';
import BillingInfoForm, { billingInfoSchema as schema } from './Form';
import { AccountContext } from '../../../../contexts/account.context';
import { useUpdateBillingInfoMutation } from '../../../../api';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

function BillingInfo() {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const dispatch = useAppDispatch();

  const [updateBillingInfo] = useUpdateBillingInfoMutation();

  const handleSubmit = async (values: any) => {
    //TODO use handleApiMutationResponse
    await updateBillingInfo({ accountId: account?.id || '', request: values })
      .unwrap()
      .then((res) => {
        if (res.success)
          dispatch(
            showToast({
              severity: 'success',
              message: t('settings.billing.general.info.updateSuccess'),
            }),
          );
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message:
              err?.data?.error?.message ||
              t('settings.billing.general.info.updateError'),
          }),
        );
      });
  };

  return (
    <Form
      initialValues={account?.billing}
      onSubmit={handleSubmit}
      validate={makeValidate(schema)}
      render={({
        form,
        handleSubmit,
        submitting,
        hasValidationErrors,
        pristine,
      }) => {
        return (
          <>
            <BillingInfoForm />
            <Divider inset="context" />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => form.reset()}
                disabled={submitting || hasValidationErrors || pristine}
              >
                {t('action.reset')}
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={hasValidationErrors || pristine}
                loading={submitting}
              >
                {t('action.save')}
              </Button>
            </Stack>
          </>
        );
      }}
    />
  );
}
export default BillingInfo;
