import React, { useEffect, useState } from 'react';
import { Stack, Button } from '@mui/joy';
import ContactUpload from '../../../molecules/ContactUpload';
import { useUploadContactsMutation } from '../../../../api';
import { AttributeMapping } from '../../../../utils/fieldValidationMapping';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';
import AppDrawer from '../../../molecules/AppDrawer';

interface FileUploadDrawerProps {
  open: boolean;
  onClose: () => any;
  listId?: string;
}

function FileUploadDrawer({ open, onClose, listId }: FileUploadDrawerProps) {
  const [data, setData] = useState<string>();
  const [fieldMappings, setFieldMappings] = useState<AttributeMapping[]>([]);
  const [validMobile, setValidMobile] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [uploadContacts, { isLoading }] = useUploadContactsMutation();

  const handleUpload = async () => {
    //TODO use handleApiMutationResponse
    await uploadContacts({
      data: data as string,
      fieldMappings,
      listId,
    })
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              severity: 'info',
              message: 'Contact(s) uploaded',
            }),
          );
          clearData();
        }
      })
      .catch((err) =>
        dispatch(
          showToast({
            severity: 'error',
            message: err?.data?.error?.message || 'Error uploading contact(s)',
          }),
        ),
      );
  };

  const clearData = () => {
    setData('');
    setOpenConfirmation(false);
    onClose();
  };

  useEffect(() => {
    fieldMappings.forEach((val) => {
      if (val.attribute === 'mobile') {
        setValidMobile(!!val.column);
      }
    });
  }, [fieldMappings]);

  return (
    <>
      <ConfirmationDialog
        open={openConfirmation}
        onClose={(result) =>
          result ? clearData() : setOpenConfirmation(false)
        }
        type="danger"
        confirmLabel="yes"
        header="Are you sure you want to cancel?"
        content="Any unsaved data will be lost"
      />
      <AppDrawer
        position="right"
        open={open}
        onClose={() => (data ? setOpenConfirmation(true) : onClose())}
        header="Import Contacts"
      >
        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Stack sx={{ flex: 1, overflow: 'hidden' }}>
            <Stack sx={{ height: '100%', p: 2, gap: 2, overflow: 'auto' }}>
              <ContactUpload
                accept="csv"
                data={data}
                onData={setData}
                fieldMappings={fieldMappings}
                onFieldMappingsChanged={setFieldMappings}
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={1} className="buttons" p={2}>
            <Button
              variant="solid"
              color="primary"
              sx={{ flex: 1 }}
              onClick={() => handleUpload()}
              disabled={!data || !validMobile}
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              sx={{ flex: 1 }}
              onClick={() => (data ? setOpenConfirmation(true) : onClose())}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </AppDrawer>
    </>
  );
}

export default FileUploadDrawer;
