import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import { AccountContext } from '../../../../contexts/account.context';
import {
  useGetGroupsQuery,
  useGetUsersQuery,
  useStartSessionQuery,
  useTransferCancelMutation,
  useTransferMutation,
} from '../../../../api';
import SearchableMenu from '../../../molecules/Menu/SearchableMenu';
import TabbedNavigation from '../../headers/TabbedNavigation';
import CallBarPopup from '../CallBarPopup';

const LIMIT = 5;

interface TransferProps {
  setMuteStatus: any;
}

function Transfer({ setMuteStatus }: TransferProps) {
  const { t } = useTranslation('voice');

  const { account } = useContext(AccountContext);

  const { data } = useStartSessionQuery(account ? account.id : skipToken);
  const call = data?.data?.call;
  const [transfer] = useTransferMutation();
  const [cancelTransfer] = useTransferCancelMutation();

  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const debouncedSearch = useDebouncedValue(search, 300);

  const [view, setView] = useState<number>(0);

  const {
    data: groups,
    isLoading,
    isFetching,
  } = useGetGroupsQuery({
    accountId: account?.id || '',
    request: {
      offset,
      limit: LIMIT,
      q: debouncedSearch,
    },
  });

  const {
    data: users,
    // isLoading,
    // isFetching,
  } = useGetUsersQuery({
    accountId: account?.id || '',
    request: {
      offset,
      limit: LIMIT,
      q: debouncedSearch,
    },
  });

  useEffect(() => {
    setOffset(0);
  }, [debouncedSearch]);

  const handleInput = (newInput: string) => {
    setSearch(newInput);
  };

  const transferBtn = (onClick: any) => {
    if (call?.target && call?.id) {
      cancelTransfer(call.id);
      setMuteStatus(false);
    } else {
      setShowPopup(!showPopup);
      onClick(true);
    }
  };

  const button = (setAnchorEl: any, onClick: any) => {
    return (
      <Button
        ref={setAnchorEl}
        variant="plain"
        color="voiceBtn"
        size="sm"
        sx={{
          color: 'white',
          '&:hover': { opacity: 0.8 },
        }}
        onClick={() => transferBtn(onClick)}
      >
        {call?.target && call?.target?.user?.id !== call?.user?.id
          ? 'Cancel Transfer'
          : t('transfer')}
      </Button>
    );
  };

  const searchMenu = (type: any) => {
    return (
      <SearchableMenu
        sx={{
          color: 'white',
          backgroundColor: 'var(--joy-palette-voice-solidBg)',
          minHeight: '235px',
          borderRadius: '0 0 8px 8px',

          border: 'none',
          input: {
            color: 'white',
            '&::placeholder': {
              color: 'white',
              opacity: 1,
            },
          },

          '.MuiBox-root': {
            padding: 0,
          },
          '.MuiSheet-root': {
            color: 'var(--joy-palette-voice-softHoverBg)',
          },
          '.MuiStack-root': {
            width: '100%',
          },
          '.MuiInput-root': {
            backgroundColor: 'var(--joy-palette-voice-softBg)',
            borderRadius: 0,
            border: 'none',
          },
          '.MuiMenuItem-root': {
            color: 'white',
            ':hover': {
              color: 'white',
              backgroundColor: 'var(--joy-palette-voice-softHoverBg)',
            },
          },
          '.Joy-disabled': {
            backgroundColor: 'transparent',
            opacity: 0.4,
          },
        }}
        items={_.concat(type?.data || [])}
        isLoading={isLoading || isFetching}
        getLabel={(item: any) =>
          type === users
            ? `${item.firstName} ${item.lastName}`
            : `${item.name}` || ''
        }
        onQueryChanged={(newVal) => handleInput(newVal)}
        onSelect={(newVal) => {
          setShowPopup(false);
          transfer({
            id: call?.id,
            target: type === users ? { user: newVal } : { group: newVal },
          });
          setMuteStatus(true);
        }}
      />
    );
  };

  return (
    <CallBarPopup
      setShowPopup={setShowPopup}
      showPopup={showPopup}
      title={t('transferTo')}
      button={button}
      size={{
        width: '350px',
      }}
    >
      <TabbedNavigation
        tabs={[
          {
            value: 0,
            label: `${t('user')}`,
            content: <Stack>{searchMenu(users)}</Stack>,
          },
          {
            value: 1,
            label: `${t('group')}`,
            content: <Stack>{searchMenu(groups)}</Stack>,
          },
        ]}
        selectedTab={view}
        onTabSelected={setView}
        divider
        horizontalPadding={0}
        sx={{
          backgroundColor: 'var(--joy-palette-voice-solidBg)',
          borderRadius: '8px',
          transition: 'width 0.3s ease-in-out',
          '.JoyTabPanel-root': {
            padding: '0 !important',
            margin: '0 !important',
          },
          '.MuiTab-root': {
            px: 7.9,
            ':hover': {
              color: 'white',
            },
          },
          '.Mui-selected': {
            color: 'white',
            fontWeight: 500,
            ':after': {
              backgroundColor: 'white',
            },
          },
        }}
      />
    </CallBarPopup>
  );
}

export default Transfer;
