import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import React, { useEffect, useState, useCallback } from 'react';
import { Card } from '@mui/joy';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Loader } from '@sakari-io/sakari-components';
import CustomTooltip from './CustomTooltip';

export type Source = {
  id: string;
  name: string;
};

type SourceData = {
  date: string;
  value: number;
};

type ChartData = {
  date: Date;
  [key: string]: number | Date;
};

export interface SourceStats {
  color: string;
  source: Source;
  data: SourceData[];
}

interface InsightsChartProps {
  sources?: SourceStats[];
  showLegend?: boolean;
  dateRange?: number;
}
const formatDate = (date: Date) => {
  return `${format(date, 'MMM d')}`;
};

const getTimeDomain = (range: number) => {
  const now = new Date();

  // generate an array of X days, starting from X days ago to yesterday

  const days = Array.from(Array(range).keys()).map((day) => {
    const date = new Date(now);
    date.setDate(date.getDate() - day - 1);
    return date.getTime();
  });
  // reverse the array so that the oldest date is first
  days.reverse();

  return days;
};

function InsightsChart({
  sources,
  showLegend,
  dateRange = 7,
}: InsightsChartProps) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState<number>(-1);
  const [formattedData, setFormattedData] = useState<ChartData[]>([]);

  useEffect(() => {
    const formatted =
      sources?.reduce((result: ChartData[], { source, data }) => {
        data?.forEach(({ date, value }, index: number) => {
          if (!result[index]) {
            result[index] = {
              date: new Date(date),
            };
          }
          result[index][source.name] = value;
        });
        return result;
      }, []) ?? [];
    setFormattedData(formatted);
  }, [sources]);

  const renderTooltip = useCallback(
    (props: any) => {
      return (
        <CustomTooltip
          props={props}
          showTooltip={showTooltip}
          formatDate={formatDate}
        />
      );
    },
    [showTooltip, formatDate],
  );

  if (!sources) {
    return <Loader size={100} />;
  }

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-25%, -25%)',
          zIndex: -99,
          opacity: 0,
          transition: 'opacity 0.3s ease-in-out',
          ...(!formattedData.length && {
            opacity: 1,
            zIndex: 1000,
          }),
        }}
      >
        {t('links.source.noData')}
      </Card>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={formattedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickFormatter={(val) => formatDate(val)}
            domain={getTimeDomain(dateRange)}
          />
          <YAxis
            type="number"
            allowDecimals={false}
            domain={[0, formattedData.length ? 'auto' : 100]}
          />
          {showLegend && <Legend />}
          <Tooltip
            isAnimationActive={false}
            cursor={{ fill: 'none' }}
            wrapperStyle={{ outline: 'none' }}
            content={renderTooltip}
          />
          {sources?.map((source, ix) => (
            <Line
              type="monotone"
              dataKey={source?.source?.name}
              stroke={source?.color}
              dot={false}
              strokeWidth={1.5}
              activeDot={{
                r: 8,
                onMouseOver: () => setShowTooltip(ix),
                onMouseOut: () => setShowTooltip(-1),
              }}
              style={{
                ...(showTooltip === ix && {
                  strokeWidth: 3,
                  zIndex: 1000,
                  filter: 'drop-shadow(0px 3px 4px rgba(169, 99, 99, 0.25))',
                }),
              }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
export default InsightsChart;
