import React from 'react';
import { Box, IconButton, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import OverlayPopup from '../../templates/OverlayPopup';

interface CallBarPopupProps {
  setShowPopup: any;
  showPopup: boolean;
  title?: string;
  button?: any;
  children: React.ReactNode;
  size?: any;
}

function CallBarPopup({
  setShowPopup,
  showPopup,
  title,
  button,
  children,
  size,
}: CallBarPopupProps) {
  return (
    <OverlayPopup
      sx={{
        backgroundColor: 'var(--joy-palette-voice-solidBg)',
        borderRadius: '8px',
        ...size,
      }}
      open={showPopup}
      onOpenClose={setShowPopup}
      placement="top"
      renderButton={(setAnchorEl, onClick) => {
        return button(setAnchorEl, onClick);
      }}
      renderPopup={() => (
        <Box>
          {title && (
            <Box
              padding="5px 15px 5px 20px"
              sx={{
                backgroundColor: 'var(--joy-palette-voice-softBg)',
                borderRadius: '8px 8px 0 0',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  color: 'white',
                  fontWeight: 700,
                  textAlign: 'center',
                  paddingTop: '10px',
                }}
              >
                {title}
              </Typography>
              <IconButton
                variant="soft"
                color="voice"
                onClick={() => setShowPopup(!showPopup)}
                sx={{ gridColumn: '3 / span 1' }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Box>
          )}
          {children}
        </Box>
      )}
    />
  );
}

export default CallBarPopup;
