import { countries } from '@sakari-io/sakari-common';
import {
  PhoneNumber,
  Sender,
  TollFreeVerificationRequest,
} from '@sakari-io/sakari-typings';
import { makeValidate } from 'mui-rff';
import React, { useContext, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Stack } from '@mui/joy';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { useVerifyTollFreeNumberMutation } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import WizardPage from '../../../../ui/templates/forms/WizardPage';
import WizardSteps from '../../../../ui/molecules/WizardSteps';
import { StepDetail } from '../../../../types';
import BusinessInfo from './BusinessInfo';
import CampaignInfo from './CampaignInfo';
import SampleMessages from './SampleMessages';
import Helper from '../../../../utils/helper';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import Success from '../../SendersAdd/PhoneNumber/Success';

interface TollFreeVerificationProps {
  phoneNumbers: PhoneNumber[];
}

function TollFreeVerification({ phoneNumbers }: TollFreeVerificationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { account, profile } = useContext(AccountContext);

  const [verifyTollFreeNumber, { isLoading: sendingApplication }] =
    useVerifyTollFreeNumberMutation();
  const [showSuccess, setShowSuccess] = useState(false);

  const STEPS: StepDetail[] = [
    {
      id: 'business',
      label: t('senders.register.tollfree.business.label'),
      title: t('senders.register.tollfree.business.title'),
      subLabel: (values) => values.business?.name,
      pageComponent: <BusinessInfo />,
      isVisible: () => true,
    },
    {
      id: 'campaign',
      label: t('senders.register.tollfree.campaign.label'),
      title: t('senders.register.tollfree.campaign.title'),
      subLabel: (values) => values.campaign?.useCase?.label,
      pageComponent: <CampaignInfo />,
      isVisible: () => true,
    },
    {
      id: 'samples',
      label: t('senders.register.tollfree.samples.label'),
      title: t('senders.register.tollfree.samples.title'),
      description: t('senders.register.tollfree.samples.description'),
      subLabel: () => '',
      pageComponent: <SampleMessages />,
      isVisible: () => true,
      isLast: true,
    },
  ];

  const initialValues = useMemo(() => {
    const defaultValues = {
      phoneNumbers: phoneNumbers || [],
      accountId: account?.id || '',
      business: {
        name: account?.externalName,
        site: Helper.formatURL(account?.info?.website),
        companyName: account?.legalName,
        address: {
          country: countries.find((c) => c.code === 'US'),
        },
      },
      contact: {
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        phone: profile?.mobile,
      },
    };

    return defaultValues;
  }, []);

  const [step, setStep] = useState(0);
  const currentStep = useMemo(() => STEPS[step].id.toString(), [step]);

  const schema = Yup.object().shape({
    phoneNumbers: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          number: Yup.string().required(),
        }),
      )
      .min(1),
    business: Yup.object().when('numbers', {
      is: () => ['business', 'review'].includes(currentStep),
      then: Yup.object()
        .shape({
          name: Yup.string().required(
            t('validation.pleaseEnter', {
              field: t('senders.register.businessName.label').toLowerCase(),
            }),
          ),
          site: Yup.string()
            .url(
              t('validation.isInvalid', {
                field: t('form.website.label').toLowerCase(),
              }),
            )
            .required(
              t('validation.pleaseEnter', {
                field: t('form.website.label').toLowerCase(),
              }),
            )
            .nullable(),
          address: Yup.object().shape({
            line1: Yup.string().required(
              t('validation.pleaseEnter', {
                field: t('form.street.label').toLowerCase(),
              }),
            ),
            line2: Yup.string(),
            city: Yup.string().required(
              t('validation.pleaseEnter', {
                field: t('form.city.label').toLowerCase(),
              }),
            ),
            state: Yup.mixed().test(
              'valid-state',
              'Invalid state',
              function (value) {
                const { path, createError } = this;
                if (typeof value === 'string') {
                  return Yup.string().required().isValidSync(value);
                }
                if (
                  typeof value === 'object' &&
                  value !== null &&
                  'code' in value
                ) {
                  return Yup.object()
                    .shape({ code: Yup.string().required() })
                    .nullable()
                    .isValidSync(value);
                }
                return createError({
                  path,
                  message: t('validation.pleaseEnter', {
                    field: t('form.state.label').toLowerCase(),
                  }),
                });
              },
            ),
            postalCode: Yup.string().required(
              t('validation.pleaseEnter', {
                field: t('form.postalCode.label').toLowerCase(),
              }),
            ),
            country: Yup.object({ code: Yup.string() })
              .required(
                t('validation.pleaseSelect', {
                  field: t('form.country.label').toLowerCase(),
                }),
              )
              .required(
                t('validation.pleaseSelect', {
                  field: t('form.country.label').toLowerCase(),
                }),
              )
              .nullable(),
          }),
        })
        .required('Business Info Required'),
    }),
    contact: Yup.object().when('numbers', {
      is: () => ['business', 'review'].includes(currentStep),
      then: Yup.object().shape({
        firstName: Yup.string().required(
          t('validation.pleaseEnter', {
            field: t('form.name.first').toLowerCase(),
          }),
        ),
        lastName: Yup.string().required(
          t('validation.pleaseEnter', {
            field: t('form.name.last').toLowerCase(),
          }),
        ),
        email: Yup.string()
          .email()
          .required(
            t('validation.pleaseEnter', {
              field: t('form.email.label').toLowerCase(),
            }),
          ),
        phone: Yup.object()
          .shape({
            number: Yup.string().required(
              t('validation.pleaseEnter', {
                field: t('form.phone.label').toLowerCase(),
              }),
            ),
            country: Yup.string().required(
              t('validation.pleaseSelect', {
                field: t('form.country.label').toLowerCase(),
              }),
            ),
          })
          .nullable()
          .test(
            'phone',
            t('form.phonenumber.invalid'),
            (value: { number: string; country: CountryCode }) => {
              if (value) {
                return isValidPhoneNumber(value.number, value.country);
              }
              return true;
            },
          ),
      }),
    }),
    campaign: Yup.object().when('numbers', {
      is: () => ['campaign', 'review'].includes(currentStep),
      then: Yup.object().shape({
        description: Yup.string()
          .min(25, t('validation.minLength', { count: 25 }))
          .max(500, t('validation.exceededMaxLength', { count: 500 }))
          .required(
            t('validation.isRequired', {
              field: t('form.description.label'),
            }),
          ),
        optinMethod: Yup.object()
          .shape({
            id: Yup.string(),
            label: Yup.string(),
          })
          .required('Select an opt-in method'),
        optinInfo: Yup.string()
          .min(25, t('validation.minLength', { count: 25 }))
          .max(500, t('validation.exceededMaxLength', { count: 500 }))
          .required(t('validation.required')),
        optinFile: Yup.array()
          .of(Yup.object())
          .min(1, t('validation.required'))
          .required(t('validation.required')),
        useCase: Yup.object()
          .nullable()
          .required(t('10dlc.errors.campaign.usecases.required')),
        multipleNumbersReason: Yup.string().when('type', {
          is: () => phoneNumbers?.length > 1,
          then: Yup.string().required(t('validation.required')),
        }),
        messageVolume: Yup.number().required(t('validation.required')),
      }),
    }),
    samples: Yup.array().when('numbers', {
      is: () => ['samples', 'review'].includes(currentStep),
      then: Yup.array()
        .of(
          Yup.string()
            .min(25, t('validation.minLength', { count: 25 }))
            .max(250, t('validation.exceededMaxLength', { count: 250 })),
        )
        .test(
          'min_samples',
          t('validation.required'),
          (value) => value?.some((v) => (v?.length || 0) > 0) || false,
        ),
    }),
    files: Yup.array().of(Yup.object()),
  });

  const validate = makeValidate(schema);

  const formatPayload = (values: any) => {
    const allFileURLs = [...values.campaign.optinFile, ...values.files].map(
      (f) => f.link,
    );

    // logger.info('allFileURLs', allFileURLs);
    const payload: TollFreeVerificationRequest = {
      phoneNumbers: values.phoneNumbers.map((number: Sender) => number.id),
      name: values.business.name,
      website: values.business.site,
      address: {
        line1: values.business.address.line1,
        line2: values.business.address.line2 || '',
        city: values.business.address.city,
        state: values.business.address.state,
        zip: values.business.address.postalCode,
      },
      contact: {
        firstName: values.contact.firstName || '',
        lastName: values.contact.lastName || '',
        email: values.contact.email,
        phoneNumber: values.contact.phone.number,
      },
      messageVolume: values.campaign.messageVolume,
      useCase: values.campaign.useCase.id,
      useCaseSummary: values.campaign.description,
      messageContent: values.samples.join(', '),
      optInWorkflow: {
        description: values.campaign.optinInfo,
        imageUrls: allFileURLs,
      },
      additionalInformation: values.campaign.multipleNumbersReason || '',
    };
    // logger.info('payload', payload);
    return payload;
  };

  const handleOnSubmit = async (values: any) => {
    const nextStep = step + 1;
    if (nextStep < STEPS.length) {
      setStep(nextStep);
    }

    if (STEPS[step].isLast) {
      // logger.info('submitting');
      const payload = formatPayload(values);
      verifyTollFreeNumber({ accountId: account?.id || '', request: payload })
        .unwrap()
        .then((res) => {
          // logger.info('verifyTollFreeNumber', res);
          if (res.success) {
            setShowSuccess(true);
          } else {
            dispatch(
              showToast({
                message: t('somethingWrong'),
                severity: 'error',
              }),
            );
          }
        })
        .catch((err: any) => {
          // logger.info('verifyTollFreeNumberErr', err);
          dispatch(
            showToast({
              message: err?.data?.error?.message || t('somethingWrong'),
              severity: 'error',
            }),
          );
        });
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    } else {
      history.back();
    }
  };

  return (
    <>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({
          handleSubmit,
          hasValidationErrors,
          hasSubmitErrors,
          submitting,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <Stack
                sx={{
                  py: 8,
                  pl: 4,
                  pr: 2,
                  width: 224,
                  alignItems: 'center',
                }}
              >
                <WizardSteps
                  orientation="vertical"
                  steps={STEPS}
                  activeStep={step}
                  withFade
                />
              </Stack>
              <Stack
                sx={{
                  overflowY: 'overlay',
                  overflowX: 'hidden',
                  flex: 1,
                }}
              >
                <Stack
                  sx={{
                    paddingY: 8,
                    paddingLeft: 8,
                    paddingRight: { md: 12, xs: 6 },
                    width: 'clamp(600px, 100%, 820px)',
                    gap: 2,
                  }}
                >
                  <WizardPage currentStep={STEPS[step]} />
                  <Stack
                    sx={{
                      columnGap: 2,
                      height: 'fit-content',
                      flexDirection: 'row',
                      width: '400px',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="neutral"
                      onClick={() => handleBack()}
                      fullWidth
                    >
                      {t('action.back')}
                    </Button>
                    <Button
                      color="primary"
                      disabled={hasValidationErrors || hasSubmitErrors}
                      loading={submitting || sendingApplication}
                      fullWidth
                      type="submit"
                    >
                      {STEPS[step]?.isLast
                        ? t('action.submit')
                        : t('action.next')}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          );
        }}
      />
      <Success open={showSuccess} type="success" />
    </>
  );
}
export default TollFreeVerification;
