import * as React from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from '@hello-pangea/dnd';

import {
  IconButton,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import CopyButton from '../../../ButtonContexts/CopyButton';
import { IntegrationProperty } from '../../../cards/IntegrationCard/integrationProperty';

interface IntegrationListItemProps {
  item: IntegrationProperty;
  index: number;
  isEditMode: boolean;
  onVisibility: (visibility: boolean) => void;
}

function IntegrationListItem({
  item,
  index,
  isEditMode,
  onVisibility,
}: IntegrationListItemProps) {
  const [integrationPropertyVisibility, setIntegrationPropertyVisibility] =
    React.useState<boolean>(item.visible);

  React.useEffect(() => {
    setIntegrationPropertyVisibility(item.visible);
  }, [item]);

  let endAction;

  if (isEditMode) {
    endAction = (
      <IconButton
        aria-selected={integrationPropertyVisibility}
        aria-description={`${item.visible ? 'hide' : 'show'} ${item.name}`}
        size="sm"
        onClick={() => {
          setIntegrationPropertyVisibility(!integrationPropertyVisibility);
          onVisibility(!integrationPropertyVisibility);
        }}
        sx={{
          opacity: integrationPropertyVisibility ? 0.8 : 0.5,
        }}
      >
        <FontAwesomeIcon
          icon={
            integrationPropertyVisibility
              ? regular('eye')
              : regular('eye-slash')
          }
        />
      </IconButton>
    );
  } else if (item.value) {
    endAction = <CopyButton text={item.value} />;
  } else {
    endAction = null;
  }

  const editListItem = (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
  ) => (
    <ListItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        py: 1,
        ...(snapshot.isDragging && {
          backgroundColor: 'background.level1',
          filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15))',
        }),
        ...(isEditMode &&
          !item.visible && {
            opacity: 0.2,
          }),

        ...(!isEditMode && {
          '#copy-button': {
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
          },
          '&:hover': {
            '#copy-button': {
              opacity: 0.8,
            },
          },
          ...(!item.visible && {
            display: 'none',
          }),
        }),
      }}
      endAction={endAction}
    >
      {isEditMode && (
        <ListItemDecorator>
          <FontAwesomeIcon icon={regular('ellipsis-vertical')} />
        </ListItemDecorator>
      )}
      <ListItemContent>
        <Typography
          level="body-md"
          sx={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          }}
          textColor="text.primary"
        >
          {item.label}
        </Typography>
        <Typography level="body-sm" textColor="text.secondary">
          {item.value}
        </Typography>
      </ListItemContent>
    </ListItem>
  );

  return (
    <Draggable
      key={item.name}
      draggableId={item.name}
      index={index}
      isDragDisabled={!isEditMode}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) =>
        editListItem(provided, snapshot)
      }
    </Draggable>
  );
}

export default IntegrationListItem;
