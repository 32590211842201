import React from 'react';
import {
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  YAxis,
  XAxis,
} from 'recharts';
import { Account, CampaignExecution } from '@sakari-io/sakari-typings';
import { Stack, styled } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Loader } from '@sakari-io/sakari-components';
import CustomTooltip from './CustomTooltip';
import { useGetCampaignExecutionChartQuery } from '../../../../api';

export interface ExecutionChartProps {
  account: Account;
  execution: CampaignExecution;
}
const getBarStyle = (palette: string) => ({
  fill: `var(--joy-palette-${palette}-solidBg)`,
  '&:hover': {
    fill: `var(--joy-palette-${palette}-solidHoverBg)`,
  },
});

const StyledBarChart = styled(BarChart)(() => ({
  fontFamily: 'var(--joy-fontFamily-body)',
  color: 'var(--joy-palette-text-tertiary)',
  fontSize: 'var(--joy-fontSize-sm)',
  stroke: 'var(--joy-palette-text-secondary)',

  '#processing': getBarStyle('neutral'),

  '#inTransit': getBarStyle('warning'),

  '#error': getBarStyle('danger'),

  '#sent': getBarStyle('primary'),
}));

function ExecutionChart({ account, execution }: ExecutionChartProps) {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCampaignExecutionChartQuery({
    accountId: account.id,
    request: execution.id,
  });

  const groupedData = data?.data.statistics.reduce(
    (acc, item) => {
      if (['new', 'verified', 'validated'].includes(item.status)) {
        acc.processing = item.count;
      } else if (item.status === 'queued') {
        acc.inTransit = item.count;
      } else if (['sent', 'delivered'].includes(item.status)) {
        acc.sent = item.count;
      } else if (['error', 'invalid'].includes(item.status)) {
        acc.error = item.count;
      }
      return acc;
    },
    {
      processing: 0,
      inTransit: 0,
      sent: 0,
      error: 0,
    },
  );
  const items = Object.entries(groupedData || {}).map(([key, value]) => ({
    id: key,
    name: _.capitalize(t(`campaigns.status.${key}.label`)),
    value,
  }));

  if (isLoading) {
    return (
      <Stack>
        <Loader size={100} />
      </Stack>
    );
  }

  return (
    <ResponsiveContainer width={400} height={400}>
      <StyledBarChart
        data={items}
        margin={{ top: 30, right: 20, left: 20, bottom: 20 }}
        maxBarSize={100}
      >
        <XAxis dataKey="name" tickLine={false} />
        <YAxis allowDecimals={false} />
        <Tooltip content={CustomTooltip} cursor={{ fill: 'none' }} />
        <Bar dataKey="value" strokeWidth={0} radius={[8, 8, 0, 0]}>
          {items.map((entry, index) => (
            <Cell key={`cell-${index}`} />
          ))}
        </Bar>
      </StyledBarChart>
    </ResponsiveContainer>
  );
}

export default ExecutionChart;
