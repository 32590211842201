import React, { MouseEvent, useContext, useState } from 'react';
import { Campaign } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconButton, MenuList, ListDivider } from '@mui/joy';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import OverlayPopup from '../../../../ui/templates/OverlayPopup';
import MenuItem from '../../../../ui/atoms/MenuItem';
import { AccountContext } from '../../../../contexts/account.context';
import { UserRole } from '../../../../constants';

interface CampaignMenuProps {
  onAction: (action: string) => any;
  campaign: Campaign;
}

const MenuActions = {
  EDIT: 'edit',
  DELETE: 'delete',
  PAUSE: 'pause',
  RESUME: 'resume',
  EXECUTE: 'execute',
  COPY: 'copy',
};

function CampaignMenu({ campaign, onAction }: CampaignMenuProps) {
  const { userRole } = useContext(AccountContext);
  const canManage = [UserRole.ADMIN, UserRole.CAMPAIGN_MANAGER].includes(
    userRole as UserRole,
  );
  const [open, setOpen] = useState(false);

  const isOnDemand = campaign.schedule?.frequency === 'OD';
  const isEditable = campaign.trigger?.code === 'V2';

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const handleAction = (e: MouseEvent, action: string) => {
    e.stopPropagation();
    onAction(action);
    setOpen(false);
  };

  return (
    <OverlayPopup
      open={open}
      onOpenClose={setOpen}
      placement="bottom-end"
      renderButton={(setAnchorEl, onClick) => (
        <IconButton
          ref={setAnchorEl}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={onClick}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </IconButton>
      )}
      renderPopup={() => (
        <MenuList
          onKeyDown={handleListKeyDown}
          onClick={(e) => e.stopPropagation()}
          sx={{
            minWidth: '180px',
          }}
        >
          <MenuItem
            label={isOnDemand ? 'Execute' : 'Preview'}
            startIcon={
              <FontAwesomeIcon
                icon={
                  isOnDemand ? solid('play') : solid('magnifying-glass-dollar')
                }
              />
            }
            onClick={(e) => handleAction(e, MenuActions.EXECUTE)}
            disabled={!canManage}
          />
          {!isOnDemand ? (
            <MenuItem
              label={campaign.paused ? 'Resume' : 'Pause'}
              startIcon={
                campaign.paused ? (
                  <FontAwesomeIcon icon={solid('play-pause')} />
                ) : (
                  <FontAwesomeIcon icon={solid('pause')} />
                )
              }
              onClick={(e) =>
                handleAction(
                  e,
                  campaign.paused ? MenuActions.RESUME : MenuActions.PAUSE,
                )
              }
            />
          ) : null}
          <ListDivider inset="gutter" />
          <MenuItem
            label="Duplicate"
            startIcon={<FontAwesomeIcon icon={solid('copy')} />}
            onClick={(e) => handleAction(e, MenuActions.COPY)}
            disabled={!canManage}
          />
          <MenuItem
            label="Edit"
            startIcon={<FontAwesomeIcon icon={solid('edit')} />}
            disabled={!isEditable || !canManage}
            onClick={(e) => handleAction(e, MenuActions.EDIT)}
          />
          <MenuItem
            label="Delete"
            startIcon={<FontAwesomeIcon icon={solid('trash')} />}
            onClick={(e) => handleAction(e, MenuActions.DELETE)}
            disabled={!canManage}
          />
        </MenuList>
      )}
    />
  );
}

export default CampaignMenu;
