import React, { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { LineTypeMetaData, Country } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';

import { Chip, ChipDelete, Sheet, Stack, Typography } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  NumberWithFlag,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import SearchBar from '../../../../../../ui/molecules/inputs/SearchBar';
import Radios from '../../../../../../ui/molecules/Radios';
import Checkboxes, {
  CheckboxData,
} from '../../../../../../ui/organisms/Checkboxes';
import AvailablePhoneNumbersGrid from '../../../../../../ui/organisms/datagrids/AvailablePhoneNumbersGrid/index2';

interface ICapabilities {
  [key: string]: boolean;
}

interface SearchNumberProps {
  country: Country;
  lineType: LineTypeMetaData;
  value: string[];
  onChange: (numbers: string[]) => any;
}

function SearchNumber({
  value = [],
  onChange,
  country,
  lineType,
}: SearchNumberProps) {
  const { t } = useTranslation();

  const CAPABILITIES: CheckboxData[] = ['SMS', 'MMS', 'Voice'].map((c) => ({
    label: t(`phonenumbers.capabilities.${c}`),
    value: c,
  }));

  const [capabilities, setCapabilities] = useState<ICapabilities>({
    SMS: true,
    MMS: false,
    Voice: false,
  });

  const [searchType, setSearchType] = useState<string | undefined>(
    lineType.search?.[0]?.type,
  );

  const searchParameters = useMemo(
    () => lineType?.search?.find((s) => s.type === searchType)?.parameters,
    [searchType],
  );

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebouncedValue(search, 500)?.replace(
    /[^\w\s]|_/g,
    '',
  );

  useEffect(() => setSearch(''), [searchType]);

  const searchDetails = useMemo(
    () => ({
      country: country.code,
      type: lineType.type,
      features: _.keys(_.pickBy(capabilities, (selected) => selected)),
      [searchType ?? 'unknown']: debouncedSearch,
      limit: 50,
    }),
    [searchType, capabilities, debouncedSearch],
  );

  const isValidSearchParams = (searchType?: string) => {
    if (!searchType) {
      return false;
    }

    const currentSearchNumberType = lineType.search?.find(
      (st: any) => st.type === searchType,
    );

    if (
      currentSearchNumberType?.parameters?.minLength === undefined &&
      currentSearchNumberType?.parameters?.maxLength === undefined
    ) {
      return true;
    }

    if (
      currentSearchNumberType.parameters?.minLength >
      (debouncedSearch?.length || 0)
    ) {
      return false;
    }

    if (
      currentSearchNumberType.parameters?.maxLength <
      (debouncedSearch?.length || 0)
    ) {
      return false;
    }

    return true;
  };

  const addNumber = (number: string) => {
    if (!value.includes(number)) {
      onChange([...value, number]);
    }
  };

  const removeNumber = (number: string) => {
    const arr = value.filter((num) => {
      return number !== num;
    });
    onChange(arr);
  };

  const toggleNumber = (number: string) => {
    if (!value.includes(number)) {
      addNumber(number);
    } else {
      removeNumber(number);
    }
  };

  return (
    <>
      <Sheet
        variant="outlined"
        sx={{
          maxHeight: 400,
          // maxWidth: 860,
          width: '100%',
          display: 'flex',
          padding: '15px',
          borderRadius: 'var(--joy-radius-sm)',
        }}
      >
        <Stack gap={2} width="100%">
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{
              borderBottom: `1px solid ${'var(--joy-palette-neutral-outlinedBorder)'}`,
              paddingBottom: '15px',
              '& .MuiFormControl-root': {
                width: 'fit-content',
              },
            }}
          >
            <Typography fontWeight={700}>
              {t('phonenumbers.capabilities.title')}
            </Typography>
            <Checkboxes
              value={capabilities}
              onChange={setCapabilities}
              items={CAPABILITIES}
              row
              size="sm"
              variant="outlined"
              id="capabilities"
              isDisabled={(v) => v.value === 'SMS'}
              columns={3}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontWeight={700}>{t('search')}</Typography>

            <Radios
              row
              items={[
                {
                  label: t('phonenumbers.search.searchTypes.any'),
                  value: 'any',
                },
                {
                  label: t('phonenumbers.search.searchTypes.areaCode'),
                  value: 'areaCode',
                },
                {
                  label: t('phonenumbers.search.searchTypes.contains'),
                  value: 'contains',
                },
                {
                  label: t('phonenumbers.search.searchTypes.zip'),
                  value: 'postalCode',
                },
              ].filter(({ value }) =>
                lineType.search?.find(({ type }) => type === value),
              )}
              value={searchType}
              onChange={(e) => setSearchType(e)}
            />
          </Stack>

          <SearchBar
            value={search}
            onChange={setSearch}
            placeholder={t(`phonenumbers.search.placeholder.${searchType}`, {
              min: searchParameters?.minLength,
              max: searchParameters?.maxLength,
            })}
            disabled={searchType === 'any'}
            autoFocus
          />
        </Stack>
      </Sheet>

      <AvailablePhoneNumbersGrid
        filter={isValidSearchParams(searchType) ? searchDetails : skipToken}
        value={value || []}
        onSelect={toggleNumber}
      />

      {value.length > 0 && (
        <>
          <Typography textColor="text.secondary" fontWeight={700}>
            {t('phonenumbers.search.searchTypes.selectedNumbers')}
          </Typography>
          <Stack sx={{ paddingBottom: '20px', flexFlow: 'row wrap', gap: 2 }}>
            {value?.map((num: any) => {
              return (
                <Chip
                  key={num}
                  color="primary"
                  aria-describedby={num}
                  endDecorator={
                    <ChipDelete
                      variant="plain"
                      onDelete={() => removeNumber(num)}
                    />
                  }
                >
                  <NumberWithFlag mobile={num} />
                </Chip>
              );
            })}
          </Stack>
        </>
      )}
    </>
  );
}

export default SearchNumber;
