import React, { useContext } from 'react';
import { Stack, Button, Sheet, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { StyledQRCode, Loader, logger } from '@sakari-io/sakari-components';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AccountContext } from '../../contexts/account.context';
import { useGetPhoneNumbersQuery, useLazySendPftEmailQuery } from '../../api';
import { download } from '../Tools/QRGenerator';
import { showToast } from '../../redux/reducers/toast';
import { useAppDispatch } from '../../redux';
import Helper from '../../utils/helper';

function OptInQR() {
  const { t } = useTranslation();

  const { account } = useContext(AccountContext);
  const { data, isLoading } = useGetPhoneNumbersQuery(
    account
      ? {
          accountId: account?.id,
          request: {
            type: 'local',
            destination: 'US',
          },
        }
      : skipToken,
  );

  const [sendEmail, result] = useLazySendPftEmailQuery();

  const dispatch = useAppDispatch();

  const handleButtonClick = () => {
    if (account) {
      //TODO use handleApiMutationResponse
      sendEmail({
        accountId: account?.id,
        request: '',
      })
        .then((resp) => {
          logger.info('resp', resp);
          dispatch(
            showToast({
              message: t('senders.register.platformFreeTrial.email.success'),
              severity: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            showToast({
              message:
                err?.data?.error?.message ||
                t('senders.register.platformFreeTrial.email.error'),
              severity: 'error',
            }),
          );
        });
    }
  };

  if (!isLoading && !data?.data?.length) {
    return <div>No QR</div>;
  }

  return (
    <Sheet
      variant="outlined"
      sx={{
        backgroundColor: 'white',
        border: `1px solid var(--joy-palette-neutral-outlinedBorder)`,
        borderRadius: '6px',
        boxShadow: '0px 1px 3px 2px rgb(33 84 186 / 5%)',
        padding: '20px',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
      }}
    >
      {isLoading && <Loader size={100} />}
      <Stack alignItems="center" spacing={2}>
        <Stack alignItems="center">
          <Typography sx={{ fontWeight: 700 }}>
            {t('senders.register.platformFreeTrial.howToOptIn')}
          </Typography>
          <Typography level="body-md">
            {t('senders.register.platformFreeTrial.haveContactsScanQRCode')}
          </Typography>
        </Stack>

        <StyledQRCode
          id="QRCode"
          value={`SMSTO:${data?.data[0].number}:START`}
          size={120}
        />

        <Stack alignItems="center" sx={{ textAlign: 'center' }}>
          <Typography level="body-md">{t('or')}</Typography>

          <Typography level="body-md">
            {t('senders.register.platformFreeTrial.textTo', {
              phrase: '"START"',
              number: `${data?.data[0].number.slice(
                0,
                2,
              )} ${Helper.formatPhoneNumber({
                number: data?.data[0].number || '',
                country: data?.data[0].country || '',
              })}`,
            })}
          </Typography>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Button
          onClick={handleButtonClick}
          fullWidth
          variant="outlined"
          color="neutral"
          disabled={result.isFetching}
          startDecorator={<FontAwesomeIcon icon={solid('envelope')} />}
        >
          {t('senders.register.platformFreeTrial.emailInstructions')}
        </Button>
        <Button
          onClick={() => download()}
          fullWidth
          variant="outlined"
          color="neutral"
          startDecorator={<FontAwesomeIcon icon={solid('download')} />}
        >
          {t('senders.register.platformFreeTrial.downloadQRCode')}
        </Button>
      </Stack>
    </Sheet>
  );
}

export default OptInQR;
