import React from 'react';
import { Stack } from '@mui/joy';
import { AdvancedDelay, Delay as DelayType } from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import TimeDelay from '../../molecules/TimeDelay';
import ScheduleRelease from '../ScheduleRelease';
import { ConfigFieldProps } from '../../../pages/Workflows/Edit/ConfigDrawer/NodeProperties/PropertyField';

interface DelayProps extends ConfigFieldProps<AdvancedDelay> {
  disabled?: boolean;
}

function Delay({ label, helperText, value, onChange, disabled }: DelayProps) {
  const handleTimeDelayChange = (newDelay: DelayType) => {
    onChange({ initialDelay: newDelay, additionalTime: value?.additionalTime });
  };

  const handleScheduleReleaseChange = (
    newSchedule: AdvancedDelay['additionalTime'],
  ) => {
    onChange({
      initialDelay: value?.initialDelay,
      additionalTime: newSchedule,
    });
  };

  return (
    <InputControl label={label} helperText={helperText} fullWidth>
      <Stack spacing={3}>
        <TimeDelay
          value={value?.initialDelay || { days: 0, hours: 0, minutes: 0 }}
          onChange={handleTimeDelayChange}
          disabled={disabled}
        />
        <ScheduleRelease
          value={value?.additionalTime}
          onChange={handleScheduleReleaseChange}
          disabled={disabled}
        />
      </Stack>
    </InputControl>
  );
}

export default Delay;
