import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { Stack } from '@mui/joy';
import { logger } from '@sakari-io/sakari-components';
import TemplatingPlugin from './plugins/TemplatingPlugin';
import PlainTextPlugin from './plugins/PlainTextPlugin';
import { exportHandlebars, parseHandlebars } from './utils/handlebars-parser';
import { VariableNode } from './nodes/VariableNode';
import ClearEditorPlugin from './plugins/ClearEditorPlugin';
import AutoFocusPlugin from './plugins/AutoFocusPlugin';
import FormPlugin from './plugins/FormPlugin';
import ReadOnlyPlugin from './plugins/ReadOnlyPlugin';
import { splitByHandlebars } from '../../../utils/parseHandlebars';

const theme = {
  // Theme styling goes here
};

// test();

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  logger.error(error);
}

export interface EditorProps {
  value: string;
  onChange: (hbs: string) => any;
  initialValue?: string;
  disabled?: boolean;
  orientation?: 'horizontal' | 'vertical';
  containerSx?: any;
  plugins?: {
    start?: any;
    end?: any;
  };
  rowRange?: number[];
  placeholder?: string;
  autofocus?: boolean;
  onHover?: (nodeId: string) => void;
}

function Editor({
  initialValue,
  value,
  onChange,
  disabled,
  plugins,
  orientation = 'vertical',
  containerSx,
  rowRange,
  placeholder,
  autofocus,
  onHover,
}: EditorProps) {
  // logger.info('vaaa', value);

  // const [content, setContent] = useState<SerializedRootNode>(parseHandlebars(''));
  // // const initialConfig: InitialConfigType = ;

  // useEffect(() => {
  //   try {
  //     setContent(parseHandlebars(value ?? ''));
  //   } catch (err) {
  //     logger.error('parse error', err);
  //   }
  // }, [value]);

  const handleOnHover = (e: React.MouseEvent<any>) => {
    // logger.info('editor hover', e);

    if (e.type === 'mouseover' && (e.target as HTMLElement)?.id) {
      onHover?.((e.target as HTMLElement)?.id ?? '');
    } else {
      onHover?.('');
    }
  };

  const formatString = (str: string) => {
    return splitByHandlebars(str).join(' ').trim();
  };

  return (
    <LexicalComposer
      initialConfig={{
        namespace: 'MyEditor',
        theme,
        onError,
        editable: !disabled,
        nodes: [VariableNode], //, MentionNode],
        editorState: JSON.stringify({
          root: parseHandlebars(value ?? '', true),
        }),
      }}
    >
      <Stack
        sx={containerSx}
        flexDirection={orientation === 'vertical' ? 'column' : 'row'}
        onMouseOver={handleOnHover}
        onMouseLeave={handleOnHover}
      >
        {plugins?.start ? plugins.start : null}
        <PlainTextPlugin rowRange={rowRange} placeholder={placeholder} />
        <TemplatingPlugin />
        {/* <HistoryPlugin /> */}
        <AutoFocusPlugin autofocus={autofocus} />
        {/* <MentionsPlugin /> */}
        {/* <SpeechToTextPlugin /> */}
        <ClearEditorPlugin />
        <FormPlugin value={initialValue} />
        <ReadOnlyPlugin disabled={disabled} />
        <OnChangePlugin
          onChange={(editorState) => {
            // logger.log('editor state', editorState.toJSON());
            const template = exportHandlebars(editorState.toJSON().root);
            const formatted = formatString(template);
            logger.log('formatted template on change', formatted);
            onChange(formatted);
          }}
        />
        {plugins?.end ? plugins.end : null}
      </Stack>
    </LexicalComposer>
  );
}

export default Editor;
