import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/joy';
import FieldWrapper from '../../utils/FieldWrapper';
import { TextField } from '../../ui';
import AddressAutocomplete from '../../ui/organisms/forms/AddressAutocomplete';
import PhoneInput from '../../ui/atoms/inputs/PhoneInput/index';
import Setting from '../../ui/templates/forms/SettingForm';

function SupportInfo() {
  const { t } = useTranslation();

  const defaultProps = {
    fullWidth: true,
    component: TextField,
    required: true,
    orientation: 'horizontal',
  };

  return (
    <Stack spacing={2} maxWidth={700}>
      <Setting label={t('settings.supportinfo.businessName.label')} required>
        <FieldWrapper
          {...defaultProps}
          name="info.name"
          placeholder={t('settings.supportinfo.businessName.label')}
        />
      </Setting>

      <Setting label={t('settings.supportinfo.email.label')} required>
        <FieldWrapper
          {...defaultProps}
          name="info.email"
          placeholder={t('settings.supportinfo.email.label')}
          type="email"
        />
      </Setting>

      <Setting label={t('settings.supportinfo.address.label')} required>
        <FieldWrapper
          {...defaultProps}
          component={AddressAutocomplete}
          name="info.address"
          placeholder={t('settings.supportinfo.address.label')}
          isRequired={(field: string) => !['line2'].includes(field)}
        />
      </Setting>

      <Setting label={t('settings.supportinfo.website.label')} required>
        <FieldWrapper
          {...defaultProps}
          name="info.website"
          placeholder={t('settings.supportinfo.website.label')}
        />
      </Setting>

      <Setting label={t('settings.supportinfo.phone.label')} required>
        <FieldWrapper
          {...defaultProps}
          component={PhoneInput}
          name="info.phone"
          placeholder={t('settings.supportinfo.phone.label')}
        />
      </Setting>
    </Stack>
  );
}

export default SupportInfo;
