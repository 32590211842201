/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IDable } from '@sakari-io/sakari-typings';
import { Sheet, Checkbox, Radio, RadioProps, CheckboxProps } from '@mui/joy';

export interface SelectableCardProps<T> extends Omit<RadioProps, 'component'> {
  aspectRatio?: string;
  width?: number;
  height?: number;
  multiple?: boolean;
}

function SelectableCard<T extends IDable>({
  aspectRatio = '1/1',
  width,
  height,
  variant = 'outlined',
  color = 'primary',
  size = 'md',
  multiple,
  sx,
  children,
  ...rest
}: SelectableCardProps<T>) {
  const inputProps = {
    overlay: true,
    disableIcon: true,
    size,
    color,
    variant,
    slotProps: {
      action: ({ checked }: any) => ({
        sx: {
          ...(checked && {
            backgroundColor: `var(--joy-palette-${color}-${variant}HoverBg)`,
            border: `solid 2px var(--joy-palette-${color}-${variant}Border)`,
            boxShadow: `var(--joy-shadow-${size})`,
          }),
        },
      }),
      label: ({ checked }: any) => ({
        sx: {
          color: `var(--joy-palette-neutral-${variant}Color)`,
          ...(rest.disabled && {
            color: `var(--joy-palette-${color}-${variant}DisabledColor)`,
          }),
          ...(checked && {
            color: `var(--joy-palette-${color}-${variant}ActiveColor)`,
          }),
        },
      }),
    },
    sx: {
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyItems: 'center',
      ...(rest.disabled && {
        backgroundColor: `var(--joy-palette-${color}-disabledBg)`,
        opacity: 0.6,
      }),
      ...sx,
    },
    ...rest,
  };

  return (
    <Sheet
      sx={{
        aspectRatio,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: `var(--joy-radius-${size})`,
        padding: `var(--joy-radius-${size})`,
        width: width ?? '100%',
        height,
        '--RadioGroup-gap': 0,
      }}
    >
      {multiple ? (
        <Checkbox {...(inputProps as CheckboxProps)} />
      ) : (
        <Radio {...(inputProps as RadioProps)} />
      )}
    </Sheet>
  );
}

export default SelectableCard;
