const ATTRIBUTES = [
  {
    label: 'First Name',
    name: 'firstName',
    id: 'firstName',
    type: 'default',
  },
  {
    label: 'Last Name',
    name: 'lastName',
    id: 'lastName',
    type: 'default',
  },
  {
    label: 'Email',
    name: 'email',
    id: 'email',
    type: 'default',
  },
  {
    label: 'Mobile',
    name: 'mobile',
    id: 'mobile',
    type: 'default',
  },
];

export default ATTRIBUTES;
