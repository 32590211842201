import _ from 'lodash';
import {
  SakariAPIResponse,
  SearchablePaginationRequest,
  User,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { buildGridQuery, getAccountId } from './common';
import { AccountIdWith } from '../types';

const PROFILE_TAG = 'Profile';
const USER_NOTIFICATIONS_TAG = 'User Notifications';

interface VerificationRequest {
  verificationId: string;
  code: string;
}

interface VerificationResult {
  accountCredited?: boolean;
}

export const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query<SakariAPIResponse<User>, void>({
      query: () => `profile`,
      providesTags: [PROFILE_TAG],
    }),
    updateUserProfile: builder.mutation<SakariAPIResponse<User>, User>({
      query: (data) => ({
        url: `profile`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [PROFILE_TAG],
    }),
    acceptTAC: builder.mutation<SakariAPIResponse<any>, any>({
      query: () => ({
        url: `profile/accepttac`,
        method: 'PUT',
      }),
      invalidatesTags: [PROFILE_TAG],
    }),
    requestEmailVerification: builder.query<SakariAPIResponse<string>, string>({
      query: (email) => `../verifyemail?email=${encodeURIComponent(email)}`,
    }),
    requestUserMobileVerification: builder.mutation<
      SakariAPIResponse<string>,
      any
    >({
      query: (request) => {
        return {
          url: `profile/verifymobile`,
          method: 'POST',
          body: request,
        };
      },
    }),
    resetPassword: builder.mutation<SakariAPIResponse<any>, any>({
      query: (data) => ({
        url: `profile/password`,
        method: 'PUT',
        body: data,
      }),
    }),
    checkUserMobileVerification: builder.mutation<
      SakariAPIResponse<VerificationResult>,
      VerificationRequest
    >({
      query: ({ verificationId, code }) => ({
        url: `profile/verifymobile/${verificationId}`,
        method: 'PUT',
        body: {
          code,
        },
      }),
    }),
    subscribeToUserNotifications: builder.mutation<
      SakariAPIResponse,
      PushSubscription
    >({
      query: (subscription) => ({
        url: 'profile/webpush',
        method: 'POST',
        body: subscription.toJSON(),
      }),
    }),
    unsubscribeToUserNotifications: builder.mutation<
      SakariAPIResponse,
      PushSubscription
    >({
      query: (subscription) => ({
        url: `webpush/unregister`,
        method: 'POST',
        body: _.assign({ type: 'webpush' }, subscription),
      }),
    }),

    getUserNotifications: builder.query<
      SakariAPIResponse<any>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/usernotifications?${buildGridQuery(request)}`,
      providesTags: [USER_NOTIFICATIONS_TAG],
    }),
    updateUserNotifications: builder.mutation<SakariAPIResponse<any>, any[]>({
      query: (groups) => ({
        url: `accounts/${getAccountId()}/usernotifications`,
        method: 'PUT',
        body: groups,
      }),
      invalidatesTags: [USER_NOTIFICATIONS_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useAcceptTACMutation,
  useRequestEmailVerificationQuery,
  useRequestUserMobileVerificationMutation,
  useResetPasswordMutation,
  useCheckUserMobileVerificationMutation,
  useSubscribeToUserNotificationsMutation,
  useUnsubscribeToUserNotificationsMutation,
  useGetUserNotificationsQuery,
  useUpdateUserNotificationsMutation,
} = extendedApi;
