import { NodeTypes } from 'reactflow';
import WorkflowNode from './WorkflowNode';
import TriggerContainer from '../../Edit/Trigger/TriggerCard/TriggerContainer';
import PlaceholderNode from '../PlaceholderNode';

const nodeTypes: NodeTypes = {
  actionPlaceholder: PlaceholderNode,
  trigger: TriggerContainer,
  node: WorkflowNode,
};

export default nodeTypes;
