import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@sakari-io/sakari-components';
import { AppDialog } from '../../..';

interface CustomScheduleMessageProps {
  open: boolean;
  onSchedule: (date: Date) => void;
  onClose: () => void;
}

function CustomScheduleMessage({
  open,
  onSchedule,
  onClose,
}: CustomScheduleMessageProps) {
  const { t } = useTranslation();
  const [scheduleDateTime, setScheduleDateTime] = useState<Date>(new Date());
  const handleChange = (date: Date) => {
    setScheduleDateTime(date);
  };
  return (
    <AppDialog
      confirmButtonProps={{
        children: t('scheduleMessage.customTimeSaveButtonText'),
        onClick: () => {
          onSchedule(scheduleDateTime);
          onClose();
        },
      }}
      cancelButtonProps={{
        children: t('scheduleMessage.customTimeCancelButtonText'),
        onClick: () => onClose(),
      }}
      header={t('scheduleMessage.title')}
      open={open}
      setOpen={() => onClose()}
      content={
        <DateTimePicker
          label={t('scheduleMessage.customTimeDatePickerLabel')}
          minDateTime={new Date()}
          value={scheduleDateTime}
          onChange={(date: Date | null) => date && handleChange(date)}
          disableIgnoringDatePartForTimeValidation
        />
      }
    />
  );
}

export default CustomScheduleMessage;
