import { Stack, Typography, Checkbox } from '@mui/joy';
import {
  GridColumnHeaderParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { Account } from '@sakari-io/sakari-typings';
import { useGetUserNotificationsQuery } from '../../../../api';
import DataGrid from '../../../molecules/DataGrid';
import EmptyResult from '../../../molecules/EmptyResult';
import FieldWrapper from '../../../../utils/FieldWrapper';

export interface GroupNotificationSettings {
  id: string;
  name: string;
  browser?: boolean;
  email?: boolean;
  sms?: boolean;
}

// TODO why is grid so intertwined with redux form?
function NotificationsGrid({ account }: { account: Account }) {
  const { t } = useTranslation();
  const form = useForm();
  const { values } = useFormState({ subscription: { values: true } });

  const handleToggleAll = (e: React.SyntheticEvent, data: any) => {
    // logger.info({ data: data, groups: groups, target: e.target });
    const { checked } = e.target as HTMLInputElement;
    const { field } = data;
    const { groups } = values;

    groups.forEach((group: GroupNotificationSettings, index: number) => {
      form?.change(`groups[${index}].${field}`, checked);
    });
  };

  function ColumnHeaderCheckbox({ colDef }: { colDef: any }): JSX.Element {
    const { field, headerName } = colDef;

    const { groups } = values;
    const groupsChecked = groups?.filter(
      (group: GroupNotificationSettings) =>
        group[field as keyof GroupNotificationSettings],
    ).length;
    const isIndeterminate = groups?.length > groupsChecked && groupsChecked > 0;
    const isSelected = groups?.length > 0 && groupsChecked === groups?.length;

    return (
      <Stack direction="row" gap={1}>
        <Checkbox
          onClick={(e) => handleToggleAll(e, colDef)}
          indeterminate={isIndeterminate}
          checked={isSelected}
          size="sm"
          disabled={groups?.length === 0}
        />
        <Typography level="body-sm" textColor="text.secondary">
          {headerName}
        </Typography>
      </Stack>
    );
  }

  function CellCheckbox({
    params,
  }: {
    params: GridRenderCellParams;
  }): JSX.Element {
    const { api, id, field } = params;
    const index: number = api.getRowIndexRelativeToVisibleRows(id);
    return (
      <FieldWrapper
        component={Checkbox}
        name={`groups[${index}].${field}`}
        size="sm"
        type="checkbox"
      />
    );
  }

  const columns: GridColDef[] = [
    {
      field: `name`,
      headerName: t('group'),
      headerClassName: 'gridHeaders',
      flex: 2,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: `email`,
      headerName: t('email'),
      headerClassName: 'gridHeaders',
      width: 110,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <ColumnHeaderCheckbox colDef={params.colDef} />;
      },
      renderCell: (params: GridRenderCellParams) => {
        return <CellCheckbox params={params} />;
      },
    },
    {
      field: `sms`,
      headerName: 'SMS',
      headerClassName: 'gridHeaders',
      width: 110,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <ColumnHeaderCheckbox colDef={params.colDef} />;
      },
      renderCell: (params: GridRenderCellParams) => {
        return <CellCheckbox params={params} />;
      },
    },
    {
      field: `browser`,
      headerName: t('Browser'),
      headerClassName: 'gridHeaders',
      width: 130,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <ColumnHeaderCheckbox colDef={params.colDef} />;
      },
      renderCell: (params: GridRenderCellParams) => {
        return <CellCheckbox params={params} />;
      },
    },
  ];

  return (
    <DataGrid
      loadingText="Loading notification settings..."
      columns={columns}
      filter={{
        accountId: account.id,
        request: {
          q: '',
        },
      }}
      renderNoData={<EmptyResult heading={t('noGroups')} item="groups" />}
      loadData={useGetUserNotificationsQuery}
    />
  );
}

export default NotificationsGrid;
