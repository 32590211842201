import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSupportedLanguages } from '../../../../../constants';
import MenuItem from '../../../../atoms/MenuItem';
import AppMenu from '../../../../molecules/Menu';

interface LanguageMenuProps {
  onSelectLanguage: (language: string) => void;
  onClose: (isOpen: boolean) => void;
  isSelected: (id: string) => boolean;
}

function LanguageMenu({
  onSelectLanguage,
  onClose,
  isSelected,
}: LanguageMenuProps) {
  const { t } = useTranslation();

  const handleClick = (id: string) => {
    onSelectLanguage(id);
    onClose(false);
  };
  return (
    <AppMenu>
      {getSupportedLanguages().map(({ id }) => (
        <MenuItem
          id={id}
          key={id}
          onClick={() => handleClick(id)}
          label={t(`languageMenu.${id}.label`)}
          selected={isSelected(id)}
          variant={isSelected(id) ? 'soft' : 'plain'}
        />
      ))}
    </AppMenu>
  );
}
export default LanguageMenu;
