import React, { useState, useCallback, useRef } from 'react';
import _ from 'lodash';
import ReactFlow, {
  useReactFlow,
  Node as RFNode,
  ProOptions,
  applyNodeChanges,
  applyEdgeChanges,
  Background,
  BackgroundVariant,
} from 'reactflow';
import { useSelector, useDispatch } from 'react-redux';
import {
  WorkflowNode,
  WorkflowEdge,
  Workflow as WorkflowType,
  WorkflowNodeType,
  WorkflowVersion,
} from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import useLayout from '../Canvas/hooks/useLayout';
import NODE_TYPES from '../Canvas/NodeTypes';
import EDGE_TYPES from '../Canvas/EdgeTypes';

import AddElementDrawer from './AddElementDrawer';
import AddTriggerDrawer from './Trigger/AddTriggerDrawer';

import WorkflowDetailsDrawer from '../View/WorkflowDetailsDrawer';
import ConfigDrawer from './ConfigDrawer';

import { Mode, SubMode, actions } from '../../../redux/reducers/workflow';
import { isNodeConnectable } from '../Canvas/hooks/formatWorkflowDefinition';
import Controls from '../Canvas/Controls';
import Panel from '../Canvas/Panel';

import CustomConnectionLine from './CustomConnectionLine';
import 'reactflow/dist/style.css';

const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

interface WorkflowProps {
  workflow: WorkflowType;
  version: WorkflowVersion;
  onVersionChange: (versionId: string) => any;
}

export type SelectedValuesType = {
  value: string;
  type: string;
};

function Workflow({ workflow, version, onVersionChange }: WorkflowProps) {
  useLayout();

  const ref = useRef(null);

  const { mode, subMode, currentRFNode, selectionType, nodes, edges } =
    useSelector((state: any) => state.workflow.present);

  const prevNodes = useSelector(
    (state: any) =>
      state.workflow.past[state.workflow.past.length - 1]?.nodes ?? [],
  );

  const [workflowZoom, setWorkflowZoom] = useState(1);

  const { getZoom, fitView } = useReactFlow();

  const dispatch = useDispatch();

  function hasChanges(currentNodes: any) {
    if (prevNodes?.length !== currentNodes?.length) return true;
    const currentSorted = _.sortBy(currentNodes, 'id');
    const prevSorted = _.sortBy(prevNodes, 'id');
    return !_.isEqual(currentSorted, prevSorted);
  }

  const onNodesChange = useCallback(
    (changes: any) => {
      dispatch(actions.checkForChanges());
      if (hasChanges(changes)) {
        if (prevNodes.length === changes.length || mode === Mode.SELECTION) {
          logger.info();
        }
        return dispatch(
          actions.setNodeDefinition({
            nodes: applyNodeChanges(changes, nodes),
          }),
        );
      }
      return null;
    },
    [nodes, dispatch],
  );

  const onEdgesChange = useCallback(
    (changes: any) => {
      dispatch(
        actions.setEdgeDefinition({
          edges: applyEdgeChanges(changes, edges),
        }),
      );
    },
    [edges, dispatch],
  );

  const reactFlowStyle = {
    backgroundColor: 'var(--joy-palette-background-body)',
  };

  const handleSelectNode = (rfNode?: RFNode) => {
    if (mode === Mode.SELECTION) {
      if (rfNode?.data?.disabled) return;

      dispatch(actions.setElementOutputNode(rfNode));

      if (subMode === SubMode.SELECTION_BY_TYPE) {
        dispatch(
          actions.exitSelectionMode({
            type: 'output',
            nodeId: rfNode?.id,
            name: 'Output Contact',
            path: selectionType,
          }),
        );
      }
    } else {
      dispatch(actions.setCurrentRFNode(rfNode));
      fitView({
        nodes: rfNode ? [rfNode] : nodes,
        duration: 800,
        maxZoom: 1,
      });
    }
  };

  const updateNode = (nt?: WorkflowNodeType) => {
    if (nt) {
      dispatch(actions.setNodeType(nt));
    }
  };

  const updateNodes = (nt?: WorkflowNodeType) => {
    if (nt) {
      const nodeId = currentRFNode?.id;
      // TODO centralize node creation
      const newNode = {
        id: nodeId,
        type: nt.type,
        position: { x: 0, y: 0 },
        data: { type: nt, ...currentRFNode?.data.config, mode: Mode.EDIT },
      };
      dispatch(
        actions.updateNode({
          currentNodeId: nodeId,
          newNode,
        }),
      );
      dispatch(actions.setCurrentRFNode(newNode));
    }
  };

  const firstNode = nodes[0];
  const basePadding = 100;
  const paddingFactor = 100;

  const dynamicPadding = Math.min(
    basePadding + paddingFactor * nodes?.length,
    2000,
  );

  const nodesInView = nodes?.filter(
    (node: RFNode) =>
      node.position.y >= firstNode.position.y &&
      node.position.y <= firstNode.position.y + dynamicPadding,
  );

  const fitViewOptions = {
    padding: 0.1,
    includeHiddenNodes: true,
    minZoom: 0.2,
    maxZoom: 1,
    duration: 300,
    nodes: nodesInView,
  };

  const edgesOfNode = edges.filter(
    (edge: WorkflowEdge) => edge.source === currentRFNode?.id,
  );

  return (
    <ReactFlow
      ref={ref}
      style={reactFlowStyle}
      panOnScroll
      proOptions={proOptions}
      disableKeyboardA11y
      deleteKeyCode={null}
      // nodes and edges
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={NODE_TYPES}
      edgeTypes={EDGE_TYPES}
      onNodeClick={(evt, rfNode) => handleSelectNode(rfNode)}
      // dragging and connecting
      onConnectStart={() => dispatch(actions.setDragging(true))}
      onConnectEnd={() => dispatch(actions.setDragging(false))}
      connectionLineComponent={CustomConnectionLine}
      // view
      fitView
      fitViewOptions={fitViewOptions}
      nodesDraggable={false}
      nodesConnectable={mode === Mode.EDIT}
      selectNodesOnDrag
      zoomOnDoubleClick={false}
      onMove={() => {
        setWorkflowZoom(getZoom());
      }}
      // connection
      autoPanOnConnect
      onConnect={(conn) => {
        logger.info('onConnect', conn);
        const { source, target } = conn;
        if (source && target) {
          dispatch(
            actions.addGoto({
              source,
              target,
            }),
          );
        }
      }}
      isValidConnection={(connection) => {
        logger.info('isValidConnection', connection);
        const targetNode = nodes.find(
          (n: WorkflowNode) => n.id === connection.target,
        );
        if (!isNodeConnectable(targetNode?.type?.type)) {
          return false;
        }
        if (connection.source === connection.target) {
          return false;
        }
        return true;
      }}
    >
      <Controls workflowZoom={workflowZoom} />

      {mode === Mode.EDIT &&
        currentRFNode?.type === 'trigger' &&
        !currentRFNode.data.type && (
          <AddTriggerDrawer
            open
            onClose={(nt) => {
              // dispatch(actions.setDrawer(Drawer.Configure));
              updateNodes(nt);
            }}
          />
        )}

      {mode === Mode.EDIT &&
        subMode !== SubMode.DRAGGING &&
        currentRFNode?.data?.type?.type === 'actionPlaceholder' && (
          <AddElementDrawer
            open
            onClose={(nt) => {
              updateNode(nt);
            }}
          />
        )}

      {(mode === Mode.EDIT || mode === Mode.VIEW || mode === Mode.SELECTION) &&
        currentRFNode &&
        currentRFNode?.data?.type?.type !== 'actionPlaceholder' && (
          <ConfigDrawer
            workflow={workflow}
            version={version}
            nodeType={currentRFNode?.data?.type}
            nodeId={currentRFNode?.id}
            config={currentRFNode?.data?.config}
            edges={edgesOfNode}
            mode={mode}
            onClose={() => {
              if (mode !== Mode.SELECTION) {
                // only reset the current rf node if not in SELECTION mode
                dispatch(actions.setCurrentRFNode(undefined));
              }
            }}
            hidden={mode === Mode.SELECTION}
          />
        )}

      {(mode === Mode.CONTACTS || mode === Mode.VERSIONS) && (
        <WorkflowDetailsDrawer
          open
          workflow={workflow}
          version={version}
          onVersionChange={onVersionChange}
        />
      )}

      <Background
        color={
          mode === Mode.SELECTION
            ? 'var(--joy-palette-info-300)'
            : 'var(--joy-palette-primary-200)'
        }
        variant={BackgroundVariant.Lines}
        style={{
          opacity: 0.3,
          backgroundColor:
            mode === Mode.SELECTION
              ? 'var(--joy-palette-info-200)'
              : 'var(--joy-palette-neutral-100)',
        }}
        lineWidth={0.5}
        offset={0.5}
        gap={10}
      />

      {mode === Mode.VIEW ? ( // && versionSummary?.data?.paths?.length ? (
        <Panel position="top-right" />
      ) : null}
    </ReactFlow>
  );
}

export default Workflow;
