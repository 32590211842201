import { SakariAPIResponse, Domain } from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { getAccountId } from './common';
import { ACCOUNT_TAG } from './tags';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    createDomain: builder.mutation<SakariAPIResponse<Domain>, string>({
      query: (domain) => ({
        url: `accounts/${getAccountId()}/domains`,
        method: 'POST',
        body: { domain },
      }),
      invalidatesTags: [ACCOUNT_TAG],
    }),

    verifyDomain: builder.mutation<SakariAPIResponse<Domain>, string>({
      query: (domain) => ({
        url: `accounts/${getAccountId()}/domains/verify`,
        method: 'PUT',
        body: { domain },
      }),
      invalidatesTags: [ACCOUNT_TAG],
    }),

    deleteDomain: builder.mutation<SakariAPIResponse, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/domains/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ACCOUNT_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateDomainMutation,
  useVerifyDomainMutation,
  useDeleteDomainMutation,
} = extendedApi;
