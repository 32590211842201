import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';

import { logger } from '@sakari-io/sakari-components';
import i18next from '../i18n';

import ActionType from '../redux/action-types';

const { t } = i18next;
const mesagges: { [index: string]: any } = {
  GET: t('getData'),
  POST: t('postData'),
  PUT: t('postData'),
  DELETE: t('deleteData'),
};

/**
 * Log a warning and show a toast!
 */
const rtkQueryLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      logger.warn('We got a rejected action!');
      const method: string =
        action?.meta?.baseQueryMeta?.request?.method ?? 'GET';

      api.dispatch({
        type: ActionType.NOTIFICATION_UPDATE,
        payload: {
          enable: true,
          message: mesagges[method],
        },
      });
    }

    return next(action);
  };

export default rtkQueryLogger;
