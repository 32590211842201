import { Button, Stack } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Integrations, { IntegrationsViewMode } from '.';
import { useGetInstalledIntegrationsQuery } from '../../api';
import { AccountContext } from '../../contexts/account.context';
import EmptyResult from '../../ui/molecules/EmptyResult';
import InstalledIntegrationCardItem from '../../ui/organisms/cards/InstalledIntegrationCardItem';
import ToastSnackbar from '../../ui/organisms/alerts/ToastSnackbar';
import { handleContactSupport } from '../../ui/organisms/navigation/menus/SupportMenu';

function Installed() {
  const { account, profile } = useContext(AccountContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState<{
    message: string | undefined;
    status: string;
  }>({
    message: '',
    status: 'info',
  });

  const { data: integrationsData, isLoading } =
    useGetInstalledIntegrationsQuery(
      account
        ? {
            accountId: account.id,
            request: {},
          }
        : skipToken,
    );

  const installedIntegrations = Object.values(integrationsData?.data || []);

  const handleCloseAlert = () => {
    setAlert({
      message: undefined,
      status: 'info',
    });
  };

  const handleContactSupportClick = () => {
    handleContactSupport({
      name: {
        value: profile?.firstName || account?.name || '',
      },
      email: {
        value: profile?.email || account?.info?.email || '',
      },
      subject: {
        value:
          `Error connecting to ${location.state?.integration}` ?? 'Integration',
      },
      description: {
        value:
          `Error connecting to ${
            location.state?.integration ?? 'Integration'
          }: '${alert.message}'` || '',
      },
    });
  };

  useEffect(() => {
    if (!location.search) return;

    const params = new URLSearchParams(location.search);

    if (params.get('error')) {
      setAlert({
        message: params.get('error') || undefined,
        status: params.get('status') || 'info',
      });

      setTimeout(() => {
        handleCloseAlert();
      }, 10000);
    }
  }, []);

  if (isLoading) {
    return <Loader size={200} label="Loading integrations..." hideBackdrop />;
  }

  return (
    <>
      <ToastSnackbar
        open={!!alert.message}
        onClose={handleCloseAlert}
        status="error"
        title={t('couldNotConnect')}
        message={alert.message}
        placement={{ vertical: 'top', horizontal: 'center' }}
        style={{ position: 'absolute' }}
        sx={{
          margin: '16px',
          whiteSpace: 'pre-wrap',
        }}
        fullWidth
      >
        <Button
          variant="plain"
          color="danger"
          size="sm"
          sx={{
            ml: -2,
          }}
          onClick={handleContactSupportClick}
          endDecorator={<FontAwesomeIcon icon={solid('arrow-right')} />}
        >
          {t('supportMenu.contactSupport')}
        </Button>
      </ToastSnackbar>
      <Integrations view={IntegrationsViewMode.INSTALLED}>
        {installedIntegrations.length === 0 ? (
          <EmptyResult
            heading={t('integrations.installed.empty.title')}
            text={t('integrations.installed.empty.description')}
            buttonText="Go to Marketplace"
            onButtonClicked={() => navigate('/marketplace')}
          />
        ) : (
          <Stack direction="column" spacing={1.5}>
            {installedIntegrations.map((integration) => (
              <InstalledIntegrationCardItem
                key={integration.id}
                integration={integration}
              />
            ))}{' '}
          </Stack>
        )}
      </Integrations>
    </>
  );
}

export default Installed;
