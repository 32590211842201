/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import { Stack, Button, Checkbox, Typography } from '@mui/joy';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { CloseAccount } from '@sakari-io/sakari-typings';
import { useNavigate } from 'react-router-dom';
import { Textarea } from '@sakari-io/sakari-components';
import { useCloseAccountMutation } from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import clearCookies from '../../../../utils/authentication';
import { AccountContext } from '../../../../contexts/account.context';
import Radios from '../../../molecules/Radios';
import Checkboxes from '../../../molecules/inputs/Checkboxes';
import { AppDialog } from '../../..';

interface Question {
  title: string;
  name: string;
  type: (
    question: Question,
    value: string | string[],
    onChange: (value: string | string[]) => any,
  ) => JSX.Element;
  translationPrefix?: string;
  hideNext?: boolean;
  next?: Question;
  options?: { [key: string]: Question | undefined };
}

interface CloseAccountDialogProps {
  open: boolean;
  onClose: () => any;
}

function CloseAccountDialog({ open, onClose }: CloseAccountDialogProps) {
  const { account } = useContext(AccountContext);
  function SingleSelect(
    question: Question,
    value: string | string[],
    onChange: (value: string | string[]) => any,
  ) {
    const { options, translationPrefix } = question;
    return (
      <Radios
        items={_.keys(options).map((item) => ({
          label: t(`${translationPrefix}.${item}.label`),
          value: item,
        }))}
        value={value as string}
        onChange={onChange}
      />
    );
  }

  function MultiSelect(
    question: Question,
    value: string | string[],
    onChange: (value: string | string[]) => any,
  ) {
    const valueArr = (value as string[]) || [];
    const { options, translationPrefix } = question;
    return (
      <Stack>
        <Checkboxes
          items={_.keys(options).map((item) => ({
            label: t(`${translationPrefix}.${item}.label`),
            value: item,
          }))}
          value={valueArr.reduce(
            (res, a) => {
              res[a] = true;
              return res;
            },
            {} as { [key: string]: boolean },
          )}
          onChange={(val: any) => onChange(_.keys(_.pickBy(val, (v) => v)))}
        />

        {valueArr.indexOf('other') >= 0 ? (
          <Textarea
            label={t('sayAnything')}
            value={(
              valueArr.find((v) => v.startsWith('other-')) || ''
            ).substring(6)}
            onChange={(newVal: string) =>
              onChange([
                ...valueArr.filter((v) => !v.startsWith('other-')),
                `other-${newVal}`,
              ])
            }
          />
        ) : null}
      </Stack>
    );
  }

  function TextArea(
    question: Question,
    value: string | string[],
    onChange: (value: string) => any,
  ): JSX.Element {
    return (
      <Textarea
        label={t('sayAnything')}
        value={Array.isArray(value) ? value.join(',') : value}
        onChange={onChange}
        minRows={7}
      />
    );
  }

  function OtherFeedback(
    question: Question,
    value: string | string[],
    onChange: (value: string | string[]) => any,
  ) {
    return (
      <Stack spacing={3}>
        <Textarea
          value={value?.length ? value[0] : ''}
          onChange={(newVal: string) =>
            onChange([newVal, value ? value[1] : 'false'])
          }
          minRows={7}
          label={t('sayAnything')}
        />

        <Checkbox
          checked={value ? value[1] === 'true' : false}
          onChange={(e) =>
            onChange([value ? value[0] : '', String(e.target.checked)])
          }
          size="sm"
          label={t('settings.general.closeAccount.feedback.exchange')}
        />
      </Stack>
    );
  }

  function Success() {
    return (
      <Button
        onClick={() => {
          handleClosedAccount();
        }}
      >
        {t('returnToLogin')}
      </Button>
    );
  }

  function Promo() {
    const handleDiscountSelection = async (discount: boolean) => {
      closeAccount({
        reason: answers.why,
        feedback: answers[currentQuestion.name][0],
        functionality: answers.missingFunctionality || '',
        competitor: answers.usingOtherProduct || '',
        endingUsage: answers.noLongerUsing || [],
        discount,
        followUp: Boolean(answers[currentQuestion.name][1]),
      } as CloseAccount)
        .unwrap()
        .then((res) => {
          if (res.success) {
            if (discount) {
              navigate('/billing');
              dispatch(
                showToast({
                  message: t(
                    'settings.general.closeAccount.confirmation.great',
                  ),
                  severity: 'success',
                }),
              );
            } else {
              handleClosedAccount();
              dispatch(
                showToast({
                  message: t('settings.general.closeAccount.successMessage'),
                  severity: 'success',
                }),
              );
            }
            // return setCurrentQuestion(SUCCESS_MESSAGE);}
          }
        })
        .catch((err) => {
          dispatch(
            showToast({
              message: err.data.error?.message || t('somethingWrong'),
              severity: 'error',
              timeout: 10000,
            }),
          );
        });
    };

    return (
      <Stack
        sx={{
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
        }}
      >
        <Button
          color="danger"
          variant="outlined"
          onClick={() => {
            handleDiscountSelection(false);
          }}
          loading={isClosing}
        >
          {t('settings.general.closeAccount.confirmation.notInterested')}
        </Button>
        <Button
          onClick={() => {
            handleDiscountSelection(true);
          }}
          loading={isClosing}
        >
          {t('settings.general.closeAccount.confirmation.stay')}
        </Button>
      </Stack>
    );
  }

  const SUCCESS_MESSAGE: Question = {
    title: 'settings.general.closeAccount.successMessage',
    name: 'success',
    type: Success,
    hideNext: true,
  };
  const FEEDBACK_QUESTION: Question = {
    title: 'settings.general.closeAccount.feedback.question',
    name: 'feedback',
    next: SUCCESS_MESSAGE,
    type: OtherFeedback,
  };

  const QUESTIONS: Question = {
    title: 'settings.general.closeAccount.why',
    name: 'why',
    type: SingleSelect,
    translationPrefix: 'settings.general.closeAccount.reasons',
    options: {
      price: FEEDBACK_QUESTION,
      difficultyOfUse: {
        title: 'settings.general.closeAccount.selectAll',
        name: 'difficultyOfUse',
        type: MultiSelect,
        translationPrefix:
          'settings.general.closeAccount.reasons.specificReasons',
        next: FEEDBACK_QUESTION,
        options: {
          buggy: undefined,
          clunky: undefined,
          outdated: undefined,
          slow: undefined,
          other: undefined,
        },
      },
      missingFunctionality: {
        title: 'settings.general.closeAccount.feedback.features',
        name: 'missingFunctionality',
        type: TextArea,
        next: FEEDBACK_QUESTION,
      },
      usingOtherProduct: {
        title: 'settings.general.closeAccount.feedback.whichProduct',
        name: 'usingOtherProduct',
        type: SingleSelect,
        translationPrefix:
          'settings.general.closeAccount.reasons.otherProducts',
        next: FEEDBACK_QUESTION,
        options: {
          hubspot: undefined,
          intercom: undefined,
          heyMarket: undefined,
          attentive: undefined,
          salesMsg: undefined,
          ezTexting: undefined,
          aircall: undefined,
          avochato: undefined,
          simpleText: undefined,
          other: undefined,
        },
      },
      noLongerUsing: {
        title: 'settings.general.closeAccount.selectAll',
        name: 'noLongerUsing',
        type: MultiSelect,
        translationPrefix:
          'settings.general.closeAccount.reasons.noLongerUsing',
        next: FEEDBACK_QUESTION,
        options: {
          decisionMaker: undefined,
          smsMarketing: undefined,
          strategyChanged: undefined,
          other: undefined,
        },
      },
      other: {
        title: 'settings.general.closeAccount.feedback.question',
        name: 'feedback',
        next: SUCCESS_MESSAGE,
        type: OtherFeedback,
        options: {},
      },
    },
  };

  const PROMO_QUESTION: Question = {
    title: 'settings.general.closeAccount.confirmation.discountQuestion',
    name: 'promo',
    type: Promo,
    hideNext: true,
  };

  const { t } = useTranslation();

  const [answers, setAnswers] = useState<{ [key: string]: string | string[] }>(
    {},
  );
  const [closeAccount, { error, isLoading: isClosing }] =
    useCloseAccountMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error && 'data' in error) {
      if ((error as any).data.error.message) {
        dispatch(
          showToast({
            message: (error as any).data.error.message,
            severity: 'info',
          }),
        );
      }
    }
  }, [error, navigate]);

  const [currentQuestion, setCurrentQuestion] = useState<any>(QUESTIONS);

  const getCurrentAnswer = () => {
    // logger.info('getCurrentAnswer', answers, currentQuestion);
    return answers[currentQuestion.name];
  };

  const setAnswer = (name: string, value: string | string[]): any => {
    // logger.info('setAnswer', name, value);
    setAnswers({ ...answers, [name]: value });
  };

  const renderTitle = (question: any) => {
    return (
      <Typography level="h4" textAlign="center" whiteSpace="pre-wrap">
        {t(question.title)}
      </Typography>
    );
  };

  const handleCancel = () => {
    setAnswers({});
    setCurrentQuestion(QUESTIONS);
    onClose();
  };

  const handleClosedAccount = () => {
    onClose();
    clearCookies();
    navigate('/accounts');
  };

  const handleNext = async (question: Question, currentAnswer: any) => {
    if (
      currentQuestion.name === 'feedback' ||
      currentQuestion.name === 'promo'
    ) {
      if (answers.why === 'price' && currentQuestion.name === 'feedback') {
        if (
          account?.plan?.name !== 'Trial' &&
          account?.plan?.billingFrequency !== 'annual'
        ) {
          return setCurrentQuestion(PROMO_QUESTION);
        }
      }

      // close account logic
      await closeAccount({
        reason: answers.why,
        feedback: answers[currentQuestion.name][0],
        functionality: answers.missingFunctionality || '',
        competitor: answers.usingOtherProduct || '',
        endingUsage: answers.noLongerUsing || [],
        discount: false,
        followUp: Boolean(answers[currentQuestion.name][1]),
      } as CloseAccount).then(() => {
        // success message not showing - have redirect and toast as placeholder
        clearCookies();
        navigate('/accounts');
        dispatch(
          showToast({
            message: t('settings.general.closeAccount.successMessage'),
            severity: 'info',
          }),
        );
        // return setCurrentQuestion(SUCCESS_MESSAGE);
      });
    }

    if (question.next) {
      return setCurrentQuestion(question.next);
    }

    return setCurrentQuestion(currentQuestion.options[currentAnswer as string]);
  };

  const renderQuestion = (question: Question) => {
    const currentAnswer = getCurrentAnswer();
    return (
      <Stack spacing={3}>
        {question.type(
          question,
          answers[currentQuestion.name],
          (value: string | string[]) => setAnswer(currentQuestion.name, value),
        )}
        {!question.hideNext ? (
          <Stack spacing={2}>
            <Button
              disabled={!currentAnswer || currentAnswer.length === 0}
              loading={isClosing}
              fullWidth
              onClick={() => handleNext(question, currentAnswer)}
              color={currentQuestion.name !== 'feedback' ? 'primary' : 'danger'}
            >
              {currentQuestion.name !== 'feedback'
                ? t('action.next')
                : t('settings.general.closeAccount.label')}
            </Button>
            <Button
              variant="plain"
              fullWidth
              onClick={() => handleCancel()}
              disabled={isClosing}
            >
              {t('cancel')}
            </Button>
          </Stack>
        ) : null}
      </Stack>
    );
  };

  return (
    <AppDialog
      open={open}
      setOpen={() => handleCancel()}
      header={renderTitle(currentQuestion)}
      content={renderQuestion(currentQuestion)}
      sx={{ width: '600px' }}
    />
  );
}

export default CloseAccountDialog;
