import _ from 'lodash';
import {
  Conversation,
  PaginationRequest,
  SakariAPIResponse,
  Message,
  SendMessageRequest,
  SendMessageResponse,
} from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { sakariApi } from './rtk';

import { getAccountId, buildQuery } from './common';
import {
  updateQueryData as updateConversationQueryData,
  selectInvalidatedBy as selectConvoInvalidatedBy,
} from './conversations';

import { LIMIT } from '../pages/Inbox/Messages/MessagesHistory';
import { AccountIdWith } from '../types';
import { CONVERSATION_TAG, MESSAGE_TAG } from './tags';

const updateConversationWithMsg = async (
  dispatch: any,
  getState: any,
  conversationId: string,
  message: Message,
) => {
  let convoPatchResult: any;

  try {
    selectConvoInvalidatedBy(getState(), [
      `${CONVERSATION_TAG}-${conversationId}`,
    ]).forEach(({ endpointName, originalArgs }: any) => {
      if (endpointName === 'getConversations') {
        convoPatchResult = dispatch(
          updateConversationQueryData(
            endpointName,
            originalArgs,
            (draft: any) => {
              const convo = draft.data.find(
                (c: Conversation) => c.id === conversationId,
              );
              if (convo) {
                if (!message.outgoing) {
                  const unreadMsgs = [...(convo?.unread ?? []), message.id];
                  convo.unread = _.uniq(unreadMsgs);
                  convo.lastMessage = message;
                } else {
                  convo.lastMessage = message;
                }
              }
            },
          ),
        );
      }
    });
  } catch {
    convoPatchResult.undo();
    logger.info('unable to update conversation');
  }
};

interface GetMessagesRequest extends PaginationRequest {
  conversationId: string;
  markRead?: number;
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query<
      SakariAPIResponse<Message[]>,
      AccountIdWith<GetMessagesRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/messages?${buildQuery(request)}`,
      // keepUnusedDataFor: 0,
      providesTags: (res) => [
        MESSAGE_TAG,
        ...(res?.data || []).map((m) => `${MESSAGE_TAG}-${m.id}`),
      ],
    }),
    sendMessage: builder.mutation<
      SakariAPIResponse<SendMessageResponse>,
      SendMessageRequest
    >({
      query: (req) => ({
        url: `accounts/${getAccountId()}/messages`,
        method: 'POST',
        body: req,
      }),
      invalidatesTags: [
        {
          type: CONVERSATION_TAG,
          id: 'LIST',
        },
      ],
      onQueryStarted: async (
        request: SendMessageRequest,
        { dispatch, queryFulfilled, getState },
      ) => {
        // logger.info('onQueryStarted', request);

        const newMsg = {
          id: 'new',
          outgoing: true,
          message: request.template,
          status: request?.sendAt ? 'scheduled' : 'new',
          sendAt: request?.sendAt,
          created: {
            at: new Date().toISOString(),
            by: { name: 'Chris' },
          },
        };

        if (request.conversations?.length) {
          // update an existing convo
          const key = {
            accountId: getAccountId() || '',
            request: {
              conversationId: request.conversations[0],
              markRead: 1,
              offset: 0,
              limit: LIMIT,
            },
          };

          const patchResult = dispatch(
            extendedApi.util.updateQueryData('getMessages', key, (draft) => {
              draft.data.push(newMsg);
            }),
          );

          try {
            const { data } = await queryFulfilled;
            patchResult.undo();
            if (data.data.messages.length && data.success) {
              // logger.info('undoing optimistic chqange');
              dispatch(
                extendedApi.util.updateQueryData(
                  'getMessages',
                  key,
                  (draft) => {
                    // logger.info('applying pessimistic chqange', draft.data);
                    // draft.data.pop(); // = draft.data.filter(m => m.id !== 'new');
                    const newMsg = data.data.messages[0];
                    draft.data.push({
                      ...newMsg,
                      status: newMsg.sendAt ? 'scheduled' : 'new',
                    });
                  },
                ),
              );

              updateConversationWithMsg(
                dispatch,
                getState,
                request.conversations[0],
                data.data.messages[0],
              );
            }
          } catch (err) {
            logger.info('err1', err);
            patchResult.undo();

            /**
             * Alternatively, on failure you can invalidate the corresponding cache tags
             * to trigger a re-fetch:
             * dispatch(api.util.invalidateTags(['Post']))
             */
          }
        } else {
          try {
            const { data } = await queryFulfilled;
            const sentMsg = data.data.messages[0];
            logger.info('new convo query fulfilled', data);

            const newConvo = sentMsg.conversation;

            selectInvalidatedBy(getState(), [MESSAGE_TAG]).forEach(
              ({ originalArgs }) => {
                if (originalArgs.request.conversationId === newConvo?.id) {
                  dispatch(
                    extendedApi.util.updateQueryData(
                      'getMessages',
                      originalArgs,
                      (draft) => {
                        draft.data[0] = {
                          ...sentMsg,
                          status: newMsg.sendAt ? 'scheduled' : 'new',
                        };
                      },
                    ),
                  );
                }
              },
            );
          } catch (err) {
            logger.info('err2', err);
          }
        }
      },
    }),
    cancelScheduledMessage: builder.mutation<
      SakariAPIResponse<Message[]>,
      string
    >({
      query: (messageId) => ({
        url: `accounts/${getAccountId()}/messages/${messageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, err, args) => [
        MESSAGE_TAG,
        `${MESSAGE_TAG}-${args}`,
      ],
      onQueryStarted: async (messageId, { dispatch, getState }) => {
        // find all getMessages queries that are invalidated by this mutation
        selectInvalidatedBy(getState(), [
          `${MESSAGE_TAG}-${messageId}`,
        ]).forEach(({ endpointName, originalArgs }) => {
          if (endpointName === 'getMessages') {
            dispatch(
              extendedApi.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  const msgIdx = draft.data.findIndex(
                    (m: Message) => m.id === messageId,
                  );
                  if (msgIdx > -1) {
                    draft.data[msgIdx] = {
                      ...draft.data[msgIdx],
                      status: 'error',
                      error: {
                        code: 'MSG-060',
                        description: 'Scheduled message has been cancelled.',
                      },
                      updated: {
                        ...draft.data[msgIdx].updated,
                        at: new Date().toISOString(),
                      },
                    };

                    updateConversationWithMsg(
                      dispatch,
                      getState,
                      draft.data?.[msgIdx]?.conversation?.id ?? '',
                      draft.data[msgIdx],
                    );
                  }
                },
              ),
            );
          }
        });
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetMessagesQuery,
  useSendMessageMutation,
  useCancelScheduledMessageMutation,
  util: { updateQueryData, selectInvalidatedBy },
} = extendedApi;
