import React from 'react';
import { Stack, Button, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

interface AddBranchButtonProps {
  label?: string;
  onAdd: () => void;
}

function AddBranchButton({ label, onAdd }: AddBranchButtonProps) {
  const { t } = useTranslation('workflows');
  return (
    <Stack>
      <Button
        size="xs"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onAdd();
        }}
        sx={{
          marginTop: '8px',
          padding: 0,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={1} padding="0.3rem">
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: '0.75rem' }} />
          <Typography
            level="body-sm"
            sx={{ color: 'var(--joy-palette-common-white)' }}
          >
            {label ? label : t('workflow.newBranch')}
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
}

export default AddBranchButton;
