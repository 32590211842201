import React from 'react';
import { useTranslation } from 'react-i18next';
import { SakariAPIResponse } from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface DeleteDomainDialogProps {
  open: boolean;
  onClose: () => void;
  domain: string;
  onDelete: (domain: string) => Promise<SakariAPIResponse>;
}

function DeleteDomainDialog({
  open,
  onClose,
  domain,
  onDelete,
}: DeleteDomainDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    logger.info('deleting domain', domain);

    try {
      const deleted = await onDelete(domain);
      logger.info('deletedRes', deleted);
      dispatch(
        showToast({
          severity: 'info',
          message: t('customDomain.delete.success'),
        }),
      );
    } catch (err) {
      logger.error('Unable to delete domain', err);
      dispatch(
        showToast({
          severity: 'error',
          message:
            (err as any).data?.error?.message || t('customDomain.delete.error'),
        }),
      );
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={(result) => {
        onClose();
        if (result) {
          handleDelete();
        }
      }}
      header={t('customDomain.delete.title')}
      type="danger"
      content={t('cannotUndoAction')}
      confirmLabel="yesDelete"
    />
  );
}

export default DeleteDomainDialog;
