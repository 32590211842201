import { Stack, Typography } from '@mui/joy';
import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { TemplateType, Template } from '@sakari-io/sakari-typings';
import {
  useSaveTemplateMutation,
  useUpdateTemplateMutation,
} from '../../../../../../api/templates';

import FieldWrapper from '../../../../../../utils/FieldWrapper';

import { useAppDispatch } from '../../../../../../redux';
import { showToast } from '../../../../../../redux/reducers/toast';
import SimpleDialog from '../../../../../molecules/SimpleDialog';
import TextField from '../../../../../atoms/inputs/TextField';
import Helper from '../../../../../../utils/helper';
import { AccountContext } from '../../../../../../contexts/account.context';
import Composer from '../..';

interface SaveTemplateProps {
  value?: Template;
  onChange: (value: Template) => void;
  open: boolean;
  onClose: () => void;
}

function SaveTemplate({ value, onChange, open, onClose }: SaveTemplateProps) {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const dispatch = useAppDispatch();

  const [createTemplate, { isLoading: isCreating }] = useSaveTemplateMutation();
  const [updateTemplate, { isLoading: isUpdating }] =
    useUpdateTemplateMutation();

  const schema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required(t('validation.required')),
    template: Yup.object().required(t('validation.required')),
  });

  const validate = makeValidate(schema);

  const formSave = (values: any) => {
    const template = {
      id: values.id ?? '',
      name: values.name,
      template: values.template.message.message,
      media: values.template.files,
      type: TemplateType.SMS,
    };

    if (values?.id) {
      updateTemplate(template)
        .unwrap()
        .then((res) => {
          onChange(res?.data);
          dispatch(
            showToast({
              severity: 'success',
              message: t('template.updatedTemplateMessage'),
            }),
          );
          onClose();
        })
        .catch((err) => {
          dispatch(
            showToast({
              severity: 'error',
              message: err.data?.error?.message || t('template.errorMessage'),
            }),
          );
        });
    } else {
      createTemplate(template)
        .unwrap()
        .then((res) => {
          onChange(res?.data);
          dispatch(
            showToast({
              severity: 'success',
              message: t('template.createdTemplateMessage'),
            }),
          );
          onClose();
        })
        .catch((err) => {
          onChange({ ...template, id: '0' });
          dispatch(
            showToast({
              severity: 'error',
              message: err.data?.error?.message || t('template.errorMessage'),
            }),
          );
        });
    }
  };

  return (
    <Form
      initialValues={{
        ...value,
        template: {
          message: value?.template,
          files: (value as any)?.media,
        },
      }}
      onSubmit={formSave}
      validate={validate}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, hasValidationErrors, values, pristine }) => {
        return (
          <SimpleDialog
            title={
              value?.id
                ? t('template.editTemplate')
                : t('template.createTemplate')
            }
            open={open}
            onClose={(res) => {
              if (res) {
                handleSubmit();
              } else {
                onClose();
              }
            }}
            confirmText={value?.id ? 'action.save' : 'action.create'}
            confirmLoading={isCreating || isUpdating}
            confirmDisabled={hasValidationErrors || pristine}
            content={
              <>
                <FieldWrapper
                  component={TextField}
                  name="name"
                  label="Template Name"
                  placeholder="New Template"
                />
                <FieldWrapper
                  component={Composer}
                  account={account}
                  name="template.message"
                  label="Message"
                  size="small"
                  hideTemplates
                />
                <Stack>
                  {values.updated && (
                    <Typography level="body-xs">
                      <b>{t('lastUpdated')} </b>
                      {Helper.getLocalizedDateTime((values.updated as any).at, {
                        dateStyle: 'medium',
                        timeStyle: 'medium',
                      })}
                      {(values.updated as any).by && (
                        <>
                          <b> {t('by')} </b>
                          {(values.updated as any).by.name}
                        </>
                      )}{' '}
                    </Typography>
                  )}
                  {values.created && (
                    <Typography level="body-xs">
                      <b>{t('createdAt')} </b>
                      {Helper.getLocalizedDateTime((values.created as any).at, {
                        dateStyle: 'medium',
                        timeStyle: 'medium',
                      })}
                      {(values.created as any).by && (
                        <>
                          <b> {t('by')} </b>
                          {(values.created as any).by.name}
                        </>
                      )}
                    </Typography>
                  )}
                </Stack>
              </>
            }
          />
        );
      }}
    </Form>
  );
}

export default SaveTemplate;
