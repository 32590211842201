import React, { useEffect, useState } from 'react';
import Switch from '@mui/joy/Switch';
import { Tooltip } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import { Account, GroupNotification } from '@sakari-io/sakari-typings';
import _ from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFormState } from 'react-final-form';
import { useGetHubSpotInboxesQuery } from '../../../../api';
import Setting from '../../../templates/forms/SettingForm';
import HubSpotAssignGroups from '../HubSpotAssignGroups';

interface HubSpotInboxProps {
  account: Account;
  value: GroupNotification[];
  onChange: (
    value:
      | GroupNotification
      | GroupNotification[]
      | [{ group: string; destination: { id: string; name: string } }],
  ) => any;
}

function HubSpotInbox({ account, value, onChange }: HubSpotInboxProps) {
  const { t } = useTranslation();
  const { values } = useFormState();
  const [enabled, setEnabled] = useState(!!value.length);
  const disabled = !values.hasInboxScope;
  const {
    error: fetchError,
    isLoading,
    isFetching,
  } = useGetHubSpotInboxesQuery(enabled && !disabled ? null : skipToken);
  const [toolTipText, setToolTipText] = useState('');

  const enableHubSpotInbox = () => {
    if (enabled) {
      onChange(value);
      setEnabled(false);
    } else {
      onChange([{ group: '', destination: { id: '', name: '' } }]);
      setEnabled(true);
    }
  };

  useEffect(() => {
    if (value) {
      if (value && value.length > 0) {
        if (
          value[0].group === undefined &&
          value[0].destination === undefined
        ) {
          setEnabled(false);
        } else if (
          value[0].group !== undefined &&
          value[0].destination !== undefined
        ) {
          setEnabled(true);
        } else {
          setEnabled(value.length !== 0);
        }
      }
    }
    if (!isLoading && !isFetching && fetchError) {
      if ((fetchError as any).data?.error?.code === 'INT-023') {
        return setToolTipText(t('integrations.hubspot.reconnectMessage.label'));
      }
    }
    return undefined;
  }, [value, toolTipText, setToolTipText, isLoading, isFetching]);

  useEffect(() => {
    const sorted = _.sortBy(value, ['group.name']);
    onChange(sorted);
  }, []);

  return (
    <>
      <Setting label="Enabled" spacer="0px 30px 0px 0px">
        <Tooltip title={toolTipText}>
          <Switch
            size="md"
            checked={enabled}
            onClick={enableHubSpotInbox}
            disabled={disabled}
          />
        </Tooltip>
      </Setting>

      {enabled && !disabled ? (
        <Setting
          label={t('integrations.hubspot.inbox.configuration.label')}
          description={t(
            'integrations.hubspot.inbox.configuration.description',
          )}
          spacer="0px 30px 0px 0px"
        >
          <HubSpotAssignGroups
            account={account}
            value={value}
            onChange={onChange}
          />
        </Setting>
      ) : null}
    </>
  );
}

export default HubSpotInbox;
