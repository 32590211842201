import { Modal, ModalDialog, Typography, Stack } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const unsupportedScreenWidth = 800;

interface UnsupportedViewModalProps {
  open?: boolean;
}
function UnsupportedViewModal({ open }: UnsupportedViewModalProps) {
  const { t } = useTranslation();

  const [isUnsupportedView, setIsUnsupportedView] = useState(open || false);

  useEffect(() => {
    const handleResize = () => {
      setIsUnsupportedView(window.innerWidth < unsupportedScreenWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Modal open={isUnsupportedView}>
      <ModalDialog
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          p: 4,
          width: {
            xs: '80%',
            sm: '60%',
            md: '40%',
          },
        }}
      >
        <Stack gap={2}>
          <Typography level="h2" textColor="text.secondary">
            {t('unsupportedView.title')}
          </Typography>
          <Typography level="title-lg" textColor="text.tertiary">
            {t('unsupportedView.subtitle')}
          </Typography>
          <Typography level="body-md" textColor="text.tertiary">
            {t('unsupportedView.description')}
          </Typography>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default UnsupportedViewModal;
