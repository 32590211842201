import { ListDivider } from '@mui/joy';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Account, SakariAPIResponse } from '@sakari-io/sakari-typings';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import MenuItem from '../../../../atoms/MenuItem';
import SearchableMenu, {
  SearchableMenuProps,
} from '../../../../molecules/Menu/SearchableMenu';
import { AccountContext } from '../../../../../contexts/account.context';
import clearCookies from '../../../../../utils/authentication';

interface SwitchAccountMenuProps<T extends Account>
  extends Omit<SearchableMenuProps<T>, 'onSelect' | 'onQueryChanged'> {
  getAccountsQuery: UseQuery<any>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SwitchAccountMenu<T>({
  getAccountsQuery,
  variant = 'outlined',
  ...rest
}: SwitchAccountMenuProps<Account>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changeAccount, account } = useContext(AccountContext);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
  const { data, isFetching } = getAccountsQuery({
    limit: 20,
    name: debouncedSearchTerm,
  });

  const items = useMemo(
    () =>
      (data as SakariAPIResponse<Account[]>)?.data.filter(
        (acc) => acc.id !== account?.id,
      ) || [],
    [data],
  );

  const handleSelectAccount = (account: Account) => {
    changeAccount(account?.id);
    navigate('/');
  };

  const handleAddAccount = () => {
    clearCookies();
    navigate('/register');
  };

  return (
    <SearchableMenu
      onQueryChanged={setSearchTerm}
      items={items}
      onSelect={handleSelectAccount}
      getLabel={(item) => item.name}
      isLoading={isFetching}
      loadingText={t('loadingItems', {
        items: t('items.account_other'),
      })}
      noOptionsText={t('sharedAccounts.noneFound', {
        item: t('items.account_other'),
      })}
      sx={{
        width: 250,
        '#footer': {
          p: 0,
        },
        overflow: 'hidden',
      }}
      footer={
        <>
          <ListDivider />
          <MenuItem
            id="add-account"
            label={t('addItems', {
              items: _.capitalize(t('items.account_one')),
            })}
            onClick={handleAddAccount}
            sx={{
              p: 1.5,
            }}
          />
        </>
      }
      {...{ ...rest, variant }}
    />
  );
}
export default SwitchAccountMenu;
