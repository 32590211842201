import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Conversation,
  CreateConversation,
  Group,
} from '@sakari-io/sakari-typings';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Stack, Sheet, Box } from '@mui/joy';
import { Loader } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Conversations from '../../ui/organisms/drawers/ConversationsDrawer';
import Messages from './Messages';
import createConversation from '../../utils/conversation';
import { AccountContext } from '../../contexts/account.context';
import { Drawer, PageHeader } from '../../ui';
import Container from '../../ui/templates/Container';
import GroupSelect from '../../ui/organisms/forms/GroupSelect';
import ConversationHeader from '../../ui/organisms/headers/ConversationHeader';
import { UserRole } from '../../constants';
import ContactDetails from '../../ui/organisms/drawers/ContactDetails';
import EmptyResult from '../../ui/molecules/EmptyResult';
import {
  useGetContactQuery,
  useGetGroupQuery,
  useGetGroupsQuery,
  useGetConversationsQuery,
} from '../../api';
import useLocalStorage from '../../hooks/useLocalStorage';
import NewMessageButton from './NewMessageButton';

enum DrawerType {
  CONTACT_DETAILS = 'contact-details',
  CONVERSATION_LIST = 'conversation-list',
  EMPTY = 'none',
}

function Inbox() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFocusInComposerBox, setIsFocusInComposerBox] =
    useState<boolean>(false);
  const params = useParams();
  const { profile, accountId, account, isFetching, userRole } =
    useContext(AccountContext);

  const isReadOnly = userRole === UserRole.READ_ONLY;

  const { data: allGroupsData, isFetching: isFetchingAllGroups } =
    useGetGroupsQuery(
      accountId
        ? {
            accountId,
            request: {
              // admin: 1,
            },
          }
        : skipToken,
    );

  const [selectedGroupId, setSelectedGroupId] = useLocalStorage(
    `${accountId || ''}-selectedGroupId`,
    '',
  );

  const {
    data,
    isLoading,
    isFetching: isFetchingGroup,
  } = useGetGroupQuery(
    selectedGroupId !== 'all'
      ? {
          accountId: accountId || '',
          request: selectedGroupId,
        }
      : skipToken,
  );

  const group: Group | undefined = useMemo(
    () =>
      data?.data.id === selectedGroupId
        ? data?.data
        : { id: 'all', name: 'All Groups' },
    [selectedGroupId, data],
  );
  const groups: Group[] = useMemo(
    () => allGroupsData?.data || [],
    [allGroupsData],
  );

  const [selectedConversation, setSelectedConversation] = useState<
    Conversation | CreateConversation | undefined
  >(location?.state?.conversation);

  const { data: existingConvoData, isFetching: isFetchingExistingConvo } =
    useGetConversationsQuery(
      selectedConversation?.id === 'draft' &&
        selectedConversation?.contact?.id &&
        account?.id &&
        selectedGroupId
        ? {
            accountId: account?.id || '',
            request: {
              group: selectedConversation.group?.id ?? selectedGroupId,
              contactId: selectedConversation?.contact?.id,
            },
          }
        : skipToken,
    );

  useEffect(() => {
    if (!isFetchingExistingConvo) {
      const existingConvo = existingConvoData?.data[0];
      if (existingConvo?.contact?.id === selectedConversation?.contact?.id) {
        setSelectedConversation(existingConvo);
        if (selectedConversation?.id !== 'draft') {
          setConversations((prev) => prev.filter((c) => c.id !== 'draft'));
        }
      }
    }
  }, [isFetchingExistingConvo, existingConvoData]);

  const { data: contactData, isFetching: isFetchingContact } =
    useGetContactQuery(
      selectedConversation?.contact?.id && account?.id
        ? {
            accountId: account.id,
            request: selectedConversation?.contact?.id,
          }
        : skipToken,
    );

  useEffect(() => {
    if (!isFetchingContact) {
      setSelectedConversation((prev) => {
        if (prev && prev.contact?.id === contactData?.data?.id) {
          return {
            ...prev,
            contact: contactData?.data,
          };
        }
        return prev;
      });
    }
  }, [isFetchingContact, contactData]);

  const noGroupsExist: boolean = groups ? groups.length <= 0 : true;

  useEffect(() => {
    if (params.selectedGroupId) {
      setSelectedGroupId(params.selectedGroupId);
    }
  }, []);

  useEffect(() => {
    if (!selectedGroupId && groups?.length > 0) {
      setSelectedGroupId(groups[0].id);
    }
  }, [groups]);

  const [drawers, setDrawers] = useState<DrawerType[]>([
    DrawerType.CONVERSATION_LIST,
  ]);

  const handleDrawerToggle = (drawer: DrawerType) => {
    if (drawer === DrawerType.EMPTY) {
      setDrawers([]);
    } else if (drawers.includes(drawer)) {
      setDrawers(drawers.filter((d) => d !== drawer));
    } else {
      setDrawers([...drawers, drawer]);
    }
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedConversation(undefined);
    setSelectedGroupId(group.id);
  };

  const handleNewMessage = (group?: Group) => {
    const convo = createConversation(undefined, undefined, 'sms');
    convo.id = 'draft';
    if (group) {
      convo.group = group;
    }
    setSelectedConversation(convo);
  };

  const getGroupButtonText = (userRole: UserRole, noGroupsExist: boolean) => {
    if ([UserRole.ADMIN].includes(userRole)) {
      return noGroupsExist
        ? t('groups.add')
        : t('goToItem', {
            item: t('items.group_other'),
          });
    }
    return '';
  };

  if (isLoading || isFetching || isFetchingAllGroups) {
    return <Loader size={200} hideBackdrop />;
  }

  return (
    <>
      <PageHeader
        id="inbox-header"
        title={t('inbox')}
        optionalContent={
          accountId &&
          groups &&
          groups?.length > 0 && (
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <GroupSelect
                accountId={accountId}
                addUnassignedGroup
                addAllGroups
                value={group}
                onChange={handleGroupSelect}
                disabled={isFetchingGroup}
              />
              <Stack
                sx={{
                  flexDirection: 'row',
                  gap: 1,
                  minWidth: 'fit-content',
                  height: 'fit-content',
                }}
              >
                <NewMessageButton
                  accountId={accountId}
                  group={group}
                  disabled={!group || isReadOnly}
                  onClick={handleNewMessage}
                />
              </Stack>
            </Stack>
          )
        }
      />

      {/* Select Channel Filter */}

      {/* Main Inbox */}
      {groups && groups.length > 0 && group ? (
        <>
          <Container>
            <Sheet
              id="inbox-main"
              variant="outlined"
              sx={{
                flex: 1,
                borderRadius: 'var(--joy-radius-sm)',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
              }}
            >
              <Drawer
                id="conversations-drawer"
                isOpen={drawers.includes(DrawerType.CONVERSATION_LIST)}
                persistent
                position="left"
                sx={{
                  overflow: 'hidden',
                  ...(drawers.includes(DrawerType.CONVERSATION_LIST) && {
                    minWidth: 250,
                    maxWidth: 350,
                    flex: 1,
                  }),
                }}
              >
                <Conversations
                  conversations={conversations}
                  setConversations={setConversations}
                  selected={selectedConversation}
                  onStartConversation={() => {
                    setSelectedConversation(
                      createConversation(undefined, undefined, 'sms'),
                    );
                  }}
                  group={group}
                  onSelected={(conversation) => {
                    if (conversation) {
                      setSelectedConversation(conversation);
                    }
                  }}
                />
              </Drawer>
              <Stack
                sx={{
                  flex: 2,
                  bgcolor: 'background.level1',
                  ...(drawers.includes(DrawerType.CONVERSATION_LIST) && {
                    borderLeft: '1px solid ',
                    borderColor: 'divider',
                  }),
                }}
              >
                {account && profile && (
                  <ConversationHeader
                    account={account}
                    isReadOnly={isReadOnly}
                    group={
                      group.id === 'all'
                        ? (selectedConversation?.group as Group)
                        : group
                    }
                    conversation={selectedConversation}
                    setConversation={(conversation) => {
                      setSelectedConversation(conversation);

                      if (conversation) {
                        setConversations(
                          (conversations || []).map((convo: Conversation) => {
                            if (convo.id === conversation.id) {
                              return conversation;
                            }

                            return convo;
                          }),
                        );
                      }
                    }}
                    drawers={drawers}
                    onToggleDrawer={(drawer) =>
                      handleDrawerToggle(drawer as DrawerType)
                    }
                  />
                )}
                {account && group && !!selectedConversation ? (
                  <Messages
                    account={account}
                    disabledComposer={isReadOnly}
                    group={group}
                    conversation={selectedConversation}
                    onConversationChanged={(conversation) => {
                      if (conversation) {
                        setSelectedConversation(conversation);
                      }
                    }}
                    onFocusInComposerBox={setIsFocusInComposerBox}
                  />
                ) : null}
              </Stack>
            </Sheet>
          </Container>
          {account && selectedConversation?.contact ? (
            <ContactDetails
              account={account}
              contactId={selectedConversation?.contact.id}
              drawerOpen={drawers.includes(DrawerType.CONTACT_DETAILS)}
              setDrawerOpen={() =>
                handleDrawerToggle(DrawerType.CONTACT_DETAILS)
              }
            />
          ) : null}
        </>
      ) : (
        <Container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* No Access */}

          <Box width={350}>
            <EmptyResult
              size="sm"
              heading={t('noGroups')}
              text={
                userRole !== UserRole.ADMIN
                  ? t('sakari.errors.403.contactAdmin')
                  : t('sakari.errors.403.noGroups')
              }
              item="inbox"
              buttonText={getGroupButtonText(userRole, noGroupsExist)}
              onButtonClicked={() =>
                noGroupsExist ? navigate('/groups/add') : navigate('/groups')
              }
            />
          </Box>
        </Container>
      )}
    </>
  );
}

export default Inbox;
