import React from 'react';
import { Stack, Typography, radioClasses } from '@mui/joy';
import { useFormState } from 'react-final-form';
import { PhoneNumber } from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@sakari-io/sakari-components';
import Radios, { RadioData } from '../../../../../ui/molecules/Radios';
import FieldWrapper from '../../../../../utils/FieldWrapper';
import Setting from '../../../../../ui/templates/forms/SettingForm';
import PhoneInput from '../../../../../ui/atoms/inputs/PhoneInput';
import { useGetAccountQuery } from '../../../../../api';
import { getAccountId } from '../../../../../api/common';
import { VOICE_ACCOUNTS } from '../../../../NavBarWrapper';

interface ChannelItemVoiceProps {
  sender?: PhoneNumber;
}

function ChannelItemVoice({ sender }: ChannelItemVoiceProps) {
  const formState = useFormState();
  const features = formState.values?.channels?.voice?.features ?? [];
  const action = formState.values?.channels?.voice?.incoming?.action;
  const accountId = getAccountId();
  const { t } = useTranslation();

  const { data } = useGetAccountQuery(accountId ?? skipToken);

  const isTrial: boolean = data?.data?.plan.name === 'Trial';

  const items = [
    {
      label: t('senders.edit.voice.incoming.options.sakari.label'),
      description: t('senders.edit.voice.incoming.options.sakari.description'),
      tooltip: t('senders.edit.voice.incoming.options.sakari.label'),
      value: 'app',
      disabled: !accountId || !VOICE_ACCOUNTS.includes(accountId), // true, //! features.includes('app'),
    },
    {
      label: t('senders.edit.voice.incoming.options.forward.label'),
      description: (
        <>
          {t('senders.edit.voice.incoming.options.forward.description')}
          {action === 'forward' ? (
            <FieldWrapper
              component={PhoneInput}
              name="channels.voice.incoming.forwardTo"
              size="sm"
              helperText={`* ${t(
                'senders.edit.voice.incoming.options.forward.helperText',
              )}`}
            />
          ) : null}
        </>
      ),
      sx: {
        ...(action === 'forward' && {
          [`& .${radioClasses.radio}`]: {
            position: 'relative',
          },
          '& .react-tel-input': {
            mt: 1,
            maxWidth: 250,
          },
        }),
      },
      value: 'forward',
      disabled: isTrial || !features.includes('forward'),
      tooltip: isTrial
        ? t('businessInfo.notAvailableOnFreeTrial')
        : t('senders.edit.voice.incoming.options.forward.label'),
    },
    {
      label: t('senders.edit.voice.incoming.options.voicemail.label'),
      description: t(
        'senders.edit.voice.incoming.options.voicemail.description',
      ),
      value: 'voicemail',
      disabled: isTrial || !features.includes('voicemail'),
      tooltip: isTrial
        ? t('businessInfo.notAvailableOnFreeTrial')
        : t('senders.edit.voice.incoming.options.voicemail.label'),
    },
    {
      label: 'Do nothing',
      value: 'none',
      disabled: false,
    },
  ].filter(({ value }) => {
    if (!sender?.channels?.voice?.features?.length) {
      return true;
    }

    if (value === 'none') {
      return true;
    }

    return sender?.channels?.voice?.features.includes(value);
  });

  return (
    <Stack spacing={2} padding={2}>
      <Setting
        label={t('senders.edit.voice.incoming.title')}
        description={t('senders.edit.voice.incoming.description')}
      >
        <FieldWrapper
          component={Radios}
          name="channels.voice.incoming.action"
          size="md"
          renderLabel={(item: RadioData<string>) => (
            <Stack>
              <Typography level="body-sm" fontWeight={600}>
                {item.label}
              </Typography>
              {item.description ? (
                <Typography level="body-xs">{item.description}</Typography>
              ) : null}
            </Stack>
          )}
          items={items}
        />
      </Setting>

      {['voicemail', 'app'].includes(action) ? (
        <Setting
          label={t('senders.edit.voice.voicemail.title')}
          description={t('senders.edit.voice.voicemail.description')}
        >
          <FieldWrapper
            component={Textarea}
            name="channels.voice.incoming.message"
            placeholder={t('senders.edit.voice.voicemail.placeholder')}
            fullWidth
            minRows={4}
          />
        </Setting>
      ) : null}
    </Stack>
  );
}

export default ChannelItemVoice;
