import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { IDable } from '@sakari-io/sakari-typings';
import { useFormState } from 'react-final-form';
import SendDuringOptions from './SendDuringOptions';
import InputControl from '../../../ui/molecules/InputControl';

interface SendDuringProps {
  value: IDable;
  onChange: (value: IDable) => any;
  error?: boolean;
  helperText?: string;
  onBlur?: () => any;
}

function SendDuring({
  value,
  onChange,
  error,
  helperText,
  onBlur,
}: SendDuringProps) {
  const { t } = useTranslation();
  const { values } = useFormState();

  useEffect(() => {
    if (value.id === 'custom' && onBlur) {
      onBlur();
    }
  }, [values.customHours]);

  return (
    <InputControl
      error={error}
      helperText={helperText}
      sx={{ padding: '40px' }}
    >
      <Stack spacing={2}>
        <Typography fontWeight="bold">
          {t('groupsNav.autoResponders.customMessage.sendDuring')}
        </Typography>
        <Typography level="body-md">
          {t('groupsNav.autoResponders.customMessage.setOfficeHours')}
        </Typography>

        <SendDuringOptions
          value={value}
          onChange={(e) => {
            onChange(e);
            if (onBlur) {
              onBlur();
            }
          }}
        />
      </Stack>
    </InputControl>
  );
}

export default SendDuring;
