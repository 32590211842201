import { Link, Modal, ModalDialog, Stack, Typography, Button } from '@mui/joy';
import { InternationalNumber } from '@sakari-io/sakari-typings';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { TwoFACodeInput, logger } from '@sakari-io/sakari-components';
import {
  useRequestVerificationCodeMutation,
  useValidateVerificationCodeMutation,
} from '../../../../../../api';
import FieldWrapper from '../../../../../../utils/FieldWrapper';
import Helper from '../../../../../../utils/helper';

interface VerifyDialogProps {
  open: boolean;
  onClose: (result: any) => any;
  number: InternationalNumber;
  channel: string;
}

// TODO consolidate
function VerifyDialog({ open, onClose, number, channel }: VerifyDialogProps) {
  const { t } = useTranslation();
  const { values } = useFormState();

  const [verificationId, setVerificationId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [request, { isLoading: isRequesting }] =
    useRequestVerificationCodeMutation();
  const [validate, { isLoading: isValidating }] =
    useValidateVerificationCodeMutation();

  useEffect(() => {
    requestCode();
  }, []);

  const requestCode = () => {
    request({
      number: number.number,
      channel,
    })
      .unwrap()
      .then((data) => {
        setVerificationId(data.data);
      })
      .catch((err) => {
        logger.info('err requesting verification', err);
        setErrorMessage(
          t('addSenders.phonenumber.byo.verify.error.requestCode'),
        );
      });
  };

  const validateCode = () => {
    validate({
      id: verificationId,
      code: values?.code,
      channel,
    })
      .unwrap()
      .then((data) => {
        if (data.success) {
          onClose(data.data);
        } else {
          setErrorMessage(
            t('addSenders.phonenumber.byo.verify.error.validate'),
          );
        }
      })
      .catch((err) => {
        logger.info('validation error', err);
        onClose(undefined);
      });
  };

  return (
    <Modal open={open} onClose={() => onClose(undefined)}>
      <ModalDialog
        id="verify"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 4,
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '434px',
        }}
      >
        <Typography level="title-lg">{t('checkPhone')}</Typography>
        <Typography>{Helper.formatPhoneNumber(number)}</Typography>
        <Typography level="body-md">{t('phoneCall')}.</Typography>
        <FieldWrapper
          component={TwoFACodeInput}
          fullWidth
          id="code"
          name="code"
          splitAt={3}
          numBoxes={6}
        />
        {errorMessage && (
          <Typography level="body-md" color="danger">
            {errorMessage}
          </Typography>
        )}
        <Button
          fullWidth
          disabled={values?.code?.length !== 6 || !verificationId}
          color="primary"
          loading={isRequesting || isValidating}
          onClick={() => validateCode()}
        >
          {t('verify')}
        </Button>
        <Stack sx={{ flexDirection: 'row', gap: 1 }}>
          <Typography level="body-sm">
            {t('addSenders.phonenumber.byo.verify.didntReceiveCode')}
          </Typography>
          <Link level="body-sm" color="primary" onClick={() => requestCode()}>
            {t('retry')}
          </Link>
        </Stack>
        <Stack alignItems="center">
          <Typography level="body-sm">
            {t('addSenders.phonenumber.byo.verify.didntReceiveCode2')}
          </Typography>
          <Link
            level="body-sm"
            color="primary"
            onClick={() => {
              onClose(undefined);
            }}
          >
            {t('withoutVerification')}
          </Link>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default VerifyDialog;
