import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button, IconButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactUpdate } from '@sakari-io/sakari-typings';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';

interface ValueProps<T> {
  value: T;
  onChange: (v: T) => any;
  fullWidth?: boolean;
  disabled?: boolean;
}

interface MultipleWrapperProps<T> {
  component: React.ComponentType<ValueProps<T>>;
  value: T[];
  onChange: (v: (T | ContactUpdate)[]) => any;
  customAddButton?: React.ReactNode;
  addDeleteButton?: boolean;
  sx?: any;
  createNewItem?: (e: SyntheticEvent) => T;
  disabled?: boolean;
  keyProp?: string;
}

function MultipleWrapper<T>({
  component: Component,
  value,
  onChange,
  customAddButton,
  addDeleteButton,
  createNewItem,
  sx,
  disabled,
  keyProp,
  ...rest
}: MultipleWrapperProps<T>): React.ReactElement<MultipleWrapperProps<T>> {
  const { t } = useTranslation('workflows');

  const getCustomButton = () => {
    if (React.isValidElement(customAddButton)) {
      return React.cloneElement(customAddButton as React.ReactElement, {
        onChange: (e: any) => {
          const newItem = createNewItem
            ? createNewItem(e)
            : { property: { name: e.name, label: e.label }, value: '' };
          onChange([...value, newItem]);
        },
      });
    }

    return null;
  };

  const customButton = getCustomButton();

  const handleDelete = (i: number) => {
    const newValue = [...value.slice(0, i), ...value.slice(i + 1)];
    onChange(newValue);
  };

  return (
    <>
      {(value || []).map((v, i) => (
        <Stack
          direction="row"
          sx={{ ...sx }}
          key={keyProp ? _.get(v, keyProp) : i}
        >
          <Component
            value={v}
            onChange={(newVal) => {
              const updatedValue = [
                ...value.slice(0, i),
                newVal,
                ...value.slice(i + 1),
              ];
              onChange(updatedValue);
            }}
            fullWidth
            {...rest}
          />
          {addDeleteButton && (
            <IconButton disabled={disabled} onClick={() => handleDelete(i)}>
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          )}
        </Stack>
      ))}
      {!customAddButton && (
        <Stack alignItems="center">
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => onChange([...value, {} as any])}
            disabled={disabled}
          >
            {t('and')}
          </Button>
        </Stack>
      )}
      {customAddButton && <Stack alignItems="center">{customButton}</Stack>}
    </>
  );
}

export default MultipleWrapper;
