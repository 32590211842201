import {
  Box,
  ColorPaletteProp,
  ModalDialog,
  Divider,
  Modal,
  ModalClose,
  ModalProps,
  Stack,
  Typography,
  ModalDialogProps,
} from '@mui/joy';
import { Button, Loader } from '@sakari-io/sakari-components';
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import ModalIcon from './ModalIcon';

const widthMap = {
  sm: '340px',
  md: '440px',
  lg: '640px',
};

export interface DialogProps extends Pick<ModalProps, 'open'> {
  title: string;
  color?: ColorPaletteProp;
  onClose: () => any;
  size?: ModalDialogProps['size'];
  primaryTitle: string;
  primaryAction: (values?: any) => any;
  cancelTitle?: string;
  cancelAction?: () => any;
  submitting?: boolean;
  disablePrimary?: boolean;
  children: React.ReactNode;
  icon?: IconDefinition;
  loadingText?: string;
  contentLoading?: boolean;
}

function Dialog({
  title,
  open,
  onClose,
  size = 'md',
  color = 'primary',
  primaryAction,
  primaryTitle,
  cancelAction,
  cancelTitle,
  submitting,
  disablePrimary,
  children,
  icon,
  loadingText,
  contentLoading,
}: DialogProps) {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => (onClose ? onClose() : undefined)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <ModalDialog
        size={size}
        sx={{
          overflow: 'hidden',
          width: `clamp(340px, 80vw, ${widthMap[size]})`,
        }}
      >
        <ModalClose size={size} variant="plain" disabled={submitting} />

        {contentLoading ? (
          <Box width="100%" minHeight="250px" position="relative" zIndex={-1}>
            <Loader size={100} label={loadingText} />
          </Box>
        ) : (
          <>
            <Stack width="100%">
              <ModalIcon color={color} size={size} icon={icon} />
              <Typography level="h4" pt={2.5}>
                {title}
              </Typography>
            </Stack>

            <Stack
              sx={{
                overflow: 'scrollY',
                flex: 1,
              }}
            >
              {children}
            </Stack>
          </>
        )}

        <Divider />

        <Stack direction="row" justifyContent="space-between">
          {cancelTitle && (
            <Button
              variant="secondary"
              onClick={cancelAction}
              disabled={submitting}
            >
              {cancelTitle}
            </Button>
          )}

          <Button
            variant="primary"
            onClick={primaryAction}
            loading={submitting}
            color={color}
            disabled={disablePrimary || contentLoading}
          >
            {primaryTitle}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default Dialog;
