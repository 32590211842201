import React from 'react';
import { WorkflowNodeProperty } from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import Select from '../../../../../../ui/atoms/inputs/Select';
import { ConfigFieldProps } from '../PropertyField';

interface BasicSelectProps extends ConfigFieldProps<string> {
  property: WorkflowNodeProperty;
  disabled?: boolean;
}

function BasicSelect({
  label,
  helperText,
  property,
  value,
  onChange,
  disabled,
}: BasicSelectProps) {
  return (
    <InputControl label={label} helperText={helperText} fullWidth>
      <Select
        fullWidth
        required={property?.mandatory}
        disabled={disabled}
        options={(property?.options || [])?.map((option) => {
          return {
            label: option.label || '',
            value: option.value,
          };
        })}
        value={value}
        onChange={onChange}
      />
    </InputControl>
  );
}

export default BasicSelect;
