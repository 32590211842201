import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Typography } from '@mui/joy';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

interface MetricProps {
  type: string;
  value: number;
  hideIcon?: boolean;
  percentage?: boolean;
}

function Metric({ type, value, hideIcon, percentage }: MetricProps) {
  const color =
    type === 'passed'
      ? 'var(--joy-palette-success-300)'
      : 'var(--joy-palette-danger-400)';

  const icon = (() => {
    switch (type) {
      case 'passed':
        return faCheck;
      case 'timeout':
        return '⏰';
      case 'error':
        return faXmark;
      default:
        return '';
    }
  })();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={0.5}
    >
      {!hideIcon &&
        (typeof icon === 'string' ? (
          <span style={{ color, marginRight: '4px' }}>{icon}</span>
        ) : (
          <FontAwesomeIcon
            icon={icon}
            style={{
              color,
              marginRight: '4px',
            }}
          />
        ))}

      <Typography level="body-sm" fontWeight={700} sx={{ color }}>
        {value} {percentage ? '%' : ''}
      </Typography>
    </Stack>
  );
}

export default Metric;
