import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/joy';
import {
  WorkflowDataReference,
  WorkflowProperty,
} from '@sakari-io/sakari-typings';
import { faTags } from '@fortawesome/pro-solid-svg-icons';
import PropertiesMenu from '../../../../../pages/Workflows/Edit/PropertiesMenu';

interface WorkflowAttributesListProps {
  onSelect: (val: WorkflowDataReference) => any;
  properties: WorkflowProperty[];
  disabled?: boolean;
}

function WorkflowAttributesList({
  onSelect,
  properties,
  disabled,
}: WorkflowAttributesListProps) {
  const [open, setOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  return (
    <>
      <Tooltip title="Search attributes" placement="top" size="sm">
        <IconButton
          ref={buttonRef}
          onClick={() => setOpen((prev) => !prev)}
          size="smRound"
          color="primary"
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faTags} />
        </IconButton>
      </Tooltip>

      <PropertiesMenu
        open={open}
        onClose={() => setOpen(false)}
        inputRef={buttonRef}
        fullWidth={false}
        onChange={onSelect}
        properties={properties}
      />
    </>
  );
}

export default WorkflowAttributesList;
