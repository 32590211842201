import { logger } from '@sakari-io/sakari-components';

const publicVapidKey =
  'BM8ilDdVtIqd6u7hrfbnk3ZeMIFSKms-oIeIxPWgAW0GfOCXZdXuiReUefMl94KJyWqOTkov2Qh2dkD7h7mLFlU';

const isPushNotificationSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

const registerServiceWorker =
  async (): Promise<ServiceWorkerRegistration | null> => {
    try {
      const swRegistration = await navigator.serviceWorker.register(
        '/service-worker.js',
        { scope: '/' },
      );
      logger.info('swRegistration', swRegistration);
      return swRegistration;
    } catch (e) {
      logger.error('Error when registering service worker ', e);
      return null;
    }
  };

// const unregisterServiceWorker = async (): Promise<any> => {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//       .getRegistration()
//       .then((registration) => {
//         registration?.unregister();
//       })
//       .catch((error) => {
//         logger.error(error.message);
//       });
//   }
// };

const getUserPermission: NotificationPermission =
  window.Notification?.permission;

const askUserPermission = (): Promise<NotificationPermission> => {
  return Notification?.requestPermission();
};

const createNotificationSubscription = async (): Promise<
  PushSubscription | undefined
> => {
  logger.info('Creating Notification Subscription');
  // wait for service worker installation to be ready
  return navigator.serviceWorker
    .getRegistration()
    .then(async (registration) => {
      logger.info('serviceWorker found', registration);
      // subscribe and return the subscription
      return registration?.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicVapidKey,
        })
        .then((sub: PushSubscription) => {
          logger.info('sub', sub.toJSON());
          return sub;
        });
    });
};

const getUserSubscription = async (): Promise<any> => {
  // wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then((serviceWorker: ServiceWorkerRegistration) => {
      return serviceWorker.pushManager.getSubscription();
    })
    .then((value) => {
      return value;
    });
};

export {
  isPushNotificationSupported,
  askUserPermission,
  getUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
};
