import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { Plan } from '@sakari-io/sakari-typings';
import { useBuyPhoneNumbersMutation } from '../../../../api';
import Helper from '../../../../utils/helper';
import { AppDialog } from '../../../../ui';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

interface ConfirmPurchaseDialogProps {
  open: boolean;
  setOpen: (open: boolean) => any;
  values: any;
  plan: Plan;
  price?: number;
}

function ConfirmPurchaseDialog({
  open,
  setOpen,
  values,
  plan,
  price,
}: ConfirmPurchaseDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [buyPhoneNumbers, { isLoading }] = useBuyPhoneNumbersMutation();

  const handleSubmit = async () => {
    const payload = Helper.formatAddNumbersRequest('sakari', values);
    //TODO use handleApiMutationResponse
    await buyPhoneNumbers(payload)
      .unwrap()
      .then((res) => {
        if (res?.data?.failures && res?.data?.failures.length > 0) {
          dispatch(
            showToast({
              severity: 'error',
              message: res?.data?.failures[0].reason || t('somethingWrong'),
            }),
          );
        } else
          navigate('/senders', {
            state: {
              addSender: {
                success: true,
                message: t('addSenders.phonenumber.buy.success'),
              },
            },
          });
      })
      .catch(() => {
        dispatch(
          showToast({
            severity: 'error',
            message: t('somethingWrong'),
          }),
        );
      });
  };

  const handleContent = () => {
    if (price !== 0) {
      return (
        <Stack>
          <Typography>
            {t('addSenders.phonenumber.buy.confirm.confirmPrice', {
              number: values?.numbers?.length,
              total: price,
              frequency:
                plan?.billingFrequency === 'monthly' ? 'month' : 'year',
            })}
          </Typography>

          {plan?.billingFrequency === 'monthly' && (
            <Typography>
              {t('addSenders.phonenumber.buy.confirm.proratedDescription')}
            </Typography>
          )}
        </Stack>
      );
    }
    return (
      <Stack>
        <Typography>
          {t('addSenders.phonenumber.buy.confirm.confirmFree')}
        </Typography>
      </Stack>
    );
  };

  return (
    <AppDialog
      autoClose={false}
      open={open}
      setOpen={setOpen}
      header={t('addSenders.phonenumber.buy.confirm.title')}
      content={handleContent()}
      confirmButtonProps={{
        children: t('confirm'),
        onClick: () => {
          setOpen(true);
          handleSubmit();
        },
        loading: isLoading,
      }}
      cancelButtonProps={{
        children: t('cancel'),
        onClick: () => setOpen(false),
        disabled: isLoading,
      }}
    />
  );
}

export default ConfirmPurchaseDialog;
