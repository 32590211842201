import {
  Lead,
  PaginationRequest,
  SakariAPIResponse,
  Referral,
  Partner,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';
import { getAccountId, buildGridQuery } from './common';
import { AccountIdWith } from '../types';
import { ACCOUNT_TAG, LEAD_TAG, PARTNER_TAG } from './tags';

type PartnerRequest = {
  user: string;
  paypalEmail: string;
  linkedInURL: string;
  country: string;
  customerLocation: string;
  numberEmployees: string;
  numberClients: string;
  joinDescription: string;
};

type UpdatePartnerRequest = {
  paypalEmail: string;
  contact: string;
};

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    createPartner: builder.mutation<
      SakariAPIResponse<Partner>,
      AccountIdWith<PartnerRequest>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/partners`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: [PARTNER_TAG, ACCOUNT_TAG],
    }),

    updatePartner: builder.mutation<
      SakariAPIResponse<Partner>,
      AccountIdWith<UpdatePartnerRequest>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/partners`,
        method: 'PUT',
        body: request,
      }),
      invalidatesTags: [PARTNER_TAG, ACCOUNT_TAG],
    }),

    getReferrals: builder.query<
      SakariAPIResponse<Referral[]>,
      AccountIdWith<PaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/partners/referrals?${buildGridQuery(request)}`,
      providesTags: [PARTNER_TAG],
    }),

    getLeads: builder.query<
      SakariAPIResponse<Lead[]>,
      AccountIdWith<PaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/leads?${buildGridQuery(request)}`,
      providesTags: [LEAD_TAG],
    }),

    getLead: builder.query<SakariAPIResponse<Lead>, AccountIdWith<string>>({
      query: ({ accountId, request: id }) =>
        `accounts/${accountId}/leads/${id}`,
      providesTags: [LEAD_TAG],
    }),

    createLead: builder.mutation<
      SakariAPIResponse<Lead>,
      Partial<Lead> & Pick<Lead, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/leads`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [LEAD_TAG],
    }),

    updateLead: builder.mutation<
      SakariAPIResponse<Lead>,
      Partial<Lead> & Pick<Lead, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/leads/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [LEAD_TAG],
    }),

    deleteLead: builder.mutation<SakariAPIResponse<Lead>, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/leads/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [LEAD_TAG],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLeadsQuery,
  useGetLeadQuery,
  useCreateLeadMutation,
  useDeleteLeadMutation,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useUpdateLeadMutation,
  useGetReferralsQuery,
} = extendedApi;
