import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Switch, Typography } from '@mui/joy';

interface HourSwitchProps {
  showAdd: boolean;
  setShowAdd: (showAdd: boolean) => void;
  day: string;
}

function HourSwitch({ showAdd, setShowAdd, day }: HourSwitchProps) {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={1}
      minWidth="100px"
      height="54px"
      alignItems="center"
    >
      <Switch
        size="md"
        checked={showAdd}
        onClick={() => setShowAdd(!showAdd)}
      />
      <Typography level="body-md" fontWeight="700">
        {t(`week.${day}`)}
      </Typography>
    </Stack>
  );
}

export default HourSwitch;
