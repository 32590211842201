import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, logger } from '@sakari-io/sakari-components';
import { AccountContext } from '../../contexts/account.context';

function Refresh() {
  const {
    getAccessTokenSilently,
    user,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    error,
  } = useAuth0();
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);

  useEffect(() => {
    // logger.info('dddata', data, other);
    logger.info('user1', user);
    logger.info(
      'isAuthenticated, isLoading, error',
      isAuthenticated,
      isLoading,
      error,
    );

    if (!isLoading) {
      // if (account) {
      //   localStorage.setItem('account', JSON.stringify(data.data));
      // }

      setTimeout(() => {
        getAccessTokenSilently({ ignoreCache: true })
          .then(() => {
            return getIdTokenClaims();
          })
          .then((claims) => {
            // logger.info('claims', claims);
            if (claims?.email_verified) {
              navigate('/');
            }
          });
      }, 1000);
    }
  }, [account]);

  return <Loader size={200} />;

  // return <Button onClick={()=> getAccessTokenSilently({ ignoreCache: true })
  // .then((at) => logger.info('accesstoken', at))}>
  //   Button
  // </Button>;
}

export default Refresh;
