import React, { useContext, useEffect } from 'react';
import { Loader, logger } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Stack } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from '../../constants';
import { AccountContext } from '../../contexts/account.context';
import { NavBar, WithSideNav } from '../../ui';
import Banner from '../PlatformFreeTrial/Banner';
import { useGetPhoneNumbersQuery } from '../../api';
import QuickSendButton from '../../ui/organisms/ButtonContexts/QuickSendButton';
import { useAppDispatch } from '../../redux';
import { disconnectSockets } from '../../utils/socket-connection';
import { toggleTheme } from '../../redux/slices/themeSlice';
import CallBarAlert from '../../ui/organisms/alerts/CallBarAlert';
import UnsupportedViewModal from '../../ui/organisms/Dialogs/UnsupportedViewDialog';
import { Mode } from '../../redux/reducers/workflow';
import { useSubscribeToUserNotificationsMutation } from '../../api/profile';
import { showToast } from '../../redux/reducers/toast';
import { createNotificationSubscription } from '../../utils/notifications';

export const VOICE_ACCOUNTS = [
  '5f358eee8541d00f36135f2f',
  '655bfad99c13f54978a0c90a',
  '5da62629cbe2e537941028b8',
  '6570d94dc356150a81419c9a',
  '6632895e662307b3f47a66b5',
  '5df3fadaaa8ca30f4c3a24d3',
  '655d45eb693e01bd579fbdb0',
  '66a54538e236c8a44cbe8adc',
];

function NavBarWrapper({ children }: { children?: React.ReactNode }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { account, profile, userRole, isLoading, isChangingAccount } =
    useContext(AccountContext);

  const [subscribeToUserNotifications] =
    useSubscribeToUserNotificationsMutation();
  // const [unsubscribeToUserNotifications, { isLoading: isUnsubscribing }] =
  //   useUnsubscribeToUserNotificationsMutation();

  const { data: phoneNumbers } = useGetPhoneNumbersQuery(
    account
      ? { accountId: account.id, request: { type: 'local', destination: 'US' } }
      : skipToken,
  );

  const showPft = !!(phoneNumbers?.data || []).find(
    (number) => number?.channels?.sms?.regulatory?.pft,
  );

  // TODO this needs to be reworked
  const { mode: workflowMode } = useSelector(
    (state: any) => state.workflow.present,
  );

  const values = {
    profile,
    account,
    userRole,
    isAdmin: userRole === UserRole.ADMIN,
  };

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'darkTheme') {
      dispatch(toggleTheme());
    }

    logger.info('permission', Notification?.permission);

    if (Notification?.permission === 'denied') {
      dispatch(
        showToast({
          severity: 'warning',
          message: t('insufficientPermissions'),
        }),
      );
    } else {
      (Notification?.permission === 'default'
        ? Notification.requestPermission()
        : Promise.resolve(Notification?.permission)
      ).then((confirmPermission) => {
        if (confirmPermission === 'granted') {
          createNotificationSubscription()
            .then((subscription) => {
              if (subscription) {
                subscribeToUserNotifications(subscription)
                  .unwrap()
                  .then((result) => {
                    logger.info({ result });
                  });
              }
            })
            .catch((error) => {
              logger.info({ error });
            });
        }
      });
    }

    // close socket on window unload
    window.addEventListener('beforeunload', disconnectSockets);
    return () => {
      window.removeEventListener('beforeunload', disconnectSockets);
    };
  }, []);

  if (isLoading && !isChangingAccount) {
    return <Loader label={t('loadingApp')} size={200} />;
  }
  if (isChangingAccount) {
    return (
      <Loader
        size={200}
        label={`${t('fetchingItem', {
          item: t('items.account_one'),
        })}...`}
      />
    );
  }
  if (!account) {
    navigate('/accounts');
    return undefined;
  }

  return (
    <Stack sx={{ height: '100vh' }}>
      <UnsupportedViewModal />
      <CallBarAlert />
      <Banner
        account={account}
        showPft={showPft}
        phoneNumbers={phoneNumbers?.data || []}
      />
      {/* TODO review this */}
      <WithSideNav
        sidenav={
          workflowMode !== Mode.EDIT &&
          workflowMode !== Mode.SELECTION && <NavBar {...values} />
        }
        main={children}
      />

      {userRole !== UserRole.READ_ONLY && (
        <QuickSendButton
          account={account}
          hideVoice={
            !(
              profile?.email?.endsWith('@sakari.io') ||
              VOICE_ACCOUNTS.includes(account?.id)
            )
          }
        />
      )}
    </Stack>
  );
}
export default NavBarWrapper;
