import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader } from '@sakari-io/sakari-components';
import { useGetLinkSourcesQuery } from '../../../../../api';
import SourceRow from './SourceRow';
import { AccountContext } from '../../../../../contexts/account.context';

interface SourcesDetailPanelProps {
  id: string;
  columns: GridColDef[];
}

function SourcesDetailPanel({ id, columns }: SourcesDetailPanelProps) {
  const { account } = useContext(AccountContext);
  const { data, isLoading } = useGetLinkSourcesQuery(
    account
      ? {
          accountId: account.id,
          request: id,
        }
      : skipToken,
  );

  if (isLoading) return <Loader size={100} label="Fetching link sources" />;

  return data?.data?.map((source: any, ix: number) => (
    <SourceRow linkId={id} columns={columns} source={source} index={ix} />
  ));
}
export default SourcesDetailPanel;
