import React from 'react';
import { Typography, Sheet } from '@mui/joy';

interface DrawerHeaderProps {
  title?: string;
  children?: React.ReactNode;
}

function DrawerHeader({ title, children }: DrawerHeaderProps) {
  return (
    <Sheet
      variant="solid"
      color="primary"
      sx={{
        display: 'flex',
        width: '100%',
        height: '64px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {title ? (
        <Typography level="h4" sx={{ color: 'white' }}>
          {title}
        </Typography>
      ) : null}
      {children}
    </Sheet>
  );
}

export default DrawerHeader;
