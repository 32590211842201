import React from 'react';
import { Stack, Box, Skeleton } from '@mui/joy';

function ConversationSkeleton() {
  return (
    <Stack direction="row">
      <Skeleton
        style={{ marginLeft: '17px' }}
        variant="circular"
        width="52px"
        height="52px"
      />

      <Box style={{ flex: 1, paddingLeft: 10, justifyContent: 'center' }}>
        <Skeleton variant="text" width="50%" height="20px" />
        <Skeleton variant="text" width="100%" height="20px" />
      </Box>
    </Stack>
  );
}

export default ConversationSkeleton;
