import { Divider, ListItem, MenuList } from '@mui/joy';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { isFriday, isSaturday } from 'date-fns';
import Popper from '../../../templates/Popper';
import MenuItem from '../../../atoms/MenuItem';

interface ScheduleMessageProps {
  anchorRef?: HTMLElement | null;
  open: boolean;
  onSchedule: (date: Date) => void;
  onCustomSchedule: () => void;
  onOpenClose: () => void;
}

const ScheduleMessage = React.forwardRef<
  RefObject<HTMLElement>,
  ScheduleMessageProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ anchorRef, open, onSchedule, onCustomSchedule, onOpenClose }, ref) => {
  const { t } = useTranslation();

  const date = new Date();
  const showMondayScheduleOption = isFriday(date) || isSaturday(date);

  const calculateDate = (calculateTomorrowDate = true, isAfternoon = false) => {
    const date = new Date();
    let days = 0;
    if (calculateTomorrowDate) {
      days = date.getDate() + 1;
    } else {
      days = date.getDate() + ((1 + 7 - date.getDay()) % 7);
    }
    date.setDate(days);
    date.setHours(isAfternoon ? 13 : 9, 0, 0, 0);
    return date;
  };

  const onScheduleTomorrowMorning = () => {
    onSchedule(calculateDate());
  };

  const onScheduleTomorrowAfternoon = () => {
    onSchedule(calculateDate(true, true));
  };

  const onScheduleMonday = () => {
    onSchedule(calculateDate(false));
  };
  return (
    <Popper
      id="schedule-message-popper"
      onOpenClose={() => {
        onOpenClose();
      }}
      open={open}
      anchorEl={anchorRef}
      role={undefined}
      placement="top-end"
      disablePortal
    >
      <MenuList id="composition-menu" aria-labelledby="composition-button">
        <ListItem
          sx={{
            color: 'text.secondary',
          }}
        >
          {t('scheduleMessage.title')}
        </ListItem>
        <MenuItem
          onClick={onScheduleTomorrowMorning}
          label={t('scheduleMessage.tomorrowMorningOption')}
        />
        <MenuItem
          onClick={onScheduleTomorrowAfternoon}
          label={t('scheduleMessage.tomorrowAfternoonOption')}
        />
        {showMondayScheduleOption && (
          <MenuItem
            onClick={onScheduleMonday}
            label={t('scheduleMessage.mondayMorningOption')}
          />
        )}
        <Divider />
        <MenuItem
          onClick={onCustomSchedule}
          label={t('scheduleMessage.customOption')}
        />
      </MenuList>
    </Popper>
  );
});

export default ScheduleMessage;
