import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountAttribute } from '@sakari-io/sakari-typings';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import { useDeleteAttributeMutation } from '../../../../api';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface DeleteAttributeDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  attribute: AccountAttribute;
  type: string;
}

function DeleteAttributeDialog({
  open,
  setOpen,
  attribute,
  type,
}: DeleteAttributeDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteAttribute] = useDeleteAttributeMutation();

  const triggerDelete = () => {
    deleteAttribute({ name: attribute.name, type })
      .unwrap()
      .then((res) => {
        dispatch(
          showToast({
            severity: res.success ? 'info' : 'error',
            message: res.success
              ? t('customAttributes.deleted')
              : t('customAttributes.error'),
          }),
        );
      });
    setOpen(false);
  };

  return (
    <ConfirmationDialog
      type="danger"
      open={open}
      onClose={(result) => {
        setOpen(false);
        if (result) {
          triggerDelete();
        }
      }}
      confirmLabel="yesDelete"
      header={t('customAttributes.delete')}
      content={t('cannotUndoAction')}
    />
  );
}

export default DeleteAttributeDialog;
