import React from 'react';
import { User } from '@auth0/auth0-react';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Account, Role } from '@sakari-io/sakari-typings';
import config from '../config';

export type NavigationItem = {
  label: string;
  url: string;
  icon?: { default?: JSX.Element; active?: JSX.Element };
  isVisible: (user?: User, account?: Account) => boolean;
  roles?: Role[];
  subItems?: NavigationItem[];
};

const navigation: NavigationItem[] = [
  {
    label: 'Inbox',
    url: '/inbox',
    icon: {
      default: <FontAwesomeIcon icon={regular('messages')} />,
      active: <FontAwesomeIcon icon={solid('messages')} />,
    },
    isVisible: () => true,
  },
  {
    label: 'Calls',
    url: '/calls',
    icon: {
      default: <FontAwesomeIcon icon={regular('phone')} />,
      active: <FontAwesomeIcon icon={solid('phone')} />,
    },
    isVisible: () => true,
  },
  {
    label: 'Contacts',
    url: '/contacts',
    icon: {
      default: <FontAwesomeIcon icon={regular('users')} />,
      active: <FontAwesomeIcon icon={solid('users')} />,
    },
    isVisible: () => true,
  },
  {
    label: 'Campaigns',
    url: '/campaigns',
    icon: {
      default: <FontAwesomeIcon icon={regular('paper-plane')} />,
      active: <FontAwesomeIcon icon={solid('paper-plane')} />,
    },
    isVisible: () => true,
  },
  {
    label: 'Senders',
    url: '/senders',
    icon: {
      default: <FontAwesomeIcon icon={regular('arrow-right-arrow-left')} />,
      active: <FontAwesomeIcon icon={solid('arrow-right-arrow-left')} />,
    },
    isVisible: () => true,
    roles: [Role.Administrator],
  },
  {
    label: 'Groups',
    url: '/groups',
    icon: {
      default: <FontAwesomeIcon icon={regular('people-group')} />,
      active: <FontAwesomeIcon icon={solid('people-group')} />,
    },
    isVisible: () => true,
    roles: [Role.Administrator],
  },
  {
    label: 'Integrations',
    url: '/integrations',
    icon: {
      default: <FontAwesomeIcon icon={regular('circle-nodes')} />,
      active: <FontAwesomeIcon icon={solid('circle-nodes')} />,
    },
    isVisible: () => true,
    roles: [Role.Administrator],
  },
  {
    label: 'Workflows',
    url: '/workflows',
    icon: {
      default: <FontAwesomeIcon icon={regular('split')} />,
      active: <FontAwesomeIcon icon={solid('split')} />,
    },
    isVisible: (user) =>
      !!user?.email?.endsWith('@sakari.io') && config.stage !== 'prod',
  },
  {
    label: 'Partners',
    url: '/partners',
    icon: {
      default: <FontAwesomeIcon icon={regular('handshake')} />,
      active: <FontAwesomeIcon icon={solid('handshake')} />,
    },
    isVisible: (user, account) => !!account?.partner?.approved,
  },
  {
    label: 'Tools',
    url: '/tools',
    icon: {
      default: <FontAwesomeIcon icon={regular('tools')} />,
      active: <FontAwesomeIcon icon={solid('tools')} />,
    },
    isVisible: () => true,
    subItems: [
      {
        label: 'Link Tracking',
        url: '/tools/links',
        icon: {
          default: <FontAwesomeIcon icon={regular('link')} />,
          active: <FontAwesomeIcon icon={solid('link')} />,
        },
        isVisible: () => true,
      },
      {
        label: 'QR Generator',
        url: '/tools/qr',
        icon: {
          default: <FontAwesomeIcon icon={regular('qrcode')} />,
          active: <FontAwesomeIcon icon={solid('qrcode')} />,
        },
        isVisible: (user) =>
          !!user?.email?.endsWith('@sakari.io') && config.stage !== 'prod',
      },
    ],
  },
];

const getNavigationItems = (
  path: string,
  profile?: User,
  role?: string,
  account?: Account,
): NavigationItem[] => {
  return navigation.filter(
    (navItem) =>
      navItem.isVisible(profile, account) &&
      role &&
      (!navItem.roles || navItem.roles.includes(role as Role)),
  );
};

export { getNavigationItems };
