import React from 'react';
import { PhoneNumber, SmsRegulatory } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/joy';
import { getStatus } from '../../../../../../utils/regulatory';
import Helper from '../../../../../../utils/helper';
import StatusChip from '../../../../../../ui/organisms/ChipContexts/StatusChip';

interface DetailProps {
  heading: string;
  body?: string | React.ReactNode;
}

function Detail({ heading, body }: DetailProps) {
  const { t } = useTranslation();

  return body ? (
    <Stack gap={0.5}>
      <Typography>{t(`regulatory.headers.${heading}`)}</Typography>
      {typeof body === 'string' ? (
        <Typography level="body-md">{body}</Typography>
      ) : (
        body
      )}
    </Stack>
  ) : null;
}

function CommonSections({ reg }: { reg: SmsRegulatory }) {
  return (
    <>
      <Detail heading="submitted" body={Helper.formatISODate(reg?.submitted)} />
      <Detail heading="approved" body={Helper.formatISODate(reg?.approved)} />
      <Detail heading="rejected" body={Helper.formatISODate(reg?.rejected)} />
    </>
  );
}

interface RegulatoryProps {
  sender?: PhoneNumber;
}

function Regulatory({ sender }: RegulatoryProps) {
  if (!sender) {
    return null;
  }

  const reg = sender.channels?.sms?.regulatory;
  const supportedDestinations = sender.channels?.sms?.supportedDestinations;
  const status = getStatus(
    sender?.country,
    sender?.type,
    supportedDestinations,
    reg,
  );

  const renderCommonSections = reg && <CommonSections reg={reg} />;

  const handleNumberType = (type: string) => {
    if (type) {
      if (type === 'local') {
        return (
          <>
            <Detail heading="campaignId" body={reg?.campaign?.id} />
            {status && (
              <Detail
                heading="campaignStatus"
                body={<StatusChip status={status} />}
              />
            )}
            {renderCommonSections}
          </>
        );
      }
      if (type === 'tollfree') {
        return (
          <>
            {status && (
              <Detail
                heading="verificationStatus"
                body={<StatusChip status={status} />}
              />
            )}
            {renderCommonSections}
          </>
        );
      }
    }
    return null;
  };

  return <Stack spacing={1}>{handleNumberType(sender?.type || '')}</Stack>;
}

export default Regulatory;
