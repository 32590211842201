import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountAttribute } from '@sakari-io/sakari-typings';
import Helper from '../../../../../../utils/helper';
import Condition, { ConditionProps } from '.';
import AttributeSelect from '../../../../../../ui/organisms/forms/AttributeSelect';

interface ContactAttributeConditionProps
  extends Omit<ConditionProps, 'attributeComponent'> {}

function ContactAttributeCondition({
  value,
  onChange,
  onHighlight,
  disabled,
}: ContactAttributeConditionProps) {
  const { t } = useTranslation('workflows');

  const contactType = 'contacts' as AccountAttribute['type'];
  const textType = 'text' as AccountAttribute['dataType'];

  const defaultContactAttributes = [
    {
      id: Helper.generateMongoId(),
      label: t('contactProperties.firstName'),
      name: 'firstName',
      dataType: textType,
      type: contactType,
    },
    {
      id: Helper.generateMongoId(),
      label: t('contactProperties.lastName'),
      name: 'lastName',
      dataType: textType,
      type: contactType,
    },
    {
      id: Helper.generateMongoId(),
      label: t('contactProperties.email'),
      name: 'email',
      dataType: textType,
      type: contactType,
    },
    {
      id: Helper.generateMongoId(),
      label: t('contactProperties.mobile'),
      name: 'mobile',
      dataType: 'number' as AccountAttribute['dataType'],
      type: contactType,
    },
  ];

  return (
    <Condition
      attributeComponent={
        <AttributeSelect
          type="contacts"
          disabled={disabled}
          value={{
            id: Helper.generateMongoId(),
            name: (value?.attribute?.path || '').replace('attributes.', ''),
            label: value?.attribute?.name || '',
            type: contactType,
            dataType: textType,
          }}
          onChange={(
            v:
              | AccountAttribute
              | (string | AccountAttribute | undefined)[]
              | null
              | undefined,
          ) => {
            if (v && !Array.isArray(v) && 'name' in v) {
              onChange({
                ...value,
                attribute: {
                  type: 'event',
                  path: v.name,
                  name: v.label,
                },
              });
            }
          }}
          otherAttributes={defaultContactAttributes}
        />
      }
      value={value}
      onChange={onChange}
      onHighlight={onHighlight}
      disabled={disabled}
    />
  );
}

export default ContactAttributeCondition;
