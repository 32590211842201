import { Stack, Card, Typography, Box } from '@mui/joy';
import { Button, Logo } from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';

interface PassthroughBannerProps {
  planType: string;
  isDisabled: boolean;
  onToggle: () => void;
}

function PassthroughBanner({
  planType,
  isDisabled,
  onToggle,
}: PassthroughBannerProps) {
  const { t } = useTranslation();
  return (
    <Card
      variant="plain"
      sx={{
        p: 4,
        width: 'clamp(600px, 80%, 960px)',
        background:
          'radial-gradient(100% 100% at 50% 0%, #FFFFFF 0%, #F3F9FF 100%)',
        boxShadow: 'lg',
        borderColor: 'divider',
      }}
    >
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Logo
          provider={planType === 'passthrough' ? 'twilio' : 'sakari'}
          size={40}
        />
        <Stack flex={1}>
          <Typography textColor="primary.700" fontWeight={600}>
            {`You're looking at ${planType === 'passthrough' ? 'pass through' : 'Sakari'} plans`}
          </Typography>
          <Typography level="body-sm" textColor="primary.700">
            {`Show plans for ${planType === 'passthrough' ? 'Sakari plans' : 'Twilio pass-through'}`}
          </Typography>
        </Stack>
        <Box>
          <Button variant="secondary" disabled={isDisabled} onClick={onToggle}>
            {t('action.show')}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

export default PassthroughBanner;
