import React from 'react';
import { Stack } from '@mui/joy';

interface IconWrapperProps {
  children: any;
}

function IconWrapper({ children }: IconWrapperProps) {
  return (
    <Stack
      sx={{
        padding: '8px',
        alignItems: 'center',
        justifyItems: 'center',
        minWidth: '64px',
      }}
    >
      {children}
    </Stack>
  );
}

export default IconWrapper;
