import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Sheet, Tab, Tabs, TabList, TabPanel } from '@mui/joy';
import { Contact, Group } from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/query';
import { logger } from '@sakari-io/sakari-components';
import useTypedSelector from '../../../hooks/useTypedSelector';

import ContactsList from './ContactsList';
import Dialpad from './Dialpad';

import {
  useEndCallMutation,
  useMakeCallMutation,
  useStartSessionQuery,
} from '../../../api';

import { AccountContext } from '../../../contexts/account.context';

import { useAppDispatch } from '../../../redux';
import { displayPhoneComponent } from '../../../redux/reducers/calls';

import GroupSelectStyled from './GroupSelectStyled';
import { PhoneStatus } from '../../../types';
import { showToast } from '../../../redux/reducers/toast';

export interface PhoneProps {}

function Phone() {
  const { phoneStatus } = useTypedSelector((state) => state.calls);
  const { t } = useTranslation('voice');

  const { account, profile } = useContext(AccountContext);
  const dispatch = useAppDispatch();
  const [makeCall] = useMakeCallMutation();
  const [endCall] = useEndCallMutation();

  const [group, setGroup] = useState<Group>({
    id: account?.id
      ? localStorage.getItem(`${account?.id}-selectedGroupId`) ?? ''
      : '',
    name: '',
  });
  const { data: sessionData } = useStartSessionQuery(
    account ? account.id : skipToken,
  );

  const handleEnd = () => {
    dispatch(displayPhoneComponent(PhoneStatus.PHONE));
    if (sessionData?.data?.call?.id) {
      endCall(sessionData.data.call.id)
        .unwrap()
        .catch((err) => {
          logger.error('unable to end call', err);
          dispatch(
            showToast({
              message: 'Unable to end call',
              severity: 'error',
            }),
          );
        });
    }
  };

  const onSelectContact = async () => {
    //  TODO: Open Persistent Contact Card when clicking on a contact, waiting on updated contact card design
  };
  const onActionContact = (contact: Contact) => {
    if (contact.mobile) {
      startCall({ mobile: contact.mobile });
    }
  };

  const startCall = async (contact: Pick<Contact, 'mobile'>) => {
    if (group && profile) {
      dispatch(displayPhoneComponent(PhoneStatus.NONE));
      await makeCall({
        from: group,
        to: contact,
        user: profile,
      })
        .unwrap()
        .then((result) => {
          logger.info('result.data', result.data);
        })
        .catch((error) => {
          dispatch(displayPhoneComponent(PhoneStatus.PHONE));
          dispatch(
            showToast({
              severity: 'error',
              message: error?.data?.error?.message || t('somethingWrong'),
            }),
          );
        });
    }
  };

  const onSelectGroup = (group: Group) => {
    setGroup(group);
    if (group.id && account) {
      localStorage.setItem(`${account.id}-selectedGroupId`, group.id);
    }
  };

  return (
    phoneStatus !== PhoneStatus.NONE && (
      <Sheet
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          background:
            'radial-gradient(91.91% 91.91% at 50.59% 8.09%, rgba(24, 60, 125, 0.95) 0%, rgba(1, 9, 20, 0.90) 100%)',
          width: 280,
          height: 578,
          borderRadius: '32px',
          border: '4px solid rgba(25, 46, 84, 0.50)',
          boxSizing: 'content-box',
          boxShadow: '0px 0px 43.2px -4px rgba(22, 68, 157, 0.52)',
          backdropFilter: 'blur(20px)',
          // '&::before': {
          //   content: '""',
          //   position: 'absolute',
          //   width: 280,
          //   height: 578,
          //   top: '-4px',
          //   left: '-4px',
          //   border: '4px solid rgba(25, 46, 84, 0.50)',
          //   zIndex: 100,
          //   borderRadius: '35px',
          // },
        }}
      >
        <Stack
          sx={{
            width: '100%',
            py: '12px',
            alignItems: 'center',
          }}
        >
          <GroupSelectStyled
            account={account}
            value={group}
            onChange={onSelectGroup}
          />
        </Stack>
        <Tabs
          sx={{
            background: 'transparent',
            flex: 1,
            overflow: 'hidden',
            '--Tabs-spacing': 0,
          }}
        >
          <TabList
            tabFlex={1}
            sx={{
              '.MuiTab-root': {
                color: 'neutral.200',

                '&:hover, &:active, &.Mui-selected': {
                  color: 'white',
                  fontWeight: 700,
                },
                '&::after': {
                  backgroundColor: 'white',
                },
              },
            }}
          >
            <Tab>{`📱 ${t('dialpad')}`}</Tab>
            <Tab>{`🗄️ ${t('contacts')}`}</Tab>
          </TabList>
          <TabPanel value={0} sx={{ pt: 4 }}>
            <Dialpad
              onDial={(number) => {
                if (sessionData?.data.call) {
                  handleEnd();
                } else {
                  startCall({ mobile: number });
                }
              }}
              group={group}
              call={sessionData?.data.call}
            />
          </TabPanel>
          <TabPanel
            value={1}
            sx={{
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <ContactsList
              account={account}
              onSelect={onSelectContact}
              onAction={onActionContact}
            />
          </TabPanel>
        </Tabs>
      </Sheet>
    )
  );
}

export default Phone;
