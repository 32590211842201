import { Box, Button, Chip, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { format } from '@sakari-io/sakari-common';
import { logger } from '@sakari-io/sakari-components';
import { useEndCallMutation } from '../../../api';
import { ActiveCall } from '../../../types';
import { showToast } from '../../../redux/reducers/toast';
import { useAppDispatch } from '../../../redux';

interface CallingProps {
  call: ActiveCall;
}

function Calling({ call }: CallingProps) {
  const { t } = useTranslation('voice');
  const dispatch = useAppDispatch();

  const [endCall] = useEndCallMutation();

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 14px',
        height: '100%',
      }}
    >
      <Box>
        <Chip color="primary" sx={{ marginRight: 2, marginBottom: '5px' }}>
          {t('callingFrom')}: {call.group?.name}
        </Chip>
        <Typography level="body-md" sx={{ color: 'white', fontWeight: 700 }}>
          {format.nameOrMobile(call.contact)}
        </Typography>
      </Box>
      <Button
        variant="plain"
        color="voiceBtn"
        size="sm"
        sx={{
          color: 'var(--joy-palette-danger-500)',
          '&:hover': { opacity: 0.8 },
        }}
        onClick={() =>
          endCall(call.id)
            .unwrap()
            .catch((err) => {
              logger.error('unable to end call', err);
              dispatch(
                showToast({
                  message: 'Unable to end call',
                  severity: 'error',
                }),
              );
            })
        }
      >
        {t('endCall')}
      </Button>
    </Box>
  );
}

export default Calling;
