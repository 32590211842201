import React from 'react';
import { useFormState } from 'react-final-form';
import FieldWrapper from '../../../../../../utils/FieldWrapper';
import SearchNumber from './SearchNumber';

interface ChooseNumberProps {
  name: string;
  lineTypeName: string;
  countryName: string;
}
function ChooseNumber({ name, lineTypeName, countryName }: ChooseNumberProps) {
  const { values } = useFormState();

  return (
    <FieldWrapper
      name={name}
      component={SearchNumber}
      country={values[countryName]}
      lineType={values[lineTypeName]}
    />
  );
}

export default ChooseNumber;
