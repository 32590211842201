import React from 'react';
import { AspectRatio, Stack, Typography, Button } from '@mui/joy';
import { AvailableIntegration } from '@sakari-io/sakari-typings';
import { getIntegrationIcon } from '@sakari-io/sakari-components';
import config from '../../../../config';

interface ZapierIntegrationDetailsDialogProps {
  integration: AvailableIntegration;
}

function ZapierIntegrationDetailsDialog({
  integration,
}: ZapierIntegrationDetailsDialogProps) {
  const openInNewTab = (sourceId: string): void => {
    window.open(
      `https://zapier.com/apps/${sourceId}/integrations/sakari-sms`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <Stack gap={3} maxWidth={496} padding={1}>
      <Stack direction="row" gap={3}>
        <AspectRatio
          variant="outlined"
          ratio="1"
          sx={{ width: '72px', height: '72px', borderRadius: '20px' }}
        >
          <img
            src={getIntegrationIcon(config.stage, integration.id, 128)}
            alt={integration.name}
            style={{ objectFit: 'cover' }}
          />
        </AspectRatio>

        <Typography level="title-lg" sx={{ fontWeight: 700, my: 'auto' }}>
          {integration.name}
        </Typography>
      </Stack>
      <Button onClick={() => openInNewTab(integration.sourceId)} fullWidth>
        Configure in Zapier
      </Button>
      <Typography level="body-md">{integration.description}</Typography>
    </Stack>
  );
}

export default ZapierIntegrationDetailsDialog;
