import React from 'react';
import { Chip, ChipProps } from '@mui/joy';

import { ChannelTypes } from '@sakari-io/sakari-typings';
import { ChannelIcon } from '@sakari-io/sakari-components';

const channelStyling: {
  [key in ChannelTypes]: {
    label: string;
    color: ChipProps['color'];
  };
} = {
  [ChannelTypes.Sms]: {
    label: 'SMS',
    color: 'warning',
  },
  [ChannelTypes.Voice]: {
    label: 'Voice',
    color: 'neutral',
  },
  [ChannelTypes.Whatsapp]: {
    label: 'Whatsapp',
    color: 'success',
  },
  [ChannelTypes.Messenger]: {
    label: 'Messenger',
    color: 'primary',
  },
};

interface ChannelChipProps {
  channel: ChannelTypes;
  noLabel?: boolean;
}

function ChannelChip({ channel, noLabel }: ChannelChipProps) {
  const styling = channelStyling[channel];
  // const style: any = {
  //   paddingLeft: '10px',
  //   backgroundColor: styling.color,
  //   marginRight: '4px',
  //   fontWeight: 'bold',
  // };
  return (
    <Chip
      key={channel}
      startDecorator={<ChannelIcon channel={channel} />}
      color={styling?.color || 'neutral'}
      sx={{
        fontWeight: 'bold',
      }}
      slotProps={{
        label: {
          sx: {
            ...(noLabel && { display: 'none' }),
          },
        },
        startDecorator: {
          sx: {
            ...(noLabel && { marginRight: '0' }),
          },
        },
      }}
    >
      {styling?.label}
    </Chip>
  );
}

export default ChannelChip;
