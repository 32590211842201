import React from 'react';
import { CircularProgress } from '@mui/joy';
import { Account } from '@sakari-io/sakari-typings';
import Select from '../../../atoms/inputs/Select';
import { useGetHubSpotInboxesQuery } from '../../../../api';

// type Destination = Partial<GroupNotification> &
//   Pick<GroupNotification, 'destination'>;

interface Destination {
  id: string;
  name: string;
}

interface HubSpotInboxSelectProps {
  account: Account;
  value?: Destination;
  onChange: (value: Destination) => any;
}

function HubSpotInboxSelect({ value, onChange }: HubSpotInboxSelectProps) {
  const { data, isLoading } = useGetHubSpotInboxesQuery(null);

  const inboxOptions =
    data?.data?.map((inbox: Destination) => {
      return {
        value: inbox.id,
        label: inbox.name,
      };
    }) || [];

  return (
    <Select
      options={inboxOptions}
      value={value?.id}
      onChange={(evt) => {
        const selectedInbox = data?.data.find(
          (inbox: Destination) => inbox.id === evt,
        );
        if (selectedInbox) {
          onChange(selectedInbox);
        }
      }}
      renderOption={(option: any) => (option ? option.label : '')}
      placeholder={
        inboxOptions?.length === 0 ? 'No inboxes found' : 'Select inbox'
      }
      {...(isLoading && {
        disabled: true,
        indicator: <CircularProgress size="sm" />,
      })}
    />
  );
}

export default HubSpotInboxSelect;
