import React from 'react';
import { useTranslation } from 'react-i18next';
import { SakariAPIResponse, User } from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import ConfirmationDialog from '../../../molecules/ConfirmationDialog';

interface RemoveUserDialogProps {
  open: boolean;
  onClose: () => void;
  user: Partial<User> & Pick<User, 'id'>;
  handleRemove: (id: string) => Promise<SakariAPIResponse>;
}

function RemoveUserDialog({
  open,
  onClose,
  user,
  handleRemove,
}: RemoveUserDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onRemove = async () => {
    logger.info(`removing user ${user.id} from account`);
    const removed = await handleRemove(user.id);
    dispatch(
      showToast({
        severity: removed.success ? 'info' : 'error',
        message: removed.success
          ? t('users.remove.success')
          : t('users.remove.error'),
      }),
    );
    onClose();
  };

  const userName =
    user.firstName || user.lastName
      ? `${user.firstName} ${user.lastName}\n`
      : '';
  const userEmail = user.email ? `(${user.email})` : '';

  return (
    <ConfirmationDialog
      type="danger"
      open={open}
      onClose={(result) => {
        onClose();
        if (result) {
          onRemove();
        }
      }}
      header={t('users.remove.dialog.title')}
      content={`${userName}${userEmail}`}
      confirmLabel="yesRemove"
    >
      {t('cannotUndoAction')}
    </ConfirmationDialog>
  );
}

export default RemoveUserDialog;
