import React from 'react';
import { Stack } from '@mui/joy';
import DialpadButton from './DialpadButton';

const numbers: NumberItem[] = [
  { id: '1', value: '1' },
  { id: '2', value: '2', letters: 'ABC' },
  { id: '3', value: '3', letters: 'DEF' },
  { id: '4', value: '4', letters: 'GHI' },
  { id: '5', value: '5', letters: 'JKL' },
  { id: '6', value: '6', letters: 'MNO' },
  { id: '7', value: '7', letters: 'PQRS' },
  { id: '8', value: '8', letters: 'TUV' },
  { id: '9', value: '9', letters: 'WXYZ' },
  { id: '*', value: '*' },
  { id: '0', value: '0', altValue: '+' },
  { id: '#', value: '#' },
];

// const getNumberFromLetter = (letter: string) => {
//   const number = numbers.find(
//     ({ letters }) => (letters || '').indexOf(letter) >= 0,
//   );
//   if (!number) {
//     return '';
//   }

//   return number.value;
// };

export interface NumberItem {
  id: string;
  value: string;
  altValue?: string;
  letters?: string;
}

export interface DialpadButtonsProps {
  onClick: (value: string) => void;
}

function DialpadButtons({ onClick }: DialpadButtonsProps) {
  // const containerRef = useRef<HTMLDivElement | null>(null);
  // useEffect(() => {
  //   const inputElement = containerRef.current?.querySelector('input');
  //   if (inputElement) {
  //     inputElement.focus();
  //   }
  // }, []);
  // const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  // const handleSingleClick = (buttonValue: string) => {
  //   const newValue = value + buttonValue;
  //   onChange(newValue, buttonValue);
  // };
  // const handleButtonClick = (buttonValue: string) => {
  //   if (clickTimeoutRef.current) {
  //     clearTimeout(clickTimeoutRef.current);
  //   }
  //   clickTimeoutRef.current = setTimeout(() => {
  //     handleSingleClick(buttonValue);
  //   }, 200);
  // };
  // const handleDoubleClick = (buttonValue: string) => {
  //   if (clickTimeoutRef.current) {
  //     clearTimeout(clickTimeoutRef.current);
  //   }
  //   if (buttonValue === '0') {
  //     const newValue = value + '+';
  //     onChange(newValue, '+');
  //   }
  // };
  // const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
  //   const allowedControlKeys = [
  //     'Backspace',
  //     'Delete',
  //     'ArrowLeft',
  //     'ArrowRight',
  //   ];
  //   if (allowedControlKeys.includes(e.key)) {
  //     return;
  //   }
  //   if (e.key >= '0' && e.key <= '9') {
  //     // const newValue = value + e.key;
  //     // onChange(newValue, e.key);
  //     // e.preventDefault();
  //     return;
  //   }
  //   const directInputKeys = ['+', '*', '#'];
  //   if (directInputKeys.includes(e.key)) {
  //     // const newValue = value + e.key;
  //     onClick(e.key);
  //     // e.preventDefault();
  //     return;
  //   }
  //   const letter = e.key.toUpperCase();
  //   if (letter.length === 1 && letter >= 'A' && letter <= 'Z') {
  //     const mappedValue = getNumberFromLetter(letter);
  //     // const newValue = value + mappedValue;
  //     onClick(mappedValue);
  //     // e.preventDefault();
  //   } else {
  //     // e.preventDefault();
  //   }
  // };
  return (
    <Stack
      // p={1}
      spacing={1}
    >
      {[0, 1, 2, 3, 4].map((row) => {
        const rowNumbers = numbers.slice(row * 3, row * 3 + 3);
        return (
          <Stack direction="row" spacing={1}>
            {rowNumbers.map((item) => {
              return <DialpadButton item={item} onClick={onClick} />;
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}

export default DialpadButtons;
