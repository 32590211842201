import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Stack, Box, Divider, IconButton, Button, Sheet, List } from '@mui/joy';
import { Tooltip, Loader } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { List as ListType } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import ListsListItem from '../../../ui/organisms/Lists/ContactListsList/ListsListItem';
import { Drawer } from '../../../ui';
import SearchBar from '../../../ui/molecules/inputs/SearchBar';
import EmptyResult from '../../../ui/molecules/EmptyResult';

interface SidebarProps {
  width: number;
  open: boolean;
  setOpen: (value: boolean) => void;
  selected?: string;
  setSelected: (listId: string) => any;
  onAdd: () => any;
  pinned?: ListType[];
  onTogglePinned: (list: ListType) => any;
  lists: ListType[];
  filter: any;
  onChangeFilter: (filter: any) => any;
  hasMore: boolean;
  isReadOnly: boolean;
}

function Sidebar({
  width,
  open,
  setOpen,
  selected,
  setSelected,
  onAdd,
  pinned,
  onTogglePinned,
  lists,
  filter,
  onChangeFilter,
  hasMore = false,
  isReadOnly,
}: SidebarProps) {
  const { t } = useTranslation();
  const listsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateLimit = (clientHeight: number, itemHeight: number) =>
      Math.floor(clientHeight / itemHeight) + 3;

    const resizeListener = () => {
      const listsElement = listsRef.current;
      if (listsElement) {
        const newLimit = calculateLimit(listsElement.clientHeight, 48);
        onChangeFilter({ ...filter, limit: newLimit });
      }
    };

    resizeListener();

    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    // find the list item selected and scroll in view
    if (selected) {
      const list = document.getElementById(selected);
      if (list) {
        list.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [selected]);

  const renderList = (list: ListType, pinned: boolean) => {
    return (
      <ListsListItem
        key={list.id}
        list={list}
        onClick={(selected) => setSelected(selected.id)}
        onPinned={onTogglePinned}
        selected={selected === list.id}
        pinned={pinned}
      />
    );
  };

  const q = filter.q?.trim();
  const pinnedFiltered = (pinned || []).filter(
    (list) => !q || list.name.indexOf(q) >= 0,
  );

  return (
    <Drawer
      isOpen={open}
      persistent
      sx={{
        background: 'transparent',
        maxWidth: width || '300px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: open ? '0.75rem' : '0.3rem',
          top: '0.2rem',
          transition: 'all 1s ease-in-out',
        }}
      >
        <Tooltip
          title={open ? 'Hide sidebar' : 'Show sidebar'}
          placement="right"
        >
          <IconButton
            id="toggle-list-sidebar"
            onClick={() => setOpen(!open)}
            variant={open ? 'plain' : 'soft'}
            size="sm"
            sx={{
              zIndex: 'var(--joy-zIndex-tooltip)',
              transition: 'all 1s ease-in-out',
            }}
          >
            <FontAwesomeIcon
              icon={open ? regular('outdent') : regular('indent')}
            />
          </IconButton>
        </Tooltip>
      </Box>

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
          maxWidth: '80%',
          pb: 2,
          opacity: open ? 1 : 0,
        }}
      >
        <SearchBar
          value={filter.q}
          onChange={(val) => onChangeFilter({ ...filter, q: val })}
          fullWidth
          sx={{
            width: open ? '100%' : '0px',
            transition: 'width 1s ease-in-out',
          }}
        />
      </Stack>
      <Sheet
        variant="outlined"
        sx={{
          height: '100%',
          borderRadius: 'var(--joy-radius-md)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          visibility: open ? 'visible' : 'hidden',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden',
          }}
          ref={listsRef}
        >
          <List
            id="lists-list"
            size="sm"
            sx={{
              '--ListItem-paddingX': '24px',
              '--ListItem-paddingY': '12px',
              height: '100%',
              width: '250px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Stack id="unpinned-lists" sx={{ flex: 1, overflowY: 'overlay' }}>
              <InfiniteScroll
                dataLength={lists?.length || 0}
                next={() =>
                  onChangeFilter({
                    ...filter,
                    offset: filter.offset + filter.limit,
                  })
                }
                hasMore={hasMore}
                scrollableTarget="unpinned-lists"
                loader={<Loader size={50} />}
                style={{
                  overflow: 'hidden',
                }}
                scrollThreshold={0.9}
              >
                {pinnedFiltered?.map((list: ListType) =>
                  renderList(list, true),
                )}
                {pinnedFiltered?.length && (lists || []).length ? (
                  <Divider />
                ) : null}
                {lists?.map((list) => {
                  if (pinned?.find((p) => p.id === list.id)) {
                    return null;
                  }

                  return renderList(list, false);
                })}
              </InfiniteScroll>
              {!hasMore && lists?.length === 0 && (
                <Stack flex={1} p={2}>
                  <EmptyResult
                    item="lists"
                    heading={t('emptyResultHeading')}
                    text={t('emptyResultMessage')}
                    size="sm"
                  />
                </Stack>
              )}
            </Stack>
          </List>
        </Stack>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Divider />
          <Button
            variant="plain"
            color="neutral"
            fullWidth
            startDecorator={
              <FontAwesomeIcon
                icon={solid('circle-plus')}
                color="var(--joy-palette-focusVisible)"
              />
            }
            onClick={() => onAdd()}
            disabled={isReadOnly}
          >
            Add New List
          </Button>
        </Box>
      </Sheet>
    </Drawer>
  );
}

export default Sidebar;
