import React, { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Stack,
} from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import { useTranslation } from 'react-i18next';
import SelectableCard, {
  SelectableCardProps,
} from '../../../atoms/inputs/SelectableCard';
import { Option } from '../../../../types/ui';

interface SelectableCardsMultiProps<T>
  extends Omit<SelectableCardProps<T>, 'onSelected' | 'value' | 'onChange'> {
  label?: string | React.ReactNode;
  multiple?: boolean;
  options: T[];
  limit?: number;
  value?: T | T[];
  getId?: (option: T) => string;
  getLabel?: (option: T) => string;
  onChange: (value: T | T[]) => any;
  renderOption?: (option: T) => ReactNode;
  containerSx?: SxProps;
  isDisabled?: (selected: T | T[], option: T) => boolean;
  helperText?: string;
  error?: boolean;
  required?: boolean;
  direction?: 'row' | 'column';
  noResultsText?: string;
}

function SelectableCardGroup<T extends object>({
  label,
  multiple,
  options,
  getId = (option) => (option as Option)?.id,
  getLabel = (option) => (option as Option)?.label,
  width,
  value,
  limit,
  onChange,
  renderOption,
  containerSx,
  isDisabled,
  helperText,
  error,
  required,
  defaultValue,
  direction = 'row',
  name,
  ...rest
}: SelectableCardsMultiProps<T>) {
  const { t } = useTranslation();
  const containerProps: SxProps = {
    gap: 1.5,
    flexDirection: direction,
    flexWrap: 'wrap',
    ...containerSx,
  };

  const isOptionSelected = (option: T, selection?: T | T[]): boolean => {
    if (!selection) {
      return false;
    }

    return Array.isArray(selection)
      ? selection?.map((s) => getId(s)).includes(getId(option))
      : getId(selection) === getId(option);
  };

  const handleSelection = (option: T, selection?: T | T[]) => {
    if (multiple) {
      const newSelection = isOptionSelected(option, selection)
        ? (selection as T[]).filter((o) => getId(o) !== getId(option))
        : [...(selection as T[]), option];

      if (limit && newSelection.length > limit) {
        // onChange((prev) => prev);
        return;
      }
      onChange(newSelection);
      return;
    }

    onChange(option);
  };

  const renderOptions = (options: T[]) => {
    if (!options.length) {
      return (
        <FormHelperText>{rest.noResultsText || t('noResults')}</FormHelperText>
      );
    }
    return options.map((option: T) => (
      <SelectableCard
        key={getId(option)}
        sx={{ width: 'fit-content', textAlign: 'center' }}
        multiple={multiple}
        width={width}
        value={option}
        disabled={isDisabled && value ? isDisabled(value, option) : false}
        onChange={() => handleSelection(option, value)}
        label={renderOption ? renderOption(option) : getLabel(option)}
        checked={value && isOptionSelected(option, value)}
        {...rest}
      />
    ));
  };

  return (
    <FormControl
      error={error}
      required={required}
      id={name}
      disabled={rest.disabled}
    >
      {label && <FormLabel>{label}</FormLabel>}
      {multiple ? (
        <Stack
          sx={{
            ...containerProps,
          }}
        >
          {renderOptions(options)}
        </Stack>
      ) : (
        <RadioGroup value={value} sx={{ ...containerProps }}>
          {renderOptions(options)}
        </RadioGroup>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectableCardGroup;
