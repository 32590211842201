import React from 'react';
import { useTranslation } from 'react-i18next';
import { Sheet, Checkbox } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';

interface ReEnrollTriggerOptionProps {
  value: boolean;
  onChange: (value: boolean) => any;
  disabled?: boolean;
}

function ReEnrollTriggerOption({
  value,
  onChange,
  disabled,
}: ReEnrollTriggerOptionProps) {
  const { t } = useTranslation('workflows');

  return (
    <FormControl>
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 'var(--joy-radius-md)',
          p: 1.5,
          height: 'min-content',
        }}
      >
        <Checkbox
          checked={value || false}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          label={t('reEnrollment')}
          disabled={disabled}
        />

        <FormHelperText>{t('allowContactsToReEnroll')}</FormHelperText>
      </Sheet>
    </FormControl>
  );
}

export default ReEnrollTriggerOption;
