import React, { useState } from 'react';
import _ from 'lodash';
import { Workflow, WorkflowVersion } from '@sakari-io/sakari-typings';
import { Stack } from '@mui/joy';
import VersionCard from './VersionCard';
import DrawerHeader from '../DrawerHeader';

interface VersionDetailsProps {
  workflow: Workflow;
  version: WorkflowVersion;
  onVersionChange: (versionId: string) => any;
}

function VersionDetails({
  workflow,
  version,
  onVersionChange,
}: VersionDetailsProps) {
  const [openPopperId, setOpenPopperId] = useState<string>('');

  return (
    <>
      <DrawerHeader title="Version History" />
      <Stack
        p={2}
        spacing={1.5}
        sx={{
          backgroundColor: 'var(--joy-palette-background-level1)',
          height: `${window.innerHeight - 146}px`,
          overflow: 'auto',
          paddingBottom: '24px',
        }}
      >
        <Stack spacing={2}>
          {_.reverse([...(workflow.versions || [])])?.map(
            (vers: WorkflowVersion) => (
              <Stack key={vers.id}>
                <VersionCard
                  version={vers}
                  workflow={workflow}
                  openPopperId={openPopperId}
                  setOpenPopperId={setOpenPopperId}
                  onVersionChange={onVersionChange}
                  selected={version?.id === vers.id}
                />
              </Stack>
            ),
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default VersionDetails;
