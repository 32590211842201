import React, { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import CallBar from '../../CallBar';
import CallNotifications from '../../CallBar/CallNotifications';
import { useStartSessionQuery } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';

export enum BarStatus {
  INCOMING = 'incoming',
  ACTIVE = 'active',
  CALLING = 'calling',
}

// TODO is this component needed now?
function CallBarAlert() {
  const { account, profile } = useContext(AccountContext);

  const { data } = useStartSessionQuery(account ? account.id : skipToken);

  return (
    <>
      <CallBar call={data?.data?.call} profile={profile} />
      {data?.data?.call ? <CallNotifications call={data?.data?.call} /> : null}
    </>
  );
}

export default CallBarAlert;
