/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { Typography } from '@mui/joy';
import { logger } from '@sakari-io/sakari-components';
import LOAForm from './LOAForm';

interface SignLOAProps {
  name: string;
}

function SignLOA({ name }: SignLOAProps) {
  const form = useForm();
  const { values } = form.getState();

  // Call API to get LOA and Session ID
  const [sessionId, setSessionId] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPandaDoc, setShowPandaDoc] = useState(false);
  // Call API to generate LOA, send to user, and get session ID

  useEffect(() => {
    setSessionId('yXYAfoJ5rRDdvviPoBdB6k'); // Remove this
    form.change(`${name}.country`, values.country);

    window.addEventListener('message', (event) => {
      if (event.origin !== '') return;
      const type = event.data && event.data.type;
      if (type === 'session_view.document.completed') {
        logger.info('Document is completed');
        form.change('isLOAsigned', true);
      }
    });
  }, []);

  // const handleGenerateLOA = () => {
  //   const { errors } = form.getState();

  //   if (errors) {
  //     if (!errors[name]) {
  //       setShowPandaDoc(true);
  //     }
  //   }
  // };

  if (showPandaDoc) {
    if (sessionId) {
      return (
        <iframe
          id="pandadoc-iframe"
          src={`https://app.pandadoc.com/s/${sessionId}`}
          width="100%"
          height="800px"
          style={{ border: 0 }}
        />
      );
    }
    return (
      <Typography
        fontWeight="bold"
        sx={{
          color: 'text.secondary',
        }}
      >
        Generating LOA...
      </Typography>
    );
  }
  return <LOAForm name={name} />;
}
export default SignLOA;
