/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  GoogleSheet,
  GoogleSheetColumn,
  GoogleSheetsExportNodeConfig,
} from '@sakari-io/sakari-typings';
import { AccountContext } from '../../../../../../contexts/account.context';
import { useGetGoogleSheetColumnsQuery } from '../../../../../../api';
import DynamicAttributeSelect from '../../../../../../ui/molecules/DynamicAttributeSelect';
import { ConfigFieldProps } from '../PropertyField';

interface GoogleColumnsPopupProps extends ConfigFieldProps<GoogleSheetColumn> {
  fullWidth?: boolean;
  renderButton: (
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
    onClick: () => void,
  ) => React.ReactNode;
  hideButtonOnOpen?: boolean;
  disabled?: boolean;
  config: { googlesheet: GoogleSheet };
  disabledOptions?: string[];
}

function GoogleColumnsPopup({
  value,
  onChange,
  fullWidth,
  renderButton,
  hideButtonOnOpen,
  disabled,
  config,
  disabledOptions,
}: GoogleColumnsPopupProps) {
  const { account } = useContext(AccountContext);
  const [search, setSearch] = useState('');

  const googleSheet = (config as GoogleSheetsExportNodeConfig)?.googlesheet;

  const {
    data: columns,
    isLoading,
    isFetching,
  } = useGetGoogleSheetColumnsQuery(
    account &&
      googleSheet?.connectionId &&
      googleSheet?.id &&
      googleSheet?.tab !== undefined
      ? {
          accountId: account.id,
          request: googleSheet,
        }
      : skipToken,
  );
  const selectValue = value ? { id: value.index, name: value.name } : undefined;

  return (
    <DynamicAttributeSelect
      value={selectValue}
      onChange={(v) => {
        onChange({
          index: v.id,
          name: v.name,
        });
      }}
      attributes={
        columns?.data?.map((col: GoogleSheetColumn) => {
          return {
            id: col.index,
            name: col.name,
          };
        }) || []
      }
      renderLabel={(item) => item.name}
      isLoading={isLoading || isFetching}
      fetchAttributes={setSearch}
      hideButtonOnOpen={hideButtonOnOpen}
      renderButton={renderButton}
      fullWidth={fullWidth}
      disabled={disabled}
      disabledOptions={disabledOptions}
    />
  );
}

export default GoogleColumnsPopup;
