import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Workflow, WorkflowVersion } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';
import { useSelector, useDispatch } from 'react-redux';
import {
  useActivateWorkflowMutation,
  // usePreactivateWorkflowMutation,
  useUpdateWorkflowDefinitionMutation,
} from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import Radios from '../../../../ui/molecules/Radios';

import { formatWorkflowDefinition } from '../../Canvas/hooks/formatWorkflowDefinition';
import Dialog from '../../../../components/molecules/Dialog';
import ActivationFailureDialog from '../ActivationFailure';
import { actions } from '../../../../redux/reducers/workflow';
import { showToast } from '../../../../redux/reducers/toast';
import ActivationWarningDialog from '../ActivationWarning';

interface EnrollExistingDialogProps {
  workflow: Workflow;
  version: WorkflowVersion;
  onClose: (val: boolean) => any;
}

function EnrollExistingDialog({
  workflow,
  version,
  onClose,
}: EnrollExistingDialogProps) {
  const { t } = useTranslation('workflows');
  const { account } = useContext(AccountContext);

  const [openEnrollDialog, setOpenEnrollDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const [enrollExisting, setEnrollExisting] = useState(false);

  const [openFailureDialog, setOpenFailureDialog] = useState(false);
  const [failureMessage, setFailureMessage] = useState('');

  const [activateWorkflow, { isLoading: isActivating }] =
    useActivateWorkflowMutation();

  const [updateWorkflowDefiniton] = useUpdateWorkflowDefinitionMutation();

  const dispatch = useDispatch();

  const { nodes, edges, hasChanges } = useSelector(
    (state: any) => state.workflow.present,
  );

  const hasActiveVersion = _.some(
    workflow.versions,
    (version) => version?.active,
  );

  useEffect(() => {
    if (hasActiveVersion) {
      setOpenWarningDialog(true);
    } else {
      setOpenEnrollDialog(true);
    }
  }, []);

  // useEffect(() => {
  //   if (open) {
  //     handleApiMutationResponse(
  //       preactivateWorkflow({
  //         accountId: account?.id ?? '',
  //         request: {
  //           workflowId: workflow.id,
  //           versionId: version.id!,
  //           enrollExisting: true,
  //         },
  //       }).unwrap(),
  //       dispatch,
  //       {
  //         onSuccess: (result) => {
  //           setEligibleContacts(result?.count);
  //         },
  //         onError: () => {
  //           if (onError) {
  //             onError(true);
  //           }
  //         },
  //         defaultErrorMessage: 'Unable to calculate impacted contacts',
  //       },
  //     );
  //   }
  // }, [open]);

  const OPTIONS = [
    {
      value: true,
      label: (
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography>{t('edit.enrollExisting.yes')}</Typography>
          {/* <Typography textColor="primary.500" fontWeight={700}>
            {t('edit.enrollExisting.eligibleContacts', {
              count: eligibleContacts || 0,
            })}
          </Typography> */}
        </Stack>
      ),
      disabled: true, //! eligibleContacts || eligibleContacts === 0,
    },
    {
      value: false,
      label: t('edit.enrollExisting.no'),
      disabled: false,
    },
  ];

  const payload = {
    accountId: account?.id ?? '',
    request: {
      workflowId: workflow?.id,
      versionId: version?.id,
    },
  };

  const handleClose = () => {
    setOpenEnrollDialog(false);
    setOpenWarningDialog(false);
    onClose(false);
  };

  const handleWorkflowUpdate = () => {
    if (!hasChanges) return true;

    const formattedWorkflowDefinition = formatWorkflowDefinition(nodes, edges);

    const updatePayload = {
      ...payload,
      request: {
        ...payload.request,
        ...formattedWorkflowDefinition,
        updatedAt: version.updated?.at,
      },
    };

    return updateWorkflowDefiniton(updatePayload);
  };

  const handleActivate = async () => {
    const result = await handleWorkflowUpdate();
    if (!result) return;

    const activatePayload = {
      ...payload,
      request: {
        ...payload.request,
        versionId: version?.id ?? '',
        enrollExisting,
      },
    };

    activateWorkflow(activatePayload)
      .unwrap()
      .then(() => {
        handleClose();
        dispatch(
          showToast({
            severity: 'success',
            message: t('activate.success', { name: workflow.name }),
          }),
        );
        dispatch(actions.enterViewMode());
      })
      .catch((err) => {
        setOpenEnrollDialog(false);
        setFailureMessage(err.data?.error?.message);
        setOpenFailureDialog(true);
      });
  };

  return (
    <>
      <Dialog
        open={openEnrollDialog}
        onClose={() => handleClose()}
        title={t(`edit.enrollExisting.header`)}
        disablePrimary={isActivating} // || isCounting}
        // contentLoading={isCounting}
        submitting={isActivating}
        primaryAction={handleActivate}
        primaryTitle="Activate"
        cancelTitle="Cancel"
        cancelAction={() => handleClose()}
        color="primary"
      >
        <Stack spacing={2} pr={2} pl={2}>
          {/* {isCounting && <Loader size={100} />} */}
          <Typography level="body-sm">
            {t('edit.enrollExisting.description')}
          </Typography>

          <Stack spacing={1}>
            <Radios
              items={OPTIONS}
              value={enrollExisting}
              onChange={setEnrollExisting}
              size="sm"
            />
          </Stack>
        </Stack>
      </Dialog>
      <ActivationWarningDialog
        open={openWarningDialog}
        onAgree={() => {
          setOpenWarningDialog(false);
          setOpenEnrollDialog(true);
        }}
        onClose={() => {
          handleClose();
        }}
      />
      <ActivationFailureDialog
        open={openFailureDialog}
        onClose={() => setOpenFailureDialog(false)}
        message={failureMessage}
      />
    </>
  );
}

export default EnrollExistingDialog;
