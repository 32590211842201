import {
  PhoneNumber,
  SakariAPIResponse,
  AddNumbersResponse,
  AddNumbersPreview,
  AddNumbersRequest,
  AddHostedNumbersRequest,
  ImportNumbersRequest,
  Sender,
  AvailablePhoneNumber,
  AvailablePhoneNumberSearchRequest,
  PhoneNumberSearchRequest,
  LineTypeMetaData,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { getAccountId, buildQuery } from './common';
import { PHONE_NUMBER_TAG } from './tags';
import { AccountIdWith } from '../types';

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    searchPhoneNumbers: builder.query<
      SakariAPIResponse<AvailablePhoneNumber[]>,
      AvailablePhoneNumberSearchRequest
    >({
      query: (params) =>
        `accounts/${getAccountId()}/availablephonenumbers?${buildQuery(
          params,
        )}`,
    }),
    // create type for search results!
    phoneNumbersMetadata: builder.query<
      SakariAPIResponse<LineTypeMetaData[]>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: country }) =>
        `accounts/${accountId}/availablephonenumbers/metadata/${country}`,
    }),
    // create type for search results!
    fetchLineTypeMetadata: builder.query<
      SakariAPIResponse<LineTypeMetaData>,
      AccountIdWith<{
        country: string;
        lineType: string;
      }>
    >({
      query: ({ accountId, request: { country, lineType } }) =>
        `accounts/${accountId}/availablephonenumbers/metadata/${country}/${lineType}`,
    }),

    buyPhoneNumber: builder.mutation<object, any>({
      query: (data) => ({
        url: `accounts/${getAccountId()}/availablephonenumbers/buy`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [PHONE_NUMBER_TAG],
    }),

    buyPhoneNumbersPreview: builder.mutation<
      SakariAPIResponse<AddNumbersPreview>,
      AddNumbersRequest | AddHostedNumbersRequest | ImportNumbersRequest
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/phonenumbers?preview=1`,
        method: 'POST',
        body: data,
      }),
    }),

    buyPhoneNumbers: builder.mutation<
      SakariAPIResponse<AddNumbersResponse>,
      (AddNumbersRequest | AddHostedNumbersRequest | ImportNumbersRequest) & {
        preview?: boolean;
      }
    >({
      query: (data) => ({
        url: `accounts/${getAccountId()}/phonenumbers`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [PHONE_NUMBER_TAG],
    }),

    getPhoneNumbers: builder.query<
      SakariAPIResponse<PhoneNumber[]>,
      AccountIdWith<PhoneNumberSearchRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/phonenumbers?${buildQuery(request)}`,
      providesTags: [PHONE_NUMBER_TAG, { type: PHONE_NUMBER_TAG, id: 'LIST' }],
    }),

    getPhoneNumber: builder.query<
      SakariAPIResponse<PhoneNumber>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/phonenumbers/${request}`,
      providesTags: [PHONE_NUMBER_TAG],
    }),

    updatePhoneNumber: builder.mutation<
      Sender,
      Partial<Sender> & Pick<Sender, 'id'>
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/${getAccountId()}/phonenumbers/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [PHONE_NUMBER_TAG],
    }),

    // validates phone number regardless of whether its in our DB
    requestVerificationCode: builder.mutation<SakariAPIResponse<any>, any>({
      query: ({ number, channel }) => ({
        url: `verify`,
        method: 'POST',
        body: {
          number,
          channel,
        },
      }),
    }),

    // validates phone number regardless of whether its in our DB
    validateVerificationCode: builder.mutation<SakariAPIResponse<any>, any>({
      query: ({ id, code, channel }) => ({
        url: `verify/${id}`,
        method: 'PUT',
        body: {
          code,
          channel,
          accountId: getAccountId(),
        },
      }),
    }),

    requestPhoneNumberVerificationCode: builder.query<
      SakariAPIResponse<string>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request }) => ({
        url: `accounts/${accountId}/phonenumbers/${request}/verify`,
        method: 'GET',
        // body: data,
      }),
    }),

    validatePhoneNumberVerificationCode: builder.mutation<
      SakariAPIResponse<PhoneNumber>,
      { id: string; code: string; verificationId?: string }
    >({
      query: ({ id, ...data }) => ({
        url: `accounts/{${getAccountId()}}/phonenumbers/${id}/verify`,
        method: 'PUT',
        body: data,
      }),
    }),

    deletePhoneNumber: builder.mutation<SakariAPIResponse<any>, string>({
      query: (id) => ({
        url: `accounts/${getAccountId()}/phonenumbers/${id}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: [PHONE_NUMBER_TAG],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        let patchResult: any;

        extendedApi.util
          .selectInvalidatedBy(getState(), [
            { type: PHONE_NUMBER_TAG, id: 'LIST' },
          ])
          .forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getPhoneNumbers') {
              patchResult = dispatch(
                extendedApi.util.updateQueryData(
                  'getPhoneNumbers',
                  originalArgs,
                  (draft) => {
                    const index = draft.data.findIndex((p) => p.id === id);

                    if (index >= 0) {
                      draft.data.splice(index, 1);
                    }
                  },
                ),
              );
            }
          });

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchPhoneNumbersQuery,
  usePhoneNumbersMetadataQuery,
  useFetchLineTypeMetadataQuery,
  useBuyPhoneNumberMutation,
  useBuyPhoneNumbersMutation,
  useBuyPhoneNumbersPreviewMutation,
  useGetPhoneNumberQuery,
  useUpdatePhoneNumberMutation,
  useRequestVerificationCodeMutation,
  useValidateVerificationCodeMutation,
  useRequestPhoneNumberVerificationCodeQuery,
  useLazyRequestPhoneNumberVerificationCodeQuery,
  useValidatePhoneNumberVerificationCodeMutation,
  useGetPhoneNumbersQuery,
  useDeletePhoneNumberMutation,
} = extendedApi;
