import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Handle, Position, NodeProps } from 'reactflow';
import { Stack, Box } from '@mui/joy';
import {
  WorkflowEdge,
  WorkflowNodeType,
  WorkflowNodeConfig,
} from '@sakari-io/sakari-typings';
import WorkflowCard from '../../WorkflowCard';
import AddBranchButton from '../../EdgeTypes/AddBranchButton';
import {
  Mode,
  SubMode,
  actions,
  MetricType,
} from '../../../../../redux/reducers/workflow';
import DeleteHoverButton from '../../DeleteHoverButton';
import DeleteHoverDialog from '../../DeleteHoverDialog';
import useHover from '../../../../../hooks/useHover';
import SelectElementOutputsMenu from '../../../Edit/SelectElementOutputsMenu';
import { isNodeComplete } from '../../../../../utils/workflows/nodevalidation';
import SummaryRenderer from './SummaryRenderer';
import getMetrics from '../../hooks/getMetrics';
import MetricsTab from '../../../Edit/ElementCard/MetricsTab';

export interface WorkflowNodeProps extends NodeProps {
  data: {
    type?: WorkflowNodeType;
    config?: WorkflowNodeConfig;
    edges?: WorkflowEdge[];
    disabled?: boolean;
  };
}

function WorkflowNode({ id, data }: WorkflowNodeProps) {
  const {
    mode,
    subMode,
    currentRFNode,
    elementOutputNode,
    highlightedNodeId,
    metricType,
    metrics,
    version,
    edges,
  } = useSelector((state: any) => state.workflow.present);

  const nodeMetrics = getMetrics(
    metrics?.paths,
    metrics?.total ?? 0,
    version,
    metricType,
    id,
  );

  const edgesOfNode = edges.filter((edge: WorkflowEdge) => edge.source === id);

  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);
  const wfCardRef = useRef<HTMLDivElement>(null);

  const onAddBranch = () => {
    dispatch(
      actions.addEdgeToCurrentNode({
        id,
        data,
      } as any),
    );
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [isHovered, hoverEventHandlers, setHovered] = useHover();
  const [isHandleHovered, setIsHandleHovered] = useState(false);

  const showAddBranchButton =
    data?.type?.validation?.numEdgeCondition === 'at least';

  const renderDeleteHoverButton = () => {
    return isHovered && mode === Mode.EDIT && subMode !== SubMode.DRAGGING ? (
      <DeleteHoverButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...hoverEventHandlers}
        onClick={() => {
          setOpenDialog(true);
          setHovered(false);
        }}
      />
    ) : null;
  };

  const highlightedNodeSx = {
    '.MuiCard-root': {
      border: '1px solid var(--joy-palette-info-500)',
      boxShadow: '0 0 20px var(--joy-palette-info-500)',
    },
  };

  const isHighlighted = [id, data?.type?.type].includes(highlightedNodeId);

  const renderContentBasedOnType = () => {
    const cardContainer = (
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...hoverEventHandlers}
        sx={{
          position: 'relative',
          zIndex: 'var(--joy-zIndex-tooltip)',
          ...(isHighlighted ? highlightedNodeSx : {}),
        }}
      >
        <Stack alignItems="center">
          <WorkflowCard
            type={data.type!}
            isHandleHovered={isHandleHovered}
            incomplete={
              !data.type ||
              !isNodeComplete(data.type, data.config || {}, edgesOfNode).valid
            }
            id={id}
            ref={wfCardRef}
            disabled={data.disabled}
            renderSummary={() => <SummaryRenderer data={data} />}
          />
          {mode === Mode.SELECTION &&
            subMode !== SubMode.SELECTION_BY_TYPE &&
            !data.disabled &&
            id !== currentRFNode?.id &&
            id === elementOutputNode?.id && (
              <SelectElementOutputsMenu
                open
                // nodeId={currentRFNode.id}
                nodeType={elementOutputNode?.data?.type}
              />
            )}

          {nodeMetrics &&
            data?.type?.type !== 'trigger' &&
            (mode === Mode.VIEW ||
              mode === Mode.VERSIONS ||
              mode === Mode.CONTACTS) && (
              <MetricsTab
                metrics={nodeMetrics}
                percentage={metricType === MetricType.PERCENTAGE}
              />
            )}
        </Stack>

        {renderDeleteHoverButton()}
      </Box>
    );

    return (
      <Stack alignItems="center">
        {cardContainer}

        {data?.type?.type !== 'trigger' && (
          <Handle
            type="target"
            style={{
              opacity: 0,
              width: '100%', // increase target area to make it easier to connect
              height: '100%',
              cursor: 'pointer',
              pointerEvents: 'auto',
            }}
            onMouseOver={() => {
              // set sub mode to dragging to prevent drawers from opening
              if (data?.type?.type !== 'actionPlaceholder') {
                dispatch(actions.setDragging(true));
              }
              setIsHandleHovered(true);
              setHovered(true);
            }}
            onMouseOut={() => {
              if (data?.type?.type !== 'actionPlaceholder') {
                dispatch(actions.setDragging(false));
              }
              setIsHandleHovered(false);
              setHovered(false);
            }}
            position={Position.Top}
            isConnectable={isConnectable}
            isConnectableStart={false}
            id="b"
          />
        )}
        {showAddBranchButton && mode === Mode.EDIT && (
          <AddBranchButton onAdd={onAddBranch} />
        )}
      </Stack>
    );
  };

  // const isConnectable = mode === Mode.EDIT && true;
  const isConnectable = false;

  return (
    <>
      <Stack flexDirection="column" alignItems="center" justifyContent="center">
        <Stack
          width="350px"
          ref={ref}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {renderContentBasedOnType()}
        </Stack>

        <Handle
          type="source"
          style={{
            opacity: 0,
            width: '1',
            height: '1',
            cursor: 'pointer',
          }}
          position={Position.Bottom}
          isConnectable={false}
          id="a"
        />
      </Stack>

      {openDialog && (
        <DeleteHoverDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          id={id}
        />
      )}
    </>
  );
}

export default WorkflowNode;
