import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  ListItemDecorator,
  ListItemContent,
  Divider,
  CircularProgress,
} from '@mui/joy';

import { useTranslation } from 'react-i18next';
import Button from '@mui/joy/Button';
import Papa from 'papaparse';
import { List } from '@sakari-io/sakari-typings';
import { useFormState, useForm } from 'react-final-form';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import Autocomplete from '@mui/joy/Autocomplete';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  IntegrationIcon,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import {
  useGetListsQuery,
  useUploadContactsMutation,
  useGetHubSpotListsQuery,
} from '../../../../api';

import FileUpload from '../../../../ui/organisms/FileUpload';
import FileUploadResult from '../../../../ui/molecules/FileUploadResult';
import FieldMappings from '../../../../ui/organisms/forms/FieldMappings';
import {
  AttributeMapping,
  generateFieldMappings,
} from '../../../../utils/fieldValidationMapping';
import { getPrimaryAttributes } from '../../../../constants';
import { TextField } from '../../../../ui';
import { AccountContext } from '../../../../contexts/account.context';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import { ExternalList } from '../../../../api/integrations';
import config from '../../../../config';

const LIMIT = 20;

const ALL_CONTACTS_LIST = {
  id: 'all',
  name: 'All Contacts',
};

interface ContactsProps {
  value: Partial<List>;
  onChange: (value: Partial<List> | (string | Partial<List>)[] | null) => void;
}

function Contacts({ value, onChange }: ContactsProps) {
  const { t } = useTranslation();
  const form = useForm();
  const { values } = useFormState();
  const dispatch = useAppDispatch();

  const { account, contactAttributes } = useContext(AccountContext);

  const [file, setFile] = useState<File>();
  const [csvData, setCsvData] = useState<string>();
  const [columns, setColumns] = useState<string[]>();
  const [createList, setCreateList] = useState<string>('');
  const [fieldMapping, setFieldMapping] = useState<AttributeMapping[]>([]);
  const [query, setQuery] = useState('');
  const [offset] = useState<number>(0);
  const [allLists, setAllLists] = useState<Partial<List>[]>([]);

  const debouncedQuery = useDebouncedValue(query, 500);

  const [uploadContacts, { isLoading: isUploading }] =
    useUploadContactsMutation();

  useEffect(() => {
    Papa.parse(csvData || '', {
      header: true,
      preview: 1,
      complete: (results) => {
        setColumns(results.meta.fields);
        setFieldMapping(
          generateFieldMappings(
            getPrimaryAttributes(),
            results.meta.fields,
            contactAttributes,
          ),
        );
      },
    });
  }, [csvData]);

  const getQuery = (offset: number) =>
    account && offset >= 0
      ? {
          accountId: account?.id,
          request: {
            offset,
            limit: LIMIT,
            q: debouncedQuery,
          },
        }
      : skipToken;

  const { data: lists, isFetching } = useGetListsQuery(getQuery(offset));
  const { data: hubSpotLists, isFetching: isFetchingHS } =
    useGetHubSpotListsQuery(getQuery(offset));

  const handleResetFile = () => {
    setCsvData(undefined);
    setFile(undefined);
    form.change('filters.list.status', undefined);
  };

  const handleSaveCsv = () => {
    if (csvData) {
      //TODO use handleApiMutationResponse
      uploadContacts({
        data: csvData,
        fieldMappings: fieldMapping,
        list: createList || 'Selected Contact(s)',
        hidden: !createList ? 1 : undefined,
      })
        .unwrap()
        .then((resp) => {
          // logger.info('resp', resp);
          setCsvData(undefined);
          setColumns([]);
          // logger.info('resp.data?.filters?.list', resp.data?.filters?.list);

          onChange(resp.data?.filters?.list || null);
          form.change('filters.list.status', undefined);
        })
        .catch((err) => {
          dispatch(
            showToast({
              message:
                err?.data?.error?.message ||
                'Unable save list - please check the file and try again',
              severity: 'error',
              vertical: 'bottom',
            }),
          );
        });
    }
  };

  const contactFilter = (values as any).filters?.list?.filter;

  const removeDuplicateHsLists = (
    lists: List[] = [],
    hsLists: ExternalList[] = [],
  ) => {
    return (hsLists || []).filter(
      (hsl) =>
        !lists.find((l) => {
          return (
            l.source?.integration === 'hubspot' && l.source?.id === `${hsl.id}`
          );
        }),
    );
  };

  useEffect(() => {
    setAllLists([
      !contactFilter
        ? ALL_CONTACTS_LIST
        : {
            id: 'selected',
            name: 'Selected Contact(s)',
            filter: contactFilter,
          },
      ...(lists?.data || []),
      ...removeDuplicateHsLists(lists?.data, hubSpotLists?.data).map(
        ({ id, name }) => ({
          name,
          source: {
            id,
            integration: 'hubspot',
          },
        }),
      ),
    ]);
  }, [lists, hubSpotLists]);

  return (
    <Stack spacing={1}>
      {!file ? (
        <Stack>
          <Autocomplete
            // fullWidth
            loading={isFetching || isFetchingHS}
            endDecorator={
              isFetching || isFetchingHS ? <CircularProgress size="sm" /> : null
            }
            placeholder="Select List"
            options={allLists}
            value={value || ALL_CONTACTS_LIST}
            onChange={(evt: any, v: any) => onChange(v)}
            isOptionEqualToValue={(a, b) => {
              return a?.id === b?.id;
            }}
            getOptionLabel={(option: string | Partial<List>) => {
              return option && (option as List).name;
            }}
            inputValue={query}
            onInputChange={(e: any, value: any) => setQuery(value)}
            startDecorator={
              value?.source && (
                <IntegrationIcon
                  stage={config.stage}
                  integration={value.source.integration}
                />
              )
            }
            renderOption={(props: any, option: any) => (
              <AutocompleteOption
                key={
                  option.id ||
                  `${option.source?.integration}-${option.source?.id}`
                }
                {...props}
              >
                {option.source && (
                  <ListItemDecorator>
                    <IntegrationIcon
                      stage={config.stage}
                      integration={option.source.integration}
                    />
                  </ListItemDecorator>
                )}
                <ListItemContent
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {option.name}
                </ListItemContent>
              </AutocompleteOption>
            )}
          />

          {!contactFilter && (
            <>
              <Typography
                level="body-md"
                sx={{
                  color: 'var(--joy-palette-neutral-outlinedBorder)',
                  textAlign: 'center',
                }}
                p={2}
              >
                {t('or')}
              </Typography>
              <FileUpload
                accept="csv"
                onSuccess={(file, data) => {
                  setCsvData(data);
                  setFile(file[0]);
                  form.change('filters.list.status', 'uploading');
                }}
              />
            </>
          )}
        </Stack>
      ) : (
        <FileUploadResult file={file} onRemove={handleResetFile} />
      )}
      <div />
      <div>
        {csvData ? (
          <>
            <FieldMappings
              columns={columns || []}
              value={fieldMapping}
              onChange={setFieldMapping}
            />
            <Typography
              level="body-md"
              fontWeight="lg"
              textColor="text.primary"
              mt={2}
              mb={1}
            >
              {t('addContacts')}
            </Typography>
            <Divider />
            <Stack direction="row" alignItems="center" spacing={1} pb={2}>
              <TextField
                size="sm"
                placeholder={t('listName')}
                fullWidth
                value={createList}
                onChange={setCreateList}
                helperText="This will create a new list with the contacts you are uploading"
              />
            </Stack>

            <Button
              size="sm"
              variant="solid"
              onClick={handleSaveCsv}
              loading={isUploading}
            >
              {t('upload')}
            </Button>
          </>
        ) : null}
      </div>
    </Stack>
  );
}

export default Contacts;
