import React, { useState, useContext } from 'react';
import { Typography, IconButton, Stack, Chip } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { EditableLabel } from '@sakari-io/sakari-components';
import { Workflow, WorkflowVersion } from '@sakari-io/sakari-typings';
import { useSelector, useDispatch } from 'react-redux';
import { faAngleLeft, faX } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateWorkflowNameMutation } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import { handleApiMutationResponse } from '../../../../utils/api';
import { Mode, actions } from '../../../../redux/reducers/workflow';
import HeaderActions from './HeaderActions';
import EnrollExistingDialog from '../EnrollExistingDialog';
// import UndoRedo from '../../../../ui/organisms/ButtonContexts/UndoRedo';

interface WorkflowHeaderProps {
  workflow: Workflow;
  version: WorkflowVersion;
  onVersionChange: (verisonId: string) => any;
  id?: string;
  divider?: boolean;
  sx?: any;
}

const WorkflowHeader = React.memo(
  ({
    id,
    divider,
    workflow,
    version,
    sx,
    onVersionChange,
  }: WorkflowHeaderProps) => {
    const { t } = useTranslation('workflows');
    const { account } = useContext(AccountContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [updateWorkflowName] = useUpdateWorkflowNameMutation();

    const [openEnrollExistingDialog, setOpenEnrollExistingDialog] =
      useState(false);

    const { mode } = useSelector((state: any) => state.workflow.present);

    const getStatusChip = () => {
      if (version.active) return <Chip color="primary">{version.name}</Chip>;
      if (version.lastActive)
        return <Chip color="warning">{version.name}</Chip>;
      return <Chip>{version.name}</Chip>;
    };

    const headerName = (
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          '& .MuiTypography-root, .MuiIconButton-root.MuiIconButton-sizeSmRound':
            {
              color:
                mode === Mode.EDIT ? 'white' : 'var(--joy-grey-primary-900)',
            },
        }}
      >
        <EditableLabel
          value={workflow.name}
          onChange={(newName: string) => onNameUpdate(newName)}
          onCancel={() => {}}
          placeholder="New workflow name"
          level="h4"
        />
        {mode === Mode.EDIT ? (
          <Chip color="neutral">{version?.name}</Chip>
        ) : (
          getStatusChip()
        )}
      </Stack>
    );

    const onNameUpdate = (newName: string) => {
      const payload = {
        accountId: account!.id,
        request: {
          ...workflow,
          id: workflow.id,
          name: newName,
        },
      };

      return handleApiMutationResponse(
        updateWorkflowName(payload).unwrap(),
        dispatch,
        {
          successMessage: t('edit.nameUpdated'),
          defaultErrorMessage: t('edit.nameUpdateError'),
        },
      );
    };

    const renderModeStyle = () => {
      const defaultStartDecorator = (
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton
            onClick={() => navigate('/workflows')}
            color="neutral"
            variant="outlined"
            sx={{
              height: '36px',
              width: '36px',
              borderRadius: '8px',
              padding: 'auto',
              background: 'var(--joy-palette-background-surface)',
            }}
            aria-label="Back"
          >
            <FontAwesomeIcon icon={faAngleLeft} size="xs" />
          </IconButton>
        </Stack>
      );
      switch (mode) {
        case Mode.SELECTION:
          return {
            bgColor: 'var(--joy-palette-info-50)',
            title: (
              <Typography sx={{ color: 'var(--joy-palette-info-500)' }}>
                {headerName}
              </Typography>
            ),
            chip: false,
            plain: true,
            endDecorator: (
              <IconButton
                size="sm"
                color="voice"
                onClick={() => {
                  dispatch(actions.enterEditMode());
                }}
              >
                <FontAwesomeIcon
                  icon={faX}
                  style={{
                    color: 'var(--joy-palette-info-500)',
                  }}
                />
              </IconButton>
            ),
          };
        case Mode.EDIT:
          return {
            bg: ' linear-gradient(90deg, #052653 62.22%, #0C4596 105.64%)',
            title: headerName,
            chip: true,
            plain: false,
            endDecorator: null,
          };

        default:
          return {
            bgColor: 'inherit',
            title: headerName,
            chip: false,
            plain: false,
            startDecorator: defaultStartDecorator,
            endDecorator: null,
          };
      }
    };

    return (
      <>
        <Stack
          id={id ?? 'page-header'}
          sx={{
            flexFlow: 'row nowrap',
            py: 3,
            px: 4,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            boxSizing: 'border-box',
            borderBottom: divider
              ? `1px solid var(--joy-palette-divider)`
              : 'none',
            backgroundColor: renderModeStyle().bgColor,
            background: renderModeStyle().bg,
            ...sx,
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {renderModeStyle().startDecorator}

            <Stack
              sx={{
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              <Typography
                level="h4"
                fontWeight="bold"
                slotProps={{
                  root: {
                    sx: {
                      color:
                        mode === Mode.EDIT
                          ? 'var(--joy-palette-common-white)'
                          : 'inherit',
                    },
                  },
                }}
              >
                {renderModeStyle().title}
              </Typography>

              {/* {mode === Mode.EDIT && (
                <UndoRedo stateKey="workflow" undoable={hasChanges} />
              )} */}
            </Stack>
          </Stack>
          {!renderModeStyle().plain && version && (
            <HeaderActions
              workflow={workflow}
              version={version}
              onVersionChange={onVersionChange}
            />
          )}
          {renderModeStyle().endDecorator
            ? renderModeStyle().endDecorator
            : null}
        </Stack>
        {version && openEnrollExistingDialog && (
          <EnrollExistingDialog
            workflow={workflow}
            version={version}
            onClose={() => setOpenEnrollExistingDialog(false)}
          />
        )}

        {/* TODO: reinstate with number of nodes failing validation when new wf validation logic is merged {mode === Mode.EDIT && <NodeValidationAlert />} */}
      </>
    );
  },
);

export default WorkflowHeader;
