import React, { useEffect } from 'react';
import useTypedSelector from '../../../../hooks/useTypedSelector';
import { hideToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../hooks';
import Snackbar from '../../../templates/Snackbar';
import StatusAlert from '../StatusAlert';

function GlobalToastSnackbar() {
  const dispatch = useAppDispatch();
  const {
    message,
    severity,
    vertical = 'bottom',
    horizontal = 'center',
    timeout = 3000,
  } = useTypedSelector((state) => state.toast);

  useEffect(() => {
    if (message && timeout) {
      setTimeout(() => {
        dispatch(hideToast());
      }, timeout);
    }
  }, [message]);

  return (
    <Snackbar
      open={!!message}
      onClose={() => dispatch(hideToast())}
      placement={{
        vertical,
        horizontal,
      }}
      style={{
        paddingBottom: 16,
      }}
    >
      <StatusAlert status={severity || 'other'} message={message || ''} />
    </Snackbar>
  );
}

export default GlobalToastSnackbar;
