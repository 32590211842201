import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/joy';
import React, { useMemo, useState } from 'react';
import { Account, AccountAttribute } from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/query';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import SearchableMenu from '../../../../molecules/Menu/SearchableMenu';
import OverlayPopup from '../../../../templates/OverlayPopup';
import { useGetAttributesByTypeQuery } from '../../../../../api';
import ATTRIBUTES from './constants';

interface AttributeListProps {
  onSelect: (attr: AccountAttribute) => any;
  disabled?: boolean;
  account?: Account;
  isOptionDisabled?: (option: AccountAttribute) => boolean;
}

function AttributeList({
  onSelect,
  disabled,
  account,
  isOptionDisabled,
}: AttributeListProps) {
  const [open, setOpen] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);
  const { data: attributesData, isFetching } = useGetAttributesByTypeQuery(
    account
      ? {
          accountId: account.id,
          request: {
            type: 'contacts',
            q: debouncedSearchTerm,
          },
        }
      : skipToken,
  );

  const filteredAttributes = useMemo(() => {
    const defaultAttributes: any[] = ATTRIBUTES.map((attr) => ({
      ...attr,
      type: 'default',
      dataType: 'text',
    }));

    if (!isFetching && attributesData?.data?.length) {
      const allAttributes = [...defaultAttributes, ...attributesData.data];
      if (debouncedSearchTerm) {
        return allAttributes?.filter((attr: AccountAttribute) => {
          return attr.label
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase());
        });
      }
      return [...defaultAttributes, ...attributesData.data];
    }

    return defaultAttributes;
  }, [debouncedSearchTerm, attributesData, isFetching]);

  return (
    <OverlayPopup
      open={open}
      onOpenClose={setOpen}
      renderButton={(setAnchorEl, onClick) => (
        <Tooltip title="Search attributes" placement="top" size="sm">
          <IconButton
            ref={setAnchorEl}
            onClick={onClick}
            size="smRound"
            color="primary"
            disabled={disabled}
          >
            <FontAwesomeIcon icon={solid('tags')} />
          </IconButton>
        </Tooltip>
      )}
      renderPopup={() => (
        <SearchableMenu
          onQueryChanged={setSearchTerm}
          onSelect={(attribute) => {
            onSelect(attribute);
            setOpen(false);
          }}
          getLabel={(attribute: any) => attribute.label}
          items={filteredAttributes?.slice(0, 20) || []}
          isDisabled={isOptionDisabled}
        />
      )}
      placement="top-start"
    />
  );
}

export default AttributeList;
