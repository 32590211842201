import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import config from '../../../../config';
import clearCookies from '../../../../utils/authentication';

function LogoutLink() {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  return (
    <Link
      underline="none"
      onClick={() => {
        clearCookies();
        logout({ returnTo: config.auth0.callbackUrl });
      }}
      sx={{
        '&:hover': { fontWeight: 'bold' },
      }}
    >
      {t('logout')}
    </Link>
  );
}

export default LogoutLink;
