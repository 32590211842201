import { Box, Button, Divider, List } from '@mui/joy';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader } from '@sakari-io/sakari-components';
import { AccountContext } from '../../../../contexts/account.context';
import { useAppDispatch } from '../../../../redux';
import {
  useAddPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
} from '../../../../api';
import PaymentMethodItem from '../../../../ui/organisms/cards/PaymentMethodCardItem/PaymentMethodItem';
import ConfirmationDialog from '../../../../ui/molecules/ConfirmationDialog';
import { useMakePaymentMethodDefaultMutation } from '../../../../api/accounts';
import { showToast } from '../../../../redux/reducers/toast';

function PaymentMethods() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { account } = useContext(AccountContext);

  const { data, isLoading, isFetching } = useGetPaymentMethodsQuery(
    account ? account.id : skipToken,
  );

  const sortedPaymentMethods = useMemo(() => {
    const paymentMethods = data?.data || [];

    const defaultPaymentMethod = paymentMethods.find((pm) => pm.default);

    if (!defaultPaymentMethod) return paymentMethods;

    const otherPaymentMethods = paymentMethods.filter((pm) => !pm.default);
    return [defaultPaymentMethod, ...otherPaymentMethods];
  }, [data]);

  const [addPaymentMethod, { isLoading: addPaymentMethodLoading }] =
    useAddPaymentMethodMutation();

  const [makeDefault, { isLoading: makeDefaultLoading }] =
    useMakePaymentMethodDefaultMutation();

  const [removePaymentMethod, { isLoading: removePaymentMethodLoading }] =
    useDeletePaymentMethodMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>('');

  const handleDelete = async (id: string) => {
    removePaymentMethod(id)
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              severity: 'success',
              message: t(
                'settings.billing.general.paymentMethods.removeCard.success',
              ),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data?.error?.message ?? t('somethingWrong'),
          }),
        );
      });
  };

  const handleAdd = async () => {
    try {
      const url = await addPaymentMethod(null)
        .unwrap()
        .then(({ data }) => data);

      window.location.href = url;
    } catch (error) {
      dispatch(
        showToast({
          severity: 'error',
          message: 'Unable to load Stripe Checkout',
        }),
      );
    }
  };
  const handleMakeDefault = async (id: string) => {
    makeDefault({ id })
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(
            showToast({
              severity: 'success',
              message: t(
                'settings.billing.general.paymentMethods.makeDefault.success',
              ),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message: err.data?.error?.message ?? t('somethingWrong'),
          }),
        );
      });
  };

  const handleAction = async (action: 'remove' | 'makeDefault', id: string) => {
    if (action === 'remove') {
      setSelectedPaymentMethod(id);
      setIsOpen(true);
    }

    if (action === 'makeDefault') {
      setSelectedPaymentMethod(id);
      await handleMakeDefault(id);
    }
  };
  if (isLoading)
    return (
      <Box height={120}>
        <Loader size={100} />
      </Box>
    );

  return (
    <>
      <List
        id="payment-methods-list"
        sx={{
          gap: 2,
          '--ListItem-radius': '0.5rem',
          minHeight: 120,
        }}
      >
        {isFetching ? (
          <Loader size={100} key="loader" />
        ) : (
          sortedPaymentMethods?.map((pm) => (
            <React.Fragment key={pm.id}>
              <PaymentMethodItem
                loading={makeDefaultLoading || removePaymentMethodLoading}
                isSelected={pm.id === selectedPaymentMethod}
                paymentMethod={pm}
                setAction={(action, id) => handleAction(action, id)}
              />
            </React.Fragment>
          ))
        )}
      </List>
      <Divider inset="context" />
      <Button
        title={t('settings.billing.general.paymentMethods.addCard.label')}
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => handleAdd()}
        loading={addPaymentMethodLoading}
      >
        {t('settings.billing.general.paymentMethods.addCard.label')}
      </Button>
      <ConfirmationDialog
        open={isOpen}
        onClose={(result) => {
          if (result) {
            handleDelete(selectedPaymentMethod);
          }
          setIsOpen(false);
        }}
        type="danger"
        header={t('settings.billing.general.paymentMethods.removeCard.title')}
        content={t(
          'settings.billing.general.paymentMethods.removeCard.description',
        )}
        confirmLabel={t(
          'settings.billing.general.paymentMethods.removeCard.yes',
        )}
      />
    </>
  );
}
export default PaymentMethods;
