import React from 'react';
import { Form } from 'react-final-form';
import Dialog, { DialogProps } from '../Dialog';

interface FormDialogProps
  extends Pick<
    DialogProps,
    | 'title'
    | 'size'
    | 'open'
    | 'primaryAction'
    | 'primaryTitle'
    | 'cancelAction'
    | 'cancelTitle'
    | 'color'
    | 'children'
    | 'icon'
  > {
  initialValues?: any;
  validate?: any;
  resetOnClose?: boolean;
}

function FormDialog({
  title,
  open,
  size,
  primaryAction,
  primaryTitle,
  cancelAction,
  initialValues,
  validate,
  children,
  resetOnClose,
  ...dialogProps
}: FormDialogProps) {
  const handleClose = (form: any) => {
    if (resetOnClose) {
      form.restart();
    }
    cancelAction?.();
  };
  return (
    <Form
      onSubmit={primaryAction}
      validate={validate}
      initialValues={initialValues || {}}
      render={({ handleSubmit, form, submitting, hasValidationErrors }) => {
        return (
          <Dialog
            title={title}
            open={open}
            size={size}
            onClose={() => handleClose(form)}
            primaryAction={handleSubmit}
            primaryTitle={primaryTitle}
            disablePrimary={hasValidationErrors}
            cancelAction={() => handleClose(form)}
            submitting={submitting}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...dialogProps}
          >
            {children}
          </Dialog>
        );
      }}
    />
  );
}

export default FormDialog;
