import React, { useState } from 'react';
import { Box, FormHelperText } from '@mui/joy';
import { Account, User } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { format } from '@sakari-io/sakari-common';
import {
  useDebouncedValue,
  DropdownSelect,
} from '@sakari-io/sakari-components';
import { useGetUsersQuery } from '../../../../api';
import UserLineItem from './UserLineItem';

interface GroupUsersProps {
  account: Account;
  value: Array<User>;
  onChange: (value: Array<User>) => any;
  sx?: any;
  error?: string;
  helperText?: string;
}

function GroupUsers({
  account,
  value,
  sx,
  onChange,
  error,
  helperText,
}: GroupUsersProps) {
  const [input, setInput] = useState('');
  const debouncedInput = useDebouncedValue(input, 500);
  const { data } = useGetUsersQuery(
    account
      ? {
          accountId: account.id,
          request: { q: debouncedInput },
        }
      : skipToken,
  );
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ ...sx, padding: 2 }}>
        <DropdownSelect
          key="group-user-select"
          fullWidth
          inputValue={input}
          onChange={(v: any) => {
            if (Array.isArray(value)) {
              if (!value.find((u) => u.id === v.id)) {
                onChange([...value, v]);
              }
              setInput('');
            } else {
              onChange([v]);
            }
          }}
          onInputChange={setInput}
          options={data?.data || []}
          placeholder={t('addUser')}
          value={null}
          getOptionLabel={(option: any) => format.name(option)}
          getOptionDisabled={(option: User) =>
            Array.isArray(value)
              ? !!value.find((v) => v.id === option.id)
              : false
          }
          renderOption={(props: any, user: any) => (
            <AutocompleteOption
              key={user.id}
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {format.name(user)}
            </AutocompleteOption>
          )}
        />
      </Box>

      {(value || []).map((user) => {
        return (
          <UserLineItem
            sx={{ padding: '10px' }}
            key={user.id}
            user={user}
            onRemove={(user) =>
              onChange((value || []).filter((u) => u.id !== user.id))
            }
          />
        );
      })}

      {helperText ? (
        <FormHelperText color={error ? 'danger' : 'neutral'}>
          {helperText}
        </FormHelperText>
      ) : null}
    </>
  );
}

export default GroupUsers;
