import React from 'react';
import { useTranslation } from 'react-i18next';
import { INDUSTRY_TYPES } from '../../../constants';

import TextField from '../../../ui/atoms/inputs/TextField';
import FieldWrapper from '../../../utils/FieldWrapper';
import Select from '../../../ui/atoms/inputs/Select';
import CountryAutocomplete from '../../../ui/organisms/forms/CountryAutocomplete';
import Checkboxes from '../../../ui/organisms/Checkboxes';

const messagingVolume = [
  { value: '1-1,000', label: '1-1,000 messages per month' },
  { value: '1,001-10,000', label: '1,001-10,000 messages per month' },
  { value: '10,001-50,000', label: '10,001-50,000 messages per month' },
  { value: '50,001-100,000', label: '50,001-100,000 messages per month' },
  { value: '100,001+', label: '100,001+ messages per month' },
];

const desiredIntegrations = [
  { value: 'activecampaign', label: 'ActiveCampaign' },
  { value: 'slack', label: 'Slack' },
  { value: 'hubspot', label: 'HubSpot' },
  { value: 'intercom', label: 'Intercom' },
  { value: 'pipedrive', label: 'Pipedrive' },
  { value: 'zapier', label: 'Zapier' },
];

function BusinessInfo() {
  const { t } = useTranslation();

  return (
    <>
      <FieldWrapper
        component={TextField}
        fullWidth
        id="business"
        name="businessInfo.name"
        label={t('registration.businessInfo.businessName.label')}
        placeholder={t('registration.businessInfo.businessName.placeholder')}
        infoText={t('registration.businessInfo.businessName.info')}
        required
        autoFocus
      />
      <FieldWrapper
        component={CountryAutocomplete}
        fullWidth
        name="businessInfo.country"
        required
        label={t('form.country.label')}
      />
      <FieldWrapper
        component={Select}
        fullWidth
        name="businessInfo.questionnaire.industry"
        label={t('registration.businessInfo.industry.label')}
        placeholder={t('registration.businessInfo.industry.placeholder')}
        options={INDUSTRY_TYPES.map((industry) => ({
          value: industry,
          label: t(`industry.${industry}`),
        }))}
        required
      />
      <FieldWrapper
        component={Select}
        fullWidth
        name="businessInfo.questionnaire.monthlyUsage"
        label={t('registration.businessInfo.monthlyUsage.label')}
        placeholder={t('registration.businessInfo.monthlyUsage.placeholder')}
        labelInfo={t('registration.businessInfo.monthlyUsage.info')}
        options={messagingVolume}
        required
      />
      <FieldWrapper
        component={Checkboxes}
        items={desiredIntegrations}
        name="businessInfo.questionnaire.integrations"
        label={t('registration.businessInfo.integrations.label')}
        columns={2}
        row
      />
    </>
  );
}

export default BusinessInfo;
