import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/joy';
import { Group } from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import Radios from '../../../../../../ui/molecules/Radios';
import GroupAutoComplete from '../ObjectAutoComplete/GroupAutoComplete';
import DynamicText from '../DynamicText';
import { ConfigFieldProps } from '../PropertyField';

interface DynamicGroupProps extends ConfigFieldProps<any> {}

function DynamicGroup({
  label,
  value,
  onChange,
  onHighlight,
  placeholder,
  disabled,
  required,
  helperText,
}: DynamicGroupProps) {
  const { t } = useTranslation('workflows');

  const items = [
    { label: t('groupName'), value: 'group', disabled },
    { label: t('Custom'), value: 'custom', disabled },
  ];

  return (
    <InputControl
      fullWidth
      label={label}
      required={required}
      helperText={helperText}
    >
      <Stack spacing={1} sx={{ width: '100%' }}>
        <Radios
          row
          size="sm"
          items={items}
          value={value.type || 'group'}
          onChange={(e) => {
            onChange({ type: e });
          }}
        />

        {value?.type === 'custom' ? (
          <Stack direction="row" spacing={1}>
            <DynamicText
              placeholder={placeholder}
              value={value.custom}
              onChange={(v) => {
                onChange({ type: 'custom', custom: v });
              }}
              disabled={disabled}
              onHighlight={onHighlight}
              fullWidth
            />
          </Stack>
        ) : (
          <GroupAutoComplete
            value={value?.group?.name ? value.group : ''}
            onChange={(val) => {
              onChange({
                type: 'group',
                group: {
                  id: (val as Group)?.id,
                  name: (val as Group)?.name,
                },
              });
            }}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
      </Stack>
    </InputControl>
  );
}

export default DynamicGroup;
