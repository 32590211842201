import React, { useContext } from 'react';
import {
  Button,
  Divider,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  ModalProps,
  Stack,
  Typography,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeValidate } from 'mui-rff';
import { ShortenedLink } from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import StatusAlert from '../../alerts/StatusAlert';
import { useCreateLinkMutation } from '../../../../api';
import FieldWrapper from '../../../../utils/FieldWrapper';
import TextField from '../../../atoms/inputs/TextField';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';
import URLInput from '../../../atoms/inputs/URLInput';
import { AccountContext } from '../../../../contexts/account.context';

interface CreateLinkDialogProps
  extends Omit<ModalProps, 'children' | 'onClose'> {
  hideClose?: boolean;
  onClose?: (result?: ShortenedLink) => void;
}

function CreateLinkDialog({ open, onClose, hideClose }: CreateLinkDialogProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const location = useLocation(); // TODO: determine if this is the best way to determine what CTA text to use
  const dispatch = useAppDispatch();

  const [createLink, { isLoading }] = useCreateLinkMutation();

  const handleSave = async (values: any) => {
    // TODO: review how to include contact tracking option
    await createLink(values)
      .unwrap()
      .then((result) => {
        if (result.success) {
          dispatch(
            showToast({
              message: t('links.createNew.success'),
              severity: 'success',
            }),
          );
          logger.info(result.data);
          if (onClose) {
            onClose(result.data);
          }
        } else {
          dispatch(
            showToast({
              message: t('links.createNew.error'),
              severity: 'error',
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          showToast({
            message: error.data.error.message
              ? error.data.error.message
              : t('links.createNew.error'),
            severity: 'error',
          }),
        );
      });
  };

  const schema = Yup.object().shape({
    name: Yup.string(),
    destinationUrl: Yup.string()
      .url(
        t('validation.isInvalid', {
          field: t('form.domain.label'),
        }),
      )
      .required(t('validation.required')),
  });

  return (
    <Modal open={open} onClose={() => onClose && onClose(undefined)}>
      <ModalDialog
        sx={{
          p: 0,
          width: 400,
        }}
      >
        {!hideClose && <ModalClose />}
        <Stack p={2}>
          <Typography level="title-md">{t('links.createNew.title')}</Typography>
        </Stack>
        <Divider />
        <Form validate={makeValidate(schema)} onSubmit={handleSave}>
          {({ handleSubmit, hasValidationErrors }) => (
            <form onSubmit={handleSubmit}>
              <Stack p={2} gap={2}>
                <FieldWrapper
                  component={TextField}
                  name="name"
                  label="Link Name"
                />
                <FieldWrapper
                  component={URLInput}
                  name="destinationUrl"
                  label="Destination URL"
                  infoText={t('form.domain.info')}
                  required
                />

                {!account?.options?.customDomain && (
                  <StatusAlert
                    status="info"
                    message={
                      <>
                        {t('links.createNew.info')}
                        <Link
                          fontWeight={500}
                          onClick={() => navigate('/settings/advanced')}
                          rel="noopener noreferrer"
                        >
                          {t('settings.title')}
                        </Link>
                      </>
                    }
                  >
                    <Link
                      level="body-md"
                      fontWeight={500}
                      href="https://support.sakari.io/hc/en-us/articles/4402725716756-Create-a-Custom-Domain-for-Shortened-URL-Links"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('learnMore')}
                    </Link>
                  </StatusAlert>
                )}
              </Stack>
              <Divider />
              <Stack
                sx={{
                  p: 2,
                  gap: 2,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Button variant="outlined" onClick={() => onClose && onClose()}>
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  loading={isLoading}
                  disabled={hasValidationErrors}
                >
                  {location.pathname.includes('inbox')
                    ? t('Save and Insert')
                    : t('save')}
                </Button>
              </Stack>
            </form>
          )}
        </Form>
      </ModalDialog>
    </Modal>
  );
}
export default CreateLinkDialog;
