import { Stack, Typography } from '@mui/joy';
import {
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Logo,
  NumberWithFlag,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import { Account, Condition } from '@sakari-io/sakari-typings';
import { useGetProviderNumbersQuery } from '../../../../../api';
import DataGrid from '../../../../../ui/molecules/DataGrid';
import EmptyResult from '../../../../../ui/molecules/EmptyResult';
import SearchableDataGrid from '../../../../../ui/organisms/datagrids/SearchableDataGrid';

function ImportNumbers({
  provider,
  isLoading,
  onSelect,
  account,
}: {
  provider: string;
  isLoading?: boolean;
  onSelect: (value: any) => void;
  account: Account;
}) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [rowSelectionModel, setSelectionModel] = useState<any[]>([]);
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);

  const handleSelection = (
    newSelectionModel: GridRowSelectionModel,
    attributes?: Condition,
    selectedAllRows?: boolean,
    selected?: any[],
  ) => {
    onSelect(selected);
    setSelectionModel(newSelectionModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'source',
      headerName: t('Integration'),
      width: 130,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const provider = params.row.source;
        return (
          <Stack direction="row" alignItems="center" gap={1}>
            <Logo size={30} provider={provider} />
            <Typography
              level="body-md"
              sx={{
                textTransform: 'capitalize',
              }}
            >
              {provider}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'number',
      headerName: t('Number'),
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <NumberWithFlag mobile={params.row.number} />
      ),
    },
    {
      field: 'error',
      headerName: t('Status'),
      flex: 2,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <SearchableDataGrid
      value={searchTerm}
      onChange={setSearchTerm}
      renderDataGrid={() => (
        <DataGrid
          columns={columns}
          filter={{
            accountId: account?.id || '',
            request: {
              provider,
              q: debouncedSearchTerm,
            },
          }}
          rowSelectionModel={rowSelectionModel}
          onSelectionModelChange={handleSelection}
          isRowSelectable={({ row }: { row: any }) =>
            row.available || isLoading
          }
          renderNoData={
            <EmptyResult
              heading={t('No phone numbers found')}
              item="phonenumbers"
            />
          }
          loadingText={t('loadingNumbers')}
          loadData={useGetProviderNumbersQuery}
          checkboxSelection
        />
      )}
    />
  );
}
export default ImportNumbers;
