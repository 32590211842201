import { Box, Typography } from '@mui/joy';
import React from 'react';

interface PageSectionProps {
  title?: string;
  padding?: number | string;
  parentPadding?: number | string;
  sx?: any;
  children?: React.ReactNode;
}

function PageSection({
  title,
  padding = 2,
  parentPadding,
  children,
  sx,
}: PageSectionProps) {
  return (
    <Box
      padding={padding}
      sx={{
        width: '100%',
        backgroundColor: 'white',
        border: `1px solid var(--joy-palette-neutral-outlinedBorder)`,
        borderRadius: '6px',
        boxShadow: '0px 1px 2px rgba(16, 24,40, 0.05)',
        ...sx,
      }}
    >
      <Box padding={parentPadding}>
        {title ? (
          <Typography
            sx={{
              fontWeight: 'bold',
              padding: !parentPadding ? '0px 0px 16px 0px' : '0px',
            }}
          >
            {title}
          </Typography>
        ) : null}
      </Box>

      {children}
    </Box>
  );
}

export default PageSection;
