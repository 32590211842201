import React, { useEffect, useState } from 'react';
import { Account, Contact } from '@sakari-io/sakari-typings';
import { getIntegrationIcon, Loader } from '@sakari-io/sakari-components';
import { motion } from 'framer-motion';
import { Typography, Button, Link, Stack, Box } from '@mui/joy';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import { useGetContactIntegrationPropertiesQuery } from '../../../../api';
import Accordion from '../../../templates/Accordion';
import IntegrationPropertyList from '../../Lists/IntegrationPropertyList';

import {
  AccountIntegrationProperty,
  IntegrationProperty as IntegrationPropertyItem,
  reorder,
} from './integrationProperty';

import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import Helper from '../../../../utils/helper';

import config from '../../../../config';

interface IntegrationProps {
  account: Account;
  contact: Contact;
  integration: any;
}

function Integration({ account, contact, integration }: IntegrationProps) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const INTEGRATION_KEY = 'INTEGRATION_KEY';
  const { data, isLoading } = useGetContactIntegrationPropertiesQuery({
    accountId: account.id,
    request: {
      id: contact.id,
      integration: integration.sourceId,
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [items, setItems] = useState<IntegrationPropertyItem[]>([]);
  const integrationProperties = localStorage.getItem(INTEGRATION_KEY) || '';
  const [localItems, setLocalItems] = useState<IntegrationPropertyItem[]>([]);

  useEffect(() => {
    if (data && data?.data) {
      const item = (
        integrationProperties
          ? (JSON.parse(integrationProperties) as AccountIntegrationProperty[])
          : []
      ).find((i) => i.accountId === Helper.getAccountId());

      const items: Array<IntegrationPropertyItem> = data.data.map((i: any) => {
        return {
          name: i.name,
          label: i.label,
          value: i.value,
          visible: true,
        };
      });
      if (item) {
        item.properties.forEach((i) => {
          const x = items.find(
            (a) => a.name.toLowerCase() === i.name.toLowerCase(),
          );
          if (x) {
            const startIndex = items.findIndex(
              (a) => a.name.toLowerCase() === i.name.toLowerCase(),
            );
            x.visible = i.visible;
            items.splice(startIndex, 1);
            items.splice(i?.position ? i.position : 0, 0, x);
          }
        });
      }

      setItems(items);
    }
  }, [data]);

  const onDragEnd: OnDragEndResponder = ({ destination, source }) => {
    if (!destination) return;

    const which = items[source.index];

    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);

    const localItemsCloned = _.cloneDeep(localItems);
    const localItem = localItemsCloned.find(
      (i) => i.name.toLowerCase() === which.name.toLowerCase(),
    );
    if (localItem) {
      localItem.position = destination.index;
    } else {
      localItemsCloned.push({
        name: which.name,
        position: destination.index,
        visible: which.visible,
      });
    }
    setLocalItems(localItemsCloned);
  };

  const onSave = () => {
    if (localItems.length === 0) {
      return;
    }

    const storageItems = integrationProperties
      ? (JSON.parse(integrationProperties) as AccountIntegrationProperty[])
      : [];

    const item = storageItems.find(
      (i) => i.accountId === Helper.getAccountId(),
    );
    if (item) {
      item.properties = _.uniqBy([...item.properties, ...localItems], 'name');
    } else {
      storageItems.push({
        accountId: Helper.getAccountId(),
        properties: localItems,
      });
    }

    localStorage.setItem(INTEGRATION_KEY, JSON.stringify(storageItems));

    dispatch(
      showToast({
        severity: 'info',
        message: 'Your changes have been saved',
      }),
    );
    setLocalItems([]);
  };

  const onVisibility = (item: IntegrationPropertyItem) => {
    const itemsCloned = _.cloneDeep(items);
    itemsCloned
      .filter(
        (x: IntegrationPropertyItem) =>
          x.name.toLowerCase() === item.name.toLowerCase(),
      )
      .forEach((i) => {
        i.visible = item.visible;
      });

    setItems(itemsCloned);

    const localItemsCloned = _.cloneDeep(localItems);
    const localItem = localItemsCloned.find(
      (i) => i.name.toLowerCase() === item.name.toLowerCase(),
    );
    if (localItem) {
      localItem.visible = item.visible;
      localItem.position = item.position;
    } else {
      localItemsCloned.push({
        name: item.name,
        position: item.position,
        visible: item.visible,
      });
    }

    setLocalItems(localItemsCloned);
  };

  // IntegrationProperty
  if (isLoading) return <Loader size={50} />;

  return (
    <Accordion
      isOpen={isOpen}
      setIsOpen={(state) => {
        setIsOpen(state);
      }}
      header={
        <Stack
          sx={{
            flex: 1,
            alignItems: 'center',
            flexFlow: 'row nowrap',
            gap: 2,
          }}
        >
          <img
            width="30"
            alt={integration.name}
            src={getIntegrationIcon(config.stage, integration.id, 128)}
            className="icon"
          />
          <Typography>{integration.name}</Typography>
          {isOpen && (
            <Box>
              {!isEditMode ? (
                <Button
                  size="sm"
                  variant="plain"
                  onClick={() => setIsEditMode(true)}
                >
                  {t('action.edit')}
                </Button>
              ) : (
                <Button
                  size="sm"
                  variant={localItems.length === 0 ? 'plain' : 'solid'}
                  color={localItems.length === 0 ? 'neutral' : 'primary'}
                  onClick={() => {
                    setIsEditMode(false);
                    onSave();
                  }}
                >
                  {localItems.length === 0
                    ? t('action.cancel')
                    : t('action.save')}
                </Button>
              )}
            </Box>
          )}
        </Stack>
      }
      content={
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0, transitionDelay: '0.2 sec' }}
          transition={{
            duration: 2,
            default: { ease: 'easeInOut' },
            delayChildren: 0.5,
          }}
        >
          <Link
            px={2}
            level="body-sm"
            href={
              // TODO: This should be done in the backend
              _.replace(
                integration.contactLink,
                '%%id%%',
                (data?.data as Array<any>)?.find((item) => item.name === 'vid')
                  ?.value || '',
              )
            }
            target="_blank"
          >
            View Contact in {_.capitalize(integration.sourceId)}
          </Link>
          <IntegrationPropertyList
            onVisibility={onVisibility}
            isEditMode={isEditMode}
            items={items}
            onDragEnd={onDragEnd}
            size="sm"
          />
        </motion.div>
      }
      variant="outlined"
      color="neutral"
    />
  );
}

export default Integration;
