import {
  InstalledIntegration,
  SakariAPIResponse,
  SearchablePaginationRequest,
  SyncUsersRequest,
  SyncUsersResponse,
  GoogleSheet,
  GoogleSheetColumn,
} from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { buildGridQuery, buildQuery, getAccountId } from './common';
import { AccountIdWith } from '../types';

const INTEGRATION_TAG = 'Integration';
const INTEGRATION_NUMBERS_TAG = 'IntegrationNumbers';

interface SyncUsersRequestWrapper {
  data: SyncUsersRequest;
  type: string;
}

interface ProviderNumbersRequest extends SearchablePaginationRequest {
  provider: string;
}

// TODO move to typings
export interface ExternalList {
  id: string;
  name: string;
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstalledIntegrations: builder.query<
      SakariAPIResponse<InstalledIntegration[]>,
      AccountIdWith<SearchablePaginationRequest | { features?: string }>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/integrations?${buildQuery(request)}`,
      providesTags: [INTEGRATION_TAG],
    }),
    getIntegrationProperties: builder.query<
      SakariAPIResponse<any>,
      AccountIdWith<string>
    >({
      query: ({ accountId, request: type }) =>
        `accounts/${accountId}/integrations/${type}`,
      providesTags: [INTEGRATION_TAG],
    }),
    connectIntegration: builder.mutation<SakariAPIResponse<any>, any>({
      query: ({ data, type }) => ({
        url: `accounts/${getAccountId()}/integrations/${type}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [INTEGRATION_TAG],
    }),
    disconnectIntegration: builder.mutation<SakariAPIResponse<any>, string>({
      query: (type) => ({
        url: `accounts/${getAccountId()}/integrations/${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: [INTEGRATION_TAG],
    }),
    updateIntegration: builder.mutation<SakariAPIResponse<any>, any>({
      query: ({ data, type }) => ({
        url: `accounts/${getAccountId()}/integrations/${type}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [INTEGRATION_TAG],
    }),
    getChannels: builder.query<SakariAPIResponse<any>, string>({
      query: (accountId) => `accounts/${accountId}/integrations/slack/channels`,
    }),
    getProviderNumbers: builder.query<
      SakariAPIResponse<any>,
      AccountIdWith<ProviderNumbersRequest>
    >({
      query: ({ accountId, request: { provider, ...query } }) =>
        `accounts/${accountId}/integrations/${provider}/numbers?${buildGridQuery(
          query,
        )}`,
      providesTags: [INTEGRATION_NUMBERS_TAG],
    }),
    syncUsers: builder.mutation<
      SakariAPIResponse<SyncUsersResponse>,
      SyncUsersRequestWrapper
    >({
      query: ({ data, type }) => ({
        url: `accounts/${getAccountId()}/integrations/${type}/synchronizeusers`,
        method: 'POST',
        body: data,
      }),
    }),
    getConnections: builder.query<SakariAPIResponse<any>, string>({
      query: (integration) => {
        const q = integration ? `?integration=${integration}` : '';
        return `profile/connections${q}`;
      },
    }),
    getGoogleSheet: builder.query<
      SakariAPIResponse<GoogleSheet>,
      AccountIdWith<{ sheetId: string; connectionId: string }>
    >({
      query: ({ accountId, request: { sheetId, connectionId } }) => {
        return `accounts/${accountId}/integrations/googlesheets/sheets/${sheetId}?connection=${connectionId}`;
      },
    }),
    getGoogleSheetColumns: builder.query<
      SakariAPIResponse<GoogleSheetColumn[]>,
      AccountIdWith<GoogleSheet>
    >({
      query: ({ accountId, request: { id, tab, connectionId } }) => {
        return `accounts/${accountId}/integrations/googlesheets/sheets/${id}/columns?connection=${connectionId}&tab=${tab?.name}`;
      },
    }),
    getHubSpotLists: builder.query<
      SakariAPIResponse<ExternalList[]>,
      AccountIdWith<SearchablePaginationRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/integrations/hubspot/lists?${buildGridQuery(
          request,
        )}`,
    }),
    deleteIntegrationUser: builder.mutation<
      SakariAPIResponse,
      { integrationId: string; integration: string }
    >({
      query: ({ integrationId }) => ({
        url: `profile/integrations/${integrationId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (
        { integration, integrationId },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          extendedApi.util.updateQueryData(
            'getConnections',
            integration,
            (draft) => {
              draft.data = draft.data.filter(
                (connection: any) => connection.id !== integrationId,
              );
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: INTEGRATION_TAG, id: arg.integrationId },
      ],
    }),
    getHubSpotInboxes: builder.query<SakariAPIResponse<any>, null>({
      query: () => `accounts/${getAccountId()}/integrations/hubspot/inboxes`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInstalledIntegrationsQuery,
  useGetIntegrationPropertiesQuery,
  useUpdateIntegrationMutation,
  useConnectIntegrationMutation,
  useDisconnectIntegrationMutation,
  useGetChannelsQuery,
  useGetProviderNumbersQuery,
  useSyncUsersMutation,
  useGetHubSpotListsQuery,
  useGetHubSpotInboxesQuery,
  useGetGoogleSheetQuery,
  useGetGoogleSheetColumnsQuery,
  useLazyGetGoogleSheetQuery,
  useGetConnectionsQuery,
  useDeleteIntegrationUserMutation,
} = extendedApi;
