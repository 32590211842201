import React, { useRef, useContext } from 'react';
import { Stack, Typography, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { Loader, logger } from '@sakari-io/sakari-components';
import DataTypeSelect from '../DataTypeSelect';
import {
  useGetAttributeQuery,
  useCreateAttributeMutation,
  useUpdateAttributeMutation,
} from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';
import FieldWrapper from '../../../../utils/FieldWrapper';
import TextField from '../../../../ui/atoms/inputs/TextField';
import AppDrawer from '../../../../ui/molecules/AppDrawer';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';

interface AttributeFormProps {
  open: boolean;
  onClose: () => any;
  type?: string;
}

function AttributeForm({ open, onClose, type }: AttributeFormProps) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { account } = useContext(AccountContext);

  const [createAttribute, { isLoading: isLoadingCreate }] =
    useCreateAttributeMutation();
  const [updateAttribute, { isLoading: isLoadingUpdate }] =
    useUpdateAttributeMutation();

  const { data, isLoading } = useGetAttributeQuery(
    {
      accountId: account?.id || '',
      request: {
        attributeId: params.attributeId || '',
        type: type || '',
      },
    },
    { skip: !params.attributeId },
  );

  const formSave = (values: any) => {
    // logger.info('custom attributes form save vals', values);

    if (!params.attributeId) {
      return createAttribute({ data: values, type })
        .unwrap()
        .then((res) => {
          if (res.success) {
            dispatch(
              showToast({
                severity: 'success',
                message: t('itemCreated', { item: res.data.label }),
              }),
            );
          }
          onClose();
        })
        .catch((err) => {
          dispatch(
            showToast({
              severity: 'error',
              message:
                err?.data?.error?.message ??
                t('unableToCreateItem', { item: t('items.attribute_one') }),
            }),
          );
        });
    }
    return updateAttribute({
      data: values,
      type,
      name: params.attributeId,
    })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((err) => {
        logger.info('err', err);
        dispatch(
          showToast({
            severity: 'error',
            message: 'Unable to update attribute',
          }),
        );
      });
  };

  const schema = Yup.object().shape({
    label: Yup.string().required(t('dataTypes.nameError')),
    dataType: Yup.string().required(t('dataTypes.dataTypeError')),
  });

  const validate = makeValidate(schema);

  return (
    <AppDrawer
      position="right"
      title={
        params.attributeId
          ? t('customAttributes.editAttribute')
          : t('customAttributes.addAttribute')
      }
      open={open}
      onClose={onClose}
    >
      <Form
        onSubmit={formSave}
        keepDirtyOnReinitialize
        validate={validate}
        initialValues={data?.data || { label: '', dataType: 'text' }}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }} ref={ref}>
            <Stack padding="35px" spacing={3}>
              {isLoading ? (
                <Stack
                  justifyContent="center"
                  height="calc(100vh - 140px)"
                  alignItems="center"
                >
                  <Loader size={100} label={t('customAttributes.loading')} />
                </Stack>
              ) : (
                <>
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      <Typography level="body-sm">
                        {t('customAttributes.attributeName')}
                      </Typography>

                      <FieldWrapper
                        component={TextField}
                        name="label"
                        placeholder={t('name')}
                      />
                      {/* <TextField name="label" placeholder="name lol"/> */}
                    </Stack>

                    <Stack spacing={1}>
                      <Typography level="body-sm">
                        {t('customAttributes.dataType')}
                      </Typography>

                      <FieldWrapper
                        component={DataTypeSelect}
                        name="dataType"
                        disabled={!!params.attributeId}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <Button
                      type="button"
                      color="neutral"
                      variant="outlined"
                      onClick={() => [
                        onClose(),
                        params.attributeId ? navigate(`/attributes`) : null,
                      ]}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      variant="solid"
                      color="primary"
                      type="submit"
                      disabled={
                        hasValidationErrors ||
                        isLoadingCreate ||
                        isLoadingUpdate
                      }
                    >
                      {t('save')}
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </form>
        )}
      />
    </AppDrawer>
  );
}

export default AttributeForm;
