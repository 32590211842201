/* eslint-disable @typescript-eslint/no-unused-vars */
import Select, {
  SelectProps as SelectBaseProps,
  selectClasses,
} from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React, { ReactNode, useState } from 'react';
import { InputControl } from '@sakari-io/sakari-components';

type SelectPropsBase<T> = SelectBaseProps<any, any, any>;

export interface Option {
  label: string;
  value: any;
}

export interface StyledSelectProps<T>
  extends Omit<SelectPropsBase<T>, 'onChange'> {
  label?: string;
  helperText?: string;
  error?: boolean;
  errorText?: string;
  onChange: (value: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  options: T[] | string[];
  orientation?: 'horizontal' | 'vertical';
  disabled?: boolean;
  optionLabel?: string;
  renderOption?: (value: any) => any;
  labelInfo?: string;
  noGap?: boolean;
  endDecorator?: ReactNode;
}

function StyledSelect<T extends Option>({
  label,
  helperText,
  error,
  errorText,
  onChange,
  required,
  options,
  fullWidth,
  orientation,
  sx,
  size,
  disabled,
  children,
  optionLabel,
  renderOption,
  labelInfo,
  noGap,
  endDecorator,
  ...rest
}: StyledSelectProps<T>) {
  const [open, isOpen] = useState(false);
  const ControlProps = {
    label,
    fullWidth,
    required,
    error,
    helperText,
    orientation,
    size,
    disabled,
    labelInfo,
    noGap,
  };

  return (
    <InputControl {...ControlProps}>
      <Select
        disabled={disabled}
        onChange={(e: any, newValue: any) => onChange(newValue)}
        indicator={
          <FontAwesomeIcon
            icon={regular('chevron-down')}
            onClick={() => isOpen((prev) => !prev)}
          />
        }
        endDecorator={endDecorator}
        listboxOpen={open}
        onListboxOpenChange={(state) => setTimeout(() => isOpen(state), 1)}
        sx={{
          [`& .${selectClasses.indicator}`]: {
            transition: '0.2s',
            [`&.${selectClasses.expanded}`]: {
              transform: 'rotate(-180deg)',
            },
          },
          [`& .${selectClasses.button}`]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          ...sx,
        }}
        color="neutral"
        value={rest.value}
        slotProps={{
          listbox: {
            component: 'div',
            sx: {
              maxHeight: 240,
              overflowY: 'overlay',
            },
          },
        }}
        {...rest}
      >
        {options.map((option: T | string) =>
          typeof option === 'string' ? (
            <Option key={option} value={option} label={option}>
              {renderOption ? renderOption(option) : option}
            </Option>
          ) : (
            <Option
              key={option.value}
              value={option.value}
              label={option.label}
            >
              {renderOption ? renderOption(option) : option.label}
            </Option>
          ),
        )}
        {children}
      </Select>
    </InputControl>
  );
}

export default StyledSelect;
