import React, { useContext, useState, useEffect } from 'react';
import { AccountAttribute } from '@sakari-io/sakari-typings';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Chip, Stack } from '@mui/joy';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Tooltip,
  Autocomplete,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import { AccountContext } from '../../../../contexts/account.context';
import { useGetAttributesByTypeQuery } from '../../../../api';
import { AutocompleteProps } from '../../../clonedfromcomponents';

const iconMap = {
  text: regular('text'),
  number: regular('hashtag'),
  date: regular('calendar'),
  // boolean: regular('check-square'),
  datetime: regular('calendar-clock'),
} as { [key: string]: any };

interface AttributeSelectProps
  extends Omit<AutocompleteProps<AccountAttribute | undefined>, 'options'> {
  type: AccountAttribute['type'];
  otherAttributes?: AccountAttribute[];
  overrideAttributes?: AccountAttribute[];
  value: AccountAttribute | undefined;
}

function AttributeSelect({
  type,
  otherAttributes,
  overrideAttributes,
  value,
  disabled,
  ...rest
}: AttributeSelectProps): JSX.Element {
  const { account } = useContext(AccountContext);
  const [input, setInput] = useState('');
  const debouncedInput = useDebouncedValue(input, 300);

  useEffect(() => {
    if (value) {
      setInput(value.label);
    }
  }, [value]);

  const { data: attributes } = useGetAttributesByTypeQuery(
    account?.id && !overrideAttributes?.length
      ? {
          accountId: account.id,
          request: {
            type,
            q: debouncedInput,
          },
        }
      : skipToken,
  );

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      isOptionEqualToValue={(option: any, val: any) => option?.id === val?.id}
      renderOption={(props: any, option: any) => (
        <AutocompleteOption {...props} key={option?.id}>
          <Stack
            sx={{
              flex: 1,
              flexFlow: 'row nowrap',
              justifyContent: 'space-between',
            }}
          >
            {option?.label}
            {option?.dataType && (
              <Tooltip title={option?.dataType} placement="left">
                <Chip
                  sx={{
                    alignSelf: 'flex-end',
                  }}
                >
                  <FontAwesomeIcon icon={iconMap[option?.dataType]} />
                </Chip>
              </Tooltip>
            )}
          </Stack>
        </AutocompleteOption>
      )}
      options={
        overrideAttributes ?? [
          ...(otherAttributes ?? []),
          ...(attributes?.data.map((attr) => ({
            ...attr,
            name: `attributes.${attr.name}`,
          })) ?? []),
        ]
      }
      inputValue={input}
      onInputChange={(e: any, val: any) => setInput(val)}
      onOpen={() => setInput('')}
      {...rest}
    />
  );
}
export default AttributeSelect;
