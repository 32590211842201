import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Contact, CreateConversation, Group } from '@sakari-io/sakari-typings';
import { addYears } from 'date-fns';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPopupProps } from '../../../templates/OverlayPopup';
import GroupMenuButton from '../GroupMenuButton';

interface MessageMenuButtonProps
  extends Omit<
    OverlayPopupProps,
    'open' | 'onOpenClose' | 'renderButton' | 'renderPopup'
  > {
  accountId: string;
  contact: Contact;
  icon?: ReactNode;
  disabled?: boolean;
  limit?: number;
}

function MessageMenuButton({
  accountId,
  contact,
  icon,
  disabled,
  limit = 5,
  ...rest
}: MessageMenuButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sendMessage = (group: Group) => {
    const conversation: CreateConversation = {
      id: 'draft',
      contact,
      group,
      lastMessage: {
        id: '',
        status: 'new',
        created: {
          at: new Date().toString(),
          by: {
            name: '',
          },
        },
      },
      closed: addYears(new Date(), 1).toISOString(),
    };
    navigate(`/inbox/${group.id}`, { state: { conversation } });
  };

  return (
    <GroupMenuButton
      accountId={accountId}
      contact={contact}
      icon={<FontAwesomeIcon icon={solid('comment-dots')} />}
      disabled={disabled}
      limit={limit}
      onSelectGroup={(group) => {
        sendMessage(group);
      }}
      tooltip={t('message')}
      {...rest}
    />
  );
}

export default MessageMenuButton;
