import {
  Box,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
  Button,
} from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Stepper from '../../molecules/Stepper';

interface Step {
  title?: string;
  content?: any;
  description?: string;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
}

interface LightboxProps {
  open?: boolean;
  onOpenClose: (state: boolean) => void;
  onClose?: () => void;
  back?: boolean;
  next?: boolean;
  steps: Step[];
  currentStep: number;
  setCurrentStep: (state: number) => void;
}

function Lightbox({
  open,
  onOpenClose,
  onClose,
  back,
  next,
  steps,
  setCurrentStep,
  currentStep,
}: LightboxProps) {
  const [stepObject, setStepObject] = useState<Step | null>(null);
  const { title, description, content, actionButtonText, onActionButtonClick } =
    stepObject || {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showSlide, setShowSlide] = useState(false);

  useEffect(() => {
    setShowSlide(false);
    setStepObject(steps[currentStep]);
    setShowSlide(true);
  }, [currentStep]);

  const handleClose = () => {
    onOpenClose(false);
    if (onClose) {
      onClose();
    }
    setCurrentStep(0);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal open={open || false} onClose={handleClose}>
      <ModalDialog size="md">
        <ModalClose />
        <Stack
          spacing={3}
          alignItems="center"
          padding={2}
          width={{
            xs: 500,
            sm: 600,
            md: 700,
          }}
        >
          {title && (
            <Typography level="h4" sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          )}

          {description && (
            <Typography level="body-md" textColor="text.secondary">
              {description}
            </Typography>
          )}
          {content && (
            <AnimatePresence>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                {content}
              </motion.div>
            </AnimatePresence>
          )}

          <Stack direction="row" spacing={2} paddingX={2}>
            {back && (
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => handleBack()}
                disabled={currentStep === 0}
              >
                Back
              </Button>
            )}

            {next && steps?.length - 1 > currentStep && (
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => handleNext()}
              >
                Next
              </Button>
            )}

            {actionButtonText && onActionButtonClick && (
              <Button onClick={() => onActionButtonClick()}>
                {actionButtonText}
              </Button>
            )}
          </Stack>
          <Box width={200}>
            <Stepper
              steps={steps.map((s) => ({
                id: s.title || '',
                title: s.title || '',
              }))}
              activeStep={currentStep}
              size="sm"
            />
          </Box>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default Lightbox;
