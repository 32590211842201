import React, { useState } from 'react';
// import Carousel from 'react-material-ui-carousel';
import { Account, AvailableIntegration, User } from '@sakari-io/sakari-typings';
import { useNavigate } from 'react-router-dom';
import { getIntegrationIcon, logger } from '@sakari-io/sakari-components';
import { Stack, Typography, AspectRatio, Button } from '@mui/joy';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import _ from 'lodash';
import IntegrationCredentialsDialog from '../IntegrationCredentialsDialog';
import Helper from '../../../../utils/helper';
import { useGetInstalledIntegrationsQuery } from '../../../../api';
import config from '../../../../config';

const replaceVariables = (url: string, properties: any) => {
  logger.info('replace', url, properties);
  _.keys(properties).forEach((key) => {
    url = url.replace(`%${key}%`, encodeURIComponent(properties[key]));
  });

  return url;
};

// const mediaItems: { type: React.ElementType<any>; url: string }[] = [
//   {
//     type: 'img',
//     url: 'https://www.hubspot.com/hubfs/SUI-Homepage-Header-Desktop@2x.png',
//   },
//   {
//     type: 'img',
//     url: 'https://www.hubspot.com/hubfs/2022-gartner-magic-quadrant-final-graphic.png',
//   },
//   {
//     type: 'img',
//     url: 'https://blog.hubspot.com/hubfs/youtube%20tags.jpg',
//   },
// ];

interface NativeIntegrationDetailsDialogProps {
  account: Account;
  integration: AvailableIntegration;
  user: User;
}

function NativeIntegrationDetailsDialog({
  account,
  integration,
  user,
}: NativeIntegrationDetailsDialogProps) {
  const { data: integrationData, isFetching } =
    useGetInstalledIntegrationsQuery(
      account
        ? {
            accountId: account.id,
            request: {},
          }
        : skipToken,
    );
  const navigate = useNavigate();

  const [showCredentials, setShowCredentials] = useState(false);

  const checkIntegrationStatus = () => {
    if (integrationData?.data) {
      for (let i = 0; i < integrationData?.data.length; i++) {
        const item = integrationData?.data[i];
        if (item.name.toLowerCase() === integration.name.toLowerCase()) {
          return 'Installed';
        }
      }
      return 'Add';
    }
    return '';
  };

  const handleAddCheckIntegrationStatus = () => {
    if (integration?.authentication?.type === 'parameters') {
      setShowCredentials(true);

      return;
    }

    const url = (integration.authentication?.url || '')
      .replace(/%ACCOUNT_ID%/g, Helper.getAccountId())
      .replace(/%USER_ID%/g, user.id || '');

    window.open(replaceVariables(url, config.integrationProperties));
  };

  return (
    <>
      <Stack gap={3} maxWidth={496} padding={1}>
        <Stack direction="row" gap={3}>
          <AspectRatio
            variant="outlined"
            ratio={1}
            sx={{
              width: '132px',
              height: '132px',
              borderRadius: '20px',
            }}
          >
            <img
              src={getIntegrationIcon(config.stage, integration.id, 128)}
              alt={integration.name}
              style={{ objectFit: 'cover' }}
            />
          </AspectRatio>
          <Typography level="title-lg" sx={{ fontWeight: 700, my: 'auto' }}>
            {integration.name}
          </Typography>
        </Stack>

        <Button
          loading={isFetching}
          onClick={() =>
            checkIntegrationStatus() === 'Add'
              ? handleAddCheckIntegrationStatus()
              : navigate(`/integrations/${integration.name.toLowerCase()}`)
          }
          fullWidth
        >
          {checkIntegrationStatus()}
        </Button>
        {/* <Carousel
            navButtonsAlwaysVisible={true}
            animation="slide"
            indicators={false}
          >
            {mediaItems.map((item, i) => (
              <AspectRatio
                variant="outlined"
                sx={{ maxWidth: '100%', borderRadius: '20px' }}
              >
                <img
                  src={item.url}
                  alt={`${integration.name}-${i}`}
                  style={{ objectFit: 'cover' }}
                />
              </AspectRatio>
            ))}
          </Carousel> */}
        <Typography
          level="body-md"
          textColor="text.tertiary"
          sx={{ fontWeight: 400 }}
        >
          {integration.description}
        </Typography>
      </Stack>
      <IntegrationCredentialsDialog
        open={showCredentials}
        onHide={() => setShowCredentials(false)}
        integration={integration}
      />
    </>
  );
}

export default NativeIntegrationDetailsDialog;
