/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import {
  PaginationRequest,
  SakariAPIResponse,
  Conversation,
} from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { sakariApi } from './rtk';
import {
  updateQueryData as updateMessageQueryData,
  selectInvalidatedBy as selectMessagesInvalidatedBy,
} from './messages';
import { getAccountId, buildQuery } from './common';
import { CONVERSATION_TAG, MESSAGE_TAG } from './tags';
import { AccountIdWith } from '../types';
import { LIMIT } from '../ui/organisms/drawers/ConversationsDrawer';
import getSocket from '../utils/socket-connection';
import sec from '../security';
import config from '../config';

const processMessage = (data: any, draft: any) => {
  const messageConvo = data?.conversation?.id;
  const found = draft.data?.find((c: Conversation) => c.id === messageConvo);

  if (found) {
    found.lastMessage = data;

    if (!data.outgoing) {
      const unreadMsgs = [...(found?.unread ?? []), data.id];
      found.unread = _.uniq(unreadMsgs);
    }
    found.lastMessage = data;
  } else {
    const updatedConvo = {
      ...data.conversation,
      contact: data.contact,
      lastMessage: data,
    };
    draft.data.unshift(updatedConvo);
  }
};

const invalidateConversationList = (result: any, error: any, id: any) => [
  `${CONVERSATION_TAG}-${id}`,
  {
    type: CONVERSATION_TAG,
    id: 'LIST',
  },
];

export const getConversationKey = (
  accountId?: string,
  groupId?: string,
  blocked = false,
  closed = false,
  offset = 0,
) => {
  return {
    accountId: accountId || '',
    request: {
      blocked: false,
      closed: false,
      group: groupId || '',
      offset: 0,
      limit: LIMIT,
    },
  };
};

interface GetConversationsRequest extends PaginationRequest {
  closed?: boolean;
  q?: string;
  group?: string;
  blocked?: boolean;
  contactId?: string;
}

export const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    getConversations: builder.query<
      SakariAPIResponse<Conversation[]>,
      AccountIdWith<GetConversationsRequest>
    >({
      query: ({ accountId, request }) =>
        `accounts/${accountId}/conversations?${buildQuery(request)}`,
      providesTags: (result) => [
        CONVERSATION_TAG,
        { type: CONVERSATION_TAG, id: 'LIST' },
        ...(result?.data || []).map((c) => `${CONVERSATION_TAG}-${c.id}`),
      ],
      keepUnusedDataFor: 0,
      onCacheEntryAdded: async (
        { request },
        {
          dispatch,
          cacheDataLoaded,
          updateCachedData,
          getState,
          cacheEntryRemoved,
        },
      ) => {
        const token = await sec.getAccessTokenSilently();
        const socket = getSocket(config.websocketUrl, token);

        const handleReceivedMessage = (data: any) => {
          logger.info('message-received', data);

          selectMessagesInvalidatedBy(getState(), [MESSAGE_TAG]).forEach(
            ({ endpointName, originalArgs, queryCacheKey }) => {
              const cacheConvoId = originalArgs.request.conversationId;
              const { offset } = originalArgs.request;
              if (
                endpointName === 'getMessages' &&
                data.conversation.id === cacheConvoId &&
                offset === 0
              ) {
                dispatch(
                  updateMessageQueryData(
                    endpointName,
                    originalArgs,
                    (draft) => {
                      draft.data.push(data);
                    },
                  ),
                );
              }
            },
          );

          updateCachedData((draft) => {
            processMessage(data, draft);
          });
        };

        const handleSentMessage = (data: any) => {
          logger.info('message-sent', data);
          selectMessagesInvalidatedBy(getState(), [MESSAGE_TAG]).forEach(
            ({ endpointName, originalArgs, queryCacheKey }) => {
              const cacheConvoId = originalArgs.request.conversationId;
              const { offset } = originalArgs.request;
              if (
                endpointName === 'getMessages' &&
                data.conversation.id === cacheConvoId
              ) {
                dispatch(
                  updateMessageQueryData(
                    endpointName,
                    originalArgs,
                    (draft) => {
                      const index = draft.data?.findIndex(
                        (m) => m.id === data?.conversation.id,
                      );
                      if (index <= 0) {
                        if (offset === 0) {
                          draft.data.push(data);
                        }
                      } else {
                        draft.data[index] = {
                          ...draft.data[index],
                          updated: {
                            ...draft.data[index].updated,
                            at: new Date().toISOString(),
                          },
                        };
                      }
                    },
                  ),
                );
              }
            },
          );

          if (data.group.id === request.group) {
            updateCachedData((draft) => {
              processMessage(data, draft);
            });
          }
        };

        const handleUpdateMessageStatus = (data: any) => {
          logger.info('message-status', data);
          selectMessagesInvalidatedBy(getState(), [
            MESSAGE_TAG,
            `${MESSAGE_TAG}-${data.id}`,
          ]).forEach(({ endpointName, originalArgs, queryCacheKey }) => {
            if (endpointName === 'getMessages') {
              dispatch(
                updateMessageQueryData(endpointName, originalArgs, (draft) => {
                  const index = draft.data?.findIndex((m) => m.id === data.id);
                  if (index >= 0) {
                    draft.data[index] = {
                      ...draft.data[index],
                      status: data.status,
                      updated: {
                        ...draft.data[index].updated,
                        at: new Date().toISOString(),
                      },
                    };
                  }
                }),
              );
            }
          });
        };

        if (socket) {
          socket.connect();
          try {
            await cacheDataLoaded;
            logger.info('creating socket listeners');

            socket.on('message-received', handleReceivedMessage);
            socket.on('message-sent', handleSentMessage);
            socket.on('message-status', handleUpdateMessageStatus);

            await cacheEntryRemoved;
            logger.info('cacheEntry removed');
            socket.off('message-received', handleReceivedMessage);
            socket.off('message-sent', handleSentMessage);
            socket.off('message-status', handleUpdateMessageStatus);
          } catch (err) {
            logger.info('error creating socket listeners', err);
          }
        }
      },
    }),
    archiveConversation: builder.mutation<
      SakariAPIResponse<Conversation>,
      Conversation
    >({
      query: (conversation) => ({
        url: `accounts/${getAccountId()}/conversations/${
          conversation.id
        }/close`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: invalidateConversationList,
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const key = getConversationKey(
          getAccountId() || '',
          request.group?.id,
          false,
          false,
          0,
        );
        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getConversations', key, (draft) => {
            draft.data = draft.data.filter((c) => c.id !== request.id);
          }),
        );

        try {
          await queryFulfilled;
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
    hideConversation: builder.mutation<
      SakariAPIResponse<Conversation>,
      Conversation
    >({
      query: (conversation) => ({
        url: `accounts/${getAccountId()}/conversations/${conversation.id}/hide`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: invalidateConversationList,
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const key = getConversationKey(getAccountId(), request.group?.id);
        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getConversations', key, (draft) => {
            draft.data = draft.data.map((c) => {
              if (c.id === request.id && c.contact) {
                c.contact.blocked = new Date().toISOString();
              }

              return c;
            });
          }),
        );

        try {
          const { data } = await queryFulfilled;
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
    markConversationAsUnread: builder.mutation<
      SakariAPIResponse<Conversation>,
      Conversation
    >({
      query: (conversation) => ({
        url: `accounts/${getAccountId()}/conversations/${
          conversation.id
        }/unread`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: invalidateConversationList,
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const key = getConversationKey(getAccountId(), request.group?.id);
        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getConversations', key, (draft) => {
            draft.data = draft.data.map((c) => {
              if (c.id === request.id) {
                c.unread = ['unread'];
              }

              return c;
            });
          }),
        );

        try {
          const { data } = await queryFulfilled;
        } catch (err) {
          patchResult.undo();
        }
      },
    }),

    markConversationAsRead: builder.mutation<
      SakariAPIResponse<Conversation>,
      Conversation
    >({
      query: (conversation) => ({
        url: `accounts/${getAccountId()}/conversations/${conversation.id}/read`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: invalidateConversationList,
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const key = getConversationKey(getAccountId(), request.group?.id);
        const patchResult = dispatch(
          extendedApi.util.updateQueryData('getConversations', key, (draft) => {
            draft.data = draft.data.map((c) => {
              if (c.id === request.id) {
                c.unread = [];
              }

              return c;
            });
          }),
        );

        try {
          const { data } = await queryFulfilled;
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetConversationsQuery,
  useArchiveConversationMutation,
  useHideConversationMutation,
  useMarkConversationAsUnreadMutation,
  useMarkConversationAsReadMutation,
  util: { updateQueryData, selectInvalidatedBy },
} = extendedApi;
