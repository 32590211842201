import React from 'react';
import { Account, List } from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/query';
import { ObjectAutoComplete } from '@sakari-io/sakari-components';
import { useGetListsQuery } from '../../../../../../api';

interface ListAutoCompleteProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  value?: List;
  onChange: (value?: List) => void;
  disabled?: boolean;
  account: Account;
}

function ListAutoComplete({
  label,
  placeholder,
  helperText,
  value,
  onChange,
  disabled,
  account,
}: ListAutoCompleteProps) {
  return (
    <ObjectAutoComplete
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      fetch={useGetListsQuery}
      query={(input: string) =>
        account
          ? {
              accountId: account.id,
              request: {
                limit: 10,
                q: input,
              },
            }
          : skipToken
      }
      value={value}
      onChange={onChange}
      disabled={disabled}
      getOptionLabel={(o: any) => (o as any).name || ''}
      multiple={false}
    />
  );
}

export default ListAutoComplete;
