import { Plan } from '@sakari-io/sakari-typings';

const getSegmentPrice = (plan: Plan, country: string) => {
  return (plan.pricing[country] || plan.pricing.DEFAULT)?.sms?.outbound;
};

// const combinePrice = (lineType: LineTypeMetaData) => {
//   const { price, setup, regulatory } = lineType.prices;
//   return {
//     min: (price || 0) + (regulatory?.min || 0),
//     max: (price || 0) + (regulatory?.max || 0),
//     setup: (setup || 0) + (regulatory?.setup || 0),
//   };
// };

export default getSegmentPrice;
