import React, { useState } from 'react';

import {
  GridColDef,
  GridCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';

import { Account, Role, User } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Typography, Stack, IconButton, Box } from '@mui/joy';
import {
  Avatar,
  logger,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import { format } from '@sakari-io/sakari-common';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  useGetUsersQuery,
  useUpdateUserMutation,
  useRemoveUserMutation,
  useGetUserProfileQuery,
} from '../../../../api';
import { showToast } from '../../../../redux/reducers/toast';
import { useAppDispatch } from '../../../../redux';

import DataGrid from '../../../molecules/DataGrid';
import RoleSelect from '../../forms/RoleSelect';
import RemoveUserDialog from '../../Dialogs/RemoveUserDialog';
import EmptyResult from '../../../molecules/EmptyResult';
import SearchableDataGrid from '../SearchableDataGrid';

function UsersGrid({ account }: { account: Account }) {
  const [updating, setUpdating] = useState<boolean>(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebouncedValue(query, 500);

  const [selected, setSelected] = useState<Partial<User> & Pick<User, 'id'>>({
    id: '',
  });

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { data } = useGetUserProfileQuery();
  const [updateUser] = useUpdateUserMutation();
  const [removeUser] = useRemoveUserMutation();

  const isActiveUser = (id: string) => data?.data?.id === id;

  const handleUpdateRole = (id: string) => async (role: Role) => {
    logger.info(`update id: ${id} update role: ${role}`);
    const updated = await updateUser({ id, role }).unwrap();
    dispatch(
      showToast({
        severity: updated.success ? 'info' : 'error',
        message: updated.success
          ? t('users.update.success')
          : t('users.update.error'),
      }),
    );
  };

  const openRemoveDialog = (user: User) => () => {
    setSelected(user);
    setShowRemoveDialog(true);
  };

  const closeRemoveDialog = () => {
    setShowRemoveDialog(false);
    setSelected({ id: '' });
  };

  const handleRemove = async (id: string) => {
    setUpdating(true);
    const removed = await removeUser(id).unwrap();
    setUpdating(false);
    return removed;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('users.grid.headings.name'),
      minWidth: 200,
      flex: 1,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar person={params.row} />
            <Stack>
              <Typography level="body-md" fontWeight="medium">
                {format.name(params.row)}
              </Typography>
              <Typography level="body-md">{params.row.email}</Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'role',
      headerName: t('users.grid.headings.role'),
      width: 250,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const isActive = isActiveUser(params.row.id || '');
        const SelectionBox = (
          <RoleSelect
            value={params.row.role}
            onChange={handleUpdateRole(params.row.id || '')}
            disabled={updating || isActive}
          />
        );

        if (isActive) {
          return (
            <Tooltip
              title={"Cannot update your own user's role"}
              arrow
              placement="top"
            >
              <Box>{SelectionBox}</Box>
            </Tooltip>
          );
        }

        return SelectionBox;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      cellClassName: 'actions',
      getActions: (params: GridRowParams) => {
        const actions = [
          <Tooltip
            title={
              isActiveUser(params.row.id)
                ? 'Cannot remove your own user'
                : 'Remove'
            }
            arrow
            placement="top"
          >
            <Box>
              <IconButton
                size="smRound"
                variant="plain"
                color="danger"
                onClick={openRemoveDialog(params.row)}
                disabled={
                  updating ||
                  selected.id === params.row.id ||
                  isActiveUser(params.row.id || '')
                }
              >
                <FontAwesomeIcon icon={faXmark} />
              </IconButton>
            </Box>
          </Tooltip>,
        ];

        return actions;
      },
    },
  ];

  return (
    <>
      <SearchableDataGrid
        value={query}
        onChange={(val) => setQuery(val)}
        renderDataGrid={() => (
          <DataGrid
            columns={columns}
            filter={{
              accountId: account.id,
              request: {
                q: debouncedQuery,
              },
            }}
            loadData={useGetUsersQuery}
            renderNoData={
              <EmptyResult
                heading={t('noneFound', {
                  items: t('items.user_other'),
                })}
              />
            }
            disableRowSelectionOnClick
            loadingText="Loading users..."
          />
        )}
      />
      <RemoveUserDialog
        open={showRemoveDialog}
        onClose={closeRemoveDialog}
        user={selected}
        handleRemove={handleRemove}
      />
    </>
  );
}

export default UsersGrid;
