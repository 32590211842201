import React from 'react';
import { Card, Typography } from '@mui/joy';

interface CustomTooltipProps {
  props: any;
  showTooltip: number;
  formatDate: (date: Date) => string;
}

function CustomTooltip({ props, showTooltip, formatDate }: CustomTooltipProps) {
  const { active, payload, label } = props;

  if (showTooltip >= 0 && active && payload) {
    const value = payload[showTooltip].value as number;
    return (
      <Card
        sx={{
          backgroundColor: payload[showTooltip].color,
          width: 'max-content',
          display: 'flex',
          flexFlow: 'row nowrap',
          gap: '0.5rem',
          verticalAlign: 'center',
        }}
        size="sm"
      >
        <Typography
          textColor="white"
          sx={{
            opacity: 0.8,
          }}
        >
          {formatDate(label)}
        </Typography>

        <Typography fontWeight={700} textColor="white">
          {Math.floor(value)}
        </Typography>
      </Card>
    );
  }
  return null;
}

export default CustomTooltip;
