import React from 'react';
import { IconButton, Box } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

interface DeleteHoverButtonProps {
  onClick: (e: any) => void;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  ignorePosition?: boolean;
  sx?: object;
}

function DeleteHoverButton({
  onClick,
  disabled,
  size,
  ignorePosition,
  sx,
}: DeleteHoverButtonProps): JSX.Element {
  const position = ignorePosition
    ? {}
    : { position: 'absolute', bottom: 0, right: 0 };
  return (
    <Box sx={{ position, ...sx }}>
      <IconButton
        variant="outlined"
        size={size || 'sm'}
        onClick={onClick}
        disabled={disabled}
        sx={{
          borderRadius: '6px',
          backgroundColor: 'var(--joy-palette-common-white)',
          zIndex: 10,
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    </Box>
  );
}

export default DeleteHoverButton;
