import React from 'react';
import { IconButton, Link, LinkProps } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

interface TextLinkProps extends LinkProps {
  label?: string;
  hideIcon?: boolean;
}

function TextLink({ hideIcon, label, href, sx, ...rest }: TextLinkProps) {
  if (!href) return null;
  return (
    <Link
      sx={{
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: 'text.secondary',
        ...(hideIcon && {
          '.MuiLink-endDecorator': { opacity: 0 },
          '&:hover': {
            color: 'text.secondary',

            '.MuiLink-endDecorator': { opacity: 1 },
          },
        }),
        ...sx,
      }}
      title={href}
      endDecorator={
        <IconButton size="xs" component="a" href={href} target="_blank">
          <FontAwesomeIcon icon={regular('arrow-up-right-from-square')} />
        </IconButton>
      }
      target="_blank"
      href={href}
      {...rest}
    >
      {label || href}
    </Link>
  );
}
export default TextLink;
