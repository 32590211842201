import React from 'react';
import { Grid, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../../../ui';
import FieldWrapper from '../../../../../../utils/FieldWrapper';
import AddressAutocomplete from '../../../../../../ui/organisms/forms/AddressAutocomplete/index';

function LOAForm({ name }: { name: string }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <FieldWrapper
            component={TextField}
            name={`${name}.businessName`}
            label={t('addSenders.phonenumber.byo.signLOA.businessName.label')}
            required
          />
        </Grid>
        <Grid xs={12}>
          <FieldWrapper
            component={AddressAutocomplete}
            name={`${name}.address`}
            label={t('form.address.label')}
            required
          />
        </Grid>

        <Grid xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            {t('addSenders.phonenumber.byo.signLOA.authPerson.label')}
          </Typography>
        </Grid>
        <Grid xs={6}>
          <FieldWrapper
            component={TextField}
            name={`${name}.authPerson.firstName`}
            label={t('form.name.first')}
            required
          />
        </Grid>
        <Grid xs={6}>
          <FieldWrapper
            component={TextField}
            name={`${name}.authPerson.lastName`}
            label={t('form.name.last')}
            required
          />
        </Grid>
        <Grid xs={12}>
          <FieldWrapper
            component={TextField}
            name={`${name}.authPerson.email`}
            type="email"
            label={t('form.email.label')}
            required
          />
        </Grid>
      </Grid>
      {/* <Button
        disabled={!canSave}
        onClick={() => onSubmit()}
        fullWidth
        sx={{ maxWidth: '400px' }}
      >
        Generate LOA
      </Button> */}
    </>
  );
}
export default LOAForm;
