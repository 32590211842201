import React from 'react';
import { Avatar, Chip, Stack, Typography } from '@mui/joy';
import { MD5 } from 'crypto-js';
import { format } from '@sakari-io/sakari-common';
import Helper from '../../../utils/helper';
import ReturnCallButton from '../ReturnCallButton';

interface DetailsProps {
  call: any;
}

function Details({ call }: DetailsProps) {
  const callDetails = call.data;
  const { contact, user } = callDetails;

  const email = user?.email;
  const type = '404';
  const size = 40;
  const hashEmail = email ? MD5(email).toString() : null;
  const initials = Helper.getNameInitials(user || {});

  return (
    <Stack spacing={0.3}>
      <Typography level="body-sm">
        {Helper.dateFormatterDate(callDetails.created.at)}{' '}
        {Helper.dateFormatterTime(callDetails.created.at)}
      </Typography>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Typography level="body-sm" fontWeight={500}>
          {Helper.capitalize(callDetails.direction)} -{' '}
          {Helper.capitalize(callDetails.status)}
        </Typography>
        <Chip size="md" variant="soft" color="primary">
          {callDetails.group.name}
        </Chip>
      </Stack>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <Typography level="title-lg">
          {contact?.firstName || contact?.lastName
            ? `${contact?.firstName} ${contact?.lastName}`
            : contact?.mobile || ''}
        </Typography>
        <ReturnCallButton call={callDetails} />
      </Stack>
      <Typography level="body-md" fontWeight={500}>
        {Helper.getDuration(callDetails.duration)}
      </Typography>
      {user && (
        <Stack direction="row">
          <Chip
            size="md"
            startDecorator={
              <Avatar
                alt={format.name(user)}
                src={
                  hashEmail
                    ? `https://www.gravatar.com/avatar/${hashEmail}?s=${size}${
                        type ? `&default=${type}` : ''
                      }`
                    : undefined
                }
              >
                {initials}
              </Avatar>
            }
            variant="outlined"
          >
            {format.name(user)}
          </Chip>
        </Stack>
      )}
      <Stack>
        {callDetails.recordings.length > 0 && (
          <Typography
            level="body-md"
            fontWeight={600}
            sx={{ margin: '14px 0 6px 0' }}
          >
            {callDetails.recordings[0].type === 'voicemail'
              ? 'Voicemail'
              : 'Recordings'}
          </Typography>
        )}
        {/* <Stack gap={1}>
          {voicemail && (
            <Recordings
              key={call.id}
              callId={call.id}
              type="voicemail"
              style="light"
            />
          )}
          {recording && (
            <Recordings
              key={call.id}
              callId={call.id}
              type="recording"
              style="light"
            />
          )}
        </Stack> */}
      </Stack>
    </Stack>
  );
}

export default Details;
