import React from 'react';
import { Card, Typography, Avatar } from '@mui/joy';
import { Account } from '@sakari-io/sakari-typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useGetAccountQuery } from '../../api';

interface AccountListItemProps {
  value: Account;
  onSelect: (account: Account) => any;
}

const getFaviconUrl = (url?: string) => {
  if (!url) return undefined;

  try {
    const urlObj = new URL(url);
    url = urlObj.hostname;
    // logger.info(urlObj);
  } catch (e) {
    // logger.error(e);
  }
  return `https://icons.duckduckgo.com/ip3/${url}.ico`;
};

function AccountListItem({ value, onSelect }: AccountListItemProps) {
  const { data, isLoading } = useGetAccountQuery(value.id);

  if (isLoading) {
    return null;
  }
  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      key={value.id}
      sx={{
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        gap: 1,
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid var(--joy-palette-primary-solidBg)',
          cursor: 'pointer',
          '.MuiTypography-root': {
            color: 'var(--joy-palette-primary-solidBg)',
          },
        },
      }}
      onClick={() => onSelect(value)}
    >
      <Avatar
        alt={value.name}
        src={getFaviconUrl(data?.data?.info?.website)}
        slots={{
          fallback: () => <FontAwesomeIcon icon={faBuilding} />,
        }}
      />
      <Typography
        endDecorator={<FontAwesomeIcon icon={faChevronRight} />}
        sx={{
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        {value.name}
      </Typography>
    </Card>
  );
}

export default AccountListItem;
