import React from 'react';
import Grid from '@mui/joy/Grid';
import { AspectRatio, Button, Stack, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { ErrorImage } from '../../assets/images';

type ErrorType = {
  codes: string[];
  title: string;
  description: string;
  buttonText?: string;
  action?: () => any;
};

interface ErrorPageProps {
  code: string;
  message?: string | null;
}

function ErrorPage({ code = 'default', message }: ErrorPageProps) {
  const navigate = useNavigate();

  const errorStates: {
    [key: string]: ErrorType;
  } = {
    notFound: {
      codes: ['404'],
      title: 'Oops! Page Cannot Be Found',
      description:
        'Sorry but the page you are looking for either does not exist, has been changed or is temporarily unavailable.',
      buttonText: 'Back to Homepage',
      action: () => navigate('/'),
    },
    notAuthorized: {
      codes: ['401'],
      title: 'Restricted Access',
      description: 'Please check with your administrator for details',
      buttonText: 'Logout',
      action: () => navigate('/logout'),
    },
    forbidden: {
      codes: ['403'],
      title: 'Restricted Access',
      description: 'Please check with your administrator for details.',
      buttonText: 'Back to Homepage',
      action: () => navigate('/'),
    },
    noAccountAccess: {
      codes: ['AUTH-001'],
      title: 'Unauthorized Access',
      description: 'You do not have access to the specified account.',
      buttonText: 'Go to Accounts',
      action: () => navigate('/accounts'),
    },
    accessExpired: {
      codes: ['AUTH-003'],
      title: 'Unauthorized Access',
      description: 'Your temporary access has expired.',
      buttonText: 'Go to Accounts',
      action: () => navigate('/accounts'),
    },
    serverDown: {
      codes: ['500', '502', '503', '504'],
      title: 'Something went wrong, our bad!',
      description:
        "We're working to resolve the issue and should be back up and running soon.",
    },
    default: {
      codes: ['default'],
      title: 'Oops! Something went wrong',
      description: 'Try again later or contact us if the problem persists',
      buttonText: 'Back to Homepage',
      action: () => navigate('/'),
    },
  };

  const getErrorState = (code: string): ErrorType => {
    const errorState = _.find(errorStates, (errorState) =>
      errorState.codes.includes(code),
    );
    return errorState || errorStates.default;
  };

  const errorState = getErrorState(code);

  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid xs={10} sm={10} md={8} lg={8}>
        <Stack
          flexDirection={{ md: 'column', lg: 'row' }}
          padding={{ sx: 4, sm: 6, md: 6, lg: 8, xl: 10 }}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: 6,
          }}
        >
          <AspectRatio
            variant="plain"
            ratio="4/3"
            objectFit="contain"
            minHeight={150}
            sx={{
              width: '100%',
              overflow: 'auto',
              flexBasis: '60%',
            }}
          >
            <img src={ErrorImage} alt={`error ${code}`} />
          </AspectRatio>
          <Stack
            gap={2}
            flexBasis="40%"
            alignItems="center"
            justifyContent="center"
          >
            <Typography level="h3" textColor="text.secondary">
              {errorState.title}
            </Typography>
            <Typography>{message ?? errorState.description}</Typography>
            {errorState.buttonText && errorState.action && (
              <Button onClick={errorState.action}>
                {errorState.buttonText}
              </Button>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ErrorPage;
