import React, { useState, useRef } from 'react';
import { Contact } from '@sakari-io/sakari-typings';
import { IconButton, Stack, Typography } from '@mui/joy';
import { Avatar } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  faEllipsisVertical,
  faRightFromBracket,
} from '@fortawesome/pro-solid-svg-icons';
import Helper from '../../../../../../utils/helper';
import Popper from '../../../../../../ui/templates/Popper';
import MenuItem from '../../../../../../ui/atoms/MenuItem';
import Menu from '../../../../../../ui/molecules/Menu';

interface ContactHeaderProps {
  contact: Contact;
  onBack: () => void;
  options?: { label: string; onClick: () => void }[];
}

function ContactHeader({
  contact,
  onBack,
  options,
}: ContactHeaderProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        padding: '20px 16px',
        borderBottom: `1px solid ${'var(--joy-palette-neutral-outlinedBorder)'}`,
      }}
    >
      <IconButton
        size="sm"
        onClick={() => onBack()}
        color="neutral"
        variant="outlined"
        sx={{
          borderRadius: '8px',
          padding: 'auto',
          background: 'var(--joy-palette-background-surface)',
        }}
        aria-label="Back"
      >
        <FontAwesomeIcon
          icon={solid('angle-left')}
          size="xs"
          style={{ fontWeight: 700 }}
        />
      </IconButton>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack direction="row" gap={0.5} alignItems="center">
          <Avatar size="sm" person={contact} />
          <Typography fontWeight={500}>
            {Helper.getNameOrMobileNumber(contact)}
          </Typography>
        </Stack>

        {options?.length ? (
          <IconButton
            size="xs"
            onClick={() => setOpen((prev) => !prev)}
            ref={anchorRef}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </IconButton>
        ) : null}
      </Stack>

      <Popper
        open={open}
        onOpenClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        placement="left"
      >
        <Menu>
          {options?.map((option, index) => (
            <MenuItem key={index} onClick={option.onClick}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <FontAwesomeIcon icon={faRightFromBracket} />
                <Typography>{option.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
      </Popper>
    </Stack>
  );
}

export default ContactHeader;
