import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, ButtonGroup } from '@mui/joy';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomScheduleMessage from '../../../Dialogs/CustomScheduleMessage';
import ScheduleMessage from '../../../ButtonContexts/ScheduleMessage';

interface SendButtonProps {
  onSchedule: (date: Date) => any;
  onSend: () => any;
  disabled: boolean;
}

function SendButton({ disabled, onSchedule, onSend }: SendButtonProps) {
  const { t } = useTranslation();
  const scheduleButtonRef = useRef<HTMLButtonElement>(null);

  const [showScheduleMenu, setShowScheduleMenu] = useState(false);
  const [showCustomScheduleDialog, setShowCustomScheduleDialog] =
    useState(false);

  return (
    <Box>
      <ButtonGroup disabled={disabled}>
        <Button
          variant="solid"
          color="primary"
          size="sm"
          onClick={() => onSend()}
        >
          {t('action.send')}
        </Button>
        <Button
          variant="solid"
          color="primary"
          ref={scheduleButtonRef}
          size="sm"
          onClick={() => setShowScheduleMenu(true)}
          startDecorator={<FontAwesomeIcon icon={faClock} />}
        />
      </ButtonGroup>

      <ScheduleMessage
        open={showScheduleMenu}
        anchorRef={scheduleButtonRef.current}
        onOpenClose={() => setShowScheduleMenu(false)}
        onSchedule={(date) => {
          setShowScheduleMenu(false);
          onSchedule(date);
        }}
        onCustomSchedule={() => setShowCustomScheduleDialog(true)}
      />
      <CustomScheduleMessage
        onClose={() => setShowCustomScheduleDialog(false)}
        onSchedule={onSchedule}
        open={showCustomScheduleDialog}
      />
    </Box>
  );
}

export default SendButton;
