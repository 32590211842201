import React from 'react';
import { List, ListItem, ListItemContent, ListProps } from '@mui/joy';

export interface AppListProps<T> extends ListProps {
  items: T[];
  renderItem?: (item: T) => React.ReactNode;
  noItemsText?: string;
}

function AppList<T>({
  items,
  renderItem,
  children,
  noItemsText,
  ...rest
}: AppListProps<T>) {
  return (
    <List {...rest}>
      {items.length > 0 ? (
        items.map((item: any) =>
          renderItem ? (
            renderItem(item)
          ) : (
            <ListItem>
              <ListItemContent>{item}</ListItemContent>
            </ListItem>
          ),
        )
      ) : (
        <ListItem>
          <ListItemContent>{noItemsText || 'No items'}</ListItemContent>
        </ListItem>
      )}
      {children}
    </List>
  );
}
export default AppList;
