import ActionType from '../action-types';
import { Action } from '../actions/LoginAction';

interface LoginState {
  loading: boolean;
  error: string | null;
  data: any | null;
  notification: {
    enable: boolean;
    message: string;
  };
  language: string;
}
const initialState = {
  loading: false,
  error: null,
  data: null,
  notification: {
    enable: false,
    message: '',
  },
  language: 'english',
};

const loginReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: LoginState = initialState,
  action: Action,
): LoginState => {
  switch (action.type) {
    case ActionType.LOGIN:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case ActionType.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case ActionType.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: null,
      };
    case ActionType.NOTIFICATION_UPDATE:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
