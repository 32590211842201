import React from 'react';
import { Contact } from '@sakari-io/sakari-typings';
import { Stack, Box, IconButton, Sheet } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Tooltip, Avatar } from '@sakari-io/sakari-components';
import OverlayPopup from '../../../../templates/OverlayPopup';
import AppMenu from '../../../../molecules/Menu';

interface ContactDetailsHeaderProps {
  contact?: Partial<Contact>;
  setDrawerOpen: (value: boolean) => void;
  setOpenBlockDialog: (value: boolean) => void;
  setOpenDeleteDialog: (value: boolean) => void;
  onSave?: () => void;
  canSave?: boolean;
  readOnly?: boolean;
}

function ContactDetailsHeader({
  setDrawerOpen,
  contact,
  setOpenBlockDialog,
  setOpenDeleteDialog,
  onSave,
  canSave,
  readOnly,
}: ContactDetailsHeaderProps) {
  const [open, setOpen] = React.useState(false);
  return (
    <Sheet
      variant="solid"
      color="primary"
      sx={{
        height: 96,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          m: 1,
        }}
      >
        <IconButton
          variant="solid"
          color="primary"
          onClick={() => setDrawerOpen(false)}
        >
          <FontAwesomeIcon icon={solid('chevron-right')} />
        </IconButton>
      </Box>
      <Stack
        sx={{
          position: 'absolute',
          bottom: '-50%',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Avatar
          size="lg"
          person={contact}
          sx={{
            width: 96,
            height: 96,
            fontSize: 'xl4',
          }}
        />
      </Stack>
      <Box
        sx={{
          m: 1,
        }}
      >
        {contact?.id !== 'new' ? (
          <OverlayPopup
            open={open}
            onOpenClose={setOpen}
            placement="bottom-end"
            renderButton={(setAnchorEl, onClick) => (
              <IconButton
                ref={setAnchorEl}
                onClick={onClick}
                variant="solid"
                color="primary"
              >
                <FontAwesomeIcon icon={solid('ellipsis-vertical')} />
              </IconButton>
            )}
            renderPopup={() => (
              <AppMenu
                size="sm"
                items={[
                  {
                    label: 'Block Contact',
                    onClick: () => setOpenBlockDialog(true),
                    disabled: readOnly,
                  },
                  {
                    label: 'Delete Contact',
                    onClick: () => setOpenDeleteDialog(true),
                    disabled: readOnly,
                  },
                ]}
              />
            )}
          />
        ) : null}
        {canSave && onSave ? (
          <Tooltip title="Save Changes" size="sm" placement="left">
            <IconButton
              variant="solid"
              color="primary"
              onClick={() => onSave()}
              disabled={readOnly}
            >
              <FontAwesomeIcon icon={solid('check')} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
    </Sheet>
  );
}

export default ContactDetailsHeader;
