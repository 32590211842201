import React, { useContext, useState } from 'react';
import { IconButton, CircularProgress } from '@mui/joy';
import { GridColDef } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Campaign, CampaignExecution } from '@sakari-io/sakari-typings';
import { format, parseISO } from 'date-fns';
import { Tooltip, logger } from '@sakari-io/sakari-components';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { useAppDispatch } from '../../../../redux';
import { showToast } from '../../../../redux/reducers/toast';
import config from '../../../../config';
import StatusChip from '../../ChipContexts/StatusChip';
import { AccountContext } from '../../../../contexts/account.context';
import { useGetCampaignExecutionsQuery } from '../../../../api';
import EmptyResult from '../../../molecules/EmptyResult';
import DataGrid from '../../../molecules/DataGrid';

interface ExecutionsGridProps {
  campaign: Campaign;
  selectedRow?: CampaignExecution;
  onSelectedRowChange: (value: CampaignExecution) => any;
}

function ExecutionsGrid({
  campaign,
  selectedRow,
  onSelectedRowChange,
}: ExecutionsGridProps) {
  const { account } = useContext(AccountContext);
  const [isDownloading, setIsDownloading] = useState<string>('');
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const downloadReport = async (execution: CampaignExecution) => {
    setIsDownloading(execution.id);
    const token = await getAccessTokenSilently();
    const url = `${config.apiUrl}/accounts/${account?.id}/campaigns/${campaign.id}/jobs/${execution.id}/download`;
    axios({
      url,
      headers: {
        Authorization: `bearer ${token}`,
      },
      responseType: 'blob',
    })
      .then((res: any) => {
        const blob = new Blob([res.data], { type: 'text/csv;charset=utf-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'execution.csv';
        link.click();
        if (res.status === 200) {
          setIsDownloading('');

          dispatch(
            showToast({
              message: 'Report downloaded successfully',
              severity: 'success',
              timeout: 3000,
            }),
          );
        }
      })
      .catch((err: any) => {
        logger.info(err);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'created',
      headerName: 'Executed At',
      minWidth: 200,
      flex: 2,
      maxWidth: 300,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value: any) =>
        format(parseISO(value.at), 'MMM d, yyyy, hh:mm aa'),
    },
    {
      field: 'submitted',
      headerName: 'Submitted',
      minWidth: 80,
      flex: 1,
      maxWidth: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value) => value || 0,
    },
    {
      field: 'failures',
      headerName: 'Failures',
      minWidth: 80,
      flex: 1,
      maxWidth: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value) => value || 0,
    },
    {
      field: 'price',
      headerName: 'Price',
      minWidth: 80,
      flex: 1,
      maxWidth: 150,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (value: number) => `$${value ? value.toFixed(4) : 0}`,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      filterable: false,
      pinnable: false,
      minWidth: 120,
      flex: 1,
      maxWidth: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <StatusChip status={params.row.status} />;
      },
    },
    {
      field: 'report',
      headerName: 'Report',
      type: 'actions',
      sortable: false,
      filterable: false,
      pinnable: false,
      minWidth: 80,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: (params) => {
        return isDownloading === params.row.id ? (
          <CircularProgress size="sm" />
        ) : (
          <Tooltip size="sm" title="Download report">
            <IconButton
              sx={{
                opacity: 0.5,
                '&:hover': {
                  opacity: 0.8,
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => {
                logger.info('downloading');
                downloadReport(params.row);
              }}
            >
              <FontAwesomeIcon icon={faCloudArrowDown} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <DataGrid
        columns={columns}
        filter={{
          accountId: account?.id,
          request: {
            campaignId: campaign.id,
          },
        }}
        onRowClick={(params) =>
          onSelectedRowChange(params.row as CampaignExecution)
        }
        selectedRow={selectedRow}
        onSelectedRowChange={onSelectedRowChange}
        renderNoData={<EmptyResult heading="No executions available" />}
        loadingText="Loading executions..."
        loadData={useGetCampaignExecutionsQuery}
        autoSelect={(reports) => onSelectedRowChange(reports[0])}
      />
    </div>
  );
}

export default ExecutionsGrid;
