import React, { useState } from 'react';
import { Stack, Typography } from '@mui/joy';
import { WorkflowExecution } from '@sakari-io/sakari-typings';
import { Accordion } from '../../../../../../ui';
import VersionExecution from './VersionExecution';

interface ExecutionsAccordionProps {
  version?: any;
  onExecutionClick: (execution: WorkflowExecution) => void;
}

function ExecutionsAccordion({
  version,
  onExecutionClick,
}: ExecutionsAccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion
      key={version.id}
      id={version.id}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      divider
      variant="outlined"
      sx={{ cursor: 'pointer' }}
      onClick={() => setIsOpen(!isOpen)}
      header={
        <Stack>
          <Typography level="title-md">{version.version}</Typography>
        </Stack>
      }
      content={
        <Stack sx={{ padding: '12px 16px' }}>
          {version.executions.map((execution: WorkflowExecution) => (
            <VersionExecution
              key={execution.id}
              execution={execution}
              onClick={onExecutionClick}
            />
          ))}
        </Stack>
      }
    />
  );
}

export default ExecutionsAccordion;
