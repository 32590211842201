import React from 'react';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Chip, IconButton, Stack, Typography } from '@mui/joy';

import {
  NumberWithFlag,
  Tooltip,
  AvatarChip,
} from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { format } from '@sakari-io/sakari-common';
import { useTranslation } from 'react-i18next';
import { faUser, faVoicemail } from '@fortawesome/pro-solid-svg-icons';
import { faCircleDot } from '@fortawesome/pro-regular-svg-icons';
import Helper from '../../../../utils/helper';
import ReturnCallButton from '../../../../pages/CallHistory/ReturnCallButton';
import DataGrid from '../../../molecules/DataGrid';
import { useGetCallsQuery } from '../../../../api';

const callIconMap: {
  [key: string]: {
    icon: any;
    color: string;
    label: string;
  };
} = {
  initiated: {
    icon: solid('phone-volume'),
    color: 'var(--joy-palette-primary-400)',
    label: 'initiated',
  },
  ringing: {
    icon: solid('phone-volume'),
    color: 'var(--joy-palette-primary-400)',
    label: 'ringing',
  },
  active: {
    icon: solid('phone'),
    color: 'var(--joy-palette-primary-400)',
    label: 'inProgress',
  },
  inbound: {
    icon: solid('phone-arrow-down-left'),
    color: 'var(--joy-palette-neutral-500)',
    label: 'inbound',
  },
  outbound: {
    icon: solid('phone-arrow-up-right'),
    color: 'var(--joy-palette-primary-400)',
    label: 'outbound',
  },
  missed: {
    icon: solid('phone-missed'),
    color: 'var(--joy-palette-danger-500)',
    label: 'missedCall',
  },
  forwarded: {
    icon: solid('phone-arrow-right'),
    color: 'var(--joy-palette-neutral-500)',
    label: 'forwarded',
  },
};

// TODO find query typings
export type CallFilter = {
  direction: string[];
  userId: string[];
  groupId: string[];
  contactId: string[];
  phonenumberId: string[];
  orderBy: 'created-desc' | 'created-asc';
  status?: string | undefined;
  from: Date | null;
  to: Date | null;
  recording?: boolean;
};
interface CallsGridProps {
  filter: object;
  showContact: any;
  playAudio: any;
}

function CallsGrid({ filter, showContact, playAudio }: CallsGridProps) {
  const { t } = useTranslation('voice');

  const columns: GridColDef[] = [
    {
      field: ' ',
      headerName: ' ',
      width: 30,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { direction, status } = params.row;
        const state = status === 'ended' ? direction : status;
        const iconConfig = callIconMap[state];
        if (!iconConfig) {
          return undefined;
        }

        const { icon, color, label } = callIconMap[state];
        // if a call is active show a call volume icon, else if missed show a missed call icon, else show the direction icon

        return (
          <Tooltip title={t(`${label}`)} arrow placement="right">
            <FontAwesomeIcon
              icon={icon}
              style={{
                color,
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'contact',
      headerName: 'Contact',
      width: 180,
      flex: 1,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { firstName, lastName, mobile } = params.row?.contact || {};

        if (firstName || lastName) {
          return (
            <Chip size="sm" variant="outlined" color="neutral">
              {format.name(params.row?.contact)}
            </Chip>
          );
        }

        return (
          <Chip size="sm" variant="outlined" color="neutral">
            <NumberWithFlag mobile={mobile} size={13} />
          </Chip>
        );
      },
    },
    {
      field: 'created',
      headerName: 'Date',
      width: 110,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Stack flex={1}>
            <Typography
              level="body-xs"
              sx={{
                color: 'var(--joy-palette-neutral-400)',
                fontWeight: 900,
              }}
            >
              {Helper.dateFormatterDate(params.row.created.at)}
            </Typography>
            <Typography>
              {Helper.dateFormatterTime(params.row.created.at)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 110,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { status, duration, voicemail } = params.row;
        return (
          <Stack direction="row" alignItems="center">
            <Typography level="body-md">
              {['ringing', 'active'].includes(status)
                ? `${t('inProgress')}...`
                : Helper.getDuration(duration)}
            </Typography>

            {(params.row.voicemail || params.row.recording) && (
              <Tooltip title={voicemail ? t('voicemail') : t('recording')}>
                <IconButton
                  onClick={() => playAudio(params.row)}
                  size="smRound"
                  color="primary"
                >
                  {voicemail ? (
                    <FontAwesomeIcon icon={faVoicemail} />
                  ) : (
                    <FontAwesomeIcon icon={faCircleDot} />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },

    {
      field: 'group',
      headerName: 'Group',
      width: 150,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          params.row.group?.name && (
            <Chip size="sm" variant="soft" color="primary">
              {params.row.group.name}
            </Chip>
          )
        );
      },
    },
    {
      field: 'user',
      headerName: 'User',
      width: 160,
      flex: 1,
      filterable: false,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { user } = params.row;
        return user?.firstName ? <AvatarChip person={user} /> : null;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 90,
      headerAlign: 'right',
      align: 'right',
      pinnable: false,
      hideable: false,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={
              <FontAwesomeIcon
                icon={faUser}
                onClick={() => showContact(params.row)}
              />
            }
            label="Show Contact"
          />,
          <ReturnCallButton call={params.row} />,
        ];
      },
    },
  ];

  return (
    <DataGrid
      columns={columns}
      loadData={useGetCallsQuery}
      loadingText="Loading calls..."
      renderNoData={<p>No results</p>}
      checkboxSelection={false}
      filter={{ request: filter }}
    />
  );
}

export default CallsGrid;
