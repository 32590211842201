import React from 'react';
import {
  WorkflowDataReference,
  WorkflowVersion,
} from '@sakari-io/sakari-typings';
import { Node } from 'reactflow';
import Condition, { ConditionProps } from '.';
import PropertySelect from './PropertySelect';
import MultipleWrapper from '../MultipleWrapper';

interface FilterConditionProps
  extends Omit<ConditionProps, 'attributeComponent'> {
  version: WorkflowVersion;
  nodes: Node[];
  disabled?: boolean;
}

function FilterCondition({
  version,
  value,
  onChange,
  onHighlight,
  nodes,
  disabled,
}: FilterConditionProps) {
  // TODO Leah does this work if nodeId === 'trigger'
  const nodeType = nodes?.find((n: Node) => n.id === value?.attribute?.nodeId)
    ?.data?.type;

  return (
    <Condition
      attributeComponent={
        <PropertySelect
          properties={version?.definition?.properties || []}
          value={value?.attribute}
          onChange={(d?: WorkflowDataReference) => {
            if (d) {
              onChange({
                ...value,
                attribute: d,
              });
            }
          }}
          nodeType={nodeType}
          onHighlight={onHighlight}
          disabled={disabled}
        />
      }
      value={value}
      onChange={onChange}
      disabled={disabled}
      onHighlight={onHighlight}
    />
  );
}

export default FilterCondition;

export function FilterConditionMulti(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MultipleWrapper component={FilterCondition} addFieldDivider {...props} />
  );
}
