import React, { useContext } from 'react';
import { Stack } from '@mui/joy';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { makeValidate } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loader, Textarea } from '@sakari-io/sakari-components';
import _ from 'lodash';
import {
  useCreateLeadMutation,
  useGetLeadQuery,
  useUpdateLeadMutation,
} from '../../api';
import PageHeader from '../../ui/organisms/headers/PageHeader';
import Container from '../../ui/templates/Container';
import Setting from '../../ui/templates/forms/SettingForm';
import FieldWrapper from '../../utils/FieldWrapper';
import { TextField } from '../../ui';
import { useAppDispatch } from '../../redux';
import { showToast } from '../../redux/reducers/toast';
import { AccountContext } from '../../contexts/account.context';
import PhoneInput from '../../ui/atoms/inputs/PhoneInput/index';

function LeadAdd() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);

  const [createLead, { isLoading: isCreating }] = useCreateLeadMutation();
  const [updateLead, { isLoading: isUpdating }] = useUpdateLeadMutation();

  const { data: leadData, isLoading: isLoadingLead } = useGetLeadQuery(
    account && params.leadId
      ? {
          accountId: account.id,
          request: params.leadId,
        }
      : skipToken,
  );

  const initialValues = leadData?.data
    ? {
        email: leadData?.data.email || '',
        firstName: leadData?.data.firstName || '',
        lastName: leadData?.data.lastName || '',
        mobile: {
          number: leadData?.data.mobile || '',
        },
        website: leadData?.data.website || '',
        notes: leadData?.data.notes || '',
      }
    : undefined;

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
    website: Yup.string().required('Please make sure you enter a website'),
    firstName: Yup.string().required('Please Enter Lead First Name'),
    lastName: Yup.string().required('Please Enter Lead Last Name'),
    mobile: Yup.object()
      .shape({
        number: Yup.string().required('Phone number is required.'),
      })
      .nullable(),
  });

  const dispatch = useAppDispatch();
  const validate = makeValidate(schema);

  const formSave = (values: any) => {
    const request = _.assign(values, { mobile: values.mobile?.number || '' });
    const handleAction = () => {
      if (params.leadId) {
        request.id = params.leadId;
        return updateLead;
      }
      return createLead;
    };

    return handleAction()(request)
      .unwrap()
      .then((result) => {
        if (result.success) {
          navigate('/partners/leads');
        }
      })
      .catch((err) =>
        dispatch(
          showToast({
            severity: 'error',
            message: `${err.data.error.message}`,
          }),
        ),
      );
  };

  return (
    <Form
      onSubmit={formSave}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, hasValidationErrors, submitting }) => {
        return (
          <>
            <PageHeader
              backAction={() => navigate('/partners/leads')}
              title={
                params.leadId
                  ? t('partnerSettings.editLead')
                  : t('partnerSettings.createLead')
              }
              ctaAction={handleSubmit}
              ctaText="Save"
              ctaDisabled={hasValidationErrors}
              loading={submitting || isCreating || isUpdating}
            />

            <Container>
              {isLoadingLead && <Loader size={200} />}
              <Stack direction="column" spacing={2} pt={1} flex={1}>
                <Setting
                  label="Email"
                  required
                  description="Email is used to map referrals to your account"
                >
                  <FieldWrapper
                    component={TextField}
                    name="email"
                    required
                    fullWidth
                    disabled={!!params.leadId}
                  />
                </Setting>
                <Setting required label="First Name">
                  <FieldWrapper
                    component={TextField}
                    name="firstName"
                    fullWidth
                  />
                </Setting>

                <Setting required label="Last Name">
                  <FieldWrapper
                    component={TextField}
                    name="lastName"
                    fullWidth
                  />
                </Setting>

                <Setting label="Phone Number" required>
                  <FieldWrapper
                    component={PhoneInput}
                    name="mobile"
                    fullWidth
                  />
                </Setting>

                <Setting required label="Website">
                  <FieldWrapper
                    component={TextField}
                    name="website"
                    placeholder="google.com"
                    fullWidth
                    type="url"
                  />
                </Setting>

                <Setting
                  label="Notes"
                  sx={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  <FieldWrapper
                    component={Textarea}
                    name="notes"
                    placeholder="Indicate desired use cases, integrations, estimated message volume, etc."
                    fullWidth
                    maxRows={10}
                    minRows={5}
                  />
                </Setting>
              </Stack>
            </Container>
          </>
        );
      }}
    />
  );
}

export default LeadAdd;
