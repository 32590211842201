import React, { useContext } from 'react';
import {
  Account,
  Workflow,
  WorkflowNodeProperty,
  WorkflowNodePropertyType,
  WorkflowVersion,
} from '@sakari-io/sakari-typings';
import { Node } from 'reactflow';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  SplitValueSlider,
  Textarea,
  Checkbox,
} from '@sakari-io/sakari-components';
import { useTranslation } from 'react-i18next';
import IncrDecrementValue from '../IncrDecrementValue';
import WorkflowConfigFieldWrapper from '../../../../../../utils/WorkflowConfigFieldWrapper';
import { getAccountId } from '../../../../../../api/common';
import Composer from '../../../../../../ui/organisms/forms/Composer';
import TimeDelay from '../../../../../../ui/organisms/Delay';
import FilterCondition, {
  FilterConditionMulti,
} from '../Condition/FilterCondition';
import PropertyUpdate from '../PropertyUpdate';
import BasicSelect from '../BasicSelect';
import WorkflowPropertiesSelect from '../WorkflowPropertiesSelect';
import SendTo from '../SendTo';
import PropertyUpdateMulti from '../PropertyUpdateMulti';
import GoogleSheets from '../../../../../../ui/organisms/GoogleSheets';
import GoogleColumnsMulti from '../GoogleColumnsMulti';
import ColumnValueSelector from '../../../../../../ui/organisms/GoogleSheets/ColumnValueSelector';
import GroupAutoComplete from '../ObjectAutoComplete/GroupAutoComplete';
import ListAutoComplete from '../ObjectAutoComplete/ListAutoComplete';
import DynamicGroup from '../DynamicGroup';
import LinkAutoComplete from '../ObjectAutoComplete/LinkAutoComplete';
import ErrorsAutoComplete from '../ObjectAutoComplete/ErrorsAutoComplete';
import DynamicText from '../DynamicText';
import PropertySelect from '../Condition/PropertySelect';
import ContactAttributeCondition from '../Condition/ContactAttributeCondition';
import Timeout from '../../Timeout';
import { formatWorkflowDefinition } from '../../../../Canvas/hooks/formatWorkflowDefinition';
import { handleApiMutationResponse } from '../../../../../../utils/api';
import {
  useUpdateWorkflowDefinitionMutation,
  useGetGoogleSheetQuery,
} from '../../../../../../api';
import { useAppDispatch } from '../../../../../../redux';
import ContactTypeSelect from '../../../ContactTypeSelect';
import { Mode } from '../../../../../../redux/reducers/workflow';
import UserIntegration from '../UserIntegration';
import { AccountContext } from '../../../../../../contexts/account.context';
import { TextField } from '../../../../../../ui';
import ApiSelect from '../ApiSelect';
import URLInput from '../../../../../../ui/atoms/inputs/URLInput';

export interface ConfigFieldProps<T> {
  label?: string;
  value: T;
  onChange: (val: T) => any;
  onHighlight: (nodeId: string) => any;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  error?: boolean;
  link?: string;
}

interface PropertyFieldProps {
  account: Account;
  workflow: Workflow;
  version: WorkflowVersion;
  property: WorkflowNodeProperty;
  onHighlight: (nodeId: string) => any;
  mode: Mode;
}

function PropertyField({
  account,
  workflow,
  version,
  property,
  onHighlight,
  mode,
}: PropertyFieldProps) {
  const propertyLabel = property?.label || '';
  const dispatch = useAppDispatch();
  const { profile } = useContext(AccountContext);
  const { t } = useTranslation('workflows');

  const { values } = useFormState();
  const value = values[property.name];

  const disabled = [Mode.VIEW, Mode.SELECTION].includes(mode);

  // TODO consider passing down from config drawer
  const { nodes, edges, currentRFNode } = useSelector(
    (state: any) => state.workflow.present,
  );
  const nodeType = nodes?.find((n: Node) => n.id === value?.nodeId)?.data?.type;

  const [updateWorkflow] = useUpdateWorkflowDefinitionMutation();

  const saveVersion = (onSuccess?: () => any) => {
    const formattedWorkflowDefinition = formatWorkflowDefinition(nodes, edges);
    return handleApiMutationResponse(
      updateWorkflow({
        accountId: account.id,
        request: {
          workflowId: workflow.id,
          versionId: version.id,
          ...formattedWorkflowDefinition,
          updatedAt: version.updated?.at,
        },
      }).unwrap(),
      dispatch,
      {
        onSuccess,
        successMessage: t('edit.workflowUpdated'),
        defaultErrorMessage: t('edit.workflowUpdateError'),
      },
    );
  };

  // TODO this shouldn't live here - this component should have no knowledge of Google Sheets API's
  const gSheetValue = values.googlesheet;

  const { isLoading: isLoadingSheet, isError } = useGetGoogleSheetQuery(
    account && gSheetValue?.id && gSheetValue?.connectionId
      ? {
          accountId: account?.id,
          request: {
            sheetId: gSheetValue.id,
            connectionId: gSheetValue.connectionId,
          },
        }
      : skipToken,
  );

  switch (property.type) {
    case WorkflowNodePropertyType.Basictext:
      return (
        <WorkflowConfigFieldWrapper
          component={TextField}
          name={property.name}
          label={property.label}
          fullWidth
          required={property.mandatory}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
          conditions={property.conditions}
        />
      );
    case WorkflowNodePropertyType.Password:
      return (
        <WorkflowConfigFieldWrapper
          component={TextField}
          name={property.name}
          label={property.label}
          fullWidth
          required={property.mandatory}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
          conditions={property.conditions}
          type="password"
        />
      );
    case WorkflowNodePropertyType.Url:
      return (
        <WorkflowConfigFieldWrapper
          component={URLInput}
          name={property.name}
          label={property.label}
          fullWidth
          required={property.mandatory}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
          conditions={property.conditions}
        />
      );
    case WorkflowNodePropertyType.Text:
    case WorkflowNodePropertyType.Code:
      return (
        <WorkflowConfigFieldWrapper
          component={DynamicText}
          name={property.name}
          fullWidth
          required={property.mandatory}
          label={propertyLabel}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={
            disabled ||
            (property.name === 'searchValue' && !isLoadingSheet && isError)
          }
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Checkbox:
      return (
        <WorkflowConfigFieldWrapper
          component={Checkbox}
          name={property.name}
          fullWidth
          required={property.mandatory}
          label={propertyLabel}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
        />
      );
    case WorkflowNodePropertyType.To:
      return (
        <WorkflowConfigFieldWrapper
          component={SendTo}
          name={property.name}
          property={property}
          fullWidth
          required={property.mandatory}
          label={propertyLabel}
          conditions={property.conditions}
          onHighlight={onHighlight}
          properties={version?.definition?.properties || []}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Textarea:
      return (
        <WorkflowConfigFieldWrapper
          component={Textarea}
          name={property.name}
          required={property.mandatory}
          label={propertyLabel}
          minRows={7}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Composer:
      return (
        <WorkflowConfigFieldWrapper
          component={Composer}
          name={property.name}
          required={property.mandatory}
          label={propertyLabel}
          conditions={property.conditions}
          onHighlight={onHighlight}
          hideTemplates
          usePropertiesMenu
          properties={version?.definition?.properties || []}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Split:
      return (
        <WorkflowConfigFieldWrapper
          component={SplitValueSlider}
          name={property.name}
          required={property.mandatory}
          label={propertyLabel}
          step={5}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Link:
      return (
        <WorkflowConfigFieldWrapper
          component={LinkAutoComplete}
          name={property.name}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.List:
      return (
        <WorkflowConfigFieldWrapper
          component={ListAutoComplete}
          name={property.name}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          account={account}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Group:
      return (
        <WorkflowConfigFieldWrapper
          component={GroupAutoComplete}
          label={propertyLabel}
          name={property.name}
          property={property}
          required={property.mandatory}
          accountId={getAccountId() || ''}
          fullWidth
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Groups:
      return (
        <WorkflowConfigFieldWrapper
          component={GroupAutoComplete}
          label={propertyLabel}
          name={property.name}
          property={property}
          required={property.mandatory}
          accountId={getAccountId() || ''}
          fullWidth
          multiple
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    // case 'groups':
    //   return (
    //     <WorkflowConfigFieldWrapper
    //       component={MultiGroupSelect}
    //       name={property.name}
    //       accountId={getAccountId() || ''}
    //       label={propertyLabel}
    //       required={property.mandatory}
    //     />
    //   );
    case WorkflowNodePropertyType.Dynamicgroup:
      return (
        <WorkflowConfigFieldWrapper
          component={DynamicGroup}
          name={property.name}
          accountId={getAccountId() || ''}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Googlesheet:
      return (
        <WorkflowConfigFieldWrapper
          component={GoogleSheets}
          name={property.name}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          saveVersion={saveVersion}
          disabled={disabled}
          workflowId={workflow.id}
          currentNode={currentRFNode}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Googlesheetcolumnmappings:
      return (
        <WorkflowConfigFieldWrapper
          component={GoogleColumnsMulti}
          fullWidth
          name={property.name}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled || (!isLoadingSheet && isError)}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Contactselect:
      return (
        <WorkflowConfigFieldWrapper
          component={ContactTypeSelect}
          name={property.name}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Googlesheetcolumn:
      return (
        <WorkflowConfigFieldWrapper
          component={ColumnValueSelector}
          name={property.name}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
          fullWidth
        />
      );
    case WorkflowNodePropertyType.Number:
      return (
        <WorkflowConfigFieldWrapper
          component={IncrDecrementValue}
          name={property.name}
          required={property.mandatory}
          label={propertyLabel}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Property:
      return (
        <WorkflowConfigFieldWrapper
          component={PropertySelect}
          name={property.name}
          required={property.mandatory}
          label={propertyLabel}
          conditions={property.conditions}
          properties={version?.definition?.properties || []}
          nodeType={nodeType}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Workflowproperty:
      return (
        <WorkflowConfigFieldWrapper
          component={WorkflowPropertiesSelect}
          name={property.name}
          property={property}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          properties={version?.definition?.properties || []}
          version={version}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Update:
      return (
        <WorkflowConfigFieldWrapper
          component={
            property.min !== undefined ? PropertyUpdateMulti : PropertyUpdate
          }
          name={property.name}
          fullWidth
          handleDelete={undefined}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Dropdown:
      return (
        <WorkflowConfigFieldWrapper
          component={BasicSelect}
          name={property.name}
          property={property}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Apidropdown:
      return (
        <WorkflowConfigFieldWrapper
          component={ApiSelect}
          name={property.name}
          property={property}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
          accountId={account.id}
          url={property.args?.[0]}
          authenticated={property.args?.[1] === 'true'}
          valuePath={property.args?.[2]}
          labelPath={property.args?.[3]}
          formData={values}
        />
      );
    case WorkflowNodePropertyType.Filtercondition:
      return (
        <WorkflowConfigFieldWrapper
          component={
            property.min !== undefined ? FilterConditionMulti : FilterCondition
          }
          name={property.name}
          fullWidth
          handleDelete={undefined}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          version={version}
          nodes={nodes}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Contactattributecondition:
      return (
        <WorkflowConfigFieldWrapper
          component={ContactAttributeCondition}
          name={property.name}
          fullWidth
          handleDelete={undefined}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          version={version}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Delay:
      return (
        <WorkflowConfigFieldWrapper
          component={TimeDelay}
          name={property.name}
          required={property.mandatory}
          label={propertyLabel}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Userintegration:
      return (
        <WorkflowConfigFieldWrapper
          component={UserIntegration}
          name={property.name}
          property={property}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          integrationType={property.args?.[0]}
          saveVersion={saveVersion}
          account={account}
          user={profile}
          workflowId={workflow.id}
          currentNode={currentRFNode}
          helperText={property.helperText}
          link={property.link}
        />
      );
    case WorkflowNodePropertyType.Errors:
      return (
        <WorkflowConfigFieldWrapper
          component={ErrorsAutoComplete}
          name={property.name}
          property={property}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );

    case WorkflowNodePropertyType.Timeout:
      return (
        <WorkflowConfigFieldWrapper
          component={Timeout}
          name={property.name}
          property={property}
          label={propertyLabel}
          required={property.mandatory}
          conditions={property.conditions}
          onHighlight={onHighlight}
          disabled={disabled}
          helperText={property.helperText}
          link={property.link}
        />
      );

    default:
      return <div>Not recognized - {property.type}</div>;
  }
}

export default PropertyField;
