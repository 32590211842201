import React, { useContext } from 'react';
import { Group } from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/query';
import { ObjectAutoComplete } from '@sakari-io/sakari-components';
import { useGetGroupsQuery } from '../../../../../../api';
import { AccountContext } from '../../../../../../contexts/account.context';

interface GroupAutoCompleteProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  value?: Group | Group[];
  onChange: (value?: Group | Group[]) => void;
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
}

function GroupAutoComplete({
  label,
  placeholder,
  helperText,
  value,
  onChange,
  multiple,
  disabled,
  required,
}: GroupAutoCompleteProps) {
  const { account } = useContext(AccountContext);

  return (
    <ObjectAutoComplete
      label={label}
      helperText={helperText}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      fetch={useGetGroupsQuery}
      query={(input: string) =>
        account
          ? {
              accountId: account.id,
              request: {
                limit: 10,
                q: input,
              },
            }
          : skipToken
      }
      value={value || (multiple ? ([] as Group[]) : undefined)}
      onChange={onChange}
      multiple={multiple}
    />
  );
}

export default GroupAutoComplete;
