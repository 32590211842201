import * as React from 'react';
import Button, { ButtonProps } from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog, { ModalDialogProps } from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { ModalClose, Stack } from '@mui/joy';
import getDefaultIcon from '../dialogs';

export interface AppDialogProps extends Omit<ModalDialogProps, 'content'> {
  open: boolean;
  setOpen: (state: any) => void; //TODO deprecate this - onClose should be sufficient
  autoClose?: boolean;
  onClose?: () => void;
  hideClose?: boolean;
  header?: string | React.ReactNode;
  headerLayout?: {
    position?:
      | 'flex-start'
      | 'flex-end'
      | 'center'
      | 'space-between'
      | 'space-around'
      | 'space-evenly';
  };
  headerIcon?: any;
  showHeaderIcon?: boolean;
  content?: string | React.ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  buttonLayout?: {
    position?:
      | 'flex-start'
      | 'flex-end'
      | 'center'
      | 'space-between'
      | 'space-around'
      | 'space-evenly';
    reverse?: boolean;
    fullWidth?: boolean;
    padding?: string;
  };
  width?: number;
  divider?: boolean;
}

function AppDialog({
  open,
  setOpen,
  onClose,
  header,
  headerLayout = { position: 'center' },
  headerIcon,
  content,
  hideClose,
  autoClose = true,
  confirmButtonProps = {},
  cancelButtonProps = {},
  buttonLayout = {
    position: 'center',
    reverse: false,
    fullWidth: true,
  },
  size = 'md',
  width,
  sx,
  showHeaderIcon = true,
  divider,
  ...rest
}: AppDialogProps) {
  const { onClick: confirmAction, ...restConfirmButtonProps } =
    confirmButtonProps;
  const { onClick: cancelAction, ...restCancelButtonProps } = cancelButtonProps;
  const showConfirmButton = confirmButtonProps?.children || confirmAction;
  const showCancelButton = cancelButtonProps?.children || cancelAction;

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleConfirmation = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (confirmAction) {
      confirmAction(e);
    }
    if (autoClose) {
      handleClose();
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (cancelAction) {
      cancelAction(e);
    }
    handleClose();
  };

  return (
    <Modal
      aria-labelledby="alert-dialog-modal-title"
      aria-describedby="alert-dialog-modal-description"
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <ModalDialog
        role="alertdialog"
        variant={rest.variant || 'outlined'}
        color={rest.color || 'neutral'}
        sx={{
          display: 'flex',
          flexFlow: 'column',
          width: width || 'auto',
          ...(size === 'sm' && {
            minWidth: 360,
            py: 2,
            px: 3,
            gap: 2,
          }),
          ...(size === 'md' && {
            minWidth: 480,
            py: 4,
            px: 6,
            gap: 4,
          }),
          ...(size === 'lg' && {
            minWidth: 640,
            py: 6,
            px: 9,
            gap: 6,
          }),
          ...sx,
        }}
        size={size}
        {...rest}
      >
        {hideClose ? null : (
          <ModalClose onClick={handleClose} variant="plain" size="sm" />
        )}

        <Stack
          sx={{
            alignItems: headerLayout?.position,
            gap: 2,
            borderBottom: divider
              ? '1px solid var(--joy-palette-background-level2) '
              : 'none',
          }}
        >
          {showHeaderIcon &&
            (headerIcon ? headerIcon : getDefaultIcon(rest.color, size))}

          {typeof header === 'string' ? (
            <Typography id="alert-dialog-modal-title" level="h4">
              {header}
            </Typography>
          ) : (
            header
          )}
        </Stack>

        {typeof content === 'string' ? (
          <Typography
            id="alert-dialog-modal-description"
            textColor="text.tertiary"
            sx={{ display: 'flex', alignSelf: 'center' }}
          >
            {content}
          </Typography>
        ) : (
          content
        )}
        {rest.children}
        {showConfirmButton || showCancelButton ? (
          <Stack
            sx={{
              gap: 1,
              justifyContent: buttonLayout?.position,
              flexDirection: buttonLayout?.reverse ? 'row-reverse' : 'row',
              padding: buttonLayout?.padding,
              // ...(buttonLayout?.fullWidth && {
              //   width: '100%',
              //   '& > *': {
              //     flex: 1,
              //   },
              // }),
            }}
          >
            {showCancelButton ? (
              <Button
                onClick={handleCancel}
                fullWidth={buttonLayout?.fullWidth}
                size={size}
                {...{
                  color: 'neutral',
                  variant: 'outlined',
                  children: 'Cancel',
                  ...restCancelButtonProps,
                }}
              />
            ) : null}
            {showConfirmButton ? (
              <Button
                onClick={handleConfirmation}
                size={size}
                fullWidth={buttonLayout?.fullWidth}
                {...{
                  color: rest.color || 'primary',
                  variant: 'solid',
                  children: 'Confirm',
                  ...restConfirmButtonProps,
                }}
              />
            ) : null}
          </Stack>
        ) : null}
      </ModalDialog>
    </Modal>
  );
}

export default AppDialog;
