import { useState, useEffect } from 'react';

const isString = (value: any) => {
  return typeof value === 'string';
};

function useSessionStorage<T>(
  key: string,
  initialValue?: T,
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(() => {
    const storedValue = sessionStorage.getItem(key);

    if (storedValue === null) {
      return initialValue || undefined;
    }

    try {
      const parsedValue = JSON.parse(storedValue);
      if (isString(parsedValue)) {
        return storedValue;
      }
      return parsedValue;
    } catch (error) {
      return storedValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return;
    const valueToSave = isString(value) ? value : JSON.stringify(value);

    sessionStorage.setItem(key, valueToSave as string);
  }, [key, value]);

  const updateValue = (newValue: T) => {
    setValue(newValue);
  };

  return [value, updateValue];
}

export default useSessionStorage;
