import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import { PageHeader } from '../../../ui';
import Container from '../../../ui/templates/Container';
import SearchableDataGrid from '../../../ui/organisms/datagrids/SearchableDataGrid';
import LinksGrid from '../../../ui/organisms/datagrids/LinksGrid';
import CreateLinkDialog from '../../../ui/organisms/Dialogs/CreateLinkDialog';

function Links() {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const debouncedQuery = useDebouncedValue(query, 500);

  return (
    <>
      <PageHeader
        title={t('links.title')}
        ctaText={t('links.createNew.label')}
        ctaAction={() => setShowDialog(true)}
      />
      <Container>
        <SearchableDataGrid
          value={query}
          onChange={setQuery}
          renderDataGrid={() => <LinksGrid query={debouncedQuery} />}
        />
      </Container>
      <CreateLinkDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
}
export default Links;
