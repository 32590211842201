export const STAGE = 'prod' as string;
export const API_SUFFIX = '';
export const AUTH0_CLIENT_ID = 'DqDeUmyuHhD53cNhrROZccbTNxvN5gVE' as string;
export const INTEGRATION_PROPERTIES = {
  AIRCALL_CLIENT_ID: '6-EPS6u7iDjzzo0nojem8yxGZWLzrpEgwYr02pP6nWQ',
  SLACK_CLIENT_ID: '83474011347.83543752535',
  GOOGLE_SHEETS_CLIENT_ID:
    '572855696562-2ljmfqucie55jj3od6mmsj3sqde10f1j.apps.googleusercontent.com',
  HUBSPOT_CLIENT_ID: 'e8af35ae-60fc-46ca-83b2-a6a73c529a06',
  INTERCOM_CLIENT_ID: '4a6f9741-095b-4507-9939-f515a8ce5854',
  INTEGRATIONS_URL: 'https://integrations.sakari.io',
  PIPEDRIVE_CLIENT_ID: '4365a11b795c62f0',
};
