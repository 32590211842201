import React, { useContext, useState } from 'react';
import { User } from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/query';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import { ListItemDecorator, ListItemContent } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { getName, ObjectAutoComplete } from '@sakari-io/sakari-components';
import { useGetUsersQuery } from '../../../../../../api';
import { AccountContext } from '../../../../../../contexts/account.context';

interface UserAutoCompleteProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  value?: User;
  onChange: (value?: User) => void;
  disabled?: boolean;
}

function UserAutoComplete({
  label,
  placeholder,
  helperText,
  value,
  onChange,
  disabled,
}: UserAutoCompleteProps) {
  const { account } = useContext(AccountContext);
  const [hoveredId, setHoveredId] = useState(null);

  return (
    <ObjectAutoComplete
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      helperText={helperText}
      fetch={useGetUsersQuery}
      startDecorator={
        value && (
          <FontAwesomeIcon
            icon={faUser}
            style={{
              color: 'var(--joy-palette-primary-500)',
            }}
          />
        )
      }
      query={(input: string) =>
        account
          ? {
              accountId: account.id,
              request: {
                limit: 10,
                q: input,
              },
            }
          : skipToken
      }
      value={value}
      onChange={onChange}
      renderOption={(props: any, option: any) => (
        <AutocompleteOption
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={option?.id}
          onMouseEnter={() => setHoveredId(option?.id)}
          onMouseLeave={() => setHoveredId(null)}
        >
          <ListItemDecorator>
            <FontAwesomeIcon
              icon={faUser}
              style={{
                color:
                  hoveredId === option?.id
                    ? 'var(--joy-palette-primary-500)'
                    : 'inherit',
              }}
            />
          </ListItemDecorator>

          <ListItemContent>
            {`${option?.firstName} ${option?.lastName}`}
          </ListItemContent>
        </AutocompleteOption>
      )}
      getOptionLabel={(option: any) => (option ? getName(option) : '')}
    />
  );
}

export default UserAutoComplete;
