import React, { useState } from 'react';
import { Switch, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../..';
import RoleSelect from '../RoleSelect';
import { UserRole } from '../../../../constants';
import FieldWrapper from '../../../../utils/FieldWrapper';

type AutoEnrollType = {
  enabled: boolean;
  domain?: string;
  role?: string;
  notifyEmail?: string;
};

interface AutoEnrollProps {
  value: AutoEnrollType;
  onChange: (value: AutoEnrollType | undefined) => any;
}

function AutoEnroll({ value, onChange }: AutoEnrollProps) {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(value.enabled);

  const enableAutoEnroll = () => {
    if (enabled === false) {
      onChange({
        enabled: true,
        domain: '',
        role: UserRole.ADMIN,
        notifyEmail: '',
      });
      setEnabled(true);
    } else {
      onChange({ enabled: false });
      setEnabled(false);
    }
  };

  return (
    <Stack sx={{ width: '100%', gap: 2 }}>
      <Switch
        checked={enabled}
        onChange={() => enableAutoEnroll()}
        sx={{ alignSelf: 'flex-start' }}
      />
      {enabled ? (
        <Stack flexDirection="row" gap={2}>
          <FieldWrapper
            component={TextField}
            name="options.autoEnroll.domain"
            placeholder={t('domain')}
            label={t('domain')}
            infoText={t('form.domain.info')}
            required
          />

          <FieldWrapper
            component={RoleSelect}
            name="options.autoEnroll.role"
            placeholder={t('selectRole')}
            label={t('selectRole')}
          />

          <FieldWrapper
            component={TextField}
            name="options.autoEnroll.notifyEmail"
            placeholder={t('notifyEmail')}
            label={t('notifyEmail')}
            required
          />
        </Stack>
      ) : null}
    </Stack>
  );
}

export default AutoEnroll;
