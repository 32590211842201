import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../ui/molecules/ConfirmationDialog';

interface ConfirmCredentialsDialogProps {
  open: boolean;
  onClose: (result?: boolean) => any;
}

function ConfirmCredentials({ open, onClose }: ConfirmCredentialsDialogProps) {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      header={t('settings.api.refresh.description')}
      content={t('settings.api.overwriteWarning')}
      type="danger"
      confirmLabel="yes"
    />
  );
}

export default ConfirmCredentials;
