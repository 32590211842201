import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Stack, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { actions } from '../../../../redux/reducers/workflow';

interface DrawerHeaderProps {
  title: string | React.ReactNode;
}

function DrawerHeader({ title }: DrawerHeaderProps) {
  const dispatch = useDispatch();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        padding: '16px',
        backgroundColor: 'white',
        borderBottom: '1px solid var(--joy-palette-neutral-200)',
      }}
    >
      {typeof title === 'string' ? (
        <Typography fontWeight={700}>{title}</Typography>
      ) : (
        title
      )}

      <IconButton onClick={() => dispatch(actions.enterViewMode())} size="xs">
        <FontAwesomeIcon icon={solid('x')} />
      </IconButton>
    </Stack>
  );
}

export default DrawerHeader;
