import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { StyledSelectProps } from '../../../../ui/atoms/inputs/Select';

function DataTypeSelect({ value, onChange, disabled }: StyledSelectProps<any>) {
  const { t } = useTranslation();
  const options = [
    {
      label: t('dataTypes.text'),
      value: 'text',
    },
    // {
    //   label: t('dataTypes.date'),
    //   value: 'date',
    // },
    // {
    //   label: t('dataTypes.datetime'),
    //   value: 'datetime',
    // },
    {
      label: t('dataTypes.number'),
      value: 'number',
    },
  ];
  return (
    <Select
      options={options}
      disabled={disabled}
      value={value}
      onChange={(evt) => onChange(evt)}
    />
  );
}

export default DataTypeSelect;
