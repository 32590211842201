import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { SakariAPIResponse } from '@sakari-io/sakari-typings';
import { Loader, logger } from '@sakari-io/sakari-components';
import React, { useContext, useMemo } from 'react';
import { Box } from '@mui/joy';
import _ from 'lodash';
import { useAppDispatch } from '../../redux';
import { showToast } from '../../redux/reducers/toast';
import timeCheck from './AutoResponses/utils';
import { PageHeader } from '../../ui';
import { daysOfWeekShort } from '../../constants';
import { AccountContext } from '../../contexts/account.context';
import {
  useGetGroupQuery,
  useGetOfficeHoursQuery,
  useUpdateOfficeHoursMutation,
  useUpdateGroupMutation,
  useCreateGroupMutation,
} from '../../api';
import TabbedNavigation from '../../ui/organisms/headers/TabbedNavigation';

export enum GroupViewMode {
  GENERAL = 0,
  AUTORESPONDERS = 1,
  OFFICEHOURS = 2,
}

interface GroupProps {
  secondaryAction?: any;
  view: GroupViewMode;
  children?: React.ReactNode;
}

function Group({ view, secondaryAction, children }: GroupProps) {
  const navigate = useNavigate();
  const { account } = useContext(AccountContext);
  const params = useParams();
  const q =
    account && params?.groupId && params.groupId !== 'add'
      ? { accountId: account.id, request: params.groupId }
      : skipToken;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetGroupQuery(q);

  const { data: officeHoursData, isLoading: loadingOfficeHoursData } =
    useGetOfficeHoursQuery(q);

  // group
  const [createGroup, { isLoading: isCreating }] = useCreateGroupMutation();
  const [updateGroup, { isLoading: isUpdating }] = useUpdateGroupMutation();

  // office hours
  const [updateOfficeHours, { isLoading: isUpdatingOfficeHours }] =
    useUpdateOfficeHoursMutation();

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const initialValues = useMemo(
    () => ({
      ...data?.data,
      officeHours: {
        timezone: officeHoursData?.data?.timezone || tz,
        times: officeHoursData?.data?.times || {},
      },
    }),
    [data, officeHoursData],
  );

  const dispatchToast = (res: SakariAPIResponse, result: string) => {
    if (view === GroupViewMode.GENERAL && result === 'result1') {
      dispatch(
        showToast({
          severity: res.success ? 'info' : 'error',
          message: res.success ? t('groupSaved') : t('groupError'),
        }),
      );
    }
    if (view === GroupViewMode.OFFICEHOURS && result === 'result2') {
      dispatch(
        showToast({
          severity: res.success ? 'info' : 'error',
          message: res.success
            ? t('groupsNav.officeHours.saved')
            : t('groupsNav.officeHours.error'),
        }),
      );
    }
  };

  const formSave = async (values: any) => {
    const isCreate = params.groupId === 'add' || !params.groupId;

    const { officeHours } = values;

    await (isCreate ? createGroup : updateGroup)(values)
      .unwrap()
      .then((res) => {
        dispatchToast(res, 'result1');
        return res;
      })
      .then(() => {
        navigate('/groups');
      });

    const newVals = {
      timezone: officeHours?.timezone,
      times: officeHours?.times,
    };

    await updateOfficeHours({
      id: params.groupId || '',
      data: newVals,
    })
      .unwrap()
      .then((res) => {
        dispatchToast(res, 'result2');
        return res;
      })
      .catch((err) => {
        logger.info(err);
      });
  };

  const groupSchema = Yup.object().shape({
    name: Yup.string().required(t('enterGroupName')),
    officeHours: timeCheck(
      daysOfWeekShort,
      t('groupsNav.officeHours.validationDateRange'),
      t('groupsNav.officeHours.validationOverlaps'),
    ),
  });

  const validate = makeValidate(groupSchema);

  const tabUrlProvider = (tab: number) => {
    if (tab === 0) {
      if (params.groupId === 'add' || !params.groupId) {
        return '/groups/add';
      }
      return `/groups/${params.groupId}`;
    }
    if (tab === 1) {
      if (params.groupId === 'add' || !params.groupId) {
        return '/groups/add/autoresponders';
      }
      return `/groups/${params.groupId}/autoresponders`;
    }
    if (tab === 2) {
      if (params.groupId === 'add' || !params.groupId) {
        return '/groups/add/officehours';
      }
      return `/groups/${params.groupId}/officehours`;
    }
    return '';
  };

  if (isLoading || loadingOfficeHoursData)
    return (
      <Box
        sx={{
          height: '100vh',
        }}
      >
        <Loader size={200} label="Loading group..." hideBackdrop />
      </Box>
    );

  return (
    <Form
      onSubmit={formSave}
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, hasValidationErrors, dirty, values }) => {
        return (
          <form
            onSubmit={handleSubmit}
            style={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <PageHeader
              title={data?.data?.name || t('groups.add')}
              backAction={() => navigate('/groups')}
              ctaText={view !== GroupViewMode.AUTORESPONDERS ? t('save') : ''}
              loading={isCreating || isUpdating || isUpdatingOfficeHours}
              addTooltip={!dirty ? t('nothingToSave') : ''}
              ctaDisabled={
                isLoading ||
                hasValidationErrors ||
                _.isEqual(values, initialValues)
              }
              ctaAction={handleSubmit}
              cancelAction={secondaryAction}
              cancelText={params.groupId ? t('deleteGroup') : ''}
            />

            <TabbedNavigation
              tabs={[
                {
                  value: 0,
                  label: t('groupsNav.general'),
                  url: tabUrlProvider(0),
                },
                {
                  value: 1,
                  label: t('groupsNav.autoResponders.label'),
                  url: tabUrlProvider(1),
                },
                {
                  value: 2,
                  label: t('groupsNav.officeHours.label'),
                  url: tabUrlProvider(2),
                },
              ]}
              selectedTab={view}
              onTabSelected={(tab) => tabUrlProvider(tab)}
              divider
            >
              {isLoading ? (
                <Loader size={100} label={t('loadingGroup')} />
              ) : (
                children
              )}
            </TabbedNavigation>
          </form>
        );
      }}
    />
  );
}

export default Group;
