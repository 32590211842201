import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkflowEdge } from '@sakari-io/sakari-typings';
import Select from '../../../../../ui/atoms/inputs/Select';

interface ChildrenEdgeSelectProps {
  value?: WorkflowEdge;
  onChange: (edge: WorkflowEdge) => void;
  options: WorkflowEdge[];
}

function ChildrenEdgeSelect({
  value,
  onChange,
  options,
}: ChildrenEdgeSelectProps) {
  const { t } = useTranslation('workflows');

  let unnamedBranchCount = 0;

  const renderOptionLabel = (
    edge: WorkflowEdge,
    unnamedBranchCount: number,
  ) => {
    if (edge.value || edge.value === false) {
      return edge.value;
    }

    switch (edge?.type) {
      case 'error':
        return 'error';
      case 'timeout':
        return 'timeout';
      case 'standard':
        return unnamedBranchCount > 1
          ? `unnamed branch ${unnamedBranchCount}`
          : 'unnamed branch';
      default:
        return '';
    }
  };

  const optionsWithLabels = options.map((edge: WorkflowEdge) => {
    if (!edge.value && edge.value !== false && edge.type === 'standard') {
      unnamedBranchCount += 1;
    }
    return {
      label: renderOptionLabel(edge, unnamedBranchCount).toString(),
      value: edge,
    };
  });

  useEffect(() => {
    if (!value && options.length > 0) {
      onChange(options[0]);
    }
  }, [value, options, onChange]);

  return (
    <Select
      options={optionsWithLabels}
      fullWidth
      value={value}
      placeholder={t('edit.delete.selectBranch')}
      onChange={onChange}
    />
  );
}

export default ChildrenEdgeSelect;
