import React from 'react';
import { Stack, Button, Box } from '@mui/joy';

interface FieldDividerProps {
  buttonText?: string;
  renderLabel?: () => any;
  onClick?: () => void;
  icon?: React.ReactNode;
  height?: string;
  disabled?: boolean;
  showEndDivider?: boolean;
  color?: string;
}

function FieldDivider({
  buttonText,
  renderLabel,
  onClick,
  icon,
  height,
  disabled,
  color = 'var(--joy-palette-neutral-300)',
  showEndDivider = true,
}: FieldDividerProps) {
  const dividerStyle = {
    borderLeft: `1px solid ${color}`,
    height: height || '10px',
  };

  return (
    <Stack alignItems="center">
      <Box sx={dividerStyle} />
      {renderLabel ? renderLabel() : null}
      {buttonText ? (
        <Button
          variant="outlined"
          color="neutral"
          onClick={onClick}
          endDecorator={icon}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      ) : null}
      {showEndDivider && <Box sx={dividerStyle} />}
    </Stack>
  );
}

export default FieldDivider;
