import * as React from 'react';
import Tabs, { TabsProps } from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { Divider } from '@mui/joy';
import { NavLink } from 'react-router-dom';
import Container from '../../../templates/Container';

export interface NavTabProps {
  content?: React.ReactNode;
  value?: number;
  label: string;
  url?: string;
  hidden?: boolean;
  [key: string]: any;
}

const loadPanels = (tabs: NavTabProps[]) =>
  tabs.map((tab, index) => (
    <TabPanel key={tab.value} value={tab.value || index}>
      {tab.content}
    </TabPanel>
  ));

interface TabbedNavigationProps extends TabsProps {
  tabs: NavTabProps[];
  selectedTab: number;
  onTabSelected: (tab: number) => void;
  renderLabel?: (tab: NavTabProps) => React.ReactNode;
  fullWidth?: boolean;
  divider?: boolean;
  horizontalPadding?: number;
  labelPadding?: number;
  childrenSx?: any;
  disableNavLink?: boolean;
}

function TabbedNavigation({
  children,
  tabs,
  selectedTab,
  onTabSelected,
  fullWidth,
  divider,
  renderLabel,
  variant = 'underlined',
  size,
  color,
  horizontalPadding = 4,
  labelPadding,
  childrenSx,
  disableNavLink,
  ...rest
}: TabbedNavigationProps) {
  return (
    <Tabs
      aria-label={rest['aria-label'] || 'tabbed-navigation'}
      value={selectedTab}
      onChange={(event, value) => onTabSelected(value as number)}
      sx={{
        width: '100%',
        minHeight: 0,
        flex: '1 1 0',
        bgcolor: 'transparent',
        ...rest.sx,
      }}
      variant={variant !== 'outlined' ? variant : 'plain'}
      {...rest}
    >
      <TabList
        variant={variant !== 'outlined' ? variant : 'plain'}
        size={size}
        color={color}
        sx={{
          width: '100%',
          pt: 1,
          px: horizontalPadding,
          alignSelf: 'flex-start',
          justifyContent: 'flex-start',
          columnGap: 2,
          bgcolor: 'transparent',
        }}
        disableUnderline
      >
        {tabs.map((tab, index) => {
          const key = tab.value ?? index;
          return (
            <Tab
              key={key}
              value={key}
              variant={variant || 'underlined'}
              color={color || 'primary'}
              sx={{
                maxWidth: `calc(100% / ${tabs.length})`,
                flexGrow: fullWidth ? 1 : 0,
                display: tab.hidden ? 'none' : 'auto',
                padding: labelPadding || 'auto',
              }}
              {...(!disableNavLink &&
                !!tab.url && {
                  component: NavLink,
                  to: tab.url,
                })}
            >
              {renderLabel ? renderLabel(tab) : tab.label}
            </Tab>
          );
        })}
      </TabList>
      {divider && <Divider sx={{ mx: horizontalPadding }} />}

      {children ? (
        <Container id="tabbed-navigation-container" sx={{ ...childrenSx }}>
          {children}
        </Container>
      ) : (
        loadPanels(tabs)
      )}
    </Tabs>
  );
}

export default TabbedNavigation;
