import React from 'react';
import { Typography, Sheet, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import TextField from '../../../../../../ui/atoms/inputs/TextField';
import FieldWrapper from '../../../../../../utils/FieldWrapper';

interface AlphanumericProps {
  name: string;
}

function Alphanumeric({ name }: AlphanumericProps) {
  const { t } = useTranslation();

  const generateList = (requirements: string[]) => {
    return requirements.map((requirement) => (
      <li>
        <Typography level="body-md"> {requirement}</Typography>
      </li>
    ));
  };

  return (
    <Stack spacing={3}>
      <FieldWrapper
        component={TextField}
        name={name}
        placeholder={t('addSenders.phonenumber.alphaNumeric.id')}
        slotProps={{
          input: {
            maxLength: 11,
            minLength: 3,
          },
        }}
      />

      <Sheet variant="outlined" sx={{ p: 4, borderRadius: '10px' }}>
        <Stack spacing={1}>
          <Typography level="body-md">
            {t('addSenders.phonenumber.alphaNumeric.requirements.title')}
          </Typography>

          <ul>
            {generateList([
              t('addSenders.phonenumber.alphaNumeric.requirements.upperCase'),
              t('addSenders.phonenumber.alphaNumeric.requirements.lowerCase'),
              t('addSenders.phonenumber.alphaNumeric.requirements.numbers'),
            ])}
          </ul>
        </Stack>
        <Typography level="body-md">
          {t('addSenders.phonenumber.alphaNumeric.requirements.mustInclude')}
        </Typography>
        <Typography level="body-md" fontWeight={700}>
          {t('addSenders.phonenumber.alphaNumeric.description')}
        </Typography>
      </Sheet>
    </Stack>
  );
}

export default Alphanumeric;
