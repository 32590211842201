import React, { useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Grid, Stack } from '@mui/joy';

import { Form } from 'react-final-form';
import { Loader, logger } from '@sakari-io/sakari-components';
import PageSection from '../../../ui/templates/PageSection';
import PageHeader from '../../../ui/organisms/headers/PageHeader';

import Container from '../../../ui/templates/Container';
import { useGetCallQuery } from '../../../api';

import { AccountContext } from '../../../contexts/account.context';
import ContactDetails from '../../../ui/organisms/drawers/ContactDetails';

import Details from './Details';

function CallDetails() {
  const { callId } = useParams();
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { account } = useContext(AccountContext);

  const { data: call } = useGetCallQuery({
    id: callId,
  });

  const formSave = () => {
    logger.info('Form Save');
  };

  const viewContact = () => {
    setDrawerOpen(true);
  };

  return (
    <Form
      onSubmit={formSave}
      keepDirtyOnReinitialize
      initialValues={[]}
      render={({ handleSubmit }) => {
        return (
          <>
            <form
              onSubmit={handleSubmit}
              style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <PageHeader
                title="Call Details"
                ctaText="View Contact Details"
                ctaAction={viewContact}
                backAction={() => navigate('/calls')}
              />
              <Container pb={1} spacing={3}>
                {call?.data ? (
                  <Grid sm={6}>
                    <Stack spacing={3}>
                      <PageSection title="">
                        <Details call={call} />
                      </PageSection>
                    </Stack>
                  </Grid>
                ) : (
                  // <Grid container spacing={3}>
                  //   <Grid sm={6}>
                  //     <Stack spacing={3}>
                  //       <PageSection title="">
                  //         <Details call={call} />
                  //       </PageSection>
                  //       <PageSection title={t('Tags')}>
                  //         <FieldWrapper name="tags" component={Tags} />
                  //       </PageSection>
                  //     </Stack>
                  //   </Grid>
                  //   <Grid sm={6}>
                  //     <Stack spacing={3}>
                  //       <PageSection title="Notes">
                  //         <Notes />
                  //       </PageSection>
                  //     </Stack>
                  //   </Grid>
                  // </Grid>
                  <Stack height="183px">
                    <Loader size={200} label="Loading Call Details" />
                  </Stack>
                )}
              </Container>
            </form>
            {account && call?.data?.contact && (
              <ContactDetails
                account={account}
                contactId={call?.data.contact.id}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
          </>
        );
      }}
    />
  );
}

export default CallDetails;
