import { Link, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Plan } from '@sakari-io/sakari-typings';
import Helper from '../../../../utils/helper';

interface PlanContentProps {
  frequency: Plan['billingFrequency'];
  commitment: number;
  credit: number;
  segmentPrice: number;
}

function PlanContent({
  frequency,
  commitment,
  credit,
  segmentPrice,
}: PlanContentProps) {
  const { t } = useTranslation();
  const planSegments = Math.floor(credit / segmentPrice);
  const hasDiscount = credit !== commitment;

  return (
    <Stack>
      <Stack direction="row" alignItems="flex-end" gap={1}>
        <Typography
          level="h4"
          startDecorator={
            hasDiscount && `$${Helper.formatNumberWithCommas(credit, 2)}`
          }
          sx={{
            '.MuiTypography-startDecorator': {
              textDecoration: 'line-through',
              opacity: 0.5,
            },
          }}
        >
          ${Helper.formatNumberWithCommas(commitment, 0)}
        </Typography>
        <Typography>
          / {frequency === 'annual' ? t('year') : t('month')} USD
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="flex-end" gap={1}>
        <Typography level="h4">
          ~{Helper.formatNumberWithCommas(planSegments, 0)}
        </Typography>
        <Typography
          endDecorator={
            <Tooltip
              variant="plain"
              title={
                <>
                  {t('settings.billing.plans.features.sentReceivedCharge')}{' '}
                  <Link
                    href="https://support.sakari.io/hc/en-us/articles/360039248071"
                    target="_blank"
                  >
                    {t('settings.billing.plans.features.whatIsASegment')}
                  </Link>
                </>
              }
              placement="top"
              arrow
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{
                  marginLeft: 2,
                }}
              />
            </Tooltip>
          }
        >
          {t('items.segment_other')}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="flex-end" gap={1}>
        <Typography level="h4">
          +$
          {Helper.formatNumberWithCommas(segmentPrice, 4)}
        </Typography>
        <Typography>
          {t('settings.billing.plans.features.additionalSegment')}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default PlanContent;
