import React from 'react';
import {
  WorkflowProperty,
  WorkflowDataReference,
  WorkflowNodeType,
} from '@sakari-io/sakari-typings';
import PropertySelect from '../ConfigDrawer/NodeProperties/Condition/PropertySelect';
import ContactTypeMenu from './ContactTypeMenu';

interface ContactTypeSelectProps {
  properties: WorkflowProperty[];
  value?: WorkflowDataReference;
  onChange: (ref?: WorkflowDataReference) => any;
  nodeType: WorkflowNodeType;
  label?: string;
  helperText?: string;
  labelInfo?: string;
  fullWidth?: boolean;
  error?: boolean;
  onHighlight?: (nodeId: string) => void;
  disabled?: boolean;
}

function ContactTypeSelect({
  properties,
  value,
  onChange,
  nodeType,
  label,
  helperText,
  labelInfo,
  fullWidth,
  error,
  onHighlight,
  disabled,
}: ContactTypeSelectProps) {
  return (
    <PropertySelect
      value={value}
      properties={properties}
      onChange={onChange}
      nodeType={nodeType}
      menuComponent={ContactTypeMenu}
      label={label}
      helperText={helperText}
      labelInfo={labelInfo}
      fullWidth={fullWidth}
      error={error}
      onHighlight={onHighlight}
      disabled={disabled}
    />
  );
}

export default ContactTypeSelect;
