import React from 'react';
import { FontSize, SvgIcon, SvgIconProps } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const getDefaultIcon = (
  type: SvgIconProps['color'],
  size: 'sm' | 'md' | 'lg',
) => {
  const iconSizeMap = {
    sm: 'xl2',
    md: 'xl4',
    lg: 'xl6',
  };

  let icon;
  switch (type) {
    case 'success':
      icon = <FontAwesomeIcon color="blue" icon={solid('check-circle')} />;
      break;
    case 'danger':
      icon = <FontAwesomeIcon color="red" icon={solid('exclamation-circle')} />;
      break;
    case 'warning':
      icon = <FontAwesomeIcon icon={solid('exclamation-triangle')} />;
      break;
    case 'info':
      icon = <FontAwesomeIcon icon={solid('info-circle')} />;
      break;
    default:
      icon = null;
      break;
  }
  return icon ? (
    <SvgIcon fontSize={iconSizeMap[size] as keyof FontSize} color={type}>
      {icon}
    </SvgIcon>
  ) : null;
};

export default getDefaultIcon;
