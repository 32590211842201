import React, { useState } from 'react';
import { Account, User } from '@sakari-io/sakari-typings';
import { format } from '@sakari-io/sakari-common';
import { useTranslation } from 'react-i18next';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDebouncedValue, Autocomplete } from '@sakari-io/sakari-components';
import { useGetUsersQuery } from '../../../../api';
import { UserRole } from '../../../../constants';
import { AutocompleteProps } from '../../../clonedfromcomponents';

interface PartnerUsersProps extends Omit<AutocompleteProps<User>, 'options'> {
  account: Account;
  sx?: any;
  helperText?: string;
}

function PartnerUsers({
  account,
  value,
  onChange,
  ...rest
}: PartnerUsersProps) {
  const { t } = useTranslation();

  const [input, setInput] = useState('');
  const debouncedInput = useDebouncedValue(input, 300);
  const { data, isFetching } = useGetUsersQuery(
    account?.id
      ? {
          accountId: account.id,
          request: { q: debouncedInput },
        }
      : skipToken,
  );

  const admins = data?.data?.filter((u) => u.role === UserRole.ADMIN) || [];

  return (
    <Autocomplete
      key="partner-user-select"
      fullWidth
      inputValue={input}
      onInputChange={(e: any, v: any) => setInput(v)}
      options={admins}
      placeholder={t('selectUser')}
      getOptionLabel={(option: any) => format.name(option) || ''}
      isOptionEqualToValue={(option: any, value: any) =>
        value ? option.id === value.id : false
      }
      onOpen={() => setInput('')}
      loading={isFetching}
      renderOption={(props: any, user: any) => (
        <AutocompleteOption
          key={user?.id}
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {`${format.name(user)} (${user?.email})` || 'No name'}
        </AutocompleteOption>
      )}
      onChange={(v: any) => v && onChange(v as User)}
      value={value}
      {...rest}
    />
  );
}

export default PartnerUsers;
