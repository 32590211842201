import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { enUS, fr, de, es, pt } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import {
  differenceInDays,
  format,
  isToday,
  isYesterday,
  parseISO,
  addDays,
} from 'date-fns';

import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Message as MessageType,
  Conversation,
  CreateConversation,
  Account,
} from '@sakari-io/sakari-typings';
import { skipToken } from '@reduxjs/toolkit/query';
import { DateDivider, logger } from '@sakari-io/sakari-components';
import { Box, Container, Stack } from '@mui/joy';
import { useGetMessagesQuery } from '../../../../api';
import MessagesSkeleton from './MessagesSkeleton';
import Message from './MessageItem';
import { useCancelScheduledMessageMutation } from '../../../../api/messages';
import { useAppDispatch } from '../../../../redux';
import ConfirmationDialog from '../../../../ui/molecules/ConfirmationDialog';
import { handleApiMutationResponse } from '../../../../utils/api';

export enum DialogType {
  Cancel = 'cancel',
}

const lang: { [index: string]: any } = {
  en: enUS,
  fr,
  de,
  es,
  br: pt,
};

interface HistoryProps {
  account: Account;
  conversation?: Conversation | CreateConversation;
}

export const LIMIT = 10;

function History({ account, conversation }: HistoryProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState<number>(0);
  const [visibleMessages, setVisibleMessages] = useState<MessageType[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<string>();
  const [dialog, setDialog] = useState<DialogType>();

  const getQuery = (offset: number) =>
    conversation?.id &&
    !['new', 'draft'].includes(conversation?.id) &&
    offset >= 0
      ? {
          accountId: account.id,
          request: {
            conversationId: conversation.id,
            markRead: 1,
            offset,
            limit: LIMIT,
          },
        }
      : skipToken;

  const { data: previousData, isFetching: isFetchingPrev } =
    useGetMessagesQuery(getQuery(offset - LIMIT));
  const {
    data: currentData,
    isLoading: isLoadingCurrent,
    isFetching: isFetchingCurrent,
  } = useGetMessagesQuery(getQuery(offset));
  const { data: nextData, isFetching: isFetchingNext } = useGetMessagesQuery(
    !isLoadingCurrent && currentData?.data.length === LIMIT
      ? getQuery(offset + LIMIT)
      : skipToken,
  );

  const [cancelScheduled] = useCancelScheduledMessageMutation();

  const loadMessages = (offset: number) => {
    if (nextData?.data.length === LIMIT) {
      setOffset(offset + LIMIT);
    } else {
      logger.info('no more data available');
    }
  };

  useEffect(() => {
    setVisibleMessages([]);
    setOffset(0);
  }, [conversation?.id]);

  const setData = (data: MessageType[], conversationId?: string) => {
    if (conversationId === data[0]?.conversation?.id) {
      setVisibleMessages((prev: MessageType[]) => {
        const newMessages = [...prev].filter((msg) => msg.id !== 'new');
        newMessages.push(...(data ?? []));
        const sorted = _.orderBy(newMessages, (m) => m.updated?.at, ['desc']);
        return _.uniqBy(sorted, 'id');
      });
    }
  };

  useEffect(() => {
    if (!isFetchingPrev && previousData?.data) {
      setData(previousData.data, conversation?.id);
    }
  }, [isFetchingPrev, previousData?.data, conversation?.id]);

  useEffect(() => {
    if (!isFetchingCurrent && currentData?.data) {
      setData(currentData.data, conversation?.id);
    }
  }, [isFetchingCurrent, currentData?.data, conversation?.id]);

  useEffect(() => {
    if (!isFetchingNext && nextData?.data) {
      setData(nextData.data, conversation?.id);
    }
  }, [isFetchingNext, nextData?.data, conversation?.id]);

  let previousDate = '';
  let previousDate1: any = addDays(new Date(), 5);

  const isDateChanged = (date: string) => {
    const createdAt = parseISO(date);
    const isDateEqual = differenceInDays(createdAt, previousDate1);
    if (
      isToday(createdAt) &&
      isDateEqual === 0 &&
      (previousDate === 'Yesterday' || previousDate === 'OldDays')
    ) {
      previousDate = 'Today';
      previousDate1 = createdAt;
      return <DateDivider label={t('today')} />;
    }
    if (isYesterday(createdAt) && isDateEqual !== 0) {
      previousDate = 'Yesterday';
      previousDate1 = createdAt;
      return <DateDivider label={t('yesterday')} />;
    }
    previousDate1 = createdAt;
    previousDate = 'OldDays';

    const dateFormat = 'MMMM dd, yyyy';

    return (
      <DateDivider
        label={format(createdAt, dateFormat, {
          locale: lang[i18n.language ?? 'en'],
        })}
      />
    );
  };

  const groupMessages = (messages: MessageType[]) => {
    const sorted = _.orderBy(messages, (m) => m.created.at, ['desc']);
    return _.groupBy(sorted, (m) => m.created.at.substring(0, 10));
  };

  const items = useMemo(
    () =>
      conversation?.id && !['new', 'draft'].includes(conversation.id)
        ? groupMessages(visibleMessages)
        : {},
    [visibleMessages, conversation?.id],
  );

  const handleCancelScheduledMessage = (messageId: string) => {
    handleApiMutationResponse(cancelScheduled(messageId).unwrap(), dispatch, {
      successMessage: t('messages.schedule.cancel.success'),
      defaultErrorMessage: t('messages.schedule.cancel.error'),
    });
  };

  const handleAction = (action: DialogType, messageId: string) => {
    setSelectedMessage(messageId);
    switch (action) {
      case DialogType.Cancel:
        handleCancelScheduledMessage(messageId);
        break;
      default:
        break;
    }
  };

  const handleSelectAction = (action: DialogType, message: MessageType) => {
    setSelectedMessage(message.id);
    if (action) {
      setDialog(action);
    }
  };

  if (isFetchingCurrent && offset === 0) {
    return (
      <Container>
        <MessagesSkeleton />
      </Container>
    );
  }

  return (
    <Stack
      id="historyHolder"
      sx={{
        paddingLeft: 4,
        flexDirection: 'column-reverse',
        overflowY: 'overlay',
      }}
    >
      <InfiniteScroll
        dataLength={
          (nextData?.pagination?.offset || 0) + (nextData?.data.length || 0)
        }
        next={() => loadMessages(offset)}
        hasMore={nextData?.data.length === LIMIT}
        scrollableTarget="historyHolder"
        // eslint-disable-next-line react/jsx-no-useless-fragment
        loader={<></>}
        inverse
        scrollThreshold="50%"
        style={{
          paddingTop: '1rem',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {Object.keys(items).map((key: string) => {
          return (
            <Box key={key}>
              {isDateChanged(key)}
              {_.orderBy(items[key], ['created.at'], ['asc']).map(
                (item: MessageType) => {
                  return (
                    <Message
                      key={item.id}
                      message={item}
                      onAction={handleSelectAction}
                    />
                  );
                },
              )}
            </Box>
          );
        })}
      </InfiniteScroll>
      {dialog && selectedMessage && (
        <ConfirmationDialog
          open={!!dialog}
          header={t(`messages.schedule.${dialog}.title`)}
          content={t(`messages.schedule.${dialog}.description`)}
          confirmLabel={t(`yes`)}
          onClose={(result) => {
            if (result) {
              handleAction(dialog, selectedMessage);
            }
            setDialog(undefined);
          }}
        />
      )}
    </Stack>
  );
}

export default History;
