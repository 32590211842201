import React from 'react';
import { Stack, Checkbox } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Delay } from '@sakari-io/sakari-typings';
import { InputControl } from '@sakari-io/sakari-components';
import TimeDelay from '../../../../../ui/molecules/TimeDelay';

const MAX_TIMEOUT = { hours: 0, minutes: 0, days: 7 };

interface TimeoutProps {
  label?: string;
  helperText?: string;
  value?: Delay;
  onChange: (value: Delay) => void;
  disabled?: boolean;
}

function Timeout({
  label,
  helperText,
  value,
  onChange,
  disabled,
}: TimeoutProps) {
  const { t } = useTranslation('workflows');

  // const [maxTimeout, setMaxTimeout] = useState<boolean>(false);

  // should disable options if max timeout - not only onChange but when value.days is changed
  // useEffect(() => {
  //   setMaxTimeout(value.days === 7);
  // }, [value.days, maxTimeout]);

  const isMax = !value || value.days === 7;

  return (
    <InputControl label={label} helperText={helperText}>
      <Stack spacing={1}>
        {!isMax ? (
          <TimeDelay
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
              // if (
              //   newValue?.hours === MAX_TIMEOUT.hours &&
              //   newValue?.minutes === MAX_TIMEOUT.minutes &&
              //   newValue?.days === MAX_TIMEOUT.days
              // ) {
              //   setMaxTimeout(false);
              // }
            }}
            maxDays={7}
            disabled={disabled}
          />
        ) : null}

        <Checkbox
          checked={isMax}
          label={t('workflow.optionLabels.setMaxTimeForTimeout')}
          onChange={(evt) => {
            // setMaxTimeout(evt.target.checked);
            if (evt.target.checked) {
              onChange(MAX_TIMEOUT);
            } else {
              // if box is unchecked, reset the value to 0
              onChange({ hours: 0, minutes: 0, days: 0 });
            }
          }}
          size="sm"
          disabled={disabled}
        />
      </Stack>
    </InputControl>
  );
}
export default Timeout;
