import React, { useContext } from 'react';
import InvoicesGrid from '../../../ui/organisms/datagrids/InvoicesGrid';
import { AccountContext } from '../../../contexts/account.context';

function Invoices() {
  const { account } = useContext(AccountContext);
  if (!account) {
    return null;
  }
  return <InvoicesGrid account={account} />;
}

export default Invoices;
