export const removeHandlebars = (input: string): string => {
  return input.replace(/{{{|}}}/g, '').trim();
};

export const splitByHandlebars = (input: string): string[] => {
  let currentIndex = 0;
  let nextOpen = input.indexOf('{{{', currentIndex);
  const parts = [];

  while (nextOpen !== -1) {
    const nextClose = input.indexOf('}}}', nextOpen);
    if (nextOpen > currentIndex) {
      parts.push(input.substring(currentIndex, nextOpen));
    }
    if (nextClose !== -1) {
      parts.push(input.substring(nextOpen, nextClose + 3));
      currentIndex = nextClose + 3;
    } else {
      parts.push(input.substring(nextOpen));
      break;
    }
    nextOpen = input.indexOf('{{{', currentIndex);
  }
  if (currentIndex < input.length) {
    parts.push(input.substring(currentIndex));
  }
  return parts;
};
