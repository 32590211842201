import React from 'react';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  IconButton,
  ColorPaletteProp,
  SvgIcon,
  Stack,
} from '@mui/joy';
import AlertBase, { AlertProps } from '@mui/joy/Alert';

export type AlertType = 'success' | 'error' | 'info' | 'warning' | 'other';

export interface StatusAlertProps extends Omit<AlertProps, 'color'> {
  message: string | React.ReactNode;
  status: AlertType;
  handleClose?: () => void;
  fullWidth?: boolean;
  action?: React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

function StatusAlert({
  children,
  status = 'other',
  title,
  message,
  sx,
  variant,
  handleClose,
  fullWidth,
  action,
  size = 'md',
  icon,
  ...rest
}: StatusAlertProps) {
  const state: {
    [key: string]: {
      color: ColorPaletteProp;
      icon: any;
    };
  } = {
    success: {
      color: 'success',
      icon: regular('check-circle'),
    },
    warning: {
      color: 'warning',
      icon: regular('circle-exclamation'),
    },
    error: {
      color: 'danger',
      icon: regular('triangle-exclamation'),
    },
    info: { color: 'primary', icon: regular('info-circle') },
    other: { color: 'neutral', icon: regular('question-circle') },
  };

  const altVariant = variant !== 'outlined' ? variant : 'plain';

  const iconSize = {
    sm: 'lg',
    md: 'xl',
    lg: 'xl2',
  };

  return (
    <AlertBase
      key={title}
      sx={{
        flexGrow: 1,
        alignItems: 'flex-start',
        '.MuiTypography-root:first-of-type': {
          paddingBlock: 0,
        },
        ...(variant === 'outlined' && {
          background: `var(--joy-palette-${state[status].color}-softBg)`,
          opacity: 0.95,
        }),
        ...(fullWidth && { width: '100%' }),
        ...sx,
      }}
      color={state[status].color}
      variant={variant}
      size={size}
      startDecorator={
        <SvgIcon
          sx={{
            mt: '1px',
          }}
          fontSize={iconSize[size] as any}
        >
          {icon || <FontAwesomeIcon icon={state[status].icon} />}
        </SvgIcon>
      }
      endDecorator={
        action ||
        (handleClose ? (
          <IconButton
            size="sm"
            onClick={handleClose}
            color={state[status].color}
            variant={altVariant}
          >
            <FontAwesomeIcon icon={solid('xmark')} />
          </IconButton>
        ) : null)
      }
      {...rest}
    >
      <Stack id="status-alert-content">
        {title && (
          <Typography
            variant={altVariant}
            color={state[status].color}
            fontWeight="lg"
          >
            {title}
          </Typography>
        )}

        {message && (
          <Typography
            variant={altVariant}
            color={state[status].color}
            fontSize="sm"
            sx={{ opacity: 0.8 }}
          >
            {message}
          </Typography>
        )}
        {children}
      </Stack>
    </AlertBase>
  );
}

export default StatusAlert;
