import React, { useContext, useState } from 'react';
import { Stack, Typography } from '@mui/joy';
import {
  WorkflowNodeType,
  WorkflowNodeTypeOutput,
} from '@sakari-io/sakari-typings';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import OverlayPopup from '../../../../ui/templates/OverlayPopup';
import ElementOutputMenuItem from './ElementOutputMenuItem';
import { actions } from '../../../../redux/reducers/workflow';
import PropertiesAccordion from '../PropertiesAccordion';
import { useGetAttributesByTypeQuery } from '../../../../api';
import { AccountContext } from '../../../../contexts/account.context';

interface SelectElementOutputsMenuProps {
  open: boolean;
  nodeType: WorkflowNodeType;
}

function SelectElementOutputsMenu({
  open,
  nodeType,
}: SelectElementOutputsMenuProps) {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');

  const { account } = useContext(AccountContext);

  const { data: attributes } = useGetAttributesByTypeQuery(
    account
      ? {
          accountId: account.id,
          request: {
            type: 'contacts',
            limit: 5,
            q: searchTerm,
          },
        }
      : skipToken,
  );

  const { elementOutputNode } = useSelector(
    (state: any) => state.workflow.present,
  );

  const handleSelectOutput = async (e: any, output: WorkflowNodeTypeOutput) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      actions.exitSelectionMode({
        type: 'output',
        nodeId: elementOutputNode?.id,
        name: output.name,
        path: output.path,
      }),
    );
  };

  const coreAttributes = [
    { label: 'Id', value: '_id' },
    {
      label: 'First Name',
      value: 'firstName',
    },
    {
      label: 'Last Name',
      value: 'lastName',
    },
    {
      label: 'Mobile',
      value: 'mobile',
    },
    {
      label: 'Country',
      value: 'country',
    },
    {
      label: 'Email',
      value: 'email',
    },
  ];

  const mappedAttributes = attributes?.data?.map((attr) => ({
    label: attr.label,
    value: attr.name,
  }));

  const genericFields = [
    {
      label: 'Id',
      value: '_id',
    },
    {
      label: 'Name',
      value: 'name',
    },
  ];

  const predefinedOutputTypes = [
    {
      type: 'message',
      fields: [
        { label: 'Id', value: '_id' },
        {
          label: 'Status',
          value: 'status',
        },
        {
          label: 'Message',
          value: 'message',
        },
      ],
    },
    { type: 'group', fields: genericFields },
    { type: 'list', fields: genericFields },
    { type: 'link', fields: genericFields },
    {
      type: 'contact',
      fields: [...coreAttributes, ...(mappedAttributes ?? [])],
    },
  ];

  const predefinedTypesSet = predefinedOutputTypes.map((output) => output.type);

  const { standardOutputs, nestedOutputs } = (nodeType?.outputs || []).reduce(
    (
      acc: {
        standardOutputs: WorkflowNodeTypeOutput[];
        nestedOutputs: WorkflowNodeTypeOutput[];
      },
      output: WorkflowNodeTypeOutput,
    ) => {
      if (predefinedTypesSet.includes(output?.name.toLowerCase())) {
        acc.nestedOutputs.push(output);
      } else {
        acc.standardOutputs.push(output);
      }
      return acc;
    },
    { standardOutputs: [], nestedOutputs: [] },
  );

  return (
    <OverlayPopup
      disablePortal
      open={open}
      sx={{
        minWidth: '300px',
        borderRadius: 'var(--joy-radius-sm)',
        right: 0,
        left: '100%',
      }}
      onOpenClose={() => {}}
      renderPopup={() => (
        <Stack
          spacing={1}
          p={2}
          sx={{
            backgroundColor: 'var(--joy-palette-background-level1)',
            borderRadius: 'var(--joy-radius-sm)',
            overflow: 'scroll',
          }}
        >
          {standardOutputs?.map((output) => (
            <ElementOutputMenuItem
              label={output.name}
              key={output.name}
              onClick={(evt) => handleSelectOutput(evt, output)}
            />
          ))}

          {nestedOutputs?.map((output) => {
            const predefinedOutput = _.find(predefinedOutputTypes, {
              type: output.name.toLowerCase(),
            });
            const fields = predefinedOutput ? predefinedOutput.fields : [];
            return (
              <PropertiesAccordion
                key={output?.path}
                header={<Typography pl={1}>{output?.name}</Typography>}
                items={fields}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                renderItem={(item, index) => (
                  <ElementOutputMenuItem
                    label={item?.label}
                    key={item?.value ?? index}
                    onClick={(evt) => {
                      handleSelectOutput(evt, {
                        ...output,
                        name: item?.label,
                        path: `${output?.path}.${item?.value}`,
                      });
                    }}
                  />
                )}
              />
            );
          })}
        </Stack>
      )}
    />
  );
}

export default SelectElementOutputsMenu;
