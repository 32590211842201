import React from 'react';
import { useTranslation } from 'react-i18next';
import Setting from '../../../ui/templates/forms/SettingForm';
import Integration from '../IntegrationEdit';

function CallRail() {
  const { t } = useTranslation();

  return (
    <Integration
      name="callrail"
      renderSettings={(values) => {
        return (
          <>
            <Setting label={t('integrations.callrail.accountName.label')}>
              {values.account_name}
            </Setting>
            <Setting label={t('integrations.callrail.companyName.label')}>
              {values.company_name}
            </Setting>
          </>
        );
      }}
    />
  );
}

export default CallRail;
