import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener } from '@mui/base';
import { Card, IconButton, IconButtonProps } from '@mui/joy';
import { Account } from '@sakari-io/sakari-typings';
import React, { useEffect, useRef, useState } from 'react';
import QuickSendDialog from '../../Dialogs/QuickSendDialog';
import Popper from '../../../templates/Popper';
import Phone from '../../Phone';
import { displayPhoneComponent } from '../../../../redux/reducers/calls';
import { useAppDispatch } from '../../../../redux';
import { PhoneStatus } from '../../../../types';

enum QuickSendOption {
  NONE = 'none',
  SMS = 'sms',
  VOICE = 'voice',
}
interface QuickSendButtonProps {
  account: Account;
  hideVoice?: boolean;
  keepOpen?: boolean;
}

const QuickSendButton = React.forwardRef(
  ({ account, hideVoice, keepOpen }: QuickSendButtonProps) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const delaySeconds = 1.5;

    const dispatch = useAppDispatch();
    const voiceRef = useRef<any>(null);
    const [show, setShow] = useState(false);
    const [showOption, setShowOption] = useState(QuickSendOption.NONE);

    const iconButtonProps: IconButtonProps = {
      sx: {
        background: 'transparent',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.15)',
        },
      },
    };

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const handleMouseEnter = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShow(true);
    };

    const handleMouseLeave = () => {
      timeoutRef.current = setTimeout(
        () => setShow(false),
        delaySeconds * 1000,
      );
    };

    const openStyles = {
      transform: 'translateX(0)',
      boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.5)',
      opacity: 1,
      '#toggle': {
        opacity: 0,
        transition: 'all 0.3s ease-in-out',
      },
      'svg:not(#toggle)': {
        color: 'white',
        transition: `all 0.3s ease-in-out`,
      },

      transition: 'all 0.3s ease-in-out',
    };

    return (
      <>
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <Card
            id="quick-send-button"
            ref={voiceRef}
            size="sm"
            variant="solid"
            color="primary"
            orientation="horizontal"
            sx={{
              zIndex: 'calc(var(--joy-zIndex-popup) - 1)',
              position: 'fixed',
              bottom: 30,
              right: 30,
              transform: 'translateX(100%)',
              alignItems: 'center',
              borderRadius: 50,
              background:
                'linear-gradient(297.64deg, var(--joy-palette-primary-700) -24.66%, var(--joy-palette-primary-500) 82.85%)',
              boxShadow:
                '0px 3.9846057891845703px 3.9846057891845703px -1.9923028945922852px rgba(16, 24, 40, 0.03)',
              border: '1px solid white',
              opacity: 0.6,
              gap: 1,

              'svg:not(#toggle)': {
                color: 'transparent',
                transition: `all 0.3s ease-in-out`,
              },
              '#toggle': {
                transition: `all 0.3s ease-in-out`,
              },

              transition: `all 0.3s ease-in-out`,
              ...((show || keepOpen) && openStyles),
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <FontAwesomeIcon
              icon={solid('bolt')}
              id="toggle"
              style={{
                position: 'absolute',
                left: 10,
              }}
            />
            <IconButton
              {...iconButtonProps}
              id="sms"
              onClick={() => {
                setShowOption(QuickSendOption.SMS);
              }}
            >
              <FontAwesomeIcon icon={solid('comment')} />
            </IconButton>
            {hideVoice ? null : (
              <IconButton
                {...iconButtonProps}
                id="voice"
                onClick={() => {
                  setShowOption(QuickSendOption.VOICE);
                  dispatch(displayPhoneComponent(PhoneStatus.PHONE));
                }}
              >
                <FontAwesomeIcon icon={solid('phone')} />
              </IconButton>
            )}
          </Card>
        </ClickAwayListener>
        <QuickSendDialog
          account={account}
          open={showOption === QuickSendOption.SMS}
          onClose={() => setShowOption(QuickSendOption.NONE)}
        />
        <Popper
          open={showOption === QuickSendOption.VOICE}
          onOpenClose={() => setShowOption(QuickSendOption.NONE)}
          anchorEl={voiceRef.current}
          style={{
            zIndex: 100,
          }}
          placement="top-end"
          offset={[-50, 10]}
          animate="grow"
        >
          <Phone />
        </Popper>
      </>
    );
  },
);
export default QuickSendButton;
