import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import TextField, { TextFieldProps } from '../../../atoms/inputs/TextField';

export interface SearchBarProps
  extends Omit<TextFieldProps, 'onChange' | 'value'> {
  value: string;
  isLoading?: boolean;
  onChange: (value: string) => void;
}

function SearchBar({
  value = '',
  isLoading,
  onChange,
  ...rest
}: SearchBarProps) {
  const { t } = useTranslation();
  const getEndDecorator = () => {
    if (isLoading) {
      return <CircularProgress size="sm" variant="plain" color={rest.color} />;
    }
    if (value.length > 0) {
      return (
        <IconButton
          size="sm"
          variant="plain"
          color={rest.color}
          onClick={() => onChange('')}
        >
          <FontAwesomeIcon icon={solid('xmark')} />
        </IconButton>
      );
    }

    return null;
  };

  return (
    <TextField
      key="searchbar"
      role="searchbox"
      startDecorator={<FontAwesomeIcon icon={solid('magnifying-glass')} />}
      value={value}
      onChange={onChange}
      endDecorator={getEndDecorator()}
      placeholder={t('searchPlaceHolder')}
      {...rest}
    />
  );
}
export default SearchBar;
