import React from 'react';
import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { AdvancedDelay } from '@sakari-io/sakari-typings';
import { Checkbox } from '@sakari-io/sakari-components';
import TimeSelect from '../../molecules/TimeSelect';
import SelectableDays from '../../molecules/inputs/SelectableDays';

export interface ScheduleReleaseProps {
  value: AdvancedDelay['additionalTime'];
  onChange: (v: AdvancedDelay['additionalTime']) => any;
  disabled?: boolean;
}

function ScheduleRelease({ value, onChange, disabled }: ScheduleReleaseProps) {
  const { t } = useTranslation('workflows');

  const handleChange = (val: AdvancedDelay['additionalTime']) => {
    return onChange(val);
  };

  const showDays = value?.days !== undefined;
  const showTime = value?.time !== undefined;

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Checkbox
          label={t('workflow.optionLabels.endDelayOnDayOfWeek')}
          checked={showDays}
          disabled={disabled}
          onChange={(evt: any) => {
            handleChange({
              ...value,
              days: evt.target.checked ? [] : undefined,
            });
          }}
        />
        {showDays && (
          <SelectableDays
            disabled={disabled}
            value={value?.days!}
            onChange={(days) =>
              handleChange({
                ...value,
                days,
              })
            }
          />
        )}
      </Stack>

      <Stack spacing={1}>
        <Checkbox
          label={t('workflow.optionLabels.endDelayOnTimeOfDay')}
          checked={showTime}
          disabled={disabled}
          onChange={(evt: any) => {
            onChange({
              ...value,
              time: evt.target.checked
                ? {
                    hour: new Date().getHours(),
                    minute: 0,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }
                : undefined,
            });
          }}
        />
        {showTime && (
          <TimeSelect
            value={value.time!}
            disabled={disabled}
            onChange={(time) =>
              onChange({
                ...value,
                time,
              })
            }
          />
        )}
      </Stack>
    </Stack>
  );
}

export default ScheduleRelease;
