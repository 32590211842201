import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AlertType } from '../../ui/organisms/alerts/StatusAlert';

interface ToastProps {
  message: string;
  severity: AlertType;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right' | 'center';
  timeout?: number;
}

export interface ToastState {
  message?: string;
  severity?: AlertType;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right' | 'center';
  timeout?: number;
}

const initialState: ToastState = {};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastProps>) => {
      // logger.info('showToast', action.payload);
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.vertical = action.payload.vertical;
      state.horizontal = action.payload.horizontal;
      state.timeout = action.payload.timeout;
    },
    hideToast: (state) => {
      // logger.info('hideToast', action.payload);
      state.message = undefined;
      state.severity = undefined;
      state.vertical = undefined;
      state.horizontal = undefined;
      state.timeout = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
