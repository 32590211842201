import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@mui/joy';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface HoverMenuItemProps {
  icon: IconDefinition;
  label: string;
  onSelect: () => void;
  color: string;
  disabled?: boolean;
}

function HoverMenuItem({
  icon,
  label,
  onSelect,
  color,
  disabled,
}: HoverMenuItemProps): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  const iconStyle = {
    color: isHovered ? color : 'var(--joy-palette-grey-300)',
  };

  return (
    <MenuItem
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onSelect}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={icon} style={iconStyle} />
      {label}
    </MenuItem>
  );
}

export default HoverMenuItem;
