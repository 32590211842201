import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import CustomHour from './CustomHour';
import TimezoneAutoComplete from '../TimezoneAutoComplete';
import FieldWrapper from '../../../../utils/FieldWrapper';

interface CustomHoursProps {
  days: string[];
  rffName?: string;
  officeHoursActive?: boolean;
}

function CustomHours({ days, rffName, officeHoursActive }: CustomHoursProps) {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={1}
      sx={{
        marginTop: rffName === 'officeHours' ? '-10px' : '10px',
        flex: 1,
        overflow: 'auto',
      }}
    >
      <FieldWrapper
        component={TimezoneAutoComplete}
        name={`${rffName}.timezone`}
      />
      <Typography level="body-md">
        {t('groupsNav.officeHours.setDefaultTimezone')}
      </Typography>
      {days.map((day) => (
        <CustomHour
          key={day}
          name={rffName || 'customHour'}
          day={day}
          officeHoursActive={officeHoursActive}
        />
      ))}
    </Stack>
  );
}

export default CustomHours;
