import React from 'react';
import {
  Chip,
  ChipProps,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Typography,
  TypographySystem,
} from '@mui/joy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export interface StepProps extends ChipProps {
  orientation?: 'vertical' | 'horizontal';
  label?: string;
  subLabel?: string;
  active?: boolean;
  visited?: boolean;
  clickable?: boolean;
  index?: number;
  activeShadow?: boolean;
  checkVisited?: boolean;
}

const inactiveVariant: any = {
  solid: 'soft',
  soft: 'outlined',
  outlined: 'soft',
  plain: 'plain',
};

const typographySizeMap: {
  [key: string]: {
    lg: number;
    md: number;
    sm: number;
  };
} = {
  label: {
    lg: 1,
    md: 2,
    sm: 3,
  },
  subLabel: {
    lg: 2,
    md: 3,
    sm: 4,
  },
};

function Step({
  id,
  orientation,
  index,
  label,
  subLabel,
  active,
  visited,
  variant = 'solid',
  color = 'primary',
  children,
  size = 'md',
  clickable,
  activeShadow,
  checkVisited,
  ...rest
}: StepProps) {
  const getLabelColor = (
    slot: string,
    state: {
      active?: boolean;
      visited?: boolean;
    },
  ) => {
    if (state.active) {
      if (slot === 'label') {
        return `var(--joy-palette-${color}-solidBg)`;
      }
      if (slot === 'indicator') {
        return `var(--joy-palette-${color}-solidColor)`;
      }
    }

    if (state.visited) {
      return `var(--joy-palette-${color}-softDisabledColor)`;
    }
    return 'text.tertiary';
  };

  const renderLabel = () => {
    if (checkVisited && visited) {
      return <FontAwesomeIcon icon={solid('check')} />;
    }
    return id ? index : '';
  };

  return (
    <ListItem
      sx={{
        flexDirection: orientation === 'vertical' ? 'row' : 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: 2,
        padding: 0,
      }}
    >
      <ListItemDecorator
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Chip
          id={id}
          slotProps={{
            root: {
              sx: {
                display: 'flex',
                flex: '0 0 auto',
                aspectRatio: '1',
                alignItems: 'center',
                justifyContent: 'center',
                pointerEvents: clickable ? 'auto' : 'none',
                textAlign: 'center',
                boxShadow:
                  activeShadow && active
                    ? '0px 0px 6px 4px #87d2e8b0;'
                    : 'none',
              },
            },
            label: {
              sx: {
                filter: active ? 'none' : 'opacity(0.5)',
                color: getLabelColor('indicator', { active, visited }),
              },
            },
          }}
          size={size}
          color={visited || active ? color : 'neutral'}
          variant={active ? variant : inactiveVariant[variant]}
          {...rest}
        >
          {renderLabel()}
        </Chip>
      </ListItemDecorator>
      <ListItemContent
        sx={{
          flex: 1,
          // paddingTop:
          //   orientation === 'vertical'
          //     ? 'calc(var(--ListItem-paddingY) * 1)'
          //     : 0,
        }}
      >
        {label && (
          <Typography
            level={
              `body${typographySizeMap.label[size]}` as keyof TypographySystem
            }
            color={active || visited ? color : 'neutral'}
            fontWeight="md"
            sx={{
              opacity: active ? 1 : 0.75,
              color: getLabelColor('label', { active, visited }),
            }}
          >
            {label}
          </Typography>
        )}
        {subLabel && (
          <Typography
            level={
              `body${typographySizeMap.subLabel[size]}` as keyof TypographySystem
            }
            color="neutral"
            sx={{
              opacity: active ? 1 : 0.75,
            }}
          >
            {subLabel}
          </Typography>
        )}
        {children}
      </ListItemContent>
    </ListItem>
  );
}
export default Step;
