import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography, Box } from '@mui/joy';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/joy/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autoresponder } from '@sakari-io/sakari-typings';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import DeleteAutoResponderDialog from '../../Dialogs/DeleteAutoResponderDialog';
import ScheduleTypeChip from '../../ChipContexts/ScheduleTypeChip';
import { AccountContext } from '../../../../contexts/account.context';
import { useGetAutoRespondersQuery } from '../../../../api';
import EmptyResult from '../../../molecules/EmptyResult';
import DataGrid from '../../../molecules/DataGrid';

interface AutoResponsesGridProps {
  onSelectedRowChange?: (value: Autoresponder) => any;
}

function AutoResponsesGrid({ onSelectedRowChange }: AutoResponsesGridProps) {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  const { groupId } = useParams();

  const [open, setOpen] = useState(false);
  const [removedAutoResponder, setRemovedAutoResponder] =
    useState<Autoresponder>();

  useEffect(() => {
    if (!groupId) {
      navigate('/groups');
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'match',
      headerName: t('groupsNav.trigger'),
      headerClassName: 'gridHeaders',
      maxWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack alignItems="flex-start">
            {params.value === '' ? (
              <Typography
                level="body-md"
                sx={{
                  color: 'var(--joy-palette-neutral-outlinedBorder)',
                  fontWeight: '500',
                }}
              >
                {t('groupsNav.autoResponders.anyMessage')}
              </Typography>
            ) : (
              <>
                <Typography>&quot;{params.value}&quot;</Typography>
                <Typography
                  level="body-md"
                  sx={{
                    color: 'var(--joy-palette-neutral-outlinedBorder)',
                    fontWeight: '500',
                  }}
                >
                  {t('groupsNav.autoResponders.keyWord')}
                </Typography>
              </>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'scheduleType',
      headerName: t('groupsNav.sendDuring'),
      // headerClassName: 'gridHeaders',
      maxWidth: 150,
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <ScheduleTypeChip label={params.value} />;
      },
    },
    {
      field: 'response',
      headerName: t('groupsNav.autoResponders.response'),
      // headerClassName: 'gridHeaders',
      minWidth: 200,
      flex: 1,
      sortable: false,
      filterable: false,
      pinnable: false,
      disableColumnMenu: true,
    },

    {
      field: 'actions',
      type: 'actions',
      width: 44,
      align: 'right',
      sortable: false,
      filterable: false,
      pinnable: false,
      hideable: false,
      disableColumnMenu: true,
      // cellClassName: 'actions',
      getActions: (params) => {
        const actions = [];
        if (params.row) {
          actions.push(
            <GridActionsCellItem
              icon={
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRemovedAutoResponder(params.row as Autoresponder);
                    setOpen(true);
                  }}
                />
              }
              label="Delete"
            />,
          );
        }
        return actions;
      },
    },
  ];

  return (
    <>
      <Box pb={2} alignItems="end">
        <Button
          variant="solid"
          onClick={() => {
            navigate(`/groups/${groupId}/autoresponders/add`);
          }}
        >
          {t('autoresponders.add')}
        </Button>
      </Box>

      {account?.id && groupId && (
        <DataGrid
          columns={columns}
          filter={{
            accountId: account.id,
            request: { group: groupId },
          }}
          onSelectedRowChange={onSelectedRowChange}
          renderNoData={
            <EmptyResult
              heading={t('groupsNav.autoResponders.noAutoResponders')}
              item="autoresponders"
            />
          }
          loadingText={t('groupsNav.autoResponders.loadingAutoResponders')}
          loadData={useGetAutoRespondersQuery}
        />
      )}
      {removedAutoResponder ? (
        <DeleteAutoResponderDialog
          open={open}
          setOpen={setOpen}
          autoResponder={removedAutoResponder}
        />
      ) : null}
    </>
  );
}

export default AutoResponsesGrid;
