import { SakariAPIResponse } from '@sakari-io/sakari-typings';
import { sakariApi } from './rtk';

import { getAccountId } from './common';

interface AiRequest {
  request: string;
  count?: number;
}

interface AiResponse {
  responses?: string[];
}

const extendedApi = sakariApi.injectEndpoints({
  endpoints: (builder) => ({
    generateContent: builder.mutation<SakariAPIResponse<AiResponse>, AiRequest>(
      {
        query: ({ request, count }) => ({
          url: `accounts/${getAccountId()}/suggestions`,
          method: 'POST',
          body: {
            request,
            count: count || 1,
          },
        }),
      },
    ),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useGenerateContentMutation } = extendedApi;
