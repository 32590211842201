import React, { MouseEvent, useState } from 'react';
import { Chip, IconButton, IconButtonProps } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import Helper from '../../../../utils/helper';
import OverlayPopup from '../../../templates/OverlayPopup';

interface CopyButtonProps extends IconButtonProps {
  text: string | number | readonly string[] | undefined;
  placement?: 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end';
  id?: string;
  ignorePopup?: boolean;
  alternateOnClick?: () => void;
}

function CopyButton({
  id = 'copy-button',
  text,
  size = 'xsRound',
  variant = 'plain',
  color = 'neutral',
  onClick,
  placement = 'top-start',
  ignorePopup = false,
  alternateOnClick,
  ...rest
}: CopyButtonProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
    Helper.copyToClipboard(text as string);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <OverlayPopup
      open={copied}
      onOpenClose={setCopied}
      placement={placement}
      offset={[10, 5]}
      renderButton={(anchorRef) => (
        <IconButton
          id={id}
          ref={anchorRef}
          onClick={(e) =>
            alternateOnClick ? alternateOnClick() : handleCopy(e)
          }
          {...rest}
          size={size}
          variant={variant}
          color={color}
        >
          <FontAwesomeIcon icon={regular('clone')} />
        </IconButton>
      )}
      animate="fade"
      renderPopup={() =>
        !ignorePopup ? <Chip color="primary">copied!</Chip> : null
      }
    />
  );
}
export default CopyButton;
