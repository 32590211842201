import { createSlice } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Node as RFNode, Edge as RFEdge, Node, Edge } from 'reactflow';
import _ from 'lodash';

import {
  WorkflowNodeType,
  WorkflowEdgeType,
  WorkflowNode,
  EdgeValidation,
  WorkflowDefinition,
  WorkflowEdge,
  NumEdgeConditions,
  WorkflowDataReference,
  WorkflowVersion,
  WorkflowNodeTypeOutput,
  WorkflowNodeTypeOutputDataType,
  WorkflowVersionMetrics,
} from '@sakari-io/sakari-typings';
import { logger } from '@sakari-io/sakari-components';
import { subDays } from 'date-fns';
import Helper from '../../utils/helper';
import findReachableNodes from '../../pages/Workflows/Canvas/hooks/findUnreachableNodes';

export enum Mode {
  VIEW,
  EDIT,
  SELECTION,
  // INSIGHTS,
  CONTACTS,
  VERSIONS,
  DELETING,
}

export enum SubMode {
  NONE,
  SELECTION_BY_TYPE,
  DRAGGING,
}

export enum Drawer {
  SetTrigger,
  SetAction,
  Configure,
  None,
}

export enum MetricType {
  ABSOLUTE,
  PERCENTAGE,
}

export const ACTION_PLACEHOLDER_TYPE = 'actionPlaceholder';

export const ACTION_PLACEHOLDER_NODE_CONFIG: WorkflowNodeType = {
  id: ACTION_PLACEHOLDER_TYPE,
  name: ACTION_PLACEHOLDER_TYPE,
  type: ACTION_PLACEHOLDER_TYPE,
  icon: '',
  label: '',
  description: '',
  validation: {
    numEdgeCondition: NumEdgeConditions.Exactly,
    numEdges: 1,
    edges: [
      {
        defaultEdge: true,
        type: 'standard',
      },
    ],
  },
  // colors: colors.default,
};

export const createPlaceholder = (mode: Mode, id?: string): Node => ({
  id: id || Helper.generateMongoId(),
  type: ACTION_PLACEHOLDER_TYPE,
  position: { x: 0, y: 0 },
  data: {
    mode,
    type: ACTION_PLACEHOLDER_NODE_CONFIG,
    disabled: false,
  },
});

export const createEdge = (
  sourceNode: Node,
  targetNode: Node,
  edgeRule?: EdgeValidation,
  // type: WorkflowEdgeType = WorkflowEdgeType.Standard,
  // value?: string | number | boolean,
): RFEdge => {
  const id = Helper.generateMongoId();

  let edgeValue;

  if (edgeRule?.value?.value !== undefined) {
    edgeValue = edgeRule?.value?.value;
  } else if (edgeRule?.value && edgeRule?.value?.value === undefined) {
    edgeValue = '';
  } else {
    edgeValue = undefined;
  }

  return {
    id,
    source: sourceNode.id,
    target: targetNode.id,
    type: 'standard',
    data: {
      edge: {
        id,
        source: sourceNode.id,
        target:
          targetNode?.type !== ACTION_PLACEHOLDER_TYPE
            ? targetNode?.id
            : undefined,
        type: edgeRule?.type,
        value: edgeValue,
      },
      rule: edgeRule,
      mode: Mode.EDIT,
    },
  };
};

const createMultiplierNodeAndEdge = (
  currentNode: Node,
  edgeRule: EdgeValidation,
) => {
  const node = createPlaceholder(Mode.EDIT);
  const edge = createEdge(currentNode, node, edgeRule);

  return { node, edge };
};

const createEdgesForNodeType = (node: Node, defaultEdgeNode?: Node) => {
  const nodes: Node[] = [];

  const edges = (node.data?.type?.validation?.edges || []).map(
    (e: EdgeValidation) => {
      // const destNode = e.defaultEdge ? null : createPlaceholder(Mode.EDIT);

      const destNode =
        defaultEdgeNode && e.defaultEdge
          ? defaultEdgeNode
          : createPlaceholder(Mode.EDIT);

      if (!defaultEdgeNode || !e.defaultEdge) {
        nodes.push(destNode);
      }

      return createEdge(node, destNode, e);
    },
  );

  const multiplierEdge = (node.data?.type?.validation?.edges || []).find(
    ({ multiple }: EdgeValidation) => multiple,
  );

  while (
    multiplierEdge &&
    edges.length < (node.data?.type?.validation?.numEdges || 1)
  ) {
    const { node: destNode, edge: destEdge } = createMultiplierNodeAndEdge(
      node,
      multiplierEdge,
    );
    nodes.push(destNode);
    edges.push(destEdge);
  }

  return {
    nodes,
    edges,
  };
};

const setMode = (
  state: WorkflowState,
  mode: Mode,
  subMode?: SubMode,
  selectionType?: WorkflowNodeTypeOutputDataType,
) => {
  state.mode = mode;
  state.subMode = subMode ?? SubMode.NONE;
  state.selectionType = selectionType ?? undefined;

  if (mode === Mode.SELECTION) {
    const reachableNodes = state.currentRFNode
      ? findReachableNodes(state.currentRFNode?.id, state.edges, [])
      : [];

    state.nodes = state.nodes.map((n: Node) => {
      if (n.data) {
        n.data.mode = mode;
        n.data.disabled =
          !reachableNodes.includes(n.id) ||
          n.data?.type?.type === 'actionPlaceholder' ||
          !n.data.type?.outputs.length;
      }
      return n;
    });

    if (state.subMode === SubMode.SELECTION_BY_TYPE) {
      state.nodes = state.nodes.map((n: Node) => {
        if (n.data) {
          const isDisabledByType = !n.data.type?.outputs?.find(
            (o: WorkflowNodeTypeOutput) => o.dataType === state.selectionType,
          );
          n.data.disabled = n.data.disabled || isDisabledByType;
        }
        return n;
      });
    }
  } else {
    state.nodes = state.nodes.map((n: Node) => {
      if (n.data) {
        n.data.mode = mode;
        n.data.disabled = false;
      }
      return n;
    });
  }

  state.edges = state.edges.map((e: Edge) => {
    if (e.data) {
      e.data.mode = mode;
    }
    return e;
  });
};

const deleteTrigger = (state: WorkflowState) => {
  state.nodes = state.nodes.map((n) => {
    if (n.type === 'trigger') {
      n.data.type = undefined;
    }
    return n;
  });
  if (state.currentRFNode) {
    state.currentRFNode.data.type = undefined;
  }
};

export interface WorkflowState {
  nodes: Node[];
  edges: Edge[];
  nodeTypes: { [key: string]: WorkflowNodeType };
  hasChanges: boolean;
  initialHash?: string;
  mode: Mode;
  subMode: SubMode;
  version?: WorkflowVersion;
  currentRFNode?: RFNode; // TODO is this needed? ReactFlow sets a selected=true prop on node when selected
  highlightedNodeId?: string;
  elementOutputNode?: RFNode;
  elementSelectionCallback?: (value: WorkflowDataReference) => any;
  selectionType?: WorkflowNodeTypeOutputDataType; // when in selection mode and selection by type submode, this determines what type of data type is selectable
  metricType?: MetricType;
  metricDateRange?: { start: string; end: string };
  metrics?: WorkflowVersionMetrics;
}

const newNodeId = Helper.generateMongoId();
const initialState: WorkflowState = {
  nodes: [
    {
      id: newNodeId,
      type: ACTION_PLACEHOLDER_TYPE,
      position: { x: 0, y: 0 },
      data: {},
    },
  ],
  edges: [
    {
      id: Helper.generateMongoId(),
      type: WorkflowEdgeType.Standard,
      source: newNodeId,
      target: '3',
    },
  ],
  nodeTypes: {}, // TODO should this also be in this cstore - its already in RTK cache
  hasChanges: false,
  mode: Mode.VIEW,
  subMode: SubMode.NONE,
};

const sortObjectByKeys = (obj: { [key: string]: any }) => {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    return obj;
  }

  const sortedObj: { [key: string]: any } = {};
  Object.keys(obj)
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      sortedObj[key] = sortObjectByKeys(obj[key]);
    });

  return sortedObj;
};

const generateHashChanges = (state: any) => {
  const sortedNodes = _.sortBy(state.nodes, 'id');

  const nodesString = sortedNodes
    .map((node) => {
      const sortedConfig = sortObjectByKeys(node.data?.config);
      const configString = JSON.stringify(sortedConfig);
      // format node into string that combines id, type, and sorted config
      return `${node.id}-${node.type}-${configString}`;
    })
    .join('|');

  const sortedEdges = _.sortBy(state.edges, 'id');
  const edgesString = sortedEdges
    .map((edge) => {
      // format edge into string that combines id, source, target, and value
      // adding check for edge.value - to detect changes in edge value
      return `${edge.id}-${edge.source}-${edge.target}-${JSON.stringify(
        edge?.data?.edge?.value,
      )}`;
    })
    .join('|');

  // combine the nodes and edges strings into one
  const combinedString = `${nodesString}#${edgesString}`;

  // return combined string - to detect changes in state by comparing to previous hashes
  return combinedString;
};

export const parseNodes = (
  nodes: WorkflowNode[],
  nodeTypes: { [id: string]: WorkflowNodeType },
  wfEdges: WorkflowEdge[],
  mode: Mode,
) => {
  if (!nodes?.length) {
    return [
      {
        id: '1',
        type: 'trigger',
        position: { x: 0, y: 0 },
        data: {
          mode,
          disabled: false,
        },
      },
    ] as RFNode[];
  }

  const newNodes = nodes?.map((node, i) => {
    return {
      id: node.id,
      type: node.type.type === 'trigger' ? 'trigger' : 'node',
      position: { x: 0, y: i * 300 },
      data: {
        config: node.config,
        type: nodeTypes[node.type.id],
        mode,
        edges: wfEdges.filter((e) => e.source === node.id),
      },
    } as RFNode;
  });
  return newNodes;
};

const findRule = (edge: WorkflowEdge, type: WorkflowNodeType) => {
  const rule = (type?.validation?.edges || []).find((r) => {
    return edge.type === r.type && edge?.value === r?.value?.value;
  });

  return rule;
};

const addDefaultEdge = (edges: WorkflowEdge[]): WorkflowEdge[] => {
  if (!edges?.length) {
    return [
      {
        id: '2',
        source: '1',
        target: '3',
        type: WorkflowEdgeType.Standard,
      },
    ];
  }
  return edges;
};

export const parseEdges = (
  wfEdges: WorkflowEdge[],
  placeholders: Node[],
  nodes: Node[],
  mode: Mode,
): Edge[] => {
  if (!wfEdges?.length) return [];
  const edges = addDefaultEdge(wfEdges) || [];
  const edgesWithTypes = edges.map((e) => {
    const { id, source } = e;
    let { target } = e;
    if (!target) {
      const placeholder = createPlaceholder(mode);
      placeholders.push(placeholder);
      target = placeholder.id;
    }

    const sourceNode = nodes.find((node) => source === node.id);

    // TODO centralize this
    return {
      id,
      type: 'standard',
      source,
      target,
      data: {
        edge: e,
        rule: findRule(e, sourceNode?.data.type),
        mode,
      },
    } as Edge;
  });

  return edgesWithTypes;
};

const initDefinition = (
  state: WorkflowState,
  definition: WorkflowDefinition,
  nodeId?: string,
  edit?: boolean,
) => {
  const newNodes = parseNodes(
    definition.nodes,
    state.nodeTypes,
    definition.edges,
    state.mode,
  );

  const placeholders: Node[] = [];
  const newEdges = parseEdges(
    definition.edges,
    placeholders,
    newNodes,
    state.mode,
  );

  state.nodes = [...newNodes, ...placeholders];
  state.edges = newEdges;

  // for view mode analytics - set default metric date range to last 7 days
  const today = new Date();
  const sevenDaysAgo = subDays(today, 7);
  state.metricDateRange = {
    start: sevenDaysAgo.toISOString(),
    end: today.toISOString(),
  };

  // after the state is fully formed, set the initial hash
  state.initialHash = generateHashChanges(state);
  state.hasChanges = false;
  state.metricType = MetricType.ABSOLUTE;

  // after creating new workflow and entering edit mode, set the currentRFNode to the first node (trigger)
  if (edit) {
    state.mode = Mode.EDIT;
  }

  if (nodeId) {
    const node = state.nodes.find((n) => n.id === nodeId);
    state.currentRFNode = node;
  } else if (
    state.mode === Mode.EDIT &&
    state.nodes.length <= 2 &&
    !state.currentRFNode
  ) {
    state.currentRFNode = state.nodes.find(
      (n) => n?.data?.type?.type === 'trigger',
    );
  }
};

const WorkflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    checkForChanges: (state) => {
      const currentHash = generateHashChanges(state);
      const hashChanged = currentHash !== state.initialHash;

      state.hasChanges = hashChanged;
    },

    setCurrentRFNode: (
      state: WorkflowState,
      action: PayloadAction<Node | undefined>,
    ) => {
      state.currentRFNode = action.payload;
    },

    setDragging: (state: WorkflowState, action: PayloadAction<boolean>) => {
      state.subMode = action.payload ? SubMode.DRAGGING : SubMode.NONE;
    },

    setNodeDefinition: (state, action: PayloadAction<{ nodes: Node[] }>) => {
      state.nodes = action.payload.nodes;
    },
    setEdgeDefinition: (
      state: WorkflowState,
      action: PayloadAction<{ edges: Edge[] }>,
    ) => {
      state.edges = action.payload.edges;
    },
    setNodeTypes: (
      state: WorkflowState,
      action: PayloadAction<{ [key: string]: WorkflowNodeType }>,
    ) => {
      state.nodeTypes = action.payload;
    },
    deleteNode: (
      state: WorkflowState,
      action: PayloadAction<WorkflowEdge | undefined>,
      // eslint-disable-next-line consistent-return
    ) => {
      if (!state.currentRFNode) {
        return undefined;
      }

      if (state.currentRFNode.data.type.type === 'trigger') {
        return deleteTrigger(state);
      }

      if (state.currentRFNode.type === ACTION_PLACEHOLDER_TYPE) {
        const incomingEdge = state.edges.find(
          (e) => e.target === state.currentRFNode?.id,
        );
        const outgoingEdge = state.edges.find(
          (e) => e.source === state.currentRFNode?.id,
        );

        if (incomingEdge && outgoingEdge) {
          const edges = state.edges
            .filter((e) => e.id !== outgoingEdge?.id)
            .map((e) => {
              if (e.id === incomingEdge.id) {
                e.target = outgoingEdge.target;
                e.data.edge.target = outgoingEdge.target;
              }

              return e;
            });
          const nodes = state.nodes.filter(
            (n) => n.id !== state.currentRFNode?.id,
          );
          state.edges = edges;
          state.nodes = nodes;
          state.currentRFNode = undefined;
        }
      } else {
        const nodesToDelete: string[] = [state.currentRFNode.id];
        const edgesToDelete: string[] = [];

        const findNodes = (node: Node) => {
          const edges = state.edges.filter(
            (e) => e.source === node.id && action.payload?.id !== e.id,
          );

          edges.forEach((e) => {
            edgesToDelete.push(e.id);

            if (nodesToDelete.includes(e.target)) {
              // hit a node we already visited - therefore loop - do nothing
              logger.info(
                'hit a node we already visited - therefore loop - do nothing',
                e,
              );
            } else {
              const targetNode = state.nodes.find((n) => n.id === e.target);
              if (targetNode) {
                const inboundEdgesToTargetNode = state.edges.filter(
                  (el) => el.target === targetNode.id,
                );
                if (inboundEdgesToTargetNode.length <= 1) {
                  nodesToDelete.push(targetNode.id);
                } else {
                  // if 2+ edges coming into this node - just delete the edge
                }

                findNodes(targetNode);
              }
            }
          });
        };

        findNodes(state.currentRFNode);

        if (action.payload) {
          edgesToDelete.push(action.payload.id);
        }

        const inboundEdgesToNode = state.edges
          .filter((e) => e.target === state.currentRFNode?.id)
          .map(({ id }) => id);

        logger.info(
          'edges to update target',
          action.payload ? inboundEdgesToNode : undefined,
        ); // these need to be updated with new target (from action.payload.target if present) or create new placeholder nodes
        logger.info(
          'edges to create placeholders',
          !action.payload ? inboundEdgesToNode : undefined,
        ); // these need to be updated with new target (from action.payload.target if present) or create new placeholder nodes

        const updatedNodes = state.nodes.filter(
          (n) => !nodesToDelete.includes(n.id),
        );
        const updatedEdges = state.edges
          .filter((e) => !edgesToDelete.includes(e.id))
          .map((e) => {
            if (inboundEdgesToNode.includes(e.id)) {
              if (action.payload?.target) {
                e.target = action.payload.target;
                e.data.edge.target = action.payload.target;
              } else {
                const placeholder = createPlaceholder(Mode.EDIT);
                updatedNodes.push(placeholder);
                e.target = placeholder.id;
                e.data.edge.target = undefined;
              }
            }

            return e;
          });

        state.nodes = updatedNodes;
        state.edges = updatedEdges;
        state.currentRFNode = undefined;
      }
    },

    insertPlaceholderNode: (
      state: WorkflowState,
      action: PayloadAction<Edge>,
    ) => {
      const edge = action.payload;

      const placeholder = createPlaceholder(Mode.EDIT);

      const currentEdgeTargetNode = state.nodes.find(
        ({ id }) => id === edge.target,
      )!;

      const { edges, nodes } = createEdgesForNodeType(
        placeholder,
        currentEdgeTargetNode,
      );

      state.nodes = [...state.nodes, placeholder, ...nodes];
      state.edges = [
        ...state.edges.map((e) => {
          if (e.id === edge.id) {
            e.target = placeholder.id;
            e.data.edge.target = undefined;
            // return { ...e, target: placeholder.id };
          }
          return e;
        }),
        ...edges,
      ];
      state.currentRFNode = placeholder;
      // state.drawer = Drawer.SetAction;
    },

    setNodeType: (
      state: WorkflowState,
      action: PayloadAction<WorkflowNodeType>,
    ) => {
      if (state.currentRFNode?.data) {
        const updatedNode = state.currentRFNode;
        updatedNode.type = 'node';
        updatedNode.data.type = action.payload;
        updatedNode.data.config = (action.payload.properties || []).reduce(
          (res, prop) => {
            if (prop.defaultValue !== undefined) {
              return {
                ...res,
                [prop.name]: prop.defaultValue,
              };
            }

            return res;
          },
          {} as any,
        );

        const currentDefaultEdge = state.edges.find(
          ({ source }) => source === state.currentRFNode?.id,
        );
        const currentTargetNode = currentDefaultEdge
          ? state.nodes.find(({ id }) => id === currentDefaultEdge?.target)
          : undefined;

        const { nodes, edges } = createEdgesForNodeType(
          updatedNode,
          currentTargetNode,
        );

        state.currentRFNode = updatedNode;
        state.nodes = [
          ...state.nodes.map((n) => {
            if (n.id === state.currentRFNode?.id) {
              n.data.type = action.payload;
              n.type = 'node';
              return n;
            }

            return n;
          }),
          ...nodes,
        ];
        state.edges = [
          ...state.edges
            .filter(({ id }) => id !== currentDefaultEdge?.id)
            .map((edge) => {
              if (edge.target === state.currentRFNode?.id) {
                edge.data.edge.target = state.currentRFNode.id;
              }

              return edge;
            }),
          ...edges,
        ];
      }
    },

    updateNode: (state, action) => {
      const { currentNodeId, newNode } = action.payload;
      const nodeIndex = state.nodes.findIndex(
        (node: RFNode) => node.id === currentNodeId,
      );

      if (nodeIndex === -1) return;

      // update the node with new data but preserve position
      state.nodes[nodeIndex] = {
        ...newNode,
        position: state.nodes[nodeIndex].position,
      };

      // if node is a trigger, the output edge already exists - only thing updated should be new node type
      if (state.nodes[nodeIndex].data?.type?.type === 'trigger') {
        state.nodes[nodeIndex].data.type = newNode.data.type;
        return;
      }

      const validation = newNode.data?.type?.validation;
      if (!validation || validation?.edges?.length === 0) return;

      validation.edges.forEach((e: EdgeValidation) => {
        const nextNodeId = state.edges.find(
          (edge) => edge.source === currentNodeId,
        )?.target;
        const nextNode = nextNodeId
          ? state.nodes.find(({ id }) => id === nextNodeId)
          : undefined;

        if (!e.defaultEdge || !nextNode) {
          const placeholder = createPlaceholder(Mode.EDIT);
          state.nodes.push(placeholder);
          state.edges.push(createEdge(newNode, placeholder, e));
        } else {
          state.edges.push(createEdge(newNode, nextNode, e));
        }
      });

      // makes sure all edges pointing to the old current node id are updated to point to the new node id
      state.edges = state.edges.map((edge: RFEdge) => {
        if (edge.source === currentNodeId) {
          return { ...edge, source: newNode.id };
        }
        if (edge.target === currentNodeId) {
          return _.set(
            { ...edge, target: newNode.id },
            'data.edge.target',
            newNode.id,
          );
          // return { ...edge, target: newNode.id };
        }
        return edge;
      });
    },

    updateNodeConfig: (state, action) => {
      if (state.currentRFNode) {
        // state.currentRFNode.data.config = action.payload;
        state.nodes = state.nodes.map((node) => {
          if (node.id === state.currentRFNode!.id) {
            node.data.config = action.payload;
          }

          return node;
        });
      }
    },

    addEdgeToCurrentNode: (
      state: WorkflowState,
      action: PayloadAction<Node>,
    ) => {
      const edgeRule = (
        action.payload.data?.type?.validation?.edges || []
      ).find(({ multiple }: EdgeValidation) => multiple);

      const { node, edge } = createMultiplierNodeAndEdge(
        action.payload,
        edgeRule,
      );

      state.nodes.push(node);
      state.edges.push(edge);

      state.currentRFNode = undefined;
    },

    updateEdge: (state: WorkflowState, action: PayloadAction<any>) => {
      const { edgeId, newEdge } = action.payload;
      const edgeIndex = state.edges.findIndex(
        (edge: RFEdge) => edge.id === edgeId,
      );

      if (edgeIndex !== -1) {
        state.edges[edgeIndex] = newEdge;
      }
    },

    updateEdgeValue: (
      state: WorkflowState,
      action: PayloadAction<{
        edgeId: string;
        value: string | number | boolean;
      }>,
    ) => {
      const { edgeId, value } = action.payload;

      state.edges = state.edges.map((e) => {
        if (e.id === edgeId) {
          e.data.edge.value = value;
        }

        return e;
      });
    },

    addGoto: (
      state: WorkflowState,
      action: PayloadAction<{ source: string; target: string }>,
    ) => {
      // if source node is a placeholder (it should be!), check previous node, to see if its also a
      // place holder. If so discard previous placeholder ...then repeat until we find a
      // non-placeholder node

      const sourceNode = state.nodes.find(
        ({ id }) => id === action.payload.source,
      );
      const targetNode = state.nodes.find(
        ({ id }) => id === action.payload.target,
      );

      const nodesToDelete: string[] = sourceNode ? [sourceNode.id] : [];
      const edgesToDelete: string[] = [];

      const getRootSourceEdge = (edge?: Edge): Edge | undefined => {
        if (!edge) {
          return undefined;
        }

        const prevNode = state.nodes.find((n) => n.id === edge.source);
        if (prevNode?.type === ACTION_PLACEHOLDER_TYPE) {
          nodesToDelete.push(prevNode.id);
          edgesToDelete.push(edge.id);

          const rootEdge = state.edges.find((e) => e.target === prevNode.id);

          if (!rootEdge) {
            return edge;
          }
          return getRootSourceEdge(rootEdge);
        }
        return edge;
      };

      const sourceEdge = state.edges.find((e) => e.target === sourceNode?.id);
      if (sourceNode && targetNode && sourceEdge) {
        const rootSourceEdge = getRootSourceEdge(sourceEdge);
        logger.info(
          'rootSourceEdgeResult',
          rootSourceEdge?.id,
          rootSourceEdge?.source,
          rootSourceEdge?.target,
        );
        // const edge = createEdge(sourceNode, targetNode);

        state.nodes = state.nodes.filter((n) => !nodesToDelete.includes(n.id));
        state.edges = state.edges
          .filter((e) => !edgesToDelete.includes(e.id))
          .map((e) => {
            if (e.id === rootSourceEdge?.id) {
              logger.info(
                'found matching edge',
                e.id,
                e.target,
                targetNode.id,
                e.data.edge.target,
              );
              e.target = targetNode.id;
              e.data.edge.target = targetNode.id;
            }

            return e;
          });
      }
    },

    setElementOutputNode: (
      state: WorkflowState,
      action: PayloadAction<Node | undefined>,
    ) => {
      state.elementOutputNode = action.payload;
    },

    deleteEdge: (state: WorkflowState, action: PayloadAction<string>) => {
      const edgeId = action.payload;

      const edgeToDelete = state.edges.find((e) => e.id === edgeId);
      if (!edgeToDelete) {
        return;
      }

      const nodesToDelete: string[] = [];
      const edgesToDelete: string[] = [edgeId];

      const findAndDeleteDownstream = (currentNodeId: string) => {
        state.edges.forEach((e) => {
          if (e.source === currentNodeId) {
            edgesToDelete.push(e.id);
            if (!nodesToDelete.includes(e.target)) {
              nodesToDelete.push(e.target);
              findAndDeleteDownstream(e.target);
            }
          }
        });
      };

      nodesToDelete.push(edgeToDelete.target);
      findAndDeleteDownstream(edgeToDelete.target);

      state.nodes = state.nodes.filter((n) => !nodesToDelete.includes(n.id));
      state.edges = state.edges.filter((e) => !edgesToDelete.includes(e.id));
    },

    // setLoading: (state: WorkflowState) => {
    //   setLoading(state, true);
    // },

    enterViewMode: (state: WorkflowState) => {
      setMode(state, Mode.VIEW);
      state.currentRFNode = undefined;
    },
    enterEditMode: (state: WorkflowState) => {
      setMode(state, Mode.EDIT);
    },
    enterSelectionMode: (
      state: WorkflowState,
      action: PayloadAction<{
        callback: (value: WorkflowDataReference) => any;
        subMode?: SubMode;
        selectionType?: WorkflowNodeTypeOutputDataType;
      }>,
    ) => {
      setMode(
        state,
        Mode.SELECTION,
        action.payload.subMode,
        action.payload.selectionType,
      );

      state.elementSelectionCallback = action.payload.callback;
    },

    exitSelectionMode: (
      state: WorkflowState,
      action: PayloadAction<WorkflowDataReference | undefined>,
    ) => {
      if (state.elementSelectionCallback && action.payload) {
        state.elementSelectionCallback(action.payload);
      }

      state.elementOutputNode = undefined;
      setMode(state, Mode.EDIT);
      state.subMode = SubMode.NONE;
      state.selectionType = undefined;
    },

    setMetricType: (
      state: WorkflowState,
      action: PayloadAction<MetricType>,
    ) => {
      state.metricType = action.payload;
    },

    setMetricDateRange: (
      state: WorkflowState,
      action: PayloadAction<{ start: string; end: string }>,
    ) => {
      if (state.metrics) {
        state.metricDateRange = action.payload;
      }
    },

    showContacts: (state: WorkflowState) => {
      setMode(state, Mode.CONTACTS);
    },

    showVersions: (state: WorkflowState) => {
      setMode(state, Mode.VERSIONS);
    },

    setVersion: (
      state: any,
      action: PayloadAction<{
        version: WorkflowVersion;
        forceInit?: boolean;
        nodeId?: string;
        edit?: boolean;
      }>,
    ) => {
      const { version, forceInit, nodeId, edit } = action.payload;

      if (
        version?.definition &&
        (forceInit || state.version?.id !== version.id)
      ) {
        initDefinition(state, version.definition, nodeId, edit);
      }

      state.version = version;
    },

    addMetrics: (state: any, action: PayloadAction<WorkflowVersionMetrics>) => {
      state.metrics = action.payload;
    },

    highlightNode: (state, action: PayloadAction<string>) => {
      state.highlightedNodeId = action.payload;
    },
  },
});

const undoableNodeReducer = undoable(WorkflowSlice.reducer, {
  filter: () => {
    return true;
  },

  groupBy: (action, state) => {
    return generateHashChanges(state);
  },
});

export const { actions } = WorkflowSlice;
export default undoableNodeReducer;
