import React from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/joy';
import {
  Workflow as WorkflowType,
  WorkflowVersion,
} from '@sakari-io/sakari-typings';
import { Mode } from '../../../../redux/reducers/workflow';
import Drawer from '../../../../ui/templates/Drawer';
import ContactDetails from './ContactDetails';
import VersionDetails from './VersionDetails';

interface WorkflowDetailsDrawerProps {
  open: boolean;
  workflow: WorkflowType;
  version: WorkflowVersion;
  onVersionChange: (versionId: string) => any;
}

function WorkflowDetailsDrawer({
  open,
  workflow,
  version,
  onVersionChange,
}: WorkflowDetailsDrawerProps) {
  const { mode } = useSelector((state: any) => state.workflow.present);

  return (
    <Drawer
      isOpen={open}
      position="right"
      variant="outlined"
      sx={{
        backgroundColor: 'var(--joy-palette-background-level1)',
        width: 'clamp(250px, 100%, 434px)',
        borderTop: 'none',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        {/* {WorkflowDetailsViewMode.PERFORMANCE && <PerformanceDetails />} */}
        {mode === Mode.CONTACTS && <ContactDetails workflow={workflow} />}
        {mode === Mode.VERSIONS && (
          <VersionDetails
            workflow={workflow}
            version={version}
            onVersionChange={onVersionChange}
          />
        )}
      </Stack>
    </Drawer>
  );
}

export default WorkflowDetailsDrawer;
