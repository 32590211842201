import React, { ReactNode, useState } from 'react';
import { Button, Sheet, Stack, Typography } from '@mui/joy';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { WorkflowCondition } from '@sakari-io/sakari-typings';
import Select from '../../../../../../ui/atoms/inputs/Select';
import DeleteFieldButton from '../../../../Canvas/DeleteFieldButton';
import DynamicText from '../DynamicText';

export interface ConditionProps {
  attributeComponent: ReactNode;
  value: WorkflowCondition;
  onChange: (val: WorkflowCondition) => any;
  onHighlight: (nodeId: string) => any;
  disabled?: boolean;
}

function Condition({
  attributeComponent,
  value,
  onChange,
  onHighlight,
  disabled,
}: ConditionProps) {
  const { t } = useTranslation('workflows');
  const comparatorOptions = ['equals', 'notequals', 'gte', 'gt', 'lte', 'lt'];
  // value?.attribute?.name === 'number'
  //   ? ['equals', 'notequals', 'gte', 'gt', 'lte', 'lt']
  //   : ['equals', 'notequals'];

  const val = value.value || '';

  const isValueArray = Array.isArray(val);

  const [uniqueKeys, setUniqueKeys] = useState(() =>
    isValueArray
      ? (val as string[]).map((v, index) => `${index}-${Date.now()}`)
      : [`${0}-${Date.now()}`],
  );

  const handleOr = () => {
    const newValueArray = Array.isArray(value.value)
      ? [...value.value, '']
      : [value.value, ''];
    onChange({ ...value, value: newValueArray as string[] });
    setUniqueKeys((keys) => [
      ...keys,
      `${newValueArray.length - 1}-${Date.now()}`,
    ]);
  };

  const handleDelete = (index: number) => {
    const newValue = [...(value.value as string[])];
    newValue.splice(index, 1);
    onChange({ ...value, value: newValue });
    setUniqueKeys((keys) => keys.filter((a, idx) => idx !== index));
  };

  const handleDynamicPropertiesChange = (
    right = '',
    index: number | null = null,
  ) => {
    if (isValueArray) {
      const newValue = [...(value.value as string[])];
      newValue[index as number] = right;
      onChange({ ...value, value: newValue });
    } else {
      onChange({ ...value, value: right });
    }
  };

  const disableValueOptions = !value?.attribute || !value?.comparator;

  const allowMultipleValues =
    value?.comparator === 'notequals' || value?.comparator === 'equals';

  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
        padding: '16px',
        borderRadius: 'var(--joy-radius-md)',

        border: '1px solid var(--joy-palette-neutral-300)',
        backgroundColor: 'var(--joy-palette-neutral-100)',
      }}
    >
      {attributeComponent}
      <Select
        value={value?.comparator || ''}
        options={comparatorOptions?.map((c) => ({
          label: t(`comparator.${c}`),
          value: c,
        }))}
        onChange={(comparator) => onChange(_.assign({}, value, { comparator }))}
        placeholder={t('comparator')}
        disabled={!value?.attribute || disabled}
      />
      {isValueArray && val.length > 0 ? (
        (value?.value as string[])?.map((v: string, index: number) => (
          <Stack
            key={uniqueKeys[index]}
            direction="row"
            alignItems="flex-end"
            justifyContent="center"
            spacing={1}
          >
            <DynamicText
              key={index}
              value={`${v || ''}`}
              fullWidth
              onChange={(right) => handleDynamicPropertiesChange(right, index)}
              startDecorator={
                (value?.value as string[])?.length && index !== 0 ? (
                  <Typography
                    level="body-xs"
                    sx={{ color: 'var(--joy-palette-neutral-400)' }}
                  >
                    {t('or')}
                  </Typography>
                ) : null
              }
              disabled={disableValueOptions || disabled}
              onHighlight={onHighlight}
            />

            {(value?.value as string[])?.length > 1 ? (
              <DeleteFieldButton
                variant="plain"
                onClick={() => handleDelete(index)}
                disabled={disabled}
              />
            ) : null}
          </Stack>
        ))
      ) : (
        <DynamicText
          value={`${value.value || ''}`}
          onChange={(right) => handleDynamicPropertiesChange(right)}
          disabled={disableValueOptions || disabled}
          onHighlight={onHighlight}
        />
      )}

      {allowMultipleValues && (
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          sx={{
            width: 'fit-content',
          }}
          disabled={disableValueOptions || disabled}
          onClick={handleOr}
        >
          {t('or')}
        </Button>
      )}
    </Sheet>
  );
}

export default Condition;
