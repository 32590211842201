import React, { useState } from 'react';
import _ from 'lodash';
import {
  Account,
  Conversation,
  CreateConversation,
  Group,
  IFileUpload,
  SakariAPIResponse,
  SendMessageRequest,
  SendMessageResponse,
} from '@sakari-io/sakari-typings';
import { Box, Stack } from '@mui/joy';
import { logger } from '@sakari-io/sakari-components';
import Composer, {
  ComposerResponse,
} from '../../../ui/organisms/forms/Composer';
import MessageHistory from './MessagesHistory';
import { useAppDispatch } from '../../../hooks';
import { useSendMessageMutation } from '../../../api';
import { showToast } from '../../../redux/reducers/toast';
import Helper from '../../../utils/helper';

interface MessagesProps {
  account: Account;
  group: Group;
  conversation?: Conversation | CreateConversation;
  onConversationChanged: (
    conversation: Conversation | CreateConversation,
  ) => any;
  onFocusInComposerBox: (value: boolean) => void;
  disabledComposer: boolean;
}

function Messages({
  account,
  group,
  conversation,
  onConversationChanged,
  onFocusInComposerBox,
  disabledComposer,
}: MessagesProps) {
  const [composerState, setComposerState] = useState<ComposerResponse>({
    message: '',
    files: [] as IFileUpload[],
  });

  const [sendMessage] = useSendMessageMutation();

  const dispatch = useAppDispatch();

  // SEND ACTION
  const sendButtonAction = async (
    message?: string,
    files?: IFileUpload[],
    sendAt?: Date,
  ) => {
    const attachedFiles = (files || composerState.files).map((fileItem) => {
      const { url, name, type } = fileItem;
      return { url, name, type };
    });

    const payload: SendMessageRequest = sendAt
      ? {
          template: message || composerState.message,
          sendAt: sendAt.toISOString(),
        }
      : { template: message || composerState.message };

    if (!conversation) {
      return Promise.resolve();
    }
    if (conversation?.id && !['new', 'draft'].includes(conversation.id)) {
      payload.conversations = [conversation.id];
    } else if (conversation.contact) {
      payload.contacts = [conversation.contact];

      if (conversation?.group?.id) {
        payload.phoneNumberFilter = {
          group: { id: conversation.group.id },
        };
      } else if (group) {
        payload.phoneNumberFilter = {
          group: { id: group.id },
        };
      }
    } else {
      throw new Error('Invalid contact reference');
    }

    if (attachedFiles.length > 0) {
      payload.media = attachedFiles;
    }

    if (conversation.id === 'draft') {
      onConversationChanged(_.assign({}, conversation, { id: 'new' }));
    }

    const apiResultProm = sendMessage(payload);

    return apiResultProm
      .unwrap()
      .then((response: SakariAPIResponse<SendMessageResponse>) => {
        if (
          conversation.id === 'draft' &&
          response.data.messages?.length &&
          response.data.messages[0].conversation
        ) {
          logger.info(
            'updating conversation from Messages component',
            response.data.messages[0],
          );
          onConversationChanged(
            _.assign({}, response.data.messages[0].conversation, {
              contact: response.data.messages[0].contact,
              group: response.data.messages[0].group,
            }),
          );
        } else if (response.data.valid === 0) {
          dispatch(
            showToast({
              severity: 'error',
              message: 'Unable to send message',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          showToast({
            severity: 'error',
            message:
              err.data?.error?.message ||
              err.message ||
              'Unable to send message',
          }),
        );
      });
  };

  return (
    <>
      <Stack
        sx={{
          flex: '1 1 0',
          overflow: 'hidden',
          justifyContent: 'flex-end',
        }}
      >
        <MessageHistory account={account} conversation={conversation} />
      </Stack>

      {conversation && (
        <Box
          sx={{
            flex: 0,
            width: '100%',
            zIndex: 100,
            px: 2,
            paddingBottom: 2,
          }}
        >
          <Composer
            account={account}
            contact={conversation.contact}
            value={composerState}
            onChange={(value: any) => setComposerState(value)}
            onSend={(message: string, files: IFileUpload[], sendAt?: Date) => {
              sendButtonAction(message, files, sendAt)
                .then(() => {
                  // logger.info('resetting composer');
                  setComposerState({
                    message: '',
                    files: [],
                  });
                })
                .catch((err) => {
                  // logger.error('error sending message', err);
                  dispatch(
                    showToast({
                      severity: 'error',
                      message: err.data.error.message,
                    }),
                  );
                  setComposerState({
                    message,
                    files,
                    sendAt,
                  });
                });
            }}
            onFocus={(value: boolean) => onFocusInComposerBox(value)}
            disabled={
              disabledComposer ||
              Helper.isConversationArchived(conversation) ||
              !!conversation?.contact?.blocked ||
              !conversation?.contact
            }
            disabledFeatures={
              account?.plan?.name === 'Trial' ? ['upload', 'link'] : undefined
            }
            autofocus
          />
        </Box>
      )}
    </>
  );
}

export default Messages;
