import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Loader,
  logger,
  useDebouncedValue,
} from '@sakari-io/sakari-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Stack, List } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Contact } from '@sakari-io/sakari-typings';
import { useDispatch } from 'react-redux';
import SearchBar from '../../../../../../ui/molecules/inputs/SearchBar';
import { AccountContext } from '../../../../../../contexts/account.context';
import { useGetContactsQuery } from '../../../../../../api';
import ContactListItem from './ContactListItem';
import DrawerHeader from '../../DrawerHeader';
import EmptyResult from '../../../../../../ui/molecules/EmptyResult';
import { showToast } from '../../../../../../redux/reducers/toast';

interface ContactsListProps {
  onClick: (contact: Contact) => void;
}

export const LIMIT = 20;

function ContactsList({ onClick }: ContactsListProps) {
  const { t } = useTranslation('workflows');
  const { account } = useContext(AccountContext);
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [contacts, setContacts] = useState<Contact[]>([]);

  const debouncedSearchKey = useDebouncedValue(inputValue, 500);

  const getQuery = () =>
    offset >= 0
      ? {
          accountId: `${account?.id}`,
          request: {
            q: debouncedSearchKey,
            offset,
            limit: LIMIT,
          },
        }
      : skipToken;

  const {
    data: currentData,
    isLoading: isLoadingCurrent,
    isError,
    error,
  } = useGetContactsQuery(getQuery());

  useEffect(() => {
    if (isError) {
      dispatch(
        showToast({
          message: (error as Error)?.message || 'Error loading data',
          severity: 'error',
        }),
      );
    }
  }, [isError]);

  const loadContacts = () => {
    if (currentData?.data.length === LIMIT) {
      setOffset(offset + LIMIT);
    } else {
      logger.info('No more data available');
    }
  };

  useEffect(() => {
    if (offset < 20) {
      setContacts(currentData?.data || []);
    } else {
      setContacts(_.concat(contacts, currentData?.data || []));
    }
  }, [currentData]);

  useEffect(() => {
    setOffset(0);
  }, [inputValue]);

  if (isLoadingCurrent) {
    return <Loader size={100} label={t(`contactExecutions.loadingContacts`)} />;
  }

  return (
    <>
      <DrawerHeader title={t('contactExecutions.searchContact')} />
      <SearchBar
        value={inputValue}
        onChange={(term) => setInputValue(term)}
        sx={{
          border: 'none',
          borderRadius: 0,
          padding: '12px 14px',
        }}
      />
      {currentData ? (
        <Stack
          id="contactsHolder"
          sx={{
            height: `${window.innerHeight - 191}px`,
            overflow: 'auto',
            display: 'flex',
            padding: 0,
            backgroundColor: 'var(--joy-palette-neutral-50)',
          }}
        >
          <InfiniteScroll
            scrollableTarget="contactsHolder"
            dataLength={contacts?.length}
            next={() => loadContacts()}
            hasMore
            scrollThreshold="75%"
            style={{
              display: 'flex',
            }}
            loader={
              isLoadingCurrent && (
                <Loader
                  size={100}
                  label={t(`contactExecutions.loadingContacts`)}
                />
              )
            }
          >
            <List>
              {contacts?.map((contact: Contact) => (
                <ContactListItem
                  key={contact.id}
                  contact={contact}
                  onClick={onClick}
                />
              ))}
            </List>
          </InfiniteScroll>
        </Stack>
      ) : (
        <Stack sx={{ padding: '24px' }} gap={2}>
          <img
            src="https://assets.sakari.io/images/empty/userexecutions.svg"
            alt="No contacts found"
            height="100px"
          />
          <EmptyResult
            heading={t(`contactExecutions.noContacts`)}
            text={t(`contactExecutions.noContactsFound`)}
          />
        </Stack>
      )}
    </>
  );
}

export default ContactsList;
