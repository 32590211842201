import { Stack, Typography } from '@mui/joy';
import { Hours } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import React from 'react';
import SimpleTime from '../../SimpleTime';

interface TimeRangeProps {
  value: Hours;
  onChange: (value: Hours) => void;
}

function TimeRange({ value, onChange }: TimeRangeProps) {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
      }}
    >
      <SimpleTime
        onChange={(start) => onChange({ start, end: value?.end })}
        value={value?.start}
      />
      <Typography level="body-md">{t('to')}</Typography>
      <SimpleTime
        onChange={(end) => onChange({ start: value?.start, end })}
        value={value?.end}
      />
    </Stack>
  );
}
export default TimeRange;
