import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';
import { AccountContext } from '../../../../../contexts/account.context';
import ContactDetails from '../../../drawers/ContactDetails';

interface ContactProps {
  contactId: string;
}

function Contact({ contactId }: ContactProps) {
  const { t } = useTranslation('voice');
  const { account } = useContext(AccountContext);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <Typography
        level="body-sm"
        sx={{
          color: 'var(--joy-palette-voice-softHoverBg)',
          cursor: 'pointer',
          '&:hover': { opacity: 0.8 },
        }}
        onClick={() => setDrawerOpen(!drawerOpen)}
      >
        {t('viewContact')}
      </Typography>
      {account && (
        <ContactDetails
          account={account}
          contactId={contactId}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      )}
    </>
  );
}

export default Contact;
