import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate } from 'mui-rff';

import {
  Typography,
  Stack,
  Link,
  SvgIcon,
  ModalDialog,
  Modal,
  Button,
} from '@mui/joy';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { InternationalNumber } from '@sakari-io/sakari-typings';
import { useTranslation } from 'react-i18next';
import { TwoFACodeInput, logger } from '@sakari-io/sakari-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Helper from '../../../../utils/helper';
import AppDialog from '../../../molecules/AppDialog';
import {
  useRequestUserMobileVerificationMutation,
  useCheckUserMobileVerificationMutation,
} from '../../../../api';
import FieldWrapper from '../../../../utils/FieldWrapper';

interface VerifyDialogProps {
  open: boolean;
  onClose: (verified: boolean) => void;
  number: InternationalNumber;
  skipDisabled?: boolean;
}

function VerifyDialog({
  open,
  onClose,
  number,
  skipDisabled,
}: VerifyDialogProps) {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [alreadyCredited, setAlreadyCredited] = useState(false);

  const [verifyUserMobile] = useRequestUserMobileVerificationMutation();
  const [verificationId, setVerificationId] = useState('');
  const [updateVerification] = useCheckUserMobileVerificationMutation();

  const schema = Yup.object().shape({
    code: Yup.string().min(6).required(t('pleaseEnterVerificationCode')),
  });

  const validate = makeValidate(schema);

  const sendCode = async () => {
    logger.info('number', number);
    const payload = await verifyUserMobile(number).unwrap();

    logger.info('verificationId', payload.data);
    setVerificationId(payload.data);
  };

  const sendVerificationForm = async (values: any) => {
    try {
      const payload = await updateVerification({
        verificationId,
        code: values?.code,
      }).unwrap();

      if (payload.data.accountCredited) {
        setAlreadyCredited(true);
      } else {
        setAlreadyCredited(false);
      }

      if (payload.success) {
        setSuccess(true);
        setSubmitted(true);
      } else {
        setSuccess(false);
        setSubmitted(true);
      }
    } catch (e) {
      setSuccess(false);
      setSubmitted(true);
    }
  };

  const continueClick = (success: boolean) => {
    setSuccess(false);
    setSubmitted(false);
    if (success) {
      onClose(true);
    }
  };

  useEffect(() => {
    if (open) {
      sendCode();
    }
  }, []);

  let content;

  if (success) {
    if (alreadyCredited) {
      content = t('verifiedReceivedCredit');
    } else {
      content = t('youreVerified');
    }
  } else {
    content = t('notRight');
  }

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Form
        onSubmit={sendVerificationForm}
        validate={validate}
        render={({
          handleSubmit,
          hasValidationErrors,
          pristine,
          submitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              width: 'inherit',
              display: 'flex',
            }}
          >
            <ModalDialog>
              <Stack
                sx={{
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Typography level="h4">{t('checkMobile')}</Typography>
                <Typography level="body-md">
                  {`${t('sentCodeTo')} ${Helper.formatPhoneNumber(number)}`}
                </Typography>
                <FieldWrapper
                  component={TwoFACodeInput}
                  numBoxes={6}
                  splitAt={3}
                  fullWidth
                  placeholder={t('enterCode')}
                  id="code"
                  name="code"
                  sx={{
                    gap: 0,
                    alignItems: 'center',
                  }}
                />

                <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                  <Typography level="body-sm">{t('didntGetCode')}</Typography>
                  <Link
                    level="body-sm"
                    color="primary"
                    onClick={() => {
                      sendCode();
                    }}
                  >
                    {t('retry')}
                  </Link>
                </Stack>
                {skipDisabled ? null : (
                  <Link
                    level="body-sm"
                    color="primary"
                    onClick={() => {
                      onClose(false);
                    }}
                  >
                    {t('withoutVerification')}
                  </Link>
                )}
                <Button
                  type="submit"
                  disabled={pristine || submitting || hasValidationErrors}
                >
                  {t('action.verify')}
                </Button>
              </Stack>
            </ModalDialog>
            <AppDialog
              setOpen={() => setSubmitted(false)}
              open={submitted}
              content={content}
              headerIcon={
                <SvgIcon color={success ? 'success' : 'danger'} fontSize="xl">
                  <FontAwesomeIcon
                    icon={
                      success ? solid('check') : solid('exclamation-circle')
                    }
                  />
                </SvgIcon>
              }
              confirmButtonProps={
                success
                  ? {
                      children: t('continue'),
                      onClick: () => continueClick(true),
                    }
                  : {
                      children: t('tryAgain'),
                      onClick: () => continueClick(false),
                    }
              }
            />
          </form>
        )}
      />
    </Modal>
  );
}

export default VerifyDialog;
