import React, { useState, useEffect, useContext } from 'react';
import { Stack, Box, LinearProgress } from '@mui/joy';
import Grid from '@mui/joy/Grid';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AvailableIntegration } from '@sakari-io/sakari-typings';
import { useDebouncedValue } from '@sakari-io/sakari-components';
import Integrations, { IntegrationsViewMode } from '.';
import IntegrationCardItem from '../../ui/organisms/cards/AvailableIntegrationCardItem';
import { useGetAvailableIntegrationsQuery } from '../../api';

import IntegrationDetailsDialog from '../../ui/organisms/Dialogs/IntegrationDetailsDialog';
import SearchBar from '../../ui/molecules/inputs/SearchBar';
import EmptyResult from '../../ui/molecules/EmptyResult';
import { AccountContext } from '../../contexts/account.context';

function Marketplace() {
  const { account, profile } = useContext(AccountContext);
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCategories] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [integrations, setIntegrations] = useState<AvailableIntegration[]>([]);
  const [selectedIntegration, setSelectedIntegration] =
    useState<AvailableIntegration>();

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);

  const loadIntegrations = () => {
    setOffset(offset + 20);
  };

  const {
    data: integrationsData,
    isLoading,
    isFetching,
  } = useGetAvailableIntegrationsQuery({
    ...(debouncedSearchTerm && { q: debouncedSearchTerm || '' }),
    ...(selectedCategories && {
      category: selectedCategories.map((c) => c.value),
    }),
    offset,
    limit: 20,
  });

  useEffect(() => {
    setOffset(0);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (integrationsData?.data) {
      if (offset) {
        setIntegrations([...(integrations || []), ...integrationsData.data]);
      } else {
        setIntegrations(integrationsData.data);
      }
    }
  }, [integrationsData]);

  return (
    <Integrations view={IntegrationsViewMode.MARKETPLACE}>
      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            position: 'sticky',
            zIndex: 'appBar',
            backgroundColor: 'background.level1',
            pb: 1,
            width: '100%',
          }}
        >
          {/* <Grid xs={12} md={6}>
            <Autocomplete
              orientation="horizontal"
              label="Filter by:"
              multiple
              options={formattedCategories}
              value={selectedCategories}
              onChange={(value) => setSelectedCategories(value)}
              limitTags={3}
              fullWidth
              selectOnFocus
              clearOnBlur
            />
          </Grid> */}
          <Grid xs={12} md={12}>
            <SearchBar
              value={searchTerm}
              onChange={(val) => setSearchTerm(val)}
              fullWidth
            />
          </Grid>
          {isLoading ||
            (isFetching && (
              <Box
                width="100%"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                }}
              >
                <LinearProgress
                  sx={{
                    zIndex: 'appBar',
                  }}
                />
              </Box>
            ))}
        </Grid>
        <Stack
          id="marketplaceScroller"
          sx={{
            pt: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          {(!isLoading || !isFetching) && integrations.length === 0 && (
            <EmptyResult
              item="integrations"
              heading={t('emptyResultHeading')}
              text={t('emptyResultMessage')}
            />
          )}
          <InfiniteScroll
            dataLength={integrations.length}
            next={loadIntegrations}
            hasMore
            scrollableTarget="marketplaceScroller"
            style={{
              display: 'flex',
              overflow: 'hidden',
              zIndex: 0,
            }}
            loader={null}
          >
            <Grid container spacing={2} sx={{ width: '100%' }}>
              {integrations.map((integration) => (
                <Grid xs={12} md={6} key={integration.id}>
                  <IntegrationCardItem
                    integration={integration}
                    onClick={() => {
                      setSelectedIntegration(integration);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </Stack>
      </Stack>

      {account && profile ? (
        <IntegrationDetailsDialog
          account={account}
          integration={selectedIntegration}
          user={profile}
          open={!!selectedIntegration}
          onClose={() => setSelectedIntegration(undefined)}
        />
      ) : null}
    </Integrations>
  );
}

export default Marketplace;
